import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  VStack,
  Text,
  Image,
  Container,
  HStack,
  Icon,
  Button,
  useToast,
  Spinner,
  Center,
  Flex,
  Badge,
} from '@chakra-ui/react';
import { collection, getDocs, doc, updateDoc, arrayUnion, getDoc } from 'firebase/firestore';
import { firestore } from "../firebase/firebase";
import useAuthStore from "../store/authStore";
import { FaHeart, FaTimes, FaChevronUp, FaChevronDown, FaBriefcase, FaInfoCircle, FaClock, FaUser, FaFlag, FaArrowLeft, FaArrowUp, FaArrowDown, FaArrowRight } from 'react-icons/fa';

const LogoComponent = ({ onClick }) => (
  <Box 
    as="h1" 
    fontSize={{ base: "28px", md: "32px" }}
    fontWeight="400"
    letterSpacing="0.35em"
    position="relative"
    display="inline-block"
    cursor="pointer"
    color="#1A1A1A"
    textTransform="uppercase"
    fontFamily="Montserrat, sans-serif"
    onClick={onClick}
    _hover={{
      letterSpacing: "0.4em",
      transform: "translateY(-1px)",
      transition: "all 0.4s cubic-bezier(0.4, 0, 0.2, 1)",
    }}
    transition="all 0.4s cubic-bezier(0.4, 0, 0.2, 1)"
    sx={{
      WebkitFontSmoothing: "antialiased",
      textRendering: "optimizeLegibility",
    }}
  >
    <Box
      as="span"
      position="relative"
      _before={{
        content: '""',
        position: "absolute",
        left: "-8%",
        right: "-8%",
        top: "-8px",
        bottom: "-8px",
        background: "linear-gradient(135deg, rgba(26, 26, 26, 0.08), rgba(26, 26, 26, 0.03))",
        backdropFilter: "blur(4px)",
        opacity: { base: 0.95, md: 0.9 },
        borderRadius: "2px",
        transform: "skew(-4deg)",
        transition: "all 0.3s ease",
        zIndex: -1,
      }}
      _after={{
        content: '""',
        position: "absolute",
        left: 0,
        right: 0,
        bottom: "-4px",
        height: "1px",
        background: "linear-gradient(to right, transparent, currentColor, transparent)",
        opacity: 0.4,
        transform: "scaleX(0.8)",
        transition: "transform 0.3s ease",
      }}
      _hover={{
        _after: {
          transform: "scaleX(1)",
        },
      }}
    >
      hubzo
    </Box>
  </Box>
);

const SwipePage = () => {
  const [profiles, setProfiles] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const toast = useToast();
  const authUser = useAuthStore((state) => state.user);
  const [currentPage, setCurrentPage] = useState(0);
  const TOTAL_PAGES = 3; // Total number of pages available
  const [isTransitioning, setIsTransitioning] = useState(false);
  const touchStartY = useRef(null);
  const touchStartTime = useRef(null);
  const MIN_SWIPE_DISTANCE = 50; // Minimum distance for a swipe
  const MAX_SWIPE_TIME = 300; // Maximum time for a swipe in milliseconds

  useEffect(() => {
    const fetchProfiles = async () => {
      try {
        const userDoc = await getDoc(doc(firestore, 'users', authUser.uid));
        const userData = userDoc.data();
        const alreadySwiped = userData.swipedUsers || [];

        const querySnapshot = await getDocs(collection(firestore, 'users'));
        const fetchedProfiles = querySnapshot.docs
          .map(doc => ({ 
            id: doc.id, 
            ...doc.data(),
            interests: doc.data().interests || [],
            age: doc.data().yob || '?',
            bio: doc.data().bio || 'No bio yet',
            displayName: doc.data().fullName || 'Anonymous',
            profilePicURL: doc.data().profilePicURL || 'https://bit.ly/broken-link',
          }))
          .filter(profile => 
            profile.id !== authUser.uid && 
            !alreadySwiped.includes(profile.id)
          );
        
        setProfiles(fetchedProfiles);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching profiles:', error);
        toast({
          title: 'Error loading profiles',
          status: 'error',
          duration: 3000,
        });
        setLoading(false);
      }
    };

    if (authUser) {
      fetchProfiles();
    }
  }, [authUser]);

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === 'ArrowLeft') {
        handleAction(false);
      } else if (e.key === 'ArrowRight') {
        handleAction(true);
      } else if (e.key === 'ArrowUp' && currentPage > 0) {
        setCurrentPage(prev => prev - 1);
      } else if (e.key === 'ArrowDown' && currentPage < TOTAL_PAGES - 1) {
        setCurrentPage(prev => prev + 1);
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [currentIndex, currentPage]);

  useEffect(() => {
    let lastScrollTime = 0;
    const scrollCooldown = 500; // milliseconds between scroll actions

    const handleWheel = (e) => {
      e.preventDefault();
      
      const currentTime = Date.now();
      if (currentTime - lastScrollTime < scrollCooldown) {
        return;
      }

      const scrollThreshold = 20;
      
      if (Math.abs(e.deltaY) > scrollThreshold) {
        if (e.deltaY > 0 && currentPage < TOTAL_PAGES - 1) {
          setCurrentPage(prev => prev + 1);
          lastScrollTime = currentTime;
        } else if (e.deltaY < 0 && currentPage > 0) {
          setCurrentPage(prev => prev - 1);
          lastScrollTime = currentTime;
        }
      }
    };

    const cardContainer = document.querySelector('.profile-card-container');
    if (cardContainer) {
      // Using both wheel and touchmove events for better touchpad support
      cardContainer.addEventListener('wheel', handleWheel, { passive: false });
      cardContainer.addEventListener('touchmove', (e) => {
        e.preventDefault();
      }, { passive: false });
    }

    return () => {
      if (cardContainer) {
        cardContainer.removeEventListener('wheel', handleWheel);
        cardContainer.removeEventListener('touchmove', (e) => {
          e.preventDefault();
        });
      }
    };
  }, [currentPage]);

  const handleSwipe = (event) => {
    const touch = event.changedTouches[0];
    const swipeUp = event.type === 'touchend' && touch.pageY < touchStartY.current;
    const swipeDown = event.type === 'touchend' && touch.pageY > touchStartY.current;
    
    const distance = Math.abs(touch.pageY - touchStartY.current);
    
    if (distance > 50) { // Minimum swipe distance
      if (swipeUp && currentPage < TOTAL_PAGES - 1) {
        setCurrentPage(prev => prev + 1);
      } else if (swipeDown && currentPage > 0) {
        setCurrentPage(prev => prev - 1);
      }
    }
  };

  const handleAction = async (liked) => {
    const currentProfile = profiles[currentIndex];
    if (!currentProfile || isTransitioning) return;

    try {
      setIsTransitioning(true);
      
      // First, smoothly reset the page view
      if (currentPage !== 0) {
        setCurrentPage(0);
        // Wait for page transition to complete
        await new Promise(resolve => setTimeout(resolve, 300));
      }

      const userRef = doc(firestore, 'users', authUser.uid);
      await updateDoc(userRef, {
        swipedUsers: arrayUnion(currentProfile.id),
        [`likes.${currentProfile.id}`]: liked,
      });

      if (liked) {
        const swipedUserDoc = await getDoc(doc(firestore, 'users', currentProfile.id));
        const swipedUserData = swipedUserDoc.data();
        
        if (swipedUserData?.likes?.[authUser.uid]) {
          toast({
            title: "It's a match! 🎉",
            description: "You can now message each other",
            status: 'success',
            duration: 5000,
          });
          
          await updateDoc(userRef, {
            [`matches.${currentProfile.id}`]: true,
          });
          
          await updateDoc(doc(firestore, 'users', currentProfile.id), {
            [`matches.${authUser.uid}`]: true,
          });
        }
      }

      // Add a slight delay before changing profile
      await new Promise(resolve => setTimeout(resolve, 100));
      setCurrentIndex(prev => prev + 1);
      setIsTransitioning(false);
    } catch (error) {
      console.error('Error updating matches:', error);
      setIsTransitioning(false);
    }
  };

  const DetailedView = ({ profile }) => (
    <Box w="full" h="full" bg="white" p={8}>
      <VStack align="start" spacing={8} h="full">
        {/* About Section with icon */}
        <Box w="full">
          <HStack spacing={2} mb={4} color="gray.600">
            <Icon as={FaInfoCircle} />
            <Text fontSize="lg" fontWeight="semibold">
              About {profile.fullName}
            </Text>
          </HStack>
          <Text 
            color="gray.700" 
            fontSize="lg" 
            whiteSpace="pre-wrap"
            textAlign="center"
          >
            {profile.bio}
          </Text>
        </Box>

        {/* Basic Info Badges */}
        <Flex gap={3} flexWrap="wrap" justify="center" w="full">
          <Badge
            px={4}
            py={2}
            bg="yellow.50"
            color="gray.700"
            rounded="full"
            fontSize="md"
            display="flex"
            alignItems="center"
            gap={2}
          >
            <Icon as={FaBriefcase} />
            Full-time job
          </Badge>
          <Badge
            px={4}
            py={2}
            bg="yellow.50"
            color="gray.700"
            rounded="full"
            fontSize="md"
            display="flex"
            alignItems="center"
            gap={2}
          >
            <Icon as={FaClock} />
            10+ years
          </Badge>
          <Badge
            px={4}
            py={2}
            bg="yellow.50"
            color="gray.700"
            rounded="full"
            fontSize="md"
            display="flex"
            alignItems="center"
            gap={2}
          >
            <Icon as={FaUser} />
            Man
          </Badge>
        </Flex>

        {/* Interests Section */}
        {Array.isArray(profile.interests) && profile.interests.length > 0 && (
          <Box w="full">
            <Text fontSize="lg" fontWeight="semibold" mb={4} color="gray.600">
              Interests
            </Text>
            <Flex gap={3} flexWrap="wrap">
              {profile.interests.map((interest, i) => (
                <Badge
                  key={i}
                  px={4}
                  py={2}
                  bg="yellow.50"
                  color="gray.700"
                  rounded="full"
                  fontSize="md"
                >
                  {interest}
                </Badge>
              ))}
            </Flex>
          </Box>
        )}
      </VStack>
    </Box>
  );

  const PhotosView = ({ profile }) => (
    <Box w="full" h="full" bg="white">
      <Flex h="full">
        {/* First Photo */}
        <Box w="50%" h="full" position="relative">
          <Image
            src={profile?.profilePicURLs?.pic2?.url || profile?.profilePicURL}
            alt={`${profile.fullName} photo 1`}
            objectFit="cover"
            w="full"
            h="full"
          />
        </Box>

        {/* Second Photo - you might want to use a different photo field */}
        <Box w="50%" h="full" position="relative">
          <Image
            src={profile?.profilePicURLs?.pic3?.url || profile?.profilePicURL}
            alt={`${profile.fullName} photo 2`}
            objectFit="cover"
            w="full"
            h="full"
          />
        </Box>
      </Flex>
    </Box>
  );

  if (loading) {
    return (
      <Center h="100vh">
        <Spinner size="xl" color="purple.500" />
      </Center>
    );
  }

  const currentProfile = profiles[currentIndex];

  return (
    <Box 
      minH="calc(100vh - 60px)"
      bg="gray.50"
      display="flex"
      alignItems="center"
      mb={{ base: "60px", md: 0 }}
      pb={{ base: 32, md: 8 }}
    >
      <Container 
        maxW={{ base: "container.md", md: "container.xl" }}
        h="full"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="flex-start"
        px={4}
        py={6}
        mb={{ base: 8, md: 0 }}
      >
        {/* Logo Header - Always show */}
        <Flex
          w="full"
          alignItems="center"
          justify={{ base: "space-between", md: "center" }}
          mb={6}
          gap={4}
        >
          <Box display={{ base: "block", md: "none" }}>
            <LogoComponent onClick={() => navigate("/")} />
          </Box>
        </Flex>

        {/* Profile Content */}
        <Flex 
          direction="column" 
          align="center" 
          justify="center"
          h="full"
          w="full"
        >
          {loading ? (
            <Center flex={1}>
              <Spinner size="xl" color="purple.500" />
            </Center>
          ) : currentProfile ? (
            <Box position="relative" w={{ base: "full", md: "850px" }}>
              <Box 
                w="full"
                h={{ base: "calc(100vh - 180px)", md: "600px" }}
                borderRadius={{ base: "none", md: "xl" }}
                overflow="hidden"
                bg="white"
                shadow={{ base: "none", md: "xl" }}
                position="relative"
                className="profile-card-container"
                onTouchStart={(e) => {
                  touchStartY.current = e.touches[0].pageY;
                }}
                onTouchEnd={handleSwipe}
                sx={{
                  touchAction: "pan-x", // Allow horizontal swipes only
                  WebkitOverflowScrolling: "touch",
                  overscrollBehavior: "none",
                }}
              >
                {/* Pagination Indicator */}
                <Box
                  position="absolute"
                  top={4}
                  right={4}
                  zIndex={2}
                  display="flex"
                  flexDirection="column"
                  gap={2}
                >
                  {Array.from({ length: TOTAL_PAGES }).map((_, index) => (
                    <Box
                      key={index}
                      w="4px"
                      h="24px"
                      bg={currentPage === index ? "yellow.400" : "gray.300"}
                      rounded="full"
                      cursor="pointer"
                      onClick={() => setCurrentPage(index)}
                      transition="background 0.2s"
                    />
                  ))}
                </Box>

                <Box
                  transform={`translateY(-${currentPage * 100}%)`}
                  transition="transform 0.3s ease-out"
                  opacity={isTransitioning ? 0.5 : 1}
                  filter={isTransitioning ? 'blur(2px)' : 'none'}
                  h="full"
                >
                  {/* Main View */}
                  <Flex 
                    h="full" 
                    direction={{ base: "column", md: "row" }}
                  >
                    {/* Left side - Photo */}
                    <Box 
                      w={{ base: "100%", md: "65%" }} 
                      h={{ base: "60%", md: "full" }}
                      position="relative"
                    >
                      <Image
                        src={currentProfile?.profilePicURLs?.pic1?.url || currentProfile?.profilePicURL}
                        alt={currentProfile.fullName}
                        objectFit="cover"
                        w="full"
                        h="full"
                        fallback={
                          <Center w="full" h="full" bg="gray.100">
                            <VStack spacing={2}>
                              <Text color="gray.500" fontSize="lg">
                                No Image Available
                              </Text>
                              <Text color="gray.400" fontSize="sm">
                                {currentProfile.fullName}
                              </Text>
                            </VStack>
                          </Center>
                        }
                        onError={(e) => {
                          e.target.src = 'https://via.placeholder.com/400?text=No+Image';
                        }}
                      />
                    </Box>

                    {/* Right side - Basic Info */}
                    <Box 
                      w={{ base: "100%", md: "35%" }} 
                      h={{ base: "40%", md: "full" }}
                      borderLeft={{ base: "none", md: "1px" }}
                      borderTop={{ base: "1px", md: "none" }}
                      borderColor="gray.100" 
                      p={{ base: 4, md: 6 }}
                    >
                      <VStack align="start" spacing={6} h="full">
                        <Box>
                          <HStack spacing={3} mb={2}>
                            <Text fontSize="3xl" fontWeight="bold">
                              {currentProfile.fullName}
                            </Text>
                            <Text fontSize="2xl" color="gray.500">
                              {currentProfile.age}
                            </Text>
                          </HStack>
                          <Text color="gray.500" fontSize="md">
                            {currentProfile.occupation || 'No occupation listed'}
                          </Text>
                        </Box>
                      </VStack>
                    </Box>
                  </Flex>

                  {/* Detailed View */}
                  <Box h="full" position="absolute" top="100%" left="0" right="0">
                    <DetailedView profile={currentProfile} />
                  </Box>

                  {/* Photos View */}
                  <Box h="full" position="absolute" top="200%" left="0" right="0">
                    <PhotosView profile={currentProfile} />
                  </Box>
                </Box>
              </Box>

              {/* Block and Report Button - Hide on mobile */}
              <Button
                display={{ base: "none", md: "flex" }}
                variant="ghost"
                size="sm"
                position="absolute"
                bottom="-30px"
                left="4"
                color="gray.500"
                fontSize="sm"
                fontWeight="normal"
                leftIcon={<Icon as={FaFlag} boxSize={3} />}
                _hover={{
                  color: "gray.700",
                  bg: "transparent"
                }}
                height="auto"
                padding={2}
              >
                Block and report
              </Button>

              {/* Action Buttons positioned under the card */}
              <HStack 
                spacing={{ base: 6, md: 8 }}
                position="absolute" 
                bottom={{ base: "-15px", md: "-35px" }}
                left="50%" 
                transform="translateX(-50%)"
                zIndex={2}
                bg="transparent"
                p={{ base: 2, md: 4 }}
                rounded="full"
                justify="center"
              >
                <Button
                  size={{ base: "md", md: "lg" }}
                  w={{ base: "60px", md: "70px" }}
                  h={{ base: "60px", md: "70px" }}
                  bg="white"
                  color="red.500"
                  shadow="xl"
                  onClick={() => handleAction(false)}
                  _hover={{
                    transform: "scale(1.1)",
                    bg: "white",
                  }}
                  transition="all 0.2s"
                >
                  <Icon as={FaTimes} boxSize={{ base: 6, md: 7 }} />
                </Button>
                
                <Button
                  size={{ base: "md", md: "lg" }}
                  w={{ base: "60px", md: "70px" }}
                  h={{ base: "60px", md: "70px" }}
                  bg="white"
                  color="green.500"
                  shadow="xl"
                  onClick={() => handleAction(true)}
                  _hover={{
                    transform: "scale(1.1)",
                    bg: "white",
                  }}
                  transition="all 0.2s"
                >
                  <Icon as={FaHeart} boxSize={{ base: 6, md: 7 }} />
                </Button>
              </HStack>

              {/* Add this component after the action buttons */}
              <HStack 
                display={{ base: "none", md: "flex" }}
                position="absolute"
                bottom="-30px"
                right="4"
                spacing={4}
                color="gray.400"
              >
                <Box position="relative">
                  <Icon 
                    as={FaArrowLeft} 
                    boxSize={3.5}
                    _hover={{ color: "gray.600" }}
                    cursor="pointer"
                    role="button"
                    aria-label="Pass"
                    title="Pass"
                  />
                </Box>

                <Box position="relative">
                  <Icon 
                    as={FaArrowUp} 
                    boxSize={3.5}
                    _hover={{ color: "gray.600" }}
                    cursor="pointer"
                    role="button"
                    aria-label="Scroll up"
                    title="Scroll up"
                  />
                </Box>

                <Box position="relative">
                  <Icon 
                    as={FaArrowDown} 
                    boxSize={3.5}
                    _hover={{ color: "gray.600" }}
                    cursor="pointer"
                    role="button"
                    aria-label="Scroll down"
                    title="Scroll down"
                  />
                </Box>

                <Box position="relative">
                  <Icon 
                    as={FaArrowRight} 
                    boxSize={3.5}
                    _hover={{ color: "gray.600" }}
                    cursor="pointer"
                    role="button"
                    aria-label="Like"
                    title="Like"
                  />
                </Box>
              </HStack>
            </Box>
          ) : (
            <Center py={{ base: 10, md: 20 }}>
              <VStack spacing={4}>
                <Text fontSize="xl" color="gray.600">
                  No more profiles to show
                </Text>
                <Button
                  colorScheme="blue"
                  onClick={() => window.location.reload()}
                >
                  Refresh
                </Button>
              </VStack>
            </Center>
          )}
        </Flex>
      </Container>
    </Box>
  );
};

export default SwipePage; 