import { Link, useLocation } from 'react-router-dom';
import { Box, Flex, Avatar, Menu, MenuButton, MenuList, MenuItem, Icon } from '@chakra-ui/react';
import { BiSearch, BiGroup, BiCalendarPlus, BiBell } from 'react-icons/bi';
import { AiFillHome, AiTwotoneCalendar } from 'react-icons/ai';
import useAuthStore from '../../store/authStore';
import { auth } from '../../firebase/firebase';
import { signOut } from 'firebase/auth';
import { CgProfile, CgQr } from "react-icons/cg";
import useLogout from "../../hooks/useLogout";
import { HiOutlineUserGroup, HiUserGroup } from 'react-icons/hi';
import { HiOutlineTicket, HiTicket } from 'react-icons/hi2';
import { BiCalendarEvent, BiSolidCalendarEvent } from 'react-icons/bi';

// Brand colors matching LandingPage
const colors = {
  activeColor: 'gray.900',
  inactiveColor: 'gray.400',
  bgColor: 'rgba(255, 255, 255, 0.98)',
  borderColor: 'rgba(0, 0, 0, 0.08)',
  activeBg: 'blackAlpha.50',
};

const LogoComponent = ({ onClick }) => (
  <Box 
    as="h1" 
    fontSize={{ base: "28px", md: "32px" }}
    fontWeight="400"
    letterSpacing="0.35em"
    position="relative"
    display="inline-block"
    cursor="pointer"
    color="#1A1A1A"
    textTransform="uppercase"
    fontFamily="Montserrat, sans-serif"
    onClick={onClick}
    _hover={{
      letterSpacing: "0.4em",
      transform: "translateY(-1px)",
      transition: "all 0.4s cubic-bezier(0.4, 0, 0.2, 1)",
    }}
    transition="all 0.4s cubic-bezier(0.4, 0, 0.2, 1)"
    sx={{
      WebkitFontSmoothing: "antialiased",
      textRendering: "optimizeLegibility",
    }}
  >
    <Box
      as="span"
      position="relative"
      _before={{
        content: '""',
        position: "absolute",
        left: "-8%",
        right: "-8%",
        top: "-8px",
        bottom: "-8px",
        background: "linear-gradient(135deg, rgba(184, 160, 128, 0.08), rgba(184, 160, 128, 0.03))",
        backdropFilter: "blur(4px)",
        opacity: { base: 0.95, md: 0.9 },
        borderRadius: "2px",
        transform: "skew(-4deg)",
        transition: "all 0.3s ease",
        zIndex: -1,
      }}
      _after={{
        content: '""',
        position: "absolute",
        left: 0,
        right: 0,
        bottom: "-4px",
        height: "1px",
        background: `linear-gradient(to right, transparent, ${colors.activeColor}, transparent)`,
        opacity: 0.4,
        transform: "scaleX(0.8)",
        transition: "transform 0.3s ease",
      }}
      _hover={{
        _after: {
          transform: "scaleX(1)",
        },
      }}
    >
      hubzo
    </Box>
  </Box>
);

const DesktopNav = () => {
  const { user } = useAuthStore();
  const location = useLocation();
  const { handleLogout } = useLogout();

  const navItems = [
    { 
      iconOutline: BiCalendarEvent,
      iconFilled: BiSolidCalendarEvent,
      path: '/', 
      label: 'Invitations'
    },
    { 
      iconOutline: HiOutlineTicket,
      iconFilled: HiTicket,
      path: '/user_tickets', 
      label: 'Tickets'
    },
    // { 
    //   iconOutline: HiOutlineUserGroup,
    //   iconFilled: HiUserGroup,
    //   path: '/groups', 
    //   label: 'Groups'
    // },
    { 
      iconOutline: CgProfile,
      iconFilled: CgProfile,
      path: '/user_profile', 
      label: 'Account'
    },
  ];

  return (
    <Box 
      position="fixed"
      top={0}
      left={0}
      right={0}
      style={{
        backdropFilter: 'blur(20px)',
        backgroundColor: colors.bgColor,
      }}
      borderBottom="1px solid"
      borderColor={colors.borderColor}
      height="64px"
      zIndex={1000}
      boxShadow="0 4px 20px rgba(0, 0, 0, 0.05)"
    >
      <Flex 
        maxW="1200px" 
        mx="auto" 
        h="100%" 
        px={6} 
        align="center" 
        justify="space-between"
      >
        <Link to="/">
          <LogoComponent onClick={() => {}} />
        </Link>

        <Flex gap={8} align="center">
          {navItems.map((item) => {
            const isActive = location.pathname === item.path;
            
            return (
              <Link key={item.path} to={item.path}>
                <Flex 
                  align="center" 
                  gap={2}
                  p={2.5}
                  rounded="xl"
                  color={isActive ? colors.activeColor : colors.inactiveColor}
                  bg={isActive ? colors.activeBg : 'transparent'}
                  transition="all 0.2s ease"
                  _hover={{
                    color: colors.activeColor,
                    bg: 'blackAlpha.50',
                  }}
                >
                  <Icon 
                    as={isActive ? item.iconFilled : item.iconOutline}
                    boxSize={6}
                    transition="all 0.2s ease"
                    style={{
                      filter: isActive 
                        ? 'drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2))'
                        : 'none'
                    }}
                  />
                  <Box 
                    display={{ base: 'none', lg: 'block' }}
                    fontSize="sm"
                    fontWeight={isActive ? "semibold" : "medium"}
                    transition="all 0.2s ease"
                  >
                    {item.label}
                  </Box>
                </Flex>
              </Link>
            );
          })}


        </Flex>
      </Flex>
    </Box>
  );
};

export default DesktopNav; 