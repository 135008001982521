import {
  Container,
  Flex,
  Button,
  VStack,
  FormControl,
  Text,
  IconButton,
  Input,
  Box,
  Image,
  Progress,
  HStack,
} from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";
import useEditEventPic from "../../hooks/useEditEventPic";
import usePreviewImg from "../../hooks/usePreviewImg";

const CreateEventPicturePage = () => {
  const fileRef = useRef(null);
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState(
    localStorage.getItem("event-pic-preview") || ""
  );
  const { isUpdating, editProfile } = useEditEventPic();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type.includes("image")) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedFile(reader.result);
        localStorage.setItem("event-pic-preview", reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleNext = async () => {
    try {
      if (selectedFile) {
        await editProfile(selectedFile);
      }
      navigate("/create_event_description");
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const handleSkip = () => {
    setSelectedFile("");
    localStorage.removeItem("event-pic-preview");
    navigate("/create_event_description");
  };

  return (
    <Box
      position="fixed"
      top={{ base: 0, md: "64px" }}
      left={0}
      right={0}
      bottom={0}
      bg="white"
      zIndex={1000}
      overflowY="auto"
    >
      <Container
        maxW="container.sm"
        py={4}
        display="flex"
        flexDirection="column"
        minH={{ base: "100vh", md: "calc(100vh - 64px)" }}
      >
        {/* Header with Progress - Visible on all screens */}
        <Flex w="full" align="center" mb={6}>
          <IconButton
            icon={<ArrowBackIcon />}
            variant="ghost"
            aria-label="Go back"
            onClick={() => navigate(-1)}
            color="gray.500"
            _hover={{ color: "#4F46E5" }}
          />
          <Progress
            value={18.75}
            size="sm"
            colorScheme="purple"
            flex="1"
            mx={4}
            borderRadius="full"
            bg="gray.100"
          />
        </Flex>

        {/* Main Content */}
        <VStack spacing={8} align="center" w="full" flex="1">
          <VStack spacing={3} textAlign="center">
            <Text
              fontSize={{ base: "2xl", md: "3xl" }}
              fontWeight="bold"
              //   bgGradient="linear(to-r, #4F46E5, #7C3AED)"
              //   bgClip="text"
              color="gray.900"
            >
              Add Event Picture
            </Text>
            <Text color="gray.600" fontSize={{ base: "md", md: "lg" }}>
              Help people visualize your event
            </Text>
          </VStack>

          <VStack spacing={6} w="full" maxW="400px" flex="1">
            <FormControl>
              {/* Image Upload Section */}
              <Box
                w="full"
                h="300px"
                borderWidth="2px"
                borderStyle="dashed"
                borderColor="gray.300"
                borderRadius="xl"
                bg="gray.50"
                display="flex"
                justifyContent="center"
                alignItems="center"
                cursor="pointer"
                transition="all 0.2s"
                _hover={{
                  borderColor: "#4F46E5",
                  transform: "translateY(-2px)",
                  shadow: "sm",
                }}
                onClick={() => fileRef.current.click()}
                mb={6}
              >
                {selectedFile ? (
                  <Image
                    src={selectedFile}
                    alt="Selected Event"
                    objectFit="cover"
                    w="full"
                    h="full"
                    borderRadius="xl"
                  />
                ) : (
                  <VStack spacing={3}>
                    <Text color="gray.500" fontSize="lg">
                      Upload event image
                    </Text>
                    <Text color="gray.400" fontSize="sm">
                      Click to browse
                    </Text>
                  </VStack>
                )}
              </Box>
              <Input
                type="file"
                hidden
                ref={fileRef}
                accept="image/*"
                onChange={handleImageChange}
              />

              <VStack spacing={4} mt="auto">
                <Button
                  w="full"
                  size="lg"
                  bg="gray.900"
                  color="white"
                  h="60px"
                  fontSize="lg"
                  onClick={handleNext}
                  isLoading={isUpdating}
                  loadingText="Uploading..."
                  isDisabled={!selectedFile}
                  _hover={{
                    transform: selectedFile ? "translateY(-2px)" : "none",
                    shadow: selectedFile ? "lg" : "none",
                    opacity: selectedFile ? 0.95 : 0.6,
                  }}
                  _active={{ transform: "translateY(0)" }}
                  _disabled={{
                    bg: "gray.300",
                    cursor: "not-allowed",
                    opacity: 0.6,
                  }}
                  rounded="xl"
                  rightIcon={<FaArrowRight />}
                  transition="all 0.2s"
                >
                  Continue with Image
                </Button>

                <Button
                  variant="ghost"
                  color="gray.500"
                  w="full"
                  onClick={handleSkip}
                  _hover={{ color: "#4F46E5" }}
                >
                  Skip for now
                </Button>

                <Text color="gray.500" fontSize="sm" textAlign="center">
                  Events with pictures get 80% more responses
                </Text>
              </VStack>
            </FormControl>
          </VStack>
        </VStack>
      </Container>
    </Box>
  );
};

export default CreateEventPicturePage;
