import { useEffect, useState } from "react";
import useAuthStore from "../store/authStore";
import useShowToast from "./useShowToast";
import arrayShuffle from "array-shuffle";

import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { firestore } from "../firebase/firebase";

const useGetSuggestedGroups = (isFollowing) => {
  const [isLoading, setIsLoading] = useState(true);
  const [suggestedUsers, setSuggestedUsers] = useState([]);
  const authUser = useAuthStore((state) => state.user);
  const showToast = useShowToast();

  useEffect(() => {
    const getSuggestedUsers = async () => {
      setIsLoading(true);
      try {
        const usersRef = collection(firestore, "groups");
        const q = query(
          usersRef,
          // where("isCreator", "==", true),
          // where("uid", "not-in", [authUser.uid, ...authUser.following]),
          orderBy("users", "desc")
          // limit(3)
        );

        const querySnapshot = await getDocs(q);
        const users = [];

        querySnapshot.forEach((doc) => {
          users.push({ ...doc.data(), id: doc.id });
        });

        let filteredUsers = [];

        if (authUser && authUser.blocked) {
          let allBlockedIdsList = [];

          const youBlockedList = authUser.blocked
            .filter((blocked) => blocked.createdBy == authUser.uid)
            .map((filteredBlocked) => filteredBlocked.reportedUserId);

          // allBlockedIdsList.push(youBlockedList);

          const youWereBlockedList = authUser.blocked
            .filter((blocked) => blocked.reportedUserId == authUser.uid)
            .map((filteredBlocked) => filteredBlocked.createdBy);

          // allBlockedIdsList.push(youWereBlockedList);


          allBlockedIdsList = youBlockedList.concat(youWereBlockedList);

          filteredUsers = users.filter(
            (user) => allBlockedIdsList.includes(user.id) == false
          );
        } else {
          filteredUsers = users;
        }

        // let myArray = users;

        // let myArrayz = users.map(function(element){
        // 	return element.uid;
        // });

        // console.log(myArray); // ["a", "d", "e", "f"]

        // const myArray = ["a", "b", "c", "d", "e", "f", "g"];
        // const toRemove = new Set([...authUser.following]);
        // console.log(toRemove); // ["a", "d", "e", "f"]

        // const difference = myArray.filter((x) => !toRemove.has(x.uid));

        // const xxx = arrayShuffle(difference);

        // console.log(difference); // ["a", "d", "e", "f"]

        // if (isFollowing) {
        //   setSuggestedUsers(xxx.slice(0, 2));
        // } else {
        setSuggestedUsers(filteredUsers);
        // }
      } catch (error) {
        showToast("Error", error.message, "error");
      } finally {
        setIsLoading(false);
      }
    };

    if (true) getSuggestedUsers();
  }, [
    // authUser,
    showToast,
  ]);

  return { isLoading, suggestedUsers };
};

export default useGetSuggestedGroups;
