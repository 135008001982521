import { useEffect, useState } from "react";
import useAuthStore from "../store/authStore";
import useUserProfileStore from "../store/userProfileStore";
import useShowToast from "./useShowToast";
import { firestore } from "../firebase/firebase";
import {
  addDoc,
  collection,
  arrayRemove,
  arrayUnion,
  doc,
  updateDoc,
} from "firebase/firestore";

const useReportUser = (userId, reporterUserId, reason, type) => {
  const [isReportUpdating, setIsReportUpdating] = useState(false);
  const [isFollowing, setIsFollowing] = useState(false);
  const authUser = useAuthStore((state) => state.user);
  const setAuthUser = useAuthStore((state) => state.setUser);
  const { userProfile, setUserProfile } = useUserProfileStore();
  const showToast = useShowToast();

  const handleReportUser = async () => {
    if (!authUser) {
      showToast("Error", "You must be logged in", "error");
      return;
    }

    setIsReportUpdating(true);

    const newPost = {
      reportedUserId: userId,
      createdBy: reporterUserId,
      reason: reason,
      createdAt: Date.now(),
      type: type,
    };

    try {
      const postDocRef = await addDoc(
        collection(firestore, "reported"),
        newPost
      );

      // const currentUserRef = doc(firestore, "users", authUser.uid);
      // const userToFollowOrUnfollorRef = doc(firestore, "groups", userId);
      // await updateDoc(currentUserRef, {
      //   following: isFollowing ? arrayRemove(userId) : arrayUnion(userId),
      // });

      // await updateDoc(userToFollowOrUnfollorRef, {
      //   followers: isFollowing
      //     ? arrayRemove(authUser.uid)
      //     : arrayUnion(authUser.uid),
      // });

      // if (isFollowing) {
      //   // unfollow
      //   setAuthUser({
      //     ...authUser,
      //     following: authUser.following.filter((uid) => uid !== userId),
      //   });
      //   if (userProfile)
      //     setUserProfile({
      //       ...userProfile,
      //       followers: userProfile.followers.filter(
      //         (uid) => uid !== authUser.uid
      //       ),
      //     });

      //   localStorage.setItem(
      //     "user-info",
      //     JSON.stringify({
      //       ...authUser,
      //       following: authUser.following.filter((uid) => uid !== userId),
      //     })
      //   );
      //   setIsFollowing(false);
      // } else {
      //   // follow
      //   setAuthUser({
      //     ...authUser,
      //     following: [...authUser.following, userId],
      //   });

      //   if (userProfile)
      //     setUserProfile({
      //       ...userProfile,
      //       followers: [...userProfile.followers, authUser.uid],
      //     });

      //   localStorage.setItem(
      //     "user-info",
      //     JSON.stringify({
      //       ...authUser,
      //       following: [...authUser.following, userId],
      //     })
      //   );
      //   setIsFollowing(true);
      // }
      showToast("", "User reported successfully", "success");
    } catch (error) {
      showToast("Error", error.message, "error");
    } finally {
      setIsReportUpdating(false);
    }
  };

  // useEffect(() => {
  //   if (authUser) {
  //     const isFollowing = authUser.following.includes(userId);
  //     setIsFollowing(isFollowing);
  //   }
  // }, [authUser, userId]);

  return { isReportUpdating, handleReportUser };
};

export default useReportUser;
