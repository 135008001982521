import { Box, Text, HStack, Image, VStack, Spinner } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import useFetchEventDetails from "../hooks/useFetchEventDetails";
import useLogUserCount from "../hooks/useLogUserCount";

const MinimizedInvitationItem = ({ invite }) => {
  const navigate = useNavigate();
  const { eventDetails, loading, error } = useFetchEventDetails(invite.eventId);
  const { triggerLogUserCount } = useLogUserCount();

  const handleClick = async () => {
    navigate(`/i/${invite.eventId}`);
  };

  if (loading) return <Spinner size="sm" />;
  if (error) return <Text color="red.500">Error loading event details</Text>;

  return (
    <Box
      bg="white"
      p={2}
      borderRadius="lg"
      shadow="sm"
      w="full"
      maxW="350px"
      mb={3}
      cursor="pointer"
      onClick={handleClick}
      _hover={{ bg: "gray.50" }}
    >
      <HStack spacing={3} align="center">
        {eventDetails?.postPicURL ? (
          <Image
            src={eventDetails.postPicURL}
            alt="Event Image"
            borderRadius="md"
            w="50px"
            h="50px"
            objectFit="cover"
          />
        ) : (
          <Box
            w="50px"
            h="50px"
            bg="gray.200"
            borderRadius="md"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Text color="gray.500" fontSize="xs">No Image</Text>
          </Box>
        )}

        <VStack align="start" spacing={0} flex="1">
          <Text fontSize="md" fontWeight="semibold" color="gray.800" isTruncated>
            {eventDetails?.title || "Invitation Title"}
          </Text>
          <Text fontSize="sm" color="gray.600" isTruncated>
            {eventDetails?.formattedEventDate || "Date not specified"}
          </Text>
          <Text fontSize="xs" color="gray.500" isTruncated>
            {eventDetails?.placeName || "Location not specified"}
          </Text>
        </VStack>
      </HStack>
    </Box>
  );
};

export default MinimizedInvitationItem;
