import {
  Container,
  Flex,
  Button,
  VStack,
  FormControl,
  Input,
  Text,
  IconButton,
  Box,
  Image,
  Select,
  SimpleGrid,
  Icon,
} from "@chakra-ui/react";
import { ArrowBackIcon, AddIcon, CloseIcon } from "@chakra-ui/icons";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import useUpdateFacePic from "../../hooks/useUpdateFacePic";
import useShowToast from "../../hooks/useShowToast";
import useAuthStore from "../../store/authStore";
import DesktopNav from "../../components/Navigation/DesktopNav";

const UpdateFacePicPage = () => {
  const authUser = useAuthStore((state) => state.user);
  const showToast = useShowToast();
  const fileRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const { isUpdating, editProfile } = useUpdateFacePic();
  const navigate = useNavigate();
  
  const [selectedSlot, setSelectedSlot] = useState('pic1');

  const colors = {
    textColor: "gray.900",
    bgColor: "white",
    cardBg: "white",
    dividerColor: "gray.200",
    iconBgColor: "rgba(184, 160, 128, 0.1)",
    hoverBgColor: "rgba(184, 160, 128, 0.05)",
    subtitleColor: "gray.600",
    accentColor: "#B8A080",
    borderColor: "rgba(184, 160, 128, 0.2)",
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = async () => {
        setSelectedFile(reader.result);
        try {
          await editProfile(reader.result, selectedSlot);
          setSelectedFile(null);
        } catch (error) {
          showToast("Error", error.message, "error");
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRemovePhoto = async (slot) => {
    try {
      await editProfile(null, slot, true);
    } catch (error) {
      showToast("Error", error.message, "error");
    }
  };

  const handleSlotClick = (slot) => {
    setSelectedSlot(slot);
    fileRef.current.click();
  };

  // Helper function to get the image URL for a slot
  const getImageUrlForSlot = (slot) => {
    if (slot === 'pic1' && !authUser?.profilePicURLs) {
      // Handle legacy case
      return authUser?.profilePicURL;
    }
    return authUser?.profilePicURLs?.[slot]?.url;
  };

  return (
    <Box
      h="100vh"
      maxH="100vh"
      overflow="hidden"
      position="relative"
      bg={colors.bgColor}
      pb="env(safe-area-inset-bottom)"
    >
      <Box display={{ base: "none", md: "block" }}>
        <DesktopNav />
      </Box>

      <Box
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        overflowY="auto"
        pb={{ base: 32, md: 8 }}
        style={{
          WebkitOverflowScrolling: 'touch'
        }}
      >
        <Container 
          maxW={{ base: "container.md", md: "container.xl" }} 
          px={4} 
          py={6}
          mb={{ base: 8, md: 0 }}
          mt={{ base: 0, md: "60px" }}
        >
          {/* Header */}
          <Flex 
            w="full" 
            alignItems="center" 
            mb={8}
            position="relative"
          >
            <IconButton
              icon={<ArrowBackIcon />}
              variant="ghost"
              aria-label="Go back"
              onClick={() => navigate(-1)}
              display={{ base: "flex", md: "none" }}
              color={colors.accentColor}
              _hover={{
                bg: colors.iconBgColor,
                transform: "translateY(-2px)",
              }}
              transition="all 0.2s"
            />
            <Text 
              fontSize={{ base: "2xl", md: "3xl" }}
              fontWeight="light"
              color={colors.textColor}
              textAlign={{ base: "left", md: "center" }}
              flex="1"
              ml={{ base: 4, md: 0 }}
              letterSpacing="wide"
              fontFamily="Montserrat, sans-serif"
            >
              Choose Your Picture
            </Text>
          </Flex>

          {/* Main Content */}
          <Box maxW={{ base: "full", md: "lg" }} mx="auto">
            <Box
              bg={colors.cardBg}
              borderRadius="xl"
              borderWidth="1px"
              borderColor={colors.borderColor}
              p={6}
              textAlign="center"
              shadow="sm"
              _hover={{ shadow: "md" }}
              transition="all 0.2s"
            >
              <VStack spacing={6} align="center">
                <Text 
                  fontSize={{ base: "sm", md: "md" }} 
                  color={colors.textColor}
                  maxW="md"
                  lineHeight="tall"
                  fontFamily="Montserrat, sans-serif"
                  letterSpacing="wide"
                >
                  Add up to 6 photos to your profile. Clear face-and-body photos boost your visibility.
                </Text>

                {/* Picture Grid */}
                <SimpleGrid columns={{ base: 2, md: 3 }} spacing={4} w="full">
                  {[1, 2, 3, 4, 5, 6].map((num) => {
                    const slot = `pic${num}`;
                    const imageUrl = getImageUrlForSlot(slot);

                    return (
                      <Box
                        key={num}
                        position="relative"
                        cursor="pointer"
                        role="group"
                      >
                        <Box
                          w="full"
                          h="0"
                          pb="100%" // Makes box square
                          position="relative"
                          borderWidth="2px"
                          borderStyle="dashed"
                          borderColor={colors.borderColor}
                          borderRadius="xl"
                          bg={colors.iconBgColor}
                          overflow="hidden"
                          transition="all 0.2s"
                          _hover={{
                            borderColor: colors.accentColor,
                            shadow: "md",
                            transform: "translateY(-2px)",
                          }}
                        >
                          {imageUrl ? (
                            <>
                              <Image
                                src={imageUrl}
                                alt={`Picture ${num}`}
                                position="absolute"
                                top="0"
                                left="0"
                                w="full"
                                h="full"
                                objectFit="cover"
                              />
                              <IconButton
                                icon={<CloseIcon />}
                                size="xs"
                                position="absolute"
                                top={2}
                                right={2}
                                bg="blackAlpha.600"
                                color="white"
                                _hover={{ bg: "blackAlpha.800" }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleRemovePhoto(slot);
                                }}
                                opacity={0}
                                _groupHover={{ opacity: 1 }}
                                transition="all 0.2s"
                                aria-label="Remove photo"
                              />
                            </>
                          ) : (
                            <Flex
                              position="absolute"
                              top="0"
                              left="0"
                              w="full"
                              h="full"
                              alignItems="center"
                              justifyContent="center"
                              onClick={() => handleSlotClick(slot)}
                            >
                              <Icon
                                as={AddIcon}
                                w={6}
                                h={6}
                                color={colors.textColor}
                                opacity={0.5}
                              />
                            </Flex>
                          )}
                        </Box>
                      </Box>
                    );
                  })}
                </SimpleGrid>

                {/* Hidden file input */}
                <Input
                  type="file"
                  hidden
                  ref={fileRef}
                  accept="image/*"
                  onChange={handleFileChange}
                />
              </VStack>
            </Box>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default UpdateFacePicPage;
