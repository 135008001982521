import { useEffect, useState } from "react";
import useShowToast from "./useShowToast";
import useAuthStore from "../store/authStore";
import {
  doc,
  getDoc,
  setDoc,
  collection,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { firestore } from "../firebase/firebase";

const useGetGroupEventById = (postId) => {
  const [isLoading, setIsLoading] = useState(true);
  const [post, setPost] = useState(null);
  const authUser = useAuthStore((state) => state.user);

  const showToast = useShowToast();

  useEffect(() => {
    const getPost = async () => {
      setIsLoading(true);
      setPost(null);
      try {
        const userRef = await getDoc(doc(firestore, "events", postId));
        if (userRef.exists()) {
          let postz = userRef.data();

          if (authUser && authUser.blocked) {
            let allBlockedIdsList = [];

            const youBlockedList = authUser.blocked
              .filter((blocked) => blocked.createdBy == authUser.uid)
              .map((filteredBlocked) => filteredBlocked.reportedUserId);

            // allBlockedIdsList.push(youBlockedList);

            const youWereBlockedList = authUser.blocked
              .filter((blocked) => blocked.reportedUserId == authUser.uid)
              .map((filteredBlocked) => filteredBlocked.createdBy);

            // allBlockedIdsList.push(youWereBlockedList);

            allBlockedIdsList = youBlockedList.concat(youWereBlockedList);


            const filteredUsers = postz.goingList.filter(
              (user) => allBlockedIdsList.includes(user.uid) == false
            );

            postz.goingList = filteredUsers;
          }

          postz.id = postId;

          setPost(postz);
        }
        // console.log(userDoc);
      } catch (error) {
        showToast("Error", error.message, "error");
      } finally {
        setIsLoading(false);
      }
    };
    getPost();
  }, [showToast, setPost, postId]);

  return { isLoading, post, setPost };
};

export default useGetGroupEventById;
