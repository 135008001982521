import {
  Container,
  Flex,
  Box,
  IconButton,
  Text,
  VStack,
  Icon,
} from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import { BiBell } from "react-icons/bi";

const NotificationsPage = () => {
  const navigate = useNavigate();

  const colors = {
    textColor: "gray.900",
    bgColor: "white",
    cardBg: "white",
    dividerColor: "gray.200",
    iconBgColor: "rgba(184, 160, 128, 0.1)",
    hoverBgColor: "gray.50",
    subtitleColor: "gray.600",
    accentColor: "#B8A080",
    borderColor: "rgba(184, 160, 128, 0.2)",
  };

  return (
    <Box
      h="100vh"
      maxH="100vh"
      overflow="hidden"
      position="relative"
      bg={colors.bgColor}
      pb="env(safe-area-inset-bottom)"
    >
      <Box
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        overflowY="auto"
        pb={{ base: 32, md: 8 }}
        style={{
          WebkitOverflowScrolling: 'touch'
        }}
      >
        <Container 
          maxW={{ base: "container.md", md: "container.xl" }} 
          px={4} 
          py={6}
          mb={{ base: 8, md: 0 }}
        >
          {/* Header */}
          <Flex 
            w="full" 
            alignItems="center" 
            mb={8}
            position="relative"
          >
            <IconButton
              icon={<ArrowBackIcon />}
              variant="ghost"
              aria-label="Go back"
              onClick={() => navigate(-1)}
              position={{ base: "relative", md: "absolute" }}
              left={{ md: 0 }}
              color={colors.accentColor}
              _hover={{
                bg: colors.iconBgColor,
                transform: "translateY(-2px)",
              }}
              transition="all 0.2s"
            />
            <Text 
              fontSize={{ base: "2xl", md: "3xl" }}
              fontWeight="light"
              color={colors.textColor}
              textAlign={{ base: "left", md: "center" }}
              flex="1"
              ml={{ base: 4, md: 0 }}
              letterSpacing="wide"
              fontFamily="Montserrat, sans-serif"
            >
              Notifications
            </Text>
          </Flex>

          {/* Empty State */}
          <VStack
            spacing={6}
            justify="center"
            align="center"
            h="60vh"
            w="full"
          >
            <Box
              p={4}
              bg={colors.iconBgColor}
              borderRadius="full"
              color={colors.accentColor}
            >
              <Icon as={BiBell} boxSize={8} />
            </Box>
            <VStack spacing={2}>
              <Text
                fontSize={{ base: "xl", md: "2xl" }}
                fontWeight="medium"
                color={colors.textColor}
                textAlign="center"
                fontFamily="Montserrat, sans-serif"
              >
                No Activity Yet
              </Text>
              <Text
                fontSize={{ base: "sm", md: "md" }}
                color={colors.subtitleColor}
                textAlign="center"
                maxW="sm"
              >
                When you have new notifications, they will appear here
              </Text>
            </VStack>
          </VStack>
        </Container>
      </Box>
    </Box>
  );
};

export default NotificationsPage; 