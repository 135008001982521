import {
  Container,
  Flex,
  Button,
  VStack,
  FormControl,
  Text,
  IconButton,
  Box,
  Progress,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Tooltip,
  HStack,
} from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";

const CreateGenderRangePage = () => {
  const [genderRatio, setGenderRatio] = useState(
    Math.round(parseInt(localStorage.getItem("genderRatio")) / 10) * 10 || 50
  );
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleGenderRatioChange = (value) => {
    setGenderRatio(Math.round(value / 10) * 10);
  };

  const handleNext = () => {
    localStorage.setItem("genderRatio", genderRatio);
    navigate("/create_user_score_event");
  };

  return (
    <Box
      position="fixed"
      top={{ base: 0, md: "64px" }}
      left={0}
      right={0}
      bottom={0}
      bg="white"
      zIndex={1000}
      overflowY="auto"
    >
      <Container
        maxW="container.sm"
        py={4}
        display="flex"
        flexDirection="column"
        minH={{ base: "100vh", md: "calc(100vh - 64px)" }}
      >
        {/* Header with Progress - Visible on all screens */}
        <Flex w="full" align="center" mb={6}>
          <IconButton
            icon={<ArrowBackIcon />}
            variant="ghost"
            aria-label="Go back"
            onClick={() => navigate(-1)}
            color="gray.500"
            _hover={{ color: "gray.600" }}
          />
          <Progress
            value={87.5}
            size="sm"
            colorScheme="purple"
            flex="1"
            mx={4}
            borderRadius="full"
            bg="gray.100"
          />
        </Flex>

        {/* Main Content */}
        <VStack spacing={8} align="center" w="full">
          <VStack spacing={3} textAlign="center">
            <Box
              p={3}
              bg="blue.50"
              rounded="full"
              color="blue.600"
              fontSize="2xl"
              mb={2}
            >
              ⚖️
            </Box>

            <Text
              fontSize={{ base: "2xl", md: "3xl" }}
              fontWeight="bold"
              color="gray.900"
            >
              Gender Balance
            </Text>
            <Text color="gray.600" fontSize={{ base: "md", md: "lg" }}>
              Set your preferred gender ratio
            </Text>
          </VStack>

          <VStack spacing={6} w="full" maxW="400px">
            <FormControl>
              <Box
                bg="gray.50"
                p={6}
                borderRadius="xl"
                mb={6}
                borderWidth="1px"
                borderColor="gray.200"
              >
                <VStack spacing={4}>
                  <HStack spacing={8} justify="center" w="full">
                    <VStack spacing={1}>
                      <Text fontSize="3xl" fontWeight="bold" color="#4F46E5">
                        {genderRatio}%
                      </Text>
                      <Text fontSize="sm" color="gray.500" fontWeight="medium">
                        Male
                      </Text>
                    </VStack>
                    <Box h="50px" w="1px" bg="gray.200" />
                    <VStack spacing={1}>
                      <Text fontSize="3xl" fontWeight="bold" color="#7C3AED">
                        {100 - genderRatio}%
                      </Text>
                      <Text fontSize="sm" color="gray.500" fontWeight="medium">
                        Female
                      </Text>
                    </VStack>
                  </HStack>
                  <Box
                    w="full"
                    h="4px"
                    bg="gray.100"
                    borderRadius="full"
                    overflow="hidden"
                  >
                    <Box
                      w={`${genderRatio}%`}
                      h="full"
                      bgGradient="linear(to-r, #4F46E5, #7C3AED)"
                      transition="width 0.2s"
                    />
                  </Box>
                </VStack>
              </Box>

              <Flex alignItems="center" w="full" mb={8}>
                <Text color="gray.500" fontSize="sm" fontWeight="medium">
                  0%
                </Text>
                <Slider
                  aria-label="Gender Ratio Slider"
                  value={genderRatio}
                  min={0}
                  max={100}
                  step={10}
                  mx={4}
                  focusThumbOnChange={false}
                  onChange={handleGenderRatioChange}
                >
                  <SliderTrack bg="gray.200" h="3px">
                    <SliderFilledTrack bg="blue.500" />
                  </SliderTrack>
                  <Tooltip
                    hasArrow
                    label={`${genderRatio}% Male`}
                    bg="blue.600"
                    color="white"
                    placement="top"
                  >
                    <SliderThumb
                      boxSize={6}
                      bg="white"
                      border="2px solid"
                      borderColor="blue.500"
                      _focus={{
                        boxShadow: "0 0 0 3px rgba(66, 153, 225, 0.6)",
                      }}
                    />
                  </Tooltip>
                </Slider>
                <Text color="gray.500" fontSize="sm" fontWeight="medium">
                  100%
                </Text>
              </Flex>

              <Button
                w="full"
                size="lg"
                bg="gray.900"
                color="white"
                h="60px"
                fontSize="lg"
                onClick={handleNext}
                _hover={{
                  transform: "translateY(-2px)",
                  shadow: "lg",
                  opacity: 0.95,
                }}
                _active={{ transform: "translateY(0)" }}
                rounded="xl"
                rightIcon={<FaArrowRight />}
                transition="all 0.2s"
              >
                Continue
              </Button>
            </FormControl>
          </VStack>
        </VStack>
      </Container>
    </Box>
  );
};

export default CreateGenderRangePage;
