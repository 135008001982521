import {
  Container,
  Flex,
  Box,
  VStack,
  Text,
  Avatar,
  Button,
  IconButton,
  Divider,
  HStack,
  Stack,
  Icon,
} from "@chakra-ui/react";
import { ArrowBackIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import { FaBook, FaCog, FaQuestionCircle, FaLightbulb } from "react-icons/fa"; // Icons for sections
import useAuthStore from "../../store/authStore";
import useUserProfileStore from "../../store/userProfileStore";

const ProfilePage = () => {
  const navigate = useNavigate();
  const authUser = useAuthStore((state) => state.user);
  const { userProfile, setUserProfile } = useUserProfileStore();

  const handleRowClick = (section) => {
    // console.log(`Navigating to ${section}`);
    // Add navigation logic here

    if (section == "Settings") {
      navigate("/settings/");
    }
  };

  return (
    <Container
      maxW="container.sm"
      h="100vh"
      px={4}
      py={4}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      bg="gray.900" /* Dark background */
      color="white" /* Light text */
    >
      {/* Back Button */}
      <Flex justifyContent="flex-start" mb={4}>
        <IconButton
          icon={<ArrowBackIcon boxSize={6} />}
          variant="unstyled"
          aria-label="Go back"
          color="gray.400"
          onClick={() => navigate(-1)}
        />
      </Flex>

      {/* Profile Section */}
      <VStack spacing={4} align="center">
        {/* Profile Picture */}
        <Avatar
          size="2xl"
          name={userProfile?.fullName}
          src={authUser?.profilePicURL}
          border="4px solid orange"
        />

        {/* Profile Name */}
        <Text fontSize="2xl" fontWeight="bold">
          {userProfile?.fullName}
        </Text>

        {/* Edit Profile Button */}
        <Button
          variant="solid"
          colorScheme="gray"
          bg="gray.700"
          size="md"
          onClick={() => {
            // console.log("Edit Profile");

            navigate("/edit_profile/");
          }}
        >
          Edit Profile
        </Button>
      </VStack>

      <Divider borderColor="gray.600" my={6} />

      {/* Cards for different sections */}
      <VStack spacing={4} align="stretch">
        {/* Your Bookings */}
        <Flex
          justify="space-between"
          align="center"
          py={4}
          px={4}
          bg="gray.800"
          borderRadius="md"
          cursor="pointer"
          _hover={{ bg: "gray.700", color: "orange.400" }}
          onClick={() => handleRowClick("Your Bookings")}
        >
          <HStack spacing={3}>
            <Icon as={FaBook} color="pink.400" boxSize={6} />
            <VStack align="flex-start" spacing={0}>
              <Text fontSize="md" fontWeight="bold">
                Your ticket
              </Text>
              <Text fontSize="sm" color="gray.400">
                Find your invitations tickets
              </Text>
            </VStack>
          </HStack>
          <ChevronRightIcon color="gray.400" />
        </Flex>

        {/* Settings */}
        <Flex
          justify="space-between"
          align="center"
          py={4}
          px={4}
          bg="gray.800"
          borderRadius="md"
          cursor="pointer"
          _hover={{ bg: "gray.700", color: "orange.400" }}
          onClick={() => handleRowClick("Settings")}
        >
          <HStack spacing={3}>
            <Icon as={FaCog} color="orange.400" boxSize={6} />
            <VStack align="flex-start" spacing={0}>
              <Text fontSize="md" fontWeight="bold">
                Settings
              </Text>
              <Text fontSize="sm" color="gray.400">
                Manage your notifications, language, and subscription
              </Text>
            </VStack>
          </HStack>
          <ChevronRightIcon color="gray.400" />
        </Flex>

        {/* Help Center */}
        <Flex
          justify="space-between"
          align="center"
          py={4}
          px={4}
          bg="gray.800"
          borderRadius="md"
          cursor="pointer"
          _hover={{ bg: "gray.700", color: "orange.400" }}
          onClick={() => handleRowClick("Help Center")}
        >
          <HStack spacing={3}>
            <Icon as={FaQuestionCircle} color="green.400" boxSize={6} />
            <VStack align="flex-start" spacing={0}>
              <Text fontSize="md" fontWeight="bold">
                Help Center
              </Text>
              <Text fontSize="sm" color="gray.400">
                Find our FAQ
              </Text>
            </VStack>
          </HStack>
          <ChevronRightIcon color="gray.400" />
        </Flex>

        {/* Guide */}
        <Flex
          flexDirection="column"
          py={4}
          px={4}
          bg="gray.800"
          borderRadius="md"
          textAlign="center"
        >
          <Icon as={FaLightbulb} color="yellow.400" boxSize={8} mb={2} />
          <Text fontSize="md" fontWeight="bold" mb={2}>
            Guide
          </Text>
          <Text fontSize="sm" color="gray.400" mb={4}>
            Discover our 6 steps to talking to strangers and having
            unforgettable dinners.
          </Text>
          <Button
            variant="solid"
            colorScheme="orange"
            size="md"
            // onClick={() => console.log("Check it out")}
          >
            Check it out
          </Button>
        </Flex>
      </VStack>
    </Container>
  );
};

export default ProfilePage;
