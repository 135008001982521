import {
  Container,
  Flex,
  VStack,
  FormControl,
  FormLabel,
  Switch,
  Text,
  IconButton,
  Box,
  Button,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { doc, updateDoc } from "firebase/firestore";
import { firestore } from "../../firebase/firebase";
import useAuthStore from "../../store/authStore";
import useGetUserProfileById from "../../hooks/useGetUserProfileById";

const InvitationAlertsPage = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const authUser = useAuthStore((state) => state.user);
  const { userProfile } = useGetUserProfileById(authUser?.uid);

  const [emailNotification, setEmailNotification] = useState(false);
  const [pushNotification, setPushNotification] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const colors = {
    textColor: "gray.900",
    bgColor: "white",
    cardBg: "white",
    dividerColor: "gray.200",
    iconBgColor: "rgba(184, 160, 128, 0.1)",
    hoverBgColor: "rgba(184, 160, 128, 0.05)",
    subtitleColor: "gray.600",
    accentColor: "#B8A080",
    borderColor: "rgba(184, 160, 128, 0.2)",
  };

  useEffect(() => {
    if (userProfile) {
      setEmailNotification(userProfile?.hasEmailNotificationInvitation || false);
      setPushNotification(userProfile?.hasPushNotificationInvitation || false);
    }
  }, [userProfile]);

  const handleUpdateSettings = async () => {
    try {
      setIsUpdating(true);
      const userRef = doc(firestore, "users", authUser?.uid);
      await updateDoc(userRef, {
        hasEmailNotificationInvitation: emailNotification,
        hasPushNotificationInvitation: pushNotification,
      });
      toast({
        title: "Settings updated.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Update failed.",
        description: error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <Box
      h="100vh"
      maxH="100vh"
      overflow="hidden"
      position="relative"
      bg={colors.bgColor}
      pb="env(safe-area-inset-bottom)"
    >
      <Box
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        overflowY="auto"
        pb={{ base: 32, md: 8 }}
        style={{
          WebkitOverflowScrolling: 'touch'
        }}
      >
        <Container maxW={{ base: "container.md", md: "container.xl" }} px={4} py={6}>
          {/* Header */}
          <Flex 
            w="full" 
            alignItems="center" 
            mb={8}
            position="relative"
          >
            <IconButton
              icon={<ArrowBackIcon />}
              variant="ghost"
              aria-label="Go back"
              onClick={() => navigate(-1)}
              position={{ base: "relative", md: "absolute" }}
              left={{ md: 0 }}
              color={colors.accentColor}
              _hover={{
                bg: colors.iconBgColor,
                transform: "translateY(-2px)",
              }}
              transition="all 0.2s"
            />
            <Text 
              fontSize={{ base: "2xl", md: "3xl" }}
              fontWeight="light"
              color={colors.textColor}
              textAlign={{ base: "left", md: "center" }}
              flex="1"
              ml={{ base: 4, md: 0 }}
              letterSpacing="wide"
              fontFamily="Montserrat, sans-serif"
            >
              Invitation Alerts
            </Text>
          </Flex>

          {/* Main Content */}
          <Box 
            maxW={{ base: "full", md: "2xl" }} 
            mx="auto"
          >
            {/* Info Section */}
            <Box
              bg={colors.iconBgColor}
              borderRadius="xl"
              p={6}
              mb={8}
            >
              <Text 
                fontSize="sm" 
                color={colors.textColor} 
                textAlign="center"
                fontFamily="Montserrat, sans-serif"
                letterSpacing="wide"
              >
                Manage your notification preferences for event invitations.
              </Text>
            </Box>

            {/* Notification Toggles */}
            <VStack spacing={4} mb={8}>
              <Box
                w="full"
                bg={colors.cardBg}
                borderRadius="xl"
                borderWidth="1px"
                borderColor={colors.borderColor}
                p={6}
                shadow="sm"
                _hover={{ shadow: "md" }}
                transition="all 0.2s"
              >
                <FormControl display="flex" alignItems="center">
                  <FormLabel 
                    fontSize="md" 
                    mb={0} 
                    flex="1"
                    fontFamily="Montserrat, sans-serif"
                    fontWeight="medium"
                    color={colors.textColor}
                  >
                    Email Notifications
                  </FormLabel>
                  <Switch
                    colorScheme="orange"
                    size="lg"
                    isChecked={emailNotification}
                    onChange={(e) => setEmailNotification(e.target.checked)}
                  />
                </FormControl>
              </Box>

              <Box
                w="full"
                bg={colors.cardBg}
                borderRadius="xl"
                borderWidth="1px"
                borderColor={colors.borderColor}
                p={6}
                shadow="sm"
                _hover={{ shadow: "md" }}
                transition="all 0.2s"
              >
                <FormControl display="flex" alignItems="center">
                  <FormLabel 
                    fontSize="md" 
                    mb={0} 
                    flex="1"
                    fontFamily="Montserrat, sans-serif"
                    fontWeight="medium"
                    color={colors.textColor}
                  >
                    Push Notifications
                  </FormLabel>
                  <Switch
                    colorScheme="orange"
                    size="lg"
                    isChecked={pushNotification}
                    onChange={(e) => setPushNotification(e.target.checked)}
                  />
                </FormControl>
              </Box>
            </VStack>

            {/* Update Button */}
            <Button
              w="full"
              maxW="400px"
              mx="auto"
              size="lg"
              bg="black"
              color="white"
              isLoading={isUpdating}
              onClick={handleUpdateSettings}
              borderRadius="full"
              display="block"
              h={14}
              fontSize="md"
              fontWeight="medium"
              letterSpacing="wide"
              fontFamily="Montserrat, sans-serif"
              _hover={{
                transform: "translateY(-2px)",
                shadow: "lg",
                opacity: 0.9,
              }}
              _active={{
                transform: "translateY(0)",
                opacity: 0.8,
              }}
              transition="all 0.2s"
            >
              Update Settings
            </Button>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default InvitationAlertsPage;
  