import {
  Container,
  Flex,
  Box,
  VStack,
  Skeleton,
  HStack,
  IconButton,
  Text,
  Avatar,
  SkeletonCircle,
  Image,
  Divider,
  Link,
  Spacer,
  useColorModeValue,
} from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { FaMapMarkerAlt, FaCalendarAlt } from "react-icons/fa";
import { useParams, useNavigate } from "react-router-dom";
import useShowToast from "../../hooks/useShowToast";
import useGetEventData from "../../hooks/useGetEventData";
import useAuthStore from "../../store/authStore";
import useFetchTicket from "../../hooks/useFetchTicket";
import { useEffect } from "react";

const TicketEventPage = () => {
  const { eventId } = useParams();
  const { data: event, loading: isLoading, error } = useGetEventData(eventId);
  const authUser = useAuthStore((state) => state.user);
  const navigate = useNavigate();
  const { ticket } = useFetchTicket("", eventId);

  const colors = {
    textColor: useColorModeValue("gray.800", "whiteAlpha.900"),
    accentColor: useColorModeValue("blue.500", "blue.300"),
    bgColor: useColorModeValue("white", "gray.900"),
    mutedTextColor: useColorModeValue("gray.600", "gray.400"),
    cardBgColor: useColorModeValue("gray.50", "gray.800"),
    dividerColor: useColorModeValue("gray.200", "gray.600"),
  };

  const addEventToCalendar = () => {
    // function to handle adding event to calendar...
  };

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };

  if (error) return <PageNotFound />;
  if (isLoading) return <PageHeaderSkeleton />;

  return (
    <Box
      h="100vh"
      maxH="100vh"
      overflow="hidden"
      position="relative"
      bg={colors.bgColor}
      pb="env(safe-area-inset-bottom)"
    >
      <Box
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        overflowY="auto"
        pb={{ base: 32, md: 8 }}
        style={{
          WebkitOverflowScrolling: 'touch'
        }}
      >
        <Container 
          maxW={{ base: "container.md", md: "container.xl" }} 
          px={4} 
          py={6}
          mb={{ base: 8, md: 0 }}
        >
          {/* Header */}
          <Flex 
            w="full" 
            alignItems="center" 
            mb={6}
            position="relative"
          >
            {/* Back button - Only show on mobile */}
            <IconButton
              icon={<ArrowBackIcon />}
              variant="ghost"
              aria-label="Go back"
              onClick={() => navigate(-1)}
              display={{ base: "flex", md: "none" }}
              color={colors.accentColor}
            />
            <Text 
              fontSize={{ base: "xl", md: "2xl" }}
              fontWeight="bold"
              color={colors.textColor}
              textAlign={{ base: "left", md: "center" }}
              flex="1"
              ml={{ base: 4, md: 0 }}
            >
              Ticket Details
            </Text>
          </Flex>

          {/* Main Content */}
          <Box 
            maxW={{ base: "full", md: "lg" }} 
            mx="auto"
          >
            {/* Ticket Card */}
            <Box
              bg={colors.cardBgColor}
              borderRadius="xl"
              borderWidth="1px"
              borderColor={colors.dividerColor}
              p={{ base: 6, md: 8 }}
              mb={6}
              shadow="sm"
            >
              {/* QR Code */}
              <Flex justify="center" mb={6}>
                <Image
                  src="/qrcode_dummy.png"
                  alt="QR Code"
                  boxSize={{ base: "120px", md: "150px" }}
                  borderRadius="lg"
                />
              </Flex>

              <Divider borderColor={colors.dividerColor} mb={6} />

              {/* User Info */}
              <VStack align="start" spacing={6}>
                <HStack spacing={4} w="full">
                  <Avatar size={{ base: "lg", md: "xl" }} src="https://firebasestorage.googleapis.com/v0/b/hubzo-app.appspot.com/o/profilePics%2F7ba11f69-157f-4266-9d40-ed98b53a2fc5?alt=media&token=299699ef-5871-44d1-8bf3-a5bd5df0ba4c" />
                  <VStack align="start" spacing={1}>
                    <Text fontSize="xs" color={colors.mutedTextColor}>
                      Name
                    </Text>
                    <Text fontSize={{ base: "lg", md: "xl" }} fontWeight="bold" color={colors.textColor}>
                    Sarah Jones
                    </Text>
                    {ticket?.guestCount > 0 && (
                      <Text fontSize="sm" color={colors.mutedTextColor}>
                        +{ticket.guestCount} guests
                      </Text>
                    )}
                  </VStack>
                </HStack>

                <Box w="full">
                  <Text fontSize="xs" color={colors.mutedTextColor}>
                    Event
                  </Text>
                  <Text fontSize={{ base: "md", md: "lg" }} color={colors.textColor}>
                    {event.title} @ {event.placeName}
                  </Text>
                </Box>
              </VStack>

              <Divider borderColor={colors.dividerColor} my={6} />

              {/* Event Details */}
              <HStack justify="space-between" align="start" spacing={6}>
                <VStack align="start" spacing={2}>
                  <HStack>
                    <FaCalendarAlt color={colors.mutedTextColor} />
                    <Text fontSize="xs" color={colors.mutedTextColor}>
                      Date
                    </Text>
                  </HStack>
                  <Text fontSize="sm" color={colors.textColor}>
                    {event.formattedEventDate}
                  </Text>
                  <Link
                    fontSize="sm"
                    color={colors.accentColor}
                    onClick={addEventToCalendar}
                    _hover={{ textDecoration: "underline" }}
                  >
                    Add to calendar
                  </Link>
                </VStack>

                <VStack align="start" spacing={2}>
                  <HStack>
                    <FaMapMarkerAlt color={colors.mutedTextColor} />
                    <Text fontSize="xs" color={colors.mutedTextColor}>
                      Location
                    </Text>
                  </HStack>
                  <Text fontSize="sm" color={colors.textColor}>
                    {event.address}
                  </Text>
                  <Link
                    fontSize="sm"
                    color={colors.accentColor}
                    onClick={() => openInNewTab(event.placeLocation)}
                    _hover={{ textDecoration: "underline" }}
                  >
                    See map
                  </Link>
                </VStack>
              </HStack>
            </Box>

            {/* Instructions */}
            <Box textAlign="center" p={4}>
              <Text fontSize="sm" color={colors.mutedTextColor}>
                Present this ticket at the venue to gain access. Make sure to arrive on time!
              </Text>
            </Box>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default TicketEventPage;

const PageHeaderSkeleton = () => {
  return (
    <Flex
      gap={{ base: 4, sm: 10 }}
      py={10}
      direction={{ base: "column", sm: "row" }}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <SkeletonCircle size="24" />
      <VStack alignItems="center" gap={2}>
        <Skeleton height="12px" width="150px" />
        <Skeleton height="12px" width="100px" />
      </VStack>
    </Flex>
  );
};
