import { useState } from "react";
import useAuthStore from "../store/authStore";
import useShowToast from "./useShowToast";
import { arrayRemove, arrayUnion, doc, updateDoc } from "firebase/firestore";
import { firestore } from "../firebase/firebase";

const useAdminGroup = (group, groupId, newMemberId) => {
  const [isUpdating, setIsUpdating] = useState(false);
  const authUser = useAuthStore((state) => state.user);


  // if (group == null) {
  //   return
  // }

  const [adminList, setAdminList] = useState(group.admins ? group.admins : []);

  // const newMember = authUser
  //   ? {
  //       fullName: authUser.fullName,
  //       phoneNumber: authUser.phoneNumber,
  //       profilePicture: authUser.profilePicURL,
  //       username: authUser.username,
  //       uid: authUser.uid,
  //       state: "unverified",
  //     }
  //   : {
  //       fullName: "",
  //       phoneNumber: "",
  //       profilePicture: "",
  //       username: "",
  //       uid: "",
  //       state: "",
  //     };

  // const [isMember, setIsMember] = useState(
  //   group.memberList ?  group.memberList.some((item) => item.username === newMember.username) : false
  // );
  const showToast = useShowToast();

  const handleAdminGroup = async () => {
    if (isUpdating) return;
    if (!authUser) return showToast("Error", "You must be logged in", "error");
    setIsUpdating(true);

    try {
      const groupRef = doc(firestore, "groups", groupId);
      await updateDoc(groupRef, {
        admins: arrayUnion(newMemberId),
      });

      // setIsMember(!isMember);
      // isMember
      //   ? setMemberList(
      //       memberList.filter((item) => item.username !== newMember.username)
      //     )
      //   : setMemberList([...memberList, newMember]);

      showToast("", "Updated successfully", "success");
    } catch (error) {
      showToast("Error", error.message, "error");
    } finally {
      setIsUpdating(false);
    }
  };

  // const handleMemberGroupz = async () => {
  //   if (isUpdating) return;
  //   if (!authUser) return showToast("Error", "You must be logged in", "error");
  //   setIsUpdating(true);

  //   try {
  //     const groupRef = doc(firestore, "groups", groupId);
  //     await updateDoc(groupRef, {
  //       memberList: arrayRemove(newMember),
  //     });

  //     setIsMember(!isMember);
  //     isMember
  //       ? setMemberList(
  //           memberList.filter((item) => item.username !== newMember.username)
  //         )
  //       : setMemberList([...memberList, newMember]);
  //   } catch (error) {
  //     showToast("Error", error.message, "error");
  //   } finally {
  //     setIsUpdating(false);
  //   }
  // };

  return { 
    // isMember, 
    // memberList, 
    handleAdminGroup, isUpdating };
};

export default useAdminGroup;
