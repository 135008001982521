import {
  Container,
  Flex,
  Text,
  Box,
  Divider,
  Stack,
  HStack,
  VStack,
  IconButton,
  Progress,
  SkeletonText,
  Tooltip,
} from "@chakra-ui/react";
import { ChevronRightIcon, ArrowBackIcon } from "@chakra-ui/icons";
import {
  FaCheckCircle,
  FaExclamationCircle,
  FaUserShield,
  FaHeart,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import useAuthStore from "../../store/authStore";
import useGetUserProfileById from "../../hooks/useGetUserProfileById";

const MilestonePage = () => {
  const navigate = useNavigate();
  const authUser = useAuthStore((state) => state.user);
  const { isLoading, userProfile } = useGetUserProfileById(authUser?.uid);

  const colors = {
    textColor: "gray.900",
    bgColor: "white",
    cardBg: "white",
    dividerColor: "gray.200",
    iconBgColor: "rgba(184, 160, 128, 0.1)",
    hoverBgColor: "rgba(184, 160, 128, 0.05)",
    subtitleColor: "gray.600",
    accentColor: "#B8A080",
    borderColor: "rgba(184, 160, 128, 0.2)",
    highlightColor: "#B8A080",
    alertColor: "orange.400",
  };

  const profileFields = [
    {
      field: "instagram",
      label: "Instagram",
      icon: <FaUserShield />,
      description: "Connect your Instagram to enhance profile visibility.",
      highlight: true,
    },
    {
      field: "linkedin",
      label: "LinkedIn",
      icon: <FaUserShield />,
      description: "Adding LinkedIn improves professional connections.",
      highlight: true,
    },
    {
      field: "interests",
      label: "Interests",
      icon: <FaHeart />,
      description: "Add your interests to find more personalized events.",
      highlight: true,
    },
    ...(!userProfile?.isCreator
      ? [
          {
            field: "job",
            label: "Job",
            icon: <FaUserShield />,
            description: "Add your profession to match with similar professionals.",
          },
          {
            field: "yob",
            label: "Year of Birth",
            icon: <FaUserShield />,
            description: "Help us find age-appropriate events for you.",
          },
        ]
      : []),
  ];

  const completedFields = profileFields.filter(
    ({ field }) => userProfile && userProfile[field]
  ).length;
  const totalFields = profileFields.length;
  const completionRate = Math.round((completedFields / totalFields) * 100);

  return (
    <Box
      h="100vh"
      maxH="100vh"
      overflow="hidden"
      position="relative"
      bg={colors.bgColor}
      pb="env(safe-area-inset-bottom)"
    >
      <Box
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        overflowY="auto"
        pb={{ base: 32, md: 8 }}
        style={{
          WebkitOverflowScrolling: 'touch'
        }}
      >
        <Container 
          maxW={{ base: "container.md", md: "container.xl" }} 
          px={4} 
          py={6}
          mb={{ base: 8, md: 0 }}
        >
          {/* Header */}
          <Flex 
            w="full" 
            alignItems="center" 
            mb={8}
            position="relative"
          >
            <IconButton
              icon={<ArrowBackIcon />}
              variant="ghost"
              aria-label="Go back"
              onClick={() => navigate(-1)}
              display={{ base: "flex", md: "none" }}
              color={colors.accentColor}
              _hover={{
                bg: colors.iconBgColor,
                transform: "translateY(-2px)",
              }}
              transition="all 0.2s"
            />
            <Text 
              fontSize={{ base: "2xl", md: "3xl" }}
              fontWeight="light"
              color={colors.textColor}
              textAlign={{ base: "left", md: "center" }}
              flex="1"
              ml={{ base: 4, md: 0 }}
              letterSpacing="wide"
              fontFamily="Montserrat, sans-serif"
            >
              Complete Your Profile
            </Text>
          </Flex>

          {/* Main Content */}
          <Box 
            maxW={{ base: "full", md: "2xl" }} 
            mx="auto"
          >
            {/* Progress Section */}
            <Box
              bg={colors.cardBg}
              borderRadius="xl"
              borderWidth="1px"
              borderColor={colors.borderColor}
              p={6}
              mb={8}
              shadow="sm"
              _hover={{ shadow: "md" }}
              transition="all 0.2s"
            >
              <Text 
                fontSize={{ base: "md", md: "lg" }} 
                fontWeight="medium"
                color={colors.textColor} 
                mb={4}
                fontFamily="Montserrat, sans-serif"
                letterSpacing="wide"
              >
                Completion Progress: {completionRate}%
              </Text>
              <Progress
                value={completionRate}
                colorScheme="orange"
                size="sm"
                borderRadius="full"
                bg={colors.iconBgColor}
              />
            </Box>

            {/* Profile Fields */}
            <Stack
              spacing={4}
              borderRadius="xl"
              overflow="hidden"
              borderWidth="1px"
              borderColor={colors.borderColor}
              bg={colors.cardBg}
              shadow="sm"
              _hover={{ shadow: "md" }}
              transition="all 0.2s"
            >
              {isLoading ? (
                <SkeletonText noOfLines={5} spacing={4} p={4} />
              ) : (
                profileFields.map(({ field, label, icon, description, highlight }) => (
                  <Box
                    key={field}
                    bg={colors.cardBg}
                    cursor="pointer"
                    onClick={() => navigate(`/update_${field}`)}
                    _hover={{ 
                      bg: colors.iconBgColor,
                      transform: "translateY(-1px)",
                    }}
                    transition="all 0.2s"
                  >
                    <VStack align="start" spacing={2} p={4}>
                      <HStack spacing={4} w="full" justify="space-between">
                        <HStack spacing={4}>
                          <Box 
                            p={3}
                            bg={colors.iconBgColor}
                            borderRadius="full"
                            color={colors.accentColor}
                          >
                            {icon}
                          </Box>
                          <Box>
                            <Text
                              fontWeight="medium"
                              fontSize={{ base: "md", md: "lg" }}
                              color={colors.textColor}
                              fontFamily="Montserrat, sans-serif"
                            >
                              {label}
                            </Text>
                            <Text 
                              fontSize={{ base: "sm", md: "md" }}
                              color={colors.subtitleColor}
                            >
                              {userProfile?.[field] ? "Completed" : "Not provided"}
                            </Text>
                          </Box>
                        </HStack>
                        <Box color={userProfile?.[field] ? "green.500" : colors.alertColor}>
                          {userProfile?.[field] ? (
                            <FaCheckCircle size={20} />
                          ) : (
                            <FaExclamationCircle size={20} />
                          )}
                        </Box>
                      </HStack>
                      {highlight && !userProfile?.[field] && (
                        <Text 
                          fontSize={{ base: "xs", md: "sm" }}
                          color={colors.subtitleColor}
                          pl={12}
                          fontFamily="Montserrat, sans-serif"
                          letterSpacing="wide"
                        >
                          {description}
                        </Text>
                      )}
                    </VStack>
                  </Box>
                ))
              )}
            </Stack>

            {/* Footer Note */}
            <Box 
              textAlign="center" 
              mt={8} 
              mb={8}
              bg={colors.iconBgColor}
              p={4}
              rounded="xl"
            >
              <Text 
                fontSize="sm" 
                color={colors.textColor}
                fontFamily="Montserrat, sans-serif"
                letterSpacing="wide"
              >
                Complete your profile for a better experience and unlock full features!
              </Text>
            </Box>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default MilestonePage;
