import { Navigate, Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage/HomePage";
import AuthPage from "./pages/AuthPage/AuthPage";
import OnboardingSignUpPage from "./pages/AuthPage/OnboardingSignUpPage";
import SignUpPage from "./pages/AuthPage/SignUpPage";
import ChooseCityPage from "./pages/AuthPage/ChooseCityPage";
import UpdateCityPage from "./pages/AuthPage/UpdateCityPage";
import ChooseDobPage from "./pages/AuthPage/ChooseDobPage";
import ChooseJobPage from "./pages/AuthPage/ChooseJobPage";
import UpdateJobPage from "./pages/AuthPage/UpdateJobPage";

import ChooseNamePage from "./pages/AuthPage/ChooseNamePage";
import CreateTitlePage from "./pages/AuthPage/CreateTitlePage";
import CreateDescriptionPage from "./pages/AuthPage/CreateDescriptionPage";
import CreateLocationPage from "./pages/AuthPage/CreateLocationPage";
import CreateEventPicturePage from "./pages/AuthPage/CreateEventPicturePage";
import CreateEventDatePage from "./pages/AuthPage/CreateEventDatePage";
import CreateEventPage from "./pages/AuthPage/CreateEventPage";
import CreateAgeRangePage from "./pages/AuthPage/CreateAgeRangePage";
import CreateCapacityPage from "./pages/AuthPage/CreateCapacityPage";
import CreateGenderRangePage from "./pages/AuthPage/CreateGenderRangePage";
import CreateUserProfileScorePage from "./pages/AuthPage/CreateUserProfileScorePage";
import CreateEventPostPage from "./pages/AuthPage/CreateEventPostPage";
import UpdateNamePage from "./pages/AuthPage/UpdateNamePage";
import ChooseInstagramPage from "./pages/AuthPage/ChooseInstagramPage";
import UpdateInterestsPage from "./pages/AuthPage/UpdateInterestsPage";
import UpdateInstagramPage from "./pages/AuthPage/UpdateInstagramPage";
import UpdateDobPage from "./pages/AuthPage/UpdateDobPage";

import ChooseLinkedinPage from "./pages/AuthPage/ChooseLinkedinPage";
import UpdateLinkedinPage from "./pages/AuthPage/UpdateLinkedinPage";
import ChooseEmailPage from "./pages/AuthPage/ChooseEmailPage";
import ChooseEmailPasswordPage from "./pages/AuthPage/ChooseEmailPasswordPage";
import ForgotPasswordPage from "./pages/AuthPage/ForgotPasswordPage";
import ChooseFacePicPage from "./pages/AuthPage/ChooseFacePicPage";
import UpdateFacePicPage from "./pages/AuthPage/UpdateFacePicPage";
import PageLayout from "./Layouts/PageLayout/PageLayout";
import ProfilePage from "./pages/ProfilePage/ProfilePage";
import UserProfilePage from "./pages/ProfilePage/UserProfilePage";
import ProfileViewPage from "./pages/ProfilePage/ProfileViewPage";
import TicketsPage from "./pages/ProfilePage/TicketsPage";
import EditProfilePage from "./pages/ProfilePage/EditProfilePage";
import InstagramVerificationPage from "./pages/ProfilePage/InstagramVerificationPage";
import LinkedinLinkPage from "./pages/ProfilePage/LinkedinLinkPage";
import MilestonePage from "./pages/ProfilePage/MilestonePage";
import ProfileStrengthPage from "./pages/ProfilePage/ProfileStrengthPage";
import ValidatePromoCodePage from "./pages/ProfilePage/ValidatePromoCodePage";
import InvitationAlertsPage from "./pages/ProfilePage/InvitationAlertsPage";
import MarketingPreferencesPage from "./pages/ProfilePage/MarketingPreferencesPage";
import EventParticipationPage from "./pages/ProfilePage/EventParticipationPage";
import UserListPage from "./pages/ProfilePage/UserListPage";
import SwipePage from "./pages/SwipePage";
import DeleteAccountPage from "./pages/ProfilePage/DeleteAccountPage";
import SupportAccountPage from "./pages/ProfilePage/SupportAccountPage";
import GroupPage from "./pages/ProfilePage/GroupPage";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "./firebase/firebase";
import EventPage from "./pages/EventPage/EventPage";
import GroupEventPage from "./pages/GroupEventPage/GroupEventPage";
import InviteEventPage from "./pages/InviteEventPage/";

import TicketEventPage from "./pages/GroupEventPage/TicketEventPage";
import CreatePage from "./pages/CreatePage/CreatePage";
import CreateGroupPage from "./pages/CreateGroupPage/CreateGroupPage";
import SearchPage from "./pages/SearchPage/SearchPage";
import GroupsPageLink from "./components/Sidebar/GroupsPageLink";
import GroupsPage from "./pages/GroupsPage/GroupsPage";
import InvitationsPage from "./pages/InvitationsPage";
import MembersPage from "./pages/GroupsPage/MembersPage";
import AttendingPage from "./pages/GroupsPage/AttendingPage";
import useAuthStore from "./store/authStore";
import MemoriesPage from "./pages/Memories/MemoriesPage";
import SettingsPage from "./pages/ProfilePage/SettingsPage";
import ChooseInterestsPage from "./pages/AuthPage/ChooseInterestsPage";
import LandingPage from "./pages/LandingPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import SafetyTipsPage from "./pages/SafetyTipsPage";
import AboutUs from "./pages/AboutUs";
import ContactPage from "./pages/ContactPage";
import FAQPage from "./pages/FAQPage";
import NotificationsPage from "./pages/NotificationsPage";
import ViewUserProfilePage from "./pages/ProfilePage/ViewUserProfilePage";
import GroupsListPage from "./pages/GroupsListPage/GroupsListPage";
import GroupDetailsPage from './pages/GroupDetailsPage/GroupDetailsPage';

function App() {
  // const [authUser] = useAuthState(auth);
  const authUser = useAuthStore((state) => state.user);

  // componentDidMount() {

  //   window.addEventListener('beforeinstallprompt',e =>{
  //     // For older browsers
  //     e.preventDefault();
  //     console.log("Install Prompt fired");

  //     this.installPrompt = e;
  //     // See if the app is already installed, in that case, do nothing
  //     if((window.matchMedia && window.matchMedia('(display-mode: standalone)').matches) || window.navigator.standalone === true){
  //       return false;
  //     }
  //     // Set the state variable to make button visible
  //     this.setState({
  //       isModalOpen:true
  //     })
  //   })

  // }

  return (
    <PageLayout>
      <Routes>
        <Route
          path="/"
          element={authUser ? <InvitationsPage /> : <LandingPage />}
        />
        <Route
          path="/invitations/"
          element={authUser ? <InvitationsPage /> : <Navigate to="/" />}
        />
        {/* <Route path='/' element={<HomePage />} /> */}
        {/* <Route path="/" element={<GroupsPage />} /> */}
        {/* <Route path='/auth' element={!authUser ? <AuthPage /> : <Navigate to={`/x/${authUser.username}`} />} />
				<Route path='/u/undefined' element={!authUser ? <AuthPage /> : <Navigate to={`/x/${authUser.username}`} />} /> */}
        <Route
          path="/sign_in"
          element={!authUser ? <AuthPage /> : <Navigate to="/" />}
        />

        <Route
          path="/onboarding_sign_up"
          element={
            !authUser ? <OnboardingSignUpPage /> : <Navigate to="/sign_up" />
          }
        />

        <Route
          path="/sign_up"
          element={!authUser ? <SignUpPage /> : <Navigate to="/" />}
        />

        <Route
          path="/choose_city"
          element={!authUser ? <ChooseCityPage /> : <Navigate to="/" />}
        />

        <Route
          path="/choose_dob"
          element={!authUser ? <ChooseDobPage /> : <Navigate to="/" />}
        />

        <Route
          path="/choose_job"
          element={!authUser ? <ChooseJobPage /> : <Navigate to="/" />}
        />

        <Route
          path="/update_job"
          element={authUser ? <UpdateJobPage /> : <Navigate to="/" />}
        />

        <Route
          path="/profile_score"
          element={authUser ? <ProfileStrengthPage /> : <Navigate to="/" />}
        />

        <Route
          path="/promotions"
          element={authUser ? <ValidatePromoCodePage /> : <Navigate to="/" />}
        />

        <Route
          path="/invitations_alert"
          element={authUser ? <InvitationAlertsPage /> : <Navigate to="/" />}
        />

        <Route
          path="/marketing_alert"
          element={
            authUser ? <MarketingPreferencesPage /> : <Navigate to="/" />
          }
        />

        <Route
          path="/event_participation"
          element={authUser ? <EventParticipationPage /> : <Navigate to="/" />}
        />

        <Route
          path="/users_list"
          element={authUser ? <UserListPage /> : <Navigate to="/" />}
        />

        <Route
          path="/choose_name"
          element={!authUser ? <ChooseNamePage /> : <Navigate to="/" />}
        />

        <Route
          path="/create_event_title"
          element={authUser ? <CreateTitlePage /> : <Navigate to="/" />}
        />

        <Route
          path="/swipe"
          element={authUser ? <SwipePage /> : <Navigate to="/" />}
        />

        <Route
          path="/create_event_description"
          element={authUser ? <CreateDescriptionPage /> : <Navigate to="/" />}
        />

        <Route
          path="/create_event_location"
          element={authUser ? <CreateLocationPage /> : <Navigate to="/" />}
        />

        <Route
          path="/create_event_picture"
          element={authUser ? <CreateEventPicturePage /> : <Navigate to="/" />}
        />

        <Route
          path="/create_event_date"
          element={authUser ? <CreateEventDatePage /> : <Navigate to="/" />}
        />

        <Route
          path="/create_event"
          element={authUser ? <CreateEventPage /> : <Navigate to="/" />}
        />

        <Route
          path="/create_age_range_event"
          element={authUser ? <CreateAgeRangePage /> : <Navigate to="/" />}
        />

        <Route
          path="/create_capacity_event"
          element={authUser ? <CreateCapacityPage /> : <Navigate to="/" />}
        />

        <Route
          path="/create_gender_range_event"
          element={authUser ? <CreateGenderRangePage /> : <Navigate to="/" />}
        />

        <Route
          path="/create_user_score_event"
          element={
            authUser ? <CreateUserProfileScorePage /> : <Navigate to="/" />
          }
        />

        <Route
          path="/create_invitations_event"
          element={authUser ? <CreateEventPostPage /> : <Navigate to="/" />}
        />

        <Route
          path="/update_name"
          element={authUser ? <UpdateNamePage /> : <Navigate to="/" />}
        />

        <Route
          path="/update_city"
          element={authUser ? <UpdateCityPage /> : <Navigate to="/" />}
        />

        <Route
          path="/choose_instagram"
          element={!authUser ? <ChooseInstagramPage /> : <Navigate to="/" />}
        />

        <Route
          path="/update_instagram"
          element={authUser ? <UpdateInstagramPage /> : <Navigate to="/" />}
        />

        <Route
          path="/update_yob"
          element={authUser ? <UpdateDobPage /> : <Navigate to="/" />}
        />

        <Route
          path="/choose_linkedin"
          element={!authUser ? <ChooseLinkedinPage /> : <Navigate to="/" />}
        />

        <Route
          path="/update_linkedin"
          element={authUser ? <UpdateLinkedinPage /> : <Navigate to="/" />}
        />

        <Route
          path="/choose_interests"
          element={!authUser ? <ChooseInterestsPage /> : <Navigate to="/" />}
        />

        <Route
          path="/update_interests"
          element={authUser ? <UpdateInterestsPage /> : <Navigate to="/" />}
        />

        <Route
          path="/email/"
          element={!authUser ? <ChooseEmailPage /> : <Navigate to="/" />}
        />

        <Route
          path="/email_password/"
          element={
            !authUser ? <ChooseEmailPasswordPage /> : <Navigate to="/" />
          }
        />

        <Route
          path="/forgot-password/"
          element={!authUser ? <ForgotPasswordPage /> : <Navigate to="/" />}
        />

        <Route
          path="/choose_face_pic"
          element={!authUser ? <ChooseFacePicPage /> : <Navigate to="/" />}
        />

        <Route
          path="/update_face_pic"
          element={authUser ? <UpdateFacePicPage /> : <Navigate to="/" />}
        />

        <Route
          path="/user_profile"
          element={authUser ? <UserProfilePage /> : <Navigate to="/" />}
        />

        <Route
          path="/profile"
          element={authUser ? <ProfileViewPage /> : <Navigate to="/" />}
        />

        <Route
          path="/user_tickets"
          element={authUser ? <TicketsPage /> : <Navigate to="/" />}
        />

        <Route
          path="/edit_profile"
          element={authUser ? <EditProfilePage /> : <Navigate to="/" />}
        />

        <Route
          path="/instagram_verification"
          element={
            authUser ? <InstagramVerificationPage /> : <Navigate to="/" />
          }
        />

        <Route
          path="/linkedin_verification"
          element={authUser ? <LinkedinLinkPage /> : <Navigate to="/" />}
        />

        <Route
          path="/milestone_profile"
          element={authUser ? <MilestonePage /> : <Navigate to="/" />}
        />

        <Route
          path="/settings/"
          element={authUser ? <SettingsPage /> : <Navigate to="/" />}
        />

        <Route
          path="/u/undefined"
          element={!authUser ? <AuthPage /> : <Navigate to="/" />}
        />
        <Route
          path="/:username"
          element={<GroupDetailsPage />}
        />
        <Route
          path="/e/:eventId/attending/"
          element={
            // !authUser ? <AuthPage /> :
            <AttendingPage />
          }
        />
        <Route
          path="/e/memories/"
          element={!authUser ? <AuthPage /> : <MemoriesPage />}
        />
        <Route path="/:postId" element={<GroupPage />} />
        <Route path="/:group/members/" element={<MembersPage />} />
        {/* <Route path='/e/:postId' element={<EventPage />} /> */}
        <Route path="/i/:postId" element={<InviteEventPage />} />

        <Route path="/t/:eventId" element={<TicketEventPage />} />

        <Route path="/c/:postId" element={<CreatePage />} />
        <Route path="/g/:postId" element={<CreateGroupPage />} />
        <Route path="/explore/" element={<SearchPage />} />
        {/* <Route path="/groups/" element={<SearchPage />} /> */}
        <Route path="/x/:username" element={<ProfilePage update={true} />} />
        <Route path="/delete-account" element={<DeleteAccountPage />} />
        <Route path="/support" element={<SupportAccountPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
        <Route path="/safety-tips" element={<SafetyTipsPage />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/faq" element={<FAQPage />} />
        <Route path="/notifications" element={<NotificationsPage />} />
        <Route path="/user/:userId" element={<ViewUserProfilePage />} />
        <Route path="/groups" element={<GroupsListPage />} />
        <Route path="/groups/:groupId" element={<GroupDetailsPage />} />
      </Routes>
    </PageLayout>
  );
}

export default App;
