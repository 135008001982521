import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { collection, query, where, getDocs } from 'firebase/firestore';
import {
  Box,
  Container,
  VStack,
  Flex,
  Text,
  Avatar,
  Badge,
  IconButton,
  Link,
  useColorModeValue,
  Skeleton,
  HStack,
  Button,
  SimpleGrid,
  useToast,
  Image,
  Icon,
} from "@chakra-ui/react";
import { FaInstagram, FaWhatsapp, FaFacebook, FaGlobe, FaArrowLeft, FaUserFriends, FaCalendarAlt } from 'react-icons/fa';
import { firestore } from "../../firebase/firebase";

const GroupDetailsPage = () => {
  const { username } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();
  
  // Color modes
  const bgColor = useColorModeValue("gray.50", "gray.900");
  const cardBgColor = useColorModeValue("white", "gray.800");
  const headerBgColor = useColorModeValue('rgba(255, 255, 255, 0.95)', 'rgba(26, 32, 44, 0.95)');
  const borderColor = useColorModeValue('gray.200', 'gray.700');
  const textColorSecondary = useColorModeValue('gray.600', 'gray.400');
  
  const [group, setGroup] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    const fetchGroup = async () => {
      try {
        const groupsRef = collection(firestore, 'groups');
        const q = query(groupsRef, where('username', '==', username));
        const querySnapshot = await getDocs(q);
        
        if (!querySnapshot.empty) {
          const groupDoc = querySnapshot.docs[0];
          setGroup({ id: groupDoc.id, ...groupDoc.data() });
        } else {
          toast({
            title: "Group not found",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
      } catch (error) {
        console.error('Error fetching group:', error);
        toast({
          title: "Error loading group",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setLoading(false);
      }
    };

    if (username) {
      fetchGroup();
    }
  }, [username, toast]);

  const handleBackClick = () => {
    navigate('/groups');
  };

  const renderSocialLinks = () => {
    if (!group) return null;

    return (
      <HStack spacing={3}>
        {group.website && (
          <Link href={group.website} isExternal>
            <IconButton
              icon={<FaGlobe size="20px" />}
              aria-label="Website"
              colorScheme="gray"
              variant="ghost"
              size="lg"
              _hover={{
                transform: 'translateY(-2px)',
                shadow: 'md',
              }}
              transition="all 0.2s"
            />
          </Link>
        )}
        {group.instagram && (
          <Link href={group.instagram} isExternal>
            <IconButton
              icon={<FaInstagram size="20px" />}
              aria-label="Instagram"
              colorScheme="pink"
              variant="ghost"
              size="lg"
              _hover={{
                transform: 'translateY(-2px)',
                shadow: 'md',
              }}
              transition="all 0.2s"
            />
          </Link>
        )}
        {group.whatsapp && (
          <Link href={group.whatsapp} isExternal>
            <IconButton
              icon={<FaWhatsapp size="20px" />}
              aria-label="WhatsApp"
              colorScheme="green"
              variant="ghost"
              size="lg"
              _hover={{
                transform: 'translateY(-2px)',
                shadow: 'md',
              }}
              transition="all 0.2s"
            />
          </Link>
        )}
        {group.facebook && (
          <Link href={group.facebook} isExternal>
            <IconButton
              icon={<FaFacebook size="20px" />}
              aria-label="Facebook"
              colorScheme="facebook"
              variant="ghost"
              size="lg"
              _hover={{
                transform: 'translateY(-2px)',
                shadow: 'md',
              }}
              transition="all 0.2s"
            />
          </Link>
        )}
      </HStack>
    );
  };

  const LoadingSkeleton = () => (
    <Box bg={bgColor} minH="100vh" py={8}>
      <Container maxW="container.lg">
        <VStack spacing={8} align="stretch">
          <Skeleton height="300px" borderRadius="xl" />
          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6}>
            <Skeleton height="200px" borderRadius="lg" />
            <Skeleton height="200px" borderRadius="lg" />
          </SimpleGrid>
        </VStack>
      </Container>
    </Box>
  );

  if (loading) return <LoadingSkeleton />;

  if (!group) {
    return (
      <Box bg={bgColor} minH="100vh" py={20}>
        <Container maxW="container.lg" textAlign="center">
          <VStack spacing={6}>
            <Text fontSize="2xl" fontWeight="bold">Group not found</Text>
            <Button 
              colorScheme="blue" 
              onClick={() => navigate('/groups')}
              leftIcon={<FaArrowLeft />}
            >
              Back to Groups
            </Button>
          </VStack>
        </Container>
      </Box>
    );
  }

  return (
    <Box bg={bgColor} minH="100vh">
      {/* Header */}
      <Box
        position="fixed"
        top={0}
        left={0}
        right={0}
        zIndex={10}
        bg={headerBgColor}
        backdropFilter="blur(10px)"
        borderBottom="1px solid"
        borderColor={borderColor}
        boxShadow="sm"
      >
        <Container maxW="container.lg" py={3}>
          <Flex align="center" gap={4}>
            <IconButton
              icon={<FaArrowLeft />}
              aria-label="Go back"
              variant="ghost"
              rounded="full"
              onClick={handleBackClick}
            />
            <Text fontSize="xl" fontWeight="bold" isTruncated>
              {group.fullName}
            </Text>
          </Flex>
        </Container>
      </Box>

      {/* Main Content */}
      <Box pt={{ base: '80px', md: '100px' }} px={4} pb={8}>
        <Container maxW="container.lg">
          <VStack spacing={8} align="stretch">
            {/* Cover Image */}
            {group.coverImage && (
              <Box
                position="relative"
                height={{ base: "200px", md: "300px" }}
                borderRadius="xl"
                overflow="hidden"
                boxShadow="xl"
              >
                <Image
                  src={group.coverImage}
                  alt={group.fullName}
                  objectFit="cover"
                  w="100%"
                  h="100%"
                />
              </Box>
            )}

            {/* Group Header */}
            <Flex 
              bg={cardBgColor}
              p={6}
              borderRadius="xl"
              shadow="sm"
              gap={6}
              direction={{ base: 'column', md: 'row' }}
              align={{ base: 'center', md: 'start' }}
              _hover={{ shadow: 'md' }}
              transition="all 0.2s"
            >
              <Avatar 
                size="2xl" 
                src={group.profilePicURL} 
                name={group.fullName}
                borderWidth={3}
                borderColor={borderColor}
              />
              <VStack align="start" flex={1} spacing={4}>
                <Box width="100%">
                  <Flex 
                    align="center" 
                    gap={3} 
                    mb={2}
                    wrap="wrap"
                  >
                    <Text fontSize="2xl" fontWeight="bold">
                      {group.fullName}
                    </Text>
                    {group.verified && (
                      <Badge colorScheme="blue" variant="subtle">
                        Verified
                      </Badge>
                    )}
                  </Flex>
                  <Text color={textColorSecondary}>@{group.username}</Text>
                </Box>
                
                <Badge 
                  colorScheme="purple" 
                  px={3} 
                  py={1} 
                  borderRadius="full"
                  textTransform="capitalize"
                >
                  {group.type}
                </Badge>

                <Box width="100%">
                  <Text 
                    color={textColorSecondary} 
                    whiteSpace="pre-wrap"
                    noOfLines={isExpanded ? undefined : 3}
                    mb={1}
                  >
                    {group.bio}
                  </Text>
                  {group.bio && group.bio.length > 150 && (
                    <Button
                      variant="link"
                      color="blue.500"
                      size="sm"
                      onClick={() => setIsExpanded(!isExpanded)}
                      _hover={{ textDecoration: 'none' }}
                    >
                      {isExpanded ? 'See Less' : 'See More'}
                    </Button>
                  )}
                </Box>

                {renderSocialLinks()}
              </VStack>
            </Flex>
          </VStack>
        </Container>
      </Box>
    </Box>
  );
};

export default GroupDetailsPage; 