import {
  Container,
  Flex,
  Button,
  VStack,
  FormControl,
  Input,
  Text,
  IconButton,
  Box,
  Progress,
} from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";

const CreateCapacityPage = () => {
  const navigate = useNavigate();
  const [capacity, setCapacity] = useState(
    localStorage.getItem("eventCapacity") || ""
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleCapacityChange = (e) => {
    const value = Math.min(1000, Math.max(0, Number(e.target.value)));
    setCapacity(value === 0 ? "" : value);
  };

  const handleNext = () => {
    localStorage.setItem(
      "eventCapacity",
      capacity === 1000 ? "1000+" : capacity
    );
    navigate("/create_gender_range_event");
  };

  return (
    <Box
      position="fixed"
      top={{ base: 0, md: "64px" }}
      left={0}
      right={0}
      bottom={0}
      bg="white"
      zIndex={1000}
      overflowY="auto"
    >
      <Container
        maxW="container.sm"
        py={4}
        display="flex"
        flexDirection="column"
        minH={{ base: "100vh", md: "calc(100vh - 64px)" }}
      >
        {/* Header with Progress - Visible on all screens */}
        <Flex w="full" align="center" mb={6}>
          <IconButton
            icon={<ArrowBackIcon />}
            variant="ghost"
            aria-label="Go back"
            onClick={() => navigate(-1)}
            color="gray.500"
            _hover={{ color: "#4F46E5" }}
          />
          <Progress
            value={75}
            size="sm"
            colorScheme="purple"
            flex="1"
            mx={4}
            borderRadius="full"
            bg="gray.100"
          />
        </Flex>

        {/* Main Content */}
        <VStack spacing={8} align="center" w="full">
          <VStack spacing={3} textAlign="center">
            <Box
              p={3}
              bg="purple.50"
              rounded="full"
              color="purple.600"
              fontSize="2xl"
              mb={2}
            >
              🎟️
            </Box>

            <Text
              fontSize={{ base: "2xl", md: "3xl" }}
              fontWeight="bold"
              // bgGradient="linear(to-r, #4F46E5, #7C3AED)"
              // bgClip="text"
              color="gray.900"
            >
              Ticket Allocation
            </Text>
            <Text color="gray.600" fontSize={{ base: "md", md: "lg" }}>
              How many people can attend?
            </Text>
          </VStack>

          <VStack spacing={6} w="full" maxW="400px">
            <FormControl>
              <Input
                type="text"
                inputMode="numeric"
                value={capacity}
                onChange={handleCapacityChange}
                placeholder="Number of tickets"
                bg="gray.50"
                color="gray.800"
                border="1px solid"
                borderColor="gray.200"
                _placeholder={{ color: "gray.400" }}
                _focus={{
                  borderColor: "#4F46E5",
                  boxShadow: "0 0 0 1px #4F46E5",
                  bg: "white",
                  transform: "scale(1.02)",
                }}
                _hover={{
                  borderColor: "gray.300",
                }}
                rounded="xl"
                h="60px"
                fontSize="lg"
                textAlign="center"
                transition="all 0.2s"
                mb={8}
              />

              {capacity && (
                <Button
                  w="full"
                  size="lg"
                  bg="gray.900"
                  color="white"
                  h="60px"
                  fontSize="lg"
                  onClick={handleNext}
                  _hover={{
                    transform: "translateY(-2px)",
                    shadow: "lg",
                    opacity: 0.95,
                  }}
                  _active={{ transform: "translateY(0)" }}
                  rounded="xl"
                  rightIcon={<FaArrowRight />}
                  transition="all 0.2s"
                >
                  Continue
                </Button>
              )}
            </FormControl>
          </VStack>
        </VStack>
      </Container>
    </Box>
  );
};

export default CreateCapacityPage;
