import { Box } from '@chakra-ui/react';
import BottomNav from '../../components/Navigation/BottomNav';
import DesktopLayout from '../../components/Layout/DesktopLayout';
import useAuthStore from '../../store/authStore';
import { useLocation } from 'react-router-dom';

const PageLayout = ({ children }) => {
  const authUser = useAuthStore((state) => state.user);
  const location = useLocation();

  const noNavRoutes = [
    '/update_name',
    '/update_city',
    '/update_job',
    '/update_face_pic',
    '/update_interests',
    '/update_yob',
    '/update_instagram',
    '/update_linkedin',
    '/i/',
  ];

  const shouldShowNav = !noNavRoutes.some(route => location.pathname.startsWith(route));

  // If user is not logged in or it's a no-nav route, render without navigation
  if (!authUser || !shouldShowNav) {
    return (
      <Box 
        minH="100dvh"
        h="100dvh"
        position="relative"
        overflow="hidden"
      >
        <Box
          overflow="auto"
          h="full"
          pt="env(safe-area-inset-top)"
          pb="env(safe-area-inset-bottom)"
        >
          {children}
        </Box>
      </Box>
    );
  }

  return (
    <>
      {/* Desktop Layout */}
      <Box display={{ base: 'none', md: 'block' }}>
        <DesktopLayout>{children}</DesktopLayout>
      </Box>

      {/* Mobile Layout */}
      <Box 
        display={{ base: 'block', md: 'none' }}
        minH="100dvh"
        h="100dvh"
        position="relative"
        overflow="hidden"
      >
        <Box 
          overflow="auto"
          h="full"
          pt="env(safe-area-inset-top)"
          pb="calc(70px + env(safe-area-inset-bottom))"
        >
          {children}
        </Box>
        <BottomNav />
      </Box>
    </>
  );
};

export default PageLayout;
