import {
  Avatar,
  Tag,
  Button,
  TagLabel,
  Flex,
  Skeleton,
  Menu,
  MenuList,
  MenuItem,
  MenuButton,
  Spacer,
  SkeletonCircle,
  HStack,
  Text,
  Divider,
  Container,
  Box,
} from "@chakra-ui/react";
import useGetUserProfileById from "../../hooks/useGetUserProfileById";
import { Link } from "react-router-dom";
import { timeAgo } from "../../utils/timeAgo";
import { useNavigate } from "react-router-dom";
import { BsThreeDotsVertical } from "react-icons/bs";
import { AddIcon } from "@chakra-ui/icons";

import useAdminGroup from "../../hooks/useAdminGroup";

const Member = ({ group, groupId, comment, counter, isAdmin, seenByAdmin }) => {
  const { userProfile, isLoading } = useGetUserProfileById(comment);
  const navigate = useNavigate();

  const { handleAdminGroup } = useAdminGroup(
    userProfile ? userProfile : {},
    groupId,
    comment
  );

  if (isLoading) return <CommentSkeleton />;
  if (userProfile === null) {
    return <CommentSkeleton />;
  } else if (userProfile.status === "deleted") {
    return <CommentSkeleton />;
  } else {
    return (
      <Container
        // bg={"red"}
        userSelect={"none"}
        px={0}
      >
        <Flex flexDir={"row"}>
          <Flex
            onClick={() => {
              navigate(`/u/${userProfile.username}`);
            }}
            w={"full"}
            flexDir={"column"}
          >
            <Flex
              // onClick={() => {
              //   navigate(`/u/${userProfile.username}`);
              // }}
              userSelect={"none"}
              gap={4}
            >
              <Avatar
                pointerEvents={"none"}
                src={userProfile.profilePicURL}
                size={"md"}
              />
              <Flex
                w={"full"}
                // bg={"blue"}

                direction={"column"}
              >
                <Flex gap={2} w={"full"} alignItems={"center"} flexDir={"row"}>
                  <Text userSelect={"none"} fontWeight={"bold"} fontSize={16}>
                    {userProfile.fullName}
                  </Text>
                  <Spacer />

                  {counter && (
                    <Text userSelect={"none"} fontWeight={"bold"} fontSize={14}>
                      +{counter} guests
                    </Text>
                  )}

                  {/* <Text fontSize={14}>{comment.comment}</Text> */}
                </Flex>

                <HStack>
                  {/* {userProfile.phoneNumber !== "" && (
                    <Text
                      userSelect={"none"}
                      noOfLines={[1]}
                      fontSize={14}
                      color={"gray"}
                    >
                      📞 *{userProfile.phoneNumber.substr(-4)}
                    </Text>
                  )} */}

                  {userProfile.job && userProfile.job !== "" && (
                    <Text
                      userSelect={"none"}
                      noOfLines={[1]}
                      fontSize={14}
                      color={"gray"}
                    >
                      💼 {userProfile.job}
                    </Text>
                  )}
                </HStack>

                {isAdmin && (
                  <Flex>
                    <Tag
                      flexShrink="0"
                      size="sm"
                      borderRadius="full"
                      variant="subtle"
                      colorScheme={"green"}
                    >
                      <TagLabel px={1} size="sm">
                        Admin
                      </TagLabel>
                    </Tag>
                  </Flex>
                )}
              </Flex>
            </Flex>
            <Divider userSelect={"none"} mt={3} bg={"gray.8000"} />
          </Flex>
          <Spacer />

          {!isAdmin && seenByAdmin && (
            <Flex

            // bg={"blue"}
            >
              <Flex
                pt={2}
                px1
                //  bg={"red"}
                alignItems={"start"}
              >
                <Menu>
                  <MenuButton
                    // mr={2}
                    as={Button}
                    rounded={"full"}
                    variant={"link"}
                    cursor={"pointer"}
                    minW={0}
                  >
                    <BsThreeDotsVertical boxSize={8} />

                    {/* <Avatar
  size={'sm'}
  src={
    'https://images.unsplash.com/photo-1493666438817-866a91353ca9?ixlib=rb-0.3.5&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&s=b616b2c5b373a80ffc9636ba24f7a4a9'
  }
/> */}
                  </MenuButton>
                  <MenuList>
                    <MenuItem
                      onClick={(e) => {
                        // if (!authUser) {
                        //   showToast(
                        //     "Error",
                        //     "You must be logged in",
                        //     "error"
                        //   );
                        //   return;
                        // } else {

                        // onOpenReportModal();
                        handleAdminGroup();

                        // }
                      }}
                    >
                      <Flex gap={4} alignItems={"center"} flexDirection={"row"}>
                        <AddIcon ml={2} />
                        <Text>Make user Admin</Text>
                      </Flex>
                    </MenuItem>
                  </MenuList>
                </Menu>
              </Flex>
            </Flex>
          )}
        </Flex>
      </Container>
    );
  }
};

export default Member;

const CommentSkeleton = () => {
  return (
    <Flex gap={4} w={"full"} alignItems={"center"}>
      <SkeletonCircle h={10} w="10" />
      <Flex gap={1} flexDir={"column"}>
        <Skeleton height={2} width={100} />
        <Skeleton height={2} width={50} />
      </Flex>
    </Flex>
  );
};
