import { Box, Avatar, Text, VStack, HStack, Flex } from "@chakra-ui/react";
import { timeAgo } from "../utils/timeAgo";

const UserItem = ({
  name,
  email,
  fullName,
  profilePicURL,
  job,
  location,
  createdAt,
  city,
  yob,
}) => {
  return (
    <Box
      p={4}
      bg="white"
      borderWidth="1px"
      borderColor="gray.200"
      borderRadius="xl"
      transition="all 0.2s"
      _hover={{
        transform: "translateY(-2px)",
        shadow: "sm",
        borderColor: "gray.300",
      }}
    >
      <Flex align="center" gap={4}>
        <Avatar size="md" src={profilePicURL} name={name} />
        <Box flex="1">
          <Flex justify="space-between" align="center" mb={1}>
            <Text fontWeight="bold" color="gray.900">
              {fullName || name}
              {yob && (
                <Text as="span" ml={2} color="gray.500" fontSize="sm">
                  • {yob}
                </Text>
              )}
            </Text>
            {createdAt && (
              <Text fontSize="xs" color="gray.500">
                {timeAgo(createdAt)}
              </Text>
            )}
          </Flex>
          {job && (
            <Text fontSize="sm" color="gray.600">
              {job}
            </Text>
          )}
          {email && (
            <Text fontSize="sm" color="gray.500" mt={0.5}>
              {email}
            </Text>
          )}
          {city && (
            <Text fontSize="sm" color="gray.500" mt={0.5}>
              {city}
            </Text>
          )}
        </Box>
      </Flex>
    </Box>
  );
};

export default UserItem;
