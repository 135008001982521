import { Avatar, Flex, Skeleton, SkeletonCircle, Text } from "@chakra-ui/react";
import useGetUserProfileById from "../../hooks/useGetUserProfileById";
import { Link } from "react-router-dom";
import { timeAgo } from "../../utils/timeAgo";

const Following = ({ user }) => {
  const { userProfile, isLoading } = useGetUserProfileById(user);

  if (isLoading) return <FollowingSkeleton />;
  if (userProfile === null) {
    return <FollowingSkeleton />;
  } else {
    return (
      <Flex gap={4}>
        <Link to={`/${userProfile.username}`}>
          <Avatar src={userProfile.profilePicURL} size={"sm"} />
        </Link>
        <Flex direction={"column"}>
          <Flex gap={2} alignItems={"center"}>
            <Link to={`/${userProfile.username}`}>
              <Text fontWeight={"bold"} fontSize={12}>
                {userProfile.fullName}
              </Text>
            </Link>
            {/* <Text fontSize={14}>{comment.comment}</Text> */}
          </Flex>

          {userProfile.phoneNumber !== "" && (
            <Text fontSize={12} color={"gray"}>
              📞 *{userProfile.phoneNumber.substr(-4)}
            </Text>
          )}
        </Flex>
      </Flex>
    );
    // }
  }
};

export default Following;

const FollowingSkeleton = () => {
  return (
    <Flex gap={4} w={"full"} alignItems={"center"}>
      <SkeletonCircle h={10} w="10" />
      <Flex gap={1} flexDir={"column"}>
        <Skeleton height={2} width={100} />
        <Skeleton height={2} width={50} />
      </Flex>
    </Flex>
  );
};
