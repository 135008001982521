import {
  Container,
  Flex,
  VStack,
  FormControl,
  Input,
  Text,
  IconButton,
  Box,
  Button,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { FaTicketAlt } from "react-icons/fa";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getFirestore, collection, addDoc, serverTimestamp } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const ValidatePromoCodePage = () => {
  const [promoCode, setPromoCode] = useState("");
  const [isValidating, setIsValidating] = useState(false);
  const [validationMessage, setValidationMessage] = useState("");
  const navigate = useNavigate();
  const toast = useToast();

  const colors = {
    textColor: "gray.900",
    bgColor: "white",
    cardBg: "white",
    dividerColor: "gray.200",
    iconBgColor: "rgba(184, 160, 128, 0.1)",
    hoverBgColor: "rgba(184, 160, 128, 0.05)",
    subtitleColor: "gray.600",
    accentColor: "#B8A080",
    borderColor: "rgba(184, 160, 128, 0.2)",
  };

  const handlePromoCodeChange = (e) => {
    setPromoCode(e.target.value.toUpperCase());
  };

  const handleValidateCode = async () => {
    setIsValidating(true);
    setValidationMessage("");

    setTimeout(async () => {
      setIsValidating(false);
      if (promoCode === "HUBZOFIRST") {
        try {
          const db = getFirestore();
          const auth = getAuth();
          const user = auth.currentUser;

          if (user) {
            await addDoc(collection(db, "promotions"), {
              userId: user.uid,
              createdAt: serverTimestamp(),
              promoText: promoCode,
            });
          }

          toast({
            title: "Promo Code Applied!",
            description: "Your promo code was successfully applied.",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "bottom",
          });
          setValidationMessage("");
          navigate(-1);
        } catch (error) {
          console.error("Error adding promotion entry: ", error);
          setValidationMessage("There was an error processing your promo code.");
        }
      } else {
        setValidationMessage("Invalid promo code. Please try again.");
      }
    }, 1500);
  };

  return (
    <Box
      h="100vh"
      maxH="100vh"
      overflow="hidden"
      position="relative"
      bg={colors.bgColor}
      pb="env(safe-area-inset-bottom)"
    >
      <Box
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        overflowY="auto"
        pb={{ base: 32, md: 8 }}
        style={{
          WebkitOverflowScrolling: 'touch'
        }}
      >
        <Container 
          maxW={{ base: "container.md", md: "container.xl" }} 
          px={4} 
          py={6}
          mb={{ base: 8, md: 0 }}
        >
          {/* Header */}
          <Flex 
            w="full" 
            alignItems="center" 
            mb={8}
            position="relative"
          >
            <IconButton
              icon={<ArrowBackIcon />}
              variant="ghost"
              aria-label="Go back"
              onClick={() => navigate(-1)}
              display={{ base: "flex", md: "none" }}
              color={colors.accentColor}
              _hover={{
                bg: colors.iconBgColor,
                transform: "translateY(-2px)",
              }}
              transition="all 0.2s"
            />
            <Text 
              fontSize={{ base: "2xl", md: "3xl" }}
              fontWeight="light"
              color={colors.textColor}
              textAlign={{ base: "left", md: "center" }}
              flex="1"
              ml={{ base: 4, md: 0 }}
              letterSpacing="wide"
              fontFamily="Montserrat, sans-serif"
            >
              Use Your Promo Code
            </Text>
          </Flex>

          {/* Main Content */}
          <Box 
            maxW={{ base: "full", md: "lg" }} 
            mx="auto"
          >
            <Box
              bg={colors.cardBg}
              borderRadius="xl"
              borderWidth="1px"
              borderColor={colors.borderColor}
              p={6}
              textAlign="center"
              shadow="sm"
              _hover={{ shadow: "md" }}
              transition="all 0.2s"
            >
              <VStack spacing={6} align="center">
                <Box
                  p={4}
                  bg={colors.iconBgColor}
                  borderRadius="full"
                  color={colors.accentColor}
                  transform="rotate(-5deg)"
                  _hover={{
                    transform: "rotate(0deg)",
                  }}
                  transition="all 0.3s"
                >
                  <FaTicketAlt size={30} />
                </Box>

                <Text 
                  fontSize={{ base: "sm", md: "md" }} 
                  color={colors.textColor}
                  maxW="md"
                  lineHeight="tall"
                  fontFamily="Montserrat, sans-serif"
                  letterSpacing="wide"
                >
                  Use your promo code to unlock exclusive discounts and benefits.
                </Text>

                <FormControl>
                  <Input
                    placeholder="Enter Promo Code"
                    size="lg"
                    type="text"
                    value={promoCode}
                    onChange={handlePromoCodeChange}
                    bg={colors.iconBgColor}
                    color={colors.textColor}
                    _placeholder={{ color: colors.subtitleColor }}
                    textAlign="center"
                    rounded="full"
                    h={14}
                    fontSize="md"
                    textTransform="uppercase"
                    fontFamily="Montserrat, sans-serif"
                    letterSpacing="wide"
                    borderColor={colors.borderColor}
                    _hover={{
                      borderColor: colors.accentColor,
                    }}
                    _focus={{
                      borderColor: colors.accentColor,
                      boxShadow: `0 0 0 1px ${colors.accentColor}`,
                    }}
                  />
                  {validationMessage && (
                    <Text 
                      fontSize="sm" 
                      color="red.500" 
                      mt={2} 
                      textAlign="center"
                      fontFamily="Montserrat, sans-serif"
                      letterSpacing="wide"
                    >
                      {validationMessage}
                    </Text>
                  )}
                </FormControl>

                <Button
                  w="full"
                  maxW="400px"
                  bg={promoCode ? "black" : "gray.300"}
                  color="white"
                  size="lg"
                  h={14}
                  fontSize="md"
                  fontWeight="medium"
                  isLoading={isValidating}
                  onClick={handleValidateCode}
                  rounded="full"
                  isDisabled={!promoCode}
                  letterSpacing="wide"
                  fontFamily="Montserrat, sans-serif"
                  _hover={{ 
                    transform: promoCode ? "translateY(-2px)" : "none",
                    shadow: promoCode ? "lg" : "none",
                    opacity: promoCode ? 0.9 : 1,
                  }}
                  _active={{
                    transform: "translateY(0)",
                    opacity: 0.8,
                  }}
                  transition="all 0.2s"
                >
                  Validate Code
                </Button>
              </VStack>
            </Box>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default ValidatePromoCodePage;
  