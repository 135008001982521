import {
  Container,
  Flex,
  VStack,
  Text,
  IconButton,
  Box,
  Progress,
  Button,
  HStack,
  Icon,
} from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { FaMapMarkerAlt } from "react-icons/fa";

// List of popular European cities and their corresponding flags
const cities = [
  { name: "London", flag: "🇬🇧" },
  { name: "Manchester", flag: "🇬🇧" },
  { name: "Paris", flag: "🇫🇷" },
  { name: "Rome", flag: "🇮🇹" },
  { name: "Berlin", flag: "🇩🇪" },
  { name: "Madrid", flag: "🇪🇸" },
  { name: "Barcelona", flag: "🇪🇸" },
  { name: "Amsterdam", flag: "🇳🇱" },
  { name: "Moscow", flag: "🇷🇺" },
  { name: "Athens", flag: "🇬🇷" },
  { name: "Lisbon", flag: "🇵🇹" },
  { name: "Warsaw", flag: "🇵🇱" },
  { name: "Brussels", flag: "🇧🇪" },
  { name: "Stockholm", flag: "🇸🇪" },
  { name: "Prague", flag: "🇨🇿" },
  { name: "Other", flag: "🏴" },
];

// Add the LogoComponent
const LogoComponent = ({ onClick }) => (
  <Box 
    as="h1" 
    fontSize={{ base: "28px", md: "32px" }}
    fontWeight="400"
    letterSpacing="0.35em"
    position="relative"
    display="inline-block"
    cursor="pointer"
    color="#1A1A1A"
    textTransform="uppercase"
    fontFamily="Montserrat, sans-serif"
    onClick={onClick}
    _hover={{
      letterSpacing: "0.4em",
      transform: "translateY(-1px)",
      transition: "all 0.4s cubic-bezier(0.4, 0, 0.2, 1)",
    }}
    transition="all 0.4s cubic-bezier(0.4, 0, 0.2, 1)"
    sx={{
      WebkitFontSmoothing: "antialiased",
      textRendering: "optimizeLegibility",
    }}
  >
    <Box
      as="span"
      position="relative"
      _before={{
        content: '""',
        position: "absolute",
        left: "-8%",
        right: "-8%",
        top: "-8px",
        bottom: "-8px",
        background: "linear-gradient(135deg, rgba(26, 26, 26, 0.08), rgba(26, 26, 26, 0.03))",
        backdropFilter: "blur(4px)",
        opacity: { base: 0.95, md: 0.9 },
        borderRadius: "2px",
        transform: "skew(-4deg)",
        transition: "all 0.3s ease",
        zIndex: -1,
      }}
      _after={{
        content: '""',
        position: "absolute",
        left: 0,
        right: 0,
        bottom: "-4px",
        height: "1px",
        background: "linear-gradient(to right, transparent, currentColor, transparent)",
        opacity: 0.4,
        transform: "scaleX(0.8)",
        transition: "transform 0.3s ease",
      }}
      _hover={{
        _after: {
          transform: "scaleX(1)",
        },
      }}
    >
      hubzo
    </Box>
  </Box>
);

const ChooseCityPage = () => {
  const [selectedCity, setSelectedCity] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    const timer = setTimeout(() => setIsLoading(false), 0);
    return () => clearTimeout(timer);
  }, []);

  if (isLoading) {
    return <Box minH="100vh" bg="white" />;
  }

  return (
    <Box 
      minH="100vh"
      maxH="100vh"
      overflow="hidden"
      position="relative"
      bg="white"
      pb="env(safe-area-inset-bottom)"
    >
      {/* Top Bar */}
      <Box
        position="fixed"
        top={0}
        left={0}
        right={0}
        zIndex={1000}
        bg="white"
        h={{ base: "56px", md: "64px" }}
        backdropFilter="blur(12px)"
        backgroundColor="rgba(255, 255, 255, 0.9)"
        borderBottom="1px solid"
        borderColor="gray.100"
      >
        <Container maxW="container.xl" h="full">
          <Flex 
            justify="space-between"
            align="center"
            h="full"
            px={{ base: 3, md: 4 }}
          >
            <IconButton
              icon={<ArrowBackIcon />}
              variant="ghost"
              aria-label="Go back"
              onClick={() => navigate(-1)}
              color="gray.500"
              display={{ base: 'flex', md: 'none' }}
              _hover={{ color: "black" }}
            />

            <LogoComponent onClick={() => navigate("/")} />

            <Box w="40px" display={{ base: 'block', md: 'none' }} />
          </Flex>
        </Container>
      </Box>

      {/* Main Content */}
      <Box 
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        overflowY="auto"
        style={{
          WebkitOverflowScrolling: 'touch'
        }}
      >
        <Box 
          pt={{ base: "56px", md: "64px" }}
          minH="100vh"
          pb={{ base: 32, md: 8 }}
        >
          <Container 
            maxW="container.sm" 
            py={{ base: 8, md: 16 }} 
            px={6}
            mb={{ base: 8, md: 0 }}
          >
            <VStack spacing={8} align="center" w="full">
              {/* Progress Bar */}
              <Progress
                value={25}
                size="sm"
                colorScheme="blackAlpha"
                w="full"
                maxW="400px"
                borderRadius="full"
                bg="gray.100"
              />

              <VStack spacing={3} textAlign="center">
                <Text
                  fontSize={{ base: "3xl", md: "4xl" }}
                  fontWeight="light"
                  letterSpacing="tight"
                  color="gray.900"
                  lineHeight="shorter"
                >
                  Choose your city
                </Text>
              </VStack>

              <VStack spacing={3} align="stretch" w="full" maxW="400px">
                {cities.map(({ name, flag }) => (
                  <Button
                    key={name}
                    size="lg"
                    bg={selectedCity === name ? "black" : "white"}
                    color={selectedCity === name ? "white" : "gray.700"}
                    border="1px solid"
                    borderColor={selectedCity === name ? "transparent" : "gray.200"}
                    rounded="full"
                    fontWeight="semibold"
                    width="full"
                    h="60px"
                    leftIcon={
                      <Text fontSize="xl">{flag}</Text>
                    }
                    _hover={{ 
                      transform: "translateY(-2px)",
                      shadow: "md",
                      bg: selectedCity === name 
                        ? "black"
                        : "gray.50",
                      opacity: selectedCity === name ? 0.95 : 1
                    }}
                    onClick={() => {
                      setSelectedCity(name);
                      localStorage.setItem("city", name);
                      navigate(localStorage.getItem("isCreator") === "true" ? "/choose_interests" : "/choose_job");
                    }}
                    transition="all 0.2s"
                  >
                    <HStack spacing={3}>
                      <Text>{name}</Text>
                    </HStack>
                  </Button>
                ))}
              </VStack>

              <HStack 
                spacing={2} 
                color="gray.500"
                fontSize="sm"
                pt={2}
              >
                <Box as="span" fontSize="lg">🔒</Box>
                <Text>Your information is always private</Text>
              </HStack>
            </VStack>
          </Container>
        </Box>
      </Box>
    </Box>
  );
};

export default ChooseCityPage;
