import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import App from "./App.jsx";
import "./indexx.css";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";
import { BrowserRouter } from "react-router-dom";

// Global styles
const styles = {
  global: (props) => ({
    "html, body": {
      height: "100%",
      margin: 0,
      padding: 0,
      fontFamily: "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
      WebkitFontSmoothing: "antialiased",
      overflow: "hidden",
      bg: mode("gray.50", "gray.900")(props),
      color: mode("gray.800", "whiteAlpha.900")(props),
    },
    "#root": {
      minHeight: "100vh",
      minHeight: "-webkit-fill-available",
      display: "flex",
      flexDirection: "column",
    },
    "::-webkit-scrollbar": {
      display: "none",  // iOS-inspired hidden scrollbar
    },
    "*": {
      boxSizing: "border-box",
    },
    "input, button": {
      fontFamily: "inherit",
    },
  }),
};

// Theme configuration
const config = {
  initialColorMode: "light",
  useSystemColorMode: false,
};

// Custom theme with iOS-inspired elements
const theme = extendTheme({
  config,
  styles,
  colors: {
    blue: {
      500: "#007AFF",  // iOS-style blue accent
    },
    gray: {
      50: "#f9f9f9",   // Very light gray for backgrounds
      100: "#f0f0f0",  // Light gray for section backgrounds
      800: "#1c1c1e",  // Dark gray for high contrast on dark mode
    },
  },
  components: {
    Button: {
      baseStyle: {
        borderRadius: "md",
        _focus: { boxShadow: "none" },
      },
      variants: {
        solid: (props) => ({
          bg: mode("blue.500", "blue.500")(props),
          color: "white",
          _hover: {
            bg: mode("blue.600", "blue.400")(props),
          },
          _active: {
            bg: mode("blue.700", "blue.300")(props),
          },
        }),
        outline: (props) => ({
          borderColor: mode("gray.200", "gray.700")(props),
          color: mode("gray.800", "whiteAlpha.900")(props),
          _hover: {
            bg: mode("gray.100", "gray.700")(props),
          },
        }),
      },
    },
    IconButton: {
      baseStyle: {
        _focus: { boxShadow: "none" },
        borderRadius: "full",
        _hover: { bg: mode("gray.100", "gray.700") },
      },
    },
    Container: {
      baseStyle: {
        maxW: "100%",
        px: 4,
        borderRadius: "md",
      },
    },
    Text: {
      baseStyle: {
        color: mode("gray.800", "whiteAlpha.900"),
      },
    },
  },
});

// Viewport height hook to handle iOS-style layout
function useViewportHeight() {
  useEffect(() => {
    const setAppHeight = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };
    setAppHeight();
    window.addEventListener("resize", setAppHeight);
    return () => window.removeEventListener("resize", setAppHeight);
  }, []);
}

function Main() {
  useViewportHeight();
  return (
    <BrowserRouter>
      <ChakraProvider theme={theme}>
        <App />
      </ChakraProvider>
    </BrowserRouter>
  );
}

ReactDOM.createRoot(document.getElementById("root")).render(
  <Main />
);
