import { useEffect, useState } from "react";
import {
  Box,
  VStack,
  Container,
  Heading,
  Avatar,
  Text,
  Flex,
  Skeleton,
  Stack,
} from "@chakra-ui/react";
import UserItem from "../../components/UserItem";
import { firestore } from "../../firebase/firebase";
import {
  doc,
  getDoc,
  setDoc,
  collection,
  getDocs,
  query,
  where,
  updateDoc,
  orderBy,
  limit,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";

const UserListPage = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const q = query(
          collection(firestore, "users"),
          orderBy("createdAt", "desc"),
          limit(20)
        );

        const userSnapshot = await getDocs(q);
        const userList = userSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setUsers(userList);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching users:", error);
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  const handleUserClick = (userId) => {
    navigate(`/user/${userId}`);
  };

  return (
    <Box
      h="100vh"
      maxH="100vh"
      overflow="hidden"
      position="relative"
      bg="white"
      pb="env(safe-area-inset-bottom)"
    >
      <Box
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        overflowY="auto"
        pb={8}
        style={{
          WebkitOverflowScrolling: "touch",
        }}
      >
        <Container maxW="container.md" py={6}>
          <Heading 
            as="h2" 
            size="lg" 
            mb={6}
            color="gray.900"
          >
            User List
          </Heading>
          <VStack spacing={4} align="stretch">
            {loading ? (
              <Stack>
                <Skeleton height="70px" />
                <Skeleton height="70px" />
                <Skeleton height="70px" />
              </Stack>
            ) : users.length > 0 ? (
              users.map((user) => (
                <Box
                  key={user.id}
                  onClick={() => handleUserClick(user.id)}
                  cursor="pointer"
                  _hover={{ bg: "gray.50" }}
                  transition="background 0.2s"
                >
                  <UserItem
                    name={user.name}
                    email={user.email}
                    profilePicURL={user.profilePicURL}
                    job={user.job}
                    location={user.location}
                    fullName={user.fullName}
                    createdAt={user.createdAt}
                    city={user.city}
                    yob={user.yob}
                  />
                </Box>
              ))
            ) : (
              <Box
                p={8}
                bg="gray.50"
                borderRadius="xl"
                textAlign="center"
                borderWidth="1px"
                borderColor="gray.200"
              >
                <Text color="gray.600">No users found.</Text>
              </Box>
            )}
          </VStack>
        </Container>
      </Box>
    </Box>
  );
};

export default UserListPage;
