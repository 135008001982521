import {
  Container,
  Flex,
  Box,
  HStack,
  VStack,
  IconButton,
  Text,
  Button,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  useDisclosure,
} from "@chakra-ui/react";
import { ChevronRightIcon, ArrowBackIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";
import useAuthStore from "../../store/authStore";
import useShowToast from "../../hooks/useShowToast";
import useLogout from "../../hooks/useLogout";
import { FaBell, FaBriefcase, FaLock, FaFileContract } from "react-icons/fa";
import { doc, updateDoc } from "firebase/firestore";
import { auth, firestore } from "../../firebase/firebase";

const SettingsPage = () => {
  const authUser = useAuthStore((state) => state.user);
  const navigate = useNavigate();
  const { handleLogout } = useLogout();
  const showToast = useShowToast();
  const { isOpen: isOpenDeleteAccountModal, onOpen: onOpenDeleteAccountModal, onClose: onCloseDeleteAccountModal } = useDisclosure();
  const cancelRef = useRef();

  const colors = {
    textColor: "gray.900",
    bgColor: "white",
    cardBg: "white",
    dividerColor: "gray.200",
    iconBgColor: "rgba(184, 160, 128, 0.1)",
    hoverBgColor: "gray.50",
    subtitleColor: "gray.600",
    dangerColor: "red.500",
    accentColor: "#B8A080",
    borderColor: "rgba(184, 160, 128, 0.2)",
  };

  const handleDeleteAccount = async () => {
    try {
      const userRef = doc(firestore, "users", authUser?.uid);
      await updateDoc(userRef, { status: "deleted" });
      showToast("Success", "Account deleted successfully", "success");
      handleLogout();
      navigate("/");
    } catch (error) {
      showToast("Error", error.message, "error");
    }
  };

  const openInNewTab = (url) => window.open(url, "_blank", "noreferrer");

  return (
    <Box
      h="100vh"
      maxH="100vh"
      overflow="hidden"
      position="relative"
      bg={colors.bgColor}
      pb="env(safe-area-inset-bottom)"
    >
      <Box
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        overflowY="auto"
        pb={{ base: 32, md: 8 }}
        style={{
          WebkitOverflowScrolling: 'touch'
        }}
      >
        <Container 
          maxW={{ base: "container.md", md: "container.xl" }} 
          px={4} 
          py={6}
          mb={{ base: 8, md: 0 }}
        >
          {/* Header */}
          <Flex 
            w="full" 
            alignItems="center" 
            mb={8}
            position="relative"
          >
            <IconButton
              icon={<ArrowBackIcon />}
              variant="ghost"
              aria-label="Go back"
              onClick={() => navigate(-1)}
              position={{ base: "relative", md: "absolute" }}
              left={{ md: 0 }}
              color={colors.accentColor}
              _hover={{
                bg: colors.iconBgColor,
                transform: "translateY(-2px)",
              }}
              transition="all 0.2s"
            />
            <Text 
              fontSize={{ base: "2xl", md: "3xl" }}
              fontWeight="light"
              color={colors.textColor}
              textAlign={{ base: "left", md: "center" }}
              flex="1"
              ml={{ base: 4, md: 0 }}
              letterSpacing="wide"
              fontFamily="Montserrat, sans-serif"
            >
              Settings
            </Text>
          </Flex>

          {/* Main Content */}
          <Box 
            maxW={{ base: "full", md: "2xl" }} 
            mx="auto"
          >
            {/* Notifications Section */}
            <Box mb={8}>
              <Text 
                fontSize={{ base: "lg", md: "xl" }} 
                fontWeight="medium" 
                color={colors.textColor} 
                mb={4}
                letterSpacing="wide"
                fontFamily="Montserrat, sans-serif"
              >
                Notifications
              </Text>
              <VStack
                spacing={4}
                borderRadius="xl"
                overflow="hidden"
                borderWidth="1px"
                borderColor={colors.borderColor}
                bg={colors.cardBg}
                shadow="sm"
                _hover={{ shadow: "md" }}
                transition="all 0.2s"
              >
                <SettingOption
                  icon={<FaBell />}
                  label="Invitation Alerts"
                  description="Control notifications for invitations"
                  onClick={() => navigate("/invitations_alert")}
                  colors={colors}
                />
                <SettingOption
                  icon={<FaBriefcase />}
                  label="Marketing"
                  description="Manage your marketing preferences"
                  onClick={() => navigate("/marketing_alert")}
                  colors={colors}
                />
              </VStack>
            </Box>

            {/* Legal Section */}
            <Box mb={8}>
              <Text 
                fontSize={{ base: "lg", md: "xl" }} 
                fontWeight="medium" 
                color={colors.textColor} 
                mb={4}
                letterSpacing="wide"
                fontFamily="Montserrat, sans-serif"
              >
                Legal Information
              </Text>
              <VStack
                spacing={4}
                borderRadius="xl"
                overflow="hidden"
                borderWidth="1px"
                borderColor={colors.borderColor}
                bg={colors.cardBg}
                shadow="sm"
                _hover={{ shadow: "md" }}
                transition="all 0.2s"
              >
                <SettingOption
                  icon={<FaLock />}
                  label="Privacy Policy"
                  description="Learn more about our privacy practices"
                  onClick={() => openInNewTab("https://hubzo.social/privacy-policy/")}
                  colors={colors}
                />
                <SettingOption
                  icon={<FaFileContract />}
                  label="Terms & Conditions"
                  description="Understand your rights and obligations"
                  onClick={() => openInNewTab("https://hubzo.social/privacy-policy/")}
                  colors={colors}
                />
              </VStack>
            </Box>

            {/* Bottom Section */}
            <VStack spacing={4} w="full" mb={8}>
              <Text 
                fontSize="sm" 
                color={colors.subtitleColor}
                fontFamily="Montserrat, sans-serif"
              >
                version: 1.0.3 (23)
              </Text>

              <Button
                w="full"
                size={{ base: "lg", md: "lg" }}
                variant="outline"
                onClick={handleLogout}
                h={14}
                fontSize="md"
                fontWeight="medium"
                rounded="full"
                borderWidth={2}
                borderColor={colors.accentColor}
                color={colors.accentColor}
                _hover={{
                  transform: "translateY(-2px)",
                  shadow: "md",
                  bg: colors.iconBgColor,
                }}
                transition="all 0.2s"
              >
                Log out
              </Button>

              <Button
                w="full"
                size={{ base: "lg", md: "lg" }}
                variant="outline"
                onClick={onOpenDeleteAccountModal}
                h={14}
                fontSize="md"
                fontWeight="medium"
                rounded="full"
                borderWidth={2}
                borderColor="red.500"
                color="red.500"
                _hover={{
                  transform: "translateY(-2px)",
                  shadow: "md",
                  bg: "red.50",
                }}
                transition="all 0.2s"
              >
                Delete my account
              </Button>
            </VStack>
          </Box>
        </Container>
      </Box>

      {/* Delete Account Modal */}
      <AlertDialog
        motionPreset="slideInBottom"
        onClose={onCloseDeleteAccountModal}
        isOpen={isOpenDeleteAccountModal}
        isCentered
        leastDestructiveRef={cancelRef}
      >
        <AlertDialogOverlay />
        <AlertDialogContent mx={4} rounded="2xl">
          <AlertDialogHeader 
            fontSize="xl" 
            fontWeight="medium"
            fontFamily="Montserrat, sans-serif"
          >
            Delete Account
          </AlertDialogHeader>
          <AlertDialogBody color={colors.subtitleColor}>
            Are you sure? This action cannot be undone.
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button 
              ref={cancelRef} 
              onClick={onCloseDeleteAccountModal}
              rounded="full"
              h={12}
              px={6}
            >
              Cancel
            </Button>
            <Button
              colorScheme="red"
              onClick={handleDeleteAccount}
              ml={3}
              rounded="full"
              h={12}
              px={6}
            >
              Delete
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </Box>
  );
};

// SettingOption component
const SettingOption = ({ icon, label, description, onClick, colors }) => {
  return (
    <Box
      w="full"
      bg={colors.cardBg}
      cursor="pointer"
      _hover={{ 
        bg: colors.iconBgColor,
        transform: "translateY(-1px)",
      }}
      transition="all 0.2s"
      onClick={onClick}
    >
      <HStack p={4} spacing={4} justify="space-between">
        <HStack spacing={4}>
          <Box 
            p={3}
            bg={colors.iconBgColor}
            borderRadius="full"
            color={colors.accentColor}
          >
            {icon}
          </Box>
          <Box>
            <Text
              fontWeight="medium"
              fontSize={{ base: "md", md: "lg" }}
              color={colors.textColor}
              fontFamily="Montserrat, sans-serif"
            >
              {label}
            </Text>
            <Text 
              fontSize={{ base: "sm", md: "md" }}
              color={colors.subtitleColor}
            >
              {description}
            </Text>
          </Box>
        </HStack>
        <ChevronRightIcon color={colors.accentColor} boxSize={6} />
      </HStack>
    </Box>
  );
};

export default SettingsPage;
