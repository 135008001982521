import { useEffect, useState } from "react";
import useShowToast from "./useShowToast";
import { collection, getDocs, query, where } from "firebase/firestore";
import { firestore } from "../firebase/firebase";
import useAuthStore from "../store/authStore";

import useUserProfileStore from "../store/userProfileStore";

const useGetGroupProfileByUsername = (username) => {
  const [isLoading, setIsLoading] = useState(true);
  const [userId, setUserId] = useState("");
  const showToast = useShowToast();
  const { userProfile, setUserProfile } = useUserProfileStore();
  const authUser = useAuthStore((state) => state.user);

  useEffect(() => {
    const getUserProfile = async () => {
      setIsLoading(true);
      try {
        const q = query(
          collection(firestore, "groups"),
          where("username", "==", username)
        );
        const querySnapshot = await getDocs(q);

        if (querySnapshot.empty) return setUserProfile(null);

        let userDoc;
        let userId;
        querySnapshot.forEach((doc) => {
          userDoc = doc.data();
          userId = doc.id;
        });

        let filteredUsers = [];

        if (authUser && authUser.blocked) {
          let allBlockedIdsList = [];

          const youBlockedList = authUser.blocked
            .filter((blocked) => blocked.createdBy == authUser.uid)
            .map((filteredBlocked) => filteredBlocked.reportedUserId);

          // allBlockedIdsList.push(youBlockedList);

          const youWereBlockedList = authUser.blocked
            .filter((blocked) => blocked.reportedUserId == authUser.uid)
            .map((filteredBlocked) => filteredBlocked.createdBy);

          // allBlockedIdsList.concat(youWereBlockedList);

          allBlockedIdsList = youBlockedList.concat(youWereBlockedList);

          filteredUsers = userDoc.memberList.filter(
            (user) => allBlockedIdsList.includes(user.uid) == false
          );
        } else {
          filteredUsers = userDoc.memberList;
        }

        userDoc.memberList = filteredUsers;

        setUserProfile(userDoc);
        setUserId(userId);
        // console.log(userDoc);
      } catch (error) {
        showToast("Error", error.message, "error");
      } finally {
        setIsLoading(false);
      }
    };

    getUserProfile();
  }, [setUserProfile, username, showToast]);

  return { isLoading, userProfile, userId };
};

export default useGetGroupProfileByUsername;
