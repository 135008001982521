import {
  Container,
  Flex,
  Button,
  VStack,
  FormControl,
  Input,
  Text,
  IconButton,
  Box,
  useColorModeValue,
} from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import useEditProfile from "../../hooks/useEditProfile";
import useAuthStore from "../../store/authStore";
import useShowToast from "../../hooks/useShowToast";
import usePreviewImg from "../../hooks/usePreviewImg";
import DesktopNav from '../../components/Navigation/DesktopNav';

const UpdateNamePage = () => {
  const authUser = useAuthStore((state) => state.user);
  const [name, setName] = useState(authUser?.fullName);
  const navigate = useNavigate();
  const inputRef = useRef(null);

  const colors = {
    textColor: "gray.900",
    bgColor: "white",
    cardBg: "white",
    dividerColor: "gray.200",
    iconBgColor: "rgba(184, 160, 128, 0.1)",
    hoverBgColor: "rgba(184, 160, 128, 0.05)",
    subtitleColor: "gray.600",
    accentColor: "#B8A080",
    borderColor: "rgba(184, 160, 128, 0.2)",
  };

  const { isUpdating, editProfile } = useEditProfile();
  const showToast = useShowToast();
  const { handleImageChange, selectedFile, setSelectedFile } = usePreviewImg();

  const [inputs, setInputs] = useState({
    fullName: authUser.fullName,
    username: authUser.username,
    bio: authUser.bio,
    whatsapp: authUser.whatsapp,
    instagram: authUser.instagram,
    linkedin: authUser.linkedin,
    job: authUser.job,
    hidden: authUser.hidden,
    yob: authUser.yob,
    interests: authUser.interests,
  });

  const handleEditProfile = async () => {
    if (name.trim() === "") {
      showToast("Error", "Your name cannot be empty", "error");
    } else {
      try {
        await editProfile(inputs, selectedFile);
        showToast("Success", "Profile updated", "success");
        navigate(-1);
      } catch (error) {
        showToast("Error", error.message, "error");
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      inputRef.current.blur();
    }
  };

  return (
    <Box
      h="100vh"
      maxH="100vh"
      overflow="hidden"
      position="relative"
      bg={colors.bgColor}
      pb="env(safe-area-inset-bottom)"
    >
      <Box display={{ base: "none", md: "block" }}>
        <DesktopNav />
      </Box>

      <Box
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        overflowY="auto"
        pb={{ base: 32, md: 8 }}
        style={{
          WebkitOverflowScrolling: 'touch'
        }}
      >
        <Container 
          maxW={{ base: "container.md", md: "container.xl" }} 
          px={4} 
          py={6}
          mb={{ base: 8, md: 0 }}
          mt={{ base: 0, md: "60px" }}
        >
          {/* Header */}
          <Flex 
            w="full" 
            alignItems="center" 
            mb={8}
            position="relative"
          >
            <IconButton
              icon={<ArrowBackIcon />}
              variant="ghost"
              aria-label="Go back"
              onClick={() => navigate(-1)}
              position={{ base: "relative", md: "absolute" }}
              left={{ md: 0 }}
              color={colors.accentColor}
              _hover={{
                bg: colors.iconBgColor,
                transform: "translateY(-2px)",
              }}
              transition="all 0.2s"
            />
            <Text 
              fontSize={{ base: "2xl", md: "3xl" }}
              fontWeight="light"
              color={colors.textColor}
              textAlign={{ base: "left", md: "center" }}
              flex="1"
              ml={{ base: 4, md: 0 }}
              letterSpacing="wide"
              fontFamily="Montserrat, sans-serif"
            >
              What's your name?
            </Text>
          </Flex>

          {/* Main Content */}
          <Box 
            maxW={{ base: "full", md: "lg" }} 
            mx="auto"
          >
            <Box
              bg={colors.cardBg}
              borderRadius="xl"
              borderWidth="1px"
              borderColor={colors.borderColor}
              p={6}
              textAlign="center"
              shadow="sm"
              _hover={{ shadow: "md" }}
              transition="all 0.2s"
            >
              <VStack spacing={6} align="center">
                <FormControl>
                  <Input
                    ref={inputRef}
                    placeholder="Your Name"
                    size="lg"
                    type="text"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                      setInputs({ ...inputs, fullName: e.target.value });
                    }}
                    onKeyDown={handleKeyDown}
                    bg={colors.iconBgColor}
                    color={colors.textColor}
                    _placeholder={{ color: colors.subtitleColor }}
                    textAlign="center"
                    rounded="full"
                    h={14}
                    fontSize="md"
                    fontFamily="Montserrat, sans-serif"
                    letterSpacing="wide"
                    borderColor={colors.borderColor}
                    _hover={{
                      borderColor: colors.accentColor,
                    }}
                    _focus={{
                      borderColor: colors.accentColor,
                      boxShadow: `0 0 0 1px ${colors.accentColor}`,
                    }}
                  />
                </FormControl>

                <Button
                  w="full"
                  maxW="400px"
                  bg={name ? "black" : "gray.300"}
                  color="white"
                  size="lg"
                  h={14}
                  fontSize="md"
                  fontWeight="medium"
                  isLoading={isUpdating}
                  onClick={() => {
                    localStorage.setItem("name", name);
                    handleEditProfile();
                  }}
                  rounded="full"
                  isDisabled={!name}
                  letterSpacing="wide"
                  fontFamily="Montserrat, sans-serif"
                  _hover={{ 
                    transform: name ? "translateY(-2px)" : "none",
                    shadow: name ? "lg" : "none",
                    opacity: name ? 0.9 : 1,
                  }}
                  _active={{
                    transform: "translateY(0)",
                    opacity: 0.8,
                  }}
                  transition="all 0.2s"
                >
                  Update Name
                </Button>
              </VStack>
            </Box>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default UpdateNamePage;
