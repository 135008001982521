import { useEffect, useState } from "react";
import useAuthStore from "../store/authStore";
import useShowToast from "./useShowToast";
import { firestore } from "../firebase/firebase";

import {
  doc,
  getDoc,
  setDoc,
  collection,
  getDocs,
  query,
  where,
  updateDoc,
  orderBy,
} from "firebase/firestore";

const useGetMemories = (showAll) => {
  const [isLoading, setIsLoading] = useState(true);
  const [memories, setMemories] = useState([]);
  const showToast = useShowToast();

  useEffect(() => {
    const getEventMemories = async () => {
      setIsLoading(true);
      const q = query(
        collection(firestore, "memories"),
        orderBy("createdAt", "desc")
      );

      try {
        const querySnapshot = await getDocs(q);
        const memoriesList = [];

        querySnapshot.forEach((doc) => {
          memoriesList.push({ id: doc.id, ...doc.data() });
        });

        if (showAll) {
          setMemories(memoriesList);
        } else {
          setMemories(memoriesList.slice(0, 3));
        }
      } catch (error) {
        showToast("Error", error.message, "error");
      } finally {
        setIsLoading(false);
      }
    };

    getEventMemories();
  }, [showToast, setMemories]);

  return { isLoading, memories };
};

export default useGetMemories;
