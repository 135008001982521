import {
  Container,
  Flex,
  Link,
  Button,
  Skeleton,
  SkeletonCircle,
  Text,
  VStack,
} from "@chakra-ui/react";
import PageFeedPosts from "../../components/FeedPosts/PageFeedPosts";
import ProfileHeader from "../../components/Profile/ProfileHeader";
import ProfileTabs from "../../components/Profile/ProfileTabs";
// import ProfilePosts from "../../components/Profile/ProfilePosts";
import useGetUserProfileByUsername from "../../hooks/useGetUserProfileByUsername";
import { useParams } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import useAuthStore from "../../store/authStore";
import { WarningTwoIcon } from "@chakra-ui/icons";

const DeleteAccountPage = () => {

  return (
    <Container
      // bg={"blue"}
      px={-1}
      maxW="container.lg"
      py={0}
    >
      <Flex
        py={0}
        alignContent={"center"}
        // px={4}
        // pl={{ base: 4, md: 10 }}
        w={"full"}
        // mx={"auto"}
        flexDirection={"column"}
      >
        <Text mt={12} mx={8} fontSize={"20"} fontWeight={"bold"}>
          How to Delete Your Data
        </Text>

        <Text mx={8} fontSize={"18"}>
          If you wish to delete your data, you can do so by following the steps
          below:
        </Text>

        <Text mt={12} mx={8} fontSize={"18"} fontWeight={"bold"}>
          Automated Method
        </Text>

        <Text mx={8} fontSize={"18"}>
          Tap the button below to send an automated data deletion request:
        </Text>

        <Link mx={8} 
          href="mailto:zecweb@gmail.com?subject=Data%20deletion%20request"
          isExternal
        >
          <Button
          mt={4}
            colorScheme="red"
            // bg={"red.400"}
            // color={"white"}
            variant={"outline"}
            // _hover={{ bg: "red.500" }}
            size={{ base: "md", md: "md" }}
            // onClick={onOpen}
          >
            Send Data Deletion Request
          </Button>
        </Link>

        <Text mt={12} mx={8} fontSize={"20"} fontWeight={"bold"}>
          Manual Method
        </Text>

        <Text mx={8} fontSize={"18"}>
          Follow these steps to delete your data manually:
        </Text>

        <Text mx={8} fontSize={"14"}>
          Step 1: Log in to your email.
        </Text>

        <Text mx={8} fontSize={"14"}>
          Step 2: Create a new mail with recepient zecweb@gmail.com
        </Text>

        <Text mx={8} fontSize={"14"}>
          Step 3: Set email title to "Data deletion request"
        </Text>

        {/* {!isLoading && userProfile && <ProfileHeader update={update} />}
        {isLoading && <ProfileHeaderSkeleton />} */}
      </Flex>
    </Container>
  );
};

export default DeleteAccountPage;

// // skeleton for profile header
// const ProfileHeaderSkeleton = () => {
//   return (
//     <Flex
//       gap={{ base: 4, sm: 10 }}
//       py={10}
//       direction={{ base: "column", sm: "row" }}
//       justifyContent={"center"}
//       alignItems={"center"}
//     >
//       <Skeleton width={"full"} height={"400px"} />

//       <VStack
//         alignItems={{ base: "flex-start", sm: "flex-start" }}
//         gap={4}
//         width={"full"}
//         // mx={"auto"}
//         flex={1}
//       >
//         <Skeleton mx={8} height="16px" width="150px" />
//         <Skeleton mx={8} height="16px" width="150px" />

//       </VStack>
//     </Flex>
//   );
// };

// const UserNotFound = () => {
//   return (
//     <Flex mt={8} flexDir="column" textAlign={"center"} mx={"auto"}>
//       <Text fontSize={"2xl"}>User Not Found</Text>
//       <Link
//         as={RouterLink}
//         to={"/"}
//         color={"blue.500"}
//         w={"max-content"}
//         mx={"auto"}
//       >
//         Go home
//       </Link>
//     </Flex>
//   );
// };

// const UserDeleted = () => {
//   return (
//     <Flex
//       mt={32}
//       alignItems={"center"}
//       flexDir="column"
//       textAlign={"center"}
//       mx={"auto"}
//     >
//       <WarningTwoIcon boxSize={6} />

//       <Text mt={2} fontSize={"xl"}>
//         This Account has been deleted
//       </Text>
//       <Link
//         mt={2}
//         as={RouterLink}
//         to={"/"}
//         color={"blue.500"}
//         w={"max-content"}
//         mx={"auto"}
//       >
//         Go home
//       </Link>
//     </Flex>
//   );
// };
