import {
  Box,
  Flex,
  Link,
  Text,
  VStack,
  Skeleton,
  SkeletonCircle,
  Divider,
  HStack,
  Card,
  useColorModeValue,
  Container,
  CardBody,
  Tag,
  TagLabel,
} from "@chakra-ui/react";
import SuggestedHeader from "./SuggestedHeader";
import { useRef, useState, useEffect } from "react";

import SuggestedGroup from "./SuggestedGroup";
import useGetSuggestedGroups from "../../hooks/useGetSuggestedGroups";

const SuggestedGroups = ({ isFollowing }) => {
  const { isLoading, suggestedUsers } = useGetSuggestedGroups(isFollowing);
  // const [indexz, setIndexz] = useState(0);

  const [categoryTest, setCategoryTest] = useState(localStorage.category ? localStorage.category : 0);



  useEffect(() => {
    const c = localStorage.getItem("category");


    if (c) {
      setCategoryTest(c);

    } else {
      setCategoryTest(0);

    }

  }, []);

  const categoriesTest = (indexx) => {
    localStorage.setItem("category", indexx);
    setCategoryTest(indexx);
  };


  const typesArray = [
    "Locals 🏠",
    "Drinks 🍻",
    "Sports 🏋️",
    // "Games 🎲",
    "Arts 🎭",
    "Gigs 🎤",
  ];

  const typesValueArray = [
    "locals",
    "drinks",
    "sports",
    // "games",
    "arts",
    "gigs",
  ];

  const visibleColor = useColorModeValue("white", "gray.800");


  const [comment, setComment] = useState(typesValueArray[categoryTest ? categoryTest : 0]);


  // optional: render loading skeleton
  // if (isLoading) return null;

  return (
    <Container px={0} 
    // maxW="container.sm" 
    py={0}>
      <VStack
      pt={4}
        // background={"blue"}

          bg={visibleColor}

        // pt={0}
        px={0}
        gap={0}
        align="flex-start"
      >
        {/* <SuggestedHeader /> */}

        {/* {suggestedUsers.length !== 0 && (
        <Flex alignItems={"center"} justifyContent={"space-between"} w={"full"}>
          <Text fontSize={16} fontWeight={"bold"} color={"gray.500"}>
            Discover groups
          </Text>

          <Text
            fontSize={12}
            fontWeight={"bold"}
            _hover={{ color: "gray.400" }}
            cursor={"pointer"}
          ></Text>
        </Flex>
      )} */}

        <VStack
          // bg={"red"}
          mb={2}
          align="flex-start"
        >
          <HStack
            mt={0}
            px={1}
            spacing={2}
            overflowX="auto"
            sx={{
              "::-webkit-scrollbar": {
                display: "none",
              },
            }}
          >
            {typesArray.map((data, index) => (
              <Tag
                userSelect={"none"}
                onClick={() => {
                  // if (index == 0) {
                  setComment(typesValueArray[index]);
                  // setIndexz(index);

                  categoriesTest(index);
                  // } else {
                  //   setComment("unverified");
                  //   setIndexz(index);
                  // }

                  console.log(index);
                }}
                flexShrink="0"
                size="lg"
                key={index}
                borderRadius="full"
                variant="subtle"
                colorScheme={categoryTest == index ? "green" : "gray"}
              >
                <TagLabel>{data}</TagLabel>
              </Tag>
            ))}
          </HStack>
        </VStack>

        {isLoading &&
          [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((_, idx) => (
            <VStack w={"full"} p={4} key={idx} gap={4} alignItems={"flex-start"} mb={5}>
              <Flex gap="2">
                <SkeletonCircle size="10" />
                <VStack gap={2} alignItems={"flex-start"}>
                  <Skeleton height="10px" w={"300px"} />
                  <Skeleton height="10px" w={"300px"} />
                </VStack>
              </Flex>
              {/* <Skeleton w={"full"}>
              <Box h={"400px"}>contents wrapped</Box>
            </Skeleton> */}
            </VStack>
          ))}

        <Card mt={1} pb={0} w={"full"} mb={0}>
          <CardBody px={4} pt={1} pb={0}>
            {suggestedUsers.length !== 0 &&
              suggestedUsers
                .filter((item) => item.type === comment)
                .map((user) => <SuggestedGroup user={user} key={user.id} />)}
          </CardBody>
        </Card>

        {/* {suggestedUsers.length !== 0 && <Divider my={0} bg={"gray.8000"} />} */}
      </VStack>
    </Container>
  );
};

export default SuggestedGroups;
