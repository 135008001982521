import {
  Avatar,
  Box,
  Button,
  Flex,
  VStack,
  Container,
  HStack,
  Text,
  Stack,
  useColorModeValue,
  Spacer,
  Divider,
} from "@chakra-ui/react";
import {
  DeleteIcon,
  ChevronRightIcon,
  CalendarIcon,
  EmailIcon,
} from "@chakra-ui/icons";
import useFollowGroup from "../../hooks/useFollowGroup";
import useAuthStore from "../../store/authStore";
import { Link } from "react-router-dom";
import useShowToast from "../../hooks/useShowToast";
import { useNavigate } from "react-router-dom";
import useApplyGroup from "../../hooks/useApplyGroup";
import useUserProfileStore from "../../store/userProfileStore";
import { HiOutlineUserGroup } from "react-icons/hi";

import { WhatsappGreenLogo, WhatsappLogoGray } from "../../assets/constants";

const SuggestedGroup = ({ user, setUser }) => {
  const { isFollowing, isUpdating, handleFollowUser } = useFollowGroup(user.id);
  const authUser = useAuthStore((state) => state.user);
  const showToast = useShowToast();
  const navigate = useNavigate();
  const { userProfile } = useUserProfileStore();

  const { handleMemberGroup } = useApplyGroup(
    userProfile ? userProfile : {},
    user.id
  );

  const color = useColorModeValue("gray.500", "gray.400");


  const onFollowUser = async () => {
    await handleFollowUser();

    // showToast("", "Updated successfully", "success");

    setUser({
      ...user,
      followers: isFollowing
        ? user.followers.filter(
            (follower) => authUser && follower.uid !== authUser.uid
          )
        : [...user.followers, authUser],
    });
  };

  return (
    // <Link to={`/${user.username}`}>
    <Container
      // bg={"red"}
      // maxW={"container.sm"}
      py={0}
      px={0}
    >
      <Flex userSelect={"none"} gap={2} flexDirection={"column"}>
        <Stack justifyContent={"space-between"} spacing={0} direction="row">
          {/* <Link to={`/${user.username}`}> */}
          <Box
            w={"full"}
            onClick={() => {
              navigate(`/${user.username}`);
            }}
          >
            <Flex
              // bg={"red"}
              mt={2}
              ml={0}
              flexDirection={"row"}
              // bg={"red"}
              alignItems={"center"}
              gap={2}
            >
              {/* <Link to={`/${user.username}`}> */}
              <Avatar
                pointerEvents={"none"}
                userSelect={"none"}
                src={user.profilePicURL}
                size={"md"}
              />
              {/* </Link> */}
              <VStack spacing={1} alignItems={"flex-start"}>
                {/* <Link to={`/${user.username}`}> */}
                <Box userSelect={"none"} fontSize={16} fontWeight={"semibold"}>
                  {user.fullName}
                </Box>
                {/* </Link> */}
                {/* <Box fontSize={12} color={"gray.500"}>
                  {user.users} members
                </Box> */}

                <HStack
                ml={1}
                spacing={1}
                alignItems={"flex-start"}
                >
                  <HiOutlineUserGroup size={14} />

                  <Text
                  fontWeight={"semibold"}
                  fontSize={"14"}
                  color={color}
                  
                  >{user && user.memberList.length}</Text>

                  {/* <ChevronRightIcon ml={"0"} boxSize={4} /> */}
                </HStack>
              </VStack>
            </Flex>
          </Box>
          {/* </Link> */}

          {/* <Spacer
            bg={"yellow"}
            onClick={() => {
              navigate(`/${user.username}`);
            }}
          /> */}

          <Button
            mt={2}
            userSelect={"none"}
            alignSelf={"center"}
            fontSize={13}
            bg={"transparent"}
            variant="outline"
            pr={4}
            py={1}
            h={"max-content"}
            fontWeight={"medium"}
            colorScheme="whatsapp"
            // color={"blue.400"}
            // cursor={"pointer"}
            // _hover={{ color: "white" }}
            onClick={() => {
              if (authUser) {
                onFollowUser();
                handleMemberGroup();
              } else {
                showToast("", "You are not logged in", "error");
              }
            }}
            isLoading={isUpdating}
          >
            {isFollowing ? "Unfollow" : "Follow"}
          </Button>
        </Stack>

        <Divider my={1} bg={"gray.8000"} />
      </Flex>
    </Container>
    // </Link>
  );
};

export default SuggestedGroup;
