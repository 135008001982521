import { Box, Flex, Icon } from '@chakra-ui/react';
import { Link, useLocation } from 'react-router-dom';
import { HiOutlineUserGroup, HiUserGroup } from 'react-icons/hi';
import { HiOutlineTicket, HiTicket } from 'react-icons/hi2';
import { CgProfile } from 'react-icons/cg';
import { BiCalendarEvent, BiSolidCalendarEvent } from 'react-icons/bi';
import { useEffect, useState } from 'react';

const BottomNav = () => {
  const location = useLocation();
  const [isIOS, setIsIOS] = useState(false);

  useEffect(() => {
    const isIOSDevice = /iPad|iPhone|iPod/.test(navigator.userAgent);
    setIsIOS(isIOSDevice);
  }, []);

  const navItems = [
    { 
      iconOutline: BiCalendarEvent,
      iconFilled: BiSolidCalendarEvent,
      path: '/', 
      label: 'Invitations'  // Keep labels for accessibility
    },
    { 
      iconOutline: HiOutlineTicket,
      iconFilled: HiTicket,
      path: '/user_tickets', 
      label: 'Tickets' 
    },
    // { 
    //   iconOutline: HiOutlineUserGroup,
    //   iconFilled: HiUserGroup,
    //   path: '/groups', 
    //   label: 'Groups' 
    // },
    { 
      iconOutline: CgProfile,
      iconFilled: CgProfile,
      path: '/user_profile', 
      label: 'Account' 
    },
  ];

  const colors = {
    activeColor: 'gray.900',
    inactiveColor: 'gray.400',
    bgColor: 'rgba(255, 255, 255, 0.98)',
    borderColor: 'rgba(0, 0, 0, 0.08)',
    activeBg: 'blackAlpha.50',  // Light black background for active state
  };

  return (
    <Box 
      position="fixed" 
      bottom={0} 
      left={0} 
      right={0} 
      display={{ base: 'block', md: 'none' }}
      style={{
        WebkitTapHighlightColor: 'transparent',
        WebkitTouchCallout: 'none',
        WebkitUserSelect: 'none',
        backdropFilter: 'blur(20px)',
        backgroundColor: colors.bgColor,
        paddingBottom: 'env(safe-area-inset-bottom)',
      }}
      borderTop="1px solid"
      borderColor={colors.borderColor}
      boxShadow="0 -4px 20px rgba(0, 0, 0, 0.05)"
    >
      <Flex justify="space-around" align="center" px={4} py={3}>
        {navItems.map((item) => {
          const isActive = location.pathname === item.path;
          
          return (
            <Link 
              key={item.path} 
              to={item.path} 
              style={{ 
                WebkitTapHighlightColor: 'transparent',
                WebkitTouchCallout: 'none',
                WebkitUserSelect: 'none',
              }}
              aria-label={item.label}
            >
              <Flex 
                justify="center"
                align="center"
                p={2.5}
                rounded="xl"
                color={isActive ? colors.activeColor : colors.inactiveColor}
                bg={isActive ? colors.activeBg : 'transparent'}
                transition="all 0.2s ease"
                _active={{
                  transform: 'scale(0.9)',
                }}
              >
                <Icon 
                  as={isActive ? item.iconFilled : item.iconOutline}
                  boxSize={7}
                  transition="all 0.2s ease"
                  fontWeight={isActive ? "bold" : "normal"}
                  style={{
                    filter: isActive 
                      ? 'drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2))'
                      : 'none'
                  }}
                />
              </Flex>
            </Link>
          );
        })}
      </Flex>
    </Box>
  );
};

export default BottomNav; 