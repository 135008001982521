import {
  Container,
  Flex,
  Link,
  Skeleton,
  SkeletonCircle,
  Text,
  Input,
  Spinner,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Box,
  Select,
  Textarea,
  useDisclosure,
  InputGroup,
  InputLeftElement,
  VStack,
  useColorModeValue,
  Card,
  CardBody,
} from "@chakra-ui/react";
import { CalendarIcon } from "@chakra-ui/icons";

import { WhatsappLogo, WhatsappGreenLogo } from "../../assets/constants";
import PageFeedPosts from "../../components/FeedPosts/PageFeedPosts";
import ProfileHeader from "../../components/Profile/ProfileHeader";
import ProfileTabs from "../../components/Profile/ProfileTabs";
import ProfilePosts from "../../components/Profile/ProfilePosts";
import useGetUserProfileByUsername from "../../hooks/useGetUserProfileByUsername";
import { useParams } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import useAuthStore from "../../store/authStore";
import useGetFeedPostById from "../../hooks/useGetFeedPostById";
// import ProfileHeaderSkeleton from "../ProfilePage/ProfilePage/ProfileHeaderSkeleton";
import FeedPostDetails from "../../components/FeedPosts/FeedPostDetails";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useRef, useState } from "react";
import usePreviewImg from "../../hooks/usePreviewImg";
import useShowToast from "../../hooks/useShowToast";
import usePostStore from "../../store/postStore";
import { useLocation } from "react-router-dom";
import useUserProfileStore from "../../store/userProfileStore";
import { firestore, storage } from "../../firebase/firebase";
import { getDownloadURL, ref, uploadString } from "firebase/storage";
import { StandaloneSearchBox, useJsApiLoader } from "@react-google-maps/api";
import { useNavigate } from "react-router-dom";
import GroupsCreatorModal from "../../components/Modals/GroupsCreatorModal";

import {
  addDoc,
  where,
  getDocs,
  query,
  arrayUnion,
  collection,
  doc,
  getDoc,
  updateDoc,
} from "firebase/firestore";

const CreatePage = () => {
  const { userProfile } = useUserProfileStore();

  const libraries = ["places"];
  const inputRef = useRef();

  const groups = [
    "nDPUvVnEypUeLEMO14Ft",
    "fZpatsg3z3yTlnmoKdnI",
    "KTiBj6iCVqiRn3WXO5gU",
  ];

  const [value, setValue] = useState(groups[0]);

  const handlePlaceChanged = () => {
    const [place] = inputRef.current.getPlaces();
    if (place) {
      setLocation(place.name);
      setPlaceLocation(place.url);
      setAddress(place.formatted_address);
      console.log(place.formatted_address);
      console.log(place.name);
      console.log(place.geometry.location.lat());
      console.log(place.geometry.location.lng());
    }
  };

  const {
    isOpen: isOpenFollowingModal,
    onOpen: onOpenFollowingModal,
    onClose: onCloseFollowingModal,
  } = useDisclosure();

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: import.meta.env.VITE_GOOGLE_API_KEY,
    libraries,
  });

  const bgTheme = useColorModeValue(`white`, `black`);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [titleg, setTitleg] = useState("");
  const [captiong, setCaptiong] = useState("");
  const [whatsappg, setWhatsappg] = useState("");
  const [usernameg, setUsernameg] = useState("");

  const {
    isOpen: isOpenGoingModal,
    onOpen: onOpenGoingModal,
    onClose: onCloseGoingModal,
  } = useDisclosure();

  const { postId } = useParams();
  // const { isLoading, post } = useGetFeedPostById(postId);
  const authUser = useAuthStore((state) => state.user);

  // const userNotFound = !isLoading && !post;

  const [startDate, setStartDate] = useState("");
  const [title, setTitle] = useState("");
  const [caption, setCaption] = useState("");
  const [whatsapp, setWhatsapp] = useState("");

  const [placeLocation, setPlaceLocation] = useState("");
  const [address, setAddress] = useState("");
  const imageRef = useRef(null);
  // const { handleImageChange, selectedFile, setSelectedFile } = usePreviewImg();
  const showToast = useShowToast();
  const { pathname } = useLocation();
  const { isLoading, handleCreatePost } = useCreatePost();
  const [location, setLocation] = useState("");
  const navigate = useNavigate();

  const handlePostCreation = async () => {
    try {
      await handleCreatePost(
        // selectedFile,
        title,
        caption,
        location,
        whatsapp,
        startDate,
        placeLocation,
        address,
        value
      );
      onClose();
      setStartDate("");
      setTitle("");
      setCaption("");
      setLocation("");
      // setSelectedFile(null);
      setPlaceLocation("");
      setWhatsapp("");
      navigate("/");
    } catch (error) {
      showToast("Error", error.message, "error");
    }
  };

  // if (userNotFound) return <CreatePageNotFound />;

  // const visitingOwnProfileAndAuth = authUser && authUser.username === username;

  // const bgTheme = useColorModeValue(`#FFC0CB`, `#FFFF00`);

  const WhatsappThemeLogo = useColorModeValue(WhatsappGreenLogo, WhatsappLogo);

  const handleCreateGroup = async () => {
    try {
      // const newUser = await signInWithGoogle();
      // if (!newUser && error) {
      //   showToast("Error", error.message, "error");
      //   return;
      // }
      // const userRef = doc(firestore, "users", newUser.user.uid);
      // const userSnap = await getDoc(userRef);

      // if (userSnap.exists()) {
      //   // login
      //   const userDoc = userSnap.data();
      //   localStorage.setItem("user-info", JSON.stringify(userDoc));
      //   loginUser(userDoc);
      // } else {
      // signup

      if (!authUser) {
        showToast("Error", "You must be logged in", "error");
        return;
      }

      const groupsRef = collection(firestore, "groups");

      const q = query(groupsRef, where("whatsapp", "==", whatsappg));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        showToast("Error", "WhatsApp group already exists", "error");
        return;
      }

      const qUsername = query(groupsRef, where("username", "==", usernameg));
      const querySnapshotUsername = await getDocs(qUsername);

      if (!querySnapshotUsername.empty) {
        showToast("Error", "Username already exists", "error");
        return;
      }

      const userDoc = {
        createdBy: authUser.uid,
        uid: "sdfsdfsdfds",
        username: usernameg,
        fullName: titleg,
        bio: captiong,
        whatsapp: whatsappg,
        profilePicURL: "",
        followers: [],
        following: [],
        posts: [],
        createdAt: Date.now(),
        verified: false,
        users: 0,
        lastVerified: new Date(),
      };

      const postDocRef = await addDoc(collection(firestore, "groups"), userDoc);

      // if (userProfile)
      //   setUserProfile({
      //     ...userProfile,
      //     groups: [...userProfile.groups, postDocRef.id],
      //   });

      const userRef = doc(firestore, "users", authUser.uid);
      // await deleteDoc(doc(firestore, "posts", post.id));

      await updateDoc(userRef, {
        groups: arrayUnion(postDocRef.id),
      });

      // setAuthUser({
      //   ...authUser,
      //   groups: [...authUser.groups, postDocRef.id],
      // });

      // localStorage.setItem(
      //   "user-info",
      //   JSON.stringify({
      //     ...authUser,
      //     groups: [...authUser.groups, postDocRef.id],
      //   })
      // );

      // await setDoc(doc(firestore, "groups", "fsdfdsfdsfsd"), userDoc);
      // localStorage.setItem("user-info", JSON.stringify(userDoc));
      // loginUser(userDoc);
      // }
      showToast("Success", "Group created successfully", "success");

      onCloseGoingModal();
      setTitleg("");
      setCaptiong("");
      setWhatsappg("");
      navigate("/");
    } catch (error) {
      showToast("Error", error.message, "error");
    }
  };

  return (
    <>
      <Container py={0}>
        {!isLoaded && (
          <Flex
            flexDir="column"
            h="100vh"
            alignItems="center"
            justifyContent="center"
          >
            <Spinner size="xl" />
          </Flex>
        )}

        {isLoaded && (
          <Flex px={1} w={"full"} mx={"auto"} flexDirection={"column"}>
            {/* <Card mb={8} mt={0}>
              <CardBody> */}
                <Text mt={8} mb={8} fontSize={24}>
                  Create your own meetup 🚀
                </Text>

                {authUser && (
                  <Box
                    onClick={() => {
                      if (!authUser) {
                        onOpenModal();
                        return;
                      } else {
                        onOpenFollowingModal();
                      }
                    }}
                  >
                    <Select
                      variant="filled"
                      size="md"
                      // value={authUser ? isGoing.toString() : "Going?"}
                      placeholder="Select group"
                      onClick={() => {
                        // if (!authUser) {
                        //   onOpenModal();
                        // } else {
                        //   // openInNewTab(post.whatsapp);
                        // }
                      }}
                      onChange={(e) => {
                        setValue(e.target.value);

                        // handleGoingPost();
                      }}
                    >
                      {authUser.groups &&
                        authUser.groups.length > 0 &&
                        authUser.groups.map((id, idx) => (
                          <option key={idx} value={id}>
                            {id}
                          </option>
                        ))}
                    </Select>
                  </Box>
                )}

                {/* <Box cursor={"pointer"}>
                  <Button
                    size={"xs"}
                    bg={"transparent"}
                    fontSize={12}
                    color={"blue.500"}
                    fontWeight={"bold"}
                    _hover={{
                      color: "white",
                    }}
                    transition={"0.2s ease-in-out"}
                    onClick={() => {
                      if (!authUser) {
                        // onOpenModal();
                      } else {
                        onOpenGoingModal();
                      }
                    }}
                    // isLoading={
                    //   isUpdating
                    // }
                  >
                    {"Create group"}
                  </Button>
                </Box> */}

                <InputGroup mt={2} mb={0}>
                  <InputLeftElement mt={1} pointerEvents="none">
                    <CalendarIcon />
                  </InputLeftElement>

                  <Input
                    // borderColor="grey.900"
                    // color="tomato"
                    required={true}
                    placeholder="fsdfds"
                    size="lg"
                    type="datetime-local"
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                </InputGroup>

                <Input
                  // borderColor="whiteAlpha.900"
                  mt={4}
                  type="text"
                  placeholder="Title..."
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
                <Textarea
                  // borderColor="whiteAlpha.900"
                  mt={4}
                  placeholder="What's happening...?"
                  value={caption}
                  onChange={(e) => setCaption(e.target.value)}
                />

                <StandaloneSearchBox
                  onLoad={(ref) => (inputRef.current = ref)}
                  onPlacesChanged={handlePlaceChanged}
                >
                  <input
                    type="text"
                    className="form-control"
                    placeholder="📍 Enter Location"
                    style={{
                      marginTop: "16px",
                      marginBottom: "32px",
                      background: {},
                      // borderRadius: `4px`,
                      boxSizing: `border-box`,
                      // border: `1px solid transparent`,
                      width: "100%",
                      height: `32px`,
                      padding: `0 12px`,
                      borderRadius: `3px`,
                      boxShadow: `0 1px 2px rgba(0, 0, 0, 0.1)`,
                      fontSize: `14px`,
                      outline: `none`,
                      textOverflow: `ellipses`,
                      marginBottom: "8px",
                      outlineColor: "#99944",
                      outlineWidth: "thin",
                      // outlineStyle: "solid",
                    }}
                  />
                </StandaloneSearchBox>

                {/* <Input
  mt={4}
  mb={4}
  placeholder="Where is taking place..."
  value={location}
  onChange={(e) => setLocation(e.target.value)}
/> */}
                {/* 
                <InputGroup mt={4} mb={0}>
                  <InputLeftElement pointerEvents="none">
                    <WhatsappThemeLogo />
                  </InputLeftElement>
                  <Input
                    // borderColor="whiteAlpha.900"
                    mt={0}
                    mb={0}
                    size={"md"}
                    placeholder="Group Link (Optional)"
                    value={whatsapp}
                    onChange={(e) => setWhatsapp(e.target.value)}
                  />
                </InputGroup> */}
              {/* </CardBody>
            </Card> */}

            <Button
              mr={0}
              mb={32}
              border="2px"
              borderColor="gray.500"
              variant={"outline"}
              onClick={handlePostCreation}
              isLoading={isLoading}
            >
              Post
            </Button>

            <Modal isOpen={isOpenGoingModal} onClose={onCloseGoingModal}>
              <ModalOverlay />
              <ModalContent
                bg={bgTheme}
                border={"1px solid gray"}
                maxW={"400px"}
              >
                <ModalHeader>Create your own group 🚀</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                  <Card mb={8} mt={10}>
                    <CardBody>
                      <InputGroup mt={4} mb={0}>
                        <InputLeftElement pointerEvents="none">
                          <WhatsappThemeLogo />
                        </InputLeftElement>
                        <Input
                          borderColor="whiteAlpha.900"
                          mt={0}
                          mb={0}
                          size={"md"}
                          placeholder="Group Link"
                          value={whatsappg}
                          onChange={(e) => setWhatsappg(e.target.value)}
                        />
                      </InputGroup>

                      <Input
                        borderColor="whiteAlpha.900"
                        mt={4}
                        type="text"
                        placeholder="Username"
                        value={usernameg}
                        onChange={(e) => setUsernameg(e.target.value)}
                      />

                      <Input
                        borderColor="whiteAlpha.900"
                        mt={4}
                        type="text"
                        placeholder="Name..."
                        value={titleg}
                        onChange={(e) => setTitleg(e.target.value)}
                      />
                      <Textarea
                        borderColor="whiteAlpha.900"
                        mt={4}
                        placeholder="About...?"
                        value={captiong}
                        onChange={(e) => setCaptiong(e.target.value)}
                      />
                    </CardBody>
                  </Card>
                </ModalBody>

                <ModalFooter>
                  <Button
                    mr={0}
                    onClick={handleCreateGroup}
                    // isLoading={isLoading}
                  >
                    Create
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </Flex>
        )}

        {isOpenFollowingModal ? (
          <GroupsCreatorModal
            isOpen={isOpenFollowingModal}
            onClose={onCloseFollowingModal}
            userProfile={userProfile}
          />
        ) : null}
      </Container>
    </>
  );
};

export default CreatePage;

const CreatePageHeaderSkeleton = () => {
  return (
    <Flex
      gap={{ base: 4, sm: 10 }}
      py={10}
      direction={{ base: "column", sm: "row" }}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <SkeletonCircle size="24" />

      <VStack
        alignItems={{ base: "center", sm: "flex-start" }}
        gap={2}
        mx={"auto"}
        flex={1}
      >
        <Skeleton height="12px" width="150px" />
        <Skeleton height="12px" width="100px" />
      </VStack>
    </Flex>
  );
};

const CreatePageNotFound = () => {
  return (
    <Flex mt={8} flexDir="column" textAlign={"center"} mx={"auto"}>
      <Text fontSize={"2xl"}>User Not Found</Text>
      <Link
        as={RouterLink}
        to={"/"}
        color={"blue.500"}
        w={"max-content"}
        mx={"auto"}
      >
        Go home
      </Link>
    </Flex>
  );
};

function useCreatePost() {
  const showToast = useShowToast();
  const [isLoading, setIsLoading] = useState(false);
  const authUser = useAuthStore((state) => state.user);
  const createPost = usePostStore((state) => state.createPost);
  const addPost = useUserProfileStore((state) => state.addPost);
  const userProfile = useUserProfileStore((state) => state.userProfile);
  const { pathname } = useLocation();

  const handleCreatePost = async (
    // selectedFile,
    title,
    caption,
    location,
    whatsapp,
    startDate,
    placeLocation,
    address,
    value
  ) => {
    if (isLoading || !authUser) {
      return;
    }

    if (!authUser) {
      showToast("Error", "You must be logged in", "error");
      return;
    }
    // if (!selectedFile) throw new Error("Please select an image");
    setIsLoading(true);
    const newPost = {
      title: title,
      caption: caption,
      placeName: location,
      whatsapp: whatsapp,
      likes: [],
      goingList: [],
      comments: [],
      createdAt: Date.now(),
      createdBy: value,
      startAt: new Date(startDate),
      placeLocation: placeLocation,
      address: address,
      postedBy: authUser.uid,
    };

    try {
      const postDocRef = await addDoc(collection(firestore, "events"), newPost);
      const userDocRef = await getDoc(doc(firestore, "groups", value));
      // const imageRef = ref(storage, `posts/${postDocRef.id}`);

      if (userDocRef.exists()) {
        const userDocRefz = doc(firestore, "groups", value);

        await updateDoc(userDocRefz, { posts: arrayUnion(postDocRef.id) });
      }

      // await uploadString(imageRef, selectedFile, "data_url");
      // const downloadURL = await getDownloadURL(imageRef);

      // await updateDoc(postDocRef, { imageURL: downloadURL });
      // await updateDoc(postDocRef, {
      //   imageURL:
      //     "https://www.meetup.com/_next/image/?url=https%3A%2F%2Fsecure-content.meetupstatic.com%2Fimages%2Fclassic-events%2F508388773%2F676x380.webp&w=3840&q=75",
      // });

      // newPost.imageURL = downloadURL;

      // if (userProfile.uid === authUser.uid)
      createPost({ ...newPost, id: postDocRef.id });

      // if (pathname !== "/" && userProfile.uid === authUser.uid)
      addPost({ ...newPost, id: postDocRef.id });

      showToast("Success", "Post created successfully", "success");
    } catch (error) {
      showToast("Error", error.message, "error");
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, handleCreatePost };
}

// // skeleton for profile header
// const ProfileHeaderSkeleton = () => {
//   return (
//     <Flex
//       gap={{ base: 4, sm: 10 }}
//       py={10}
//       direction={{ base: "column", sm: "row" }}
//       justifyContent={"center"}
//       alignItems={"center"}
//     >
//       <SkeletonCircle size="24" />

//       <VStack
//         alignItems={{ base: "center", sm: "flex-start" }}
//         gap={2}
//         mx={"auto"}
//         flex={1}
//       >
//         <Skeleton height="12px" width="150px" />
//         <Skeleton height="12px" width="100px" />
//       </VStack>
//     </Flex>
//   );
// };

// const UserNotFound = () => {
//   return (
//     <Flex mt={8} flexDir="column" textAlign={"center"} mx={"auto"}>
//       <Text fontSize={"2xl"}>User Not Found</Text>
//       <Link
//         as={RouterLink}
//         to={"/"}
//         color={"blue.500"}
//         w={"max-content"}
//         mx={"auto"}
//       >
//         Go home
//       </Link>
//     </Flex>
//   );
// };
