import {
  Alert,
  AlertIcon,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogCloseButton,
  AlertDialogBody,
  AlertDialogFooter,
  Container,
  Avatar,
  AvatarGroup,
  RadioGroup,
  Stack,
  Radio,
  Box,
  Button,
  Flex,
  Card,
  CardBody,
  Image,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  FormControl,
  Heading,
  ModalHeader,
  ModalFooter,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  Center,
  Textarea,
  Divider,
  Input,
  VStack,
  Spacer,
  useDisclosure,
  useColorModeValue,
  IconButton,
} from "@chakra-ui/react";
import { useRef, useState } from "react";
import FollowerModal from "../Modals/FollowerModal";
import FollowingModal from "../Modals/FollowingModal";
import { Link } from "react-router-dom";
import {
  PhoneIcon,
  EmailIcon,
  CalendarIcon,
  ChevronRightIcon,
  WarningIcon,
  NotAllowedIcon,
} from "@chakra-ui/icons";
import { firestore, storage, auth } from "../../firebase/firebase";

import {
  MessagesLogo,
  NotificationsLogo,
  UnlikeLogo,
  SearchLogo,
  WhatsappLogo,
  LinkedinLogo,
  LinkedinLightLogo,
  InstagramMobileLogo,
  InstagramMobileLightLogo,
  PhoneLogo,
  PhoneLightLogo,
  PhoneBlackLogo,
  WhatsappGreenLogo,
  ShareLightLogo,
  ShareBlackLogo,
} from "../../assets/constants";

import useUserProfileStore from "../../store/userProfileStore";
import useAuthStore from "../../store/authStore";
import EditProfile from "./EditProfile";
import VerifyPhoneNumber from "./VerifyPhoneNumber";
import useFollowGroup from "../../hooks/useFollowGroup";
import useLogout from "../../hooks/useLogout";
import usePreviewImg from "../../hooks/usePreviewImg";
import useShowToast from "../../hooks/useShowToast";
import { useLocation } from "react-router-dom";
import { StandaloneSearchBox, useJsApiLoader } from "@react-google-maps/api";
import usePostStore from "../../store/postStore";
import { useNavigate } from "react-router-dom";
import { CgSpinner } from "react-icons/cg";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";

import useReportUser from "../../hooks/useReportUser";
import useBlockUser from "../../hooks/useBlockUser";

import {
  addDoc,
  where,
  getDocs,
  query,
  arrayUnion,
  collection,
  doc,
  getDoc,
  updateDoc,
} from "firebase/firestore";
import useApplyGroup from "../../hooks/useApplyGroup";
import userDummyApplyGroup from "../../hooks/useDummyApplyGroup";
import { ChevronRight } from "@mui/icons-material";

const GroupProfileHeader = ({ userId }) => {
  const libraries = ["places"];
  const inputRef = useRef();

  const handlePlaceChanged = () => {
    const [place] = inputRef.current.getPlaces();
    if (place) {
      setLocation(place.name);
      setPlaceLocation(place.url);
      setAddress(place.formatted_address);
      // console.log(place.formatted_address);
      // console.log(place.name);
      // console.log(place.geometry.location.lat());
      // console.log(place.geometry.location.lng());
    }
  };
  const navigate = useNavigate();

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: import.meta.env.VITE_GOOGLE_API_KEY,
    libraries,
  });
  const { userProfile } = useUserProfileStore();
  const authUser = useAuthStore((state) => state.user);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { handleLogout, isLoggingOut } = useLogout();

  const [reportReason, setReportReason] = useState("behaviour");

  const { isReportUpdating, handleReportUser } = useReportUser(
    userId,
    authUser ? authUser.uid : "",
    reportReason,
    "group"
  );

  const { isBlockUpdating, handleBlockUser } = useBlockUser(
    userId,
    authUser ? authUser.uid : ""
  );

  const ShareThemeLogo = useColorModeValue(ShareLightLogo, ShareBlackLogo);

  const [startDate, setStartDate] = useState("");

  const [title, setTitle] = useState("");
  const [caption, setCaption] = useState("");
  // const [location, setLocation] = useState("");
  const [whatsapp, setWhatsapp] = useState("");

  const [location, setLocation] = useState("");

  const [placeLocation, setPlaceLocation] = useState("");
  const [address, setAddress] = useState("");
  const imageRef = useRef(null);
  const { handleImageChange, selectedFile, setSelectedFile } = usePreviewImg();
  const showToast = useShowToast();
  const { pathname } = useLocation();
  const { isLoading, handleCreatePost } = useCreatePost(userId);

  const {
    isOpen: isOpenBlockModal,
    onOpen: onOpenBlockModal,
    onClose: onCloseBlockModal,
  } = useDisclosure();

  const {
    isOpen: isOpenAboutModal,
    onOpen: onOpenAboutModal,
    onClose: onCloseAboutModal,
  } = useDisclosure();

  const {
    isOpen: isOpenReportModal,
    onOpen: onOpenReportModal,
    onClose: onCloseReportModal,
  } = useDisclosure();

  const {
    isOpen: isOpenManageLinkModal,
    onOpen: onOpenManageLinkModal,
    onClose: onCloseManageLinkModal,
  } = useDisclosure();

  const {
    isOpen: isOpenModal,
    onOpen: onOpenModal,
    onClose: onCloseModal,
  } = useDisclosure();
  const cancelRef = useRef();

  const {
    isOpen: isOpenFollowerModal,
    onOpen: onOpenFollowerModal,
    onClose: onCloseFollowerModal,
  } = useDisclosure();

  const {
    isOpen: isOpenFollowingModal,
    onOpen: onOpenFollowingModal,
    onClose: onCloseFollowingModal,
  } = useDisclosure();

  const {
    isOpen: isOpenImageModel,
    onOpen: onOpenImageModel,
    onClose: onCloseImageModel,
  } = useDisclosure();

  const {
    isOpen: isOpenCreateModel,
    onOpen: onOpenCreateModel,
    onClose: onCloseCreateModel,
  } = useDisclosure();

  const {
    isOpen: isOpenVerifyModal,
    onOpen: onOpenVerifyModal,
    onClose: onCloseVerifyModal,
  } = useDisclosure();
  const { isFollowing, isUpdating, handleFollowUser } = useFollowGroup(userId);

  const { handleMemberGroup, isMember, memberList } = useApplyGroup(
    userProfile,
    userId
  );

  // const { handleMemberGroup, isMember, memberList } = userDummyApplyGroup(
  //   userProfile,
  //   userId,
  //   "Lv7pCQXWFDMXHop2iZHkDQdA2A92"
  // );

  const [loading, setLoading] = useState(false);
  const [showOTP, setShowOTP] = useState(false);
  const [otp, setOtp] = useState("");
  const [ph, setPh] = useState("");
  const [enterPhone, setEnterPhone] = useState(true);

  function onSignup() {
    setLoading(true);
    onCaptchVerify();

    const appVerifier = window.recaptchaVerifier;

    const formatPh = () => {
      if (ph.startsWith("07")) {
        let resStr = ph.slice(1);
        return `+44${resStr}`;
      } else if (ph.startsWith("00")) {
        let resStr = ph.slice(2);
        return `+${resStr}`;
      } else {
        return ph;
      }
    };

    // console.log(formatPh());

    signInWithPhoneNumber(auth, formatPh(), appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        setLoading(false);
        setShowOTP(true);
        // showToast("Success", "Enter the code sent to you", "success");
        setEnterPhone(false);
      })
      .catch((error) => {
        // console.log(error);
        setLoading(false);
        showToast("Error", error.message, "error");
      });
  }

  function onCaptchVerify() {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        auth,
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response) => {
            onSignup();
          },
          "expired-callback": () => {},
        }
      );
    }
  }

  const updateUser = async (user) => {
    const userDocRef = doc(firestore, "users", authUser.uid);

    const updatedUser = {
      ...authUser,
      phoneNumber: user.phoneNumber || authUser.phoneNumber,
    };

    await updateDoc(userDocRef, updatedUser);
    localStorage.setItem("user-info", JSON.stringify(updatedUser));
    // console.log("reached");
    // setUserProfile(updatedUser);
    onCloseVerifyModal();
    handleMemberGroup();
    if (!isFollowing) {
      handleFollowUser();
    }
  };

  function onOTPVerify() {
    setLoading(true);
    window.confirmationResult
      .confirm(otp)
      .then(async (res) => {
        setLoading(false);
        showToast("Success", "Phone Number Verified", "success");
        // console.log(res);
        updateUser(res.user);
      })
      .catch((err) => {
        setLoading(false);
        showToast("Error", error.message, "error");
      });
  }

  const [value, setValue] = useState(
    memberList &&
      authUser &&
      memberList
        .map((member, idx) => {
          return member.username;
        })
        .includes(authUser.username)
      ? memberList.find((uid) => uid.username === authUser.username).state
      : "unverified"
  );

  const bgTheme = useColorModeValue(`white`, `black`);

  const handlePostCreation = async () => {
    try {
      await handleCreatePost(
        selectedFile,
        title,
        caption,
        location,
        whatsapp,
        startDate,
        placeLocation,
        address
      );
      onCloseCreateModel();
      setStartDate("");
      setTitle("");
      setCaption("");
      setLocation("");
      setSelectedFile(null);
      setPlaceLocation("");
      setWhatsapp("");
    } catch (error) {
      showToast("Error", error.message, "error");
    }
  };

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };

  const visitingOwnProfileAndAuth =
    authUser && authUser.username === userProfile.username;
  const visitingOwnProfileAndAuthWithoutPhoneNumberVerified =
    authUser &&
    authUser.username === userProfile.username &&
    authUser.phoneNumber === "";
  const visitingAnotherProfileAndAuth =
    authUser && authUser.username !== userProfile.username;

  const hasInstagram =
    userProfile.instagram !== "" &&
    userProfile.instagram != "https://www.instagram.com/";
  const hasLinkedin = userProfile.linkedin !== "";
  const hasPhoneNumber = userProfile.phoneNumber !== "";
  const hasWhatsapp = userProfile.whatsapp !== "";

  const color = useColorModeValue("white", "gray.800");

  const LinkedinThemeIcon = useColorModeValue(LinkedinLightLogo, LinkedinLogo);
  const InstagramMobileThemeLogo = useColorModeValue(
    InstagramMobileLightLogo,
    InstagramMobileLogo
  );
  const PhoneThemeLogo = useColorModeValue(PhoneLightLogo, PhoneLogo);
  const WhatsappThemeLogo = useColorModeValue(WhatsappGreenLogo, WhatsappLogo);
  const textColor = useColorModeValue("gray.800", "gray.400");

  function isRunningStandalone() {
    const mqStandAlone = "(display-mode: standalone)";
    return navigator.standalone || window.matchMedia(mqStandAlone).matches;

    // return window.matchMedia("(display-mode: standalone)").matches;
  }

  function detectDevice() {
    var userAgent = navigator.userAgent.toLowerCase();
    if (
      userAgent.search("iphone") > -1 ||
      userAgent.search("ipad") > -1 ||
      userAgent.search("ipod") > -1
    ) {
      return "ios";
    } else if (userAgent.search("android") > -1) {
      return "android";
    } else {
      return "desktop";
    }
  }

  async function share() {
    try {
      await navigator.share({
        // title: `${post.title}`,
        text: `*${userProfile.fullName}*\n📋${userProfile.bio}\n*@${userProfile.username}*\n`,
        url: `https://hubzo.co/${userProfile.username}`,
      });
    } catch (error) {
      // console.log("Sharing failed!", error);
    }
  }

  return (
    <Flex
      // bg={"red"}
      // gap={{ base: 4, sm: 10 }}
      pt={6}
      pb={6}
      mx={4}
      maxW="container.lg"
      direction={{ base: "column", sm: "column" }}
    >
      <AvatarGroup
        userSelect={"none"}
        justifySelf={"center"}
        alignSelf={"flex-start"}
        mx={"auto"}
      >
        <Avatar
          pointerEvents={"none"}
          size="lg"
          src={userProfile.profilePicURL}
          alt="As a programmer logo"
          onClick={() => {
            if (visitingOwnProfileAndAuth && userProfile.profilePicURL === "") {
              onOpen();
            } else {
              if (userProfile.profilePicURL !== "") {
                onOpenImageModel();
              }
            }
          }}
        />
      </AvatarGroup>

      <Text
        mt={1}
        fontSize={18}
        alignContent={"center"}
        alignSelf={"center"}
        userSelect={"none"}
        fontWeight={"semibold"}
      >
        {userProfile.fullName}
      </Text>

      <Flex
        gap={4}
        direction={{ base: "column", sm: "column" }}
        justifyContent={{ base: "center", sm: "flex-start" }}
        alignItems={"center"}
        w={"full"}
      >
        <Flex flexDirection={"row"} gap={2}>
          {/* <Text userSelect={"none"} mt={1} fontSize={{ base: "md", md: "md" }}>
            @{userProfile.username}
          </Text> */}

          {/* <IconButton
              mr={0}
              alignSelf={"start"}
              size={"sm"}
              onClick={() => {
                if (navigator.canShare) {
                  share();
                }
              }}
              // isRound={true}

              variant="outline"
              colorScheme="whatsapp"
              aria-label="Send email"
              icon={<ShareThemeLogo />}
            /> */}
        </Flex>

        {visitingOwnProfileAndAuth && (
          <Flex gap={4} alignItems={"center"} justifyContent={"center"}>
            <Button
              bg={"blue.400"}
              color={"white"}
              _hover={{ bg: "blue.500" }}
              size={{ base: "xs", md: "sm" }}
              onClick={onOpen}
            >
              Edit Profile
            </Button>

            <Button
              isLoading={isLoggingOut}
              bg={"red.400"}
              color={"white"}
              _hover={{ bg: "red.500" }}
              size={{ base: "xs", md: "sm" }}
              onClick={handleLogout}
            >
              Logout
            </Button>
          </Flex>
        )}

        {/* {visitingAnotherProfileAndAuth && ( */}

        {/* )} */}
      </Flex>
      <Flex
        mt={4}
        onClick={() => {
          const standalone = isRunningStandalone();
          const device = detectDevice();

          if (!authUser) {
            // onOpenModal();
            // showToast("", "You are not logged in", "error");

            navigate(`/${userProfile.username}/members`);

            // if (standalone) {
            //   onOpenModal();
            // } else {
            //   if (device == "android") {
            //     onOpenModal();

            //     // openInNewTab(
            //     //   "https://play.google.com/store/apps/details?id=app.hubzo.android"
            //     // );
            //   } else if (device == "ios") {
            //     onOpenModal();

            //     // openInNewTab(
            //     //   "https://apps.apple.com/gb/app/hubzo/id6499236087"
            //     // );
            //   } else {
            //     // navigate(`/e/${eventId}/attending/`);
            //     onOpenModal();
            //   }

            //   // showToast(
            //   //   "",
            //   //   "You need to download the app to see this",
            //   //   "error"
            //   // );
            // }

            // return;
          } else {
            // let host = goingList.slice(0, 1)[0].username;

            // const standalone = isRunningStandalone();
            // const device = detectDevice();

            // if (standalone) {
            navigate(`/${userProfile.username}/members`);
            // } else {
            //   if (device == "android") {
            //     if (authUser.phoneNumber.startsWith("+44")) {
            //       openInNewTab(
            //         "https://play.google.com/store/apps/details?id=app.hubzo.android"
            //       );
            //     } else {
            //       navigate(`/${userProfile.username}/members`);
            //     }
            //   } else if (device == "ios") {
            //     if (authUser.phoneNumber.startsWith("+44")) {
            //       openInNewTab(
            //         "https://apps.apple.com/gb/app/hubzo/id6499236087"
            //       );
            //     } else {
            //       navigate(`/${userProfile.username}/members`);
            //     }
            //   } else {
            //     navigate(`/${userProfile.username}/members`);
            //   }
            // }
          }
        }}
        // cursor={"pointer"}
        alignItems={"center"}
        direction={"column"}
      >
        <Text userSelect={"none"} fontSize={"24"} as="span" fontWeight={"bold"}>
          {`${userProfile.memberList.length} › `}
        </Text>

        {/* <Text color={textColor} fontSize={{ base: "xs", md: "sm" }}>
              members
            </Text> */}

        <AvatarGroup
          ml={8}
          mr={4}
          mt={1}
          spacing={-1}
          // cursor={"pointer"}
          size="sm"
          max={6}
          // onClick={() => {
          //   if (!authUser) {
          //     onOpenModal();
          //     return;
          //   } else {
          //     // onOpenGoingModal();
          //     navigate(`/${userProfile.username}/members`);
          //     // navigate(-1);
          //   }
          // }}
        >
          {memberList &&
            memberList
              .toReversed()
              .map((member, idx) => (
                <Avatar
                  pointerEvents={"none"}
                  key={idx}
                  name={member.fullName}
                  src={member.profilePicture}
                />
              ))}
        </AvatarGroup>
      </Flex>

      <VStack
        // bg={"yellow"}
        alignItems={"start"}
        gap={2}
        // mx={"auto"}
        flex={1}
      >
        {/* <Divider w={"full"} mt={4} mb={0} bg={"gray.8000"} /> */}

        <Flex
          mt={5}
          // bg={"red"}
          userSelect={"none"}
          direction={"row"}
          gap={2}
          justifyContent={"space-between"}
          alignItems={"center"}
          w={"full"}
        >
          {/* <Flex
            ml={4}
            cursor={"pointer"}
            alignItems={"center"}
            direction={"column"}
          >
            <Text as="span" fontWeight={"bold"}>
              {userProfile.posts.length}
            </Text>

            <Text color={textColor} fontSize={{ base: "xs", md: "sm" }}>
              posts
            </Text>
          </Flex> */}

          <Spacer />

          {
          // hasWhatsapp
          true
          && (
            <VStack gap={1}>
              {/* <Text userSelect={"none"} mb={0} fontSize={12}>
                WhatsApp Group
              </Text> */}
              {/* <Button
                mt={0}
                mr={2}
                leftIcon={<WhatsappLogo />}
                iconSpacing={1}
                rightIcon={<ChevronRightIcon ml={"0"} boxSize={4} />}
                onClick={() => {
                  openInNewTab(userProfile.whatsapp);

                  // const standalone = isRunningStandalone();
                  // const device = detectDevice();

                  // if (!authUser) {
                  //   if (standalone) {
                  //     onOpenModal();
                  //   } else {
                  //     if (device == "android") {
                  //       onOpenModal();

                  //       // openInNewTab(
                  //       //   "https://play.google.com/store/apps/details?id=app.hubzo.android"
                  //       // );
                  //     } else if (device == "ios") {
                  //       onOpenModal();

                  //       // openInNewTab(
                  //       //   "https://apps.apple.com/gb/app/hubzo/id6499236087"
                  //       // );
                  //     } else {
                  //       onOpenModal();
                  //     }
                  //   }
                  // } else {
                  //   if (standalone) {
                  //     openInNewTab(userProfile.whatsapp);
                  //   } else {
                  //     if (device == "android") {
                  //       if (authUser.phoneNumber.startsWith("+44")) {
                  //         openInNewTab(
                  //           "https://play.google.com/store/apps/details?id=app.hubzo.android"
                  //         );
                  //       } else {
                  //         openInNewTab(userProfile.whatsapp);
                  //       }
                  //     } else if (device == "ios") {
                  //       if (authUser.phoneNumber.startsWith("+44")) {
                  //         openInNewTab(
                  //           "https://apps.apple.com/gb/app/hubzo/id6499236087"
                  //         );
                  //       } else {
                  //         openInNewTab(userProfile.whatsapp);
                  //       }
                  //     } else {
                  //       openInNewTab(userProfile.whatsapp);
                  //     }
                  //   }
                  // }

                  // if (!authUser) {
                  //   onOpenModal();
                  //   return;
                  // } else {
                  //   openInNewTab(userProfile.whatsapp);
                  // }
                }}
                colorScheme="whatsapp"
                variant="solid"
              >
                <Text userSelect={"none"} mb={0} fontSize={14}>
                  WhatsApp Group
                </Text>
              </Button> */}


              <Flex
          w={"full"}
          // bg={"blue"}
          mt={0}
          gap={4}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Button
            colorScheme="whatsapp"
            variant={"outline"}
            // bg={"blue.500"}
            // color={"white"}
            // _hover={{ bg: "blue.600" }}
            size={{ base: "md", md: "md" }}
            onClick={() => {
              if (!authUser) {
                onOpenModal();

                return;
              } else {
                handleFollowUser();
                handleMemberGroup();
              }
            }}
            isLoading={isUpdating}
          >
            {isFollowing ? "Unfollow 😬" : "Follow 🧐"}
          </Button>

          {/* <Button
                padding={"0"}
                leftIcon={<WarningIcon />}
                colorScheme="red"
                // isLoading={isReportUpdating}
                // bg={"red.400"}
                // color={"white"}
                variant={"outline"}
                // _hover={{ bg: "red.500" }}
                size={{ base: "xs", md: "sm" }}
                onClick={() => {
                  if (!authUser) {
                    onOpenModal();
                    return;
                  } else {
                    onOpenReportModal();
                  }
                }}
              >
                Report
              </Button>

              <Button
                padding={"0"}
                leftIcon={<NotAllowedIcon />}
                // mt={8}
                // mb={4}
                colorScheme="red"
                // isLoading={isBlockUpdating}
                // bg={"red.400"}
                // color={"white"}
                variant={"outline"}
                // _hover={{ bg: "red.500" }}
                size={{ base: "xs", md: "sm" }}
                onClick={() => {
                  if (!authUser) {
                    onOpenModal();
                    return;
                  } else {
                    onOpenBlockModal();
                  }
                }}
              >
                Block
              </Button> */}
        </Flex>



            </VStack>
          )}





          <Flex
            onClick={() => {
              if (!authUser) {
                onOpenModal();
                return;
              } else {
                // onOpenFollowerModal();
              }
            }}
            // cursor={"pointer"}
            alignItems={"center"}
            direction={"column"}
          >
            {/* <Text as="span" fontWeight={"bold"}>
              {userProfile.followers.length}
            </Text> */}

            {/* <Text color={textColor} fontSize={{ base: "xs", md: "sm" }}>
              linked accounts
            </Text> */}
            {/* 
            {memberList &&
            authUser &&
            memberList
              .map((member, idx) => {
                return member.username;
              })
              .includes(authUser.username) ? (
              <Button
                variant="outline"
                size={{ base: "sm", md: "sm" }}
                colorScheme="blue"
                mt={0}
                // leftIcon={<WhatsappThemeLogo />}
                onClick={() => {
                  if (!authUser) {
                    onOpenModal();
                    return;
                  } else {
                    onOpenManageLinkModal();
                  }
                }}
                // colorScheme="whatsapp"
                // variant="outline"
              >
                Group settings ›
              </Button>
            ) : (
              <Button
                variant="solid"
                size={{ base: "sm", md: "sm" }}
                colorScheme="blue"
                mt={0}
                // leftIcon={<WhatsappThemeLogo />}
                onClick={() => {
                  if (!authUser) {
                    onOpenModal();
                    return;
                  } else {
                    if (authUser.phoneNumber == "") {
                      onOpenVerifyModal();
                    } else {
                      handleMemberGroup();
                      if (!isFollowing) {
                        handleFollowUser();
                      }
                    }
                  }
                }}
                // colorScheme="whatsapp"
                // variant="outline"
              >
                Join group ›
              </Button>
            )} */}
          </Flex>

          <Spacer />

          {/* <Flex
            mr={4}
            onClick={() => {
              if (!authUser) {
                onOpenModal();
                return;
              } else {
                onOpenFollowingModal();
              }
            }}
            cursor={"pointer"}
            alignItems={"center"}
            direction={"column"}
          >
            <Text as="span" fontWeight={"bold"}>
              {userProfile.following.length}
            </Text>

            <Text color={textColor} fontSize={{ base: "xs", md: "sm" }}>
              following
            </Text>
          </Flex> */}
        </Flex>

        {/* <Divider mt={2} mb={0} bg={"gray.8000"} /> */}

        <Container
          mt={3}
          maxW={"container.sm"}
          pb={0}
          px={0}
          mb={0}
          onClick={() => {
            onOpenAboutModal();
          }}
        >
          {/* <Flex mt={0} alignItems={"center"} gap={4}>
          <Text fontSize={18} userSelect={"none"} fontWeight={"bold"}>
            {userProfile.fullName}
          </Text>
        </Flex> */}
          <Text userSelect={"none"} noOfLines={3} fontSize={"sm"}>
            {/* {bioText()} */}

            {userProfile.bio}
          </Text>
        </Container>

        {visitingOwnProfileAndAuth && userProfile.bio === "" && (
          <Alert status="warning" onClick={onOpen}>
            <AlertIcon />
            Say something about you 😊
          </Alert>
        )}

        {visitingOwnProfileAndAuth &&
          userProfile.profilePicURL === "" &&
          userProfile.phoneNumber === "" && (
            <Alert status="warning" onClick={onOpen}>
              <AlertIcon />
              Profiles with verified phone number and picture are more likely to
              be accepted to the WhatsApp groups.
            </Alert>
          )}

        {visitingOwnProfileAndAuth &&
          userProfile.profilePicURL !== "" &&
          userProfile.phoneNumber === "" && (
            <Alert status="warning" onClick={onOpenVerifyModal}>
              <AlertIcon />
              Profiles with verified phone number are more likely to be accepted
              to the WhatsApp groups.
            </Alert>
          )}

        {visitingOwnProfileAndAuth &&
          userProfile.profilePicURL === "" &&
          userProfile.phoneNumber !== "" && (
            <Alert status="warning" onClick={onOpen}>
              <AlertIcon />
              Profiles with a picture are more likely to be accepted to the
              WhatsApp groups.
            </Alert>
          )}

        {/* {visitingOwnProfileAndAuthWithoutPhoneNumberVerified && (
          <Flex gap={4} alignItems={"center"} justifyContent={"center"}>
            <Button
              colorScheme="orange"
              leftIcon={<PhoneBlackLogo />}
              size="sm"
              // bg={"white"}
              // color={"black"}
              // _hover={{ bg: "whiteAlpha.800" }}
              // size={{ base: "xs", md: "sm" }}
              onClick={onOpenVerifyModal}
            >
              Verify Phone Number
            </Button>
          </Flex>
        )} */}

        {userProfile.isCreator == false && (
          // (hasInstagram || hasLinkedin || hasPhoneNumber) &&
          <Flex alignItems={"center"} gap={4} w={"full"} pt={0} mb={0} mt={4}>
            <Box
              // cursor={"pointer"}
              fontSize={18}
              onClick={() => {
                if (hasInstagram) {
                  openInNewTab(
                    "https://www.instagram.com/" + userProfile.instagram
                  );
                } else {
                  if (visitingOwnProfileAndAuth) {
                    onOpen();
                  }
                }
              }}
            >
              <InstagramMobileThemeLogo />
            </Box>

            <Box
              // cursor={"pointer"}
              fontSize={18}
              onClick={() => {
                if (hasLinkedin) {
                  openInNewTab(userProfile.linkedin);
                } else {
                  if (visitingOwnProfileAndAuth) {
                    onOpen();
                  }
                }
              }}
            >
              <LinkedinThemeIcon />
            </Box>

            <Flex
              alignItems={"center"}
              gap={1}
              w={"full"}
              onClick={() => {
                if (hasPhoneNumber) {
                  // openInNewTab(userProfile.linkedin);
                } else {
                  if (visitingOwnProfileAndAuth) {
                    onOpenVerifyModal();
                  }
                }
              }}
            >
              <Box cursor={"pointer"} fontSize={18}>
                <PhoneThemeLogo />
              </Box>

              {userProfile.phoneNumber === "" && (
                <Text userSelect={"none"} fontSize={{ base: "sm", md: "sm" }}>
                  Unverified
                </Text>
              )}

              {userProfile.phoneNumber !== "" && (
                <Text userSelect={"none"} fontSize={{ base: "sm", md: "lg" }}>
                  *{userProfile.phoneNumber.substr(-4)}
                </Text>
              )}
            </Flex>
          </Flex>
        )}

        {/* {hasWhatsapp && (
          <Button
            mt={2}
            leftIcon={<WhatsappThemeLogo />}
            onClick={() => {
              if (!authUser) {
                onOpenModal();
                return;
              } else {
                openInNewTab(userProfile.whatsapp);
              }
            }}
            colorScheme="whatsapp"
            variant="outline"
          >
            Whatsapp group
          </Button>
        )} */}
        {/* 
        {userProfile &&
          userProfile.admins.length > 0 &&
          authUser &&
          userProfile.admins.includes(authUser.uid) && (
            <Button
              mt={2}
              onClick={() => {
                if (!authUser) {
                  onOpenModal();
                  return;
                } else {
                  onOpenCreateModel();
                }
              }}
            >
              Create event
            </Button>
          )} */}
      </VStack>
      {isOpen && <EditProfile isOpen={isOpen} onClose={onClose} />}
      {isOpenVerifyModal && (
        <Modal isOpen={onOpenVerifyModal} onClose={onCloseVerifyModal}>
          <ModalOverlay />
          <ModalContent
            bg={bgTheme}
            boxShadow={"xl"}
            border={"1px solid gray"}
            mx={3}
          >
            <ModalHeader />
            <ModalCloseButton size={"md"} />
            <ModalBody>
              {/* Container Flex */}
              <Flex bg={bgTheme}>
                <Stack
                  // bg={"yellow"}
                  spacing={0}
                  w={"full"}
                  maxW={"md"}
                  bg={bgTheme}
                  px={0}
                  pb={0}
                  my={0}
                >
                  {enterPhone && (
                    <Heading
                      lineHeight={1.1}
                      fontSize={{ base: "xl", sm: "xl" }}
                    >
                      Verify your phone number
                    </Heading>
                  )}
                  <div id="recaptcha-container"></div>

                  {enterPhone && (
                    <VStack
                      // bg={"blue"}
                      spacing={4}
                    >
                      <Alert
                        mt={4}
                        status="info"
                        // onClick={onOpen}
                      >
                        <AlertIcon />
                        This is needed so admins can verify is you requesting
                        access to the WhatsApp groups.
                      </Alert>

                      <FormControl>
                        {/* <FormLabel fontSize={"sm"}>Enter Phone Number</FormLabel>
    <Input
      placeholder={"+447744433322"}
      size={"sm"}
      type={"tel"}
      value={ph}
      onChange={(e) => setPh(e.target.value)}
    /> */}

                        <InputGroup>
                          <InputLeftElement pointerEvents="none">
                            <PhoneIcon color="gray.300" />
                          </InputLeftElement>
                          <Input
                            type="tel"
                            placeholder="Phone number"
                            size={"xl"}
                            value={ph}
                            onChange={(e) => setPh(e.target.value)}
                          />
                        </InputGroup>
                      </FormControl>

                      <Center w="full">
                        <Flex
                          // bg={"red"}
                          mb={0}
                          flexDirection={"column"}
                        >
                          <Button
                            size={"md"}
                            w="full"
                            colorScheme="whatsapp"
                            variant={"outline"}
                            onClick={onSignup}
                          >
                            {loading && (
                              <CgSpinner
                                size={20}
                                className="mt-1 animate-spin"
                              />
                            )}
                            <span>Send code</span>
                          </Button>

                          <Button
                            onClick={() => {
                              onCloseVerifyModal();
                              handleMemberGroup();
                              if (!isFollowing) {
                                handleFollowUser();
                              }
                            }}
                            size={"sm"}
                            my={2}
                            w="full"
                            variant={"ghost"}
                            colorScheme="red"
                          >
                            Skip for now
                          </Button>
                        </Flex>
                      </Center>
                    </VStack>
                  )}

                  {!enterPhone && (
                    <VStack spacing={6}>
                      <Alert
                        status="info"
                        // onClick={onOpen}
                      >
                        <AlertIcon />
                        You should have received an sms with a code.
                      </Alert>

                      <FormControl>
                        {/* <FormLabel fontSize={"sm"}>Enter Code</FormLabel>
    <Input
      placeholder={"123456"}
      size={"sm"}
      type={"number"}
      value={otp}
      onChange={(e) => setOtp(e.target.value)}
    /> */}

                        <InputGroup>
                          <InputLeftElement pointerEvents="none">
                            <EmailIcon color="gray.300" />
                          </InputLeftElement>
                          <Input
                            type="number"
                            placeholder="Enter code"
                            size={"lg"}
                            value={otp}
                            onChange={(e) => setOtp(e.target.value)}
                          />
                        </InputGroup>
                      </FormControl>

                      <Center w="full">
                        <Button
                          my={2}
                          w="full"
                          variant={"outline"}
                          colorScheme="whatsapp"
                          onClick={onOTPVerify}
                        >
                          {loading && (
                            <CgSpinner
                              size={20}
                              className="mt-1 animate-spin"
                            />
                          )}
                          <span>Verify code</span>
                        </Button>
                      </Center>
                    </VStack>
                  )}
                </Stack>
              </Flex>
            </ModalBody>
          </ModalContent>
        </Modal>

        // <VerifyPhoneNumber
        //   isOpen={isOpenVerifyModal}
        //   onClose={onCloseVerifyModal}
        // />
      )}
      {isOpenFollowerModal ? (
        <FollowerModal
          isOpen={isOpenFollowerModal}
          onClose={onCloseFollowerModal}
          userProfile={userProfile}
        />
      ) : null}
      {isOpenFollowingModal ? (
        <FollowingModal
          isOpen={isOpenFollowingModal}
          onClose={onCloseFollowingModal}
          userProfile={userProfile}
        />
      ) : null}

      <Modal
        isOpen={isOpenManageLinkModal}
        onClose={onCloseManageLinkModal}
        isCentered={true}
        size={{ base: "lg", md: "xl" }}
      >
        <ModalOverlay />
        <ModalContent bg={bgTheme} border={"1px solid gray"} maxW={"400px"}>
          <ModalHeader
            mt={4}
          >{`Manage group (${userProfile.fullName})`}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={5}>
            <Flex px={1} w={"full"} mx={"auto"} flexDirection={"column"}>
              <Flex gap="4">
                <RadioGroup onChange={setValue} value={value}>
                  <Stack spacing={5} direction="column">
                    <Radio size="lg" value="admin">
                      Profile visible to Admins only
                    </Radio>
                    <Radio size="lg" value="unverified">
                      Profile visible to other members
                    </Radio>
                    <Radio size="lg" value="hide">
                      Leave group
                    </Radio>
                  </Stack>
                </RadioGroup>
              </Flex>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button
              mb={2}
              mr={4}
              onClick={onCloseManageLinkModal}
              // isLoading={isLoading}
            >
              Cancel
            </Button>

            <Button
              mb={2}
              mr={0}
              colorScheme="whatsapp"
              variant={"outline"}
              onClick={() => {
                if (value === "hide") {
                  handleMemberGroup();
                  onCloseManageLinkModal();
                } else {
                  onCloseManageLinkModal();
                }
              }}
              isLoading={isLoading}
            >
              Update
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={isOpenImageModel}
        onClose={onCloseImageModel}
        isCentered={true}
        size={{ base: "3xl", md: "5xl" }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody bg={"black"} pb={5}>
            <Flex
              gap="4"
              w={{ base: "90%", sm: "70%", md: "full" }}
              mx={"auto"}
              maxH={"90vh"}
              minH={"50vh"}
            >
              <Flex
                borderRadius={4}
                overflow={"hidden"}
                border={"1px solid"}
                borderColor={"whiteAlpha.300"}
                flex={1.5}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Image src={userProfile.profilePicURL} alt="profile post" />
              </Flex>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal
        size={"full"}
        isOpen={isOpenCreateModel}
        onClose={onCloseCreateModel}
      >
        <ModalOverlay />
        <ModalContent bg={bgTheme} border={"1px solid gray"} maxW={"400px"}>
          <ModalHeader mt={4}>New Meetup</ModalHeader>
          <ModalCloseButton size={"lg"} mt={4} />
          <ModalBody pb={0}>
            <Flex px={1} w={"full"} mx={"auto"} flexDirection={"column"}>
              {/* <Card mb={2} mt={0}>
                <CardBody> */}
              <InputGroup mt={2} mb={0}>
                <InputLeftElement mt={1} pointerEvents="none">
                  <CalendarIcon />
                </InputLeftElement>

                <Input
                  // borderColor="grey.900"
                  // color="tomato"
                  placeholder="fsdfds"
                  size="lg"
                  type="datetime-local"
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </InputGroup>

              <Input
                mt={6}
                size="lg"
                type="text"
                placeholder="Title..."
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
              <Textarea
                mt={6}
                mb={4}
                size="lg"
                placeholder="What's happening..."
                value={caption}
                onChange={(e) => setCaption(e.target.value)}
              />

              {isLoaded && (
                <StandaloneSearchBox
                  onLoad={(ref) => (inputRef.current = ref)}
                  onPlacesChanged={handlePlaceChanged}
                >
                  <input
                    type="text"
                    className="form-control"
                    placeholder="📍 Enter Location"
                    style={{
                      marginTop: "16px",
                      marginBottom: "32px",
                      background: {},
                      borderRadius: `4px`,
                      boxSizing: `border-box`,
                      border: `1px solid transparent`,
                      width: "100%",
                      height: `32px`,
                      padding: `0 12px`,
                      borderRadius: `3px`,
                      boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                      fontSize: `14px`,
                      outline: `none`,
                      textOverflow: `ellipses`,
                      marginBottom: "8px",
                      outlineColor: "#555",
                      outlineWidth: "thin",
                      outlineStyle: "solid",
                    }}
                  />
                </StandaloneSearchBox>
              )}

              {/* <Input
              type="file"
              hidden
              ref={imageRef}
              onChange={handleImageChange}
            /> */}

              {/* <BsFillImageFill
              onClick={() => imageRef.current.click()}
              style={{
                marginTop: "15px",
                marginLeft: "5px",
                cursor: "pointer",
              }}
              size={24}
            /> */}
              {/* {selectedFile && (
              <Flex
                mt={5}
                w={"full"}
                position={"relative"}
                justifyContent={"center"}
              >
                <Image src={selectedFile} alt="Selected img" />
                <CloseButton
                  position={"absolute"}
                  top={2}
                  right={2}
                  onClick={() => {
                    setSelectedFile(null);
                  }}
                />
              </Flex>
            )} */}
              {/* </CardBody>
              </Card> */}
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button
              mb={2}
              mr={4}
              onClick={onCloseCreateModel}
              // isLoading={isLoading}
            >
              Cancel
            </Button>

            <Button
              mb={2}
              mr={0}
              onClick={handlePostCreation}
              isLoading={isLoading}
            >
              Create
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onCloseModal}
        isOpen={isOpenModal}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>Are you a member? 🤔</AlertDialogHeader>
          {/* <AlertDialogCloseButton /> */}
          <AlertDialogBody>Only members can see this.</AlertDialogBody>

          <AlertDialogFooter>
            <Button
              // ref={cancelRef}
              variant={"unstyled"}
              onClick={onCloseModal}
            >
              Cancel
            </Button>

            <Link to="/auth">
              <Button
                size={"md"}
                variant={"outline"}
                onClick={onCloseModal}
                colorScheme="whatsapp"
                ml={3}
              >
                Login
              </Button>
            </Link>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <Modal
        isOpen={isOpenReportModal}
        onClose={onCloseReportModal}
        isCentered={true}
        size={{ base: "lg", md: "xl" }}
      >
        <ModalOverlay />
        <ModalContent bg={bgTheme} border={"1px solid gray"} maxW={"400px"}>
          <ModalHeader mt={4}>Report this person</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={5}>
            <Flex px={1} w={"full"} mx={"auto"} flexDirection={"column"}>
              <Flex gap="4">
                <RadioGroup onChange={setReportReason} value={reportReason}>
                  <Stack spacing={5} direction="column">
                    <Radio size="md" value="behaviour">
                      Inappropriate behavior
                    </Radio>
                    <Radio size="md" value="scam">
                      Scam or fraud
                    </Radio>
                    <Radio size="md" value="impersonation">
                      Impersonation
                    </Radio>
                    <Radio size="md" value="underage">
                      Underage user
                    </Radio>
                    <Radio size="md" value="offensive">
                      Offensive or inappropriate content
                    </Radio>
                    <Radio size="md" value="suspicious">
                      Suspicious or malicious activity
                    </Radio>
                    <Radio size="md" value="violation">
                      Violation of terms of service
                    </Radio>
                  </Stack>
                </RadioGroup>
              </Flex>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button
              userSelect={"none"}
              mb={2}
              mr={4}
              onClick={onCloseReportModal}
              // isLoading={isLoading}
            >
              Cancel
            </Button>

            <Button
              mb={2}
              mr={0}
              colorScheme="red"
              variant={"outline"}
              onClick={() => {
                // if (value === "hide") {
                handleReportUser();
                onCloseReportModal();
                // } else {
                //   onCloseManageLinkModal();
                // }
              }}
              isLoading={isReportUpdating}
            >
              Confirm
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={isOpenBlockModal}
        onClose={onCloseBlockModal}
        isCentered={true}
        size={{ base: "lg", md: "xl" }}
      >
        <ModalOverlay />
        <ModalContent bg={bgTheme} border={"1px solid gray"} maxW={"400px"}>
          <ModalHeader mt={2}></ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={5}>
            <Flex py={1} px={1} w={"full"} mx={"auto"} flexDirection={"column"}>
              <Flex
                spacing={"8"}
                alignItems={"center"}
                direction={"column"}
                gap="4"
              >
                <NotAllowedIcon color={"red"} boxSize={10} />

                <Text fontSize={"xl"}>
                  Do you really want to block this group?
                </Text>
              </Flex>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button
              userSelect={"none"}
              mb={2}
              mr={4}
              onClick={onCloseBlockModal}
              // isLoading={isLoading}
            >
              Cancel
            </Button>

            <Button
              mb={2}
              mr={0}
              colorScheme="red"
              variant={"outline"}
              onClick={() => {
                // if (value === "hide") {
                handleBlockUser();
                onCloseBlockModal();
                // } else {
                //   onCloseManageLinkModal();
                // }
              }}
              isLoading={isBlockUpdating}
            >
              Yes
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isOpenAboutModal} onClose={onCloseAboutModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>About Group</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text
              p={1}
              whiteSpace="pre-line"
              // userSelect={"none"}
              // color={color}
              mt={2}
              mb={0}
              fontSize="16"
              // fontWeight={400}
            >
              {userProfile.bio}
            </Text>
          </ModalBody>

          <ModalFooter>
            {/* <Button colorScheme="blue" mr={3} onClick={onCloseAboutModal}>
              Close
            </Button>
            <Button variant="ghost">Secondary Action</Button> */}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default GroupProfileHeader;

function useCreatePost(userId) {
  const showToast = useShowToast();
  const [isLoading, setIsLoading] = useState(false);
  const authUser = useAuthStore((state) => state.user);
  const createPost = usePostStore((state) => state.createPost);
  const addPost = useUserProfileStore((state) => state.addPost);
  const userProfile = useUserProfileStore((state) => state.userProfile);
  const { pathname } = useLocation();

  const handleCreatePost = async (
    selectedFile,
    title,
    caption,
    location,
    whatsapp,
    startDate,
    placeLocation,
    address,
    value
  ) => {
    if (isLoading || !authUser) {
      return;
    }

    if (!authUser) {
      showToast("Error", "You must be logged in", "error");
      return;
    }
    // if (!selectedFile) throw new Error("Please select an image");
    setIsLoading(true);
    const newPost = {
      title: title,
      caption: caption,
      placeName: location,
      whatsapp: whatsapp,
      likes: [],
      goingList: [],
      comments: [],
      createdAt: Date.now(),
      createdBy: userId,
      startAt: new Date(startDate),
      placeLocation: placeLocation,
      address: address,
      postedBy: authUser.uid,
    };

    try {
      const postDocRef = await addDoc(collection(firestore, "events"), newPost);
      const userDocRef = await getDoc(doc(firestore, "groups", userId));
      // const imageRef = ref(storage, `posts/${postDocRef.id}`);

      if (userDocRef.exists()) {
        const userDocRefz = doc(firestore, "groups", value);

        await updateDoc(userDocRefz, { posts: arrayUnion(postDocRef.id) });
      }

      // await updateDoc(userDocRef, { posts: arrayUnion(postDocRef.id) });
      // await uploadString(imageRef, selectedFile, "data_url");
      // const downloadURL = await getDownloadURL(imageRef);

      // await updateDoc(postDocRef, { imageURL: downloadURL });
      // await updateDoc(postDocRef, {
      //   imageURL:
      //     "https://www.meetup.com/_next/image/?url=https%3A%2F%2Fsecure-content.meetupstatic.com%2Fimages%2Fclassic-events%2F508388773%2F676x380.webp&w=3840&q=75",
      // });

      // newPost.imageURL = downloadURL;

      // if (userProfile.uid === authUser.uid)
      createPost({ ...newPost, id: postDocRef.id });

      // if (pathname !== "/" && userProfile.uid === authUser.uid)
      addPost({ ...newPost, id: postDocRef.id });

      showToast("Success", "Post created successfully", "success");
    } catch (error) {
      showToast("Error", error.message, "error");
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, handleCreatePost };
}
