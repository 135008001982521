import { ArrowBackIcon, ChevronRightIcon } from "@chakra-ui/icons";
import {
  Box,
  Container,
  Divider,
  Flex,
  Grid,
  HStack,
  VStack,
  Text,
  Circle,
  useColorModeValue,
  Button,
  IconButton,
} from "@chakra-ui/react";
import { CgQr } from "react-icons/cg";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import useAuthStore from "../../store/authStore";
import useFetchTickets from "../../hooks/useFetchTickets";
import SidebarItems from "../../components/Sidebar/SidebarItems";
import TicketItem from "../../components/TicketItem";

const LogoComponent = ({ onClick }) => (
  <Box 
    as="h1" 
    fontSize={{ base: "28px", md: "32px" }}
    fontWeight="400"
    letterSpacing="0.35em"
    position="relative"
    display="inline-block"
    cursor="pointer"
    color="#1A1A1A"
    textTransform="uppercase"
    fontFamily="Montserrat, sans-serif"
    onClick={onClick}
    _hover={{
      letterSpacing: "0.4em",
      transform: "translateY(-1px)",
      transition: "all 0.4s cubic-bezier(0.4, 0, 0.2, 1)",
    }}
    transition="all 0.4s cubic-bezier(0.4, 0, 0.2, 1)"
    sx={{
      WebkitFontSmoothing: "antialiased",
      textRendering: "optimizeLegibility",
    }}
  >
    <Box
      as="span"
      position="relative"
      _before={{
        content: '""',
        position: "absolute",
        left: "-8%",
        right: "-8%",
        top: "-8px",
        bottom: "-8px",
        background: "linear-gradient(135deg, rgba(26, 26, 26, 0.08), rgba(26, 26, 26, 0.03))",
        backdropFilter: "blur(4px)",
        opacity: { base: 0.95, md: 0.9 },
        borderRadius: "2px",
        transform: "skew(-4deg)",
        transition: "all 0.3s ease",
        zIndex: -1,
      }}
      _after={{
        content: '""',
        position: "absolute",
        left: 0,
        right: 0,
        bottom: "-4px",
        height: "1px",
        background: "linear-gradient(to right, transparent, currentColor, transparent)",
        opacity: 0.4,
        transform: "scaleX(0.8)",
        transition: "transform 0.3s ease",
      }}
      _hover={{
        _after: {
          transform: "scaleX(1)",
        },
      }}
    >
      hubzo
    </Box>
  </Box>
);

const TicketsPage = () => {
  const navigate = useNavigate();
  const authUser = useAuthStore((state) => state.user);
  const userId = authUser?.id;

  const { tickets, loading, error } = useFetchTickets(userId);
  const [currentBlurb, setCurrentBlurb] = useState(0);
  const [filter, setFilter] = useState("upcoming");

  // Replace the colors object with fixed light theme colors
  const colors = {
    textColor: "gray.800",
    bgColor: "white",
    cardBg: "white",
    dividerColor: "gray.200",
    iconBgColor: "gray.100",
    hoverBgColor: "gray.50",
    subtitleColor: "gray.600",
    accentColor: "black",
  };

  const blurbs = [
    "When you accept an invite, a ticket is linked to your profile. Present it at the venue.",
    "Confirm your invitation attendance as soon as possible.",
    "It's not guaranteed a ticket will be generated for everyone. Accept early!",
    "You control which meetups to attend or decline.",
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
    const slideInterval = setInterval(() => {
      setCurrentBlurb((prev) => (prev + 1) % blurbs.length);
    }, 4000);
    return () => clearInterval(slideInterval);
  }, []);

  const filteredTickets =
    filter === "upcoming"
      ? tickets.filter((ticket) => ticket.createdAt.toDate() > new Date())
      : tickets.filter((ticket) => ticket.createdAt.toDate() <= new Date());

  return (
    <Box
      h="100vh"
      maxH="100vh"
      overflow="hidden"
      position="relative"
      bg="white"
      pb="env(safe-area-inset-bottom)"
    >
      <Box
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        overflowY="auto"
        pb={{ base: 32, md: 8 }}
        style={{
          WebkitOverflowScrolling: 'touch'
        }}
      >
        <Container
          maxW={{ base: "container.md", md: "container.xl" }}
          px={4}
          py={6}
          mb={{ base: 8, md: 0 }}
        >
          {/* Header */}
          <Flex
            w="full"
            alignItems="center"
            justify={{ base: "space-between", md: "center" }}
            mb={6}
            gap={4}
          >
            {/* Logo - Only shows on mobile */}
            <Box display={{ base: "block", md: "none" }}>
              <LogoComponent onClick={() => navigate("/")} />
            </Box>

            {/* Filter Pills - Desktop centered, Mobile to the right */}
            <HStack spacing={2} justify={{ base: "flex-end", md: "center" }}>
              <Button
                size="sm"
                bg={filter === "upcoming" ? "black" : "white"}
                color={filter === "upcoming" ? "white" : "gray.700"}
                borderWidth={1}
                borderColor={filter === "upcoming" ? "transparent" : "gray.200"}
                borderRadius="full"
                onClick={() => setFilter("upcoming")}
                _hover={{
                  transform: "translateY(-1px)",
                  shadow: "sm",
                }}
                transition="all 0.2s"
                px={4}
              >
                Upcoming
              </Button>
              <Button
                size="sm"
                bg={filter === "past" ? "black" : "white"}
                color={filter === "past" ? "white" : "gray.700"}
                borderWidth={1}
                borderColor={filter === "past" ? "transparent" : "gray.200"}
                borderRadius="full"
                onClick={() => setFilter("past")}
                _hover={{
                  transform: "translateY(-1px)",
                  shadow: "sm",
                }}
                transition="all 0.2s"
                px={4}
              >
                Past
              </Button>
            </HStack>
          </Flex>

          {/* Tickets Grid/List */}
          <Box w="full">
            {loading ? (
              <Text color={colors.textColor} textAlign="center">
                Loading tickets...
              </Text>
            ) : error ? (
              <Text color="red.500" textAlign="center">
                Error loading tickets: {error}
              </Text>
            ) : filteredTickets.length > 0 ? (
              <Grid
                templateColumns={{
                  base: "1fr",
                  md: "repeat(2, 1fr)",
                  lg: "repeat(3, 1fr)",
                }}
                gap={6}
                w="full"
              >
                {filteredTickets.map((ticket) => (
                  <TicketItem key={ticket.id} ticket={ticket} />
                ))}
              </Grid>
            ) : (
              <Box
                bg="white"
                borderRadius="2xl"
                w="full"
                maxW={{ base: "full", md: "lg" }}
                mx="auto"
                overflow="hidden"
                borderWidth="1px"
                borderColor="gray.200"
                shadow="sm"
                mb={8}
                transition="all 0.2s"
                _hover={{
                  transform: "translateY(-2px)",
                  shadow: "md",
                }}
              >
                <Box bg="gray.50" py={2} px={4}>
                  <Text
                    fontSize={{ base: "sm", md: "sm" }}
                    color="gray.700"
                    fontWeight="bold"
                  >
                    🎟️ NO TICKETS YET
                  </Text>
                </Box>

                <Box p={{ base: 8, md: 8 }}>
                  <VStack
                    spacing={{ base: 6, md: 6 }}
                    align="center"
                    textAlign="center"
                  >
                    <Box
                      p={4}
                      bg="gray.50"
                      rounded="full"
                      color="gray.600"
                    >
                      <CgQr size={30} />
                    </Box>
                    <VStack spacing={3}>
                      <Text
                        fontWeight="bold"
                        fontSize={{ base: "xl", md: "xl" }}
                        color="gray.800"
                      >
                        No Active Tickets 🎫
                      </Text>
                      <Text
                        fontSize={{ base: "md", md: "md" }}
                        color="gray.600"
                        lineHeight="tall"
                        maxW="md"
                      >
                        When you accept an invitation, your ticket will appear here. 
                        Make sure to respond to invites promptly to secure your spot! ✨
                      </Text>
                    </VStack>
                  </VStack>
                </Box>
              </Box>
            )}
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default TicketsPage;
