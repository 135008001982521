import {
  Container,
  Flex,
  Button,
  VStack,
  FormControl,
  Text,
  IconButton,
  Box,
  Progress,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Tooltip,
  HStack,
} from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";

const CreateUserProfileScorePage = () => {
  const [preferenceLevel, setPreferenceLevel] = useState(
    parseInt(localStorage.getItem("preferenceLevel")) || 3
  );
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handlePreferenceLevelChange = (value) => {
    setPreferenceLevel(value);
  };

  const handleNext = () => {
    localStorage.setItem("preferenceLevel", preferenceLevel);
    localStorage.setItem("invitationPreference", title);
    navigate("/create_event");
  };

  const getPreferenceText = () => {
    switch (preferenceLevel) {
      case 1:
        return {
          title: "Just Starting",
          subtext:
            "Discover new connections and engage in casual conversations with fellow attendees.",
        };
      case 2:
        return {
          title: "Getting Social",
          subtext:
            "Perfect for expanding your social network with friendly faces.",
        };
      case 3:
        return {
          title: "Connected and Ambitious",
          subtext:
            "Join professionals who enjoy socializing while advancing their careers.",
        };
      case 4:
        return {
          title: "Networking and Success",
          subtext:
            "Engage with peers who are focused on building meaningful networks.",
        };
      case 5:
        return {
          title: "Elite Connections",
          subtext:
            "Meet top-tier professionals and influencers in an exclusive setting.",
        };
      default:
        return { title: "", subtext: "" };
    }
  };

  const { title, subtext } = getPreferenceText();

  return (
    <Box
      position="fixed"
      top={{ base: 0, md: "64px" }}
      left={0}
      right={0}
      bottom={0}
      bg="white"
      zIndex={1000}
      overflowY="auto"
    >
      <Container
        maxW="container.sm"
        py={4}
        display="flex"
        flexDirection="column"
        minH={{ base: "100vh", md: "calc(100vh - 64px)" }}
      >
        {/* Header with Progress */}
        <Flex w="full" align="center" mb={8}>
          <IconButton
            icon={<ArrowBackIcon />}
            variant="ghost"
            aria-label="Go back"
            onClick={() => navigate(-1)}
            color="gray.500"
            _hover={{ color: "gray.600" }}
          />
          <Progress
            value={100}
            size="sm"
            colorScheme="purple"
            flex="1"
            mx={4}
            borderRadius="full"
            bg="gray.100"
          />
        </Flex>

        {/* Main Content */}
        <VStack spacing={8} align="center" w="full">
          <VStack spacing={2} textAlign="center" maxW="sm">
            <Text
              fontSize={{ base: "2xl", md: "3xl" }}
              fontWeight="bold"
              color="gray.900"
            >
              Target Audience Level
            </Text>
            <Text color="gray.600" fontSize={{ base: "md", md: "lg" }}>
              Set the profile level for your ideal attendees
            </Text>
          </VStack>

          <VStack spacing={8} w="full" maxW="400px">
            <Box
              bg="white"
              p={8}
              borderRadius="2xl"
              borderWidth="1px"
              borderColor="gray.200"
              w="full"
              position="relative"
              transition="all 0.2s"
              _hover={{
                borderColor: "gray.300",
                shadow: "sm",
              }}
            >
              <VStack spacing={6} align="stretch">
                <VStack
                  spacing={2}
                  align="center"
                  minH="100px"
                  justify="center"
                >
                  <Text
                    fontSize="2xl"
                    fontWeight="bold"
                    bgGradient="linear(to-r, #4F46E5, #7C3AED)"
                    bgClip="text"
                  >
                    {title}
                  </Text>
                  <Text
                    fontSize="md"
                    color="gray.600"
                    textAlign="center"
                    maxW="md"
                  >
                    {subtext}
                  </Text>
                </VStack>

                <Box>
                  <HStack spacing={2} w="full" justify="space-between" mb={2}>
                    <Text fontSize="sm" fontWeight="medium" color="gray.600">
                      Casual
                    </Text>
                    <Text fontSize="sm" fontWeight="medium" color="gray.600">
                      Elite
                    </Text>
                  </HStack>
                  <Box position="relative">
                    <HStack
                      spacing={2}
                      position="absolute"
                      w="full"
                      justify="space-between"
                      top="-6px"
                    >
                      {[1, 2, 3, 4, 5].map((level) => (
                        <Box
                          key={level}
                          w="3px"
                          h="12px"
                          bg={level <= preferenceLevel ? "#4F46E5" : "gray.200"}
                          transition="all 0.2s"
                        />
                      ))}
                    </HStack>
                    <Slider
                      aria-label="Preference Level Slider"
                      value={preferenceLevel}
                      min={1}
                      max={5}
                      step={1}
                      focusThumbOnChange={false}
                      onChange={handlePreferenceLevelChange}
                    >
                      <SliderTrack bg="gray.200" h="4px">
                        <SliderFilledTrack bgGradient="linear(to-r, #4F46E5, #7C3AED)" />
                      </SliderTrack>
                      <SliderThumb
                        boxSize={6}
                        bg="white"
                        borderWidth="2px"
                        borderColor="#4F46E5"
                        _focus={{
                          boxShadow: "0 0 0 3px rgba(79, 70, 229, 0.3)",
                        }}
                      />
                    </Slider>
                  </Box>
                </Box>
              </VStack>
            </Box>

            <Button
              w="full"
              size="lg"
              bg="gray.900"
              color="white"
              h="60px"
              fontSize="lg"
              onClick={handleNext}
              _hover={{
                transform: "translateY(-2px)",
                shadow: "lg",
                opacity: 0.95,
              }}
              _active={{ transform: "translateY(0)" }}
              rounded="xl"
              rightIcon={<FaArrowRight />}
              transition="all 0.2s"
            >
              Continue
            </Button>
          </VStack>
        </VStack>
      </Container>
    </Box>
  );
};

export default CreateUserProfileScorePage;
