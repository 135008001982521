import {
  Container,
  Flex,
  Button,
  VStack,
  Box,
  Progress,
  Text,
  IconButton,
  Wrap,
  WrapItem,
  Tag,
  TagLabel,
  TagCloseButton,
  Icon,
  HStack,
} from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { FaHeart, FaArrowRight } from "react-icons/fa";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// List of interests with emojis
const interestsList = [
  { emoji: "🍕", label: "Foodie" },
  { emoji: "🏋️‍♂️", label: "Fitness" },
  { emoji: "✈️", label: "Travel" },
  { emoji: "🎨", label: "Art" },
  { emoji: "💻", label: "Tech" },
  { emoji: "🎶", label: "Music" },
  { emoji: "📚", label: "Books" },
  { emoji: "🎥", label: "Movies" },
  { emoji: "🎮", label: "Gaming" },
  // { emoji: "⚽", label: "Football" },
  { emoji: "🚴‍♂️", label: "Cycling" },
  { emoji: "🏕️", label: "Camping" },
  { emoji: "📸", label: "Photography" },
  { emoji: "👩‍🍳", label: "Cooking" },
  // { emoji: "🎧", label: "Podcasts" },
  { emoji: "🎲", label: "Board Games" },
];

const businessList = [
  { emoji: "🍻", label: "Social Mixers" },
  { emoji: "🎤", label: "Open Mic Nights" },
  { emoji: "🎶", label: "Live Music" },
  { emoji: "🎲", label: "Board Game Nights" },
  { emoji: "🎬", label: "Movie Nights" },
  { emoji: "🍲", label: "Food Tastings" },
  { emoji: "🧘‍♀️", label: "Yoga" },
  { emoji: "📸", label: "Photography Walks" },
  { emoji: "🏞️", label: "Outdoor Adventures" },
  { emoji: "🌄", label: "Hiking" },
  { emoji: "💼", label: "Networking" },
  { emoji: "🎯", label: "Trivia Nights" },
  { emoji: "🥂", label: "Wine Tastings" },
  { emoji: "💃", label: "Dance Parties" },
  { emoji: "🖌️", label: "Art & Craft Workshops" },
  { emoji: "☕", label: "Coffee Tastings" },
];

const LogoComponent = ({ onClick }) => (
  <Box 
    as="h1" 
    fontSize={{ base: "28px", md: "32px" }}
    fontWeight="400"
    letterSpacing="0.35em"
    position="relative"
    display="inline-block"
    cursor="pointer"
    color="#1A1A1A"
    textTransform="uppercase"
    fontFamily="Montserrat, sans-serif"
    onClick={onClick}
    _hover={{
      letterSpacing: "0.4em",
      transform: "translateY(-1px)",
      transition: "all 0.4s cubic-bezier(0.4, 0, 0.2, 1)",
    }}
    transition="all 0.4s cubic-bezier(0.4, 0, 0.2, 1)"
    sx={{
      WebkitFontSmoothing: "antialiased",
      textRendering: "optimizeLegibility",
    }}
  >
    <Box
      as="span"
      position="relative"
      _before={{
        content: '""',
        position: "absolute",
        left: "-8%",
        right: "-8%",
        top: "-8px",
        bottom: "-8px",
        background: "linear-gradient(135deg, rgba(26, 26, 26, 0.08), rgba(26, 26, 26, 0.03))",
        backdropFilter: "blur(4px)",
        opacity: { base: 0.95, md: 0.9 },
        borderRadius: "2px",
        transform: "skew(-4deg)",
        transition: "all 0.3s ease",
        zIndex: -1,
      }}
      _after={{
        content: '""',
        position: "absolute",
        left: 0,
        right: 0,
        bottom: "-4px",
        height: "1px",
        background: "linear-gradient(to right, transparent, currentColor, transparent)",
        opacity: 0.4,
        transform: "scaleX(0.8)",
        transition: "transform 0.3s ease",
      }}
      _hover={{
        _after: {
          transform: "scaleX(1)",
        },
      }}
    >
      hubzo
    </Box>
  </Box>
);

const ChooseInterestsPage = () => {
  const [selectedInterests, setSelectedInterests] = useState([]);
  const [isBusiness, setIsBusiness] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    const isCreator = localStorage.getItem("isCreator") === "true";
    setIsBusiness(isCreator);
    const timer = setTimeout(() => setIsLoading(false), 0);
    return () => clearTimeout(timer);
  }, []);

  const toggleInterest = (interest) => {
    setSelectedInterests((prevSelected) =>
      prevSelected.includes(interest)
        ? prevSelected.filter((item) => item !== interest)
        : [...prevSelected, interest]
    );
  };

  if (isLoading) {
    return <Box minH="100vh" bg="white" />;
  }

  return (
    <Box 
      minH="100vh"
      maxH="100vh"
      overflow="hidden"
      position="relative"
      bg="white"
      pb="env(safe-area-inset-bottom)"
    >
      {/* Top Bar */}
      <Box
        position="fixed"
        top={0}
        left={0}
        right={0}
        zIndex={1000}
        bg="white"
        h={{ base: "56px", md: "64px" }}
        backdropFilter="blur(12px)"
        backgroundColor="rgba(255, 255, 255, 0.9)"
        borderBottom="1px solid"
        borderColor="gray.100"
      >
        <Container maxW="container.xl" h="full">
          <Flex 
            justify="space-between"
            align="center"
            h="full"
            px={{ base: 3, md: 4 }}
          >
            <IconButton
              icon={<ArrowBackIcon />}
              variant="ghost"
              aria-label="Go back"
              onClick={() => navigate(-1)}
              color="gray.500"
              display={{ base: 'flex', md: 'none' }}
              _hover={{ color: "black" }}
            />

            <LogoComponent onClick={() => navigate("/")} />

            <Box w="40px" display={{ base: 'block', md: 'none' }} />
          </Flex>
        </Container>
      </Box>

      {/* Main Content */}
      <Box 
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        overflowY="auto"
        style={{
          WebkitOverflowScrolling: 'touch'
        }}
      >
        <Box 
          pt={{ base: "56px", md: "64px" }}
          minH="100vh"
          pb={{ base: 32, md: 8 }}
        >
          <Container 
            maxW="container.sm" 
            py={{ base: 8, md: 16 }} 
            px={6}
            mb={{ base: 8, md: 0 }}
          >
            <VStack spacing={8} align="center" w="full">
              {/* Progress Bar */}
              <Progress
                value={75}
                size="sm"
                colorScheme="blackAlpha"
                w="full"
                maxW="400px"
                borderRadius="full"
                bg="gray.100"
              />

              <VStack spacing={3} textAlign="center">
                <Text
                  fontSize={{ base: "3xl", md: "4xl" }}
                  fontWeight="light"
                  letterSpacing="tight"
                  color="gray.900"
                  lineHeight="shorter"
                >
                  {isBusiness ? "Event types" : "Your interests"}
                </Text>
              </VStack>

              <Wrap 
                spacing={3} 
                justify="center"
                maxW="400px"
              >
                {(isBusiness ? businessList : interestsList).map((interest) => (
                  <WrapItem key={interest.label}>
                    <Tag
                      size="lg"
                      borderRadius="full"
                      variant="solid"
                      bg={
                        selectedInterests.includes(interest.label)
                          ? "black"
                          : "gray.50"
                      }
                      color={
                        selectedInterests.includes(interest.label)
                          ? "white"
                          : "gray.700"
                      }
                      cursor="pointer"
                      onClick={() => toggleInterest(interest.label)}
                      _hover={{
                        transform: "translateY(-2px)",
                        shadow: "md",
                      }}
                      transition="all 0.2s"
                      px={4}
                      py={2}
                      border="1px solid"
                      borderColor={
                        selectedInterests.includes(interest.label)
                          ? "transparent"
                          : "gray.200"
                      }
                    >
                      <TagLabel fontSize="sm">
                        {interest.emoji} {interest.label}
                      </TagLabel>
                      {selectedInterests.includes(interest.label) && (
                        <TagCloseButton
                          onClick={(e) => {
                            e.stopPropagation();
                            toggleInterest(interest.label);
                          }}
                        />
                      )}
                    </Tag>
                  </WrapItem>
                ))}
              </Wrap>

              <VStack spacing={4} w="full" maxW="400px">
                <Button
                  w="full"
                  size="lg"
                  bg="black"
                  color="white"
                  h="60px"
                  fontSize="lg"
                  isDisabled={selectedInterests.length === 0}
                  onClick={() => {
                    localStorage.setItem("interests", selectedInterests);
                    navigate("/onboarding_sign_up");
                  }}
                  _hover={{
                    transform: "translateY(-2px)",
                    shadow: "lg",
                    opacity: 0.95,
                  }}
                  _active={{ transform: "translateY(0)" }}
                  rounded="full"
                  rightIcon={<FaArrowRight />}
                  transition="all 0.2s"
                >
                  Continue
                </Button>

                <Button
                  variant="ghost"
                  color="gray.600"
                  fontSize="sm"
                  fontWeight="medium"
                  onClick={() => navigate("/onboarding_sign_up")}
                  _hover={{ 
                    color: "black",
                    bg: "gray.50"
                  }}
                  rounded="full"
                  h="60px"
                >
                  Skip for now
                </Button>

                <HStack 
                  spacing={2} 
                  color="gray.500"
                  fontSize="sm"
                  pt={2}
                >
                  <Box as="span" fontSize="lg">🔒</Box>
                  <Text>Your information is always private</Text>
                </HStack>
              </VStack>
            </VStack>
          </Container>
        </Box>
      </Box>
    </Box>
  );
};

export default ChooseInterestsPage;
