import {
  Container,
  Flex,
  Text,
  Box,
  VStack,
  HStack,
  IconButton,
  SkeletonText,
  Divider,
} from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { FaRegCalendarTimes } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import useAuthStore from "../../store/authStore";
import MinimizedInvitationItem from "../../components/MinimizedInvitationItem";
import useFetchInvitations from "../../hooks/useFetchInvitations";
import { useColorModeValue } from "@chakra-ui/react";

const EventParticipationPage = () => {
  const navigate = useNavigate();
  const authUser = useAuthStore((state) => state.user);
  const { invitations, loading, error } = useFetchInvitations(authUser?.userId);

  const color = "white";
  const sectionBgColor = "gray.800";
  const containerBgColor = useColorModeValue("white", "white"); // Define the background color

  return (
    <Container
      bg="gray.900"
      px={4}
      maxW="container.sm"
      py={6}
      overflow="hidden"
      borderRadius="lg"
    >
      {/* Header */}
      <Flex alignItems="center" justify="space-between" py={4}>
        <IconButton
          icon={<ArrowBackIcon boxSize={6} />}
          variant="unstyled"
          aria-label="Go back"
          onClick={() => navigate(-1)}
          color={color}
        />
      </Flex>

      <VStack spacing={3} align="center" textAlign="center" w="full" mb={6}>
        <Text fontSize="lg" fontWeight="bold" color="white">
          Event Participation
        </Text>
      </VStack>

      {loading ? (
        <SkeletonText mt="4" noOfLines={6} spacing="4" />
      ) : (
        <Box>
          {/* Invitations List */}
          <Box>
            <Text fontWeight="bold" fontSize="lg" color={color} mb={4}>
              Your Tickets
            </Text>

            <Flex flexDir={"column"} py={6} align="center" w="full">
              {loading ? (
                <Text color={"white"}>Loading tickets...</Text>
              ) : error ? (
                <Text color="red.500">Error loading tickets: {error}</Text>
              ) : invitations.length > 0 ? (
                invitations.map((invite) => (
                  <MinimizedInvitationItem key={invite.id} invite={invite} />
                ))
              ) : (
                <Box
                  bg={containerBgColor}
                  p={4}
                  borderRadius="lg"
                  shadow="sm"
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  w="full"
                  maxW="full"
                  minHeight="180px"
                  borderColor="gray.300"
                  borderWidth="1px"
                  textAlign="center"
                >
                  <FaRegCalendarTimes size={48} color="gray" />
                  <Text mt={4} fontSize="lg" fontWeight="bold" color="gray.800">
                    No Tickets Found
                  </Text>
                </Box>
              )}
            </Flex>
          </Box>
        </Box>
      )}
    </Container>
  );
};

export default EventParticipationPage;
