import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useColorModeValue,
  Tooltip,
  useDisclosure,
  Card,
  Text,
  CardBody,
} from "@chakra-ui/react";
import { SearchLogo } from "../../assets/constants";
import useSearchGroup from "../../hooks/useSearchGroup";
import { useRef } from "react";
import SuggestedUser from "../SuggestedUsers/SuggestedUser";

const Search = ({ isOpen, onClose }) => {
  //   const { isOpen, onOpen, onClose } = useDisclosure();
  const searchRef = useRef(null);
  const { user, isLoading, getUserProfile, setUser } = useSearchGroup();
  const bgTheme = useColorModeValue(`white`, `black`);

  const handleSearchUser = (e) => {
    e.preventDefault();
    getUserProfile(searchRef.current.value.toLowerCase());
  };

  return (
    <>
      {/* <Tooltip
				hasArrow
				// label={"Search"}
				placement='right'
				ml={1}
				openDelay={500}
				display={{ base: "block", md: "none" }}
			>
				<Flex
					alignItems={"center"}
					gap={4}
					_hover={{ bg: "whiteAlpha.400" }}
					borderRadius={6}
					p={2}
					w={{ base: 10, md: "full" }}
					justifyContent={{ base: "center", md: "flex-start" }}
					onClick={onOpen}
				>
					<SearchLogo />
					<Box display={{ base: "none", md: "block" }}>Search</Box>
				</Flex>
			</Tooltip> */}

      <Modal 
      bg={bgTheme} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent border={"1px solid gray"} maxW={"lg"}>
          <ModalHeader mt={4}>
            <Text mb={0} fontSize={24}>
              Search for a Group
            </Text>

            {/* <Text mb={0} fontSize={12}>
              You can search by the group name or the whatsapp group link
            </Text> */}
          </ModalHeader>
          <ModalCloseButton size={"lg"} mt={2} />
          <ModalBody pb={6}>
            <form onSubmit={handleSearchUser}>
              {/* <Card mb={8} mt={10}> */}
              {/* <CardBody> */}
              {/* <Text mb={6} fontSize={16}>
                    Search for a WhatsApp or Telegram group. You can use
                    keywords or the group invite link.
                  </Text> */}
              <FormControl mb={4}>
                <Input
                  // borderColor="red.900"
                  w={"full"}
                  size={"lg"}
                  placeholder="🔍"
                  ref={searchRef}
                />
              </FormControl>
              {/* </CardBody> */}
              {/* </Card> */}
              <Flex mb={0} w={"full"} justifyContent={"flex-end"}>
                <Button
                  mb={0}
                  type="submit"
                  w={"full"}
                  border="2px"
                  // borderColor="gray.500"
                  colorScheme="whatsapp"
                  variant={"outline"}
                  mr={0}
                  isLoading={isLoading}
                >
                  Search
                </Button>
              </Flex>
            </form>
            {user && (
              <Box mt={6}>
                <SuggestedUser user={user} setUser={setUser} />
              </Box>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Search;

// COPY AND PASTE AS THE STARTER CODE FOR THE SEARCH COMPONENT
// import { Box, Flex, Tooltip } from "@chakra-ui/react";
// import { SearchLogo } from "../../assets/constants";

// const Search = () => {
// 	return (
// 		<>
// 			<Tooltip
// 				hasArrow
// 				label={"Search"}
// 				placement='right'
// 				ml={1}
// 				openDelay={500}
// 				display={{ base: "block", md: "none" }}
// 			>
// 				<Flex
// 					alignItems={"center"}
// 					gap={4}
// 					_hover={{ bg: "whiteAlpha.400" }}
// 					borderRadius={6}
// 					p={2}
// 					w={{ base: 10, md: "full" }}
// 					justifyContent={{ base: "center", md: "flex-start" }}
// 				>
// 					<SearchLogo />
// 					<Box display={{ base: "none", md: "block" }}>Search</Box>
// 				</Flex>
// 			</Tooltip>
// 		</>
// 	);
// };

// export default Search;
