import {
  Container,
  Flex,
  Button,
  VStack,
  FormControl,
  Input,
  Text,
  IconButton,
  Box,
  Progress,
} from "@chakra-ui/react";
import { ArrowBackIcon, CloseIcon } from "@chakra-ui/icons";
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";

const CreateLocationPage = () => {
  const [selectedPlace, setSelectedPlace] = useState(
    localStorage.getItem("selectedPlace") || ""
  );
  const [selectedAddress, setSelectedAddress] = useState(
    localStorage.getItem("selectedAddress") || ""
  );
  const [selectedPlaceLocation, setSelectedPlaceLocation] = useState(
    localStorage.getItem("selectedPlaceLocation") || ""
  );
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const autocompleteRef = useRef(null);

  useEffect(() => {
    if (window.google && google.maps) {
      autocompleteRef.current = new google.maps.places.Autocomplete(
        inputRef.current,
        {
          types: ["establishment"],
          componentRestrictions: {
            country: [
              "fr",
              "gb",
              "de",
              "es",
              "it",
              "nl",
              "be",
              "ch",
              "se",
              "ru",
              "gr",
              "pl",
            ],
          },
        }
      );
      autocompleteRef.current.addListener("place_changed", () => {
        const place = autocompleteRef.current.getPlace();
        setSelectedPlace(place.name || "");
        setSelectedAddress(place.formatted_address || "");
        setSelectedPlaceLocation(place.url || "");
      });
    }
    window.scrollTo(0, 0);
  }, []);

  const clearPlace = () => {
    setSelectedPlace("");
    setSelectedAddress("");
    inputRef.current.value = "";
    inputRef.current.focus();
  };

  return (
    <Box
      position="fixed"
      top={{ base: 0, md: "64px" }}
      left={0}
      right={0}
      bottom={0}
      bg="white"
      zIndex={1000}
      overflowY="auto"
    >
      <Container
        maxW="container.sm"
        py={4}
        display="flex"
        flexDirection="column"
        minH={{ base: "100vh", md: "calc(100vh - 64px)" }}
      >
        {/* Header with Progress - Visible on all screens */}
        <Flex w="full" align="center" mb={6}>
          <IconButton
            icon={<ArrowBackIcon />}
            variant="ghost"
            aria-label="Go back"
            onClick={() => navigate(-1)}
            color="gray.500"
            _hover={{ color: "#4F46E5" }}
          />
          <Progress
            value={37.5}
            size="sm"
            colorScheme="purple"
            flex="1"
            mx={4}
            borderRadius="full"
            bg="gray.100"
          />
        </Flex>

        {/* Main Content */}
        <VStack spacing={8} align="center" w="full">
          <VStack spacing={3} textAlign="center">
            <Box
              p={3}
              bg="purple.50"
              rounded="full"
              color="purple.600"
              fontSize="2xl"
              mb={2}
            >
              📍
            </Box>

            <Text
              fontSize={{ base: "2xl", md: "3xl" }}
              fontWeight="bold"
              // bgGradient="linear(to-r, #4F46E5, #7C3AED)"
              // bgClip="text"
              color="gray.900"
            >
              Event Location
            </Text>
            <Text color="gray.600" fontSize={{ base: "md", md: "lg" }}>
              Where will your event take place?
            </Text>
          </VStack>

          <VStack spacing={4} w="full" maxW="400px">
            <FormControl position="relative">
              <Input
                ref={inputRef}
                placeholder="Search for a place"
                size="lg"
                value={selectedPlace}
                onChange={(e) => setSelectedPlace(e.target.value)}
                bg="gray.50"
                color="gray.800"
                border="1px solid"
                borderColor="gray.200"
                _placeholder={{ color: "gray.400" }}
                _focus={{
                  borderColor: "#4F46E5",
                  boxShadow: "0 0 0 1px #4F46E5",
                  bg: "white",
                  transform: "scale(1.02)",
                }}
                _hover={{
                  borderColor: "gray.300",
                }}
                rounded="xl"
                h="60px"
                fontSize="lg"
                transition="all 0.2s"
              />
              {selectedPlace && (
                <IconButton
                  icon={<CloseIcon />}
                  size="xs"
                  position="absolute"
                  top="50%"
                  right="4"
                  transform="translateY(-50%)"
                  aria-label="Clear location"
                  color="gray.500"
                  bg="transparent"
                  _hover={{ bg: "gray.200" }}
                  onClick={clearPlace}
                />
              )}
            </FormControl>

            {selectedAddress && (
              <Text
                w="full"
                fontSize="sm"
                color="gray.500"
                textAlign="left"
                pl={4}
                mb={6}
              >
                {selectedAddress}
              </Text>
            )}

            {selectedPlace && (
              <Button
                w="full"
                size="lg"
                bg="gray.900"
                color="white"
                h="60px"
                fontSize="lg"
                onClick={() => {
                  localStorage.setItem("selectedPlace", selectedPlace);
                  localStorage.setItem("selectedAddress", selectedAddress);
                  localStorage.setItem(
                    "selectedPlaceLocation",
                    selectedPlaceLocation
                  );
                  navigate("/create_event_date");
                }}
                _hover={{
                  transform: "translateY(-2px)",
                  shadow: "lg",
                  opacity: 0.95,
                }}
                _active={{ transform: "translateY(0)" }}
                rounded="xl"
                rightIcon={<FaArrowRight />}
                transition="all 0.2s"
              >
                Continue
              </Button>
            )}
          </VStack>
        </VStack>
      </Container>
    </Box>
  );
};

export default CreateLocationPage;
