import {
  Container,
  Flex,
  Text,
  Box,
  VStack,
  IconButton,
  Button,
  useColorModeValue,
} from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { FaInstagram } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import useAuthStore from "../../store/authStore";
import useGetUserProfileById from "../../hooks/useGetUserProfileById";

const InstagramVerificationPage = () => {
  const navigate = useNavigate();
  const authUser = useAuthStore((state) => state.user);
  const { isLoading, userProfile } = useGetUserProfileById(authUser?.uid);

  const colors = {
    textColor: "gray.900",
    bgColor: "white",
    cardBg: "white",
    dividerColor: "gray.200",
    iconBgColor: "rgba(184, 160, 128, 0.1)",
    hoverBgColor: "rgba(184, 160, 128, 0.05)",
    subtitleColor: "gray.600",
    accentColor: "#B8A080",
    borderColor: "rgba(184, 160, 128, 0.2)",
  };

  return (
    <Box
      h="100vh"
      maxH="100vh"
      overflow="hidden"
      position="relative"
      bg={colors.bgColor}
      pb="env(safe-area-inset-bottom)"
    >
      <Box
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        overflowY="auto"
        pb={{ base: 32, md: 8 }}
        style={{
          WebkitOverflowScrolling: 'touch'
        }}
      >
        <Container 
          maxW={{ base: "container.md", md: "container.xl" }} 
          px={4} 
          py={6}
          mb={{ base: 8, md: 0 }}
        >
          {/* Header */}
          <Flex 
            w="full" 
            alignItems="center" 
            mb={8}
            position="relative"
          >
            <IconButton
              icon={<ArrowBackIcon />}
              variant="ghost"
              aria-label="Go back"
              onClick={() => navigate(-1)}
              position={{ base: "relative", md: "absolute" }}
              left={{ md: 0 }}
              color={colors.accentColor}
              _hover={{
                bg: colors.iconBgColor,
                transform: "translateY(-2px)",
              }}
              transition="all 0.2s"
            />
            <Text 
              fontSize={{ base: "2xl", md: "3xl" }}
              fontWeight="light"
              color={colors.textColor}
              textAlign={{ base: "left", md: "center" }}
              flex="1"
              ml={{ base: 4, md: 0 }}
              letterSpacing="wide"
              fontFamily="Montserrat, sans-serif"
            >
              Verify Your Instagram
            </Text>
          </Flex>

          {/* Main Content */}
          <Box 
            maxW={{ base: "full", md: "lg" }} 
            mx="auto"
          >
            <Box
              bg={colors.cardBg}
              borderRadius="xl"
              borderWidth="1px"
              borderColor={colors.borderColor}
              p={6}
              textAlign="center"
              shadow="sm"
              _hover={{ shadow: "md" }}
              transition="all 0.2s"
            >
              {/* User Code */}
              <VStack spacing={6} align="center">
                <Box
                  p={4}
                  bg={colors.iconBgColor}
                  borderRadius="full"
                  color={colors.accentColor}
                  transform="rotate(-5deg)"
                  _hover={{
                    transform: "rotate(0deg)",
                  }}
                  transition="all 0.3s"
                >
                  <FaInstagram size={30} />
                </Box>

                <VStack spacing={2}>
                  <Text 
                    fontSize={{ base: "md", md: "lg" }} 
                    fontWeight="medium"
                    color={colors.textColor}
                    fontFamily="Montserrat, sans-serif"
                    letterSpacing="wide"
                  >
                    Your Verification Code:
                  </Text>
                  <Text 
                    fontSize={{ base: "2xl", md: "3xl" }} 
                    fontWeight="bold"
                    color={colors.accentColor}
                    letterSpacing="wider"
                    fontFamily="Montserrat, sans-serif"
                    bg={colors.iconBgColor}
                    px={6}
                    py={2}
                    rounded="full"
                  >
                    {isLoading ? "Loading..." : userProfile?.userCode || "Unavailable"}
                  </Text>
                </VStack>

                {/* Instructions */}
                <Text 
                  fontSize={{ base: "sm", md: "md" }} 
                  color={colors.textColor}
                  maxW="md"
                  lineHeight="tall"
                  fontFamily="Montserrat, sans-serif"
                  letterSpacing="wide"
                >
                  To verify your Instagram account, send a message with your code to the{" "}
                  <Text as="span" fontWeight="bold" color={colors.accentColor}>
                    @hubzo.app
                  </Text>{" "}
                  Instagram page from your linked account.
                </Text>

                {/* Button */}
                <Button
                  leftIcon={<FaInstagram />}
                  size="lg"
                  bg="black"
                  color="white"
                  onClick={() =>
                    window.open("https://www.instagram.com/hubzo.app", "_blank")
                  }
                  borderRadius="full"
                  px={8}
                  h={14}
                  fontSize="md"
                  fontWeight="medium"
                  letterSpacing="wide"
                  fontFamily="Montserrat, sans-serif"
                  _hover={{
                    transform: "translateY(-2px)",
                    shadow: "lg",
                    opacity: 0.9,
                  }}
                  _active={{
                    transform: "translateY(0)",
                    opacity: 0.8,
                  }}
                  transition="all 0.2s"
                >
                  Go to Instagram
                </Button>
              </VStack>
            </Box>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default InstagramVerificationPage;
