import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Box,
  Image,
  Divider,
  Text,
  ModalCloseButton,
  ModalBody,
  Card,
  CardBody,
  Skeleton,
  Button,
  Spacer,
  ModalFooter,
  Flex,
  Modal,
  ModalOverlay,
  Menu,
  MenuButton,
  RadioGroup,
  Radio,
  MenuList,
  MenuItem,
  ModalContent,
  MenuDivider,
  Stack,
  ModalHeader,
  useDisclosure,
  useColorModeValue,
  SkeletonCircle,
  Avatar,
  VStack,
  HStack,
} from "@chakra-ui/react";
import { useParams, useNavigate } from "react-router-dom";
import { useRef, useState } from "react";
import { format } from "date-fns";

import { arrayRemove, deleteDoc, doc, updateDoc } from "firebase/firestore";
import { firestore, storage } from "../../firebase/firebase";

import { BsThreeDotsVertical } from "react-icons/bs";
import {
  AddIcon,
  SearchIcon,
  CalendarIcon,
  ChevronRightIcon,
  ArrowBackIcon,
  WarningIcon,
  NotAllowedIcon,
  DeleteIcon,
} from "@chakra-ui/icons";

import useGetUserProfileById from "../../hooks/useGetUserProfileById";
import useGetGroupEventById from "../../hooks/useGetGroupEventById";
import useShowToast from "../../hooks/useShowToast";
import useReportUser from "../../hooks/useReportUser";
import { Link } from "react-router-dom";

const MemoryCard = ({ memory, hasFooter, isClickable, userId }) => {
  const { userProfile, isLoading } = useGetUserProfileById(memory.createdBy);
  const { post } = useGetGroupEventById(memory.eventId);
  const [reportReason, setReportReason] = useState("behaviour");

  const bgTheme = useColorModeValue(`white`, `gray.800`);

  const {
    isOpen: isOpenModal,
    onOpen: onOpenModal,
    onClose: onCloseModal,
  } = useDisclosure();

  const cancelRef = useRef();

  const {
    isOpen: isOpenVerifyModal,
    onOpen: onOpenVerifyModal,
    onClose: onCloseVerifyModal,
  } = useDisclosure();

  const navigate = useNavigate();
  const showToast = useShowToast();

  const {
    isOpen: isOpenReportModal,
    onOpen: onOpenReportModal,
    onClose: onCloseReportModal,
  } = useDisclosure();

  const { isReportUpdating, handleReportUser } = useReportUser(
    memory.createdBy,
    memory.picUrl,
    reportReason,
    "memory"
  );

  const [isDeleting, setIsDeleting] = useState(false);

  const handleDeleteMemory = async () => {
    if (!window.confirm("Are you sure you want to delete this memory?")) return;
    if (isDeleting) return;

    try {
      // const imageRef = ref(storage, `posts/${post.id}`);
      // await deleteObject(imageRef);
      // const userRef = doc(firestore, "users", authUser.uid);
      await deleteDoc(doc(firestore, "memories", memory.id));

      // await updateDoc(userRef, {
      //   posts: arrayRemove(post.id),
      // });

      // deletePost(post.id);
      // decrementPostsCount(post.id);
      showToast("Success", "Event deleted successfully", "success");
    } catch (error) {
      showToast("Error", error.message, "error");
    } finally {
      onCloseVerifyModal();
      setIsDeleting(false);
    }
  };

  function testFormatDate() {
    if (isToday(post.startAt.toDate())) {
      return format((post.startAt ?? new Date()).toDate(), "HH:mm") + ", Today";
    } else if (isTomorrow(post.startAt.toDate())) {
      return (
        format((post.startAt ?? new Date()).toDate(), "HH:mm") + ", Tomorrow"
      );
    } else {
      return format((post.startAt ?? new Date()).toDate(), "dd MMM");
    }
  }

  function isToday(date) {
    const today = new Date();

    // 👇️ Today's date
    // console.log(today);

    if (today.toDateString() === date.toDateString()) {
      return true;
    }

    return false;
  }

  function isTomorrow(date) {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);

    // 👇️ Tomorrow's date
    // console.log(tomorrow);

    if (tomorrow.toDateString() === date.toDateString()) {
      return true;
    }

    return false;
  }

  return (
    <>
      <Card p={0} m={0}>
        <CardBody p={0}>
          <Flex
            // bg={"red"}

            flexDir={"column"}
            alignSelf={"center"}
            alignContent={"center"}
          >
            {!hasFooter && (
              <Box
                onClick={() => {
                  if (isClickable) {
                    if (userId === "") {
                      onOpenModal();
                    } else {
                      onOpenVerifyModal();
                    }
                  }
                }}
              >
                <Image
                  pointerEvents={"none"}
                  w={"full"}
                  alignSelf={"center"}
                  // onClick={() => {
                  //   navigate(`/e/${post.id}`);
                  // }}
                  borderRadius={4}
                  // minH={100}
                  boxSize="150px"
                  objectFit="cover"
                  // maxHeight={"300px"}
                  // minHeight={"300px"}
                  aspectRatio={"-moz-initial"}
                  // fallbackSrc={memory.picUrl}
                  fallbackSrc={"/dummy_hubzo_400.jpg"}
                  // name={post.placeName}
                  // w={{ base: "md", sm: "md" }}
                  src={memory.picUrl}
                />
              </Box>
            )}

            {hasFooter && (
              <Box
                onClick={() => {
                  if (isClickable) {
                    onOpenVerifyModal();
                  }
                }}
              >
                <Image
                  pointerEvents={"none"}
                  w={"full"}
                  alignSelf={"center"}
                  // onClick={() => {
                  //   navigate(`/e/${post.id}`);
                  // }}
                  borderRadius={4}
                  // minH={100}
                  boxSize="200px"
                  objectFit="cover"
                  // maxHeight={"300px"}
                  // minHeight={"300px"}
                  aspectRatio={"-moz-initial"}
                  // fallbackSrc={memory.picUrl}
                  fallbackSrc={"/dummy_hubzo_400.jpg"}
                  // name={post.placeName}
                  // w={{ base: "md", sm: "md" }}
                  src={memory.picUrl}
                />
              </Box>
            )}

            {hasFooter && (
              <Flex alignContent={"center"} flexDir={"row"} gap={0}>
                {userProfile ? (
                  <Avatar
                    onClick={() => {
                      if (isClickable) {
                        onOpenVerifyModal();
                      }
                    }}
                    m={2}
                    size={"sm"}
                    src={userProfile.profilePicURL}
                  />
                ) : (
                  <SkeletonCircle size="10" />
                )}

                <Flex flexDir={"column"}>
                  {userProfile ? (
                    <Text
                      onClick={() => {
                        if (isClickable) {
                          onOpenVerifyModal();
                        }
                      }}
                      mt={2}
                      fontSize={11}
                    >
                      {userProfile.fullName}
                    </Text>
                  ) : (
                    <Skeleton w={"100px"} h={"10px"} />
                  )}

                  {userProfile ? (
                    <Text
                      onClick={() => {
                        if (isClickable) {
                          onOpenVerifyModal();
                        }
                      }}
                      color={"gray.500"}
                      fontSize={11}
                    >
                      @{userProfile.username}
                    </Text>
                  ) : (
                    <Skeleton w={"100px"} h={"10px"} />
                  )}
                </Flex>
              </Flex>
            )}
          </Flex>
        </CardBody>
      </Card>

      {isOpenVerifyModal && (
        <Modal
          size={"lg"}
          isOpen={onOpenVerifyModal}
          onClose={onCloseVerifyModal}
        >
          <ModalOverlay />
          <ModalContent
            p={1}
            bg={bgTheme}
            // boxShadow={"xl"}
            border={"1px solid gray"}
            maxW={"xl"}

            // maxW={"400px"}
          >
            <ModalHeader mt={0}></ModalHeader>
            <ModalCloseButton p={2} size={"md"} />
            <ModalBody bg={"red"} mx={0} mb={0} mt={4} p={0}>
              {/* Container Flex */}

              <Card p={0} w={"full"} m={0}>
                <CardBody p={0} bg={bgTheme}>
                  <Flex
                    // bg={"yellow"}
                    flexDir={"column"}
                    alignSelf={"center"}
                    alignContent={"center"}
                  >
                    <Image
                      onClick={() => {
                        navigate(`/e/${memory.eventId}`);
                      }}
                      pointerEvents={"none"}
                      w={"full"}
                      alignSelf={"center"}
                      // onClick={() => {
                      //   navigate(`/e/${post.id}`);
                      // }}
                      borderRadius={4}
                      // minH={100}
                      // boxSize="200px"
                      objectFit="cover"
                      // maxHeight={"300px"}
                      // minHeight={"300px"}
                      aspectRatio={"-moz-initial"}
                      // fallbackSrc={memory.picUrl}
                      fallbackSrc={"/dummy_hubzo_400.jpg"}
                      // name={post.placeName}
                      // w={{ base: "md", sm: "md" }}
                      src={memory.picUrl}
                    />

                    <Flex
                      w={"full"}
                      onClick={() => {
                        navigate(`/e/${memory.eventId}`);
                      }}
                      flexDir={"column"}
                    >
                      {post ? (
                        <VStack gap={0} alignItems={"flex-start"}>
                          <HStack
                          gap={0}
                          >
                            <Text
                            as='u'
                              color={"red.700"}
                              noOfLines={1}
                              // mx={3}
                              ml={3}
                              mr={1}
                              fontWeight={"semibold"}
                              mt={2}
                              fontSize={18}
                            >
                              {post.title}
                            </Text>

                            <ChevronRightIcon mt={3} mr={"4"} boxSize={4} />
                          </HStack>

                          <Text
                            // color={"red.700"}
                            noOfLines={1}
                            mx={3}
                            // fontWeight={"semibold"}
                            // mt={2}
                            fontSize={14}
                          >
                            {post.placeName} - {testFormatDate()}
                          </Text>
                        </VStack>
                      ) : (
                        <Skeleton w={"100px"} h={"10px"} />
                      )}
                    </Flex>

                    <Flex flexDir={"row"}>
                      <Flex
                        w={"full"}
                        // bg={"yellow"}
                        onClick={() => {
                          navigate(`/u/${userProfile.username}`);
                        }}
                        alignContent={"center"}
                        flexDir={"row"}
                        gap={0}
                      >
                        {userProfile ? (
                          <Avatar
                            m={2}
                            size={"sm"}
                            src={userProfile.profilePicURL}
                          />
                        ) : (
                          <SkeletonCircle size="10" />
                        )}

                        <Flex w={"full"} flexDir={"column"}>
                          {userProfile ? (
                            <Text mt={2} fontSize={11}>
                              {userProfile.fullName}
                            </Text>
                          ) : (
                            <Skeleton w={"100px"} h={"10px"} />
                          )}

                          {userProfile ? (
                            <Text color={"gray.500"} fontSize={11}>
                              @{userProfile.username}
                            </Text>
                          ) : (
                            <Skeleton w={"100px"} h={"10px"} />
                          )}
                        </Flex>
                      </Flex>

                      <Spacer />

                      <Flex
                        px={1}
                        //  bg={"red"}
                        alignItems={"center"}
                      >
                        <Menu>
                          <MenuButton
                            // mr={2}
                            as={Button}
                            rounded={"full"}
                            variant={"link"}
                            cursor={"pointer"}
                            minW={0}
                          >
                            <BsThreeDotsVertical boxSize={8} />

                            {/* <Avatar
  size={'sm'}
  src={
    'https://images.unsplash.com/photo-1493666438817-866a91353ca9?ixlib=rb-0.3.5&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&s=b616b2c5b373a80ffc9636ba24f7a4a9'
  }
/> */}
                          </MenuButton>
                          <MenuList>
                            <MenuItem
                              onClick={() => {
                                // if (!authUser) {
                                //   showToast(
                                //     "Error",
                                //     "You must be logged in",
                                //     "error"
                                //   );

                                //   return;
                                // } else {
                                onOpenReportModal();
                                // }
                              }}
                            >
                              <Flex
                                gap={4}
                                alignItems={"center"}
                                flexDirection={"row"}
                              >
                                <WarningIcon ml={2} />
                                <Text>Report</Text>
                              </Flex>
                            </MenuItem>
                            <MenuDivider />
                            <MenuItem
                              onClick={() => {
                                if (memory.createdBy !== userId) {
                                  // if (!authUser) {
                                  showToast(
                                    "Error",
                                    "You didn't post this picture",
                                    "error"
                                  );
                                  return;
                                  // } else {
                                  //   onOpenBlockModal();
                                  // }
                                } else {
                                  handleDeleteMemory();
                                }
                              }}
                            >
                              <Flex
                                gap={4}
                                alignItems={"center"}
                                flexDirection={"row"}
                                colorScheme={"red"}
                              >
                                <DeleteIcon ml={2} />
                                <Text>Delete</Text>
                              </Flex>
                            </MenuItem>
                          </MenuList>
                        </Menu>
                      </Flex>
                    </Flex>
                  </Flex>
                </CardBody>
              </Card>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}

      <Modal
        isOpen={isOpenReportModal}
        onClose={onCloseReportModal}
        isCentered={true}
        size={{ base: "lg", md: "xl" }}
      >
        <ModalOverlay />
        <ModalContent bg={bgTheme} border={"1px solid gray"} maxW={"400px"}>
          <ModalHeader mt={4}>Report this person</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={5}>
            <Flex px={1} w={"full"} mx={"auto"} flexDirection={"column"}>
              <Flex gap="4">
                <RadioGroup onChange={setReportReason} value={reportReason}>
                  <Stack spacing={5} direction="column">
                    <Radio size="md" value="behaviour">
                      Inappropriate behavior
                    </Radio>
                    <Radio size="md" value="scam">
                      Scam or fraud
                    </Radio>
                    <Radio size="md" value="impersonation">
                      Impersonation
                    </Radio>
                    <Radio size="md" value="underage">
                      Underage user
                    </Radio>
                    <Radio size="md" value="offensive">
                      Offensive or inappropriate content
                    </Radio>
                    <Radio size="md" value="suspicious">
                      Suspicious or malicious activity
                    </Radio>
                    <Radio size="md" value="violation">
                      Violation of terms of service
                    </Radio>
                  </Stack>
                </RadioGroup>
              </Flex>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button
              userSelect={"none"}
              mb={2}
              mr={4}
              onClick={onCloseReportModal}
              // isLoading={isLoading}
            >
              Cancel
            </Button>

            <Button
              mb={2}
              mr={0}
              colorScheme="red"
              variant={"outline"}
              onClick={() => {
                // if (value === "hide") {
                handleReportUser();
                onCloseReportModal();
                // } else {
                //   onCloseManageLinkModal();
                // }
              }}
              isLoading={isReportUpdating}
            >
              Confirm
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onCloseModal}
        isOpen={isOpenModal}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>Are you a member? 🤔</AlertDialogHeader>
          {/* <AlertDialogCloseButton /> */}
          <AlertDialogBody>Only members can see this.</AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onCloseModal}>
              Cancel
            </Button>

            <Link to="/auth">
              <Button
                variant={"outline"}
                onClick={onCloseModal}
                colorScheme="whatsapp"
                ml={3}
              >
                Login
              </Button>
            </Link>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default MemoryCard;
