// hooks/useGenerateTicket.js
import { useState } from "react";
import { firestore } from "../firebase/firebase";
import { collection, addDoc } from "firebase/firestore";

const useGenerateTicket = () => {
  const [loading, setLoading] = useState(false);

  const generateTicket = async (eventId, userId, guestCount) => {
    setLoading(true);
    try {
      const ticketRef = collection(firestore, "tickets");
      await addDoc(ticketRef, {
        eventId,
        userId,
        createdAt: new Date(),
        guestCount: guestCount,
      });
      setLoading(false);
      return { success: true };
    } catch (error) {
      setLoading(false);
      return { success: false, error: error.message };
    }
  };

  return { generateTicket, loading };
};

export default useGenerateTicket;
