import {
  Avatar,
  Box,
  Container,
  Divider,
  Flex,
  HStack,
  Stack,
  Text,
  useColorModeValue,
  VStack,
  CircularProgress,
  useToast,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  OrderedList,
  ListItem,
  Button,
} from "@chakra-ui/react";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { useEffect, useState, useRef } from "react";
import { AiFillSetting } from "react-icons/ai";
import { MdHelpCenter } from "react-icons/md";
import { FaDatabase, FaPencilAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import useAuthStore from "../../store/authStore";
import useGetUserProfileById from "../../hooks/useGetUserProfileById";
import { motion, AnimatePresence } from "framer-motion";
import { IoNotifications, IoEyeOutline, IoChevronForward, IoArrowBack, IoArrowForward } from "react-icons/io5";
import { FaApple, FaAndroid } from "react-icons/fa";

const LogoComponent = ({ onClick }) => (
  <Box
    as="h1"
    fontSize={{ base: "28px", md: "32px" }}
    fontWeight="400"
    letterSpacing="0.35em"
    position="relative"
    display="inline-block"
    cursor="pointer"
    color="#1A1A1A"
    textTransform="uppercase"
    fontFamily="Montserrat, sans-serif"
    onClick={onClick}
    _hover={{
      letterSpacing: "0.4em",
      transform: "translateY(-1px)",
      transition: "all 0.4s cubic-bezier(0.4, 0, 0.2, 1)",
    }}
    transition="all 0.4s cubic-bezier(0.4, 0, 0.2, 1)"
    sx={{
      WebkitFontSmoothing: "antialiased",
      textRendering: "optimizeLegibility",
    }}
  >
    <Box
      as="span"
      position="relative"
      _before={{
        content: '""',
        position: "absolute",
        left: "-8%",
        right: "-8%",
        top: "-8px",
        bottom: "-8px",
        background:
          "linear-gradient(135deg, rgba(26, 26, 26, 0.08), rgba(26, 26, 26, 0.03))",
        backdropFilter: "blur(4px)",
        opacity: { base: 0.95, md: 0.9 },
        borderRadius: "2px",
        transform: "skew(-4deg)",
        transition: "all 0.3s ease",
        zIndex: -1,
      }}
      _after={{
        content: '""',
        position: "absolute",
        left: 0,
        right: 0,
        bottom: "-4px",
        height: "1px",
        background:
          "linear-gradient(to right, transparent, currentColor, transparent)",
        opacity: 0.4,
        transform: "scaleX(0.8)",
        transition: "transform 0.3s ease",
      }}
      _hover={{
        _after: {
          transform: "scaleX(1)",
        },
      }}
    >
      hubzo
    </Box>
  </Box>
);

const isIOS = () => {
  return (
    /iPad|iPhone|iPod/.test(navigator.userAgent) ||
    (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)
  );
};

const isAndroid = () => {
  return /Android/.test(navigator.userAgent);
};

const isPWAInstalled = () => {
  return (
    window.matchMedia("(display-mode: standalone)").matches ||
    window.navigator.standalone === true
  );
};

const isMobileDevice = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
};

const getCompletionColor = (percentage) => {
  if (percentage < 1) return "gray.300" // For 0%
  if (percentage <= 20) return "red.400"
  if (percentage <= 40) return "orange.400"
  if (percentage <= 60) return "yellow.400"
  if (percentage <= 80) return "blue.400"
  return "green.400"
};

const UserProfilePage = () => {
  const navigate = useNavigate();
  const [profileLevel, setProfileLevel] = useState(1);
  const [currentCard, setCurrentCard] = useState(0);
  const [touchStart, setTouchStart] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);
  const [isInstallDialogOpen, setIsInstallDialogOpen] = useState(false);
  const cancelRef = useRef();

  const authUser = useAuthStore((state) => state.user);
  const { isLoading, userProfile } = useGetUserProfileById(authUser?.uid);

  const colors = {
    textColor: "gray.900",
    bgColor: "white",
    cardBg: "white",
    dividerColor: "gray.200",
    iconBgColor: "rgba(184, 160, 128, 0.1)",
    hoverBgColor: "rgba(184, 160, 128, 0.05)",
    subtitleColor: "gray.600",
    accentColor: "#B8A080",
    borderColor: "rgba(184, 160, 128, 0.2)",
  };

  const toast = useToast();

  useEffect(() => {
    if (userProfile) {
      const requiredFields = [
        "bio1",
        "bio2",
        "bio3",
        "verified",
        "profilePicURL",
        "instagram",
        "linkedin",
        "isInstagramVerified",
        "isLinkedinVerified",
        "job",
        "yob",
      ];
      const completedFields = requiredFields.filter(
        (field) => userProfile[field]
      ).length;
      const calculatedLevel = Math.min(
        Math.round((completedFields / requiredFields.length) * 5),
        5
      );
      setProfileLevel(calculatedLevel);
    }
  }, [userProfile]);

  const profileItems = [
    {
      label: "Verified Profile",
      field: "verified",
    },
    {
      label: "Profile Picture",
      field: "profilePicURL",
    },
    {
      label: "Instagram Verified",
      field: "isInstagramVerified",
    },
    {
      label: "LinkedIn Verified",
      field: "isLinkedinVerified",
    },
    {
      label: "Basic Info",
      isComplete: !!(
        userProfile?.job &&
        userProfile?.yob &&
        userProfile?.instagram &&
        userProfile?.linkedin
      ),
    },
  ];

  const completedItems = profileItems.filter(
    ({ field, isComplete }) => userProfile?.[field] || isComplete
  ).length;
  const profileRating = Math.round((completedItems / profileItems.length) * 5);

  const handleTouchStart = (e) => {
    setTouchStart(e.touches[0].clientX);
  };

  const handleTouchMove = (e) => {
    setTouchEnd(e.touches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (touchStart - touchEnd > 75) {
      // Swipe left
      setCurrentCard((curr) => (curr < 1 ? curr + 1 : curr));
    }
    if (touchStart - touchEnd < -75) {
      // Swipe right
      setCurrentCard((curr) => (curr > 0 ? curr - 1 : curr));
    }
  };

  const handleInstallPWA = () => {
    if (isIOS()) {
      // Show iOS installation instructions
      alert("To install: tap the Share button and then 'Add to Home Screen'");
    } else if (window.deferredPrompt) {
      // Show install prompt on Android
      window.deferredPrompt.prompt();
      window.deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the install prompt');
        }
        window.deferredPrompt = null;
      });
    } else {
      // Show manual installation dialog as fallback
      setIsInstallDialogOpen(true);
    }
  };

  const handleShare = async () => {
    const shareMessage = userProfile?.userCode 
      ? `Hey! I found this exclusive meetup app that connects like-minded professionals. Use my code ${userProfile.userCode} when signing up to boost both our profiles!`
      : `Hey! I found this exclusive meetup app that connects like-minded professionals.\n\nhttps://hubzo.social`;

    if (navigator.share) {
      try {
        await navigator.share({
          title: 'Join Exclusive Meetups on Hubzo',
          text: shareMessage,
        });
      } catch (error) {
        if (error.name !== 'AbortError') {
          await navigator.clipboard.writeText(shareMessage);
          toast({
            title: "Link copied!",
            description: "Share link has been copied to your clipboard",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        }
      }
    } else {
      try {
        await navigator.clipboard.writeText(shareMessage);
        toast({
          title: "Link copied!",
          description: "Share link has been copied to your clipboard",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } catch (error) {
        console.error('Failed to copy text: ', error);
        toast({
          title: "Error",
          description: "Failed to copy share link",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }
  };

  const handleProfileClick = () => {
    if (authUser?.uid) {
      navigate(`/user/${authUser.uid}`);
    }
  };

  return (
    <Box
      h="100vh"
      maxH="100vh"
      overflow="hidden"
      position="relative"
      bg={colors.bgColor}
      pb="env(safe-area-inset-bottom)"
    >
      <Box
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        overflowY="auto"
        pb={{ base: 32, md: 8 }}
        style={{
          WebkitOverflowScrolling: "touch",
        }}
      >
        <Container
          maxW={{ base: "container.md", md: "container.xl" }}
          px={4}
          py={6}
          mb={{ base: 8, md: 0 }}
        >
          {/* Logo and Notifications Section */}
          <Flex 
            display={{ base: "flex", md: "none" }} 
            mb={8} 
            alignItems="center"
            justifyContent="space-between"
          >
            <LogoComponent onClick={() => navigate("/")} />
            <Box
              w="40px"
              h="40px"
              borderRadius="full"
              border="1px solid"
              borderColor={colors.borderColor}
              display="flex"
              alignItems="center"
              justifyContent="center"
              cursor="pointer"
              onClick={() => {
                if (userProfile?.fullName === "zec") {
                  navigate("/users_list");
                } else {
                  navigate("/notifications");
                }
              }}
              _hover={{
                borderColor: colors.accentColor,
                transform: "translateY(-2px)",
              }}
              transition="all 0.2s"
              bg={colors.iconBgColor}
            >
              <IoNotifications size={20} color={colors.textColor} />
            </Box>
          </Flex>

          {/* Profile Section */}
          <Box
            w="full"
            maxW={{ base: "full", md: "2xl" }}
            mx="auto"
            mb={{ base: 8, md: 12 }}
          >
            <Box
              mb={12}
              textAlign="center"
              bg={colors.cardBg}
              borderRadius="xl"
              p={6}
              borderWidth="1px"
              borderColor={colors.borderColor}
              shadow="sm"
              position="relative"
              cursor="pointer"
              onClick={handleProfileClick}
              _hover={{
                transform: "translateY(-2px)",
                shadow: "lg",
                borderColor: colors.accentColor,
                "& .preview-bar": {
                  bg: colors.accentColor,
                  color: "white",
                  transform: "translateY(-1px)",
                }
              }}
              transition="all 0.2s"
            >
              <Box
                position="relative"
                w={{ base: "150px", md: "180px" }}
                h={{ base: "150px", md: "180px" }}
                mx="auto"
              >
                <CircularProgress
                  value={(profileRating / 5) * 100}
                  size="100%"
                  color={getCompletionColor((profileRating / 5) * 100)}
                  thickness="8px"
                  capIsRound
                  trackColor="gray.100"
                  isIndeterminate={false}
                  min={0}
                  max={100}
                  angle={0}
                  rotation={180}
                  transition="all 0.3s ease"
                />
                <Avatar
                  size={{ base: "xl", md: "2xl" }}
                  src={userProfile?.profilePicURLs?.pic1?.url || userProfile?.profilePicURL}
                  name={userProfile?.fullName}
                  position="absolute"
                  top="50%"
                  left="50%"
                  transform="translate(-50%, -50%)"
                  borderColor={colors.bgColor}
                  borderWidth="4px"
                />
                <Box
                  className="profile-indicator"
                  position="absolute"
                  top="50%"
                  left="50%"
                  transform="translate(-50%, -50%) scale(0.95)"
                  w="calc(100% + 8px)"
                  h="calc(100% + 8px)"
                  borderRadius="full"
                  bg="blackAlpha.400"
                  opacity={0}
                  transition="all 0.3s ease"
                  _after={{
                    content: '""',
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "30%",
                    height: "30%",
                    borderRadius: "full",
                    border: "2px solid white",
                    opacity: 0.9,
                  }}
                />
                <Box
                  position="absolute"
                  bottom="-24px"
                  left="50%"
                  transform="translateX(-50%)"
                  bg="white"
                  px={2}
                  py={1}
                  borderRadius="md"
                  shadow="sm"
                  borderWidth="1px"
                  borderColor="gray.200"
                >
                  <Text
                    fontSize="sm"
                    fontWeight="medium"
                    color={getCompletionColor((profileRating / 5) * 100)}
                  >
                    {Math.round((profileRating / 5) * 100)}%
                  </Text>
                </Box>
              </Box>

              <Box
                className="preview-bar"
                mx="auto"
                mt={8}
                mb={4}
                py={2}
                px={4}
                maxW="200px"
                borderRadius="full"
                bg={colors.iconBgColor}
                color={colors.subtitleColor}
                fontSize="sm"
                fontWeight="medium"
                display="flex"
                alignItems="center"
                justifyContent="center"
                gap={2}
                transition="all 0.2s"
                border="1px solid"
                borderColor="transparent"
                _groupHover={{
                  borderColor: colors.accentColor,
                }}
              >
                <IoEyeOutline size={16} />
                <Text>View profile</Text>
              </Box>

              <Text
                fontWeight="medium"
                fontSize={{ base: "2xl", md: "3xl" }}
                color={colors.textColor}
                fontFamily="Montserrat, sans-serif"
                letterSpacing="wide"
                transition="all 0.2s"
              >
                {userProfile?.fullName || "Complete Your Profile"}
              </Text>

              {/* Settings and Edit Profile buttons */}
              <Box mt={4}>
                {/* Settings and Help buttons row */}
                <Flex justify="space-between" px={6} mb={0}>
                  {/* Settings */}
                  <VStack spacing={1}>
                    <Box
                      w="40px"
                      h="40px"
                      borderRadius="full"
                      border="1px solid"
                      borderColor={colors.borderColor}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      cursor="pointer"
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate("/settings");
                      }}
                      _hover={{
                        borderColor: colors.accentColor,
                        transform: "translateY(-2px)",
                      }}
                      transition="all 0.2s"
                    >
                      <AiFillSetting size={16} color={colors.textColor} />
                    </Box>
                    <Text
                      fontSize={{ base: "sm", md: "md" }}
                      color={colors.subtitleColor}
                      cursor="pointer"
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate("/settings");
                      }}
                      _hover={{
                        color: colors.accentColor,
                      }}
                      transition="all 0.2s"
                    >
                      Settings
                    </Text>
                  </VStack>

                  {/* Help Center */}
                  <VStack spacing={1}>
                    <Box
                      w="40px"
                      h="40px"
                      borderRadius="full"
                      border="1px solid"
                      borderColor={colors.borderColor}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      cursor="pointer"
                      onClick={(e) => {
                        e.stopPropagation();
                        window.open("https://hubzo.social/faq", "_blank");
                      }}
                      _hover={{
                        borderColor: colors.accentColor,
                        transform: "translateY(-2px)",
                      }}
                      transition="all 0.2s"
                    >
                      <MdHelpCenter size={16} color={colors.textColor} />
                    </Box>
                    <Text
                      fontSize={{ base: "sm", md: "md" }}
                      color={colors.subtitleColor}
                      cursor="pointer"
                      onClick={(e) => {
                        e.stopPropagation();
                        window.open("https://hubzo.social/faq", "_blank");
                      }}
                      _hover={{
                        color: colors.accentColor,
                      }}
                      transition="all 0.2s"
                    >
                      Help
                    </Text>
                  </VStack>
                </Flex>

                {/* Edit Profile button below */}
                <VStack spacing={1} mt={-12}>
                  <Box
                    w="40px"
                    h="40px"
                    borderRadius="full"
                    border="1px solid"
                    borderColor={colors.borderColor}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    cursor="pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate("/edit_profile");
                    }}
                    _hover={{
                      borderColor: colors.accentColor,
                      transform: "translateY(-2px)",
                    }}
                    transition="all 0.2s"
                  >
                    <FaPencilAlt size={16} color={colors.textColor} />
                  </Box>
                  <Text
                    fontSize={{ base: "sm", md: "md" }}
                    color={colors.subtitleColor}
                    cursor="pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate("/edit_profile");
                    }}
                    _hover={{
                      color: colors.accentColor,
                    }}
                    transition="all 0.2s"
                  >
                    Edit profile
                  </Text>
                </VStack>
              </Box>

              {/* Swipeable Cards */}
              <Box mt={12} mb={6} position="relative">
                <Box
                  onTouchStart={handleTouchStart}
                  onTouchMove={handleTouchMove}
                  onTouchEnd={handleTouchEnd}
                  overflow="hidden"
                  position="relative"
                >
                  <AnimatePresence initial={false} mode="wait">
                    <motion.div
                      key={currentCard}
                      initial={{
                        opacity: 0,
                        x: currentCard === 0 ? -200 : 200,
                      }}
                      animate={{ opacity: 1, x: 0 }}
                      exit={{ opacity: 0, x: currentCard === 0 ? 200 : -200 }}
                      transition={{
                        type: "spring",
                        stiffness: 300,
                        damping: 30,
                      }}
                    >
                      {currentCard === 0 && !isPWAInstalled() && isMobileDevice() ? (
                        <Box
                          p={5}
                          bg={colors.cardBg}
                          borderRadius="xl"
                          borderWidth="1px"
                          borderColor={colors.borderColor}
                          shadow="sm"
                        >
                          <VStack align="stretch" spacing={2}>
                            <HStack justify="space-between" align="center">
                              <HStack spacing={3}>
                                {isIOS() ? (
                                  <FaApple size={18} color="#B8A080" />
                                ) : (
                                  <FaAndroid size={18} color="#B8A080" />
                                )}
                                <Text
                                  fontSize="md"
                                  fontWeight="500"
                                  color={colors.textColor}
                                >
                                  Get the Hubzo app
                                </Text>
                              </HStack>
                              <Box
                                as="button"
                                bg={colors.accentColor}
                                color="white"
                                px={3}
                                py={1}
                                borderRadius="md"
                                fontSize="sm"
                                onClick={handleInstallPWA}
                                _hover={{
                                  opacity: 0.9,
                                }}
                              >
                                Install
                              </Box>
                            </HStack>
                            <Text
                              align="left"
                              fontSize="xs"
                              color={colors.subtitleColor}
                              ml={7}
                            >
                              Never miss an event with instant updates
                            </Text>
                          </VStack>
                        </Box>
                      ) : (
                        <Box
                          p={5}
                          bg={colors.cardBg}
                          borderRadius="xl"
                          borderWidth="1px"
                          borderColor={colors.borderColor}
                          shadow="sm"
                        >
                          <VStack align="stretch" spacing={2}>
                            <HStack justify="space-between" align="center">
                              <HStack spacing={3}>
                                <Box
                                  w="18px"
                                  h="18px"
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="center"
                                >
                                  <Box
                                    w="4px"
                                    h="4px"
                                    borderRadius="full"
                                    bg={colors.accentColor}
                                  />
                                </Box>
                                <Text
                                  fontSize="md"
                                  fontWeight="500"
                                  color={colors.textColor}
                                >
                                  Boost Profile Score
                                </Text>
                              </HStack>
                              <Box
                                as="button"
                                bg={colors.accentColor}
                                color="white"
                                px={3}
                                py={1}
                                borderRadius="md"
                                fontSize="sm"
                                onClick={handleShare}
                                _hover={{
                                  opacity: 0.9,
                                }}
                              >
                                Share
                              </Box>
                            </HStack>
                            <Text
                              align="left"
                              fontSize="xs"
                              color={colors.subtitleColor}
                              ml={7}
                            >
                              Friends joining with your code boost your invite
                              chances
                            </Text>
                          </VStack>
                        </Box>
                      )}
                    </motion.div>
                  </AnimatePresence>
                </Box>

                {/* Page Indicator */}
                <HStack justify="center" spacing={2} mt={4}>
                  {[0, 1].map((index) => (
                    <Box
                      key={index}
                      w={1.5}
                      h={1.5}
                      borderRadius="full"
                      bg={
                        currentCard === index
                          ? colors.accentColor
                          : colors.borderColor
                      }
                      opacity={currentCard === index ? 1 : 0.3}
                      cursor="pointer"
                      onClick={() => setCurrentCard(index)}
                      transition="all 0.2s"
                    />
                  ))}
                </HStack>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>

      <AlertDialog
        isOpen={isInstallDialogOpen}
        leastDestructiveRef={cancelRef}
        onClose={() => setIsInstallDialogOpen(false)}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent mx={4}>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Install Hubzo App
            </AlertDialogHeader>

            <AlertDialogBody>
              <Text mb={4}>
                To install Hubzo on your Android device, follow these steps:
              </Text>
              <OrderedList spacing={3}>
                <ListItem>
                  Tap the three dots (⋮) in the top-right corner of Chrome
                </ListItem>
                <ListItem>
                  Look for "Install app" or "Add to Home screen" in the menu
                </ListItem>
                <ListItem>
                  Tap "Install" in the confirmation dialog
                </ListItem>
                <ListItem>
                  The app will be installed and available on your home screen
                </ListItem>
              </OrderedList>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                onClick={() => setIsInstallDialogOpen(false)}
                size="lg"
                variant="ghost"
              >
                Close
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};

export default UserProfilePage;
