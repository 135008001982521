import {
  Container,
  Flex,
  Link,
  Skeleton,
  SkeletonCircle,
  Text,
  Input,
  Button,
  Box,
  Textarea,
  useDisclosure,
  InputGroup,
  InputLeftElement,
  VStack,
  useColorModeValue,
  Card,
  CardBody,
} from "@chakra-ui/react";
import { CalendarIcon } from "@chakra-ui/icons";

import { WhatsappLogo, WhatsappGreenLogo } from "../../assets/constants";
import PageFeedPosts from "../../components/FeedPosts/PageFeedPosts";
import ProfileHeader from "../../components/Profile/ProfileHeader";
import ProfileTabs from "../../components/Profile/ProfileTabs";
import ProfilePosts from "../../components/Profile/ProfilePosts";
import useGetUserProfileByUsername from "../../hooks/useGetUserProfileByUsername";
import { useParams } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import useAuthStore from "../../store/authStore";
import useGetFeedPostById from "../../hooks/useGetFeedPostById";
// import ProfileHeaderSkeleton from "../ProfilePage/ProfilePage/ProfileHeaderSkeleton";
import FeedPostDetails from "../../components/FeedPosts/FeedPostDetails";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useRef, useState } from "react";
import usePreviewImg from "../../hooks/usePreviewImg";
import useShowToast from "../../hooks/useShowToast";
import usePostStore from "../../store/postStore";
import { useLocation } from "react-router-dom";
import useUserProfileStore from "../../store/userProfileStore";
import { firestore, storage } from "../../firebase/firebase";
import { getDownloadURL, ref, uploadString } from "firebase/storage";
// import { StandaloneSearchBox, useJsApiLoader } from "@react-google-maps/api";
import { useNavigate } from "react-router-dom";

import {
  getDoc,
  setDoc,
  getDocs,
  query,
  where,
  addDoc,
  arrayUnion,
  collection,
  doc,
  updateDoc,
} from "firebase/firestore";

const CreateGroupPage = () => {
  const libraries = ["places"];
  const inputRef = useRef();

  const handlePlaceChanged = () => {
    const [place] = inputRef.current.getPlaces();
    if (place) {
      setLocation(place.name);
      setPlaceLocation(place.url);
      setAddress(place.formatted_address);
      console.log(place.formatted_address);
      console.log(place.name);
      console.log(place.geometry.location.lat());
      console.log(place.geometry.location.lng());
    }
  };

  // const { isLoaded, loadError } = useJsApiLoader({
  //   googleMapsApiKey: import.meta.env.VITE_GOOGLE_API_KEY,
  //   libraries,
  // });

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { postId } = useParams();
  // const { isLoading, post } = useGetFeedPostById(postId);
  const authUser = useAuthStore((state) => state.user);
  const setAuthUser = useAuthStore((state) => state.setUser);

  const { userProfile, setUserProfile } = useUserProfileStore();

  // const userNotFound = !isLoading && !post;

  const [title, setTitle] = useState("");
  const [caption, setCaption] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [username, setUsername] = useState("");

  const showToast = useShowToast();
  const { isLoading, handleCreatePost } = useCreatePost();
  const [location, setLocation] = useState("");
  const navigate = useNavigate();

  // const handlePostCreation = async () => {
  //   try {
  //     await handleCreatePost(
  //       selectedFile,
  //       title,
  //       caption,
  //       location,
  //       whatsapp,
  //       startDate,
  //       placeLocation,
  //       address
  //     );
  //     onClose();
  //     setStartDate("");
  //     setTitle("");
  //     setCaption("");
  //     setLocation("");
  //     setSelectedFile(null);
  //     setPlaceLocation("");
  //     setWhatsapp("");
  //     navigate("/");
  //   } catch (error) {
  //     showToast("Error", error.message, "error");
  //   }
  // };

  // if (userNotFound) return <CreatePageNotFound />;

  // const visitingOwnProfileAndAuth = authUser && authUser.username === username;

  // const bgTheme = useColorModeValue(`#FFC0CB`, `#FFFF00`);

  const WhatsappThemeLogo = useColorModeValue(WhatsappGreenLogo, WhatsappLogo);

  const handleGoogleAuth = async () => {
    try {
      // const newUser = await signInWithGoogle();
      // if (!newUser && error) {
      //   showToast("Error", error.message, "error");
      //   return;
      // }
      // const userRef = doc(firestore, "users", newUser.user.uid);
      // const userSnap = await getDoc(userRef);

      // if (userSnap.exists()) {
      //   // login
      //   const userDoc = userSnap.data();
      //   localStorage.setItem("user-info", JSON.stringify(userDoc));
      //   loginUser(userDoc);
      // } else {
      // signup

      if (!authUser) {
        showToast("Error", "You must be logged in", "error");
        return;
      }

      const groupsRef = collection(firestore, "groups");

      const q = query(groupsRef, where("whatsapp", "==", whatsapp));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        showToast("Error", "WhatsApp group already exists", "error");
        return;
      }

      const qUsername = query(groupsRef, where("username", "==", username));
      const querySnapshotUsername = await getDocs(qUsername);

      if (!querySnapshotUsername.empty) {
        showToast("Error", "Username already exists", "error");
        return;
      }

      const userDoc = {
        admins: [authUser.uid],
        createdBy: authUser.uid,
        uid: "sdfsdfsdfds",
        username: username,
        fullName: title,
        bio: caption,
        whatsapp: whatsapp,
        profilePicURL: "",
        followers: [],
        following: [],
        posts: [],
        createdAt: Date.now(),
        type: "unverified",
        memberList: [],
      };

      const postDocRef = await addDoc(collection(firestore, "groups"), userDoc);

      // if (userProfile)
      //   setUserProfile({
      //     ...userProfile,
      //     groups: [...userProfile.groups, postDocRef.id],
      //   });

      const userRef = doc(firestore, "users", authUser.uid);
      // await deleteDoc(doc(firestore, "posts", post.id));

      await updateDoc(userRef, {
        groups: arrayUnion(postDocRef.id),
      });

      // setAuthUser({
      //   ...authUser,
      //   groups: [...authUser.groups, postDocRef.id],
      // });

      // localStorage.setItem(
      //   "user-info",
      //   JSON.stringify({
      //     ...authUser,
      //     groups: [...authUser.groups, postDocRef.id],
      //   })
      // );

      // await setDoc(doc(firestore, "groups", "fsdfdsfdsfsd"), userDoc);
      // localStorage.setItem("user-info", JSON.stringify(userDoc));
      // loginUser(userDoc);
      // }
      showToast("Success", "Group created successfully", "success");

      onClose();
      setTitle("");
      setCaption("");
      setWhatsapp("");
      navigate("/");
    } catch (error) {
      showToast("Error", error.message, "error");
    }
  };

  return (
    <>
      <Container py={0}>
        <Flex px={1} w={"full"} mx={"auto"} flexDirection={"column"}>
          <Card mb={0} mt={0}>
            <CardBody>
              <Text mb={0} fontSize={24}>
                Create your own group 🚀
              </Text>

              <InputGroup mt={0} mb={0}>
                <InputLeftElement pointerEvents="none">
                  <WhatsappThemeLogo />
                </InputLeftElement>
                <Input
                  borderColor="whiteAlpha.900"
                  mt={0}
                  mb={0}
                  size={"md"}
                  placeholder="Group Link"
                  value={whatsapp}
                  onChange={(e) => setWhatsapp(e.target.value)}
                />
              </InputGroup>

              <Input
                borderColor="whiteAlpha.900"
                mt={4}
                type="text"
                placeholder="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />

              <Input
                borderColor="whiteAlpha.900"
                mt={4}
                type="text"
                placeholder="Name..."
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
              <Textarea
                borderColor="whiteAlpha.900"
                mt={4}
                placeholder="About...?"
                value={caption}
                onChange={(e) => setCaption(e.target.value)}
              />
            </CardBody>
          </Card>

          <Button
            mr={0}
            mb={32}
            border="2px"
            borderColor="gray.500"
            variant={"outline"}
            // onClick={handlePostCreation}
            onClick={handleGoogleAuth}
            // isLoading={isLoading}
          >
            Create
          </Button>
        </Flex>
      </Container>
    </>
  );
};

export default CreateGroupPage;

const CreatePageHeaderSkeleton = () => {
  return (
    <Flex
      gap={{ base: 4, sm: 10 }}
      py={10}
      direction={{ base: "column", sm: "row" }}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <SkeletonCircle size="24" />

      <VStack
        alignItems={{ base: "center", sm: "flex-start" }}
        gap={2}
        mx={"auto"}
        flex={1}
      >
        <Skeleton height="12px" width="150px" />
        <Skeleton height="12px" width="100px" />
      </VStack>
    </Flex>
  );
};

const CreatePageNotFound = () => {
  return (
    <Flex mt={8} flexDir="column" textAlign={"center"} mx={"auto"}>
      <Text fontSize={"2xl"}>User Not Found</Text>
      <Link
        as={RouterLink}
        to={"/"}
        color={"blue.500"}
        w={"max-content"}
        mx={"auto"}
      >
        Go home
      </Link>
    </Flex>
  );
};

function useCreatePost() {
  const showToast = useShowToast();
  const [isLoading, setIsLoading] = useState(false);
  const authUser = useAuthStore((state) => state.user);
  const createPost = usePostStore((state) => state.createPost);
  const addPost = useUserProfileStore((state) => state.addPost);
  const userProfile = useUserProfileStore((state) => state.userProfile);
  const { pathname } = useLocation();

  const handleCreatePost = async (
    selectedFile,
    title,
    caption,
    location,
    whatsapp,
    startDate,
    placeLocation,
    address
  ) => {
    if (isLoading || !authUser) {
      return;
    }

    if (!authUser) {
      showToast("Error", "You must be logged in", "error");
      return;
    }
    // if (!selectedFile) throw new Error("Please select an image");
    setIsLoading(true);
    const newPost = {
      title: title,
      caption: caption,
      placeName: location,
      whatsapp: whatsapp,
      likes: [],
      goingList: [],
      comments: [],
      createdAt: Date.now(),
      createdBy: authUser.uid,
      startAt: new Date(startDate),
      placeLocation: placeLocation,
      address: address,
    };

    try {
      const postDocRef = await addDoc(collection(firestore, "posts"), newPost);
      const userDocRef = doc(firestore, "users", authUser.uid);
      // const imageRef = ref(storage, `posts/${postDocRef.id}`);

      await updateDoc(userDocRef, { posts: arrayUnion(postDocRef.id) });
      // await uploadString(imageRef, selectedFile, "data_url");
      // const downloadURL = await getDownloadURL(imageRef);

      // await updateDoc(postDocRef, { imageURL: downloadURL });
      await updateDoc(postDocRef, {
        imageURL:
          "https://www.meetup.com/_next/image/?url=https%3A%2F%2Fsecure-content.meetupstatic.com%2Fimages%2Fclassic-events%2F508388773%2F676x380.webp&w=3840&q=75",
      });

      // newPost.imageURL = downloadURL;

      if (userProfile.uid === authUser.uid)
        createPost({ ...newPost, id: postDocRef.id });

      if (pathname !== "/" && userProfile.uid === authUser.uid)
        addPost({ ...newPost, id: postDocRef.id });

      showToast("Success", "Post created successfully", "success");
    } catch (error) {
      showToast("Error", error.message, "error");
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, handleCreatePost };
}

// // skeleton for profile header
// const ProfileHeaderSkeleton = () => {
//   return (
//     <Flex
//       gap={{ base: 4, sm: 10 }}
//       py={10}
//       direction={{ base: "column", sm: "row" }}
//       justifyContent={"center"}
//       alignItems={"center"}
//     >
//       <SkeletonCircle size="24" />

//       <VStack
//         alignItems={{ base: "center", sm: "flex-start" }}
//         gap={2}
//         mx={"auto"}
//         flex={1}
//       >
//         <Skeleton height="12px" width="150px" />
//         <Skeleton height="12px" width="100px" />
//       </VStack>
//     </Flex>
//   );
// };

// const UserNotFound = () => {
//   return (
//     <Flex mt={8} flexDir="column" textAlign={"center"} mx={"auto"}>
//       <Text fontSize={"2xl"}>User Not Found</Text>
//       <Link
//         as={RouterLink}
//         to={"/"}
//         color={"blue.500"}
//         w={"max-content"}
//         mx={"auto"}
//       >
//         Go home
//       </Link>
//     </Flex>
//   );
// };
