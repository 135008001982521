import { Box } from '@chakra-ui/react';
import DesktopNav from '../Navigation/DesktopNav';

const DesktopLayout = ({ children }) => {
  return (
    <Box>
      <DesktopNav />
      <Box
        mt="64px"
        minH="calc(100vh - 64px)"
      >
        {children}
      </Box>
    </Box>
  );
};

export default DesktopLayout; 