import { useSignInWithEmailAndPassword } from "react-firebase-hooks/auth";
import useShowToast from "./useShowToast";
import { auth, firestore } from "../firebase/firebase";
import { doc, getDoc } from "firebase/firestore";
import useAuthStore from "../store/authStore";

const useLogin = () => {
  const showToast = useShowToast();
  const [signInWithEmailAndPassword, , loading, error] =
    useSignInWithEmailAndPassword(auth);
  const loginUser = useAuthStore((state) => state.login);
  const authUser = useAuthStore((state) => state.user);

  const login = async (inputs) => {
    if (!inputs.email || !inputs.password) {
      return showToast("Error", "Please fill all the fields", "error");
    }
    try {
      const userCred = await signInWithEmailAndPassword(
        inputs.email,
        inputs.password
      );

      if (userCred) {
        const docRef = doc(firestore, "users", userCred.user.uid);
        const docSnap = await getDoc(docRef);

        const userDoc = docSnap.data();

        if (userDoc.status == "deleted") {
          showToast("Error", "This account has been deleted", "error");
        } else {
          localStorage.setItem("user-info", JSON.stringify(userDoc));

          // localStorage.setItem(
          //   "user-info",
          //   JSON.stringify({
          //     ...authUser,
          //     verified: userCred.user.emailVerified,
          //   })
          // );

          // console.log(JSON.stringify(docSnap.data()));

          if (userCred.user.emailVerified) {
            // console.log("Email is verified, proceed to login");
            // Log the user in, load their data, etc.
          } else {
            // showToast(
            //   "Error",
            //   "Email not verified. You must verify your email to attend meetups.",
            //   "error"
            // );

            // console.log("Email not verified, prompt user to verify their email");
            // Show a message that the user needs to verify their email
          }

          loginUser(docSnap.data());
        }
      }
    } catch (error) {
      showToast("Error", error.message, "error");
    }
  };

  return { loading, error, login };
};

export default useLogin;
