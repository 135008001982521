import {
  Container,
  Flex,
  Button,
  VStack,
  Box,
  Text,
  useColorModeValue,
  Image,
  HStack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const SignUpPage = () => {
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = 3;
  const navigate = useNavigate();

  // Use Chakra UI's `useColorModeValue` to toggle between dark and light logos
  const logoSrc = useColorModeValue(
    "/hubzo_landing_logo_light.png", // Light theme version
    "/hubzo_landing_logo_dark.png" // Dark theme version
  );

  useEffect(() => {
    const ready = (e) => {
      e.preventDefault();
    };
    window.addEventListener("beforeinstallprompt", ready);
    return () => {
      window.removeEventListener("beforeinstallprompt", ready);
    };
  }, []);

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };

  return (
    <Container
      bg={useColorModeValue("white", "black")}
      maxW="container.sm" // Keep it within the viewport width
      // h="100vh"
      px={4} // Padding on the sides
      py={4}
      minHeight="calc(var(--vh, 1vh) * 100)"  // Use dynamic custom property for height
      display="flex"
      justifyContent="space-between"
      flexDirection="column"
      alignItems="center"
      overflow="hidden" // Ensure no overflow
    >
      {/* Main Content */}
      <Flex
        direction="column"
        align="center"
        justify="center"
        flexGrow={1}
        w="full"
        textAlign="center"
      >
        {/* Logo Section */}
        <Box mb={8}>
          <Image src={logoSrc} alt="Hubzo Logo" w="150px" /> {/* Dynamic logo switching */}
        </Box>

        {/* Heading */}
        <Box mb={6}>
          <Text
            fontSize={{ base: "2xl", md: "4xl" }}
            fontWeight="bold"
            color={useColorModeValue("gray.900", "gray.100")}
            maxW="280px"
            mx="auto"
            wordBreak="break-word" // Prevent text overflow
            lineHeight="1.2"
          >
            Your Kind of People. Your Kind of Privacy.
          </Text>
        </Box>

        {/* Swipable Info Section */}
        <Box w="full" mb={6}>
          <AutoPlaySwipeableViews
            index={activeStep}
            onChangeIndex={handleStepChange}
            enableMouseEvents
          >
            <Box px={4}> {/* Ensure padding to prevent overflow */}
              <Text color={useColorModeValue("gray.700", "gray.300")} fontSize="lg">
                Your privacy matters! Meet people without swiping or browsing profiles.
              </Text>
            </Box>
            <Box px={4}>
              <Text color={useColorModeValue("gray.700", "gray.300")} fontSize="lg">
                Handpicked participants for exclusive meetups.
              </Text>
            </Box>
            <Box px={4}>
              <Text color={useColorModeValue("gray.700", "gray.300")} fontSize="lg">
                100% profile-less experience for genuine connections.
              </Text>
            </Box>
          </AutoPlaySwipeableViews>

          {/* Dots Indicator */}
          <HStack justify="center" spacing={2} mt={4}>
            {Array.from({ length: maxSteps }).map((_, index) => (
              <Box
                key={index}
                w={index === activeStep ? "12px" : "8px"}
                h={index === activeStep ? "12px" : "8px"}
                bg={index === activeStep ? useColorModeValue("gray.900", "gray.100") : "gray.400"}
                borderRadius="full"
                transition="all 0.2s ease"
              />
            ))}
          </HStack>
        </Box>
      </Flex>

      {/* Buttons */}
      <VStack spacing={3} w="full" pb={4}>
        {/* Get Started Button */}
        <Button
          bg="#007AFF" // Use darker color for light theme, white for dark theme
          color={useColorModeValue("white", "black")} // Text color inverted for readability
          size="lg"
          w="full"
          onClick={() => {
            navigate(`/choose_name/`);
          }}
          rounded="full"
          fontSize="lg"
          fontWeight="bold"
          _hover={{ bg: useColorModeValue("gray.700", "gray.200") }}
        >
          Get Started
        </Button>

        {/* Already Have an Account Button */}
        <Button
          variant="outline"
          size="lg"
          w="full"
          onClick={() => {
            navigate(`/sign_in/`);
          }}
          rounded="full"
          fontSize="lg"
          fontWeight="bold"
          borderColor={useColorModeValue("gray.900", "white")}
          color={useColorModeValue("gray.900", "white")}
          _hover={{ bg: useColorModeValue("gray.200", "gray.700") }}
        >
          I already have an account
        </Button>
      </VStack>

      {/* Footer Section */}
      <Box textAlign="center" w="full">
        <Text fontSize="sm" color={useColorModeValue("gray.700", "gray.400")}>
          By signing up, you agree to the{" "}
          <Text
            as="span"
            textDecoration="underline"
            onClick={() =>
              openInNewTab(
                "https://hubzo.app/privacy-policy/"
              )
            }
            cursor="pointer"
          >
            Terms of Services
          </Text>{" "}
          and{" "}
          <Text
            as="span"
            textDecoration="underline"
            onClick={() =>
              openInNewTab(
                "https://hubzo.app/privacy-policy/"
              )
            }
            cursor="pointer"
          >
            Privacy Policy
          </Text>
          .
        </Text>
      </Box>
    </Container>
  );
};

export default SignUpPage;
