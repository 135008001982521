import {
  Box,
  Text,
  HStack,
  VStack,
  Icon,
  Image,
  Flex,
  useColorModeValue,
  Divider,
} from "@chakra-ui/react";
import {
  FaCalendarAlt,
  FaMapMarkerAlt,
  FaUserFriends,
} from "react-icons/fa"; // Import relevant icons

import { useNavigate } from "react-router-dom";

const EventItemCard = ({ event }) => {
  const navigate = useNavigate();

  // Extracting key event information from the event object
  const eventData = {
    title: "Tech Connect 2024", // Example title (could be dynamic)
    date: event?.date || "Wednesday, June 7th at 7:30 PM", // Example date (dynamic or static)
    location: event?.location || "Lisbon, Portugal", // Example location (dynamic or static)
    expectedPeople: event?.expectedPeople || "15-25 people", // Expected attendees
    image: event?.image || "https://via.placeholder.com/150x150", // Example image URL
    id: event?.id || "1", // Example image URL
  };

  // Card background and text color
  const cardBg = useColorModeValue("gray.100", "gray.700");
  const cardTextColor = useColorModeValue("gray.800", "white");
  const iconColor = useColorModeValue("blue.600", "blue.300");
  const borderColor = useColorModeValue("gray.300", "gray.600");

  return (
    <Box
      bg={cardBg}
      borderRadius="lg"
      borderWidth="1px"
      borderColor={borderColor}
      p={4}
      shadow="md"
      w="full"
      cursor="pointer"
      _hover={{ boxShadow: "lg" }} // Add hover effect for better interaction
      onClick={() => navigate(`/e/SEaXqfybqGttFZcIbJtc`)} // Navigates to the detailed event page
    >
      <HStack align="center" spacing={4}>
        {/* Event Image */}
        <Image
          src={eventData.image}
          alt={eventData.title}
          boxSize="80px"
          borderRadius="lg"
          objectFit="cover"
        />

        {/* Content Section */}
        <VStack align="flex-start" spacing={2} w="full">
          {/* Event Title */}
          <Text fontSize="lg" fontWeight="bold" color={cardTextColor}>
            {eventData.title}
          </Text>

          {/* Event Date and Location */}
          <HStack>
            <Icon as={FaCalendarAlt} color={iconColor} />
            <Text fontSize="sm" color={cardTextColor}>
              {eventData.date}
            </Text>
          </HStack>

          <HStack>
            <Icon as={FaMapMarkerAlt} color={iconColor} />
            <Text fontSize="sm" color={cardTextColor}>
              {eventData.location}
            </Text>
          </HStack>

          <HStack>
            <Icon as={FaUserFriends} color={iconColor} />
            <Text fontSize="sm" color={cardTextColor}>
              {eventData.expectedPeople}
            </Text>
          </HStack>
        </VStack>
      </HStack>
    </Box>
  );
};

export default EventItemCard;
