import { useState } from "react";
import useAuthStore from "../store/authStore";
import useShowToast from "./useShowToast";
import { getDownloadURL, ref, uploadString } from "firebase/storage";
import { firestore, storage } from "../firebase/firebase";
import { doc, updateDoc, serverTimestamp } from "firebase/firestore";
import useUserProfileStore from "../store/userProfileStore";
import { useNavigate } from "react-router-dom";

const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

const useUpdateFacePic = () => {
  const [isUpdating, setIsUpdating] = useState(false);
  const authUser = useAuthStore((state) => state.user);
  const setAuthUser = useAuthStore((state) => state.setUser);
  const setUserProfile = useUserProfileStore((state) => state.setUserProfile);
  const navigate = useNavigate();
  const showToast = useShowToast();

  const reorderPhotos = async (currentUser) => {
    // Get all existing photos in order
    const photos = [];
    for (let i = 1; i <= 6; i++) {
      const slot = `pic${i}`;
      if (currentUser.profilePicURLs?.[slot]?.url) {
        photos.push({
          url: currentUser.profilePicURLs[slot].url,
          uploadedAt: currentUser.profilePicURLs[slot].uploadedAt,
          isVerified: currentUser.profilePicURLs[slot].isVerified
        });
      }
    }

    // Create new profilePicURLs object with reordered photos
    const newProfilePicURLs = {};
    photos.forEach((photo, index) => {
      newProfilePicURLs[`pic${index + 1}`] = photo;
    });

    // Update Firestore
    const userDocRef = doc(firestore, "users", currentUser.uid);
    const updates = {
      profilePicURLs: newProfilePicURLs
    };

    // Maintain backward compatibility for pic1
    if (newProfilePicURLs.pic1) {
      updates.profilePicURL = newProfilePicURLs.pic1.url;
    } else {
      updates.profilePicURL = '';
    }

    await updateDoc(userDocRef, updates);

    // Return the updated user object
    return {
      ...currentUser,
      profilePicURL: updates.profilePicURL,
      profilePicURLs: newProfilePicURLs
    };
  };

  const editProfile = async (selectedFile, pictureSlot, isRemoval = false) => {
    if (!selectedFile && !isRemoval) return;
    setIsUpdating(true);

    try {
      const userDocRef = doc(firestore, "users", authUser.uid);
      
      if (isRemoval) {
        // First update the current user object by removing the selected slot
        const currentProfilePicURLs = {
          ...(authUser.profilePicURLs || {})
        };
        delete currentProfilePicURLs[pictureSlot];
        
        const updatedUser = {
          ...authUser,
          profilePicURLs: currentProfilePicURLs
        };

        // Reorder the remaining photos
        const reorderedUser = await reorderPhotos(updatedUser);

        // Update local state
        setAuthUser(reorderedUser);
        setUserProfile(reorderedUser);
        localStorage.setItem("user-info", JSON.stringify(reorderedUser));
        
        // Add a small delay before returning
        await delay(500);
        showToast("Success", "Photo removed successfully", "success");
        return;
      }

      // Handle new photo upload
      const imageRef = ref(storage, `profilePics/${authUser.uid}/${pictureSlot}`);
      await uploadString(imageRef, selectedFile, "data_url");
      const downloadURL = await getDownloadURL(imageRef);

      // Update the current user object with the new photo
      const currentProfilePicURLs = {
        ...(authUser.profilePicURLs || {}),
        [pictureSlot]: {
          url: downloadURL,
          uploadedAt: Date.now(),
          isVerified: false
        }
      };

      const updatedUser = {
        ...authUser,
        profilePicURLs: currentProfilePicURLs
      };

      // Reorder photos to ensure contiguous slots
      const reorderedUser = await reorderPhotos(updatedUser);

      // Update local state
      setAuthUser(reorderedUser);
      setUserProfile(reorderedUser);
      localStorage.setItem("user-info", JSON.stringify(reorderedUser));
      
      // Add a small delay before returning
      await delay(500);
      showToast("Success", "Profile picture updated successfully", "success");
    } catch (error) {
      showToast("Error", error.message, "error");
    } finally {
      setIsUpdating(false);
    }
  };

  return { isUpdating, editProfile };
};

export default useUpdateFacePic;
