import {
  Container,
  Flex,
  Button,
  VStack,
  FormControl,
  Input,
  Text,
  IconButton,
  Box,
  Image,
  SimpleGrid,
  Icon,
  HStack,
  Progress,
} from "@chakra-ui/react";
import { ArrowBackIcon, AddIcon, CloseIcon } from "@chakra-ui/icons";
import { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useEditFacePic from "../../hooks/useEditFacePic";
import useShowToast from "../../hooks/useShowToast";
import { FaArrowRight } from "react-icons/fa";

const LogoComponent = ({ onClick }) => (
  <Box 
    as="h1" 
    fontSize={{ base: "28px", md: "32px" }}
    fontWeight="400"
    letterSpacing="0.35em"
    position="relative"
    display="inline-block"
    cursor="pointer"
    color="#1A1A1A"
    textTransform="uppercase"
    fontFamily="Montserrat, sans-serif"
    onClick={onClick}
    _hover={{
      letterSpacing: "0.4em",
      transform: "translateY(-1px)",
      transition: "all 0.4s cubic-bezier(0.4, 0, 0.2, 1)",
    }}
    transition="all 0.4s cubic-bezier(0.4, 0, 0.2, 1)"
    sx={{
      WebkitFontSmoothing: "antialiased",
      textRendering: "optimizeLegibility",
    }}
  >
    <Box
      as="span"
      position="relative"
      _before={{
        content: '""',
        position: "absolute",
        left: "-8%",
        right: "-8%",
        top: "-8px",
        bottom: "-8px",
        background: "linear-gradient(135deg, rgba(26, 26, 26, 0.08), rgba(26, 26, 26, 0.03))",
        backdropFilter: "blur(4px)",
        opacity: { base: 0.95, md: 0.9 },
        borderRadius: "2px",
        transform: "skew(-4deg)",
        transition: "all 0.3s ease",
        zIndex: -1,
      }}
      _after={{
        content: '""',
        position: "absolute",
        left: 0,
        right: 0,
        bottom: "-4px",
        height: "1px",
        background: "linear-gradient(to right, transparent, currentColor, transparent)",
        opacity: 0.4,
        transform: "scaleX(0.8)",
        transition: "transform 0.3s ease",
      }}
      _hover={{
        _after: {
          transform: "scaleX(1)",
        },
      }}
    >
      hubzo
    </Box>
  </Box>
);

const ChooseFacePicPage = () => {
  const showToast = useShowToast();
  const fileRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const { isUpdating, editProfile } = useEditFacePic();
  const navigate = useNavigate();
  const [selectedSlot, setSelectedSlot] = useState('pic1');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    const timer = setTimeout(() => setIsLoading(false), 0);
    return () => clearTimeout(timer);
  }, []);

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = async () => {
        setSelectedFile(reader.result);
        try {
          await editProfile(reader.result, selectedSlot);
          setSelectedFile(null);
        } catch (error) {
          showToast("Error", error.message, "error");
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRemovePhoto = async (slot) => {
    try {
      await editProfile(null, slot, true);
    } catch (error) {
      showToast("Error", error.message, "error");
    }
  };

  const handleSlotClick = (slot) => {
    setSelectedSlot(slot);
    fileRef.current.click();
  };

  const getImageUrlForSlot = (slot) => {
    return localStorage.getItem(`face-${slot}`);
  };

  const handleContinue = () => {
    // if (!localStorage.getItem('face-pic1')) {
    //   showToast("Error", "Please add at least one photo", "error");
    //   return;
    // }
    navigate("/choose_dob/");
  };

  if (isLoading) {
    return <Box minH="100vh" bg="white" />;
  }

  return (
    <Box 
      minH="100vh"
      maxH="100vh"
      overflow="hidden"
      position="relative"
      bg="white"
      pb="env(safe-area-inset-bottom)"
    >
      {/* Top Bar */}
      <Box
        position="fixed"
        top={0}
        left={0}
        right={0}
        zIndex={1000}
        bg="white"
        h={{ base: "56px", md: "64px" }}
        backdropFilter="blur(12px)"
        backgroundColor="rgba(255, 255, 255, 0.9)"
        borderBottom="1px solid"
        borderColor="gray.100"
      >
        <Container maxW="container.xl" h="full">
          <Flex 
            justify="space-between"
            align="center"
            h="full"
            px={{ base: 3, md: 4 }}
          >
            <IconButton
              icon={<ArrowBackIcon />}
              variant="ghost"
              aria-label="Go back"
              onClick={() => navigate(-1)}
              color="gray.500"
              display={{ base: 'flex', md: 'none' }}
              _hover={{ color: "black" }}
            />

            <LogoComponent onClick={() => navigate("/")} />

            <Box w="40px" display={{ base: 'block', md: 'none' }} />
          </Flex>
        </Container>
      </Box>

      {/* Main Content */}
      <Box 
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        overflowY="auto"
        style={{
          WebkitOverflowScrolling: 'touch'
        }}
      >
        <Box 
          pt={{ base: "56px", md: "64px" }}
          minH="100vh"
          pb={{ base: 32, md: 8 }}
        >
          <Container 
            maxW="container.sm" 
            py={{ base: 8, md: 16 }} 
            px={6}
            mb={{ base: 8, md: 0 }}
          >
            <VStack spacing={8} align="center" w="full">
              {/* Progress Bar */}
              <Progress
                value={50}
                size="sm"
                colorScheme="blackAlpha"
                w="full"
                maxW="400px"
                borderRadius="full"
                bg="gray.100"
              />

              <VStack spacing={3} textAlign="center">
                <Text
                  fontSize={{ base: "3xl", md: "4xl" }}
                  fontWeight="light"
                  letterSpacing="tight"
                  color="gray.900"
                  lineHeight="shorter"
                >
                  Add your photos
                </Text>
                <Text
                  fontSize={{ base: "sm", md: "md" }}
                  color="gray.500"
                >
                  {/* Upload up to 4 photos ·  */}
                  Only used by our invites algorithm
                </Text>
              </VStack>

              {/* Photo Grid */}
              <SimpleGrid 
                columns={2} 
                spacing={4} 
                w="full" 
                maxW="400px"
              >
                {[1, 2
                // , 3, 4
              ].map((num) => {
                  const slot = `pic${num}`;
                  const imageUrl = getImageUrlForSlot(slot);
                  return (
                    <Box key={num} position="relative">
                      <Box
                        position="relative"
                        w="full"
                        pb="100%"
                        role="group"
                      >
                        <Box
                          position="absolute"
                          top={0}
                          left={0}
                          right={0}
                          bottom={0}
                          borderWidth="2px"
                          borderStyle="dashed"
                          borderColor="gray.200"
                          borderRadius="xl"
                          bg="gray.50"
                          overflow="hidden"
                          transition="all 0.2s"
                          _hover={{
                            borderColor: "black",
                            shadow: "md",
                            transform: "translateY(-2px)",
                          }}
                        >
                          {imageUrl ? (
                            <>
                              <Image
                                src={imageUrl}
                                alt={`Picture ${num}`}
                                position="absolute"
                                top="0"
                                left="0"
                                w="full"
                                h="full"
                                objectFit="cover"
                              />
                              <IconButton
                                icon={<CloseIcon />}
                                size="xs"
                                position="absolute"
                                top={2}
                                right={2}
                                bg="blackAlpha.600"
                                color="white"
                                _hover={{ bg: "blackAlpha.800" }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleRemovePhoto(slot);
                                }}
                                opacity={0}
                                _groupHover={{ opacity: 1 }}
                                transition="all 0.2s"
                                aria-label="Remove photo"
                              />
                            </>
                          ) : (
                            <Flex
                              position="absolute"
                              top="0"
                              left="0"
                              w="full"
                              h="full"
                              alignItems="center"
                              justifyContent="center"
                              onClick={() => handleSlotClick(slot)}
                            >
                              <Icon
                                as={AddIcon}
                                w={6}
                                h={6}
                                color="gray.400"
                              />
                            </Flex>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  );
                })}
              </SimpleGrid>

              {/* Hidden file input */}
              <Input
                type="file"
                hidden
                ref={fileRef}
                accept="image/*"
                onChange={handleFileChange}
              />

              <VStack spacing={4} w="full" maxW="400px">
                <Button
                  w="full"
                  size="lg"
                  bg="black"
                  color="white"
                  h="60px"
                  fontSize="lg"
                  onClick={handleContinue}
                  isLoading={isUpdating}
                  _hover={{
                    transform: "translateY(-2px)",
                    shadow: "lg",
                    opacity: 0.95,
                  }}
                  _active={{ transform: "translateY(0)" }}
                  rounded="full"
                  rightIcon={<FaArrowRight />}
                  transition="all 0.2s"
                >
                  Continue
                </Button>

                <Button
                  variant="ghost"
                  color="gray.600"
                  fontSize="sm"
                  fontWeight="medium"
                  onClick={() => navigate("/choose_dob/")}
                  _hover={{ 
                    color: "black",
                    bg: "gray.50"
                  }}
                  rounded="full"
                  h="60px"
                >
                  Skip for now
                </Button>

                <HStack 
                  spacing={2} 
                  color="gray.500"
                  fontSize="sm"
                  pt={2}
                >
                  <Box as="span" fontSize="lg">🔒</Box>
                  {/* <Text>Photos only visible to our algorithm · Can be updated anytime · Optional sharing with meetup attendees</Text> */}
                  <Text>Your information is always private</Text>
                </HStack>
              </VStack>
            </VStack>
          </Container>
        </Box>
      </Box>
    </Box>
  );
};

export default ChooseFacePicPage;
