import {
  Container,
  Flex,
  Button,
  VStack,
  FormControl,
  Input,
  Text,
  IconButton,
  Box,
  Progress,
  Icon,
  HStack,
} from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { FaLinkedin, FaArrowRight } from "react-icons/fa";
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

const ChooseLinkedinPage = () => {
  const [linkedin, setLinkedin] = useState("");
  const [isBusiness, setIsBusiness] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const inputRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    const isCreator = localStorage.getItem("isCreator") === "true";
    setIsBusiness(isCreator);
    const timer = setTimeout(() => setIsLoading(false), 0);
    return () => clearTimeout(timer);
  }, []);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      inputRef.current.blur();
    }
  };

  if (isLoading) {
    return <Box minH="100vh" bg="white" />;
  }

  return (
    <Box 
      minH="100vh"
      bg="white"
    >
      {/* Top Bar - Fixed */}
      <Box
        position="fixed"
        top={0}
        left={0}
        right={0}
        zIndex={1000}
        bg="white"
        h={{ base: "56px", md: "64px" }}
        backdropFilter="blur(12px)"
        backgroundColor="rgba(255, 255, 255, 0.9)"
        borderBottom="1px solid"
        borderColor="gray.100"
      >
        <Container maxW="container.xl" h="full">
          <Flex 
            justify="space-between"
            align="center"
            h="full"
            px={{ base: 3, md: 4 }}
          >
            {/* Back Button - Only show on mobile */}
            <IconButton
              icon={<ArrowBackIcon />}
              variant="ghost"
              aria-label="Go back"
              onClick={() => navigate(-1)}
              color="gray.500"
              display={{ base: 'flex', md: 'none' }}
              _hover={{ color: "#4F46E5" }}
            />

            {/* Logo */}
            <Box 
              as="h1" 
              fontSize={{ base: "26px", md: "32px" }}
              fontWeight="900"
              letterSpacing="-0.03em"
              position="relative"
              display="inline-block"
              transform="translateY(-1px)"
              cursor="pointer"
              onClick={() => navigate("/")}
              transition="all 0.3s ease"
              _hover={{
                transform: "translateY(-2px) scale(1.02)",
              }}
            >
              <Box
                as="span"
                position="relative"
                bgGradient="linear(135deg, #4F46E5 0%, #7C3AED 100%)"
                bgClip="text"
                _before={{
                  content: '""',
                  position: "absolute",
                  left: "-6px",
                  right: "-6px",
                  top: "-6px",
                  bottom: "-6px",
                  background: "linear-gradient(135deg, #4F46E5 0%, #7C3AED 100%)",
                  opacity: 0.15,
                  borderRadius: "xl",
                  transform: "rotate(-2deg)",
                  transition: "all 0.3s ease",
                  zIndex: -1,
                }}
                _after={{
                  content: '""',
                  position: "absolute",
                  left: "-3px",
                  right: "-3px",
                  top: "-3px",
                  bottom: "-3px",
                  background: "white",
                  opacity: 0.9,
                  borderRadius: "lg",
                  transform: "rotate(-1deg)",
                  transition: "all 0.3s ease",
                  zIndex: -1,
                }}
              >
                hubzo
              </Box>
            </Box>

            {/* Empty div to maintain spacing */}
            <Box w="40px" display={{ base: 'block', md: 'none' }} />
          </Flex>
        </Container>
      </Box>

      {/* Main Content - Scrollable */}
      <Box 
        pt={{ base: "56px", md: "64px" }}
        minH="100vh"
      >
        <Container maxW="container.sm" py={{ base: 8, md: 16 }} px={6}>
          <VStack spacing={8} align="center" w="full">
            {/* Progress Bar */}
            <Progress
              value={100}
              size="sm"
              colorScheme="purple"
              w="full"
              maxW="400px"
              borderRadius="full"
              bg="gray.100"
            />

            <VStack spacing={3} textAlign="center">
              <Box
                p={3}
                bg="purple.50"
                rounded="full"
                color="purple.600"
                fontSize="2xl"
                mb={2}
              >
                <Icon as={FaLinkedin} />
              </Box>
              
              <Text 
                fontSize={{ base: "2xl", md: "3xl" }}
                fontWeight="bold"
                bgGradient="linear(to-r, #4F46E5, #7C3AED)"
                bgClip="text"
              >
                What is your LinkedIn?
              </Text>
              <Text 
                color="gray.600" 
                fontSize={{ base: "md", md: "lg" }}
              >
                {isBusiness
                  ? "After signing up, you can use your unique code to verify your LinkedIn account."
                  : "After signing up, you can use your unique code to verify your LinkedIn account."}
              </Text>
            </VStack>

            <VStack spacing={6} w="full" maxW="400px">
              <FormControl>
                <Input
                  ref={inputRef}
                  placeholder="Your LinkedIn profile"
                  size="lg"
                  type="text"
                  value={linkedin}
                  onChange={(e) => setLinkedin(e.target.value)}
                  onKeyDown={handleKeyDown}
                  bg="gray.50"
                  color="gray.800"
                  border="1px solid"
                  borderColor="gray.200"
                  _placeholder={{ color: "gray.400" }}
                  _focus={{ 
                    borderColor: "#4F46E5",
                    boxShadow: "0 0 0 1px #4F46E5",
                    bg: "white",
                    transform: "scale(1.02)",
                  }}
                  _hover={{
                    borderColor: "gray.300",
                  }}
                  rounded="xl"
                  h="60px"
                  fontSize="lg"
                  textAlign="center"
                  transition="all 0.2s"
                />
              </FormControl>

              <Button
                w="full"
                size="lg"
                bg="linear-gradient(135deg, #4F46E5 0%, #7C3AED 100%)"
                color="white"
                h="60px"
                fontSize="lg"
                isDisabled={!linkedin}
                onClick={() => {
                  localStorage.setItem("linkedin", linkedin);
                  navigate("/onboarding_sign_up");
                }}
                _hover={{
                  transform: "translateY(-2px)",
                  shadow: "lg",
                  opacity: 0.95,
                }}
                _active={{ transform: "translateY(0)" }}
                rounded="xl"
                rightIcon={<FaArrowRight />}
                transition="all 0.2s"
              >
                Continue
              </Button>

              <Button
                variant="ghost"
                color="gray.500"
                fontSize="sm"
                fontWeight="medium"
                onClick={() => navigate("/onboarding_sign_up")}
                _hover={{ color: "#4F46E5" }}
              >
                Skip for now
              </Button>

              <HStack 
                spacing={2} 
                color="gray.500"
                fontSize="sm"
                pt={2}
              >
                <Box as="span" fontSize="lg">🔒</Box>
                <Text>Your information is always private</Text>
              </HStack>
            </VStack>
          </VStack>
        </Container>
      </Box>
    </Box>
  );
};

export default ChooseLinkedinPage;
