import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Box,
  Container,
  Flex,
  Skeleton,
  SkeletonCircle,
  Text,
  Button,
  VStack,
  Image,
  Grid,
  HStack,
  useDisclosure,
  Tag,
  TagLabel,
} from "@chakra-ui/react";
import GroupFeedPost from "./GroupFeedPost";
import useGetGroupPageFeedPosts from "../../hooks/useGetGroupPageFeedPosts";
import { useRef, useState } from "react";
import { Link } from "react-router-dom";
import useGetMemoriesByGroupId from "../../hooks/useGetMemoriesByGroupId";
import useAuthStore from "../../store/authStore";
import { useNavigate } from "react-router-dom";
import MemoryCard from "../Memories/MemoryCard";

import EventItemCard from "../../components/FeedPosts/EventItemCard";

const GroupPageFeedPosts = ({ uid }) => {
  const {
    isOpen: isOpenModal,
    onOpen: onOpenModal,
    onClose: onCloseModal,
  } = useDisclosure();
  const { isLoading, posts } = useGetGroupPageFeedPosts(uid);
  const authUser = useAuthStore((state) => state.user);

  const cancelRef = useRef();
  const navigate = useNavigate();

  const [indexz, setIndexz] = useState(0);

  const typesArray = ["Upcoming", "Past events", "Memories"];
  const { memories } = useGetMemoriesByGroupId(uid);

  const events = [
    {
      title: "Tech Connect 2024",
      date: "Wednesday, June 7th at 7:30 PM",
      location: "Lisbon, Portugal",
      expectedPeople: "15-25 people",
      description:
        "A half-day event featuring expert-led panels, workshops, and a networking mixer.",
    },
    {
      title: "AI Revolution 2024",
      date: "Thursday, July 15th at 9:00 AM",
      location: "San Francisco, USA",
      expectedPeople: "100-150 people",
      description:
        "A full-day event covering the latest advancements in AI technology and practical workshops.",
    },
  ];

  return (
    <Container maxW={"container.sm"} pb={0} px={0} mb={0}>
      {isLoading &&
        [0, 1, 2].map((_, idx) => (
          <VStack key={idx} gap={4} alignItems={"flex-start"} mb={10}>
            <Flex gap="2">
              <SkeletonCircle size="10" />
              <VStack gap={2} alignItems={"flex-start"}>
                <Skeleton height="10px" w={"200px"} />
                <Skeleton height="10px" w={"200px"} />
              </VStack>
            </Flex>
            <Skeleton w={"full"}>
              <Box h={"400px"}>contents wrapped</Box>
            </Skeleton>
          </VStack>
        ))}

      {!isLoading && (
        // && posts.length > 0

        <VStack
          spacing={0}
          // bg={"red"}
          mb={0}
          align="flex-start"
        >
          <HStack
            // bg={"red"}
            // w={"full"}
            userSelect={"none"}
            mt={1}
            mb={4}
            ml={2}
            spacing={2}
            overflowX="auto"
            sx={{
              "::-webkit-scrollbar": {
                display: "none",
              },
            }}
          >
            {typesArray.map((data, index) => (
              <Tag
                onClick={() => {
                  // if (index == 0) {
                  //   // setComment(typesValueArray[index]);
                  //   setIndexz(index);
                  // } else {
                  //   // setComment("unverified");
                  //   setIndexz(index);
                  // }
                  setIndexz(index);

                  // console.log(index);
                }}
                flexShrink="0"
                size="lg"
                key={index}
                borderRadius="full"
                variant="subtle"
                colorScheme={indexz == index ? "green" : "gray"}
              >
                <TagLabel size="sm">{data}</TagLabel>
              </Tag>
            ))}
          </HStack>

          {events.map((event, index) => (
            <EventItemCard key={index} event={event} />
          ))}
        </VStack>

        // posts.map((post) => (
        // 	<GroupFeedPost key={post.id} post={post} uid={uid} />
        //   ))
      )}
      {!isLoading && posts.length === 0 && (
        <>
          <Text fontSize={"md"} color={"red.400"}>
            {/* Looks like you don&apos;t follow anyone yet. */}
          </Text>
        </>
      )}

      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onCloseModal}
        isOpen={isOpenModal}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>Are you a member? 🤔</AlertDialogHeader>
          {/* <AlertDialogCloseButton /> */}
          <AlertDialogBody>Only members can see this.</AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onCloseModal}>
              Cancel
            </Button>

            <Link to="/auth">
              <Button
                variant={"outline"}
                onClick={onCloseModal}
                colorScheme="whatsapp"
                ml={3}
              >
                Login
              </Button>
            </Link>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </Container>
  );
};

export default GroupPageFeedPosts;
