import {
  Box,
  Container,
  Flex,
  useColorMode,
  useColorModeValue,
  Divider,
  AlertDescription,
  Alert,
  CardBody,
  Card,
  Text,
  Stack,
  AlertIcon,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  RadioGroup,
  Radio,
  Button,
  Spacer,
  useDisclosure,
  CloseButton,
  Heading,
  VStack,
} from "@chakra-ui/react";
import useAuthStore from "../../store/authStore";
import useGetGroupProfileByUsername from "../../hooks/useGetGroupProfileByUsername";
import { useParams, useNavigate } from "react-router-dom";
import Member from "../../components/Comment/Member";
import {
  MoonIcon,
  SunIcon,
  ArrowBackIcon,
  ArrowUpDownIcon,
} from "@chakra-ui/icons";
import { useRef, useState } from "react";

import { MdSort } from "react-icons/md";

const MembersPage = () => {
  const { group } = useParams();

  const { isLoading, userProfile, userId } =
    useGetGroupProfileByUsername(group);

  const authUser = useAuthStore((state) => state.user);

  const {
    isOpen: isVisible,
    onClose,
    onOpen,
  } = useDisclosure({ defaultIsOpen: true });
  const bgTheme = useColorModeValue(`white`, `black`);

  const {
    isOpen: isOpenManageLinkModal,
    onOpen: onOpenManageLinkModal,
    onClose: onCloseManageLinkModal,
  } = useDisclosure();

  const navigate = useNavigate();

  const [value, setValue] = useState("date");

  return (
    <Container px={0} maxW={"container.sm"}>
      <Flex
        px={0}
        mt={0}
        mb={28}
        gap={3}
        flexDir={"column"}
        // maxH={"500px"}
        // overflowY={"auto"}
      >
        <Box bg={useColorModeValue(`white`, `gray.800`)} px={4}>
          <Flex h={16} alignItems={"center"} justifyContent={"flex-start"}>
            <Flex alignItems={"flex-start"}>
              <Stack direction={"row"} spacing={7}>
                {/* <Button
                    onClick={() => {
                      navigate(-1);
                    }}
                  > */}
                <ArrowBackIcon
                  boxSize={6}
                  onClick={() => {
                    navigate(-1);
                  }}
                />
                {/* {colorMode === "light" ? <MoonIcon /> : <SunIcon />} */}
                {/* </Button> */}
              </Stack>
            </Flex>

            <Box ml={6}>
              {userProfile && userProfile.memberList && (
                <VStack spacing={0}>
                  <Text
                    alignSelf={"flex-start"}
                    pl={0}
                    fontSize={20}
                    fontWeight={"semibold"}
                    // color={"green.300"}
                  >
                    {`Followers (${userProfile.memberList.length})`}
                  </Text>

                  <Text
                    alignSelf={"flex-start"}
                    pl={0}
                    fontSize={12}
                    // fontWeight={"bold"}
                    // color={"green.300"}
                  >
                    {`@${userProfile.username}`}
                  </Text>
                </VStack>
              )}
            </Box>

            <Spacer />

            <Flex
              flexDirection={"row"}
              onClick={() => {
                onOpenManageLinkModal();
              }}
            >
              <MdSort
                size={22}
                onClick={() => {
                  if (!authUser) {
                    showToast("Error", "You must be logged in", "error");

                    return;
                  } else {
                    onOpen();
                  }
                }}
                boxSize={6}
              />
            </Flex>
          </Flex>
        </Box>

        {/* {userProfile && userProfile.memberList && (
          <Heading
            as="h3"
            size="lg"
          >{`Members (${userProfile.users})`}</Heading>
        )} */}

        <Flex mx={0} direction={"column"}>
          <Card mt={0} pb={0} w={"full"} mb={0}>
            <CardBody pl={2} pt={0} pb={0}>
              <Flex
                pl={2}
                pr={1}
                mt={6}
                // mb={8}
                gap={4}
                flexDir={"column"}
                // maxH={"500px"}
                // overflowY={"auto"}
              >
                {userProfile &&
                  userProfile.memberList &&
                  userProfile.memberList

                    .sort(function (a, b) {
                      if (value == "date") {
                        // if (a.fullName.toLowerCase() > b.fullName.toLowerCase())
                        // return -1;
                        // if (a.fullName.toLowerCase() < b.fullName.toLowerCase())
                        return 1;
                        // return 0;
                      } else if (value == "name") {
                        if (a.fullName.toLowerCase() > b.fullName.toLowerCase())
                          return -1;
                        if (a.fullName.toLowerCase() < b.fullName.toLowerCase())
                          return 1;
                        return 0;
                      } else if (value == "phone") {
                        if (
                          a.phoneNumber.substr(-4).toLowerCase() >
                          b.phoneNumber.substr(-4).toLowerCase()
                        )
                          return -1;
                        if (
                          a.phoneNumber.substr(-4).toLowerCase() <
                          b.phoneNumber.substr(-4).toLowerCase()
                        )
                          return 1;
                        return 0;
                      }
                    })

                    .toReversed()
                    .map((comment, idx) => (
                      <Member
                        key={idx}
                        group={userProfile}
                        groupId={userId}
                        comment={comment.uid}
                        isAdmin={userProfile.admins.includes(comment.uid)}
                        seenByAdmin={
                          
                          userProfile.admins.includes(
                          authUser?.uid ? authUser?.uid : ""
                        )
                      
                      
                      }
                      />
                    ))}
              </Flex>
            </CardBody>
          </Card>
        </Flex>

        <Modal
          isOpen={isOpenManageLinkModal}
          onClose={onCloseManageLinkModal}
          isCentered={true}
          size={{ base: "lg", md: "xl" }}
        >
          <ModalOverlay />
          <ModalContent bg={bgTheme} border={"1px solid gray"} maxW={"400px"}>
            <ModalHeader mt={4}>Sort By:</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={5}>
              <Flex px={1} w={"full"} mx={"auto"} flexDirection={"column"}>
                <Flex gap="4">
                  <RadioGroup onChange={setValue} value={value}>
                    <Stack spacing={5} direction="column">
                      <Radio size="lg" value="date">
                        Joined recently
                      </Radio>
                      <Radio size="lg" value="name">
                        Name
                      </Radio>
                      {/* <Radio size="lg" value="phone">
                        Phone number ending
                      </Radio> */}
                    </Stack>
                  </RadioGroup>
                </Flex>
              </Flex>
            </ModalBody>

            <ModalFooter>
              {/* <Button
                mb={2}
                mr={4}
                onClick={onCloseManageLinkModal}
                // isLoading={isLoading}
              >
                Cancel
              </Button> */}

              <Button
                mb={2}
                mr={0}
                colorScheme="whatsapp"
                variant={"outline"}
                onClick={() => {
                  onCloseManageLinkModal();
                }}
                isLoading={isLoading}
              >
                Update
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Flex>
    </Container>
  );
};

export default MembersPage;
