import {
  Container,
  Flex,
  InputGroup,
  Button,
  VStack,
  InputRightElement,
  Box,
  FormControl,
  Input,
  Text,
  IconButton,
  useToast,
  Icon,
  HStack,
} from "@chakra-ui/react";
import { ArrowBackIcon, ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { FaLock, FaArrowRight } from "react-icons/fa";
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import useSignUpWithEmailAndPassword from "../../hooks/useSignUpWithEmailAndPassword";

const ChooseEmailPasswordPage = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const { signup } = useSignUpWithEmailAndPassword();
  const [isLoading, setIsLoading] = useState(true);

  const [showPassword, setShowPassword] = useState(false);
  const [inputs, setInputs] = useState({
    email: localStorage.getItem("email"),
    password: "",
    confirmPassword: "",
  });
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);

  const passwordInputRef = useRef(null);
  const confirmPasswordInputRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    setIsButtonEnabled(
      inputs.password &&
        inputs.confirmPassword &&
        inputs.password === inputs.confirmPassword
    );
    const timer = setTimeout(() => setIsLoading(false), 0);
    return () => clearTimeout(timer);
  }, [inputs.password, inputs.confirmPassword]);

  const handleSignUp = () => {
    if (!inputs.password || !inputs.confirmPassword) {
      toast({
        title: "Error",
        description: "Please fill out both password fields.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    } else if (inputs.password !== inputs.confirmPassword) {
      toast({
        title: "Error",
        description: "Passwords do not match.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    } else {
      signup(inputs);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      if (e.target.name === "password") {
        confirmPasswordInputRef.current.focus();
      } else if (e.target.name === "confirmPassword") {
        confirmPasswordInputRef.current.blur();
        handleSignUp();
      }
    }
  };

  if (isLoading) {
    return <Box minH="100vh" bg="white" />;
  }

  return (
    <Box 
      minH="100vh"
      bg="white"
    >
      {/* Top Bar */}
      <Box
        position="fixed"
        top={0}
        left={0}
        right={0}
        zIndex={1000}
        bg="white"
        h={{ base: "56px", md: "64px" }}
        backdropFilter="blur(12px)"
        backgroundColor="rgba(255, 255, 255, 0.9)"
        borderBottom="1px solid"
        borderColor="gray.100"
      >
        <Container maxW="container.xl" h="full">
          <Flex 
            justify="space-between"
            align="center"
            h="full"
            px={{ base: 3, md: 4 }}
          >
            {/* Back Button - Only show on mobile */}
            <IconButton
              icon={<ArrowBackIcon />}
              variant="ghost"
              aria-label="Go back"
              onClick={() => navigate(-1)}
              color="gray.500"
              display={{ base: 'flex', md: 'none' }}
              _hover={{ color: "#B8A080" }}
            />

            {/* Logo */}
            <Box 
              as="h1" 
              fontSize={{ base: "26px", md: "32px" }}
              fontWeight="400"
              letterSpacing="0.35em"
              position="relative"
              display="inline-block"
              cursor="pointer"
              onClick={() => navigate("/")}
              transition="all 0.4s cubic-bezier(0.4, 0, 0.2, 1)"
              textTransform="uppercase"
              fontFamily="Montserrat, sans-serif"
              color="#1A1A1A"
              _hover={{
                letterSpacing: "0.4em",
                transform: "translateY(-1px)",
              }}
              sx={{
                WebkitFontSmoothing: "antialiased",
                textRendering: "optimizeLegibility",
              }}
            >
              <Box
                as="span"
                position="relative"
                _before={{
                  content: '""',
                  position: "absolute",
                  left: "-8%",
                  right: "-8%",
                  top: "-8px",
                  bottom: "-8px",
                  background: "linear-gradient(135deg, rgba(26, 26, 26, 0.08), rgba(26, 26, 26, 0.03))",
                  backdropFilter: "blur(4px)",
                  opacity: 0.9,
                  borderRadius: "2px",
                  transform: "skew(-4deg)",
                  transition: "all 0.3s ease",
                  zIndex: -1,
                }}
              >
                hubzo
              </Box>
            </Box>

            {/* Empty div to maintain spacing */}
            <Box w="40px" display={{ base: 'block', md: 'none' }} />
          </Flex>
        </Container>
      </Box>

      {/* Main Content */}
      <Box 
        pt={{ base: "56px", md: "64px" }}
        minH="100vh"
      >
        <Container maxW="container.sm" py={{ base: 8, md: 16 }} px={6}>
          <VStack spacing={8} align="center" w="full">
            <VStack spacing={3} textAlign="center">
              <Box
                p={3}
                bg="rgba(184, 160, 128, 0.1)"
                rounded="full"
                color="#B8A080"
                fontSize="2xl"
                mb={2}
                border="1px solid"
                borderColor="rgba(184, 160, 128, 0.2)"
              >
                <Icon as={FaLock} />
              </Box>
              
              <Text 
                fontSize={{ base: "2xl", md: "3xl" }}
                fontWeight="light"
                color="gray.900"
                letterSpacing="0.02em"
                fontFamily="Montserrat, sans-serif"
              >
                Choose a password
              </Text>
              {/* <Text 
                color="gray.600" 
                fontSize={{ base: "md", md: "lg" }}
                letterSpacing="wide"
              >
                Make sure it's secure
              </Text> */}
            </VStack>

            <VStack spacing={6} w="full" maxW="400px">
              <FormControl>
                <InputGroup>
                  <Input
                    ref={passwordInputRef}
                    name="password"
                    placeholder="Your Password"
                    type={showPassword ? "text" : "password"}
                    value={inputs.password}
                    onChange={(e) =>
                      setInputs({ ...inputs, password: e.target.value })
                    }
                    onKeyDown={handleKeyDown}
                    bg="gray.50"
                    color="gray.800"
                    border="1px solid"
                    borderColor="gray.200"
                    _placeholder={{ color: "gray.400" }}
                    _focus={{ 
                      borderColor: "#B8A080",
                      boxShadow: "0 0 0 1px #B8A080",
                      bg: "white",
                      transform: "scale(1.02)",
                    }}
                    _hover={{
                      borderColor: "gray.300",
                    }}
                    rounded="xl"
                    h="60px"
                    fontSize="lg"
                    textAlign="center"
                    transition="all 0.2s"
                    letterSpacing="wide"
                  />
                  <InputRightElement h="full">
                    <IconButton
                      variant="ghost"
                      onClick={() => setShowPassword(!showPassword)}
                      icon={showPassword ? <ViewOffIcon /> : <ViewIcon />}
                      color="gray.400"
                      _hover={{ color: "#B8A080" }}
                    />
                  </InputRightElement>
                </InputGroup>
              </FormControl>

              <FormControl>
                <InputGroup>
                  <Input
                    ref={confirmPasswordInputRef}
                    name="confirmPassword"
                    placeholder="Confirm Password"
                    type={showPassword ? "text" : "password"}
                    value={inputs.confirmPassword}
                    onChange={(e) =>
                      setInputs({ ...inputs, confirmPassword: e.target.value })
                    }
                    onKeyDown={handleKeyDown}
                    bg="gray.50"
                    color="gray.800"
                    border="1px solid"
                    borderColor="gray.200"
                    _placeholder={{ color: "gray.400" }}
                    _focus={{ 
                      borderColor: "#B8A080",
                      boxShadow: "0 0 0 1px #B8A080",
                      bg: "white",
                      transform: "scale(1.02)",
                    }}
                    _hover={{
                      borderColor: "gray.300",
                    }}
                    rounded="xl"
                    h="60px"
                    fontSize="lg"
                    textAlign="center"
                    transition="all 0.2s"
                    letterSpacing="wide"
                  />
                  <InputRightElement h="full">
                    <IconButton
                      variant="ghost"
                      onClick={() => setShowPassword(!showPassword)}
                      icon={showPassword ? <ViewOffIcon /> : <ViewIcon />}
                      color="gray.400"
                      _hover={{ color: "#B8A080" }}
                    />
                  </InputRightElement>
                </InputGroup>
              </FormControl>

              <Button
                w="full"
                size="lg"
                bg="black"
                color="white"
                h="60px"
                fontSize="lg"
                fontWeight="bold"
                isDisabled={!isButtonEnabled}
                onClick={handleSignUp}
                _hover={{
                  transform: "translateY(-2px)",
                  shadow: "lg",
                }}
                _active={{ transform: "translateY(0)" }}
                rounded="xl"
                rightIcon={<FaArrowRight />}
                transition="all 0.2s"
                letterSpacing="0.1em"
                textTransform="uppercase"
              >
                Sign Up
              </Button>

              <HStack 
                spacing={2} 
                color="gray.500"
                fontSize="sm"
                pt={2}
              >
                <Box as="span" fontSize="lg">🔒</Box>
                <Text letterSpacing="wide">Your information is always private</Text>
              </HStack>
            </VStack>
          </VStack>
        </Container>
      </Box>
    </Box>
  );
};

export default ChooseEmailPasswordPage;
