import {
  Container,
  Alert,
  AvatarBadge,
  AlertIcon,
  AlertDescription,
  AlertDialog,
  AlertTitle,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogCloseButton,
  AlertDialogBody,
  AlertDialogFooter,
  Flex,
  Link,
  Box,
  Stack,
  Menu,
  MenuButton,
  Avatar,
  MenuList,
  MenuItem,
  MenuDivider,
  useColorMode,
  useColorModeValue,
  Button,
  Spacer,
  Skeleton,
  RadioGroup,
  Radio,
  ModalFooter,
  SkeletonCircle,
  useDisclosure,
  Text,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  ModalHeader,
  VStack,
} from "@chakra-ui/react";
import PageFeedPosts from "../../components/FeedPosts/PageFeedPosts";
import ProfileHeader from "../../components/Profile/ProfileHeader";
import ProfileTabs from "../../components/Profile/ProfileTabs";
// import ProfilePosts from "../../components/Profile/ProfilePosts";
import useGetUserProfileByUsername from "../../hooks/useGetUserProfileByUsername";
import { useParams } from "react-router-dom";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import useAuthStore from "../../store/authStore";
import EditProfile from "../../components/Profile/EditProfile";
import useLogout from "../../hooks/useLogout";
import { doc, updateDoc } from "firebase/firestore";
import useShowToast from "../../hooks/useShowToast";
import { auth, firestore } from "../../firebase/firebase";

import {
  WarningTwoIcon,
  ArrowBackIcon,
  MoonIcon,
  WarningIcon,
  NotAllowedIcon,
  EditIcon,
  UnlockIcon,
  DeleteIcon,
  SettingsIcon,
} from "@chakra-ui/icons";
import useReportUser from "../../hooks/useReportUser";
import { useRef, useState, useEffect } from "react";
import useBlockUser from "../../hooks/useBlockUser";

import { BsThreeDotsVertical } from "react-icons/bs";

const ProfilePage = ({ update }) => {
  const { username } = useParams();
  const { isLoading, userProfile } = useGetUserProfileByUsername(username);
  const authUser = useAuthStore((state) => state.user);

  const userNotFound = !isLoading && !userProfile;
  if (userNotFound) return <UserNotFound />;

  const userDeleted = !isLoading && userProfile.status === "deleted";
  if (userDeleted) return <UserDeleted />;

  const visitingOwnProfileAndAuth = authUser && authUser.username === username;
  const navigate = useNavigate();
  const [value, setValue] = useState("behaviour");

  const bgTheme = useColorModeValue(`white`, `black`);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { handleLogout, isLoggingOut } = useLogout();

  const cancelRef = useRef();
  const showToast = useShowToast();

  const {
    isOpen: isOpenBlockModal,
    onOpen: onOpenBlockModal,
    onClose: onCloseBlockModal,
  } = useDisclosure();

  const {
    isOpen: isOpenManageLinkModal,
    onOpen: onOpenManageLinkModal,
    onClose: onCloseManageLinkModal,
  } = useDisclosure();

  const { isReportUpdating, handleReportUser } = useReportUser(
    userProfile ? userProfile.uid : "",
    authUser?.uid,
    value,
    "user"
  );

  const { isBlockUpdating, handleBlockUser } = useBlockUser(
    userProfile ? userProfile.uid : "",
    authUser?.uid
  );

  const {
    isOpen: isOpenDeleteAccountModal,
    onOpen: onOpenDeleteAccountModal,
    onClose: onCloseDeleteAccountModal,
  } = useDisclosure();

  const handleDeleteAccount = async () => {
    try {
      const userRef = doc(firestore, "users", authUser?.uid);

      await updateDoc(userRef, {
        status: "deleted",
      });

      showToast("Success", "Delete account successfully", "success");

      handleLogout();
      navigate("/");
    } catch (error) {
      showToast("Error", error.message, "error");
    }
  };

  return (
    <Container
      // bg={"blue"}
      px={0}
      maxW="container.lg"
      py={0}
    >
      <Flex
        // background={"red"}
        py={0}
        // px={4}
        // pl={{ base: 4, md: 10 }}
        w={"full"}
        mx={"auto"}
        flexDirection={"column"}
      >
        {!isLoading && userProfile && (
          <Flex
            // background={"red"}
            // px={{ base: 2, sm: 4 }}
            // maxW={"full"}
            // mx={"auto"}

            // borderTop={"1px solid"}
            // borderColor={"whiteAlpha.300"}
            direction={"column"}
          >
            <Box bg={useColorModeValue(`white`, `gray.800`)} px={2}>
              <Flex h={16} alignItems={"center"}>
                <Flex alignItems={"flex-start"}>
                  <Stack direction={"row"} spacing={7}>
                    {/* <Button
                  onClick={() => {
                    navigate(-1);
                  }}
                > */}

                    {!visitingOwnProfileAndAuth && (
                      <ArrowBackIcon
                        ml={2}
                        boxSize={6}
                        onClick={() => {
                          navigate(-1);
                        }}
                      />
                    )}

                    {/* {colorMode === "light" ? <MoonIcon /> : <SunIcon />} */}
                    {/* </Button> */}
                  </Stack>
                </Flex>

                <Box ml={6}>
                  {visitingOwnProfileAndAuth && (
                    <VStack spacing={0}>
                      <Text
                        pl={0}
                        fontWeight={"semibold"}
                        fontSize={24}
                        // fontWeight={"bold"}
                        // color={"green.300"}
                      >
                        Account
                      </Text>

                      <Text
                      alignSelf={"flex-start"}
                        // fontWeight={"semibold"}
                        pl={0}
                        fontSize={14}
                        // fontWeight={"bold"}
                        // color={"green.300"}
                      >
                        @{userProfile.username}
                      </Text>
                    </VStack>
                  )}

                  {!visitingOwnProfileAndAuth && (
                    <Text
                      // fontWeight={"semibold"}
                      pl={0}
                      fontSize={24}
                      // fontWeight={"bold"}
                      // color={"green.300"}
                    >
                      @{userProfile.username}
                    </Text>
                  )}
                </Box>

                <Spacer />
                {/* 
                <ArrowBackIcon
                        boxSize={6}
                        onClick={() => {
                          navigate(-1);
                        }}
                        /> */}

                {/* {visitingOwnProfileAndAuth && (
                  <Flex alignItems={"center"}>
                    <Stack direction={"row"} spacing={7}>
                      <Button
                      // onClick={toggleColorMode}
                      >
                        <EditIcon />
                      </Button>


                    </Stack>
                  </Flex>
                )} */}

                {!visitingOwnProfileAndAuth && (
                  <Flex pl={4} alignItems={"center"}>
                    <Menu>
                      <MenuButton
                        p={2}
                        as={Button}
                        rounded={"full"}
                        variant={"link"}
                        cursor={"pointer"}
                        minW={0}
                      >
                        <BsThreeDotsVertical boxSize={8} />

                        {/* <Avatar
size={'sm'}
src={
  'https://images.unsplash.com/photo-1493666438817-866a91353ca9?ixlib=rb-0.3.5&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&s=b616b2c5b373a80ffc9636ba24f7a4a9'
}
/> */}
                      </MenuButton>
                      <MenuList>
                        <MenuItem
                          onClick={() => {
                            if (!authUser) {
                              showToast(
                                "Error",
                                "You must be logged in",
                                "error"
                              );

                              return;
                            } else {
                              onOpenManageLinkModal();
                            }
                          }}
                        >
                          <Flex
                            gap={4}
                            alignItems={"center"}
                            flexDirection={"row"}
                          >
                            <WarningIcon ml={2} />
                            <Text>Report</Text>
                          </Flex>
                        </MenuItem>
                        <MenuDivider />
                        <MenuItem
                          onClick={() => {
                            if (!authUser) {
                              showToast(
                                "Error",
                                "You must be logged in",
                                "error"
                              );
                              return;
                            } else {
                              onOpenBlockModal();
                            }
                          }}
                        >
                          <Flex
                            gap={4}
                            alignItems={"center"}
                            flexDirection={"row"}
                            colorScheme={"red"}
                          >
                            <NotAllowedIcon ml={2} />
                            <Text>Block</Text>
                          </Flex>
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </Flex>
                )}

                {visitingOwnProfileAndAuth && (
                  <Flex flexDirection={"row"}>
                    <EditIcon
                      onClick={() => {
                        if (!authUser) {
                          showToast("Error", "You must be logged in", "error");

                          return;
                        } else {
                          onOpen();
                        }
                      }}
                      boxSize={6}
                    />

                    <Flex pl={2} alignItems={"center"}>
                      <Menu>
                        <MenuButton
                          as={Button}
                          p={2}
                          rounded={"full"}
                          variant={"link"}
                          cursor={"pointer"}
                          minW={0}
                        >
                          <BsThreeDotsVertical boxSize={8} />
                        </MenuButton>
                        <MenuList>
                          {/* <MenuItem
                            onClick={() => {
                              if (!authUser) {
                                showToast(
                                  "Error",
                                  "You must be logged in",
                                  "error"
                                );

                                return;
                              } else {
                                onOpen();
                              }
                            }}
                          >
                            <Flex
                              gap={4}
                              alignItems={"center"}
                              flexDirection={"row"}
                            >
                              <EditIcon ml={2} />
                              <Text>Edit Profile</Text>
                            </Flex>
                          </MenuItem> */}
                          {/* <MenuDivider /> */}
                          <MenuItem
                            onClick={() => {
                              if (!authUser) {
                                showToast(
                                  "Error",
                                  "You must be logged in",
                                  "error"
                                );
                                return;
                              } else {
                                handleLogout();
                                navigate("/");
                              }
                            }}
                          >
                            <Flex
                              gap={4}
                              alignItems={"center"}
                              flexDirection={"row"}
                              colorScheme={"red"}
                            >
                              <UnlockIcon ml={2} />
                              <Text>Logout</Text>
                            </Flex>
                          </MenuItem>
                          <MenuDivider />
                          <MenuItem
                            onClick={() => {
                              if (!authUser) {
                                showToast(
                                  "Error",
                                  "You must be logged in",
                                  "error"
                                );
                                return;
                              } else {
                                onOpenDeleteAccountModal();
                              }
                            }}
                          >
                            <Flex
                              gap={4}
                              alignItems={"center"}
                              flexDirection={"row"}
                              colorScheme={"red"}
                            >
                              <DeleteIcon ml={2} />
                              <Text>Delete Account</Text>
                            </Flex>
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    </Flex>
                  </Flex>
                )}
              </Flex>
            </Box>

            <ProfileHeader update={update} />
          </Flex>
        )}

        {isLoading && <ProfileHeaderSkeleton />}

        <AlertDialog
          motionPreset="slideInBottom"
          // leastDestructiveRef={cancelRef}
          onClose={onCloseDeleteAccountModal}
          isOpen={isOpenDeleteAccountModal}
          isCentered
        >
          <AlertDialogOverlay />

          <AlertDialogContent>
            <AlertDialogHeader>
              Are you sure you want to do this?
            </AlertDialogHeader>
            {/* <AlertDialogCloseButton /> */}
            <AlertDialogBody>
              This will delete your account and any references to it
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onCloseDeleteAccountModal}>
                Cancel
              </Button>

              {/* <Link to="/auth"> */}
              <Button
                colorScheme="red"
                variant={"outline"}
                onClick={() => {
                  onCloseDeleteAccountModal();
                  handleDeleteAccount();
                }}
                ml={3}
              >
                Delete Account
              </Button>
              {/* </Link> */}
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>

        <Modal
          isOpen={isOpenManageLinkModal}
          onClose={onCloseManageLinkModal}
          isCentered={true}
          size={{ base: "lg", md: "xl" }}
        >
          <ModalOverlay />
          <ModalContent bg={bgTheme} border={"1px solid gray"} maxW={"400px"}>
            <ModalHeader mt={4}>Report this person</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={5}>
              <Flex px={1} w={"full"} mx={"auto"} flexDirection={"column"}>
                <Flex gap="4">
                  <RadioGroup onChange={setValue} value={value}>
                    <Stack spacing={5} direction="column">
                      <Radio size="md" value="behaviour">
                        Inappropriate behavior
                      </Radio>
                      <Radio size="md" value="scam">
                        Scam or fraud
                      </Radio>
                      <Radio size="md" value="impersonation">
                        Impersonation
                      </Radio>
                      <Radio size="md" value="underage">
                        Underage user
                      </Radio>
                      <Radio size="md" value="offensive">
                        Offensive or inappropriate content
                      </Radio>
                      <Radio size="md" value="suspicious">
                        Suspicious or malicious activity
                      </Radio>
                      <Radio size="md" value="violation">
                        Violation of terms of service
                      </Radio>
                    </Stack>
                  </RadioGroup>
                </Flex>
              </Flex>
            </ModalBody>

            <ModalFooter>
              <Button
                userSelect={"none"}
                mb={2}
                mr={4}
                onClick={onCloseManageLinkModal}
                // isLoading={isLoading}
              >
                Cancel
              </Button>

              <Button
                mb={2}
                mr={0}
                colorScheme="red"
                variant={"outline"}
                onClick={() => {
                  // if (value === "hide") {
                  handleReportUser();
                  onCloseManageLinkModal();
                  // } else {
                  //   onCloseManageLinkModal();
                  // }
                }}
                isLoading={isReportUpdating}
              >
                Confirm
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        <Modal
          isOpen={isOpenBlockModal}
          onClose={onCloseBlockModal}
          isCentered={true}
          size={{ base: "lg", md: "xl" }}
        >
          <ModalOverlay />
          <ModalContent bg={bgTheme} border={"1px solid gray"} maxW={"400px"}>
            <ModalHeader mt={2}></ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={5}>
              <Flex
                py={1}
                px={1}
                w={"full"}
                mx={"auto"}
                flexDirection={"column"}
              >
                <Flex
                  spacing={"8"}
                  alignItems={"center"}
                  direction={"column"}
                  gap="4"
                >
                  <NotAllowedIcon color={"red"} boxSize={10} />

                  <Text fontSize={"xl"}>
                    Do you really want to block this user?
                  </Text>
                </Flex>
              </Flex>
            </ModalBody>

            <ModalFooter>
              <Button
                userSelect={"none"}
                mb={2}
                mr={4}
                onClick={onCloseBlockModal}
                // isLoading={isLoading}
              >
                Cancel
              </Button>

              <Button
                mb={2}
                mr={0}
                colorScheme="red"
                variant={"outline"}
                onClick={() => {
                  // if (value === "hide") {
                  handleBlockUser();
                  onCloseBlockModal();
                  // } else {
                  //   onCloseManageLinkModal();
                  // }
                }}
                isLoading={isBlockUpdating}
              >
                Yes
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        {isOpen && <EditProfile isOpen={isOpen} onClose={onClose} />}
      </Flex>
    </Container>
  );
};

export default ProfilePage;

// skeleton for profile header
const ProfileHeaderSkeleton = () => {
  return (
    <Flex
      gap={{ base: 4, sm: 10 }}
      py={10}
      direction={{ base: "column", sm: "row" }}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Skeleton width={"full"} height={"400px"} />

      <VStack
        alignItems={{ base: "flex-start", sm: "flex-start" }}
        gap={4}
        width={"full"}
        // mx={"auto"}
        flex={1}
      >
        <Skeleton mx={8} height="16px" width="400px" />
        <Skeleton mx={8} height="16px" width="400px" />
      </VStack>
    </Flex>
  );
};

const UserNotFound = () => {
  return (
    <Flex mt={8} flexDir="column" textAlign={"center"} mx={"auto"}>
      <Text userSelect={"none"} fontSize={"2xl"}>
        User Not Found
      </Text>
      <Link
        as={RouterLink}
        to={"/"}
        color={"blue.500"}
        w={"max-content"}
        mx={"auto"}
      >
        Go home
      </Link>
    </Flex>
  );
};

const UserDeleted = () => {
  return (
    <Flex
      mt={32}
      alignItems={"center"}
      flexDir="column"
      textAlign={"center"}
      mx={"auto"}
    >
      <WarningTwoIcon boxSize={6} />

      <Text userSelect={"none"} mt={2} fontSize={"xl"}>
        This Account has been deleted
      </Text>
      <Link
        mt={2}
        as={RouterLink}
        to={"/"}
        color={"blue.500"}
        w={"max-content"}
        mx={"auto"}
      >
        Go home
      </Link>
    </Flex>
  );
};
