import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions";

const firebaseConfig = {
	apiKey: "AIzaSyC5MkUmeSMLa5sxK1nD-2oyVLx7ueLE0B4",
	authDomain: "hubzo-app.firebaseapp.com",
	projectId: "hubzo-app",
	storageBucket: "hubzo-app.appspot.com",
	messagingSenderId: "687141956594",
	appId: "1:687141956594:web:6f783c1ceb9d5f8ac8d96b",
	measurementId: "G-G4DQR6LNTF"
  };

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const firestore = getFirestore(app);
const storage = getStorage(app);
// Initialize Functions
const functions = getFunctions(app);

export { app, auth, firestore, storage, functions };
