import {
  Container,
  Flex,
  Button,
  VStack,
  Box,
  Text,
  IconButton,
  Wrap,
  WrapItem,
  Tag,
  TagLabel,
  TagCloseButton,
} from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useAuthStore from "../../store/authStore";
import useShowToast from "../../hooks/useShowToast";
import usePreviewImg from "../../hooks/usePreviewImg";
import useEditProfile from "../../hooks/useEditProfile";
import DesktopNav from '../../components/Navigation/DesktopNav';

// List of interests with emojis
const interestsList = [
  { emoji: "🍕", label: "Foodie" },
  { emoji: "🏋️‍♂️", label: "Fitness" },
  { emoji: "✈️", label: "Travel" },
  { emoji: "🎨", label: "Art" },
  { emoji: "💻", label: "Tech" },
  { emoji: "🎶", label: "Music" },
  { emoji: "📚", label: "Books" },
  { emoji: "🎥", label: "Movies" },
  { emoji: "🎮", label: "Gaming" },
  // { emoji: "⚽", label: "Football" },
  { emoji: "🚴‍♂️", label: "Cycling" },
  { emoji: "🏕️", label: "Camping" },
  { emoji: "📸", label: "Photography" },
  { emoji: "👩‍🍳", label: "Cooking" },
  // { emoji: "🎧", label: "Podcasts" },
  { emoji: "🎲", label: "Board Games" },
];

const businessList = [
  { emoji: "🍻", label: "Social Mixers" },
  { emoji: "🎤", label: "Open Mic Nights" },
  { emoji: "🎶", label: "Live Music" },
  { emoji: "🎲", label: "Board Game Nights" },
  { emoji: "🎬", label: "Movie Nights" },
  { emoji: "🍲", label: "Food Tastings" },
  { emoji: "🧘‍♀️", label: "Yoga" },
  { emoji: "📸", label: "Photography Walks" },
  { emoji: "🏞️", label: "Outdoor Adventures" },
  { emoji: "🌄", label: "Hiking" },
  { emoji: "💼", label: "Networking" },
  { emoji: "🎯", label: "Trivia Nights" },
  { emoji: "🥂", label: "Wine Tastings" },
  { emoji: "💃", label: "Dance Parties" },
  { emoji: "🖌️", label: "Art & Craft Workshops" },
  { emoji: "☕", label: "Coffee Tastings" },
];

const UpdateInterestsPage = () => {
  const authUser = useAuthStore((state) => state.user);
  const [selectedInterests, setSelectedInterests] = useState(
    authUser?.interests
      ? authUser.interests.split(",").map((interest) => interest.trim())
      : []
  );
  const navigate = useNavigate();
  const { isUpdating, editProfile } = useEditProfile();
  const showToast = useShowToast();
  const { selectedFile } = usePreviewImg();
  const [isBusiness, setIsBusiness] = useState(authUser?.isCreator);

  const colors = {
    textColor: "gray.900",
    bgColor: "white",
    cardBg: "white",
    dividerColor: "gray.200",
    iconBgColor: "rgba(184, 160, 128, 0.1)",
    hoverBgColor: "rgba(184, 160, 128, 0.05)",
    subtitleColor: "gray.600",
    accentColor: "#B8A080",
    borderColor: "rgba(184, 160, 128, 0.2)",
    tagBg: "rgba(184, 160, 128, 0.1)",
    tagSelectedBg: "black",
  };

  const [inputs, setInputs] = useState({
    ...authUser,
    interests: authUser?.interests || "",
  });

  const toggleInterest = (interest) => {
    const updatedInterests = selectedInterests.includes(interest)
      ? selectedInterests.filter((item) => item !== interest)
      : [...selectedInterests, interest];

    setSelectedInterests(updatedInterests);
    setInputs((prev) => ({ ...prev, interests: updatedInterests.join(", ") }));
  };

  const handleEditProfile = async () => {
    if (selectedInterests.length === 0) {
      showToast("Error", "Please select at least one interest", "error");
    } else {
      try {
        await editProfile(inputs, selectedFile);
        showToast("Success", "Profile updated", "success");
        navigate(-1);
      } catch (error) {
        showToast("Error", error.message, "error");
      }
    }
  };

  return (
    <Box
      h="100vh"
      maxH="100vh"
      overflow="hidden"
      position="relative"
      bg={colors.bgColor}
      pb="env(safe-area-inset-bottom)"
    >
      <Box display={{ base: "none", md: "block" }}>
        <DesktopNav />
      </Box>

      <Box
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        overflowY="auto"
        pb={{ base: 32, md: 8 }}
        style={{
          WebkitOverflowScrolling: 'touch'
        }}
      >
        <Container 
          maxW={{ base: "container.md", md: "container.xl" }} 
          px={4} 
          py={6}
          mb={{ base: 8, md: 0 }}
          mt={{ base: 0, md: "60px" }}
        >
          {/* Header */}
          <Flex 
            w="full" 
            alignItems="center" 
            mb={8}
            position="relative"
          >
            <IconButton
              icon={<ArrowBackIcon />}
              variant="ghost"
              aria-label="Go back"
              onClick={() => navigate(-1)}
              position={{ base: "relative", md: "absolute" }}
              left={{ md: 0 }}
              color={colors.accentColor}
              _hover={{
                bg: colors.iconBgColor,
                transform: "translateY(-2px)",
              }}
              transition="all 0.2s"
            />
            <Text 
              fontSize={{ base: "2xl", md: "3xl" }}
              fontWeight="light"
              color={colors.textColor}
              textAlign={{ base: "left", md: "center" }}
              flex="1"
              ml={{ base: 4, md: 0 }}
              letterSpacing="wide"
              fontFamily="Montserrat, sans-serif"
            >
              {isBusiness ? "Select Your Event Types" : "Select Your Interests"}
            </Text>
          </Flex>

          {/* Main Content */}
          <Box 
            maxW={{ base: "full", md: "2xl" }} 
            mx="auto"
          >
            <Box
              bg={colors.cardBg}
              borderRadius="xl"
              borderWidth="1px"
              borderColor={colors.borderColor}
              p={6}
              shadow="sm"
              _hover={{ shadow: "md" }}
              transition="all 0.2s"
            >
              <VStack spacing={6} align="center">
                {/* Interests Grid */}
                <Wrap spacing={3} justify="center">
                  {(isBusiness ? businessList : interestsList).map((interest) => (
                    <WrapItem key={interest.label}>
                      <Tag
                        size="lg"
                        borderRadius="full"
                        variant="solid"
                        bg={selectedInterests.includes(interest.label) ? colors.tagSelectedBg : colors.tagBg}
                        color={selectedInterests.includes(interest.label) ? "white" : colors.textColor}
                        cursor="pointer"
                        onClick={() => toggleInterest(interest.label)}
                        transition="all 0.2s"
                        px={4}
                        py={2}
                        fontFamily="Montserrat, sans-serif"
                        letterSpacing="wide"
                        _hover={{
                          transform: "translateY(-1px)",
                          shadow: "sm",
                          bg: selectedInterests.includes(interest.label)
                            ? "blackAlpha.800"
                            : colors.hoverBgColor,
                        }}
                      >
                        <TagLabel>
                          {interest.emoji} {interest.label}
                        </TagLabel>
                        {selectedInterests.includes(interest.label) && (
                          <TagCloseButton 
                            onClick={(e) => {
                              e.stopPropagation();
                              toggleInterest(interest.label);
                            }}
                          />
                        )}
                      </Tag>
                    </WrapItem>
                  ))}
                </Wrap>

                {/* Update Button */}
                <Button
                  w="full"
                  maxW="400px"
                  bg={selectedInterests.length > 0 ? "black" : "gray.300"}
                  color="white"
                  size="lg"
                  h={14}
                  fontSize="md"
                  fontWeight="medium"
                  isLoading={isUpdating}
                  onClick={handleEditProfile}
                  rounded="full"
                  isDisabled={selectedInterests.length === 0}
                  letterSpacing="wide"
                  fontFamily="Montserrat, sans-serif"
                  _hover={{ 
                    transform: selectedInterests.length > 0 ? "translateY(-2px)" : "none",
                    shadow: selectedInterests.length > 0 ? "lg" : "none",
                    opacity: selectedInterests.length > 0 ? 0.9 : 1,
                  }}
                  _active={{
                    transform: "translateY(0)",
                    opacity: 0.8,
                  }}
                  transition="all 0.2s"
                >
                  Update {isBusiness ? "Event Types" : "Interests"}
                </Button>
              </VStack>
            </Box>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default UpdateInterestsPage;
