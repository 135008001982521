import {
  Container,
  Flex,
  VStack,
  Text,
  IconButton,
  Box,
  Button,
} from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import usePreviewImg from "../../hooks/usePreviewImg";
import useEditProfile from "../../hooks/useEditProfile";
import useAuthStore from "../../store/authStore";
import useShowToast from "../../hooks/useShowToast";
import DesktopNav from '../../components/Navigation/DesktopNav';

// List of popular European cities and their flags
const cities = [
  { name: "London", flag: "🇬🇧" },
  { name: "Manchester", flag: "🇬🇧" },
  { name: "Paris", flag: "🇫🇷" },
  { name: "Rome", flag: "🇮🇹" },
  { name: "Berlin", flag: "🇩🇪" },
  { name: "Madrid", flag: "🇪🇸" },
  { name: "Barcelona", flag: "🇪🇸" },
  { name: "Amsterdam", flag: "🇳🇱" },
  { name: "Moscow", flag: "🇷🇺" },
  { name: "Athens", flag: "🇬🇷" },
  { name: "Lisbon", flag: "🇵🇹" },
  { name: "Warsaw", flag: "🇵🇱" },
  { name: "Brussels", flag: "🇧🇪" },
  { name: "Stockholm", flag: "🇸🇪" },
  { name: "Prague", flag: "🇨🇿" },
  { name: "Other", flag: "🏴‍☠️" },
];

const UpdateCityPage = () => {
  const authUser = useAuthStore((state) => state.user);
  const navigate = useNavigate();
  const { editProfile } = useEditProfile();
  const showToast = useShowToast();
  const { selectedFile } = usePreviewImg();

  const [inputs, setInputs] = useState({
    ...authUser,
    city: authUser.city || "",
  });

  const [isCityUpdated, setIsCityUpdated] = useState(false);

  const colors = {
    textColor: "gray.900",
    bgColor: "white",
    cardBg: "white",
    dividerColor: "gray.200",
    iconBgColor: "rgba(184, 160, 128, 0.1)",
    hoverBgColor: "rgba(184, 160, 128, 0.05)",
    subtitleColor: "gray.600",
    accentColor: "#B8A080",
    borderColor: "rgba(184, 160, 128, 0.2)",
  };

  useEffect(() => {
    if (isCityUpdated) {
      navigate(-1);
    }
  }, [isCityUpdated, navigate]);

  const handleCitySelection = async (cityName) => {
    setInputs((prevInputs) => ({ ...prevInputs, city: cityName }));

    try {
      await editProfile({ ...inputs, city: cityName }, selectedFile);
      showToast("Success", `City updated to ${cityName}`, "success");
      setIsCityUpdated(true);
    } catch (error) {
      showToast("Error", error.message, "error");
    }
  };

  return (
    <Box
      h="100vh"
      maxH="100vh"
      overflow="hidden"
      position="relative"
      bg={colors.bgColor}
      pb="env(safe-area-inset-bottom)"
    >
      <Box display={{ base: "none", md: "block" }}>
        <DesktopNav />
      </Box>

      <Box
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        overflowY="auto"
        pb={{ base: 32, md: 8 }}
        style={{
          WebkitOverflowScrolling: 'touch'
        }}
      >
        <Container 
          maxW={{ base: "container.md", md: "container.xl" }} 
          px={4} 
          py={6}
          mb={{ base: 8, md: 0 }}
          mt={{ base: 0, md: "60px" }}
        >
          {/* Header */}
          <Flex 
            w="full" 
            alignItems="center" 
            mb={8}
            position="relative"
          >
            <IconButton
              icon={<ArrowBackIcon />}
              variant="ghost"
              aria-label="Go back"
              onClick={() => navigate(-1)}
              position={{ base: "relative", md: "absolute" }}
              left={{ md: 0 }}
              color={colors.accentColor}
              _hover={{
                bg: colors.iconBgColor,
                transform: "translateY(-2px)",
              }}
              transition="all 0.2s"
            />
            <Text 
              fontSize={{ base: "2xl", md: "3xl" }}
              fontWeight="light"
              color={colors.textColor}
              textAlign={{ base: "left", md: "center" }}
              flex="1"
              ml={{ base: 4, md: 0 }}
              letterSpacing="wide"
              fontFamily="Montserrat, sans-serif"
            >
              Where would you like to have your meetups?
            </Text>
          </Flex>

          {/* Main Content */}
          <Box 
            maxW={{ base: "full", md: "lg" }} 
            mx="auto"
          >
            <Box
              bg={colors.cardBg}
              borderRadius="xl"
              borderWidth="1px"
              borderColor={colors.borderColor}
              p={6}
              shadow="sm"
              _hover={{ shadow: "md" }}
              transition="all 0.2s"
            >
              <VStack spacing={4} align="stretch" w="full">
                {cities.map(({ name, flag }) => (
                  <Button
                    key={name}
                    size="lg"
                    variant="ghost"
                    borderWidth="2px"
                    borderColor={inputs.city === name ? colors.accentColor : colors.borderColor}
                    color={inputs.city === name ? colors.accentColor : colors.textColor}
                    fontWeight="medium"
                    width="full"
                    justifyContent="flex-start"
                    leftIcon={<span>{flag}</span>}
                    _hover={{ 
                      bg: colors.iconBgColor,
                      transform: "translateY(-1px)",
                    }}
                    onClick={() => handleCitySelection(name)}
                    transition="all 0.2s"
                    h={14}
                    fontSize="md"
                    fontFamily="Montserrat, sans-serif"
                    letterSpacing="wide"
                    rounded="xl"
                  >
                    {name}
                  </Button>
                ))}
              </VStack>
            </Box>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default UpdateCityPage;
