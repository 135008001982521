import { useEffect, useState } from "react";
import { collection, query, where, getDocs } from "firebase/firestore";
import { firestore } from "../firebase/firebase";
import useAuthStore from "../store/authStore";

const useFetchTickets = (userId) => {
  const authUser = useAuthStore((state) => state.user);

  const [tickets, setTickets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTickets = async () => {

      setLoading(true);
      try {
          // console.log(authUser.uid)

      // console.log("fetchedTickets")

        const ticketsRef = collection(firestore, "tickets");
        const q = query(ticketsRef, where("userId", "==", authUser.uid));
        const querySnapshot = await getDocs(q);
        const fetchedTickets = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        // console.log(fetchedTickets)
        setTickets(fetchedTickets);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (authUser.uid) fetchTickets();
  }, [authUser.uid]);

  return { tickets, loading, error };
};

export default useFetchTickets;
