import {
  Container,
  Flex,
  Button,
  VStack,
  FormControl,
  Textarea,
  Text,
  IconButton,
  Box,
  Progress,
  HStack,
} from "@chakra-ui/react";
import { ArrowBackIcon, CloseIcon } from "@chakra-ui/icons";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";

const CreateDescriptionPage = () => {
  const [eventDescription, setEventDescription] = useState(
    localStorage.getItem("eventDescription") || ""
  );
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const clearText = () => setEventDescription("");

  return (
    <Box
      position="fixed"
      top={{ base: 0, md: "64px" }}
      left={0}
      right={0}
      bottom={0}
      bg="white"
      zIndex={1000}
      overflowY="auto"
    >
      <Container
        maxW="container.sm"
        py={4}
        display="flex"
        flexDirection="column"
        minH={{ base: "100vh", md: "calc(100vh - 64px)" }}
      >
        {/* Header with Progress - Visible on all screens */}
        <Flex w="full" align="center" mb={6}>
          <IconButton
            icon={<ArrowBackIcon />}
            variant="ghost"
            aria-label="Go back"
            onClick={() => navigate(-1)}
            color="gray.500"
            _hover={{ color: "#4F46E5" }}
          />
          <Progress
            value={25}
            size="sm"
            colorScheme="purple"
            flex="1"
            mx={4}
            borderRadius="full"
            bg="gray.100"
          />
        </Flex>

        {/* Main Content */}
        <VStack spacing={8} align="center" w="full" flex="1">
          <VStack spacing={3} textAlign="center">
            <Box
              p={3}
              bg="purple.50"
              rounded="full"
              color="purple.600"
              fontSize="2xl"
              mb={2}
            >
              ✍️
            </Box>

            <Text
              fontSize={{ base: "2xl", md: "3xl" }}
              fontWeight="bold"
              // bgGradient="linear(to-r, #4F46E5, #7C3AED)"
              // bgClip="text"
              color="gray.900"
            >
              Describe your event
            </Text>
            <Text color="gray.600" fontSize={{ base: "md", md: "lg" }}>
              Tell people what to expect
            </Text>
          </VStack>

          <VStack spacing={6} w="full" maxW="400px" flex="1">
            <FormControl position="relative" h="full">
              <Textarea
                placeholder="Enter your event description"
                value={eventDescription}
                onChange={(e) => setEventDescription(e.target.value)}
                bg="gray.50"
                color="gray.800"
                border="1px solid"
                borderColor="gray.200"
                _placeholder={{ color: "gray.400" }}
                _focus={{
                  borderColor: "#4F46E5",
                  boxShadow: "0 0 0 1px #4F46E5",
                  bg: "white",
                  transform: "scale(1.02)",
                }}
                _hover={{
                  borderColor: "gray.300",
                }}
                rounded="xl"
                h="full"
                minH="200px"
                fontSize="lg"
                p={4}
                resize="none"
                transition="all 0.2s"
              />
              {eventDescription && (
                <IconButton
                  icon={<CloseIcon />}
                  size="xs"
                  position="absolute"
                  top="4"
                  right="4"
                  aria-label="Clear text"
                  color="gray.500"
                  bg="transparent"
                  _hover={{ bg: "gray.200" }}
                  onClick={clearText}
                />
              )}
            </FormControl>

            <Button
              w="full"
              size="lg"
              bg="gray.900"
              color="white"
              h="60px"
              fontSize="lg"
              isDisabled={!eventDescription}
              onClick={() => {
                localStorage.setItem("eventDescription", eventDescription);
                navigate("/create_event_location");
              }}
              _hover={{
                transform: "translateY(-2px)",
                shadow: "lg",
                opacity: 0.95,
              }}
              _active={{ transform: "translateY(0)" }}
              rounded="xl"
              rightIcon={<FaArrowRight />}
              transition="all 0.2s"
              mb={4}
            >
              Continue
            </Button>
          </VStack>
        </VStack>
      </Container>
    </Box>
  );
};

export default CreateDescriptionPage;
