// useSendInvitations.js
import { useCallback, useState } from "react";
import { getFunctions, httpsCallable } from "firebase/functions";
import { app } from "../firebase/firebase";

const useSendInvitations = () => {
  const functions = getFunctions(app);
  const sendInvitationsFunction = httpsCallable(
    functions,
    "createInvitationsForEvent"
  );
  const [loading, setLoading] = useState(false);

  const sendInvitations = useCallback(async (minAge, maxAge, eventId, city) => {
    setLoading(true);
    try {
      await sendInvitationsFunction({ minAge, maxAge, eventId, city });
      return true;
    } catch (error) {
      console.error("Error sending invitations:", error);
      return false;
    } finally {
      setLoading(false);
    }
  }, []);

  return { sendInvitations, loading };
};

export default useSendInvitations;
