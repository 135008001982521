import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Container,
  VStack,
  Heading,
  Text,
  Stack,
  Skeleton,
  Divider,
  Grid,
  GridItem,
  IconButton,
  HStack,
  Badge,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { doc, getDoc } from "firebase/firestore";
import { firestore } from "../../firebase/firebase";
import { motion, AnimatePresence } from "framer-motion";
import { IoArrowBack, IoClose } from "react-icons/io5";
import { FaInstagram, FaLinkedinIn } from "react-icons/fa";
import { IoLocationOutline } from "react-icons/io5";
import { BsBriefcase, BsInstagram, BsLinkedin } from "react-icons/bs";
import { HiOutlineLocationMarker, HiOutlineBriefcase } from "react-icons/hi";
import { BsCheckCircleFill } from "react-icons/bs";

const PhotoSlide = ({ imageUrl }) => (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    transition={{ duration: 0.3, ease: "easeInOut" }}
    style={{
      width: "100%",
      height: "100%",
      position: "absolute",
      top: 0,
      left: 0,
    }}
  >
    <Box
      w="100%"
      h="100%"
      bgImage={`url(${imageUrl})`}
      bgSize="cover"
      bgPosition="center"
      position="relative"
    />
  </motion.div>
);

const ViewUserProfilePage = () => {
  const { userId } = useParams();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const navigate = useNavigate();
  
  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const userDoc = await getDoc(doc(firestore, "users", userId));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          console.log('Fetched user data:', userData); // Debug log
          
          // Handle profilePicURLs object
          const photoUrls = userData.profilePicURLs 
            ? Object.values(userData.profilePicURLs)
                .map(pic => pic.url || pic)
                .filter(Boolean)
            : [userData.profilePicURL].filter(Boolean);

          // Convert interests string to array
          const interestsArray = userData.interests
            ? userData.interests.split(',').map(interest => interest.trim())
            : [];
          
          setUser({ 
            id: userDoc.id, 
            ...userData,
            allPhotos: photoUrls,
            interests: interestsArray
          });
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching user profile:", error);
        setLoading(false);
      }
    };

    fetchUserProfile();
  }, [userId]);

  const nextImage = () => {
    setCurrentImageIndex((prev) => 
      prev === user.allPhotos.length - 1 ? 0 : prev + 1
    );
  };

  const prevImage = () => {
    setCurrentImageIndex((prev) => 
      prev === 0 ? user.allPhotos.length - 1 : prev - 1
    );
  };

  const formatInstagramUrl = (username) => {
    // Remove @ if present
    const formattedUsername = username.replace('@', '');
    return `https://instagram.com/${formattedUsername}`;
  };

  const formatLinkedinUrl = (linkedinHandle) => {
    // Check if it's already a URL
    if (linkedinHandle.startsWith('http')) {
      return linkedinHandle;
    }
    return `https://linkedin.com/in/${linkedinHandle}`;
  };

  const getLinkedinDisplayText = (linkedinUrl) => {
    // If it's already just a username/handle, return it
    if (!linkedinUrl.includes('/')) {
      return linkedinUrl;
    }
    
    // Remove trailing slash if present
    const cleanUrl = linkedinUrl.replace(/\/$/, '');
    
    // Extract username from various possible LinkedIn URL formats
    const matches = cleanUrl.match(/linkedin\.com\/in\/([^\/\?]+)/);
    if (matches && matches[1]) {
      return matches[1];
    }
    
    // If we can't parse it, return the original
    return linkedinUrl;
  };

  const handleInstagramClick = () => {
    if (user.instagram) {
      window.open(formatInstagramUrl(user.instagram), '_blank');
    }
  };

  const handleLinkedinClick = () => {
    if (user.linkedin) {
      window.open(formatLinkedinUrl(user.linkedin), '_blank');
    }
  };

  if (loading) {
    return (
      <Container maxW="container.md" py={8}>
        <Stack spacing={4}>
          <Skeleton height="500px" borderRadius="lg" />
          <Skeleton height="120px" />
          <Skeleton height="200px" />
        </Stack>
      </Container>
    );
  }

  if (!user) {
    return (
      <Container maxW="container.md" py={8}>
        <Text>User not found</Text>
      </Container>
    );
  }

  const calculateAge = (yob) => {
    if (!yob) return null;
    const currentYear = new Date().getFullYear();
    return currentYear - parseInt(yob);
  };

  return (
    <Box bg="black" minH="100vh">
      {/* Main Container */}
      <Box 
        maxW={{ base: "container.sm", md: "container.xl" }}
        mx="auto" 
        bg="white" 
        minH="100vh"
        position="relative"
        display={{ base: "block", md: "flex" }}
      >
        {/* Left Side - Photos */}
        <Box 
          w={{ base: "100%", md: "50%" }}
          position="relative"
        >
          {/* Back Button */}
          <HStack
            position="absolute"
            top={4}
            left={4}
            right={4}
            zIndex={10}
            justify="space-between"
          >
            <IconButton
              icon={<IoClose size={24} />}
              onClick={() => navigate(-1)}
              rounded="full"
              bg="white"
              color="gray.800"
              size="md"
              shadow="lg"
              _hover={{ transform: "scale(1.05)" }}
              transition="all 0.2s"
            />
          </HStack>

          {/* Photo Carousel */}
          <Box 
            position={{ base: "relative", md: "sticky" }}
            top={0}
            h={{ base: "75vh", md: "600px" }}
            maxH={{ base: "75vh", md: "800px" }}
            overflow="hidden"
          >
            <AnimatePresence mode="wait">
              <PhotoSlide 
                key={currentImageIndex}
                imageUrl={user.allPhotos[currentImageIndex]}
              />
            </AnimatePresence>

            {/* Left and Right tap areas - Update z-index and ensure full height */}
            <Box
              position="absolute"
              left={0}
              top={0}
              width="50%"
              height="100%"
              onClick={prevImage}
              cursor="pointer"
              zIndex={5}  // Increase z-index to be above overlay
              _active={{ bg: "transparent" }}
              _focus={{ outline: "none" }}
              sx={{
                WebkitTapHighlightColor: "transparent",
                WebkitTouchCallout: "none",
              }}
            />
            <Box
              position="absolute"
              right={0}
              top={0}
              width="50%"
              height="100%"
              onClick={nextImage}
              cursor="pointer"
              zIndex={5}  // Increase z-index to be above overlay
              _active={{ bg: "transparent" }}
              _focus={{ outline: "none" }}
              sx={{
                WebkitTapHighlightColor: "transparent",
                WebkitTouchCallout: "none",
              }}
            />

            {/* Photo Indicators */}
            <HStack 
              position="absolute" 
              top={4} 
              left={4} 
              right={4}
              justify="center"
              spacing={1}
              zIndex={6}  // Increase to be above tap areas
            >
              {user.allPhotos.map((_, index) => (
                <Box
                  key={index}
                  h="3px"
                  flex={1}
                  maxW="60px"
                  bg={index === currentImageIndex ? "white" : "whiteAlpha.400"}
                  transition="all 0.2s"
                />
              ))}
            </HStack>

            {/* Gradient Overlay */}
            <Box
              position="absolute"
              bottom={0}
              w="100%"
              h="70%"
              bgGradient="linear(to-t, rgba(0,0,0,0.9), rgba(0,0,0,0.5) 50%, transparent)"
              zIndex={3}
            />

            {/* Mobile User Info Overlay */}
            <Box
              position="absolute"
              bottom={0}
              left={0}
              right={0}
              p={6}
              pb={8}
              color="white"
              zIndex={4}
              display={{ base: 'block', md: 'none' }}
              pointerEvents="none"
            >
              <VStack align="start" spacing={3}>
                <Heading 
                  size="xl" 
                  fontWeight="semibold"
                  textShadow="0 2px 4px rgba(0,0,0,0.5)"
                  letterSpacing="tight"
                >
                  {user.fullName || user.name}, {calculateAge(user.yob)}
                </Heading>
                
                <HStack spacing={6}>
                  {user.job && (
                    <HStack spacing={2}>
                      <Box color="white">
                        <HiOutlineBriefcase size={20} />
                      </Box>
                      <Text 
                        fontSize="md" 
                        color="white"
                        textShadow="0 1px 2px rgba(0,0,0,0.5)"
                      >
                        {user.job}
                      </Text>
                    </HStack>
                  )}
                  {user.city && (
                    <HStack spacing={2}>
                      <Box color="white">
                        <HiOutlineLocationMarker size={20} />
                      </Box>
                      <Text 
                        fontSize="md" 
                        color="white"
                        textShadow="0 1px 2px rgba(0,0,0,0.5)"
                      >
                        {user.city}
                      </Text>
                    </HStack>
                  )}
                </HStack>
              </VStack>
            </Box>
          </Box>
        </Box>

        {/* Right Side - Content */}
        <Box 
          w={{ base: "100%", md: "50%" }}
          bg="white"
          position="relative"
          p={{ base: 6, md: 8 }}
          minH={{ md: "600px" }}
        >
          {/* User Basic Info - Desktop */}
          <VStack 
            align="stretch" 
            spacing={6}
            display={{ base: 'none', md: 'flex' }}  // Hide on mobile
          >
            <Box>
              <Heading 
                size="xl" 
                fontWeight="semibold"
                color="gray.900"
              >
                {user.fullName || user.name}, {calculateAge(user.yob)}
              </Heading>
              
              <HStack spacing={6} mt={4}>
                {user.job && (
                  <HStack spacing={2}>
                    <Box color="gray.600">
                      <HiOutlineBriefcase size={20} />
                    </Box>
                    <Text fontSize="md" color="gray.700">
                      {user.job}
                    </Text>
                  </HStack>
                )}
                {user.city && (
                  <HStack spacing={2}>
                    <Box color="gray.600">
                      <HiOutlineLocationMarker size={20} />
                    </Box>
                    <Text fontSize="md" color="gray.700">
                      {user.city}
                    </Text>
                  </HStack>
                )}
              </HStack>
            </Box>

            {/* Social Links */}
            {(user.instagram || user.linkedin) && (
              <HStack spacing={4}>
                {user.instagram && (
                  <HStack 
                    spacing={2} 
                    flex={1} 
                    p={3}
                    bg="gray.50"
                    rounded="full"
                    transition="all 0.2s"
                    _hover={{ bg: "gray.100" }}
                    onClick={handleInstagramClick}
                    cursor="pointer"
                    role="button"
                  >
                    <Box color="pink.500">
                      <BsInstagram size={20} />
                    </Box>
                    <HStack spacing={1} flex={1}>
                      <Text fontSize="sm" fontWeight="medium">
                        {user.instagram}
                      </Text>
                      {user.isInstagramVerified && (
                        <Box color="pink.500">
                          <BsCheckCircleFill size={14} />
                        </Box>
                      )}
                    </HStack>
                  </HStack>
                )}
                {user.linkedin && (
                  <HStack 
                    spacing={2} 
                    flex={1} 
                    p={3}
                    bg="gray.50"
                    rounded="full"
                    transition="all 0.2s"
                    _hover={{ bg: "gray.100" }}
                    onClick={handleLinkedinClick}
                    cursor="pointer"
                    role="button"
                  >
                    <Box color="blue.500">
                      <BsLinkedin size={20} />
                    </Box>
                    <HStack spacing={1} flex={1} overflow="hidden">
                      <Text 
                        fontSize="sm" 
                        fontWeight="medium"
                        isTruncated
                      >
                        {getLinkedinDisplayText(user.linkedin)}
                      </Text>
                      {user.isLinkedinVerified && (
                        <Box color="blue.500" flexShrink={0}>
                          <BsCheckCircleFill size={14} />
                        </Box>
                      )}
                    </HStack>
                  </HStack>
                )}
              </HStack>
            )}

            {/* Interests */}
            {user.interests && user.interests.length > 0 && (
              <Box>
                <Text 
                  fontSize="lg" 
                  fontWeight="semibold" 
                  mb={3}
                  color="gray.700"
                >
                  Interests
                </Text>
                <Wrap spacing={2}>
                  {user.interests.map((interest, index) => (
                    <WrapItem key={index}>
                      <Box
                        px={4}
                        py={2}
                        bg="gray.100"
                        color="gray.800"
                        fontSize="sm"
                        fontWeight="medium"
                        rounded="full"
                        transition="all 0.2s"
                        _hover={{ bg: "gray.200" }}
                      >
                        {interest}
                      </Box>
                    </WrapItem>
                  ))}
                </Wrap>
              </Box>
            )}
          </VStack>

          {/* Mobile-only content */}
          <VStack 
            align="stretch" 
            spacing={6}
            display={{ base: 'flex', md: 'none' }}  // Show only on mobile
          >
            {(user.instagram || user.linkedin) && (
              <HStack spacing={4}>
                {user.instagram && (
                  <HStack 
                    spacing={2} 
                    flex={1} 
                    p={3}
                    bg="gray.50"
                    rounded="full"
                    transition="all 0.2s"
                    _hover={{ bg: "gray.100" }}
                    onClick={handleInstagramClick}
                    cursor="pointer"
                    role="button"
                  >
                    <Box color="pink.500">
                      <BsInstagram size={20} />
                    </Box>
                    <HStack spacing={1} flex={1}>
                      <Text fontSize="sm" fontWeight="medium">
                        {user.instagram}
                      </Text>
                      {user.isInstagramVerified && (
                        <Box color="pink.500">
                          <BsCheckCircleFill size={14} />
                        </Box>
                      )}
                    </HStack>
                  </HStack>
                )}
                {user.linkedin && (
                  <HStack 
                    spacing={2} 
                    flex={1} 
                    p={3}
                    bg="gray.50"
                    rounded="full"
                    transition="all 0.2s"
                    _hover={{ bg: "gray.100" }}
                    onClick={handleLinkedinClick}
                    cursor="pointer"
                    role="button"
                  >
                    <Box color="blue.500">
                      <BsLinkedin size={20} />
                    </Box>
                    <HStack spacing={1} flex={1} overflow="hidden">
                      <Text 
                        fontSize="sm" 
                        fontWeight="medium"
                        isTruncated
                      >
                        {getLinkedinDisplayText(user.linkedin)}
                      </Text>
                      {user.isLinkedinVerified && (
                        <Box color="blue.500" flexShrink={0}>
                          <BsCheckCircleFill size={14} />
                        </Box>
                      )}
                    </HStack>
                  </HStack>
                )}
              </HStack>
            )}

            {user.interests && user.interests.length > 0 && (
              <Box>
                <Text 
                  fontSize="lg" 
                  fontWeight="semibold" 
                  mb={3}
                  color="gray.700"
                >
                  Interests
                </Text>
                <Wrap spacing={2}>
                  {user.interests.map((interest, index) => (
                    <WrapItem key={index}>
                      <Box
                        px={4}
                        py={2}
                        bg="gray.100"
                        color="gray.800"
                        fontSize="sm"
                        fontWeight="medium"
                        rounded="full"
                        transition="all 0.2s"
                        _hover={{ bg: "gray.200" }}
                      >
                        {interest}
                      </Box>
                    </WrapItem>
                  ))}
                </Wrap>
              </Box>
            )}
          </VStack>
        </Box>
      </Box>
    </Box>
  );
};

export default ViewUserProfilePage; 