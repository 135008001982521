import {
  Container,
  Flex,
  Button,
  VStack,
  InputRightElement,
  Box,
  InputGroup,
  FormControl,
  Input,
  Text,
  IconButton,
  useToast,
  Icon,
  HStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import { ArrowBackIcon, CloseIcon } from "@chakra-ui/icons";
import { FaEnvelope, FaArrowRight } from "react-icons/fa";
import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// Email validation function
const isValidEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

const ChooseEmailPage = () => {
  const [email, setEmail] = useState(localStorage.getItem("email") || "");
  const [showClear, setShowClear] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const toast = useToast();
  const emailInputRef = useRef(null);
  const [referralCode, setReferralCode] = useState("");
  const [hasReferral, setHasReferral] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    window.scrollTo(0, 0);
    const timer = setTimeout(() => setIsLoading(false), 0);
    return () => clearTimeout(timer);
  }, []);

  const handleNext = () => {
    if (!isValidEmail(email)) {
      toast({
        title: "Invalid email address.",
        description: "Please enter a valid email address.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    } else {
      localStorage.setItem("email", email);
      navigate("/email_password");
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleNext();
    }
  };

  const handleInput = (e) => {
    const value = e.target.value;
    setShowClear(value.length > 0);
    setEmail(value);
  };

  const handleReferralSubmit = () => {
    if (referralCode.trim()) {
      setHasReferral(true);
      localStorage.setItem("referralCode", referralCode.trim());
      onClose();
      toast({
        title: "Referral code applied!",
        description: "You've been connected with your friend's invite",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  if (isLoading) {
    return <Box minH="100vh" bg="white" />;
  }

  return (
    <Box 
      minH="100vh"
      bg="white"
    >
      {/* Top Bar */}
      <Box
        position="fixed"
        top={0}
        left={0}
        right={0}
        zIndex={1000}
        bg="white"
        h={{ base: "56px", md: "64px" }}
        backdropFilter="blur(12px)"
        backgroundColor="rgba(255, 255, 255, 0.9)"
        borderBottom="1px solid"
        borderColor="gray.100"
      >
        <Container maxW="container.xl" h="full">
          <Flex 
            justify="space-between"
            align="center"
            h="full"
            px={{ base: 3, md: 4 }}
          >
            {/* Back Button - Only show on mobile */}
            <IconButton
              icon={<ArrowBackIcon />}
              variant="ghost"
              aria-label="Go back"
              onClick={() => navigate(-1)}
              color="gray.500"
              display={{ base: 'flex', md: 'none' }}
              _hover={{ color: "#B8A080" }}
            />

            {/* Logo */}
            <Box 
              as="h1" 
              fontSize={{ base: "26px", md: "32px" }}
              fontWeight="400"
              letterSpacing="0.35em"
              position="relative"
              display="inline-block"
              cursor="pointer"
              onClick={() => navigate("/")}
              transition="all 0.4s cubic-bezier(0.4, 0, 0.2, 1)"
              textTransform="uppercase"
              fontFamily="Montserrat, sans-serif"
              color="#1A1A1A"
              _hover={{
                letterSpacing: "0.4em",
                transform: "translateY(-1px)",
              }}
              sx={{
                WebkitFontSmoothing: "antialiased",
                textRendering: "optimizeLegibility",
              }}
            >
              <Box
                as="span"
                position="relative"
                _before={{
                  content: '""',
                  position: "absolute",
                  left: "-8%",
                  right: "-8%",
                  top: "-8px",
                  bottom: "-8px",
                  background: "linear-gradient(135deg, rgba(26, 26, 26, 0.08), rgba(26, 26, 26, 0.03))",
                  backdropFilter: "blur(4px)",
                  opacity: 0.9,
                  borderRadius: "2px",
                  transform: "skew(-4deg)",
                  transition: "all 0.3s ease",
                  zIndex: -1,
                }}
              >
                hubzo
              </Box>
            </Box>

            {/* Empty div to maintain spacing */}
            <Box w="40px" display={{ base: 'block', md: 'none' }} />
          </Flex>
        </Container>
      </Box>

      {/* Main Content */}
      <Box 
        pt={{ base: "56px", md: "64px" }}
        minH="100vh"
      >
        <Container maxW="container.sm" py={{ base: 8, md: 16 }} px={6}>
          <VStack spacing={8} align="center" w="full">
            <VStack spacing={3} textAlign="center">
              <Box
                p={3}
                bg="rgba(184, 160, 128, 0.1)"
                rounded="full"
                color="#B8A080"
                fontSize="2xl"
                mb={2}
                border="1px solid"
                borderColor="rgba(184, 160, 128, 0.2)"
              >
                <Icon as={FaEnvelope} />
              </Box>
              
              <Text 
                fontSize={{ base: "2xl", md: "3xl" }}
                fontWeight="light"
                color="gray.900"
                letterSpacing="0.02em"
                fontFamily="Montserrat, sans-serif"
              >
                What's your email address?
              </Text>
              {/* <Text 
                color="gray.600" 
                fontSize={{ base: "md", md: "lg" }}
                letterSpacing="wide"
              >
                We'll send you a verification code
              </Text> */}
            </VStack>

            <VStack spacing={6} w="full" maxW="400px">
              <FormControl>
                <InputGroup>
                  <Input
                    ref={emailInputRef}
                    placeholder="Your Email"
                    size="lg"
                    type="email"
                    value={email}
                    onChange={handleInput}
                    onInput={handleInput}
                    onKeyDown={handleKeyDown}
                    bg="gray.50"
                    color="gray.800"
                    border="1px solid"
                    borderColor="gray.200"
                    _placeholder={{ color: "gray.400" }}
                    _focus={{ 
                      borderColor: "#B8A080",
                      boxShadow: "0 0 0 1px #B8A080",
                      bg: "white",
                      transform: "scale(1.02)",
                    }}
                    _hover={{
                      borderColor: "gray.300",
                    }}
                    rounded="xl"
                    h="60px"
                    fontSize="lg"
                    textAlign="center"
                    transition="all 0.2s"
                    letterSpacing="wide"
                  />
                  {showClear && (
                    <InputRightElement h="full">
                      <IconButton
                        variant="ghost"
                        icon={<CloseIcon />}
                        size="sm"
                        color="gray.400"
                        onClick={() => {
                          setShowClear(false);
                          setEmail("");
                        }}
                        _hover={{ color: "gray.600" }}
                      />
                    </InputRightElement>
                  )}
                </InputGroup>
              </FormControl>

              <Button
                w="full"
                size="lg"
                bg="black"
                color="white"
                h="60px"
                fontSize="lg"
                fontWeight="bold"
                isDisabled={!email}
                onClick={handleNext}
                _hover={{
                  transform: "translateY(-2px)",
                  shadow: "lg",
                }}
                _active={{ transform: "translateY(0)" }}
                rounded="xl"
                rightIcon={<FaArrowRight />}
                transition="all 0.2s"
                letterSpacing="0.1em"
                textTransform="uppercase"
              >
                Continue
              </Button>

              <HStack 
                spacing={2} 
                color="gray.500"
                fontSize="sm"
                pt={2}
              >
                <Box as="span" fontSize="lg">🔒</Box>
                <Text letterSpacing="wide">Your information is always private</Text>
              </HStack>

              {!hasReferral ? (
                <Box
                  as="button"
                  onClick={onOpen}
                  py={2}
                  px={4}
                  rounded="full"
                  bg="gray.50"
                  border="1px solid"
                  borderColor="gray.200"
                  _hover={{
                    bg: "gray.100",
                    transform: "translateY(-1px)",
                  }}
                  transition="all 0.2s"
                >
                  <Text fontSize="sm" fontWeight="medium" color="gray.700">
                    Have a referral code?
                  </Text>
                </Box>
              ) : (
                <Box
                  py={2}
                  px={4}
                  rounded="full"
                  bg="green.50"
                  border="1px solid"
                  borderColor="green.200"
                >
                  <HStack spacing={2}>
                    <Text fontSize="sm" fontWeight="medium" color="green.700">
                      Referral code applied
                    </Text>
                  </HStack>
                </Box>
              )}

              <Modal isOpen={isOpen} onClose={onClose} isCentered>
                <ModalOverlay 
                  bg="blackAlpha.300"
                  backdropFilter="blur(10px)"
                />
                <ModalContent
                  mx={4}
                  bg="white"
                  rounded="2xl"
                  shadow="xl"
                >
                  <ModalHeader fontSize="lg" fontWeight="medium" pt={6}>
                    Enter Referral Code
                  </ModalHeader>
                  <ModalCloseButton />
                  <ModalBody pb={6}>
                    <VStack spacing={4}>
                      <FormControl>
                        <Input
                          placeholder="Enter referral code"
                          size="lg"
                          value={referralCode}
                          onChange={(e) => setReferralCode(e.target.value)}
                          bg="gray.50"
                          rounded="xl"
                          h="60px"
                          fontSize="lg"
                          textAlign="center"
                        />
                      </FormControl>

                      <Button
                        w="full"
                        size="lg"
                        bg="black"
                        color="white"
                        h="60px"
                        fontSize="lg"
                        onClick={handleReferralSubmit}
                        _hover={{
                          transform: "translateY(-2px)",
                          shadow: "lg",
                          opacity: 0.95,
                        }}
                        rounded="full"
                        isDisabled={!referralCode.trim()}
                      >
                        Apply Code
                      </Button>
                    </VStack>
                  </ModalBody>
                </ModalContent>
              </Modal>
            </VStack>
          </VStack>
        </Container>
      </Box>
    </Box>
  );
};

export default ChooseEmailPage;
