import { useState } from "react";
import useAuthStore from "../store/authStore";
import useShowToast from "./useShowToast";
import { getDownloadURL, ref, uploadString } from "firebase/storage";
import { firestore, storage } from "../firebase/firebase";
import { doc, updateDoc } from "firebase/firestore";
import useUserProfileStore from "../store/userProfileStore";
import { useNavigate } from "react-router-dom";

const useEditProfile = () => {
  const [isUpdating, setIsUpdating] = useState(false);

  const authUser = useAuthStore((state) => state.user);
  const setAuthUser = useAuthStore((state) => state.setUser);
  const setUserProfile = useUserProfileStore((state) => state.setUserProfile);
  const navigate = useNavigate();

  const showToast = useShowToast();

  const editProfile = async (inputs, selectedFile) => {
    if (isUpdating || !authUser) return;
    setIsUpdating(true);

    const storageRef = ref(storage, `profilePics/${authUser.uid}`);
    const userDocRef = doc(firestore, "users", authUser.uid);

    const instagramz = () => {
      if (inputs.instagram.includes("@")) {
        let newString = inputs.instagram.split("@").join("");
        return newString;
      } else if (inputs.instagram.includes("https://www.instagram.com/")) {
        let newString = inputs.instagram
          .split("https://www.instagram.com/")
          .join("");
        return newString;
      } else {
        return inputs.instagram;
      }
    };

    let URL = "";
    try {
      // if (selectedFile) {
      //   await uploadString(storageRef, selectedFile, "data_url");
      //   URL = await getDownloadURL(ref(storage, `profilePics/${authUser.uid}`));
      // }

      // const updatedUser = {
      //   ...authUser,
      //   fullName: inputs.fullName || authUser.fullName,
      //   username: inputs.username || authUser.username,
      //   bio: inputs.bio || authUser.bio,
      //   profilePicURL: URL || authUser.profilePicURL,
      //   whatsapp: inputs.whatsapp || authUser.whatsapp,
      //   instagram: instagramz() || authUser.instagram,
      //   linkedin: inputs.linkedin || authUser.linkedin,
      //   job: inputs.job || authUser.job,
      // };

      const updatedUser = {
        ...authUser,
        fullName: inputs?.fullName || authUser.fullName,
        // username: inputs?.username,
        // bio: inputs?.bio,
        // profilePicURL:
        // // URL ||
        // inputs?.profilePicURL,
        // whatsapp: inputs?.whatsapp,
        instagram: inputs?.instagram || authUser?.instagram || "",
        linkedin: inputs?.linkedin || authUser?.linkedin || "",
        job: inputs?.job || authUser.job || "",
        hidden: false,
        interests: inputs?.interests || authUser.interests || "",
        yob: inputs?.yob || authUser.yob || "",
        city: inputs?.city || authUser.city || "",
      };

      await updateDoc(userDocRef, updatedUser);
      localStorage.setItem("user-info", JSON.stringify(updatedUser));
      setAuthUser(updatedUser);
      setUserProfile(updatedUser);
      // showToast("Success", "Profile updated successfully", "success");
      navigate(-1);
    } catch (error) {
      showToast("Error", error.message, "error");
    }
  };

  return { editProfile, isUpdating };
};

export default useEditProfile;
