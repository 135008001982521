import { Container, Flex, VStack, Button, Text, Box } from "@chakra-ui/react";
import { CheckIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import Lottie from "react-lottie-player";
import loadingAnimation from "../../assets/processing_pre_signin.json";
import useSendInvitations from "../../hooks/useSendInvitations";

const CreateEventPostPage = () => {
  const navigate = useNavigate();
  const [showSuccess, setShowSuccess] = useState(false);
  const { sendInvitations, loading } = useSendInvitations();

  useEffect(() => {
    let hasInvitationsSent = false;
  
    const minAge = localStorage.getItem("minAge");
    const maxAge = localStorage.getItem("maxAge");
    const eventId = localStorage.getItem("eventId");
    const city = localStorage.getItem("city");
  
    const sendEventInvitations = async () => {
      if (!hasInvitationsSent) {
        hasInvitationsSent = true; // Set flag to true after the first call
        const success = await sendInvitations(minAge, maxAge, eventId, city);
        if (success) {
          setShowSuccess(true);
        }
      }
    };
  
    sendEventInvitations();
  }, []);

  return (
    <Container
      maxW="container.sm"
      minHeight="calc(var(--vh, 1vh) * 100)"
      display="flex"
      flexDirection="column"
      bg="black"
      p={0}
      position="relative"
    >
      {showSuccess ? (
        <Flex direction="column" flexGrow={1} px={4} justifyContent="center" alignItems="center">
          <VStack spacing={6} align="center">
            <Box
              boxSize="70px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              borderColor="gray"
              borderRadius="full"
            >
              <CheckIcon w={10} h={10} color="white" />
            </Box>
            <Text textColor="white" fontSize="xl" fontWeight="bold" textAlign="center" px={4}>
              All set! Invitations were sent. We will let you know the status of tickets issued.
            </Text>
          </VStack>
        </Flex>
      ) : (
        <Flex direction="column" flexGrow={1} px={4} justifyContent="center" alignItems="center">
          <Lottie loop animationData={loadingAnimation} play style={{ width: 150, height: 150 }} />
          <Text textColor="white" fontSize="lg" fontWeight="bold" textAlign="center" mt={4}>
            Sending the invitations to your target users...
          </Text>
        </Flex>
      )}
      {showSuccess && (
        <Box position="absolute" bottom={10} w="full" p={4} zIndex={10}>
          <VStack spacing={4} w="full">
            <Button
              bg="white"
              color="black"
              size="lg"
              w="full"
              onClick={() => {
                localStorage.setItem("minAge", "");
                localStorage.setItem("maxAge", "");
                localStorage.setItem("eventId", "");
                navigate("/");
              }}
              rounded="md"
              fontSize="lg"
              fontWeight="bold"
              _hover={{ bg: "gray.700", borderColor: "gray.600" }}
            >
              Go to invitations
            </Button>
          </VStack>
        </Box>
      )}
    </Container>
  );
};

export default CreateEventPostPage;
