import { useState, useEffect } from "react";
import { doc, getDoc } from "firebase/firestore";
import { firestore, storage } from "../firebase/firebase";
import { format, formatISO } from "date-fns";

const useGetEventData = (eventId) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!eventId) return;

    const fetchData = async () => {
      setLoading(true);
      try {
        const docRef = doc(firestore, "events", eventId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const eventData = docSnap.data();
          console.log(eventData);

          // Convert Firestore timestamps to Date objects
          const startDate = eventData.startAt.toDate();
          const endDate = eventData.endAt.toDate();

          // Format the dates
          const formattedStartDate = format(startDate, "EEE, MMM d, h:mm a");
          const formattedEndTime = format(endDate, "h:mm a");

          setData({
            ...eventData,
            formattedEventDate: `${formattedStartDate} - ${formattedEndTime}`,
          });
        } else {
          setError("Event not found");
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [eventId]);

  return { data, loading, error };
};

export default useGetEventData;
