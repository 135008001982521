import React, { useState, useEffect, useRef } from 'react';
import { collection, getDocs, addDoc, query, where } from 'firebase/firestore';
import {
  Box,
  VStack,
  Text,
  Container,
  Flex,
  useColorModeValue,
  SimpleGrid,
  Card,
  CardBody,
  Badge,
  Avatar,
  Button,
  Icon,
  HStack,
  IconButton,
  Link,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  Select,
  Stack,
  Switch,
  FormControl,
  FormLabel,
  useDisclosure,
  UnorderedList,
  ListItem,
  InputGroup,
  InputRightElement,
  Image,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useToast,
  Skeleton,
  InputLeftElement,
} from "@chakra-ui/react";
import { FaInstagram, FaWhatsapp, FaFacebook, FaGlobe, FaFilter, FaPlus, FaImage, FaEllipsisV, FaFlag, FaBan, FaShareAlt, FaSearch, FaTimesCircle, FaMapMarkerAlt, FaUserFriends, FaRobot, FaStar, FaChevronRight, FaCheckCircle } from 'react-icons/fa';
import { SiMeetup } from 'react-icons/si';
import { firestore, storage } from "../../firebase/firebase";
import { useNavigate, useLocation } from 'react-router-dom';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import useAuthStore from '../../store/authStore';
import { motion, AnimatePresence } from "framer-motion";

// First, define LocalsIcon
const LocalsIcon = (props) => (
  <Icon viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M7 3v14.5c0 .5.1.9.3 1.2.2.3.5.3.7.3h8c.5 0 1-.4 1-1 0-.5-.4-1-1-1H9V3c0-.6-.4-1-1-1S7 2.4 7 3z"  // More stylized "L"
    />
  </Icon>
);

// Then define the arrays that use it
const GROUP_TYPES = [
  'locals', 
  'drinks', 
  'games', 
  'gigs', 
  'sports', 
  'arts',
  'dating',
  'coffee',
  'club',
  'women',
  'running',
  'travel',
  'languages'
];
const LOCATIONS = ['London', 'Manchester', 'Birmingham', 'Leeds', 'Liverpool'];

const SOCIAL_FILTERS = [
  { id: 'hasInstagram', icon: FaInstagram, label: 'Instagram', color: 'pink' },
  { id: 'hasWhatsapp', icon: FaWhatsapp, label: 'WhatsApp', color: 'green' },
  { id: 'hasFacebook', icon: FaFacebook, label: 'Facebook', color: 'facebook' },
  { id: 'hasWebsite', icon: FaGlobe, label: 'Website', color: 'blue' },
  { id: 'hasMeetup', icon: SiMeetup, label: 'Meetup', color: 'red' },
  { id: 'hasLocals', icon: LocalsIcon, label: 'Locals', color: 'yellow' },
];

const SORT_OPTIONS = [
  { id: 'recent', label: '🕒 Most Recent', field: 'createdAt', order: 'desc' },
  { id: 'popularity', label: '🔥 Most Popular', field: 'users', order: 'desc' },
];

const FilterDialog = ({
  isOpen,
  onClose,
  filters,
  handleFilterChange,
  clearFilters,
  applyFilters,
  getTypeConfig
}) => (
  <Modal isOpen={isOpen} onClose={onClose} size="md">
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>Filter Groups</ModalHeader>
      <ModalCloseButton />
      <ModalBody pt={8} pb={6}>
        <Stack spacing={4}>
          <FormControl>
            <FormLabel>Group Type</FormLabel>
            <Flex gap={2} flexWrap="wrap">
              {GROUP_TYPES.map(type => {
                const typeConfig = getTypeConfig(type);
                const isSelected = filters.type === type;
                
                return (
                  <Badge
                    key={type}
                    cursor="pointer"
                    onClick={() => handleFilterChange('type', isSelected ? '' : type)}
                    colorScheme={isSelected ? typeConfig.color : 'gray'}
                    variant={isSelected ? 'solid' : 'subtle'}
                    px={3}
                    py={1.5}
                    borderRadius="full"
                    display="flex"
                    alignItems="center"
                    gap={1}
                    fontSize="sm"
                    _hover={{
                      transform: 'translateY(-1px)',
                      shadow: 'sm'
                    }}
                    transition="all 0.2s"
                  >
                    <Text>{typeConfig.emoji}</Text>
                    <Text>{type.charAt(0).toUpperCase() + type.slice(1)}</Text>
                  </Badge>
                )}
              )}
            </Flex>
          </FormControl>

          <FormControl display="flex" alignItems="center">
            <FormLabel mb="0">Verified Only</FormLabel>
            <Switch
              isChecked={filters.verified}
              onChange={(e) => handleFilterChange('verified', e.target.checked)}
            />
          </FormControl>

          <FormControl>
            <FormLabel>Social Media & Website</FormLabel>
            <Flex gap={2} flexWrap="wrap">
              {SOCIAL_FILTERS.map(({ id, icon: Icon, label, color }) => {
                const isSelected = filters[id];
                
                return (
                  <Badge
                    key={id}
                    cursor="pointer"
                    onClick={() => handleFilterChange(id, !isSelected)}
                    colorScheme={color}
                    variant={isSelected ? 'solid' : 'subtle'}
                    px={3}
                    py={1.5}
                    borderRadius="full"
                    display="flex"
                    alignItems="center"
                    gap={2}
                    fontSize="sm"
                    _hover={{
                      transform: 'translateY(-1px)',
                      shadow: 'sm'
                    }}
                    transition="all 0.2s"
                  >
                    <Icon />
                    <Text>{label}</Text>
                  </Badge>
                );
              })}
            </Flex>
          </FormControl>

          <FormControl>
            <FormLabel>Location</FormLabel>
            <Flex gap={2} flexWrap="wrap">
              {LOCATIONS.map(location => {
                const isSelected = filters.location === location;
                
                return (
                  <Badge
                    key={location}
                    cursor="pointer"
                    onClick={() => handleFilterChange('location', isSelected ? '' : location)}
                    colorScheme={isSelected ? 'blue' : 'gray'}
                    variant={isSelected ? 'solid' : 'subtle'}
                    px={3}
                    py={1.5}
                    borderRadius="full"
                    display="flex"
                    alignItems="center"
                    gap={1}
                    fontSize="sm"
                    _hover={{
                      transform: 'translateY(-1px)',
                      shadow: 'sm'
                    }}
                    transition="all 0.2s"
                  >
                    <Text>📍</Text>
                    <Text>{location}</Text>
                  </Badge>
                );
              })}
            </Flex>
          </FormControl>

          <FormControl>
            <FormLabel>Sort By</FormLabel>
            <Flex gap={2} flexWrap="wrap">
              {SORT_OPTIONS.map(({ id, label }) => {
                const isSelected = filters.sort === id;
                
                return (
                  <Badge
                    key={id}
                    cursor="pointer"
                    onClick={() => handleFilterChange('sort', isSelected ? '' : id)}
                    colorScheme={isSelected ? 'blue' : 'gray'}
                    variant={isSelected ? 'solid' : 'subtle'}
                    px={3}
                    py={1.5}
                    borderRadius="full"
                    display="flex"
                    alignItems="center"
                    gap={1}
                    fontSize="sm"
                    _hover={{
                      transform: 'translateY(-1px)',
                      shadow: 'sm'
                    }}
                    transition="all 0.2s"
                  >
                    <Text>{label}</Text>
                  </Badge>
                );
              })}
            </Flex>
          </FormControl>
        </Stack>
      </ModalBody>

      <ModalFooter gap={2}>
        <Button variant="ghost" onClick={clearFilters}>Clear Filters</Button>
        <Button colorScheme="blue" onClick={applyFilters}>Apply Filters</Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
);

const SuccessDialog = ({ isOpen, onClose }) => (
  <Modal isOpen={isOpen} onClose={onClose} size="md">
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>Group Created Successfully</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <VStack spacing={4} align="stretch">
          <Box>
            <Text fontSize="md" mb={4}>
              Your group has been created and is now pending verification. Our team will review your submission within 24-48 hours.
            </Text>
            <Text fontSize="md" color="gray.600">
              During the review process we will:
            </Text>
            <UnorderedList mt={2} spacing={2} color="gray.600">
              <ListItem>Verify your group information</ListItem>
              <ListItem>Check social media links</ListItem>
              <ListItem>Ensure compliance with our community guidelines</ListItem>
            </UnorderedList>
          </Box>
          <Box 
            bg="blue.50" 
            p={4} 
            borderRadius="md"
            borderLeft="4px"
            borderColor="blue.500"
          >
            <Text color="blue.700" fontWeight="medium">
              You'll receive a notification once your group is approved and visible to the community.
            </Text>
          </Box>
        </VStack>
      </ModalBody>
      <ModalFooter>
        <Button colorScheme="blue" onClick={onClose}>
          Got it
        </Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
);

const CreateGroupDialog = ({ isOpen, onClose }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [imagePreview, setImagePreview] = useState('');
  const fileInputRef = React.useRef();

  const [formData, setFormData] = useState({
    fullName: '',
    username: '',
    type: '',
    bio: '',
    website: '',
    instagram: '',
    whatsapp: '',
    facebook: '',
    verified: false,
    users: 0,
    location: 'London',
    meetup: '',
    locals: '',
  });

  const handleChange = (field, value) => {
    setFormData(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const uploadImage = async () => {
    if (!imageFile) return '';
    
    const storageRef = ref(storage, `group-images/${Date.now()}-${imageFile.name}`);
    await uploadBytes(storageRef, imageFile);
    return getDownloadURL(storageRef);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Validate Meetup URL if provided
    if (formData.meetup && !formData.meetup.includes('meetup.com')) {
      toast({
        title: "Invalid Meetup URL",
        description: "Please enter a valid Meetup.com URL",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      setIsSubmitting(true);
      
      // Upload image first if exists
      const profilePicURL = await uploadImage();
      
      const groupData = {
        ...formData,
        createdAt: Date.now(),
        profilePicURL,
        createdBy: 'anonymous',
        verified: false,
        status: 'pending',
        location: formData.location || 'London',
        meetup: formData.meetup,
      };

      await addDoc(collection(firestore, 'groups'), groupData);
      onClose();
      setShowSuccess(true);
    } catch (error) {
      console.error('Error creating group:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSuccessClose = () => {
    setShowSuccess(false);
    setImageFile(null);
    setImagePreview('');
    setFormData({
      fullName: '',
      username: '',
      type: '',
      bio: '',
      website: '',
      instagram: '',
      whatsapp: '',
      facebook: '',
      verified: false,
      users: 0,
      location: 'London',
      meetup: '',
      locals: '',
    });
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add a group</ModalHeader>
          <ModalCloseButton />
          <ModalBody pt={8} pb={6}>
            <Stack spacing={4}>
              <FormControl>
                <FormLabel>Group Picture</FormLabel>
                <Input
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                  display="none"
                  ref={fileInputRef}
                />
                <Box
                  onClick={() => fileInputRef.current.click()}
                  cursor="pointer"
                  borderWidth={2}
                  borderStyle="dashed"
                  borderRadius="md"
                  p={4}
                  textAlign="center"
                  bg={imagePreview ? 'transparent' : 'gray.50'}
                  position="relative"
                  height="200px"
                  overflow="hidden"
                >
                  {imagePreview ? (
                    <>
                      <Image
                        src={imagePreview}
                        alt="Group picture preview"
                        objectFit="cover"
                        w="100%"
                        h="100%"
                        position="absolute"
                        top={0}
                        left={0}
                      />
                      <Box
                        position="absolute"
                        top={0}
                        left={0}
                        right={0}
                        bottom={0}
                        bg="blackAlpha.60"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        opacity={0}
                        _hover={{ opacity: 1 }}
                        transition="opacity 0.2s"
                      >
                        <Text color="white">Change Picture</Text>
                      </Box>
                    </>
                  ) : (
                    <VStack spacing={2}>
                      <Icon as={FaImage} boxSize={8} color="gray.400" />
                      <Text color="gray.500">Click to upload group picture</Text>
                      <Text fontSize="sm" color="gray.400">
                        Recommended size: 800x800px
                      </Text>
                    </VStack>
                  )}
                </Box>
              </FormControl>

              <FormControl isRequired>
                <FormLabel>Group Name</FormLabel>
                <Input
                  placeholder="Enter group name"
                  value={formData.fullName}
                  onChange={(e) => handleChange('fullName', e.target.value)}
                />
              </FormControl>

              <FormControl isRequired>
                <FormLabel>Username</FormLabel>
                <Input
                  placeholder="Enter username"
                  value={formData.username}
                  onChange={(e) => handleChange('username', e.target.value)}
                />
              </FormControl>

              <FormControl isRequired>
                <FormLabel>Type</FormLabel>
                <Select
                  placeholder="Select group type"
                  value={formData.type}
                  onChange={(e) => handleChange('type', e.target.value)}
                >
                  {GROUP_TYPES.map(type => (
                    <option key={type} value={type}>
                      {type.charAt(0).toUpperCase() + type.slice(1)}
                    </option>
                  ))}
                </Select>
              </FormControl>

              <FormControl>
                <FormLabel>Bio</FormLabel>
                <Input
                  placeholder="Enter group bio"
                  value={formData.bio}
                  onChange={(e) => handleChange('bio', e.target.value)}
                />
              </FormControl>

              <FormControl>
                <FormLabel>Website</FormLabel>
                <Input
                  placeholder="Enter website URL"
                  value={formData.website}
                  onChange={(e) => handleChange('website', e.target.value)}
                />
              </FormControl>

              <FormControl>
                <FormLabel>Instagram</FormLabel>
                <Input
                  placeholder="Enter Instagram URL"
                  value={formData.instagram}
                  onChange={(e) => handleChange('instagram', e.target.value)}
                />
              </FormControl>

              <FormControl>
                <FormLabel>WhatsApp</FormLabel>
                <Input
                  placeholder="Enter WhatsApp URL"
                  value={formData.whatsapp}
                  onChange={(e) => handleChange('whatsapp', e.target.value)}
                />
              </FormControl>

              <FormControl>
                <FormLabel>Facebook</FormLabel>
                <Input
                  placeholder="Enter Facebook URL"
                  value={formData.facebook}
                  onChange={(e) => handleChange('facebook', e.target.value)}
                />
              </FormControl>

              <FormControl>
                <FormLabel>Location</FormLabel>
                <Input
                  placeholder="Enter location (default: London)"
                  value={formData.location}
                  onChange={(e) => handleChange('location', e.target.value)}
                />
              </FormControl>

              <FormControl>
                <FormLabel>Meetup URL (optional)</FormLabel>
                <InputGroup>
                  <InputLeftElement pointerEvents="none">
                    <Icon as={SiMeetup} color="red.500" />
                  </InputLeftElement>
                  <Input
                    placeholder="https://www.meetup.com/your-group"
                    value={formData.meetup}
                    onChange={(e) => setFormData(prev => ({
                      ...prev,
                      meetup: e.target.value
                    }))}
                  />
                </InputGroup>
              </FormControl>

              <FormControl>
                <FormLabel>Locals URL (optional)</FormLabel>
                <InputGroup>
                  <InputLeftElement pointerEvents="none">
                    <Icon as={LocalsIcon} color="yellow.500" />
                  </InputLeftElement>
                  <Input
                    placeholder="https://locals.org/your-group"
                    value={formData.locals}
                    onChange={(e) => setFormData(prev => ({
                      ...prev,
                      locals: e.target.value
                    }))}
                  />
                </InputGroup>
              </FormControl>
            </Stack>
          </ModalBody>

          <ModalFooter gap={2}>
            <Button variant="ghost" onClick={onClose}>Cancel</Button>
            <Button 
              colorScheme="blue" 
              onClick={handleSubmit}
              isLoading={isSubmitting}
            >
              Add Group
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <SuccessDialog 
        isOpen={showSuccess} 
        onClose={handleSuccessClose}
      />
    </>
  );
};

const AuthRequiredDialog = ({ isOpen, onClose }) => {
  const navigate = useNavigate();
  
  return (
    <Modal 
      isOpen={isOpen} 
      onClose={onClose} 
      size="xs"
      motionPreset="slideInBottom"
      isCentered
    >
      <ModalOverlay 
        bg="blackAlpha.800" 
        backdropFilter="blur(8px)"
      />
      <ModalContent 
        bg="black" 
        color="white"
        borderRadius="2xl"
        mx={4}
        overflow="hidden"
        boxShadow="dark-lg"
      >
        <ModalBody pt={10} pb={10} px={6}>
          <VStack spacing={8}>
          <Box display={{ base: "block", md: "none" }} flexShrink={0}>
              <Flex align="center">
                <Box
                  bg="white"
                  px={1}
                  py={0.5}
                  borderRadius="md"
                  position="relative"
                  mr={-0.5}
                >
                  <Text
                    color="black"
                    fontWeight="black"
                    fontSize="md"
                    letterSpacing="tight"
                  >
                    IRL
                  </Text>
                </Box>
                <Text
                  fontSize="md"
                  fontWeight="black"
                  color="white"
                  letterSpacing="tight"
                  ml={0.5}
                >
                  REV
                </Text>
              </Flex>
            </Box>

            <VStack spacing={2}>
              <Text
                fontSize="lg"
                fontWeight="semibold"
                textAlign="center"
                color="white"
              >
                Join Our Community
              </Text>
              <Text
                fontSize="sm"
                textAlign="center"
                color="whiteAlpha.800"
              >
                To not leak the WhatsApp group links, we require users to be logged in.
              </Text>
            </VStack>

            <VStack spacing={3} w="full">
              <Button
                w="full"
                bg="white"
                color="black"
                size="md"
                _hover={{
                  bg: "whiteAlpha.900",
                  transform: "translateY(-1px)",
                }}
                onClick={() => {
                  onClose();
                  navigate("/email");
                }}
                fontWeight="semibold"
              >
                Create Account
              </Button>

              <Text
                fontSize="sm"
                color="whiteAlpha.600"
                cursor="pointer"
                onClick={() => {
                  onClose();
                  navigate("/sign_in");
                }}
                _hover={{
                  color: "white"
                }}
                transition="color 0.2s"
              >
                Already have an account? Sign in
              </Text>
            </VStack>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const GroupDetailsDialog = ({ isOpen, onClose, username, getTypeConfig }) => {
  const [group, setGroup] = useState(null);
  const cardBgColor = useColorModeValue("white", "gray.800");
  const borderColor = useColorModeValue("gray.100", "gray.700");
  const textColorSecondary = useColorModeValue("gray.600", "gray.300");
  const toast = useToast();

  useEffect(() => {
    const fetchGroup = async () => {
      if (!username) return;
      const q = query(collection(firestore, "groups"), where("username", "==", username));
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        setGroup({ id: querySnapshot.docs[0].id, ...querySnapshot.docs[0].data() });
      }
    };
    fetchGroup();
  }, [username]);

  const handleAction = async (type) => {
    try {
      const collectionName = type === 'report' ? 'reports' : 'blocks';
      await addDoc(collection(firestore, collectionName), {
        groupId: group.id,
        groupName: group.fullName,
        userId: 'anonymous',
        createdAt: Date.now(),
        type: type,
        status: 'pending'
      });

      toast({
        title: type === 'report' ? 'Report Submitted' : 'Group Blocked',
        description: type === 'report' 
          ? 'Thank you for helping keep our community safe.' 
          : 'You won\'t see this group anymore.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      onClose();
    } catch (error) {
      console.error(`Error ${type}ing group:`, error);
      toast({
        title: 'Error',
        description: `Failed to ${type} group. Please try again.`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const ReadMoreText = ({ text, maxLines = 4 }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const textRef = useRef(null);
    const [showButton, setShowButton] = useState(false);

    useEffect(() => {
      if (textRef.current) {
        const lineHeight = parseInt(window.getComputedStyle(textRef.current).lineHeight);
        const height = textRef.current.clientHeight;
        setShowButton(height > (lineHeight * maxLines));
      }
    }, [text, maxLines]);

    return (
      <Box>
        <Text
          ref={textRef}
          fontSize="sm"
          color={textColorSecondary}
          lineHeight="tall"
          noOfLines={isExpanded ? undefined : maxLines}
          transition="all 0.2s"
        >
          {text || "No description available"}
        </Text>
        {showButton && (
          <Button
            size="sm"
            variant="link"
            color="blue.500"
            mt={1}
            onClick={() => setIsExpanded(!isExpanded)}
          >
            {isExpanded ? 'Read less' : 'Read more'}
          </Button>
        )}
      </Box>
    );
  };

  const SocialButton = ({ icon: Icon, label, onClick, colorScheme }) => (
    <Button
      leftIcon={<Icon size={18} />}
      variant="ghost"
      colorScheme={colorScheme}
      size="md"
      onClick={onClick}
      flex={1}
      fontWeight="medium"
      _hover={{
        transform: "scale(1.02)",
        bg: `${colorScheme}.50`,
      }}
      _active={{
        transform: "scale(0.98)",
        bg: `${colorScheme}.100`,
      }}
    >
      {label}
    </Button>
  );

  return (
    <Modal 
      isOpen={isOpen} 
      onClose={onClose}
      size="md"
      motionPreset="slideInBottom"
    >
      <ModalOverlay 
        bg="blackAlpha.600"
        backdropFilter="blur(8px)"
      />
      <ModalContent
        bg={cardBgColor}
        borderRadius="2xl"
        overflow="hidden"
        mx={4}
      >
        {group && (
          <>
            <ModalBody pt={6} pb={6} px={6}>
              <VStack spacing={6} align="stretch">
                {/* Group Info */}
                <Flex gap={4}>
                  <Box position="relative">
                    <Avatar
                      size="xl"
                      src={group.profilePicURL}
                      name={group.fullName}
                      border="2px solid"
                      borderColor={borderColor}
                    />
                    <Badge
                      position="absolute"
                      bottom="-4px"
                      right="-4px"
                      colorScheme={getTypeConfig(group.type).color}
                      fontSize="md"
                      borderRadius="full"
                      w="32px"
                      h="32px"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      p={0}
                      border="2px solid white"
                      boxShadow="sm"
                    >
                      {getTypeConfig(group.type).emoji}
                    </Badge>
                  </Box>

                  <VStack align="start" spacing={2} flex={1}>
                    <HStack spacing={2} align="center">
                      <Text
                        fontSize="xl"
                        fontWeight="bold"
                      >
                        {group.fullName}
                      </Text>
                      {group.verified && (
                        <Icon as={FaCheckCircle} color="blue.500" boxSize={5} />
                      )}
                    </HStack>
                    <Text
                      fontSize="sm"
                      color={textColorSecondary}
                    >
                      @{group.username}
                    </Text>
                    <HStack spacing={2}>
                      <Icon as={FaMapMarkerAlt} color="gray.500" boxSize={4} />
                      <Text fontSize="sm" color={textColorSecondary}>
                        {group.location || "London"}
                      </Text>
                    </HStack>
                  </VStack>
                </Flex>

                {/* Description */}
                <Box>
                  <ReadMoreText text={group.bio} maxLines={4} />
                </Box>

                {/* Social Links */}
                <HStack spacing={3} justify="center">
                  {group.locals && (
                    <IconButton
                      icon={<LocalsIcon size={24} />}
                      variant="solid"
                      bg="yellow"        // Changed back to yellow
                      color="black"         // Changed from yellow.500
                      size="lg"
                      borderRadius="xl"
                      onClick={(e) => {
                        e.stopPropagation();
                        window.open(group.locals, '_blank');
                      }}
                      _hover={{
                        transform: "scale(1.05)",
                        bg: "blackAlpha.100",  // Changed from yellow.100
                      }}
                      _active={{
                        transform: "scale(0.95)",
                        bg: "blackAlpha.200",  // Changed from yellow.200
                      }}
                    />
                  )}
                  {group.website && (
                    <IconButton
                      icon={<FaGlobe size={24} />}
                      variant="solid"
                      bg="gray.50"
                      color="gray.500"
                      size="lg"
                      borderRadius="xl"
                      onClick={(e) => {
                        e.stopPropagation();
                        window.open(group.website, '_blank');
                      }}
                      _hover={{
                        transform: "scale(1.05)",
                        bg: "gray.100",
                      }}
                      _active={{
                        transform: "scale(0.95)",
                        bg: "gray.200",
                      }}
                    />
                  )}
                  {group.meetup && (
                    <IconButton
                      icon={<SiMeetup size={24} />}
                      variant="solid"
                      bg="red.50"
                      color="red.500"
                      size="lg"
                      borderRadius="xl"
                      onClick={(e) => {
                        e.stopPropagation();
                        window.open(group.meetup, '_blank');
                      }}
                      _hover={{
                        transform: "scale(1.05)",
                        bg: "red.100",
                      }}
                      _active={{
                        transform: "scale(0.95)",
                        bg: "red.200",
                      }}
                    />
                  )}
                  {group.instagram && (
                    <IconButton
                      icon={<FaInstagram size={24} />}
                      variant="solid"
                      bg="pink.50"
                      color="pink.500"
                      size="lg"
                      borderRadius="xl"
                      onClick={(e) => {
                        e.stopPropagation();
                        window.open(group.instagram, '_blank');
                      }}
                      _hover={{
                        transform: "scale(1.05)",
                        bg: "pink.100",
                      }}
                      _active={{
                        transform: "scale(0.95)",
                        bg: "pink.200",
                      }}
                    />
                  )}
                  {group.whatsapp && (
                    <IconButton
                      icon={<FaWhatsapp size={24} />}
                      variant="solid"
                      bg="green.50"
                      color="green.500"
                      size="lg"
                      borderRadius="xl"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleWhatsAppClick(e, group.whatsapp);
                      }}
                      _hover={{
                        transform: "scale(1.05)",
                        bg: "green.100",
                      }}
                      _active={{
                        transform: "scale(0.95)",
                        bg: "green.200",
                      }}
                    />
                  )}
                  {group.facebook && (
                    <IconButton
                      icon={<FaFacebook size={24} />}
                      variant="solid"
                      bg="blue.50"
                      color="blue.500"
                      size="lg"
                      borderRadius="xl"
                      onClick={(e) => {
                        e.stopPropagation();
                        window.open(group.facebook, '_blank');
                      }}
                      _hover={{
                        transform: "scale(1.05)",
                        bg: "blue.100",
                      }}
                      _active={{
                        transform: "scale(0.95)",
                        bg: "blue.200",
                      }}
                    />
                  )}
                </HStack>

                {/* Action Buttons */}
                <HStack spacing={2} pt={2}>
                  <Button
                    flex={1}
                    leftIcon={<FaShareAlt />}
                    variant="ghost"
                    size="sm"
                    onClick={() => {
                      if (navigator.share) {
                        navigator.share({
                          title: group.fullName,
                          text: group.bio,
                          url: window.location.href
                        }).catch(console.error);
                      } else {
                        const shareText = `${group.fullName}\n${group.bio}\n${window.location.href}`;
                        navigator.clipboard.writeText(shareText).then(() => {
                          toast({
                            title: "Link copied",
                            description: "Share link has been copied to clipboard",
                            status: "success",
                            duration: 2000,
                            isClosable: true,
                          });
                        });
                      }
                      onClose();
                    }}
                  >
                    Share
                  </Button>
                  <Button
                    flex={1}
                    leftIcon={<FaFlag />}
                    variant="ghost"
                    size="sm"
                    onClick={() => handleAction('report')}
                    color="orange.500"
                  >
                    Report
                  </Button>
                  <Button
                    flex={1}
                    leftIcon={<FaBan />}
                    variant="ghost"
                    size="sm"
                    onClick={() => handleAction('block')}
                    color="red.500"
                  >
                    Block
                  </Button>
                </HStack>
              </VStack>
            </ModalBody>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

const PromoCard = ({ children, ...props }) => (
  <Box
    bg="linear-gradient(135deg, #1a1a1a 0%, #2d2d2d 100%)"
    p={6}
    borderRadius="2xl"
    borderWidth="1px"
    borderColor="whiteAlpha.100"
    shadow="xl"
    w="full"
    position="relative"
    overflow="hidden"
    _before={{
      content: '""',
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      bg: "linear-gradient(120deg, whiteAlpha.100 0%, transparent 100%)",
      opacity: 0.1,
    }}
    _hover={{
      transform: "translateY(-2px)",
      shadow: "2xl",
    }}
    {...props}
  >
    {children}
  </Box>
);

const GroupsListPage = () => {
  const colors = {
    primaryGradient: "linear-gradient(to right, #B8A080, #927A5F)",
    bgColor: useColorModeValue("white", "gray.900"),
    borderColor: useColorModeValue("gray.200", "gray.700"),
  };

  const bgColor = useColorModeValue("gray.50", "gray.900");
  const cardBgColor = useColorModeValue("white", "gray.800");
  const headerBgColor = useColorModeValue('rgba(255, 255, 255, 0.9)', 'rgba(26, 32, 44, 0.9)');
  const borderColor = useColorModeValue('gray.200', 'gray.700');
  const textColorSecondary = useColorModeValue('gray.600', 'gray.300');
  const textColorTertiary = useColorModeValue('gray.600', 'gray.400');
  const cardBorderColor = useColorModeValue('gray.100', 'gray.700');
  const avatarBorderColor = useColorModeValue('white', 'gray.800');
  
  const socialLinkStyles = {
    bg: useColorModeValue('white', 'gray.800'),
    borderColor: useColorModeValue('gray.100', 'gray.700'),
    backdropFilter: "blur(8px)",
    borderRadius: "2xl",
    p: 2,
    boxShadow: "lg",
    borderWidth: "1px",
  };

  const { 
    isOpen: isFilterOpen, 
    onOpen: onFilterOpen, 
    onClose: onFilterClose 
  } = useDisclosure();
  
  const { 
    isOpen: isCreateOpen, 
    onOpen: onCreateOpen, 
    onClose: onCreateClose 
  } = useDisclosure();

  const { 
    isOpen: isDetailsOpen, 
    onOpen: onDetailsOpen, 
    onClose: onDetailsClose 
  } = useDisclosure();
  const [selectedUsername, setSelectedUsername] = useState(null);

  // Use refs to persist data between renders
  const scrollPositionRef = React.useRef(0);
  const groupsDataRef = React.useRef({
    groups: [],
    filteredGroups: [],
    filters: {
      type: '',
      verified: false,
      search: '',
      hasInstagram: false,
      hasWhatsapp: false,
      hasFacebook: false,
      hasWebsite: false,
      location: 'London',
      sort: 'popularity',
    }
  });

  const [groups, setGroups] = useState(groupsDataRef.current.groups);
  const [filteredGroups, setFilteredGroups] = useState(groupsDataRef.current.filteredGroups);
  const [filters, setFilters] = useState({
    search: '',
    type: '',
    verified: false,
    location: 'London',     // Set default location to London
    sort: 'popularity',     // Set default sort to popularity
    hasInstagram: false,
    hasWhatsapp: false,
    hasFacebook: false,
    hasWebsite: false,
    hasMeetup: false,
    hasLocals: false,
  });

  // Save data to ref when it changes
  useEffect(() => {
    groupsDataRef.current = {
      groups,
      filteredGroups,
      filters
    };
  }, [groups, filteredGroups, filters]);

  // Save scroll position before unmounting
  useEffect(() => {
    return () => {
      scrollPositionRef.current = window.scrollY;
    };
  }, []);

  // Restore scroll position after mounting
  useEffect(() => {
    if (scrollPositionRef.current > 0) {
      window.scrollTo(0, scrollPositionRef.current);
    }
  }, []);

  // Add loading state
  const [isLoading, setIsLoading] = useState(true);

  // Update the fetchGroups function
  useEffect(() => {
    const fetchGroups = async () => {
      if (groups.length === 0) {
        setIsLoading(true);
        try {
          const groupsCollection = collection(firestore, 'groups');
          const groupsSnapshot = await getDocs(groupsCollection);
          const groupsList = groupsSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
          setGroups(groupsList);
        } catch (error) {
          console.error('Error fetching groups:', error);
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchGroups();
  }, [groups.length]);

  const getTypeConfig = (type) => {
    const configs = {
      'locals': {
        emoji: '🏘️',
        color: 'blue'
      },
      'drinks': {
        emoji: '🍻',
        color: 'orange'
      },
      'games': {
        emoji: '🎮',
        color: 'purple'
      },
      'gigs': {
        emoji: '🎸',
        color: 'red'
      },
      'sports': {
        emoji: '⚽',
        color: 'green'
      },
      'arts': {
        emoji: '🎨',
        color: 'pink'
      },
      'dating': {
        emoji: '❤️',
        color: 'red'
      },
      'coffee': {
        emoji: '☕',
        color: 'brown'
      },
      'club': {
        emoji: '🎉',
        color: 'purple'
      },
      'women': {
        emoji: '👩',
        color: 'pink'
      },
      'running': {
        emoji: '🏃',
        color: 'green'
      },
      'travel': {
        emoji: '✈️',
        color: 'blue'
      },
      'languages': {
        emoji: '🗣️',
        color: 'teal'
      }
    };
    
    return configs[type?.toLowerCase()] || { emoji: '👥', color: 'gray' };
  };

  const handleFilterChange = (field, value) => {
    const newFilters = {
      ...filters,
      [field]: value
    };
    setFilters(newFilters);
    
    let result = [...groups];

    if (newFilters.search?.trim()) {
      const searchTerms = newFilters.search.toLowerCase().trim().split(' ');
      result = result.filter(group => {
        const searchableText = `
          ${group.fullName || ''} 
          ${group.bio || ''} 
          ${group.username || ''}
        `.toLowerCase();
        
        return searchTerms.every(term => searchableText.includes(term));
      });
    }

    if (newFilters.type) {
      result = result.filter(group => group.type?.toLowerCase() === newFilters.type.toLowerCase());
    }

    if (newFilters.verified) {
      result = result.filter(group => group.verified === true);
    }

    if (newFilters.hasInstagram) {
      result = result.filter(group => !!group.instagram);
    }
    if (newFilters.hasWhatsapp) {
      result = result.filter(group => !!group.whatsapp);
    }
    if (newFilters.hasFacebook) {
      result = result.filter(group => !!group.facebook);
    }
    if (newFilters.hasWebsite) {
      result = result.filter(group => !!group.website);
    }

    if (newFilters.location) {
      result = result.filter(group => 
        (group.location || 'London') === newFilters.location
      );
    }

    if (newFilters.sort) {
      const sortConfig = SORT_OPTIONS.find(option => option.id === newFilters.sort);
      if (sortConfig) {
        result.sort((a, b) => {
          const aValue = a[sortConfig.field] || 0;
          const bValue = b[sortConfig.field] || 0;
          return sortConfig.order === 'desc' ? bValue - aValue : aValue - bValue;
        });
      }
    }

    setFilteredGroups(result);
  };

  const applyFilters = () => {
    handleFilterChange('', ''); // This will re-apply all current filters
    onFilterClose();
  };

  const clearFilters = () => {
    setFilters({
      type: '',
      verified: false,
      search: '',
      hasInstagram: false,
      hasWhatsapp: false,
      hasFacebook: false,
      hasWebsite: false,
      location: 'London',
      sort: 'popularity',
    });
    setFilteredGroups([...groups].sort((a, b) => (b.createdAt || 0) - (a.createdAt || 0)));
  };

  const getFilterStyle = (filterType) => {
    // Handle social media filters
    if (filterType.startsWith('has')) {
      const socialType = filterType.replace('has', '').toLowerCase();
      const socialStyles = {
        instagram: {
          bg: useColorModeValue('pink.50', 'pink.900'),
          color: useColorModeValue('pink.500', 'pink.200'),
        },
        whatsapp: {
          bg: useColorModeValue('green.50', 'green.900'),
          color: useColorModeValue('green.500', 'green.200'),
        },
        facebook: {
          bg: useColorModeValue('facebook.50', 'facebook.900'),
          color: useColorModeValue('facebook.500', 'facebook.200'),
        },
        website: {
          bg: useColorModeValue('cyan.50', 'cyan.900'),
          color: useColorModeValue('cyan.500', 'cyan.200'),
        }
      };
      return socialStyles[socialType];
    }

    // Other filter types
    const filterStyles = {
      search: {
        bg: useColorModeValue('purple.50', 'purple.900'),
        color: useColorModeValue('purple.500', 'purple.200'),
      },
      type: {
        bg: useColorModeValue('blue.50', 'blue.900'),
        color: useColorModeValue('blue.500', 'blue.200'),
      },
      verified: {
        bg: useColorModeValue('green.50', 'green.900'),
        color: useColorModeValue('green.500', 'green.200'),
      },
      location: {
        bg: useColorModeValue('orange.50', 'orange.900'),
        color: useColorModeValue('orange.500', 'orange.200'),
      },
      sort: {
        bg: useColorModeValue('gray.50', 'gray.700'),
        color: useColorModeValue('gray.600', 'gray.200'),
      }
    };

    // Return matching style or default
    return filterStyles[filterType] || {
      bg: useColorModeValue('gray.50', 'gray.700'),
      color: useColorModeValue('gray.600', 'gray.200'),
    };
  };

  const renderFilterTags = () => {
    const activeTags = [];

    if (filters.search) {
      activeTags.push({
        type: 'search',
        label: (
          <Flex align="center" gap={1}>
            <Text>🔍</Text>
            <Text>{filters.search}</Text>
          </Flex>
        ),
        onRemove: () => handleFilterChange('search', '')
      });
    }

    if (filters.type) {
      activeTags.push({
        type: 'type',
        label: (
          <Flex align="center" gap={1}>
            <Text>{getTypeConfig(filters.type).emoji}</Text>
            <Text>{filters.type.charAt(0).toUpperCase() + filters.type.slice(1)}</Text>
          </Flex>
        ),
        onRemove: () => handleFilterChange('type', '')
      });
    }

    if (filters.verified) {
      activeTags.push({
        type: 'verified',
        label: (
          <Flex align="center" gap={1}>
            <Text>✓</Text>
            <Text>Verified Only</Text>
          </Flex>
        ),
        onRemove: () => handleFilterChange('verified', false)
      });
    }

    SOCIAL_FILTERS.forEach(({ id, icon: Icon, label }) => {
      if (filters[id]) {
        activeTags.push({
          type: id,
          label: (
            <Flex align="center" gap={1}>
              <Icon />
              <Text>{label}</Text>
            </Flex>
          ),
          onRemove: () => handleFilterChange(id, false)
        });
      }
    });

    if (filters.location) {
      activeTags.push({
        type: 'location',
        label: (
          <Flex align="center" gap={1}>
            <Text>📍</Text>
            <Text>{filters.location}</Text>
          </Flex>
        ),
        onRemove: () => handleFilterChange('location', '')
      });
    }

    if (filters.sort) {
      const sortOption = SORT_OPTIONS.find(option => option.id === filters.sort);
      if (sortOption) {
        activeTags.push({
          type: 'sort',
          label: (
            <Flex align="center" gap={1}>
              <Text>{sortOption.label}</Text>
            </Flex>
          ),
          onRemove: () => handleFilterChange('sort', '')
        });
      }
    }

    if (activeTags.length === 0) return null;

    return activeTags.length > 0 ? (
      <Flex 
        gap={{ base: 1, md: 1.5 }}
        flexWrap="wrap" 
        pt={{ base: 1, md: 2 }}
        pb={{ base: 1, md: 1.5 }}
      >
        {activeTags.map((tag, index) => {
          const filterType = tag.type;
          const style = getFilterStyle(filterType);
          
          return (
            <Badge
              key={index}
              display="flex"
              alignItems="center"
              gap={{ base: 1, md: 1.5 }}
              px={{ base: 1.5, md: 2.5 }}
              py={{ base: 0.5, md: 1 }}
              borderRadius="full"
              bg={style.bg}
              color={style.color}
              fontSize={{ base: 'xs', md: 'sm' }}
              fontWeight="medium"
              userSelect="none"
              boxShadow="sm"
              _hover={{
                transform: 'translateY(-1px)',
                boxShadow: 'md',
                opacity: 0.9
              }}
              transition="all 0.2s"
            >
              {tag.label}
              <Icon
                as={FaTimesCircle}
                boxSize={{ base: "10px", md: "12px" }}
                cursor="pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  tag.onRemove();
                }}
                opacity={0.6}
                _hover={{ opacity: 1 }}
                ml={0.5}
              />
            </Badge>
          );
        })}
      </Flex>
    ) : null;
  };

  const handleAction = async (type, groupId, groupName) => {
    try {
      const collectionName = type === 'report' ? 'reports' : 'blocks';
      await addDoc(collection(firestore, collectionName), {
        groupId,
        groupName,
        userId: 'anonymous', // Replace with actual user ID when auth is implemented
        createdAt: Date.now(),
        type: type,
        status: 'pending'
      });

      toast({
        title: type === 'report' ? 'Report Submitted' : 'Group Blocked',
        description: type === 'report' 
          ? 'Thank you for helping keep our community safe.' 
          : 'You won\'t see this group anymore.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error(`Error ${type}ing group:`, error);
      toast({
        title: 'Error',
        description: `Failed to ${type} group. Please try again.`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleGroupClick = (group) => {
    setSelectedUsername(group.username);
    onDetailsOpen();
  };

  // Add the skeleton component for groups
  const GroupSkeleton = () => (
    <Card
      bg={cardBgColor}
      shadow="lg"
      position="relative"
      pb={16}
      borderWidth="1px"
      borderColor={cardBorderColor}
      borderRadius="xl"
      overflow="hidden"
    >
      <CardBody pt={6}>
        <Flex gap={5}>
          <Skeleton
            width="96px"
            height="96px"
            borderRadius="xl"
          />
          <VStack align="start" flex={1} spacing={3}>
            <Skeleton height="24px" width="70%" />
            <Skeleton height="20px" width="40%" />
            <Skeleton height="20px" width="90%" />
            <HStack spacing={2}>
              <Skeleton height="24px" width="24px" borderRadius="full" />
              <Skeleton height="24px" width="24px" borderRadius="full" />
              <Skeleton height="24px" width="24px" borderRadius="full" />
            </HStack>
          </VStack>
        </Flex>
      </CardBody>
    </Card>
  );

  // Move hooks to component level
  const { user } = useAuthStore();
  const { isOpen: isAuthOpen, onOpen: onAuthOpen, onClose: onAuthClose } = useDisclosure();
  const [selectedWhatsappLink, setSelectedWhatsappLink] = useState(null);

  const handleWhatsAppClick = (e, whatsappLink) => {
    e.stopPropagation();
    if (user) {
      window.open(whatsappLink, '_blank');
    } else {
      setSelectedWhatsappLink(whatsappLink);
      onAuthOpen();
    }
  };

  const renderSocialLinks = (group, styles = {}) => (
    <>
      {group.locals && (
        <IconButton
          icon={<LocalsIcon size={16} />}
          aria-label="Locals group"
          variant="ghost"
          bg="yellow"        // Changed back to yellow
          color="black"   
          onClick={(e) => {
            e.stopPropagation();
            window.open(group.locals, '_blank');
          }}
          {...styles}
        />
      )}
      {group.website && (
        <IconButton
          icon={<FaGlobe size={16} />}
          aria-label="Website"
          variant="ghost"
          color="gray.600"
          onClick={(e) => {
            e.stopPropagation();
            window.open(group.website, '_blank');
          }}
          {...styles}
        />
      )}
      {group.meetup && (
        <IconButton
          icon={<SiMeetup size={16} />}
          aria-label="Meetup group"
          variant="ghost"
          color="red.500"
          onClick={(e) => {
            e.stopPropagation();
            window.open(group.meetup, '_blank');
          }}
          {...styles}
        />
      )}
      {group.instagram && (
        <IconButton
          icon={<FaInstagram size={16} />}
          aria-label="Instagram"
          variant="ghost"
          color="pink.500"
          onClick={(e) => {
            e.stopPropagation();
            window.open(group.instagram, '_blank');
          }}
          {...styles}
        />
      )}
      {group.whatsapp && (
        <IconButton
          icon={<FaWhatsapp size={16} />}
          aria-label="WhatsApp"
          variant="ghost"
          color="green.500"
          onClick={(e) => {
            e.stopPropagation();
            handleWhatsAppClick(e, group.whatsapp);
          }}
          {...styles}
        />
      )}
      {group.facebook && (
        <IconButton
          icon={<FaFacebook size={16} />}
          aria-label="Facebook"
          variant="ghost"
          color="blue.500"
          onClick={(e) => {
            e.stopPropagation();
            window.open(group.facebook, '_blank');
          }}
          {...styles}
        />
      )}
    </>
  );

  const [currentCard, setCurrentCard] = useState(0);
  const [touchStart, setTouchStart] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);

  const handleTouchStart = (e) => setTouchStart(e.touches[0].clientX);
  const handleTouchMove = (e) => setTouchEnd(e.touches[0].clientX);
  const handleTouchEnd = () => {
    if (touchStart - touchEnd > 75) setCurrentCard((curr) => (curr < 1 ? curr + 1 : curr));
    if (touchStart - touchEnd < -75) setCurrentCard((curr) => (curr > 0 ? curr - 1 : curr));
  };

  const [showPromoCard, setShowPromoCard] = useState(() => {
    return localStorage.getItem('hidePromoCard') !== 'true';
  });

  const handleDismissPromo = (e) => {
    e.stopPropagation(); // Prevent triggering the card's onClick
    setShowPromoCard(false);
    localStorage.setItem('hidePromoCard', 'true');
  };

  // Update the useEffect that handles filtering to ensure it applies on initial load
  useEffect(() => {
    const applyInitialFilters = async () => {
      let filtered = [...groups];

      // Apply search filter
      if (filters.search) {
        const searchTerm = filters.search.toLowerCase();
        filtered = filtered.filter(group => 
          group.fullName?.toLowerCase().includes(searchTerm) ||
          group.username?.toLowerCase().includes(searchTerm) ||
          group.bio?.toLowerCase().includes(searchTerm)
        );
      }

      // Apply type filter
      if (filters.type) {
        filtered = filtered.filter(group => group.type === filters.type);
      }

      // Apply location filter only if group has location field
      if (filters.location) {
        filtered = filtered.filter(group => !group.location || group.location === filters.location);
      }

      // Apply social media filters
      if (filters.hasInstagram) {
        filtered = filtered.filter(group => group.instagram);
      }
      if (filters.hasWhatsapp) {
        filtered = filtered.filter(group => group.whatsapp);
      }
      if (filters.hasFacebook) {
        filtered = filtered.filter(group => group.facebook);
      }
      if (filters.hasWebsite) {
        filtered = filtered.filter(group => group.website);
      }
      if (filters.hasMeetup) {
        filtered = filtered.filter(group => group.meetup);
      }
      if (filters.hasLocals) {
        filtered = filtered.filter(group => group.locals);
      }

      // Apply sort by popularity (users field)
      if (filters.sort === 'popularity') {
        filtered.sort((a, b) => {
          const usersA = parseInt(a.users) || 0;
          const usersB = parseInt(b.users) || 0;
          return usersB - usersA;
        });
      } else if (filters.sort === 'recent') {
        filtered.sort((a, b) => b.createdAt - a.createdAt);
      }

      setFilteredGroups(filtered);
    };

    applyInitialFilters();
  }, [groups, filters]);

  // Add this helper function to get all available social links for a group
  const getAvailableSocialLinks = (group) => {
    const links = [];
    if (group.locals) links.push({ type: 'locals', icon: LocalsIcon, color: 'black', bg: 'yellow' });
    if (group.website) links.push({ type: 'website', icon: FaGlobe, color: 'gray.500', bg: 'gray.50' });
    if (group.meetup) links.push({ type: 'meetup', icon: SiMeetup, color: 'red.500', bg: 'red.50' });
    if (group.instagram) links.push({ type: 'instagram', icon: FaInstagram, color: 'pink.500', bg: 'pink.50' });
    if (group.whatsapp) links.push({ type: 'whatsapp', icon: FaWhatsapp, color: 'green.500', bg: 'green.50' });
    if (group.facebook) links.push({ type: 'facebook', icon: FaFacebook, color: 'blue.500', bg: 'blue.50' });
    return links;
  };

  return (
    <Box 
      w="100%" 
      minH="100vh" 
      bg={bgColor} 
      overflowX="hidden"
      pb={{ base: 20, md: 6 }}  // Add padding for bottom nav
    >
      {/* Sticky Header */}
      <Box 
        position="fixed"
        top={0}
        left={0}
        right={0}
        bg={headerBgColor}
        backdropFilter="blur(10px)"
        borderBottom="1px solid"
        borderColor={borderColor}
        zIndex={10}
      >
        <Box px={4} py={4}>
          <Flex 
            gap={3} 
            direction="row"
            align="center"
            maxW={{ base: "100%", md: "container.xl" }}
            mx="auto"
          >
            <Box display={{ base: "block", md: "none" }} flexShrink={0}>
              <Flex align="center">
                <Box
                  bg="gray.900"
                  px={0.5}
                  py={0.25}
                  borderRadius="md"
                  position="relative"
                  mr={-0.5}
                >
                  <Text
                    color="white"
                    fontWeight="black"
                    fontSize="md"
                    letterSpacing="tight"
                  >
                    IRL
                  </Text>
                </Box>
                <Text
                  fontSize="md"
                  fontWeight="black"
                  color={useColorModeValue('gray.900', 'white')}
                  letterSpacing="tight"
                  ml={0.5}
                >
                  REV
                </Text>
              </Flex>
            </Box>

            <InputGroup size={{ base: "md", md: "md" }} flex={1}>
              <InputLeftElement pointerEvents="none">
                <Icon as={FaSearch} color="gray.400" />
              </InputLeftElement>
              <Input
                placeholder="Search groups..."
                value={filters.search}
                onChange={(e) => handleFilterChange('search', e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.target.blur(); // This will dismiss the keyboard on mobile
                  }
                }}
                bg={cardBgColor}
                borderRadius="full"
                pl={10}
                pr={{ 
                  base: filters.search ? "8rem" : "4rem", 
                  md: filters.search ? "8.5rem" : "4.5rem" 
                }}
                borderWidth="2px"
                borderColor={useColorModeValue('gray.200', 'gray.700')}
                _hover={{
                  borderColor: useColorModeValue('gray.300', 'gray.600'),
                }}
                _focus={{
                  borderColor: 'blue.500',
                  boxShadow: '0 0 0 1px var(--chakra-colors-blue-500)',
                }}
                fontSize="md"
                boxShadow="sm"
              />
              <InputRightElement 
                width={{ 
                  base: filters.search ? "8rem" : "4rem", 
                  md: filters.search ? "8.5rem" : "4.5rem" 
                }}
                pr={0}
              >
                <HStack spacing={1} pr={1}>
                  {filters.search && (
                    <Button
                      h="1.75rem"
                      size="sm"
                      variant="ghost"
                      colorScheme="gray"
                      onClick={() => handleFilterChange('search', '')}
                    >
                      Clear
                    </Button>
                  )}
                  <Button
                    size="sm"
                    colorScheme="blue"
                    onClick={onFilterOpen}
                    px={2}
                    borderRadius="full"
                    boxShadow="sm"
                    _hover={{
                      transform: 'translateY(-1px)',
                      boxShadow: 'md',
                    }}
                    transition="all 0.2s"
                  >
                    <Icon as={FaFilter} />
                    {Object.entries(filters).filter(([key, value]) => {
                      if (key === 'location' && value === 'London') return true;
                      if (key === 'sort' && value === 'popularity') return true;
                      if (typeof value === 'boolean' && value === true) return true;
                      if (typeof value === 'string' && value !== '') return true;
                      return false;
                    }).length > 0 && (
                      <Badge
                        ml={1}
                        colorScheme="white"
                        bg="whiteAlpha.300"
                        borderRadius="full"
                        px={1.5}
                        minW={4}
                        display="flex"
                        justifyContent="center"
                      >
                        {Object.entries(filters).filter(([key, value]) => {
                          if (key === 'location' && value === 'London') return true;
                          if (key === 'sort' && value === 'popularity') return true;
                          if (typeof value === 'boolean' && value === true) return true;
                          if (typeof value === 'string' && value !== '') return true;
                          return false;
                        }).length}
                      </Badge>
                    )}
                  </Button>
                </HStack>
              </InputRightElement>
            </InputGroup>
          </Flex>
        </Box>
      </Box>

      {/* Main Content */}
      <Box 
        pt={{ base: "72px", md: "80px" }}  // Adjust based on header height
        px={4}
        maxW={{ base: "100%", md: "container.xl" }}
        mx="auto"
      >
        {/* Promo Card */}
        {showPromoCard && (
          <Box mb={6}>
            <PromoCard
              onClick={() => window.open('https://hubzo.app', '_blank')}
              cursor="pointer"
              _hover={{
                transform: "translateY(-2px)",
                shadow: "2xl",
              }}
              transition="all 0.2s"
            >
              <Flex
                position="absolute"
                top={0}
                left={0}
                right={0}
                px={6}
                py={3}
                justify="space-between"
                align="center"
                borderTopRadius="2xl"
                bg="blackAlpha.300"
                backdropFilter="blur(7px)"
              >
                <Badge
                  fontSize="xs"
                  textTransform="none"
                  bg="whiteAlpha.200"
                  color="whiteAlpha.900"
                  borderRadius="full"
                  px={2}
                  py={0.5}
                  letterSpacing="tight"
                >
                  Sponsored
                </Badge>
                
                <IconButton
                  icon={<FaTimesCircle />}
                  size="xs"
                  variant="ghost"
                  color="whiteAlpha.800"
                  _hover={{ bg: "whiteAlpha.200" }}
                  onClick={handleDismissPromo}
                  aria-label="Dismiss promotion"
                />
              </Flex>

              <VStack spacing={3} align="stretch" mt={12}>
                <HStack spacing={2}>
                  <Box
                    bg="whiteAlpha.100"
                    p={1.5}
                    borderRadius="lg"
                    color="whiteAlpha.900"
                  >
                    <FaRobot size={18} />
                  </Box>
                  <Text 
                    fontWeight="black"
                    fontSize={{ base: "md", md: "xl" }}
                    color="whiteAlpha.900"
                    letterSpacing="tight"
                    textTransform="uppercase"
                  >
                    Smart Meetups
                  </Text>
                </HStack>

                <Text 
                  fontSize={{ base: "sm", md: "xl" }}
                  color="whiteAlpha.900"
                  lineHeight="tall"
                  letterSpacing="tight"
                >
                  Get invited to exclusive meetups matched by our algorithm based on{" "}
                  <Box 
                    as="span" 
                    bgGradient="linear(to-r, #FF3366, #FF9966)"
                    bgClip="text"
                    fontWeight="extrabold"
                  >
                    attractiveness
                  </Box>
                  ,{" "}
                  <Box 
                    as="span" 
                    bgGradient="linear(to-r, #FF3366, #FF9966)"
                    bgClip="text"
                    fontWeight="extrabold"
                  >
                    professional success
                  </Box>
                  , and{" "}
                  <Box 
                    as="span" 
                    bgGradient="linear(to-r, #FF3366, #FF9966)"
                    bgClip="text"
                    fontWeight="extrabold"
                  >
                    shared interests
                  </Box>
                </Text>
              </VStack>
            </PromoCard>
          </Box>
        )}

        {/* Groups List */}
        <Box w="100%">
          {isLoading ? (
            <VStack spacing={2} align="stretch">
              {[...Array(6)].map((_, index) => (
                <GroupSkeleton key={index} />
              ))}
            </VStack>
          ) : (
            <>
              {/* Desktop Card Grid */}
              <SimpleGrid 
                columns={{ base: 1, md: 2, lg: 3 }} 
                spacing={6}
                display={{ base: 'none', md: 'grid' }}
              >
                {(filteredGroups.length > 0 ? filteredGroups : groups).map((group) => (
                  <Card 
                    key={group.id}
                    bg={cardBgColor} 
                    shadow="md"
                    _hover={{ 
                      transform: 'translateY(-2px)', 
                      shadow: 'lg',
                      borderColor: 'blue.200'
                    }}
                    transition="all 0.2s ease"
                    position="relative"
                    pb={12}
                    onClick={() => handleGroupClick(group)}
                    cursor="pointer"
                    borderWidth="1px"
                    borderColor={cardBorderColor}
                    borderRadius="lg"
                    overflow="hidden"
                  >
                    <CardBody pb={0} pt={4}>
                      <Flex gap={4}>
                        <Box position="relative">
                          <Avatar 
                            size="md"
                            src={group.profilePicURL} 
                            name={group.fullName}
                            border="2px solid"
                            borderColor={avatarBorderColor}
                            shadow="md"
                          />
                          {group.verified && (
                            <Box
                              position="absolute"
                              top="-2px"
                              right="-2px"
                              bg="blue.500"
                              color="white"
                              w="20px"
                              h="20px"
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                              boxShadow="0 2px 4px rgba(0,0,0,0.15)"
                              border="2px solid white"
                              transform="rotate(-15deg)"
                              _before={{
                                content: '""',
                                position: 'absolute',
                                inset: '-2px',
                                background: 'blue.500',
                                clipPath: 'polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%)',
                              }}
                            >
                              <Icon 
                                viewBox="0 0 24 24" 
                                boxSize="10px"
                                zIndex={1}
                              >
                                <path
                                  fill="currentColor"
                                  d="M9.86 18a1 1 0 01-.73-.32l-4.86-5.17a1.001 1.001 0 011.46-1.37l4.12 4.39 8.41-9.2a1 1 0 111.48 1.34l-9.14 10a1 1 0 01-.73.33h-.01z"
                                />
                              </Icon>
                            </Box>
                          )}
                        </Box>

                        <VStack align="stretch" spacing={2} flex={1}>
                          <Box flex={1}>
                            <Text 
                              fontWeight="bold" 
                              fontSize="lg"
                              mb={0.5}
                              lineHeight="1.2"
                            >
                              {group.fullName}
                            </Text>
                            <Text 
                              fontSize="sm" 
                              color={textColorTertiary}
                              noOfLines={2}
                              lineHeight="1.5"
                              letterSpacing="0.2px"
                            >
                              {group.bio || "No description available"}
                            </Text>
                          </Box>

                          <HStack spacing={2} mt={1}>
                            <Badge 
                              colorScheme={getTypeConfig(group.type).color}
                              variant="subtle"
                              px={2} 
                              py={1}
                              borderRadius="full"
                              fontSize="xs"
                              display="flex"
                              alignItems="center"
                              gap={1}
                            >
                              {getTypeConfig(group.type).emoji} {group.type}
                            </Badge>

                            <Badge 
                              colorScheme="gray" 
                              variant="subtle"
                              px={2} 
                              py={1}
                              borderRadius="full"
                              fontSize="xs"
                              display="flex"
                              alignItems="center"
                              gap={1}
                            >
                              <Icon as={FaMapMarkerAlt} boxSize={3} />
                              {group.location || 'London'}
                            </Badge>
                          </HStack>
                        </VStack>
                      </Flex>

                      <Flex 
                        mt="auto"
                        position="absolute"
                        bottom={2}
                        left={4}
                        right={0}
                        justify="flex-end"
                        align="center"
                      >
                        <HStack 
                          spacing={1}
                          bg={cardBgColor}
                          borderColor={cardBorderColor}
                          backdropFilter="blur(8px)"
                          borderRadius="xl"
                          p={1.5}
                          boxShadow="md"
                          borderWidth="1px"
                        >
                          {renderSocialLinks(group, {
                            ...socialLinkStyles,
                            size: "sm",
                            p: 1.5,
                          })}
                          <IconButton
                            icon={<FaShareAlt size="16px" />}
                            aria-label="Share group"
                            size="sm"
                            variant="ghost"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleShare(group, toast);
                            }}
                          />
                        </HStack>
                      </Flex>
                    </CardBody>
                  </Card>
                ))}
              </SimpleGrid>

              {/* Mobile List View */}
              <VStack 
                spacing={0} 
                align="stretch"
                w="100%"
                display={{ base: 'flex', md: 'none' }}
              >
                {(filteredGroups.length > 0 ? filteredGroups : groups).map((group) => (
                  <Box
                    key={group.id}
                    bg={cardBgColor}
                    borderBottomWidth="1px"
                    borderColor={cardBorderColor}
                    w="100%"
                  >
                    <Flex 
                      gap={4}
                      px={4}
                      py={4}
                      onClick={() => handleGroupClick(group)} 
                      cursor="pointer"
                      _active={{ bg: useColorModeValue('gray.50', 'gray.700') }}
                      maxW="100%"
                    >
                      <Box 
                        position="relative" 
                        width="40px"
                        height="40px"
                        flexShrink={0}
                        style={{ isolation: 'isolate' }}
                      >
                        <Avatar 
                          size="md"
                          src={group.profilePicURL} 
                          name={group.fullName}
                        />
                        <Badge
                          position="absolute"
                          bottom="-12px"
                          right="-6px"
                          colorScheme={getTypeConfig(group.type).color}
                          fontSize="2xs"
                          borderRadius="full"
                          w="24px"
                          h="24px"
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          p={0}
                          border="2px solid white"
                          boxShadow="sm"
                        >
                          {getTypeConfig(group.type).emoji}
                        </Badge>
                      </Box>

                      <VStack flex={1} align="start" spacing={1}>
                        <Text 
                          fontWeight="semibold" 
                          fontSize="md"
                          noOfLines={2}
                        >
                          {group.fullName}
                        </Text>
                        <Text 
                          fontSize="sm" 
                          color={textColorTertiary} 
                          noOfLines={2}
                        >
                          {group.bio || "No description available"}
                        </Text>
                      </VStack>

                      <HStack spacing={2} align="center">
                        {(() => {
                          const links = getAvailableSocialLinks(group);
                          const visibleLinks = links.slice(0, 3);
                          const remainingCount = links.length - 3;

                          return (
                            <>
                              {visibleLinks.map((link) => (
                                <IconButton
                                  key={link.type}
                                  icon={<link.icon size={20} />}
                                  variant="solid"
                                  bg={link.bg}
                                  color={link.color}
                                  size="md"
                                  borderRadius="lg"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    if (link.type === 'whatsapp') {
                                      handleWhatsAppClick(e, group.whatsapp);
                                    } else {
                                      window.open(group[link.type], '_blank');
                                    }
                                  }}
                                  _hover={{
                                    transform: "scale(1.05)",
                                    bg: link.bg.replace('50', '100'),
                                  }}
                                  _active={{
                                    transform: "scale(0.95)",
                                    bg: link.bg.replace('50', '200'),
                                  }}
                                />
                              ))}
                              {remainingCount > 0 && (
                                <Box
                                  bg="gray.100"
                                  color="gray.600"
                                  px={2}
                                  py={1}
                                  borderRadius="md"
                                  fontSize="sm"
                                  fontWeight="medium"
                                >
                                  +{remainingCount}
                                </Box>
                              )}
                            </>
                          );
                        })()}
                      </HStack>
                    </Flex>
                  </Box>
                ))}
              </VStack>
            </>
          )}
        </Box>
      </Box>

      <FilterDialog 
        isOpen={isFilterOpen}
        onClose={onFilterClose}
        filters={filters}
        handleFilterChange={handleFilterChange}
        clearFilters={clearFilters}
        applyFilters={applyFilters}
        getTypeConfig={getTypeConfig}
      />

      <CreateGroupDialog 
        isOpen={isCreateOpen}
        onClose={onCreateClose}
      />

      <GroupDetailsDialog 
        isOpen={isDetailsOpen}
        onClose={onDetailsClose}
        username={selectedUsername}
        getTypeConfig={getTypeConfig}
      />

      <AuthRequiredDialog 
        isOpen={isAuthOpen} 
        onClose={onAuthClose} 
      />
    </Box>
  );
};

export default GroupsListPage; 