import { useEffect, useState } from "react";
import useAuthStore from "../store/authStore";
import useUserProfileStore from "../store/userProfileStore";
import useShowToast from "./useShowToast";
import { firestore } from "../firebase/firebase";
import {
  addDoc,
  collection,
  arrayRemove,
  arrayUnion,
  query,
  where,
  orderBy,
  or,
  doc,
  getDocs,
  updateDoc,
} from "firebase/firestore";

const useBlockUser = (userId, reporterUserId) => {
  const [isBlockUpdating, setIsBlockUpdating] = useState(false);
  const [isFollowing, setIsFollowing] = useState(false);
  const authUser = useAuthStore((state) => state.user);
  const setAuthUser = useAuthStore((state) => state.setUser);
  const { userProfile, setUserProfile } = useUserProfileStore();
  const showToast = useShowToast();

  const handleBlockUser = async () => {
    if (!authUser) {
      showToast("Error", "You must be logged in", "error");
      return;
    }

    setIsBlockUpdating(true);

    const newPost = {
      reportedUserId: userId,
      createdBy: reporterUserId,
      createdAt: Date.now(),
    };

    try {
      const postDocRef = await addDoc(
        collection(firestore, "blocked"),
        newPost
      );

      const q = query(
        collection(firestore, "blocked"),
        or(
          where("createdBy", "==", authUser.uid),
          where("reportedUserId", "==", authUser.uid)
        ),
        // where("startAt", ">", new Date()),
        orderBy("createdAt", "desc")
      );

      const querySnapshot = await getDocs(q);
      const feedPosts = [];

      querySnapshot.forEach((doc) => {
        feedPosts.push({ id: doc.id, ...doc.data() });
      });

      localStorage.setItem(
        "user-info",
        JSON.stringify({
          ...authUser,
          blocked: feedPosts,
        })
      );

      setIsBlockUpdating(false);

      // const currentUserRef = doc(firestore, "users", authUser.uid);
      // const userToFollowOrUnfollorRef = doc(firestore, "groups", userId);
      // await updateDoc(currentUserRef, {
      //   following: isFollowing ? arrayRemove(userId) : arrayUnion(userId),
      // });

      // await updateDoc(userToFollowOrUnfollorRef, {
      //   followers: isFollowing
      //     ? arrayRemove(authUser.uid)
      //     : arrayUnion(authUser.uid),
      // });

      // if (isFollowing) {
      //   // unfollow
      //   setAuthUser({
      //     ...authUser,
      //     following: authUser.following.filter((uid) => uid !== userId),
      //   });
      //   if (userProfile)
      //     setUserProfile({
      //       ...userProfile,
      //       followers: userProfile.followers.filter(
      //         (uid) => uid !== authUser.uid
      //       ),
      //     });

      //   localStorage.setItem(
      //     "user-info",
      //     JSON.stringify({
      //       ...authUser,
      //       following: authUser.following.filter((uid) => uid !== userId),
      //     })
      //   );
      //   setIsFollowing(false);
      // } else {
      //   // follow
      //   setAuthUser({
      //     ...authUser,
      //     following: [...authUser.following, userId],
      //   });

      //   if (userProfile)
      //     setUserProfile({
      //       ...userProfile,
      //       followers: [...userProfile.followers, authUser.uid],
      //     });

      //   localStorage.setItem(
      //     "user-info",
      //     JSON.stringify({
      //       ...authUser,
      //       following: [...authUser.following, userId],
      //     })
      //   );
      //   setIsFollowing(true);
      // }
      showToast("", "User blocked successfully", "success");
    } catch (error) {
      showToast("Error", error.message, "error");
    } finally {
      setIsBlockUpdating(false);
    }
  };

  // useEffect(() => {
  //   if (authUser) {
  //     const isFollowing = authUser.following.includes(userId);
  //     setIsFollowing(isFollowing);
  //   }
  // }, [authUser, userId]);

  return { isBlockUpdating, handleBlockUser };
};

export default useBlockUser;
