import { useState } from "react";
import useShowToast from "./useShowToast";
import { collection, getDocs, query, where } from "firebase/firestore";
import { firestore } from "../firebase/firebase";

const useSearchGroup = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState(null);
  const showToast = useShowToast();

  const getUserProfile = async (username) => {
    setIsLoading(true);
    setUser(null);
    try {
      let searchString = String(username).replace("@", "");

      const queryField = () => {
        if (searchString.includes("https://chat.whatsapp.com")) {
          return "whatsapp";
        } else {
          return "username";
        }
      };

      const q = query(
        collection(firestore, "groups"),
        where(queryField(), "==", searchString)
      );

      const querySnapshot = await getDocs(q);
      if (querySnapshot.empty)
        return showToast("Error", "Group not found", "error");

      querySnapshot.forEach((doc) => {
        setUser(doc.data());
      });
    } catch (error) {
      showToast("Error", error.message, "error");
      setUser(null);
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, getUserProfile, user, setUser };
};

export default useSearchGroup;
