import { useEffect, useState } from "react";
import usePostStore from "../store/postStore";
import useAuthStore from "../store/authStore";
import useShowToast from "./useShowToast";
import useUserProfileStore from "../store/userProfileStore";
import { collection, getDocs, query, where, orderBy } from "firebase/firestore";
import { firestore } from "../firebase/firebase";

const useGetGroupPageFeedPosts = (uid) => {
  const [isLoading, setIsLoading] = useState(true);
  const { posts, setPosts } = usePostStore();
  const authUser = useAuthStore((state) => state.user);
  const showToast = useShowToast();
  const { setUserProfile } = useUserProfileStore();

  useEffect(() => {
    const getPageFeedPosts = async () => {
      setIsLoading(true);
      const q = query(
        collection(firestore, "events"),
        where("createdBy", "==", uid),
        // where("startAt", ">", new Date()),
        orderBy("startAt", "desc")
      );

      try {
        const querySnapshot = await getDocs(q);
        const feedPosts = [];

        querySnapshot.forEach((doc) => {
          feedPosts.push({ id: doc.id, ...doc.data() });
        });



        let filteredFeedPosts = [];

        if (authUser && authUser.blocked) {
          let allBlockedIdsList = [];

          const youBlockedList = authUser.blocked
            .filter((blocked) => blocked.createdBy == authUser.uid)
            .map((filteredBlocked) => filteredBlocked.reportedUserId);

          // allBlockedIdsList.push(youBlockedList);

          const youWereBlockedList = authUser.blocked
            .filter((blocked) => blocked.reportedUserId == authUser.uid)
            .map((filteredBlocked) => filteredBlocked.createdBy);

          // allBlockedIdsList.push(youWereBlockedList);

          allBlockedIdsList = youBlockedList.concat(youWereBlockedList);

          let prePeopleFilteredFeedPosts = feedPosts.filter(
            (post) => allBlockedIdsList.includes(post.createdBy) == false
          );

          filteredFeedPosts = prePeopleFilteredFeedPosts.map((item) => {
            let itemz = item;
            // itemz.goingList = item.goingList.filter((itemx) => {
            //   allBlockedIdsList.includes(itemx.uid) == false;
            // });

            itemz.goingList = item.goingList.filter(
              (going) => allBlockedIdsList.includes(going.uid) == false
            );

            return itemz;
          });
        } else {
          filteredFeedPosts = feedPosts;
        }




        // feedPosts.sort((a, b) => b.createdAt - a.createdAt);
        setPosts(filteredFeedPosts
          // .reverse()
          );
      } catch (error) {
        showToast("Error", error.message, "error");
      } finally {
        setIsLoading(false);
      }
    };

    getPageFeedPosts();

    // if (authUser) getFeedPosts();
  }, [authUser, showToast, setPosts, setUserProfile]);

  return { isLoading, posts };
};

export default useGetGroupPageFeedPosts;
