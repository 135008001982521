import {
  Container,
  Flex,
  Text,
  Box,
  VStack,
  IconButton,
  Button,
  useColorModeValue,
  FormControl,
  Input,
} from "@chakra-ui/react";
import { ArrowBackIcon, CopyIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import { FaLinkedin } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import useAuthStore from "../../store/authStore";
import useGetUserProfileById from "../../hooks/useGetUserProfileById";

const LinkedinLinkPage = () => {
  const navigate = useNavigate();
  const authUser = useAuthStore((state) => state.user);
  const { isLoading, userProfile } = useGetUserProfileById(authUser?.uid);

  const colors = {
    textColor: "gray.900",
    bgColor: "white",
    cardBg: "white",
    dividerColor: "gray.200",
    iconBgColor: "rgba(184, 160, 128, 0.1)",
    hoverBgColor: "rgba(184, 160, 128, 0.05)",
    subtitleColor: "gray.600",
    accentColor: "#B8A080",
    borderColor: "rgba(184, 160, 128, 0.2)",
  };

  return (
    <Box
      h="100vh"
      maxH="100vh"
      overflow="hidden"
      position="relative"
      bg={colors.bgColor}
      pb="env(safe-area-inset-bottom)"
    >
      <Box
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        overflowY="auto"
        pb={{ base: 32, md: 8 }}
        style={{
          WebkitOverflowScrolling: 'touch'
        }}
      >
        <Container 
          maxW={{ base: "container.md", md: "container.xl" }} 
          px={4} 
          py={6}
          mb={{ base: 8, md: 0 }}
        >
          {/* Header */}
          <Flex 
            w="full" 
            alignItems="center" 
            mb={8}
            position="relative"
          >
            <IconButton
              icon={<ArrowBackIcon />}
              variant="ghost"
              aria-label="Go back"
              onClick={() => navigate(-1)}
              position={{ base: "relative", md: "absolute" }}
              left={{ md: 0 }}
              color={colors.accentColor}
              _hover={{
                bg: colors.iconBgColor,
                transform: "translateY(-2px)",
              }}
              transition="all 0.2s"
            />
            <Text 
              fontSize={{ base: "2xl", md: "3xl" }}
              fontWeight="light"
              color={colors.textColor}
              textAlign={{ base: "left", md: "center" }}
              flex="1"
              ml={{ base: 4, md: 0 }}
              letterSpacing="wide"
              fontFamily="Montserrat, sans-serif"
            >
              Verify Your LinkedIn Profile
            </Text>
          </Flex>

          {/* Main Content */}
          <Box 
            maxW={{ base: "full", md: "lg" }} 
            mx="auto"
          >
            <Box
              bg={colors.cardBg}
              borderRadius="xl"
              borderWidth="1px"
              borderColor={colors.borderColor}
              p={6}
              textAlign="center"
              shadow="sm"
              _hover={{ shadow: "md" }}
              transition="all 0.2s"
            >
              {/* User Code */}
              <VStack spacing={6} align="center">
                <Box
                  p={4}
                  bg={colors.iconBgColor}
                  borderRadius="full"
                  color={colors.accentColor}
                  transform="rotate(-5deg)"
                  _hover={{
                    transform: "rotate(0deg)",
                  }}
                  transition="all 0.3s"
                >
                  <FaLinkedin size={30} />
                </Box>

                <VStack spacing={3} px={4}>
                  <Text
                    fontSize="sm"
                    color={colors.subtitleColor}
                    fontWeight="medium"
                    letterSpacing="wide"
                  >
                    To verify your LinkedIn:
                  </Text>
                  <Text
                    fontSize="sm"
                    color={colors.textColor}
                    textAlign="center"
                  >
                    Message your code{' '}
                    <Text
                      as="span"
                      fontWeight="bold"
                      color={colors.accentColor}
                      bg={colors.iconBgColor}
                      px={3}
                      py={1}
                      rounded="md"
                      cursor="pointer"
                      userSelect="all"
                      display="inline-flex"
                      alignItems="center"
                      gap={2}
                      _hover={{
                        bg: colors.hoverBgColor,
                      }}
                      onClick={(e) => {
                        navigator.clipboard.writeText(userProfile?.userCode || 'XXXXX');
                        showToast("Success", "Code copied to clipboard!", "success");
                      }}
                    >
                      {isLoading ? "Loading..." : userProfile?.userCode || "XXXXX"}
                      <CopyIcon boxSize={3} />
                    </Text>
                    {' '}to{' '}
                    <Text
                      as="span"
                      fontWeight="bold"
                      cursor="pointer"
                      display="inline-flex"
                      alignItems="center"
                      gap={1}
                      color={colors.accentColor}
                      _hover={{
                        textDecoration: "underline",
                      }}
                      onClick={() => window.open("https://www.linkedin.com/company/hubzo", "_blank")}
                    >
                      LinkedIn /company/hubzo
                      <ExternalLinkIcon boxSize={3} />
                    </Text>
                  </Text>
                </VStack>
              </VStack>
            </Box>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default LinkedinLinkPage;
