import { useState } from "react";
import useAuthStore from "../store/authStore";
import useShowToast from "./useShowToast";
import { getDownloadURL, ref, uploadString } from "firebase/storage";
import { firestore, storage } from "../firebase/firebase";
import { doc, updateDoc } from "firebase/firestore";
import useUserProfileStore from "../store/userProfileStore";

const useEditEventPic = () => {
  const [isUpdating, setIsUpdating] = useState(false);

  const authUser = useAuthStore((state) => state.user);
  const setAuthUser = useAuthStore((state) => state.setUser);
  const setUserProfile = useUserProfileStore((state) => state.setUserProfile);

  const showToast = useShowToast();

  const editProfile = async (selectedFile) => {
    // console.log("handleEditProfile editProfile")
    // console.log("handleEditProfile editProfile" + selectedFile)

    if (isUpdating 
      // || !authUser
    ) return;
    setIsUpdating(true);

    const inputId = crypto.randomUUID();
    // console.log("inputId" + inputId)


    const storageRef = ref(storage, `profilePics/${inputId}`);
    // const userDocRef = doc(firestore, "users", authUser.uid);

    let URL = "";

    // console.log("URL" + URL)
    try {
      if (selectedFile) {
        await uploadString(storageRef, selectedFile, "data_url");
        URL = await getDownloadURL(ref(storage, `profilePics/${inputId}`));
      }


      // const updatedUser = {
      //   ...authUser,
      //   fullName: inputs.fullName,
      //   username: inputs.username,
      //   bio: inputs.bio,
      //   profilePicURL: URL || authUser.profilePicURL,
      //   whatsapp: inputs.whatsapp,
      //   instagram: instagramz(),
      //   linkedin: inputs.linkedin,
      //   job: inputs.job,
      //   hidden: false,
      //   interests: inputs.interests,
      //   yob: inputs.yob
      // };

      // await updateDoc(userDocRef, updatedUser);
      // localStorage.setItem("user-info", JSON.stringify(updatedUser));

      // console.log("URL1" + URL)

      localStorage.setItem("event-pic", URL);


      // setAuthUser(updatedUser);
      // setUserProfile(updatedUser);
      // showToast("Success", "Profile updated successfully", "success");
    } catch (error) {
      // console.log("URLError" + error)

      showToast("Error", error.message, "error");
    }
  };

  return { editProfile, isUpdating };
};

export default useEditEventPic;
