import {
  Container,
  Flex,
  Button,
  VStack,
  FormControl,
  Input,
  Text,
  IconButton,
  Box,
  Image,
  Progress,
  HStack,
} from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";
import useEditEventPic from "../../hooks/useEditEventPic";
import usePreviewImg from "../../hooks/usePreviewImg";

const CreateTitlePage = () => {
  const [eventTitle, setEventTitle] = useState(
    localStorage.getItem("eventTitle") || ""
  );
  const fileRef = useRef(null);
  const navigate = useNavigate();
  const { handleImageChange, selectedFile, setSelectedFile } = usePreviewImg();
  const { isUpdating, editProfile } = useEditEventPic();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleNext = () => {
    localStorage.setItem("eventTitle", eventTitle);
    navigate("/create_event_picture");
  };

  return (
    <Box
      position="fixed"
      top={{ base: 0, md: "64px" }}
      left={0}
      right={0}
      bottom={0}
      bg="white"
      zIndex={1000}
      overflowY="auto"
    >
      <Container
        maxW="container.sm"
        py={4}
        display="flex"
        flexDirection="column"
        minH={{ base: "100vh", md: "calc(100vh - 64px)" }}
      >
        {/* Header with Progress - Visible on all screens */}
        <Flex w="full" align="center" mb={6}>
          <IconButton
            icon={<ArrowBackIcon />}
            variant="ghost"
            aria-label="Go back"
            onClick={() => navigate(-1)}
            color="gray.500"
            _hover={{ color: "#4F46E5" }}
          />
          <Progress
            value={12.5}
            size="sm"
            colorScheme="purple"
            flex="1"
            mx={4}
            borderRadius="full"
            bg="gray.100"
          />
        </Flex>

        {/* Rest of the content stays the same */}
        <VStack spacing={8} align="center" w="full" flex="1">
          <VStack spacing={3} textAlign="center">
            <Box
              p={3}
              bg="purple.50"
              rounded="full"
              color="purple.600"
              fontSize="2xl"
              mb={2}
            >
              👋
            </Box>

            <Text
              fontSize={{ base: "2xl", md: "3xl" }}
              fontWeight="bold"
              // bgGradient="linear(to-r, #4F46E5, #7C3AED)"
              // bgClip="text"
              color="gray.900"
            >
              Name your event
            </Text>
            <Text color="gray.600" fontSize={{ base: "md", md: "lg" }}>
              Give your event a memorable title
            </Text>
          </VStack>

          <VStack spacing={6} w="full" maxW="400px">
            <FormControl position="relative">
              <Input
                placeholder="Event title"
                size="lg"
                type="text"
                value={eventTitle}
                onChange={(e) => setEventTitle(e.target.value)}
                bg="gray.50"
                color="gray.800"
                border="1px solid"
                borderColor="gray.200"
                _placeholder={{ color: "gray.400" }}
                _focus={{
                  borderColor: "#4F46E5",
                  boxShadow: "0 0 0 1px #4F46E5",
                  bg: "white",
                  transform: "scale(1.02)",
                }}
                _hover={{
                  borderColor: "gray.300",
                }}
                rounded="xl"
                h="60px"
                fontSize="lg"
                textAlign="center"
                transition="all 0.2s"
              />
            </FormControl>

            {eventTitle && (
              <Button
                w="full"
                size="lg"
                bg="gray.900"
                color="white"
                h="60px"
                fontSize="lg"
                onClick={handleNext}
                _hover={{
                  transform: "translateY(-2px)",
                  shadow: "lg",
                  opacity: 0.95,
                }}
                _active={{ transform: "translateY(0)" }}
                rounded="xl"
                rightIcon={<FaArrowRight />}
                transition="all 0.2s"
              >
                Continue
              </Button>
            )}
          </VStack>
        </VStack>
      </Container>
    </Box>
  );
};

export default CreateTitlePage;
