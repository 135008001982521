import {
  Avatar,
  Center,
  AvatarBadge,
  Stack,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  Spacer,
  AlertDialogCloseButton,
  Divider,
  AlertDialogBody,
  AlertDialogFooter,
  Box,
  Button,
  Select,
  Flex,
  Tag,
  TagLabel,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  Container,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tooltip,
  Text,
  ModalFooter,
  Textarea,
  useDisclosure,
  useColorModeValue,
  InputGroup,
  InputLeftElement,
  Card,
  CardBody,
  HStack,
  VStack,
} from "@chakra-ui/react";
import { AddIcon, SearchIcon } from "@chakra-ui/icons";

import { firestore, storage } from "../../firebase/firebase";
// import { SearchLogo } from "../../assets/constants";
import useSearchUser from "../../hooks/useSearchUser";
import { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getDownloadURL, ref, uploadString } from "firebase/storage";

import { SearchLogo, SearchLightLogo } from "../../assets/constants";

import SuggestedUser from "../../components/SuggestedUsers/SuggestedUser";
import SuggestedGroups from "../../components/SuggestedUsers/SuggestedGroups";
import CommentsModal from "../../components/Modals/CommentsModal";
import Search from "../../components/Sidebar/Search";
import useAuthStore from "../../store/authStore";
import { WhatsappLogo, WhatsappGreenLogo } from "../../assets/constants";
import useShowToast from "../../hooks/useShowToast";
import usePreviewImg from "../../hooks/usePreviewImg";

import {
  addDoc,
  where,
  getDocs,
  query,
  arrayUnion,
  collection,
  doc,
  getDoc,
  updateDoc,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";

const SearchPage = () => {
  const [isLoadingGroupCreate, setIsLoadingGroupCreate] = useState(false);
  const [prompt, setState] = useState(null);

  const {
    isOpen: isOpenModal,
    onOpen: onOpenModal,
    onClose: onCloseModal,
  } = useDisclosure();
  const cancelRef = useRef();

  const SearchLogoTheme = useColorModeValue(SearchLightLogo, SearchLogo);
  const [value, setValue] = useState("locals");

  const { isOpen, onOpen, onClose } = useDisclosure();
  const searchRef = useRef(null);
  const { user, isLoading, getUserProfile, setUser } = useSearchUser();
  const navigate = useNavigate();

  const bgTheme = useColorModeValue(`white`, `black`);
  const WhatsappThemeLogo = useColorModeValue(WhatsappGreenLogo, WhatsappLogo);

  const inputBorderColor = useColorModeValue(`black`, `white.400`);
  const selectColor = useColorModeValue(`white`, `white.400`);

  const [titleg, setTitleg] = useState("");
  const [captiong, setCaptiong] = useState("");
  const [whatsappg, setWhatsappg] = useState("");
  const [usernameg, setUsernameg] = useState("");
  const authUser = useAuthStore((state) => state.user);
  const showToast = useShowToast();

  const fileRef = useRef(null);
  const { handleImageChange, selectedFile, setSelectedFile } = usePreviewImg();

  const {
    isOpen: isOpenGoingModal,
    onOpen: onOpenGoingModal,
    onClose: onCloseGoingModal,
  } = useDisclosure();

  const handleSearchUser = (e) => {
    e.preventDefault();

    let valuez = String(searchRef.current.value);

    if (valuez.includes("https://chat.whatsapp.com")) {
      getUserProfile(valuez);
    } else {
      getUserProfile(valuez.toLowerCase());
    }
  };

  const handleCreateGroup = async () => {
    setIsLoadingGroupCreate(true);

    try {
      // const newUser = await signInWithGoogle();
      // if (!newUser && error) {
      //   showToast("Error", error.message, "error");
      //   return;
      // }
      // const userRef = doc(firestore, "users", newUser.user.uid);
      // const userSnap = await getDoc(userRef);

      // if (userSnap.exists()) {
      //   // login
      //   const userDoc = userSnap.data();
      //   localStorage.setItem("user-info", JSON.stringify(userDoc));
      //   loginUser(userDoc);
      // } else {
      // signup

      if (!authUser) {
        showToast("Error", "You must be logged in", "error");
        return;
      }

      if (
        // whatsappg == "" || 
        usernameg == "" || titleg == "") {
        showToast("Error", "Fill in all details first", "error");
        setIsLoadingGroupCreate(false);
        return;
      }

      const groupsRef = collection(firestore, "groups");

      const q = query(groupsRef, where("whatsapp", "==", whatsappg));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        showToast("Error", "WhatsApp group already exists", "error");
        return;
      }


      const trimmedUserName = usernameg.replace(/ /g, "_").toLowerCase();


      const qUsername = query(groupsRef, where("username", "==", trimmedUserName));
      const querySnapshotUsername = await getDocs(qUsername);

      if (!querySnapshotUsername.empty) {
        showToast("Error", "Username already exists", "error");
        return;
      }

      let URL = "";

      if (selectedFile) {
        const storageRef = ref(storage, `groupPics/${trimmedUserName}`);

        await uploadString(storageRef, selectedFile, "data_url");
        URL = await getDownloadURL(ref(storage, `groupPics/${trimmedUserName}`));
      }

      const userDoc = {
        admins: [authUser.uid],
        createdBy: authUser.uid,
        uid: "sdfsdfsdfds",
        username: trimmedUserName,
        fullName: titleg,
        bio: captiong,
        whatsapp: whatsappg,
        profilePicURL: URL,
        followers: [],
        following: [],
        posts: [],
        createdAt: Date.now(),
        verified: false,
        users: 0,
        lastVerified: new Date(),
        type: value,
        memberList: [],
      };

      const postDocRef = await addDoc(collection(firestore, "groups"), userDoc);

      // if (userProfile)
      //   setUserProfile({
      //     ...userProfile,
      //     groups: [...userProfile.groups, postDocRef.id],
      //   });

      const userRef = doc(firestore, "users", authUser.uid);
      // await deleteDoc(doc(firestore, "posts", post.id));

      await updateDoc(userRef, {
        groups: arrayUnion(postDocRef.id),
      });

      // setAuthUser({
      //   ...authUser,
      //   groups: [...authUser.groups, postDocRef.id],
      // });

      // localStorage.setItem(
      //   "user-info",
      //   JSON.stringify({
      //     ...authUser,
      //     groups: [...authUser.groups, postDocRef.id],
      //   })
      // );

      // await setDoc(doc(firestore, "groups", "fsdfdsfdsfsd"), userDoc);
      // localStorage.setItem("user-info", JSON.stringify(userDoc));
      // loginUser(userDoc);
      // }

      setIsLoadingGroupCreate(false);
      showToast("Success", "Group created successfully", "success");

      onCloseGoingModal();
      setTitleg("");
      setCaptiong("");
      setWhatsappg("");
      setUsernameg("");
      setValue("locals");
      navigate("/groups/");
    } catch (error) {
      setIsLoadingGroupCreate(false);
      showToast("Error", error.message, "error");
    }
  };

  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const ready = (e) => {
      e.preventDefault();
      console.log("Install Prompt fired");
      setState(e);
      // showToast("", "App install dismissed", "error");
    };

    window.addEventListener("beforeinstallprompt", ready);

    // return () => {
    //   window.removeEventListener("beforeinstallprompt", ready);
    // };
  }, []);

  return (
    <>
      <Container maxW="container.lg" 
      
      py={0} px={0}>
        <Flex 
        // w={"full"} 
        gap={0} direction={"column"}>
          <Box bg={useColorModeValue(`white`, `gray.800`)} px={0}>
            <Flex h={16} alignItems={"center"} justifyContent={"flex-start"}>
              <Box ml={6}>
                <Text
                  fontWeight={"semibold"}
                  pl={0}
                  fontSize={24}
                  // fontWeight={"bold"}
                  // color={"green.300"}
                >
                  Groups
                </Text>
              </Box>

              <Spacer />

              <Tag
                mr={4}
                // onClick={() => {
                //   // if (index == 0) {
                //   //   // setComment(typesValueArray[index]);
                //   //   setIndexz(index);
                //   // } else {
                //   //   // setComment("unverified");
                //   //   setIndexz(index);
                //   // }
                //   setIndexz(index);

                //   // console.log(index);
                // }}
                flexShrink="0"
                size="md"
                key={1}
                borderRadius="full"
                variant="outline"
                colorScheme={"gray"}

                // colorScheme={indexz == index ? "blue" : "gray"}
              >
                <TagLabel size="sm">🇬🇧 London, UK</TagLabel>
              </Tag>

              <SearchIcon
                onClick={() => {
                  onOpen();
                }}
                boxSize={4}
                mt={0}
                mr={8}
              />
            </Flex>
          </Box>

          <Divider mt={0} bg={"gray.8000"} />


          {/* <Box flex={2} pb={8}> */}
          {/* <Flex
            w={"max-content"}
              background={"red"}
              // alignItems={"flex-end"}
              gap={4}
              _hover={{ bg: "whiteAlpha.400" }}
              borderRadius={6}
              p={2}
              // w={{ base: 10, md: "full" }}
              // justifyContent={{ base: "center", md: "flex-end" }}
              onClick={onOpen}
            >
              <SearchLogo />
            </Flex> */}
          {/* <FeedPosts /> */}

          {/* <Card 
      
      w={"full"}

      mb={4}>
        <CardBody> */}

          {!isLoadingGroupCreate && (
            <Flex
              mt={0}
              w={"full"}
              alignItems={"center"}
              justify={"center"}
              // background={"red"}
              px={0}
            >
              <SuggestedGroups />
            </Flex>
          )}

          {/* </CardBody>
      </Card> */}

          <Flex mb={32} justify={"center"}>
            <Button
              leftIcon={<AddIcon />}
              mt={6}
              size="sm"
              colorScheme="green"
              variant="outline"
              // ml={3}
              onClick={() => {
                if (!authUser) {
                  onOpenModal();
                  return;
                } else {
                  onOpenGoingModal();
                }
              }}
            >
              Add your group
            </Button>
          </Flex>
          {/* </Box> */}
          {/* <Box flex={3} mr={20} display={{ base: "none", lg: "block" }} maxW={"300px"}>
					<SuggestedUsers />
				</Box> */}
          {/* 
          <Button
            onClick={() => {
              onOpen();
            }}
          >
            Search
          </Button> */}

          <Modal
            size={"full"}
            isOpen={isOpenGoingModal}
            onClose={onCloseGoingModal}
          >
            <ModalOverlay />
            <ModalContent maxW={"xl"} bg={bgTheme} border={"1px solid gray"}>
              <ModalHeader mt={4}>
                <Text userSelect={"none"} mb={0} fontSize={22}>
                  Add your group
                </Text>
              </ModalHeader>
              <ModalCloseButton size={"lg"} mt={4} />
              <ModalBody pb={2}>
                <Card mt={0} pb={0} w={"full"} mb={0}>
                  <CardBody pl={4} pt={1} pb={0}>
                    <VStack mt={8} alignItems={"center"}>
                      <Stack
                        onClick={() => fileRef.current.click()}
                        direction={["column", "row"]}
                        spacing={6}
                      >
                        <Center>
                          <Avatar
                            // name={authUser.fullName}
                            size="xl"
                            src={
                              selectedFile
                              //  || authUser.profilePicURL
                            }
                            border={"2px solid white "}
                          >
                            <AvatarBadge bg={"blue"} boxSize="1em">
                              <svg
                                width="0.4em"
                                fill="white"
                                viewBox="0 0 20 20"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M4 5a2 2 0 00-2 2v8a2 2 0 002 2h12a2 2 0 002-2V7a2 2 0 00-2-2h-1.586a1 1 0 01-.707-.293l-1.121-1.121A2 2 0 0011.172 3H8.828a2 2 0 00-1.414.586L6.293 4.707A1 1 0 015.586 5H4zm6 9a3 3 0 100-6 3 3 0 000 6z"
                                />
                              </svg>
                            </AvatarBadge>
                          </Avatar>

                          <Text
                            //  fontWeight={"semibold"}
                            mx="4"
                          >
                            Tap here to select a picture
                          </Text>

                          {/* 
                      <EditIcon
                        onClick={() => fileRef.current.click()}
                        boxSize={6}
                      /> */}
                        </Center>
                        {/* <Center w="full">
                      <Button
                        w="full"
                        colorScheme="gray"
                        variant={"solid"}
                        onClick={() => fileRef.current.click()}
                      >
                        Update Profile Picture
                      </Button>
                    </Center> */}
                        <Input
                          type="file"
                          hidden
                          size={"lg"}
                          ref={fileRef}
                          accept="image/*"
                          onChange={handleImageChange}
                        />
                      </Stack>

                      {/* <Text
                        fontWeight={"semibold"}
                        userSelect={"none"}
                        mt={6}
                        w={"full"}
                        mb={0}
                        fontSize={16}
                      >
                        Invite link url
                      </Text> */}

                      {/* <InputGroup size={"lg"} mt={0} mb={0}>
                        <InputLeftElement size={"lg"} pointerEvents="none">
                          <WhatsappThemeLogo />
                        </InputLeftElement>

                        <Input
                          // borderColor={inputBorderColor}
                          // borderColor="whiteAlpha.900"
                          mt={0}
                          mb={0}
                          size={"lg"}
                          // placeholder="https://chat.whatsapp.com/GpZomy0fdHGExGmdnWZJdZ"
                          value={whatsappg}
                          onChange={(e) => setWhatsappg(e.target.value)}
                        />
                      </InputGroup> */}
                    </VStack>

                    {/* <Text
                  userSelect={"none"}
                  mt={6}
                  w={"full"}
                  mb={0}
                  fontSize={16}
                >
                  Group info
                </Text> */}

                    <Text
                      fontWeight={"bold"}
                      userSelect={"none"}
                      mt={4}
                      w={"full"}
                      mb={0}
                      fontSize={18}
                    >
                      @
                    </Text>

                    <Input
                      fontWeight={"semibold"}
                      mt={1}
                      // borderColor={inputBorderColor}
                      // borderColor="whiteAlpha.900"
                      size={"lg"}
                      type="text"
                      // placeholder="choose your group handle @"
                      value={usernameg}
                      onChange={(e) => setUsernameg(e.target.value)}
                    />

                    <Text
                      fontWeight={"semibold"}
                      userSelect={"none"}
                      mt={4}
                      w={"full"}
                      mb={0}
                      fontSize={16}
                    >
                      Name
                    </Text>

                    <Input
                      // borderColor={inputBorderColor}
                      // borderColor="whiteAlpha.900"
                      mt={1}
                      size={"lg"}
                      type="text"
                      // placeholder="Group name"
                      value={titleg}
                      onChange={(e) => setTitleg(e.target.value)}
                    />

                    <Text
                      fontWeight={"semibold"}
                      userSelect={"none"}
                      mt={4}
                      w={"full"}
                      mb={0}
                      fontSize={16}
                    >
                      Description
                    </Text>
                    <Textarea
                      // fontWeight={"semibold"}
                      // borderColor={inputBorderColor}
                      // borderColor="whiteAlpha.900"
                      mt={1}
                      size={"lg"}
                      // placeholder="Add a description about your group"
                      value={captiong}
                      onChange={(e) => setCaptiong(e.target.value)}
                    />

                    <Text
                      fontWeight={"semibold"}
                      userSelect={"none"}
                      mt={4}
                      w={"full"}
                      mb={0}
                      fontSize={16}
                    >
                      Category
                    </Text>

                    <Box mt={1} mb={4}>
                      <Select
                        userSelect={"none"}
                        // bg={selectColor}
                        variant="filled"
                        size="md"
                        value={value}
                        placeholder="Choose category"
                        // onClick={() => {
                        //   if (!authUser) {
                        //     onOpenModal();
                        //   } else {
                        //     // openInNewTab(post.whatsapp);
                        //   }
                        // }}
                        onChange={(e) => {
                          setValue(e.target.value);

                          // handleGoingPost();
                        }}
                      >
                        <option value="locals">Locals 🏠</option>
                        <option value="drinks">Drinks 🍻</option>
                        <option value="games">Board Games 🎲</option>
                        <option value="gigs">Gigs 🎤</option>
                        <option value="sports">Sports 🏋️</option>
                        <option value="arts">Arts 🎭</option>
                      </Select>
                    </Box>
                  </CardBody>
                </Card>
              </ModalBody>

              <ModalFooter>
                <Button
                  mb={2}
                  mr={4}
                  onClick={() => {
                    onCloseGoingModal();
                    setTitleg("");
                    setCaptiong("");
                    setWhatsappg("");
                    setUsernameg("");
                  }}
                  // isLoading={isLoading}
                >
                  Cancel
                </Button>

                <Button
                  mb={2}
                  mr={0}
                  colorScheme="whatsapp"
                  variant={"outline"}
                  onClick={handleCreateGroup}
                  isLoading={isLoadingGroupCreate}
                >
                  Create
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>

          {isOpen ? <Search isOpen={isOpen} onClose={onClose} /> : null}

          {false && (
            <Flex
              bg={bgTheme}
              direction={"column"}
              // maxW={"400px"}
              alignItems={"center"}
              gap={4}
              mt={"10%"}
              p={2}
              onClick={onOpen}
            >
              <Flex bg={bgTheme} direction={"column"} w={"full"}>
                <form onSubmit={handleSearchUser}>
                  {/* <Card mb={8} mt={10}>
                    <CardBody> */}
                  <Text fontWeight={"semibold"} mb={8} fontSize={16}>
                    Search for a WhatsApp or Telegram group. You can use
                    keywords or the group invite link.
                  </Text>

                  <FormControl mb={4}>
                    {/* <FormLabel>Username</FormLabel> */}
                    <Input
                      // borderColor="red.900"
                      w={"full"}
                      size={"lg"}
                      // placeholder="🔍 https://chat.whatsapp.com/DsUeCvMDvAHAFIOZgzrLet"
                      ref={searchRef}
                    />
                  </FormControl>
                  {/* </CardBody>
                  </Card> */}

                  <Flex mb={4} w={"full"} justifyContent={"flex-end"}>
                    <Button
                      type="submit"
                      w={"full"}
                      border="2px"
                      borderColor="gray.500"
                      variant={"outline"}
                      mr={0}
                      isLoading={isLoading}
                    >
                      Searchz
                    </Button>
                  </Flex>
                </form>
              </Flex>

              {user && <SuggestedUser user={user} setUser={setUser} />}
            </Flex>
          )}

          <AlertDialog
            motionPreset="slideInBottom"
            leastDestructiveRef={cancelRef}
            onClose={onCloseModal}
            isOpen={isOpenModal}
            isCentered
          >
            <AlertDialogOverlay />

            <AlertDialogContent>
              <AlertDialogHeader>Are you a member? 🤔</AlertDialogHeader>
              {/* <AlertDialogCloseButton /> */}
              <AlertDialogBody>Only members can see this.</AlertDialogBody>
              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onCloseModal}>
                  Cancel
                </Button>

                <Link to="/auth">
                  <Button
                    variant={"outline"}
                    onClick={onCloseModal}
                    colorScheme="whatsapp"
                    ml={3}
                  >
                    Login
                  </Button>
                </Link>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        </Flex>
      </Container>
    </>
  );
};

export default SearchPage;

// const ProfileHeaderSkeleton = () => {
//   return (
//     <Flex
//       gap={{ base: 4, sm: 10 }}
//       py={10}
//       direction={{ base: "column", sm: "row" }}
//       justifyContent={"center"}
//       alignItems={"center"}
//     >
//       <SkeletonCircle size="24" />

//       <VStack
//         alignItems={{ base: "center", sm: "flex-start" }}
//         gap={2}
//         mx={"auto"}
//         flex={1}
//       >
//         <Skeleton height="12px" width="150px" />
//         <Skeleton height="12px" width="100px" />
//       </VStack>
//     </Flex>
//   );
// };

// const UserNotFound = () => {
//   return (
//     <Flex mt={8} flexDir="column" textAlign={"center"} mx={"auto"}>
//       <Text fontSize={"2xl"}>User Not Found</Text>
//       <Link
//         as={RouterLink}
//         to={"/"}
//         color={"blue.500"}
//         w={"max-content"}
//         mx={"auto"}
//       >
//         Go home
//       </Link>
//     </Flex>
//   );
// };
