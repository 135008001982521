// hooks/useFetchInvitations.js

import { useEffect, useState } from "react";
import { collection, orderBy, query, where, getDocs } from "firebase/firestore";
import { auth, firestore } from "../firebase/firebase";
import useAuthStore from "../store/authStore";
import useShowToast from "./useShowToast";

const useFetchInvitations = (userId) => {
  const authUser = useAuthStore((state) => state.user);

  const [invitations, setInvitations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const showToast = useShowToast();

  useEffect(() => {
    const fetchInvitations = async () => {
      setLoading(true);
      try {
        const invitationsRef = collection(firestore, "invitations");
        const q = authUser?.isCreator
          ? query(
              invitationsRef,
              where("creatorId", "==", authUser.uid),
              orderBy("createdAt", "desc")
            )
          : query(
              invitationsRef,
              where("userId", "==", authUser.uid),
              orderBy("createdAt", "desc")
            )
            ;

        const querySnapshot = await getDocs(q);
        const fetchedInvitations = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setInvitations(fetchedInvitations);
      } catch (err) {
        showToast("Error", err.message, "error");
      } finally {
        setLoading(false);
      }
    };

    if (authUser.uid) fetchInvitations();
  }, [authUser.uid]);

  return { invitations, loading, error };
};

export default useFetchInvitations;
