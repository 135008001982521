import {
  Container,
  Flex,
  Button,
  VStack,
  FormControl,
  Input,
  Text,
  IconButton,
  Box,
  Progress,
  List,
  ListItem,
  HStack,
} from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { FaArrowRight } from "react-icons/fa";
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

// List of common professions
const commonProfessions = [
  "Accountant", "Actor", "Architect", "Artist", "Author", "Banker", "Barista",
  "Chef", "Consultant", "Content Writer", "Data Analyst", "Dentist", "Designer",
  "Developer", "Doctor", "Economist", "Engineer", "Farmer", "Financial Analyst",
  "Fitness Trainer", "Graphic Designer", "Human Resources Specialist", "IT Manager",
  "Interior Designer", "Journalist", "Lawyer", "Librarian", "Logistics Manager",
  "Marketing Manager", "Mechanic", "Medical Assistant", "Musician", "Nurse",
  "Nutritionist", "Pharmacist", "Photographer", "Physician", "Pilot", "Plumber",
  "Police Officer", "Professor", "Psychologist", "Real Estate Agent",
  "Research Scientist", "Sales Manager", "Social Worker", "Software Engineer",
  "Teacher", "Translator", "UX Designer", "Veterinarian", "Videographer",
  "Web Developer", "Writer", "Zoologist",
];

// Reuse the same LogoComponent from ChooseNamePage
const LogoComponent = ({ onClick }) => (
  <Box 
    as="h1" 
    fontSize={{ base: "28px", md: "32px" }}
    fontWeight="400"
    letterSpacing="0.35em"
    position="relative"
    display="inline-block"
    cursor="pointer"
    color="#1A1A1A"
    textTransform="uppercase"
    fontFamily="Montserrat, sans-serif"
    onClick={onClick}
    _hover={{
      letterSpacing: "0.4em",
      transform: "translateY(-1px)",
      transition: "all 0.4s cubic-bezier(0.4, 0, 0.2, 1)",
    }}
    transition="all 0.4s cubic-bezier(0.4, 0, 0.2, 1)"
    sx={{
      WebkitFontSmoothing: "antialiased",
      textRendering: "optimizeLegibility",
    }}
  >
    <Box
      as="span"
      position="relative"
      _before={{
        content: '""',
        position: "absolute",
        left: "-8%",
        right: "-8%",
        top: "-8px",
        bottom: "-8px",
        background: "linear-gradient(135deg, rgba(26, 26, 26, 0.08), rgba(26, 26, 26, 0.03))",
        backdropFilter: "blur(4px)",
        opacity: { base: 0.95, md: 0.9 },
        borderRadius: "2px",
        transform: "skew(-4deg)",
        transition: "all 0.3s ease",
        zIndex: -1,
      }}
      _after={{
        content: '""',
        position: "absolute",
        left: 0,
        right: 0,
        bottom: "-4px",
        height: "1px",
        background: "linear-gradient(to right, transparent, currentColor, transparent)",
        opacity: 0.4,
        transform: "scaleX(0.8)",
        transition: "transform 0.3s ease",
      }}
      _hover={{
        _after: {
          transform: "scaleX(1)",
        },
      }}
    >
      hubzo
    </Box>
  </Box>
);

// Reuse the same PrimaryButton component
const PrimaryButton = ({ children, ...props }) => (
  <Button
    size="lg"
    bg="black"
    color="white"
    h="60px"
    fontSize="lg"
    fontWeight="semibold"
    rounded="full"
    _hover={{
      transform: "translateY(-2px)",
      shadow: "lg",
      opacity: 0.95,
    }}
    transition="all 0.2s"
    {...props}
  >
    {children}
  </Button>
);

const ChooseJobPage = () => {
  const [profession, setProfession] = useState("");
  const [filteredProfessions, setFilteredProfessions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const inputRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    const timer = setTimeout(() => setIsLoading(false), 0);
    return () => clearTimeout(timer);
  }, []);

  const handleProfessionChange = (e) => {
    const value = e.target.value;
    setProfession(value);
    setFilteredProfessions(
      commonProfessions.filter((prof) =>
        prof.toLowerCase().includes(value.toLowerCase())
      )
    );
  };

  const handleProfessionSelect = (prof) => {
    setProfession(prof);
    setFilteredProfessions([]);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") inputRef.current.blur();
  };

  if (isLoading) {
    return <Box minH="100vh" bg="white" />;
  }

  return (
    <Box 
      minH="100vh"
      maxH="100vh"
      overflow="hidden"
      position="relative"
      bg="white"
      pb="env(safe-area-inset-bottom)"
    >
      {/* Top Bar */}
      <Box
        position="fixed"
        top={0}
        left={0}
        right={0}
        zIndex={1000}
        bg="white"
        h={{ base: "56px", md: "64px" }}
        backdropFilter="blur(12px)"
        backgroundColor="rgba(255, 255, 255, 0.9)"
        borderBottom="1px solid"
        borderColor="gray.100"
      >
        <Container maxW="container.xl" h="full">
          <Flex 
            justify="space-between"
            align="center"
            h="full"
            px={{ base: 3, md: 4 }}
          >
            <IconButton
              icon={<ArrowBackIcon />}
              variant="ghost"
              aria-label="Go back"
              onClick={() => navigate(-1)}
              color="gray.500"
              display={{ base: 'flex', md: 'none' }}
              _hover={{ color: "black" }}
            />

            <LogoComponent onClick={() => navigate("/")} />

            <Box w="40px" display={{ base: 'block', md: 'none' }} />
          </Flex>
        </Container>
      </Box>

      {/* Main Content */}
      <Box 
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        overflowY="auto"
        style={{
          WebkitOverflowScrolling: 'touch'
        }}
      >
        <Box 
          pt={{ base: "56px", md: "64px" }}
          minH="100vh"
          pb={{ base: 32, md: 8 }}
        >
          <Container 
            maxW="container.sm" 
            py={{ base: 8, md: 16 }} 
            px={6}
            mb={{ base: 8, md: 0 }}
          >
            <VStack spacing={8} align="center" w="full">
              {/* Progress Bar */}
              <Progress
                value={37.5}
                size="sm"
                colorScheme="blackAlpha"
                w="full"
                maxW="400px"
                borderRadius="full"
                bg="gray.100"
              />

              <VStack spacing={3} textAlign="center">
                <Text
                  fontSize={{ base: "3xl", md: "4xl" }}
                  fontWeight="light"
                  letterSpacing="tight"
                  color="gray.900"
                  lineHeight="shorter"
                >
                  What do you do?
                </Text>
                <Text
                  fontSize={{ base: "sm", md: "md" }}
                  color="gray.500"
                >
                  Connect with people in your industry
                </Text>
              </VStack>

              <VStack spacing={6} w="full" maxW="400px">
                <FormControl>
                  <Input
                    ref={inputRef}
                    placeholder="Enter your profession"
                    size="lg"
                    type="text"
                    value={profession}
                    onChange={handleProfessionChange}
                    onKeyDown={handleKeyDown}
                    bg="gray.50"
                    color="gray.800"
                    border="1px solid"
                    borderColor="gray.200"
                    _placeholder={{ color: "gray.400" }}
                    _focus={{ 
                      borderColor: "#4F46E5",
                      boxShadow: "0 0 0 1px #4F46E5",
                      bg: "white",
                      transform: "scale(1.02)",
                    }}
                    _hover={{
                      borderColor: "gray.300",
                    }}
                    rounded="xl"
                    h="60px"
                    fontSize="lg"
                    textAlign="center"
                    transition="all 0.2s"
                  />
                </FormControl>

                {/* Suggestions List */}
                {filteredProfessions.length > 0 && (
                  <List spacing={2} w="full">
                    {filteredProfessions.slice(0, 5).map((prof, index) => (
                      <ListItem
                        key={index}
                        p={4}
                        bg="gray.50"
                        borderRadius="xl"
                        cursor="pointer"
                        _hover={{ 
                          bg: "gray.100",
                          transform: "translateY(-2px)",
                          shadow: "sm",
                        }}
                        textAlign="center"
                        color="gray.700"
                        onClick={() => handleProfessionSelect(prof)}
                        transition="all 0.2s"
                        fontWeight="medium"
                        borderWidth={1}
                        borderColor="gray.200"
                      >
                        {prof}
                      </ListItem>
                    ))}
                  </List>
                )}

                <Button
                  w="full"
                  size="lg"
                  bg="black"
                  color="white"
                  h="60px"
                  fontSize="lg"
                  isDisabled={!profession}
                  onClick={() => {
                    localStorage.setItem("profession", profession);
                    navigate("/choose_face_pic");
                  }}
                  _hover={{
                    transform: "translateY(-2px)",
                    shadow: "lg",
                    opacity: 0.95,
                  }}
                  _active={{ transform: "translateY(0)" }}
                  rounded="full"
                  rightIcon={<FaArrowRight />}
                  transition="all 0.2s"
                >
                  Continue
                </Button>

                <Button
                  variant="ghost"
                  color="gray.600"
                  fontSize="sm"
                  fontWeight="medium"
                  onClick={() => navigate("/choose_face_pic")}
                  _hover={{ 
                    color: "black",
                    bg: "gray.50"
                  }}
                  rounded="full"
                  h="60px"
                >
                  Skip for now
                </Button>

                <HStack 
                  spacing={2} 
                  color="gray.500"
                  fontSize="sm"
                  pt={2}
                >
                  <Box as="span" fontSize="lg">🔒</Box>
                  <Text>Your information is always private</Text>
                </HStack>
              </VStack>
            </VStack>
          </Container>
        </Box>
      </Box>
    </Box>
  );
};

export default ChooseJobPage;
