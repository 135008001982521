import {
  Container,
  Flex,
  Button,
  VStack,
  FormControl,
  Text,
  IconButton,
  Box,
  Progress,
  RangeSlider,
  RangeSliderTrack,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  Tooltip,
  HStack,
} from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";

const CreateAgeRangePage = () => {
  const navigate = useNavigate();
  const [ageRange, setAgeRange] = useState([
    parseInt(localStorage.getItem("minAge")) || 23,
    parseInt(localStorage.getItem("maxAge")) || 39,
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSliderChange = (val) => {
    setAgeRange(val);
  };

  const handleNext = () => {
    localStorage.setItem("minAge", ageRange[0]);
    localStorage.setItem("maxAge", ageRange[1] === 49 ? "49+" : ageRange[1]);
    navigate("/create_capacity_event");
  };

  return (
    <Box
      position="fixed"
      top={{ base: 0, md: "64px" }}
      left={0}
      right={0}
      bottom={0}
      bg="white"
      zIndex={1000}
      overflowY="auto"
    >
      <Container
        maxW="container.sm"
        py={4}
        display="flex"
        flexDirection="column"
        minH={{ base: "100vh", md: "calc(100vh - 64px)" }}
      >
        {/* Header with Progress */}
        <Flex w="full" align="center" mb={6}>
          <IconButton
            icon={<ArrowBackIcon />}
            variant="ghost"
            aria-label="Go back"
            onClick={() => navigate(-1)}
            color="gray.500"
            _hover={{ color: "gray.600" }}
          />
          <Progress
            value={62.5}
            size="sm"
            colorScheme="purple"
            flex="1"
            mx={4}
            borderRadius="full"
            bg="gray.100"
          />
        </Flex>

        {/* Main Content */}
        <VStack spacing={8} align="center" w="full">
          <VStack spacing={3} textAlign="center">
            <Box
              p={3}
              bg="gray.50"
              rounded="full"
              color="gray.700"
              fontSize="2xl"
              mb={2}
            >
              👥
            </Box>
            
            <Text 
              fontSize={{ base: "2xl", md: "3xl" }}
              fontWeight="bold"
              color="gray.900"
            >
              Age Range
            </Text>
            <Text 
              color="gray.600" 
              fontSize={{ base: "md", md: "lg" }}
            >
              Set the age range for your event
            </Text>
          </VStack>

          <VStack spacing={6} w="full" maxW="400px">
            <FormControl>
              <Box
                bg="gray.50"
                p={6}
                borderRadius="xl"
                mb={6}
                borderWidth="1px"
                borderColor="gray.200"
              >
                <VStack spacing={4}>
                  <HStack spacing={8} justify="center" w="full">
                    <VStack spacing={1}>
                      <Text 
                        fontSize="3xl" 
                        fontWeight="bold"
                        color="#4F46E5"
                      >
                        {ageRange[0]}
                      </Text>
                      <Text 
                        fontSize="sm" 
                        color="gray.500"
                        fontWeight="medium"
                      >
                        Min Age
                      </Text>
                    </VStack>
                    <Box 
                      h="50px" 
                      w="1px" 
                      bg="gray.200"
                    />
                    <VStack spacing={1}>
                      <Text 
                        fontSize="3xl" 
                        fontWeight="bold"
                        color="#7C3AED"
                      >
                        {ageRange[1] === 49 ? "49+" : ageRange[1]}
                      </Text>
                      <Text 
                        fontSize="sm" 
                        color="gray.500"
                        fontWeight="medium"
                      >
                        Max Age
                      </Text>
                    </VStack>
                  </HStack>
                  <Box 
                    w="full" 
                    h="4px" 
                    bg="gray.100" 
                    borderRadius="full" 
                    overflow="hidden"
                    position="relative"
                  >
                    <Box
                      position="absolute"
                      left={`${(ageRange[0] - 18) * 100 / 31}%`}
                      right={`${100 - ((ageRange[1] - 18) * 100 / 31)}%`}
                      h="full"
                      bgGradient="linear(to-r, #4F46E5, #7C3AED)"
                      transition="all 0.2s"
                    />
                  </Box>
                </VStack>
              </Box>

              <Flex alignItems="center" w="full" mb={8}>
                <Text color="gray.500" fontSize="sm" fontWeight="medium">
                  18
                </Text>
                <RangeSlider
                  aria-label={["Minimum Age", "Maximum Age"]}
                  defaultValue={ageRange}
                  min={18}
                  max={49}
                  step={1}
                  mx={4}
                  focusThumbOnChange={false}
                  onChange={handleSliderChange}
                >
                  <RangeSliderTrack 
                    bg="gray.200"
                    h="3px"
                  >
                    <RangeSliderFilledTrack 
                      bg="gray.400"
                    />
                  </RangeSliderTrack>
                  <Tooltip
                    hasArrow
                    label={`Min Age: ${ageRange[0]}`}
                    bg="gray.700"
                    color="white"
                    placement="top"
                  >
                    <RangeSliderThumb 
                      boxSize={6} 
                      index={0}
                      bg="white"
                      border="2px solid"
                      borderColor="gray.400"
                      _focus={{
                        boxShadow: "0 0 0 3px rgba(0, 0, 0, 0.1)"
                      }}
                    />
                  </Tooltip>
                  <Tooltip
                    hasArrow
                    label={`Max Age: ${ageRange[1] === 49 ? "49+" : ageRange[1]}`}
                    bg="gray.700"
                    color="white"
                    placement="top"
                  >
                    <RangeSliderThumb 
                      boxSize={6} 
                      index={1}
                      bg="white"
                      border="2px solid"
                      borderColor="gray.400"
                      _focus={{
                        boxShadow: "0 0 0 3px rgba(0, 0, 0, 0.1)"
                      }}
                    />
                  </Tooltip>
                </RangeSlider>
                <Text color="gray.500" fontSize="sm" fontWeight="medium">
                  49+
                </Text>
              </Flex>

              <Button
                w="full"
                size="lg"
                bg="gray.900"
                color="white"
                h="60px"
                fontSize="lg"
                onClick={handleNext}
                _hover={{
                  transform: "translateY(-2px)",
                  shadow: "lg",
                  opacity: 0.95,
                }}
                _active={{ transform: "translateY(0)" }}
                rounded="xl"
                rightIcon={<FaArrowRight />}
                transition="all 0.2s"
              >
                Continue
              </Button>
            </FormControl>
          </VStack>
        </VStack>
      </Container>
    </Box>
  );
};

export default CreateAgeRangePage;
