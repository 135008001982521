import {
  Container,
  Flex,
  Button,
  VStack,
  Text,
  IconButton,
  Box,
  Progress,
  Divider,
  HStack,
  Image,
} from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import {
  FaMapMarkerAlt,
  FaCalendarAlt,
  FaUserFriends,
  FaBirthdayCake,
  FaMars,
  FaStar,
  FaArrowRight,
} from "react-icons/fa";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useCreateEvent from "../../hooks/useCreateEvent";
import useAuthStore from "../../store/authStore";

const CreateEventPage = () => {
  const authUser = useAuthStore((state) => state.user);
  const navigate = useNavigate();
  const { createEvent, loading, error } = useCreateEvent();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const startDate = localStorage.getItem("eventStart");
  const endDate = localStorage.getItem("eventEnd");
  const minAge = localStorage.getItem("minAge") || "";
  const maxAge = localStorage.getItem("maxAge") || "";
  const genderRatio = localStorage.getItem("genderRatio") || "";
  const eventCapacity = localStorage.getItem("eventCapacity") || "";
  const selectedPlace = localStorage.getItem("selectedPlace") || "";
  const selectedAddress = localStorage.getItem("selectedAddress") || "";
  const profileScore = localStorage.getItem("profileScore") || "";
  const eventPic = localStorage.getItem("event-pic") || "";
  const eventDescription =
    localStorage.getItem("eventDescription") ||
    "No description provided for this event.";

  const invitationPreference =
    localStorage.getItem("invitationPreference") || "";

  const formattedStartDate = startDate
    ? new Date(startDate).toLocaleString()
    : "";
  const formattedEndDate = endDate ? new Date(endDate).toLocaleString() : "";
  const eventData = {
    placeLocation: localStorage.getItem("selectedPlaceLocation") || "",
    address: selectedAddress,
    title: localStorage.getItem("eventTitle") || "",
    caption: eventDescription,
    createdBy: authUser?.uid,
    startAt: startDate ? new Date(startDate) : null,
    endAt: endDate ? new Date(endDate) : null,
    placeName: selectedPlace,
    createdAt: Date.now(),
    postedBy: authUser?.uid,
    minAge: minAge,
    maxAge: maxAge,
    ratio: genderRatio,
    capacity: eventCapacity,
    score: profileScore,
    postPicURL: eventPic,
  };

  const handleCreateEvent = async () => {
    localStorage.setItem("city", authUser?.city);

    await createEvent(eventData);
    if (!error) {
      [
        "eventTitle",
        "eventDescription",
        "eventStart",
        "eventEnd",
        "genderRatio",
        "eventCapacity",
        "selectedPlace",
        "selectedAddress",
        "profileScore",
        "event-pic",
      ].forEach((key) => localStorage.setItem(key, ""));
      navigate("/create_invitations_event");
    }
  };

  return (
    <Box
      position="fixed"
      top={{ base: 0, md: "64px" }}
      left={0}
      right={0}
      bottom={0}
      bg="gray.50"
      zIndex={1000}
      overflowY="auto"
    >
      <Container
        maxW="container.sm"
        py={4}
        display="flex"
        flexDirection="column"
        minH={{ base: "100vh", md: "calc(100vh - 64px)" }}
      >
        {/* Header with Progress */}
        <Flex w="full" align="center" mb={8}>
          <IconButton
            icon={<ArrowBackIcon />}
            variant="ghost"
            aria-label="Go back"
            onClick={() => navigate(-1)}
            color="gray.500"
            _hover={{ color: "gray.600" }}
          />
          <Progress
            value={100}
            size="sm"
            colorScheme="purple"
            flex="1"
            mx={4}
            borderRadius="full"
            bg="gray.200"
          />
        </Flex>

        {/* Main Content */}
        <VStack spacing={6} align="center" w="full">
          <VStack spacing={2} textAlign="center" maxW="sm">
            <Text 
              fontSize={{ base: "2xl", md: "3xl" }}
              fontWeight="bold"
              color="gray.900"
            >
              Event Summary
            </Text>
            <Text 
              color="gray.600" 
              fontSize={{ base: "md", md: "lg" }}
            >
              Review your event details
            </Text>
          </VStack>

          {/* Event Details Card */}
          <Box
            w="full"
            maxW="400px"
            bg="white"
            borderWidth="1px"
            borderColor="gray.200"
            borderRadius="2xl"
            overflow="hidden"
            transition="all 0.2s"
            _hover={{
              borderColor: "gray.300",
              shadow: "sm",
            }}
            mb="140px"
          >
            {eventData.postPicURL && (
              <Box position="relative" h="200px">
                <Image
                  src={eventData.postPicURL}
                  alt="Event"
                  objectFit="cover"
                  w="full"
                  h="full"
                />
                <Box
                  position="absolute"
                  bottom={0}
                  left={0}
                  right={0}
                  h="80px"
                  bgGradient="linear(to-t, rgba(0,0,0,0.7), rgba(0,0,0,0))"
                />
              </Box>
            )}
            
            <VStack spacing={6} p={6} align="stretch">
              <Text
                fontSize="2xl"
                fontWeight="bold"
                color="gray.900"
              >
                {eventData.title || "Event Name"}
              </Text>

              {/* Event Details */}
              <VStack spacing={4} align="stretch">
                <DetailItem 
                  icon={<FaCalendarAlt />} 
                  label="Start Date & Time"
                  value={formattedStartDate || "N/A"}
                />

                <DetailItem 
                  icon={<FaMapMarkerAlt />} 
                  label="Location"
                  value={eventData.placeName}
                  subValue={eventData.address}
                />

                <DetailItem 
                  icon={<FaUserFriends />} 
                  label="Capacity"
                  value={`${eventCapacity} attendees`}
                />

                <DetailItem 
                  icon={<FaBirthdayCake />} 
                  label="Age Range"
                  value={`${minAge} - ${maxAge} years`}
                />

                <DetailItem 
                  icon={<FaMars />} 
                  label="Gender Ratio"
                  value={`${genderRatio}% Male / ${100 - genderRatio}% Female`}
                />

                <DetailItem 
                  icon={<FaStar />} 
                  label="Invitation Preference"
                  value={invitationPreference}
                />

                <Box pt={2}>
                  <Text
                    fontSize="lg"
                    fontWeight="semibold"
                    color="gray.900"
                    mb={2}
                  >
                    About the Event
                  </Text>
                  <Text 
                    color="gray.600" 
                    fontSize="md" 
                    whiteSpace="pre-line"
                    lineHeight="tall"
                  >
                    {eventDescription}
                  </Text>
                </Box>
              </VStack>
            </VStack>
          </Box>
        </VStack>

        {/* Fixed Bottom CTAs */}
        <Box
          position="fixed"
          bottom={0}
          left={0}
          right={0}
          bg="white"
          borderTop="1px solid"
          borderColor="gray.200"
          p={4}
          pt={6}
          pb="max(env(safe-area-inset-bottom), 24px)"
          shadow="lg"
        >
          <Container maxW="container.sm">
            <VStack spacing={3} w="full" maxW="400px" mx="auto">
              <Button
                w="full"
                size="lg"
                bg="gray.900"
                color="white"
                h="60px"
                fontSize="lg"
                onClick={handleCreateEvent}
                isLoading={loading}
                loadingText="Creating Event..."
                _hover={{
                  bg: "gray.800",
                  transform: "translateY(-2px)",
                  shadow: "lg",
                }}
                _active={{ 
                  bg: "gray.950",
                  transform: "translateY(0)" 
                }}
                rounded="xl"
                rightIcon={<FaArrowRight />}
                transition="all 0.2s"
              >
                Create Event
              </Button>

              <Button
                variant="ghost"
                w="full"
                color="gray.500"
                onClick={() => {
                  localStorage.clear();
                  navigate("/");
                }}
                _hover={{ color: "gray.700" }}
              >
                Cancel
              </Button>
            </VStack>
          </Container>
        </Box>
      </Container>
    </Box>
  );
};

// Helper component for consistent detail items
const DetailItem = ({ icon, label, value, subValue }) => (
  <HStack spacing={3} align="start">
    <Box color="gray.400" pt={1}>
      {icon}
    </Box>
    <VStack align="start" spacing={0}>
      <Text fontSize="sm" color="gray.500" fontWeight="medium">
        {label}
      </Text>
      <Text fontSize="md" color="gray.900">
        {value}
      </Text>
      {subValue && (
        <Text fontSize="sm" color="gray.500">
          {subValue}
        </Text>
      )}
    </VStack>
  </HStack>
);

export default CreateEventPage;
