import {
  Box,
  Button,
  Container,
  Flex,
  Grid,
  Heading,
  Icon,
  Image,
  Stack,
  Text,
  VStack,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  SimpleGrid,
  Circle,
  HStack,
  IconButton,
  Wrap,
  WrapItem,
  Link,
  Divider,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import {
  FaUserShield,
  FaUserFriends,
  FaCalendarCheck,
  FaLock,
  FaArrowRight,
  FaTwitter,
  FaLinkedin,
  FaInstagram,
  FaMapMarkerAlt,
  FaApple,
  FaGooglePlay,
  FaCheck,
  FaUserCheck,
  FaHeart,
  FaShieldAlt,
  FaStar,
  FaBell,
  FaCalendarAlt,
  FaComments,
  FaShare,
  FaMobile,
  FaInfoCircle,
} from "react-icons/fa";
import { FaX, FaXTwitter, FaFacebook, FaTiktok } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import useDeviceType from "../hooks/useDeviceType";
import { useToast } from "@chakra-ui/react";

const MotionBox = motion(Box);
const MotionFlex = motion(Flex);
const MotionText = motion(Text);

const faqs = [
  {
    question: "How does the invitation system work?",
    answer:
      "Our algorithm analyzes multiple factors including professional background, social influence, and lifestyle to match you with exclusive events. The more complete your profile, the more curated your invitations will be.",
  },
  {
    question: "What makes Hubzo exclusive?",
    answer:
      "We maintain high standards through smart matching. Only members who align with the event's caliber and social dynamics receive invitations. This ensures meaningful connections and exceptional experiences for everyone.",
  },
  {
    question: "What types of events can I expect?",
    answer:
      "Our events range from intimate supper clubs at Michelin-starred restaurants to private cultural experiences and luxury weekend retreats. Each gathering is thoughtfully curated with an exceptional guest list.",
  },
  {
    question: "How private is my profile?",
    answer:
      "Your privacy is paramount. Your profile is hidden by default and you choose whether to reveal it to other attendees at each event you attend. We never share your information with third parties.",
  },
  {
    question: "What's the typical member profile?",
    answer:
      "Our community consists of accomplished individuals aged 25-45 who shape London's cultural and professional landscape. This includes entrepreneurs, creatives, industry leaders, and influential voices.",
  },
];

export const FAQSection = () => (
  <Box bg="black" py={32} position="relative" overflow="hidden">
    {/* Decorative background elements */}
    <Box
      position="absolute"
      top="-20%"
      right="-10%"
      w="50%"
      h="140%"
      bg="linear-gradient(45deg, #E9B6B9 0%, #F3D3C6 100%)"
      opacity={0.03}
      transform="rotate(-12deg)"
      filter="blur(120px)"
    />

    <Container maxW="container.xl">
      <VStack spacing={16}>
        {/* Section Header */}
        <VStack spacing={4} textAlign="center">
          <MotionText
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 0.6 }}
            transition={{ duration: 1 }}
            fontSize="sm"
            letterSpacing="0.2em"
            textTransform="uppercase"
            fontWeight="light"
            color="white"
          >
            Common Questions
          </MotionText>
          <MotionText
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
            fontSize={{ base: "3xl", md: "4xl" }}
            fontWeight="light"
            letterSpacing="tight"
            color="white"
            lineHeight="shorter"
          >
            Everything you need to know
          </MotionText>
        </VStack>

        {/* FAQ Items */}
        <VStack spacing={6} w="full" maxW="3xl" mx="auto">
          {faqs.map((faq, index) => (
            <MotionBox
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              w="full"
            >
              <Accordion allowToggle>
                <AccordionItem
                  border="none"
                  bg="whiteAlpha.50"
                  rounded="xl"
                  overflow="hidden"
                >
                  <AccordionButton
                    py={6}
                    px={8}
                    _hover={{ bg: "whiteAlpha.100" }}
                  >
                    <Box flex="1" textAlign="left">
                      <Text
                        color="white"
                        fontSize="lg"
                        fontWeight="medium"
                        letterSpacing="tight"
                      >
                        {faq.question}
                      </Text>
                    </Box>
                    <AccordionIcon color="white" />
                  </AccordionButton>
                  <AccordionPanel pb={6} px={8}>
                    <Text
                      color="whiteAlpha.800"
                      fontSize="sm"
                      letterSpacing="wide"
                      lineHeight="tall"
                    >
                      {faq.answer}
                    </Text>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            </MotionBox>
          ))}
        </VStack>
      </VStack>
    </Container>
  </Box>
);

const recentMeetups = [
  {
    title: "Rooftop Social @ Sky Garden",
    date: "Last Weekend",
    venue: "Sky Garden, 20 Fenchurch St",
    image:
      "https://firebasestorage.googleapis.com/v0/b/hubzo-app.appspot.com/o/Screenshot%202024-12-09%20at%2002.20.41.png?alt=media&token=449ad663-6c9b-42a9-b10c-3a89d646651b",
    attendees: "42 members",
    highlights: ["Perfect 1:1 ratio", "Stunning views", "Craft cocktails"],
    emoji: "🌃",
    color: "blue",
  },
  {
    title: "Wine Tasting Evening",
    date: "2 days ago",
    venue: "Vagabond Wines, Battersea",
    image:
      "https://firebasestorage.googleapis.com/v0/b/hubzo-app.appspot.com/o/Screenshot%202024-12-09%20at%2002.33.00%20(1).png?alt=media&token=0da247bc-154e-47a7-b774-95bb745bad11",
    attendees: "28 members",
    highlights: ["6 premium wines", "Cheese pairing", "Expert sommelier"],
    emoji: "🍷",
    color: "purple",
  },
  {
    title: "Seven Sisters Coastal Hike",
    date: "Last Sunday",
    venue: "Seven Sisters Country Park",
    image:
      "https://firebasestorage.googleapis.com/v0/b/hubzo-app.appspot.com/o/58883038_853599651659499_7115764480138543104_n.jpg?alt=media&token=568ad978-26d0-43f0-8c2b-9b96bfb9cca9",
    attendees: "32 members",
    highlights: ["8-mile coastal walk", "Pub lunch", "Beach picnic"],
    emoji: "🏃‍♂️",
    color: "green",
  },
];

const RecentMeetupsSection = ({ navigate }) => (
  <Box py={20} bg="white" position="relative" overflow="hidden">
    {/* Fun background elements */}
    <Box
      position="absolute"
      top="5%"
      right="10%"
      fontSize="120px"
      opacity={0.1}
      transform="rotate(10deg)"
    ></Box>
    <Box
      position="absolute"
      bottom="15%"
      left="5%"
      fontSize="100px"
      opacity={0.1}
      transform="rotate(-15deg)"
    ></Box>

    <Container maxW="container.xl" position="relative" zIndex={1}>
      <VStack spacing={16}>
        {/* Section Header */}
        <VStack spacing={4} textAlign="center">
          <Box
            bg="purple.50"
            px={6}
            py={2}
            rounded="full"
            transform="rotate(-1deg)"
          >
            <Text
              color="purple.600"
              fontWeight="bold"
              fontSize="md"
              letterSpacing="wide"
            >
              ✨ FOMO Alert: You missed these! ✨
            </Text>
          </Box>
          <Heading
            fontSize={{ base: "3xl", md: "4xl" }}
            fontWeight="black"
            bgGradient="linear(to-r, purple.500, blue.500)"
            bgClip="text"
            transform="rotate(-1deg)"
          >
            Latest London Hangouts
          </Heading>
        </VStack>

        {/* Meetups Grid */}
        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={8}>
          {recentMeetups.map((meetup, index) => (
            <MotionBox
              key={index}
              initial={{ opacity: 0, y: 20, rotate: -5 }}
              whileInView={{
                opacity: 1,
                y: 0,
                rotate: 0,
                transition: {
                  duration: 0.6,
                  delay: index * 0.2,
                  type: "spring",
                  stiffness: 100,
                },
              }}
              whileHover={{
                scale: 1.03,
                rotate: 1,
                transition: { duration: 0.2 },
              }}
              viewport={{ once: true }}
            >
              <Box
                bg="white"
                rounded="2xl"
                overflow="hidden"
                borderWidth="2px"
                borderColor={`${meetup.color}.100`}
                shadow="lg"
                position="relative"
                transform={index % 2 === 0 ? "rotate(1deg)" : "rotate(-1deg)"}
              >
                {/* Event Image */}
                <Box position="relative" h="200px">
                  <Image
                    src={meetup.image}
                    alt={meetup.title}
                    objectFit="cover"
                    w="full"
                    h="full"
                  />
                  <Box
                    position="absolute"
                    top={4}
                    left={4}
                    bg="white"
                    px={3}
                    py={1}
                    rounded="full"
                    fontSize="lg"
                    fontWeight="bold"
                    shadow="lg"
                    border="2px solid"
                    borderColor={`${meetup.color}.200`}
                    transform="rotate(-2deg)"
                  >
                    {meetup.emoji} {meetup.date}
                  </Box>
                </Box>

                {/* Content */}
                <Box p={6}>
                  <VStack align="start" spacing={4}>
                    <Heading
                      size="md"
                      color="gray.800"
                      transform="rotate(-1deg)"
                    >
                      {meetup.title}
                    </Heading>

                    <HStack
                      color="gray.600"
                      fontSize="sm"
                      bg={`${meetup.color}.50`}
                      px={3}
                      py={2}
                      rounded="xl"
                      transform="rotate(1deg)"
                    >
                      <Icon as={FaMapMarkerAlt} />
                      <Text fontWeight="medium">{meetup.venue}</Text>
                    </HStack>

                    {/* Highlights */}
                    <Wrap spacing={2}>
                      {meetup.highlights.map((highlight, i) => (
                        <WrapItem key={i}>
                          <Box
                            bg={`${meetup.color}.50`}
                            color={`${meetup.color}.600`}
                            px={3}
                            py={1}
                            rounded="full"
                            fontSize="xs"
                            fontWeight="bold"
                            transform={
                              i % 2 === 0 ? "rotate(1deg)" : "rotate(-1deg)"
                            }
                            _hover={{
                              transform: "rotate(0deg) scale(1.05)",
                              transition: "all 0.2s",
                            }}
                          >
                            {highlight}
                          </Box>
                        </WrapItem>
                      ))}
                    </Wrap>

                    {/* Attendees */}
                    <HStack
                      spacing={2}
                      bg={`${meetup.color}.50`}
                      px={4}
                      py={2}
                      rounded="full"
                      transform="rotate(-1deg)"
                    >
                      <Icon as={FaUserFriends} color={`${meetup.color}.500`} />
                      <Text
                        fontSize="sm"
                        fontWeight="bold"
                        color={`${meetup.color}.600`}
                      >
                        {meetup.attendees}
                      </Text>
                    </HStack>
                  </VStack>
                </Box>
              </Box>
            </MotionBox>
          ))}
        </SimpleGrid>

        {/* Fun CTA */}
        <MotionBox
          initial={{ scale: 0.95 }}
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
        >
          <Box
            bg="purple.50"
            p={8}
            rounded="2xl"
            maxW="2xl"
            w="full"
            textAlign="center"
            transform="rotate(-1deg)"
            border="3px dashed"
            borderColor="purple.200"
          >
            <VStack spacing={4}>
              <Text fontSize="4xl">🎯</Text>
              <Heading size="md" color="purple.700" transform="rotate(1deg)">
                Don't Miss Out on the Fun!
              </Heading>
              <Text color="purple.600">
                Join the community to get invited to our next exclusive meetup
              </Text>
              <Button
                size="lg"
                bgGradient="linear(to-r, purple.500, blue.500)"
                color="white"
                _hover={{
                  bgGradient: "linear(to-r, purple.600, blue.600)",
                  transform: "translateY(-2px) rotate(1deg)",
                }}
                rightIcon={<FaArrowRight />}
                onClick={() => navigate("/choose_name")}
                transform="rotate(1deg)"
              >
                Join the Fun
              </Button>
            </VStack>
          </Box>
        </MotionBox>
      </VStack>
    </Container>
  </Box>
);

const SimplifiedLandingPage = ({ navigate }) => (
  <Box 
    minH="100dvh"
    h="100dvh"
    position="relative"
    overflow="hidden"
    bg="black"
  >
    {/* Background Image with Overlay - matching hero section */}
    <Box
      position="absolute"
      top={0}
      left={0}
      right={0}
      bottom={0}
      zIndex={0}
    >
      <Image
        src="https://firebasestorage.googleapis.com/v0/b/hubzo-app.appspot.com/o/Knightox-Badges-3-1-600x600.png?alt=media&token=59fd4b95-344c-43c7-bc50-85ea9dcb8e5e"
        alt="Background"
        objectFit="contain"
        objectPosition="center"
        w="120%"
        h="120%"
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        filter="brightness(0.6)"
      />
      <Box
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        bgGradient="linear(to-b, rgba(0,0,0,0.4), rgba(0,0,0,0.7))"
      />
    </Box>

    <Box
      overflow="auto"
      h="full"
      pt={{ base: "env(safe-area-inset-top)", md: 8 }}
      pb="env(safe-area-inset-bottom)"
      position="relative"
      zIndex={1}
    >
      <Container 
        maxW="container.sm" 
        h="full"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        py={{ base: 8, md: 12 }}
        px={6}
      >
        <VStack 
          spacing={{ base: 12, md: 16 }} 
          align="center" 
          textAlign="center"
          w="full"
        >
          {/* Logo */}
          <Text
            fontSize={{ base: "36px", md: "40px" }}
            fontWeight="400"
            letterSpacing="0.35em"
            color="white"
            textTransform="uppercase"
            fontFamily="Montserrat, sans-serif"
            position="relative"
            textShadow="0 2px 10px rgba(0,0,0,0.3)"
            sx={{
              WebkitFontSmoothing: "antialiased",
              textRendering: "optimizeLegibility",
            }}
            _before={{
              content: '""',
              position: "absolute",
              left: "-8%",
              right: "-8%",
              top: "-8px",
              bottom: "-8px",
              background: "linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.05))",
              backdropFilter: "blur(4px)",
              opacity: 0.9,
              borderRadius: "2px",
              transform: "skew(-4deg)",
              transition: "all 0.3s ease",
              zIndex: -1,
            }}
          >
            hubzo
          </Text>

          {/* Content */}
          <VStack spacing={8} maxW="md">
            {/* Main Description */}
            <VStack spacing={4}>
              <Heading
                fontSize={{ base: "4xl", md: "5xl" }}
                fontWeight="black"
                bgGradient="linear(to-r, #FF3366, #FF9966)"
                bgClip="text"
                lineHeight="1"
                letterSpacing="tight"
                position="relative"
                zIndex={2}
                filter="drop-shadow(0 4px 8px rgba(0,0,0,0.3))"
                display={{ base: "none", md: "block" }}
              >
                Exclusive
              </Heading>
              <Heading
                fontSize={{ base: "4xl", md: "5xl" }}
                fontWeight="black"
                color="white"
                lineHeight="1"
                letterSpacing="tight"
                position="relative"
                zIndex={2}
                textShadow="0 4px 12px rgba(0,0,0,0.5)"
                display={{ base: "none", md: "block" }}
                mb={4}
              >
                meetups
              </Heading>
              
              <Text 
                fontSize={{ base: "xl", md: "2xl" }} 
                color="white"
                fontWeight="medium"
                lineHeight="tall"
                textShadow="0 2px 4px rgba(0,0,0,0.3)"
              >
                Get invited based on{" "}
                <Box 
                  as="span" 
                  bgGradient="linear(to-r, #FF3366, #FF9966)"
                  bgClip="text"
                  fontWeight="extrabold"
                >
                  attractiveness
                </Box>,{" "}
                <Box 
                  as="span" 
                  bgGradient="linear(to-r, #FF3366, #FF9966)"
                  bgClip="text"
                  fontWeight="extrabold"
                >
                  success
                </Box>
                , and{" "}
                <Box 
                  as="span" 
                  bgGradient="linear(to-r, #FF3366, #FF9966)"
                  bgClip="text"
                  fontWeight="extrabold"
                >
                  shared interests
                </Box>
              </Text>
            </VStack>

            {/* Features */}
            <SimpleGrid 
              columns={2} 
              spacing={{ base: 2, lg: 4 }}
              w="full"
              maxW={{ base: "sm", lg: "md" }}
            >
              {[
                { text: "✨ Selective", showOnMobile: true },
                { text: "🔒 Private", showOnMobile: false },
                { text: "✅ Verified", showOnMobile: true },
                { text: "🌟 1:1 Ratio", showOnMobile: false }
              ].map((feature) => (
                <Box
                  key={feature.text}
                  p={{ base: 2, lg: 3 }}
                  bg="whiteAlpha.200"
                  backdropFilter="blur(10px)"
                  rounded="lg"
                  textAlign="center"
                  borderWidth="1px"
                  borderColor="whiteAlpha.300"
                  _hover={{ transform: "translateY(-2px)" }}
                  transition="all 0.2s"
                  display={{ base: feature.showOnMobile ? "block" : "none", lg: "block" }}
                >
                  <Text 
                    color="white"
                    fontSize={{ base: "sm", lg: "md" }}
                    textShadow="0 1px 2px rgba(0,0,0,0.3)"
                  >
                    {feature.text}
                  </Text>
                </Box>
              ))}
            </SimpleGrid>

            {/* CTAs */}
            <VStack 
              spacing={{ base: 4, lg: 6 }}
              w="full" 
              maxW={{ base: "sm", lg: "md" }}
              pt={{ base: 12, lg: 4 }}
            >
              <Button
                size="lg"
                w="full"
                h={16}
                fontSize="xl"
                fontWeight="bold"
                bg="white"
                color="black"
                rounded="full"
                onClick={() => navigate("/email")}
                _hover={{
                  transform: "translateY(-2px)",
                  shadow: "2xl",
                  bg: "whiteAlpha.900"
                }}
                transition="all 0.2s"
                shadow="lg"
              >
                Create Account
              </Button>

              <Button
                variant="ghost"
                size="lg"
                fontSize="md"
                color="white"
                onClick={() => navigate("/sign_in")}
                _hover={{
                  bg: "whiteAlpha.200",
                  transform: "translateY(-1px)",
                }}
                transition="all 0.2s"
              >
                Already have an account? Sign in
              </Button>
            </VStack>
          </VStack>

          {/* Privacy Note */}
          <Box
            bg="whiteAlpha.200"
            backdropFilter="blur(12px)"
            px={6}
            py={4}
            rounded="xl"
            maxW="sm"
            border="1px solid"
            borderColor="whiteAlpha.200"
          >
            <Text 
              fontSize="sm" 
              color="white"
              textAlign="center"
              textShadow="0 1px 2px rgba(0,0,0,0.2)"
            >
              Your profile data is only used by our algorithm to determine meetup invitations
            </Text>
          </Box>
        </VStack>
      </Container>
    </Box>
  </Box>
);

const HeroSection = ({ navigate, isIOS, isAndroid, isDesktop }) => (
  <Box 
    position="relative"
    minH={{ base: "100vh", lg: "90vh" }}
    display="flex"
    alignItems="center"
    overflow="hidden"
  >
    {/* Background Image with Overlay */}
    <Box
      position="absolute"
      top={0}
      left={0}
      right={0}
      bottom={0}
      zIndex={0}
    >
      <Image
        src="https://firebasestorage.googleapis.com/v0/b/hubzo-app.appspot.com/o/Knightox-Badges-3-1-600x600.png?alt=media&token=59fd4b95-344c-43c7-bc50-85ea9dcb8e5e"
        alt="Hero Background"
        objectFit="contain"
        objectPosition="center"
        w="120%"
        h="120%"
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        filter="brightness(0.6)"
      />
      {/* Gradient Overlay */}
      <Box
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        bgGradient="linear(to-b, rgba(0,0,0,0.4), rgba(0,0,0,0.7))"
      />
    </Box>

    {/* Content */}
    <Container 
      maxW="container.xl" 
      position="relative" 
      zIndex={1}
      pt={{ base: 20, lg: 20 }}
      pb={{ base: 20, lg: 32 }}
    >
      <VStack 
        spacing={{ base: 4, lg: 12 }}
        align={{ base: "center", lg: "start" }}
        maxW={{ base: "full", lg: "50%" }}
      >
        {/* Main Heading */}
        <VStack 
          spacing={6} 
          align={{ base: "center", lg: "start" }}
          textAlign={{ base: "center", lg: "left" }}
        >
          {/* Title - Hidden on mobile */}
          <Box 
            position="relative" 
            display={{ base: "none", lg: "block" }} // Hide on mobile, show on desktop
          >
            <Heading
              fontSize={{ base: "4xl", md: "6xl" }}
              fontWeight="black"
              bgGradient="linear(to-r, #FF3366, #FF9966)"
              bgClip="text"
              lineHeight="1"
              letterSpacing="tight"
              position="relative"
              zIndex={2}
              filter="drop-shadow(0 4px 8px rgba(0,0,0,0.3))"
            >
              Exclusive
            </Heading>
            <Heading
              fontSize={{ base: "4xl", md: "6xl" }}
              fontWeight="black"
              color="white"
              lineHeight="1"
              letterSpacing="tight"
              mt={2}
              position="relative"
              zIndex={2}
              textShadow="0 4px 12px rgba(0,0,0,0.5)"
            >
              meetups
            </Heading>
            <Box
              position="absolute"
              top="-10%"
              left="-5%"
              right="-5%"
              bottom="-10%"
              bg="blackAlpha.400"
              filter="blur(40px)"
              zIndex={1}
              rounded="full"
            />
          </Box>

          <Text 
            fontSize={{ base: "xl", md: "2xl" }} 
            color="white"
            maxW="xl"
            lineHeight="tall"
            letterSpacing="wide"
            position="relative"
            zIndex={2}
            textShadow="0 2px 4px rgba(0,0,0,0.3)"
          >
            Get invited to meetups by our algorithm based on{" "}
            <Box 
              as="span" 
              bgGradient="linear(to-r, #FF3366, #FF9966)"
              bgClip="text"
              fontWeight="extrabold"
            >
              attractiveness
            </Box>
            ,{" "}
            <Box 
              as="span" 
              bgGradient="linear(to-r, #FF3366, #FF9966)"
              bgClip="text"
              fontWeight="extrabold"
            >
              professional success
            </Box>
            , and{" "}
            <Box 
              as="span" 
              bgGradient="linear(to-r, #FF3366, #FF9966)"
              bgClip="text"
              fontWeight="extrabold"
            >
              shared interests
            </Box>
          </Text>
        </VStack>

        {/* Features Grid */}
        <SimpleGrid 
          columns={2} 
          spacing={{ base: 2, lg: 4 }}
          w="full"
          maxW={{ base: "sm", lg: "md" }}
          mb={{ base: 12, lg: 4 }}
        >
          {[
            { text: "✨ Selective", showOnMobile: true },
            { text: "🔒 Private", showOnMobile: false },
            { text: "✅ Verified", showOnMobile: true },
            { text: "🌟 1:1 Ratio", showOnMobile: false }
          ].map((feature) => (
            <Box
              key={feature.text}
              p={{ base: 2, lg: 3 }}
              bg={{ base: "whiteAlpha.100", lg: "whiteAlpha.200" }}
              backdropFilter="blur(10px)"
              rounded="lg"
              textAlign="center"
              borderWidth="1px"
              borderColor={{ base: "whiteAlpha.200", lg: "whiteAlpha.300" }}
              _hover={{ transform: "translateY(-2px)" }}
              transition="all 0.2s"
              display={{ base: feature.showOnMobile ? "block" : "none", lg: "block" }}
            >
              <Text 
                fontSize={{ base: "sm", lg: "md" }}
                color={{ base: "whiteAlpha.900", lg: "white" }}
              >
                {feature.text}
              </Text>
            </Box>
          ))}
        </SimpleGrid>

        {/* CTA Buttons */}
        <VStack 
          spacing={{ base: 4, lg: 6 }}
          w="full" 
          maxW={{ base: "sm", lg: "md" }}
          pt={{ base: 12, lg: 4 }}
        >
          {/* Web Authentication Buttons */}
          <Button
            size="lg"
            w="full"
            h={16}
            fontSize="xl"
            fontWeight="bold"
            bg="white"
            color="black"
            rounded="full"
            onClick={() => navigate("/email")}
            _hover={{
              transform: "translateY(-2px)",
              shadow: "2xl",
              bg: "whiteAlpha.900"
            }}
            transition="all 0.2s"
            shadow="lg"
          >
            Create Account
          </Button>

          <Button
            variant="ghost"
            size="lg"
            fontSize="md"
            color="white"
            onClick={() => navigate("/sign_in")}
            _hover={{
              bg: "whiteAlpha.200",
              transform: "translateY(-1px)",
            }}
            transition="all 0.2s"
          >
            Already have an account? Sign in
          </Button>

          {/* Divider */}
          <HStack w="full" spacing={4} py={2}>
            <Divider borderColor="whiteAlpha.400" />
            <Text color="whiteAlpha.600" fontSize="sm" whiteSpace="nowrap">
              or download app
            </Text>
            <Divider borderColor="whiteAlpha.400" />
          </HStack>

          {/* App Store CTAs */}
          <HStack 
            spacing={4} 
            w="full" 
            justify="center"
          >
            {/* Show App Store button on iOS or desktop */}
            {(isIOS || isDesktop) && (
              <Box
                as="a"
                href="https://apps.apple.com/gb/app/hubzo/id6499236087"
                target="_blank"
                rel="noopener noreferrer"
                h={{ base: "48px", lg: "56px" }}
                transition="transform 0.2s"
                _hover={{ transform: "translateY(-1px)" }}
              >
                <Image
                  src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/white/en-us?size=250x83"
                  alt="Download on the App Store"
                  h={{ base: "48px", lg: "56px" }}
                  style={{ aspectRatio: '2.85/1' }}
                />
              </Box>
            )}

            {/* Show Play Store button on Android or desktop */}
            {(isAndroid || isDesktop) && (
              <Box
                as="a"
                href="https://play.google.com/store/apps/details?id=app.hubzo.android"
                target="_blank"
                rel="noopener noreferrer"
                h={{ base: "64px", lg: "74px" }}
                transition="transform 0.2s"
                _hover={{ transform: "translateY(-1px)" }}
              >
                <Image
                  src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                  alt="Get it on Google Play"
                  h={{ base: "64px", lg: "74px" }}
                  style={{ aspectRatio: '2.85/1' }}
                />
              </Box>
            )}
          </HStack>
        </VStack>

        {/* Privacy Note */}
        <HStack 
          spacing={4} 
          bg="whiteAlpha.200"
          backdropFilter="blur(10px)"
          p={5}
          rounded="xl"
          maxW={{ base: "sm", lg: "md" }}
        >
          <Icon as={FaInfoCircle} color="white" boxSize={5} />
          <VStack align="start" spacing={1}>
            <Text color="whiteAlpha.900" fontSize="sm">
              Your profile data is only used by our algorithm to determine meetup invitations.
            </Text>
            <Text color="whiteAlpha.900" fontSize="sm">
              Profile visibility is optional when joining meetups.
            </Text>
          </VStack>
        </HStack>
      </VStack>
    </Container>
  </Box>
);

const LandingPage = () => {
  const navigate = useNavigate();
  const [isPWA, setIsPWA] = useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [isReturningUser, setIsReturningUser] = useState(false);
  const { isIOS, isAndroid, isDesktop } = useDeviceType();
  const toast = useToast();

  useEffect(() => {
    // Check if running as PWA or native app
    const checkIfPWA = () => {
      // Check if running as standalone PWA
      const isStandalone = window.matchMedia(
        "(display-mode: standalone)"
      ).matches;

      // Check if running from App Store or Play Store
      const isNative = () => {
        // iOS App Store
        const isIOSApp = window.navigator.standalone === true;

        // Check for native Android or iOS app
        const userAgent = window.navigator.userAgent.toLowerCase();
        const isAndroidApp = /hubzo.*android/.test(userAgent);
        const isIOSNative =
          /hubzo.*ios/.test(userAgent) || /iphone.*hubzo/.test(userAgent);

        return isIOSApp || isAndroidApp || isIOSNative;
      };

      return isStandalone || isNative();
    };

    setIsPWA(checkIfPWA());

    // Rest of your existing useEffect code...
    const auth = getAuth();
    const lastSignInTime = localStorage.getItem("lastSignInTime");
    setIsReturningUser(!!lastSignInTime);

    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
    });

    return () => {
      window.removeEventListener("beforeinstallprompt", () => {});
    };
  }, []);

  // If running as PWA or native app, show simplified UI
  if (isPWA) {
    return <SimplifiedLandingPage 
      navigate={navigate} 
      isIOS={isIOS}
      isAndroid={isAndroid}
      isDesktop={isDesktop}
    />;
  }

  const handleInstallClick = async () => {
    if (!deferredPrompt) return;

    deferredPrompt.prompt();
    const { outcome } = await deferredPrompt.userChoice;

    if (outcome === "accepted") {
      setDeferredPrompt(null);
    }
  };

  const colors = {
    textColor: "#2D3748",
    bgColor: "white",
    accentColor: "#2B6CB0",
    accentColorHover: "#2C5282",
    subtitleColor: "#718096",
    cardBg: "gray.50",
    borderColor: "gray.100",
    gradientStart: "rgba(79, 70, 229, 0.1)",
    gradientEnd: "rgba(124, 58, 237, 0.1)",
    primaryGradient: "linear-gradient(135deg, #2B6CB0 0%, #4299E1 100%)",
  };

  const primaryButtonStyles = {
    bg: colors.primaryGradient,
    color: "white",
    px: 8,
    h: 14,
    fontSize: "lg",
    fontWeight: "semibold",
    rounded: "full",
    _hover: {
      transform: "translateY(-2px)",
      shadow: "lg",
      opacity: 0.95,
    },
    transition: "all 0.2s",
  };

  const secondaryButtonStyles = {
    variant: "outline",
    px: 8,
    h: 14,
    fontSize: "lg",
    fontWeight: "semibold",
    rounded: "full",
    borderWidth: 2,
    _hover: {
      transform: "translateY(-2px)",
      shadow: "md",
      bg: "whiteAlpha.100",
    },
    transition: "all 0.2s",
  };

  const cardStyles = {
    bg: colors.bgColor,
    rounded: "2xl",
    overflow: "hidden",
    borderWidth: "1px",
    borderColor: colors.borderColor,
    shadow: "sm",
    _hover: {
      transform: "translateY(-2px)",
      shadow: "md",
      transition: "all 0.2s",
    },
  };

  const sectionHeadingStyles = {
    size: "2xl",
    fontWeight: "extrabold",
    letterSpacing: "-0.02em",
    bgGradient: colors.primaryGradient,
    bgClip: "text",
  };

  const stats = [
    { number: "1:1", label: "Gender Ratio" },
    { number: "100%", label: "Verified Members" },
    { number: "90%", label: "Match Rate" },
  ];

  const steps = [
    {
      step: "1",
      title: "Quick Application",
      description: "Share your interests, lifestyle, and social preferences.",
      image: "https://illustrations.popsy.co/amber/profile.svg",
      highlight: "2 min process",
      color: colors.accentColor,
    },
    {
      step: "2",
      title: "Get Verified",
      description:
        "We ensure all members are real and meet our community standards.",
      image: "https://illustrations.popsy.co/amber/verified.svg",
      highlight: "Quality Check",
      color: colors.accentColor,
    },
    {
      step: "3",
      title: "Receive Invites",
      description: "Get invited to events with people who match your profile.",
      image: "https://illustrations.popsy.co/amber/party.svg",
      highlight: "Perfect Mix",
      color: colors.accentColor,
    },
  ];

  const popularMeetups = [
    {
      title: "Rooftop Socials",
      icon: "🥂",
      color: colors.accentColor,
      description: "Evening drinks at London's finest rooftop bars",
      demographics: "25-35 • Young Professionals",
      frequency: "Weekly",
      avgAttendees: "40 guests • 1:1 ratio",
    },
    {
      title: "Supper Club",
      icon: "✨",
      color: colors.accentColor,
      description: "Dining experiences at trending restaurants",
      demographics: "27-37 • Foodies & Socialites",
      frequency: "Bi-weekly",
      avgAttendees: "24 guests • Perfect mix",
    },
    {
      title: "Weekend Escapes",
      icon: "🌅",
      color: colors.accentColor,
      description: "Curated weekend trips within the UK",
      demographics: "25-35 • Adventure Seekers",
      frequency: "Monthly",
      avgAttendees: "30 guests • 1:1 ratio",
    },
  ];

  const testimonials = [
    {
      name: "Sarah M.",
      role: "Product Designer",
      image:
        "https://firebasestorage.googleapis.com/v0/b/hubzo-app.appspot.com/o/Screenshot%202024-12-09%20at%2002.20.41.png?alt=media&token=449ad663-6c9b-42a9-b10c-3a89d646651b", // Replace with real event photo
      quote:
        "Finally, a social club that gets it! Met the coolest people at my first rooftop social. The vibe was perfect - no awkward networking, just genuine connections.",
      highlight: "First-time member & already hooked! 🎯",
      eventType: "Rooftop Social @ Sky Garden",
      joinedDate: "August 2024",
    },
    {
      name: "Alex K.",
      role: "Tech Entrepreneur",
      image:
        "https://firebasestorage.googleapis.com/v0/b/hubzo-app.appspot.com/o/Screenshot%202024-12-09%20at%2002.33.00%20(1).png?alt=media&token=0da247bc-154e-47a7-b774-95bb745bad11", // Replace with real event photo
      quote:
        "Love how Hubzo keeps the groups small and well-matched. Had amazing conversations at the supper club - from startup ideas to travel stories!",
      highlight: "Best supper club in London! 🍷",
      eventType: "Supper Club @ Sketch",
      joinedDate: "September 2024",
    },
    {
      name: "Maya R.",
      role: "Creative Director",
      image:
        "https://firebasestorage.googleapis.com/v0/b/hubzo-app.appspot.com/o/Screenshot%202024-12-09%20at%2002.26.51.png?alt=media&token=3cec66d5-6866-45f2-a6cd-640abbbcf4c6", // Replace with real event photo
      quote:
        "The weekend escape was exactly what I needed! Great mix of people, perfect organization, and made some genuine friends. Can't wait for the next one!",
      highlight: "Weekend adventure squad found! 🌄",
      eventType: "Weekend Escape to Cotswolds",
      joinedDate: "September 2024",
    },
  ];

  const TopBanner = () => (
    <Box
      position="fixed"
      top={0}
      left={0}
      right={0}
      zIndex={1000}
      bg={{ base: "transparent", md: "white" }}
      h={{ base: "48px", md: "64px" }}
      backdropFilter={{ base: "none", md: "blur(12px)" }}
      backgroundColor={{ base: "transparent", md: "rgba(255, 255, 255, 0.9)" }}
      borderBottom={{ base: "none", md: "1px" }}
      borderColor={{ base: "transparent", md: "gray.100" }}
    >
      <Container maxW="container.xl" h="full" px={{ base: 0, md: 4 }}>
        <Flex
          justify="space-between"
          align="center"
          h="full"
          px={{ base: 3, md: 4 }}
        >
          {/* Logo */}
          <Box
            as="h1"
            fontSize={{ base: "28px", md: "32px" }}
            fontWeight="400"
            letterSpacing="0.35em"
            position="relative"
            display="inline-block"
            cursor="pointer"
            color={{ base: "white", md: "#1A1A1A" }}
            textTransform="uppercase"
            fontFamily="Montserrat, sans-serif"
            _hover={{
              letterSpacing: "0.4em",
              transform: "translateY(-1px)",
              transition: "all 0.4s cubic-bezier(0.4, 0, 0.2, 1)",
            }}
            transition="all 0.4s cubic-bezier(0.4, 0, 0.2, 1)"
            sx={{
              WebkitFontSmoothing: "antialiased",
              textRendering: "optimizeLegibility",
            }}
          >
            <Box
              as="span"
              position="relative"
              _before={{
                content: '""',
                position: "absolute",
                left: "-8%",
                right: "-8%",
                top: "-8px",
                bottom: "-8px",
                background: "linear-gradient(135deg, rgba(26, 26, 26, 0.08), rgba(26, 26, 26, 0.03))",
                backdropFilter: "blur(4px)",
                opacity: { base: 0.95, md: 0.9 },
                borderRadius: "2px",
                transform: "skew(-4deg)",
                transition: "all 0.3s ease",
                zIndex: -1,
              }}
              _after={{
                content: '""',
                position: "absolute",
                left: 0,
                right: 0,
                bottom: "-4px",
                height: "1px",
                background: "linear-gradient(to right, transparent, currentColor, transparent)",
                opacity: 0.4,
                transform: "scaleX(0.8)",
                transition: "transform 0.3s ease",
              }}
              _hover={{
                _after: {
                  transform: "scaleX(1)",
                },
              }}
            >
              hubzo
            </Box>
          </Box>
        </Flex>
      </Container>
    </Box>
  );

  const PWAInstallSection = () =>
    deferredPrompt && (
      <Box py={8} bg="purple.50">
        <Container maxW="container.xl">
          <VStack spacing={6} align="center" textAlign="center">
            <Heading size="lg">Install Hubzo App</Heading>
            <Text color="gray.600" maxW="2xl">
              Get the best experience by installing Hubzo on your device. Access
              your events, messages, and connections with just one tap.
            </Text>

            <HStack spacing={8} wrap="wrap" justify="center">
              <VStack align="start" bg="white" p={4} rounded="xl" shadow="sm">
                <HStack color="green.500">
                  <Icon as={FaCheck} />
                  <Text fontWeight="medium">Works offline</Text>
                </HStack>
                <HStack color="green.500">
                  <Icon as={FaCheck} />
                  <Text fontWeight="medium">Faster loading</Text>
                </HStack>
                <HStack color="green.500">
                  <Icon as={FaCheck} />
                  <Text fontWeight="medium">App-like experience</Text>
                </HStack>
              </VStack>

              <Button
                size="lg"
                onClick={handleInstallClick}
                leftIcon={<Icon as={FaApple} />}
                rightIcon={<FaArrowRight />}
                colorScheme="purple"
              >
                Install Now
              </Button>
            </HStack>

            <Text fontSize="sm" color="gray.500">
              No app store needed • Install directly from browser
            </Text>
          </VStack>
        </Container>
      </Box>
    );

  const TestimonialsSection = () => (
    <Box py={20} bg="white">
      <Container maxW="container.xl">
        <VStack spacing={16}>
          {/* Section Header */}
          <VStack spacing={4} textAlign="center">
            <Text
              color="purple.600"
              fontWeight="semibold"
              fontSize="sm"
              textTransform="uppercase"
              letterSpacing="wide"
            >
              Early Members Love Us
            </Text>
            <Heading
              fontSize={{ base: "3xl", md: "4xl" }}
              fontWeight="bold"
              bgGradient="linear(to-r, purple.500, blue.500)"
              bgClip="text"
            >
              The Hubzo Experience
            </Heading>
            <Text color="gray.600" fontSize="lg" maxW="2xl">
              Join our growing community of fun-loving professionals in London
            </Text>
          </VStack>

          {/* Testimonials Grid */}
          <SimpleGrid columns={{ base: 1, md: 3 }} spacing={8}>
            {testimonials.map((testimonial, index) => (
              <MotionBox
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                viewport={{ once: true }}
              >
                <Box
                  bg="white"
                  rounded="2xl"
                  overflow="hidden"
                  borderWidth="1px"
                  borderColor="gray.100"
                  shadow="lg"
                  position="relative"
                  _hover={{
                    transform: "translateY(-8px)",
                    shadow: "xl",
                  }}
                  transition="all 0.3s"
                >
                  {/* Event Image Section */}
                  <Box position="relative" h="200px">
                    <Image
                      src={testimonial.image}
                      alt={testimonial.eventType}
                      objectFit="cover"
                      w="full"
                      h="full"
                    />
                    {/* Fun badge overlay */}
                    <Box
                      position="absolute"
                      top={4}
                      right={4}
                      bg="white"
                      color="purple.500"
                      px={3}
                      py={1}
                      rounded="full"
                      fontSize="sm"
                      fontWeight="bold"
                      shadow="md"
                      transform="rotate(3deg)"
                    >
                      {testimonial.eventType.split("@")[0]}
                    </Box>
                  </Box>

                  {/* Content Section */}
                  <Box p={6}>
                    {/* Member Info */}
                    <HStack mb={4} spacing={3}>
                      <VStack align="start" spacing={0}>
                        <Text fontWeight="bold" fontSize="lg" color="gray.800">
                          {testimonial.name}
                        </Text>
                        <Text fontSize="sm" color="gray.600">
                          {testimonial.role}
                        </Text>
                      </VStack>
                    </HStack>

                    {/* Highlight Badge */}
                    <Box
                      bg="purple.50"
                      color="purple.600"
                      px={4}
                      py={2}
                      rounded="xl"
                      fontSize="sm"
                      fontWeight="medium"
                      mb={4}
                      display="inline-block"
                      transform="rotate(-1deg)"
                    >
                      {testimonial.highlight}
                    </Box>

                    {/* Quote */}
                    <Text
                      color="gray.700"
                      fontSize="md"
                      lineHeight="tall"
                      fontStyle="italic"
                      mb={4}
                    >
                      "{testimonial.quote}"
                    </Text>

                    {/* Event Details */}
                    <HStack
                      spacing={2}
                      bg="gray.50"
                      p={3}
                      rounded="xl"
                      fontSize="sm"
                      color="gray.600"
                    >
                      <Icon as={FaCalendarCheck} />
                      <Text>{testimonial.eventType}</Text>
                      <Text color="gray.400">•</Text>
                      <Text>{testimonial.joinedDate}</Text>
                    </HStack>
                  </Box>
                </Box>
              </MotionBox>
            ))}
          </SimpleGrid>

          {/* Early Access Note */}
          <Box bg="purple.50" p={6} rounded="2xl" maxW="2xl" textAlign="center">
            <Text fontSize="lg" color="purple.700" fontWeight="medium">
              🚀 Just Launched in London! Early members get priority access to
              exclusive events
            </Text>
          </Box>
        </VStack>
      </Container>
    </Box>
  );

  // Update the handleShare function
  const handleShare = async () => {
    // Only include URL in the message once and handle undefined userCode
    const shareMessage = userProfile?.userCode 
      ? `Hey! I found this exclusive meetup app that connects like-minded professionals. Use my code ${userProfile.userCode} when signing up to boost both our profiles!\n\nhttps://hubzo.social`
      : `Hey! I found this exclusive meetup app that connects like-minded professionals.\n\nhttps://hubzo.social`;

    if (navigator.share) {
      try {
        await navigator.share({
          title: 'Join Exclusive Meetups on Hubzo',
          text: shareMessage,
          // Don't include url here since it's already in the shareMessage
        });
      } catch (error) {
        if (error.name !== 'AbortError') {
          await navigator.clipboard.writeText(shareMessage);
          toast({
            title: "Link copied!",
            description: "Share link has been copied to your clipboard",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        }
      }
    } else {
      try {
        await navigator.clipboard.writeText(shareMessage);
        toast({
          title: "Link copied!",
          description: "Share link has been copied to your clipboard",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } catch (error) {
        console.error('Failed to copy text: ', error);
        toast({
          title: "Error",
          description: "Failed to copy share link",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }
  };

  return (
    <Box
      minH="100vh"
      maxH="100vh"
      overflow="hidden"
      position="relative"
      bg="black"
      overflowX="hidden"
    >
      <TopBanner />
      <Box
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        overflowY="auto"
        overflowX="hidden"
        pb={8}
      >
        <Box pt="0">
          {isReturningUser && <PWAInstallSection />}
          
          <HeroSection 
            navigate={navigate}
            isIOS={isIOS}
            isAndroid={isAndroid}
            isDesktop={isDesktop}
          />

          {/* Footer */}
          <Box
            borderTop="1px"
            borderColor="whiteAlpha.200"
            bg="rgba(0, 0, 0, 0.6)"
            position="relative"
            overflow="hidden"
            w="100%"
          >
            {/* Main Footer Content */}
            <Container maxW="container.xl" py={12}>
              <VStack spacing={12}>
                {/* Top Footer */}
                <Flex
                  direction={{ base: "column", md: "row" }}
                  justify="space-between"
                  align={{ base: "start", md: "center" }}
                  w="full"
                  gap={10}
                >
                  <SimpleGrid columns={{ base: 2, md: 3 }} spacing={10}>
                    <VStack align="start" spacing={4}>
                      <Text
                        fontWeight="semibold"
                        fontSize="sm"
                        textTransform="uppercase"
                        color="white"
                      >
                        Company
                      </Text>
                      <VStack align="start" spacing={3}>
                        <Button
                          variant="link"
                          fontSize="sm"
                          color="whiteAlpha.800"
                          _hover={{ color: "white" }}
                          onClick={() => navigate("/about-us")}
                        >
                          About Us
                        </Button>
                        <Button
                          variant="link"
                          fontSize="sm"
                          color="whiteAlpha.800"
                          _hover={{ color: "white" }}
                          onClick={() =>
                            window.open(
                              "https://www.linkedin.com/company/hubzo",
                              "_blank"
                            )
                          }
                        >
                          Careers
                        </Button>
                        <Button
                          variant="link"
                          fontSize="sm"
                          color="whiteAlpha.800"
                          _hover={{ color: "white" }}
                          onClick={() => navigate("/contact")}
                        >
                          Contact
                        </Button>
                      </VStack>
                    </VStack>

                    <VStack align="start" spacing={4}>
                      <Text
                        fontWeight="semibold"
                        fontSize="sm"
                        textTransform="uppercase"
                        color="white"
                      >
                        Resources
                      </Text>
                      <VStack align="start" spacing={3}>
                        <Button
                          variant="link"
                          fontSize="sm"
                          color="whiteAlpha.800"
                          _hover={{ color: "white" }}
                        >
                          Blog
                        </Button>
                        <Button
                          variant="link"
                          fontSize="sm"
                          color="whiteAlpha.800"
                          _hover={{ color: "white" }}
                        >
                          Community Guidelines
                        </Button>
                        <Button
                          variant="link"
                          fontSize="sm"
                          color="whiteAlpha.800"
                          _hover={{ color: "white" }}
                          onClick={() => navigate("/safety-tips")}
                        >
                          Safety Tips
                        </Button>
                        <Button
                          variant="link"
                          fontSize="sm"
                          color="whiteAlpha.800"
                          _hover={{ color: "white" }}
                          onClick={() => navigate("/faq")}
                        >
                          FAQ
                        </Button>
                      </VStack>
                    </VStack>

                    <VStack align="start" spacing={4}>
                      <Text
                        fontWeight="semibold"
                        fontSize="sm"
                        textTransform="uppercase"
                        color="white"
                      >
                        Legal
                      </Text>
                      <VStack align="start" spacing={3}>
                        <Button
                          variant="link"
                          fontSize="sm"
                          color="whiteAlpha.800"
                          _hover={{ color: "white" }}
                          onClick={() => navigate("/privacy-policy")}
                        >
                          Privacy Policy
                        </Button>
                        <Button
                          variant="link"
                          fontSize="sm"
                          color="whiteAlpha.800"
                          _hover={{ color: "white" }}
                        >
                          Terms of Service
                        </Button>
                        <Button
                          variant="link"
                          fontSize="sm"
                          color="whiteAlpha.800"
                          _hover={{ color: "white" }}
                        >
                          Cookie Policy
                        </Button>
                        <Button
                          variant="link"
                          fontSize="sm"
                          color="whiteAlpha.800"
                          _hover={{ color: "white" }}
                        >
                          Safety
                        </Button>
                      </VStack>
                    </VStack>
                  </SimpleGrid>
                </Flex>

                {/* Bottom Footer */}
                <Flex
                  pt={8}
                  borderTop="1px"
                  borderColor="whiteAlpha.200"
                  w="full"
                  direction={{ base: "column", sm: "row" }}
                  justify="space-between"
                  align="center"
                  gap={4}
                >
                  {/* Copyright text */}
                  <HStack spacing={4} color="white" fontSize="sm">
                    <Text color="whiteAlpha.700">© 2024 Hubzo</Text>
                    <Text color="whiteAlpha.700">•</Text>
                    <Text color="whiteAlpha.700">Made with ❤️ in London</Text>
                  </HStack>

                  {/* Social Icons */}
                  <HStack 
                    spacing={{ base: 3, md: 6 }}
                    flexWrap="wrap"
                    justify={{ base: "center", md: "flex-start" }}
                  >
                    {/* Instagram */}
                    <IconButton
                      aria-label="Instagram"
                      icon={<FaInstagram size={24} />}
                      variant="ghost"
                      color="white"
                      size="lg"
                      rounded="full"
                      _hover={{
                        transform: "translateY(-2px)",
                        color: "#E1306C",
                        bg: "whiteAlpha.200",
                      }}
                      transition="all 0.2s"
                      onClick={() =>
                        window.open(
                          "https://www.instagram.com/hubzo.social/",
                          "_blank"
                        )
                      }
                    />
                    {/* TikTok */}
                    <IconButton
                      aria-label="TikTok"
                      icon={<FaTiktok size={22} />}
                      variant="ghost"
                      color="white"
                      size="lg"
                      rounded="full"
                      _hover={{
                        transform: "translateY(-2px)",
                        color: "#ff0050",
                        bg: "whiteAlpha.200",
                      }}
                      transition="all 0.2s"
                      onClick={() =>
                        window.open(
                          "https://www.tiktok.com/@hubzo.social",
                          "_blank"
                        )
                      }
                    />
                    {/* Facebook */}
                    <IconButton
                      aria-label="Facebook"
                      icon={<FaFacebook size={24} />}
                      variant="ghost"
                      color="white"
                      size="lg"
                      rounded="full"
                      _hover={{
                        transform: "translateY(-2px)",
                        color: "#1877F2",
                        bg: "whiteAlpha.200",
                      }}
                      transition="all 0.2s"
                      onClick={() =>
                        window.open(
                          "https://www.facebook.com/hubzo.social",
                          "_blank"
                        )
                      }
                    />
                    {/* LinkedIn */}
                    <IconButton
                      aria-label="LinkedIn"
                      icon={<FaLinkedin size={24} />}
                      variant="ghost"
                      color="white"
                      size="lg"
                      rounded="full"
                      _hover={{
                        transform: "translateY(-2px)",
                        color: "#0077B5",
                        bg: "whiteAlpha.200",
                      }}
                      transition="all 0.2s"
                      onClick={() =>
                        window.open(
                          "https://www.linkedin.com/company/hubzo",
                          "_blank"
                        )
                      }
                    />
                  </HStack>
                </Flex>
              </VStack>
            </Container>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default LandingPage;
