import { useState } from "react";
import useAuthStore from "../store/authStore";
import useShowToast from "./useShowToast";
import { getDownloadURL, ref, uploadString } from "firebase/storage";
import { firestore, storage } from "../firebase/firebase";
import { doc, updateDoc } from "firebase/firestore";
import useUserProfileStore from "../store/userProfileStore";

const useEditFacePic = () => {
  const [isUpdating, setIsUpdating] = useState(false);
  const showToast = useShowToast();

  const editProfile = async (selectedFile, slot = 'pic1', isRemove = false) => {
    if (isUpdating) return;
    setIsUpdating(true);

    try {
      if (isRemove) {
        localStorage.removeItem(`face-${slot}`);
        setIsUpdating(false);
        return;
      }

      if (selectedFile) {
        const inputId = crypto.randomUUID();
        const storageRef = ref(storage, `profilePics/${inputId}`);
        await uploadString(storageRef, selectedFile, "data_url");
        const URL = await getDownloadURL(ref(storage, `profilePics/${inputId}`));
        localStorage.setItem(`face-${slot}`, URL);
      }
    } catch (error) {
      showToast("Error", error.message, "error");
    } finally {
      setIsUpdating(false);
    }
  };

  return { editProfile, isUpdating };
};

export default useEditFacePic;
