import {
  Container,
  Flex,
  Button,
  Skeleton,
  SkeletonCircle,
  useDisclosure,
  Spacer,
  Text,
  Box,
  Menu,
  MenuButton,
  Avatar,
  Center,
  AvatarBadge,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialog,
  MenuList,
  MenuItem,
  MenuDivider,
  Stack,
  VStack,
  RadioGroup,
  Input,
  Textarea,
  useColorModeValue,
  InputGroup,
  Radio,
  InputLeftElement,
  Card,
  CardBody,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Divider,
} from "@chakra-ui/react";
import GroupPageFeedPosts from "../../components/FeedPosts/GroupPageFeedPosts";
import GroupProfileHeader from "../../components/Profile/GroupProfileHeader";
import ProfileTabs from "../../components/Profile/ProfileTabs";
import ProfilePosts from "../../components/Profile/ProfilePosts";
import useGetGroupProfileByUsername from "../../hooks/useGetGroupProfileByUsername";
import { useParams } from "react-router-dom";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

import useAuthStore from "../../store/authStore";
import {
  AddIcon,
  SearchIcon,
  CalendarIcon,
  ArrowBackIcon,
  WarningIcon,
  NotAllowedIcon,
} from "@chakra-ui/icons";
import { useRef, useState } from "react";
import { StandaloneSearchBox, useJsApiLoader } from "@react-google-maps/api";
import useShowToast from "../../hooks/useShowToast";
import usePostStore from "../../store/postStore";
import useUserProfileStore from "../../store/userProfileStore";
import { useLocation } from "react-router-dom";
import usePreviewImg from "../../hooks/usePreviewImg";
import usePostPreviewImg from "../../hooks/usePostPreviewImg";
import { firestore, storage } from "../../firebase/firebase";
import { getDownloadURL, ref, uploadString } from "firebase/storage";

import {
  ShareLightLogo,
  ShareBlackLogo,
  CommentLogo,
  CommentLightLogo,
  PersonLogo,
  MessagesLogo,
  NotificationsLogo,
  UnlikeLogo,
  SearchLogo,
  WhatsappLogo,
  WhatsappGreenLogo,
} from "../../assets/constants";

import { BsThreeDotsVertical } from "react-icons/bs";

import {
  addDoc,
  where,
  getDocs,
  query,
  arrayUnion,
  collection,
  doc,
  getDoc,
  updateDoc,
} from "firebase/firestore";

import useReportUser from "../../hooks/useReportUser";
import useBlockUser from "../../hooks/useBlockUser";

const GroupPage = () => {
  const libraries = ["places"];
  const inputRef = useRef();

  const { postId } = useParams();
  // const { userProfile } = useUserProfileStore();

  const { isLoading, userProfile, userId } =
    useGetGroupProfileByUsername(postId);
  const authUser = useAuthStore((state) => state.user);

  const userNotFound = !isLoading && !userProfile;
  if (userNotFound) return <UserNotFound />;

  const visitingOwnProfileAndAuth = authUser && authUser.username === postId;

  const bgTheme = useColorModeValue(`white`, `black`);
  const bgNewEventTheme = useColorModeValue("gray.100", "#000");
  const bgThemez = useColorModeValue(`white`, `black`);
  const { handleImageChange, selectedFile, setSelectedFile } = usePreviewImg();
  const { handlePostImageChange, selectedPostFile, setSelectedPostFile } =
    usePostPreviewImg();

  const navigate = useNavigate();
  const cancelRef = useRef();

  const {
    isOpen: isOpenModal,
    onOpen: onOpenModal,
    onClose: onCloseModal,
  } = useDisclosure();

  const ShareThemeLogo = useColorModeValue(ShareLightLogo, ShareBlackLogo);

  const { isOpen, onOpen, onClose } = useDisclosure();

  async function share() {
    try {
      await navigator.share({
        // title: `${post.title}`,
        text: `*${userProfile.fullName}*\n📋${userProfile.bio}\n@${
          userProfile.username
        }\n🔗`,
        url: `https://hubzo.co/${userProfile.username}`,
      });
    } catch (error) {
      console.log("Sharing failed!", error);
    }
  }

  const {
    isOpen: isOpenCreateModel,
    onOpen: onOpenCreateModel,
    onClose: onCloseCreateModel,
  } = useDisclosure();

  const showToast = useShowToast();

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [title, setTitle] = useState("");
  const [caption, setCaption] = useState("");
  // const [location, setLocation] = useState("");
  const [whatsapp, setWhatsapp] = useState("");

  const [location, setLocation] = useState("");

  const [placeLocation, setPlaceLocation] = useState("");
  const [address, setAddress] = useState("");

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: import.meta.env.VITE_GOOGLE_API_KEY,
    libraries,
  });

  const { isLoadingz, handleCreatePost } = useCreatePost(userId);

  const [reportReason, setReportReason] = useState("behaviour");

  const {
    isOpen: isOpenReportModal,
    onOpen: onOpenReportModal,
    onClose: onCloseReportModal,
  } = useDisclosure();

  const {
    isOpen: isOpenBlockModal,
    onOpen: onOpenBlockModal,
    onClose: onCloseBlockModal,
  } = useDisclosure();

  const { isReportUpdating, handleReportUser } = useReportUser(
    userId,
    authUser ? authUser.uid : "",
    reportReason,
    "group"
  );

  const { isBlockUpdating, handleBlockUser } = useBlockUser(
    userId,
    authUser ? authUser.uid : ""
  );

  const handlePostCreation = async () => {
    try {
      await handleCreatePost(
        selectedPostFile,
        title,
        caption,
        location,
        whatsapp,
        startDate,
        endDate,
        placeLocation,
        address
      );
      onCloseCreateModel();
      setStartDate("");
      setEndDate("");
      setTitle("");
      setCaption("");
      setLocation("");
      setSelectedFile(null);
      setSelectedPostFile(null);
      setPlaceLocation("");
      setWhatsapp("");
    } catch (error) {
      showToast("Error", error.message, "error");
    }
  };

  const handlePlaceChanged = () => {
    const [place] = inputRef.current.getPlaces();
    if (place) {
      setLocation(place.name);
      setPlaceLocation(place.url);
      setAddress(place.formatted_address);
      console.log(place.formatted_address);
      console.log(place.name);
      console.log(place.geometry.location.lat());
      console.log(place.geometry.location.lng());
    }
  };

  const fileRef = useRef(null);

  return (
    <Container px={0} 
     maxW="container.lg"
     
     py={0}
    
    bg={bgThemez}
    // bg={"red"}

    >
      <Flex
        py={0}
        px={0}
        // pl={{ base: 4, md: 10 }}
        w={"full"}
        // mx={"auto"}
        flexDirection={"column"}
      >


        {!isLoading && userProfile && (
          <Flex direction={"column"}>
            <Box bg={useColorModeValue(`white`, `gray.800`)} px={4}>
              <Flex
                h={16}
                alignItems={"center"}
                justifyContent={"flex-start"}
                w={"full"}
              >
                <Flex alignItems={"flex-start"}>
                  <Stack direction={"row"} spacing={7}>
                    {/* <Button
                  onClick={() => {
                    navigate(-1);
                  }}
                > */}
                    <ArrowBackIcon
                      boxSize={6}
                      onClick={() => {
                        navigate(-1);
                      }}
                    />
                    {/* {colorMode === "light" ? <MoonIcon /> : <SunIcon />} */}
                    {/* </Button> */}
                  </Stack>
                </Flex>

                <Box
                  // w={'250px'}
                  // minW={'250px'}
                  // maxW={'300px'}
                  // bg={"red"}
                  ml={6}
                >
                  {/* {
                    userProfile && (

                      <Text
                      pl={0}
                      fontSize={24}
                      // fontWeight={"bold"}
                      // color={"green.300"}
                    >
                      {userProfile.username}
                    </Text>
                    )
                  } */}

                  <VStack spacing={0}>
                    <Text
                      alignSelf={"flex-start"}
                      pl={0}
                      fontSize={20}
                      fontWeight={"semibold"}
                      // color={"green.300"}
                    >
                      Group
                    </Text>

                    <Text
                      alignSelf={"flex-start"}
                      pl={0}
                      fontSize={14}
                      // fontWeight={"bold"}
                      // color={"green.300"}
                    >
                      {`@${userProfile.username}`}
                    </Text>
                  </VStack>
                </Box>

                

                <Spacer />

                {/* <ArrowBackIcon
                  boxSize={6}
                  onClick={() => {
                    navigate(-1);
                  }}
                /> */}

                <Flex
                  gap={4}
                  onClick={() => {
                    if (navigator.canShare) {
                      share();
                    }
                  }}
                  alignItems={"center"}
                  flexDirection={"row"}
                >
                  <ShareThemeLogo boxSize={6} />

                  {/* <BsThreeDotsVertical boxSize={6} /> */}
                </Flex>

                <Flex pl={4} alignItems={"center"}>
                  <Menu>
                    <MenuButton
                      as={Button}
                      rounded={"full"}
                      variant={"link"}
                      cursor={"pointer"}
                      minW={0}
                    >
                      <BsThreeDotsVertical boxSize={8} />

                      {/* <Avatar
                  size={'sm'}
                  src={
                    'https://images.unsplash.com/photo-1493666438817-866a91353ca9?ixlib=rb-0.3.5&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&s=b616b2c5b373a80ffc9636ba24f7a4a9'
                  }
                /> */}
                    </MenuButton>
                    <MenuList>
                      <MenuItem
                        onClick={() => {
                          if (!authUser) {
                            showToast(
                              "Error",
                              "You must be logged in",
                              "error"
                            );

                            return;
                          } else {
                            onOpenReportModal();
                          }
                        }}
                      >
                        <Flex
                          gap={4}
                          alignItems={"center"}
                          flexDirection={"row"}
                        >
                          <WarningIcon ml={2} />
                          <Text>Report</Text>
                        </Flex>
                      </MenuItem>
                      <MenuDivider />
                      <MenuItem
                        onClick={() => {
                          if (!authUser) {
                            showToast(
                              "Error",
                              "You must be logged in",
                              "error"
                            );
                            return;
                          } else {
                            onOpenBlockModal();
                          }
                        }}
                      >
                        <Flex
                          gap={4}
                          alignItems={"center"}
                          flexDirection={"row"}
                          colorScheme={"red"}
                        >
                          <NotAllowedIcon ml={2} />
                          <Text>Block</Text>
                        </Flex>
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </Flex>

                {/* <IconButton
                mr={0}
                alignSelf={"start"}
                size={"sm"}
                onClick={() => {
                  if (navigator.canShare) {
                    share();
                  }
                }}
                // isRound={true}

                variant="outline"
                colorScheme="whatsapp"
                aria-label="Send email"
                icon={<ShareThemeLogo />}
              /> */}
              </Flex>
            </Box>

            <Divider mt={0} bg={"gray.8000"} />


            <GroupProfileHeader userId={userId} />
          </Flex>
        )}
        {isLoading && <ProfileHeaderSkeleton />}
      </Flex>

      {!isLoading && userProfile && !isLoadingz && (
        <Flex
          // bg={"red"}
          px={{ base: 0, sm: 4 }}
          // maxW={"full"}
          // mx={"auto"}
          // borderTop={"1px solid"}
          borderColor={"whiteAlpha.300"}
          direction={"column"}
        >
          {/* <ProfileTabs /> */}
          {/* <ProfilePosts /> */}

          <GroupPageFeedPosts uid={userId} />
        </Flex>
      )}

      <Flex 
      pt={6}
      
      bg={bgNewEventTheme}
      mb={32} justify={"center"}>
        {!isLoading &&
          userProfile 
          // &&
          // userProfile.admins &&
          // userProfile.admins.length > 0 &&
          // authUser 
          // &&
          // userProfile.admins.includes(authUser.uid) 
          && (
            <Button
              leftIcon={<AddIcon />}
              mt={0}
              mb={8}
              size="sm"
              colorScheme="green"
              variant="outline"
              // ml={3}
              onClick={() => {
                if (!authUser) {
                  onOpenModal();
                  return;
                } else {
                  onOpenCreateModel();
                }
              }}
            >
              New event
            </Button>
          )}
      </Flex>

      <Modal
        size={"full"}
        isOpen={isOpenCreateModel}
        onClose={onCloseCreateModel}
      >
        <ModalOverlay />
        <ModalContent bg={bgTheme} border={"1px solid gray"} maxW={"xl"}>
          <ModalHeader mt={4}>
            <Text userSelect={"none"} mb={0} fontSize={22}>
              New event
            </Text>
          </ModalHeader>
          <ModalCloseButton size={"lg"} mt={4} />
          <ModalBody pb={0}>
            <Card mt={0} pb={0} w={"full"} mb={0}>
              <CardBody pl={4} pt={1} pb={0}>
                <Flex
                  pb={4}
                  px={1}
                  w={"full"}
                  mx={"auto"}
                  flexDirection={"column"}
                >
                  <Stack
                    my={2}
                    onClick={() => fileRef.current.click()}
                    direction={["column", "row"]}
                    spacing={6}
                  >
                    <Center>
                      <Avatar
                        // name={authUser.fullName}
                        size="lg"
                        src={
                          selectedPostFile
                          //  || authUser.profilePicURL
                        }
                        border={"2px solid white "}
                      >
                        <AvatarBadge bg={"blue"} boxSize="1em">
                          <svg width="0.4em" fill="white" viewBox="0 0 20 20">
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M4 5a2 2 0 00-2 2v8a2 2 0 002 2h12a2 2 0 002-2V7a2 2 0 00-2-2h-1.586a1 1 0 01-.707-.293l-1.121-1.121A2 2 0 0011.172 3H8.828a2 2 0 00-1.414.586L6.293 4.707A1 1 0 015.586 5H4zm6 9a3 3 0 100-6 3 3 0 000 6z"
                            />
                          </svg>
                        </AvatarBadge>
                      </Avatar>

                      <Text
                        //  fontWeight={"semibold"}
                        mx="4"
                      >
                        Tap here to select a picture
                      </Text>

                      {/* 
                      <EditIcon
                        onClick={() => fileRef.current.click()}
                        boxSize={6}
                      /> */}
                    </Center>
                    {/* <Center w="full">
                      <Button
                        w="full"
                        colorScheme="gray"
                        variant={"solid"}
                        onClick={() => fileRef.current.click()}
                      >
                        Update Profile Picture
                      </Button>
                    </Center> */}
                    <Input
                      type="file"
                      hidden
                      size={"lg"}
                      ref={fileRef}
                      accept="image/*"
                      onChange={handlePostImageChange}
                    />
                  </Stack>

                  <Text
                    fontWeight={"semibold"}
                    userSelect={"none"}
                    mt={4}
                    w={"full"}
                    mb={0}
                    fontSize={16}
                  >
                    Start Time:
                  </Text>

                  <InputGroup mt={1} mb={0}>
                    <InputLeftElement mt={1} pointerEvents="none">
                      <CalendarIcon />
                    </InputLeftElement>

                    <Input
                      size="lg"
                      type="datetime-local"
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                  </InputGroup>

                  <Text
                    fontWeight={"semibold"}
                    userSelect={"none"}
                    mt={4}
                    w={"full"}
                    mb={0}
                    fontSize={16}
                  >
                    End Time:
                  </Text>

                  <InputGroup mt={1} mb={0}>
                    <InputLeftElement mt={1} pointerEvents="none">
                      <CalendarIcon />
                    </InputLeftElement>

                    <Input
                      size="lg"
                      type="datetime-local"
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                  </InputGroup>

                  <Text
                    userSelect={"none"}
                    mt={6}
                    fontWeight={"semibold"}
                    w={"full"}
                    mb={0}
                    fontSize={16}
                  >
                    Title
                  </Text>

                  <Input
                    mt={1}
                    size="lg"
                    type="text"
                    // placeholder="Title..."
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />

                  <Text
                    userSelect={"none"}
                    mt={6}
                    w={"full"}
                    mb={0}
                    fontWeight={"semibold"}
                    fontSize={16}
                  >
                    Description
                  </Text>

                  <Textarea
                    mt={1}
                    mb={4}
                    size="lg"
                    // placeholder="What's happening..."
                    value={caption}
                    onChange={(e) => setCaption(e.target.value)}
                  />

                  <Text
                    userSelect={"none"}
                    mt={6}
                    w={"full"}
                    mb={0}
                    fontWeight={"semibold"}
                    fontSize={16}
                  >
                    Event location
                  </Text>

                  {isLoaded && (
                    <StandaloneSearchBox
                      onLoad={(ref) => (inputRef.current = ref)}
                      onPlacesChanged={handlePlaceChanged}
                    >
                      <input
                        type="text"
                        className="form-control"
                        placeholder="📍 Find location"
                        style={{
                          marginTop: "4px",
                          marginBottom: "32px",
                          background: {},
                          borderRadius: `4px`,
                          boxSizing: `border-box`,
                          border: `1px solid transparent`,
                          width: "100%",
                          height: `32px`,
                          padding: `0 12px`,
                          borderRadius: `3px`,
                          boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                          fontSize: `14px`,
                          outline: `none`,
                          textOverflow: `ellipses`,
                          marginBottom: "8px",
                          outlineColor: "#555",
                          outlineWidth: "thin",
                          outlineStyle: "solid",
                        }}
                      />
                    </StandaloneSearchBox>
                  )}
                </Flex>
              </CardBody>
            </Card>
          </ModalBody>

          <ModalFooter>
            <Button mb={2} mr={4} onClick={onCloseCreateModel}>
              Cancel
            </Button>
            <Button
              variant={"outline"}
              colorScheme="whatsapp"
              mb={2}
              mr={0}
              onClick={handlePostCreation}
              isLoading={isLoading}
            >
              Create
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={isOpenReportModal}
        onClose={onCloseReportModal}
        isCentered={true}
        size={{ base: "lg", md: "xl" }}
      >
        <ModalOverlay />
        <ModalContent bg={bgTheme} border={"1px solid gray"} maxW={"400px"}>
          <ModalHeader mt={4}>Report this person</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={5}>
            <Flex px={1} w={"full"} mx={"auto"} flexDirection={"column"}>
              <Flex gap="4">
                <RadioGroup onChange={setReportReason} value={reportReason}>
                  <Stack spacing={5} direction="column">
                    <Radio size="md" value="behaviour">
                      Inappropriate behavior
                    </Radio>
                    <Radio size="md" value="scam">
                      Scam or fraud
                    </Radio>
                    <Radio size="md" value="impersonation">
                      Impersonation
                    </Radio>
                    <Radio size="md" value="underage">
                      Underage user
                    </Radio>
                    <Radio size="md" value="offensive">
                      Offensive or inappropriate content
                    </Radio>
                    <Radio size="md" value="suspicious">
                      Suspicious or malicious activity
                    </Radio>
                    <Radio size="md" value="violation">
                      Violation of terms of service
                    </Radio>
                  </Stack>
                </RadioGroup>
              </Flex>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button
              userSelect={"none"}
              mb={2}
              mr={4}
              onClick={onCloseReportModal}
              // isLoading={isLoading}
            >
              Cancel
            </Button>

            <Button
              mb={2}
              mr={0}
              colorScheme="red"
              variant={"outline"}
              onClick={() => {
                // if (value === "hide") {
                handleReportUser();
                onCloseReportModal();
                // } else {
                //   onCloseManageLinkModal();
                // }
              }}
              isLoading={isReportUpdating}
            >
              Confirm
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={isOpenBlockModal}
        onClose={onCloseBlockModal}
        isCentered={true}
        size={{ base: "lg", md: "xl" }}
      >
        <ModalOverlay />
        <ModalContent bg={bgTheme} border={"1px solid gray"} maxW={"400px"}>
          <ModalHeader mt={2}></ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={5}>
            <Flex py={1} px={1} w={"full"} mx={"auto"} flexDirection={"column"}>
              <Flex
                spacing={"8"}
                alignItems={"center"}
                direction={"column"}
                gap="4"
              >
                <NotAllowedIcon color={"red"} boxSize={10} />

                <Text fontSize={"xl"}>
                  Do you really want to block this group?
                </Text>
              </Flex>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button
              userSelect={"none"}
              mb={2}
              mr={4}
              onClick={onCloseBlockModal}
              // isLoading={isLoading}
            >
              Cancel
            </Button>

            <Button
              mb={2}
              mr={0}
              colorScheme="red"
              variant={"outline"}
              onClick={() => {
                // if (value === "hide") {
                handleBlockUser();
                onCloseBlockModal();
                // } else {
                //   onCloseManageLinkModal();
                // }
              }}
              isLoading={isBlockUpdating}
            >
              Yes
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onCloseModal}
        isOpen={isOpenModal}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>Are you a member? 🤔</AlertDialogHeader>
          {/* <AlertDialogCloseButton /> */}
          <AlertDialogBody>Only members can see this.</AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onCloseModal}>
              Cancel
            </Button>

            <Link to="/auth">
              <Button
                variant={"outline"}
                onClick={onCloseModal}
                colorScheme="whatsapp"
                ml={3}
              >
                Login
              </Button>
            </Link>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

    </Container>
  );
};

export default GroupPage;

// skeleton for profile header
const ProfileHeaderSkeleton = () => {
  return (
    <Flex
      gap={{ base: 4, sm: 10 }}
      py={10}
      direction={{ base: "column", sm: "row" }}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <SkeletonCircle size="24" />

      <VStack
        alignItems={{ base: "center", sm: "flex-start" }}
        gap={2}
        mx={"auto"}
        flex={1}
      >
        <Skeleton height="12px" width="150px" />
        <Skeleton height="12px" width="100px" />
      </VStack>
    </Flex>
  );
};

const UserNotFound = () => {
  return (
    <Flex mt={8} flexDir="column" textAlign={"center"} mx={"auto"}>
      <Text userSelect={"none"} fontSize={"2xl"}>
        User Not Found
      </Text>
      <Link
        as={RouterLink}
        to={"/"}
        color={"blue.500"}
        w={"max-content"}
        mx={"auto"}
      >
        Go home
      </Link>
    </Flex>
  );
};

function useCreatePost(userId) {
  const showToast = useShowToast();
  const [isLoadingz, setIsLoadingz] = useState(false);
  const authUser = useAuthStore((state) => state.user);
  const createPost = usePostStore((state) => state.createPost);
  const addPost = useUserProfileStore((state) => state.addPost);
  const userProfile = useUserProfileStore((state) => state.userProfile);
  const { pathname } = useLocation();

  const handleCreatePost = async (
    selectedPostFile,
    title,
    caption,
    location,
    whatsapp,
    startDate,
    endDate,
    placeLocation,
    address,
    value
  ) => {
    if (isLoadingz || !authUser) {
      return;
    }

    if (!authUser) {
      showToast("Error", "You must be logged in", "error");
      return;
    }
    // if (!selectedFile) throw new Error("Please select an image");

    setIsLoadingz(true);

    let URL = "";

    if (selectedPostFile) {
      let randomNumber = Math.floor(Math.random() * 100);

      const storageRef = ref(storage, `postPics/${userId}${randomNumber}`);

      await uploadString(storageRef, selectedPostFile, "data_url");
      URL = await getDownloadURL(
        ref(storage, `postPics/${userId}${randomNumber}`)
      );
    }

    const newPost = {
      title: title,
      caption: caption,
      placeName: location,
      whatsapp: whatsapp,
      likes: [],
      goingList: [],
      comments: [],
      createdAt: Date.now(),
      createdBy: userId,
      startAt: new Date(startDate),
      endAt: new Date(endDate),
      placeLocation: placeLocation,
      address: address,
      postedBy: authUser.uid,
      postPicURL: URL,
      places: 13,
    };

    try {
      const postDocRef = await addDoc(collection(firestore, "events"), newPost);
      const userDocRef = await getDoc(doc(firestore, "groups", userId));
      // const imageRef = ref(storage, `posts/${postDocRef.id}`);

      // if (userDocRef.exists()) {
      //   const userDocRefz = doc(firestore, "groups", value);

      //   await updateDoc(userDocRefz, { posts: arrayUnion(postDocRef.id) });
      // }

      // await updateDoc(userDocRef, { posts: arrayUnion(postDocRef.id) });
      // await uploadString(imageRef, selectedFile, "data_url");
      // const downloadURL = await getDownloadURL(imageRef);

      // await updateDoc(postDocRef, { imageURL: downloadURL });
      // await updateDoc(postDocRef, {
      //   imageURL:
      //     "https://www.meetup.com/_next/image/?url=https%3A%2F%2Fsecure-content.meetupstatic.com%2Fimages%2Fclassic-events%2F508388773%2F676x380.webp&w=3840&q=75",
      // });

      // newPost.imageURL = downloadURL;

      // if (userProfile.uid === authUser.uid)
      // createPost({ ...newPost, id: postDocRef.id });

      // if (pathname !== "/" && userProfile.uid === authUser.uid)
      // addPost({ ...newPost, id: postDocRef.id });

      showToast("Success", "Post created successfully", "success");
    } catch (error) {
      showToast("Error", error.message, "error");
    } finally {
      setIsLoadingz(false);
    }
  };

  return { isLoadingz, handleCreatePost };
}
