import {
  Box,
  VStack,
  HStack,
  Text,
  Image,
  useColorModeValue,
  Divider,
  Spinner,
  Badge,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { FaCalendarAlt, FaMapMarkerAlt } from "react-icons/fa";
import useFetchEventDetails from "../hooks/useFetchEventDetails";

const TicketItem = ({ ticket }) => {
  const navigate = useNavigate();
  const { eventDetails, loading, error } = useFetchEventDetails(ticket.eventId);

  const textColor = useColorModeValue("gray.800", "whiteAlpha.900");
  const mutedTextColor = useColorModeValue("gray.500", "gray.400");
  const bgColor = useColorModeValue("white", "gray.800");
  const borderColor = useColorModeValue("gray.200", "gray.700");

  const handleClick = () => {
    navigate(`/t/${ticket.eventId}`);
  };

  if (loading) return <Spinner size="sm" />;
  if (error) return <Text color="red.500">Error loading event details</Text>;

  return (
    <Box
      onClick={handleClick}
      bg={bgColor}
      borderRadius="xl"
      borderWidth="1px"
      borderColor={borderColor}
      overflow="hidden"
      transition="all 0.2s"
      cursor="pointer"
      _hover={{ 
        transform: "translateY(-2px)",
        shadow: "md",
        borderColor: "blue.500" 
      }}
      h="full"
    >
      {/* Event Thumbnail */}
      {eventDetails.postPicURL && (
        <Image
          src={eventDetails.postPicURL}
          alt="Event Thumbnail"
          w="full"
          h="200px"
          objectFit="cover"
        />
      )}

      <VStack align="stretch" p={4} spacing={4}>
        {/* Event Title */}
        <VStack align="start" spacing={1}>
          <Text 
            fontSize={{ base: "lg", md: "xl" }} 
            fontWeight="bold" 
            color={textColor}
            noOfLines={2}
          >
            {eventDetails.title}
          </Text>
          
          {ticket.guestCount > 0 && (
            <Badge colorScheme="blue">
              +{ticket.guestCount} guests
            </Badge>
          )}
        </VStack>

        {/* Event Details */}
        <VStack align="start" spacing={2}>
          <HStack spacing={2}>
            <FaCalendarAlt color={mutedTextColor} />
            <Text fontSize="sm" color={textColor}>
              {eventDetails.formattedEventDate}
            </Text>
          </HStack>

          <HStack spacing={2}>
            <FaMapMarkerAlt color={mutedTextColor} />
            <Text fontSize="sm" color={textColor} noOfLines={1}>
              {eventDetails.placeName}
            </Text>
          </HStack>
        </VStack>
      </VStack>
    </Box>
  );
};

export default TicketItem;
