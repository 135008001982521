import {
  Container,
  Flex,
  Text,
  Box,
  Divider,
  Stack,
  HStack,
  IconButton,
  VStack,
  SkeletonText,
  SimpleGrid,
  Image,
  Icon,
  Input,
} from "@chakra-ui/react";
import { ChevronRightIcon, ArrowBackIcon, AddIcon, CloseIcon, CheckIcon } from "@chakra-ui/icons";
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import useAuthStore from "../../store/authStore";
import useGetUserProfileById from "../../hooks/useGetUserProfileById";
import {
  FaInstagram,
  FaImage,
  FaLinkedin,
  FaUser,
  FaBuilding,
  FaEnvelope,
  FaCalendar,
  FaCity,
} from "react-icons/fa";
import { BsFillPeopleFill } from "react-icons/bs";
import useUpdateFacePic from "../../hooks/useUpdateFacePic";
import useShowToast from "../../hooks/useShowToast";

const EditProfilePage = () => {
  const authUser = useAuthStore((state) => state.user);
  const { isLoading, userProfile, refetch } = useGetUserProfileById(authUser?.uid);
  const navigate = useNavigate();
  const fileRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const { isUpdating, editProfile } = useUpdateFacePic();
  const showToast = useShowToast();
  const [selectedSlot, setSelectedSlot] = useState('pic1');

  const colors = {
    textColor: "gray.900",
    bgColor: "white",
    cardBg: "white",
    dividerColor: "gray.200",
    iconBgColor: "rgba(184, 160, 128, 0.1)",
    hoverBgColor: "rgba(184, 160, 128, 0.05)",
    subtitleColor: "gray.600",
    accentColor: "#B8A080",
    borderColor: "rgba(184, 160, 128, 0.2)",
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 2 * 1024 * 1024) { // 2MB limit
        showToast("Error", "File size must be less than 2MB", "error");
        return;
      }
      
      const reader = new FileReader();
      reader.onloadend = async () => {
        setSelectedFile(reader.result);
        try {
          await editProfile(reader.result, selectedSlot);
          setSelectedFile(null);
        } catch (error) {
          showToast("Error", error.message, "error");
          setSelectedFile(null);
        }
      };
      reader.onerror = () => {
        showToast("Error", "Failed to read file", "error");
        setSelectedFile(null);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRemovePhoto = async (slot) => {
    try {
      await editProfile(null, slot, true);
    } catch (error) {
      showToast("Error", error.message, "error");
    }
  };

  const handleSlotClick = (slot) => {
    setSelectedSlot(slot);
    fileRef.current.click();
  };

  const getImageUrlForSlot = (slot) => {
    if (slot === 'pic1' && !authUser?.profilePicURLs) {
      return authUser?.profilePicURL;
    }
    return authUser?.profilePicURLs?.[slot]?.url;
  };

  useEffect(() => {
    console.log('userProfile updated:', userProfile?.profilePicURLs);
  }, [userProfile]);

  return (
    <Box
      h="100vh"
      maxH="100vh"
      overflow="hidden"
      position="relative"
      bg={colors.bgColor}
      pb="env(safe-area-inset-bottom)"
    >
      <Box
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        overflowY="auto"
        pb={{ base: 32, md: 8 }}
        style={{
          WebkitOverflowScrolling: 'touch'
        }}
      >
        <Container 
          maxW={{ base: "container.md", md: "container.xl" }} 
          px={4} 
          py={6}
          mb={{ base: 8, md: 0 }}
        >
          {/* Header */}
          <Flex 
            w="full" 
            alignItems="center" 
            mb={8}
            position="relative"
          >
            <IconButton
              icon={<ArrowBackIcon />}
              variant="ghost"
              aria-label="Go back"
              onClick={() => navigate(-1)}
              display={{ base: "flex", md: "none" }}
              color={colors.accentColor}
              _hover={{
                bg: colors.iconBgColor,
                transform: "translateY(-2px)",
              }}
              transition="all 0.2s"
            />
            <Text 
              fontSize={{ base: "2xl", md: "3xl" }}
              fontWeight="light"
              color={colors.textColor}
              textAlign={{ base: "left", md: "center" }}
              flex="1"
              ml={{ base: 4, md: 0 }}
              letterSpacing="wide"
              fontFamily="Montserrat, sans-serif"
            >
              Edit profile
            </Text>
          </Flex>

          {/* Main Content */}
          <Box 
            maxW={{ base: "full", md: "2xl" }} 
            mx="auto"
          >
            {/* Pictures Section */}
            {!userProfile?.isCreator && (
              <Box mb={8}>
                <Box
                  borderRadius="xl"
                  overflow="hidden"
                  borderWidth="1px"
                  borderColor={colors.borderColor}
                  bg={colors.cardBg}
                  shadow="sm"
                  _hover={{ shadow: "md" }}
                  transition="all 0.2s"
                >
                  {/* Header with Icon */}
                  <Box w="full" bg={colors.cardBg} p={4}>
                    <HStack spacing={4} mb={4}>
                      <Box 
                        p={3}
                        bg={colors.iconBgColor}
                        borderRadius="full"
                        color={colors.accentColor}
                      >
                        <FaImage />
                      </Box>
                      <Text
                        fontWeight="medium"
                        fontSize={{ base: "md", md: "lg" }}
                        color={colors.textColor}
                        fontFamily="Montserrat, sans-serif"
                      >
                        Your Pictures
                      </Text>
                    </HStack>

                    {/* Picture Grid */}
                    <SimpleGrid columns={{ base: 2, md: 3 }} spacing={4} w="full">
                      {[1, 2, 3, 4].map((num) => {
                        const slot = `pic${num}`;
                        const imageUrl = getImageUrlForSlot(slot);

                        return (
                          <Box
                            key={num}
                            position="relative"
                            cursor="pointer"
                            role="group"
                            onClick={() => !imageUrl && handleSlotClick(slot)}
                          >
                            <Box
                              w="full"
                              h="0"
                              pb="100%" // Makes box square
                              position="relative"
                              borderWidth="2px"
                              borderStyle="dashed"
                              borderColor={colors.borderColor}
                              borderRadius="xl"
                              bg={colors.iconBgColor}
                              overflow="hidden"
                              transition="all 0.2s"
                              _hover={{
                                borderColor: colors.accentColor,
                                shadow: "md",
                                transform: "translateY(-2px)",
                              }}
                            >
                              {imageUrl ? (
                                <>
                                  <Image
                                    src={imageUrl}
                                    alt={`Picture ${num}`}
                                    position="absolute"
                                    top="0"
                                    left="0"
                                    w="full"
                                    h="full"
                                    objectFit="cover"
                                  />
                                  <IconButton
                                    icon={<CloseIcon />}
                                    size="xs"
                                    position="absolute"
                                    top={2}
                                    right={2}
                                    bg="blackAlpha.600"
                                    color="white"
                                    _hover={{ bg: "blackAlpha.800" }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleRemovePhoto(slot);
                                    }}
                                    opacity={0}
                                    _groupHover={{ opacity: 1 }}
                                    transition="all 0.2s"
                                    aria-label="Remove photo"
                                  />
                                </>
                              ) : (
                                <Flex
                                  position="absolute"
                                  top="0"
                                  left="0"
                                  w="full"
                                  h="full"
                                  alignItems="center"
                                  justifyContent="center"
                                >
                                  <Icon
                                    as={AddIcon}
                                    w={6}
                                    h={6}
                                    color={colors.textColor}
                                    opacity={0.5}
                                  />
                                </Flex>
                              )}
                            </Box>
                          </Box>
                        );
                      })}
                    </SimpleGrid>

                    {/* Hidden file input */}
                    <Input
                      type="file"
                      hidden
                      ref={fileRef}
                      accept="image/*"
                      onChange={handleFileChange}
                    />
                  </Box>
                </Box>
              </Box>
            )}

            {/* Basic Info Section */}
            <Box mb={8}>
              <Text 
                fontSize={{ base: "lg", md: "xl" }} 
                fontWeight="medium"
                color={colors.textColor} 
                mb={4}
                letterSpacing="wide"
                fontFamily="Montserrat, sans-serif"
              >
                Basic Info
              </Text>

              <Stack
                spacing={4}
                borderRadius="xl"
                overflow="hidden"
                borderWidth="1px"
                borderColor={colors.borderColor}
                bg={colors.cardBg}
                shadow="sm"
                _hover={{ shadow: "md" }}
                transition="all 0.2s"
              >
                {isLoading ? (
                  <SkeletonText noOfLines={5} spacing={4} p={4} />
                ) : (
                  <>
                    <ProfileField
                      icon={<FaUser />}
                      label="Name"
                      value={userProfile?.fullName || "Not provided"}
                      onClick={() => navigate("/update_name")}
                      colors={colors}
                    />

                    <ProfileField
                      icon={<FaCity />}
                      label="City"
                      value={userProfile?.city || "Not provided"}
                      onClick={() => navigate("/update_city")}
                      colors={colors}
                    />

                    {!userProfile?.isCreator && (
                      <>
                        <ProfileField
                          icon={<FaBuilding />}
                          label="Job"
                          value={userProfile?.job || "Not provided"}
                          onClick={() => navigate("/update_job")}
                          colors={colors}
                        />
                      </>
                    )}

                    <ProfileField
                      icon={<BsFillPeopleFill />}
                      label="Interests"
                      value={userProfile?.interests || "No interests listed"}
                      onClick={() => navigate("/update_interests")}
                      colors={colors}
                    />

                    {!userProfile?.isCreator && (
                      <ProfileField
                        icon={<FaCalendar />}
                        label="Year of Birth"
                        value={userProfile?.yob || "Not provided"}
                        onClick={() => navigate("/update_yob")}
                        colors={colors}
                      />
                    )}

                    <ProfileField
                      icon={<FaEnvelope />}
                      label="Email"
                      value={userProfile?.email || "Not provided"}
                      isClickable={false}
                      colors={colors}
                    />
                  </>
                )}
              </Stack>
            </Box>

            {/* Additional Info Section */}
            <Box mb={8}>
              <Text 
                fontSize={{ base: "lg", md: "xl" }} 
                fontWeight="medium"
                color={colors.textColor} 
                mb={4}
                letterSpacing="wide"
                fontFamily="Montserrat, sans-serif"
              >
                Profile verification 
              </Text>

              <Stack
                spacing={4}
                borderRadius="xl"
                overflow="hidden"
                borderWidth="1px"
                borderColor={colors.borderColor}
                bg={colors.cardBg}
                shadow="sm"
                _hover={{ shadow: "md" }}
                transition="all 0.2s"
              >
                {isLoading ? (
                  <SkeletonText noOfLines={3} spacing={4} p={4} />
                ) : (
                  <>
                    <ProfileField
                      icon={<FaInstagram />}
                      label="Instagram"
                      value={userProfile?.instagram || "Not provided"}
                      onClick={() => navigate("/update_instagram")}
                      colors={colors}
                      isVerified={userProfile?.isInstagramVerified}
                    />

                    <ProfileField
                      icon={<FaLinkedin />}
                      label="LinkedIn"
                      value={userProfile?.linkedin || "Not provided"}
                      onClick={() => navigate("/update_linkedin")}
                      colors={colors}
                      isVerified={userProfile?.isLinkedinVerified}
                    />
                  </>
                )}
              </Stack>
            </Box>

            {/* Footer Note */}
            <Box 
              textAlign="center" 
              mb={8}
              bg={colors.iconBgColor}
              p={4}
              rounded="xl"
            >
              <Text 
                fontSize="sm" 
                color={colors.textColor}
                fontFamily="Montserrat, sans-serif"
              >
                *Your data is fully encrypted and never publicly visible.
              </Text>
            </Box>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

// ProfileField component
const ProfileField = ({ icon, label, value, onClick, isClickable = true, colors, isVerified }) => {
  return (
    <Box
      w="full"
      bg={colors.cardBg}
      cursor={isClickable ? "pointer" : "default"}
      _hover={isClickable ? { 
        bg: colors.iconBgColor,
        transform: "translateY(-1px)",
      } : {}}
      transition="all 0.2s"
      onClick={isClickable ? onClick : undefined}
    >
      <HStack p={4} spacing={4} justify="space-between">
        <HStack spacing={4}>
          <Box 
            p={3}
            bg={colors.iconBgColor}
            borderRadius="full"
            color={colors.accentColor}
          >
            {icon}
          </Box>
          <Box>
            <Text
              fontWeight="medium"
              fontSize={{ base: "md", md: "lg" }}
              color={colors.textColor}
              fontFamily="Montserrat, sans-serif"
            >
              {label}
            </Text>
            <Text 
              fontSize={{ base: "sm", md: "md" }}
              color={colors.subtitleColor}
            >
              {value}
            </Text>
          </Box>
        </HStack>
        <HStack>
          {isVerified !== undefined && (
            <Box
              display="flex"
              alignItems="center"
              bg={isVerified ? "green.50" : "gray.50"}
              px={2}
              py={1}
              borderRadius="md"
            >
              {isVerified ? (
                <>
                  <CheckIcon color="green.500" />
                  <Text ml={2} fontSize="sm" fontWeight="medium" color="green.500">
                    Verified
                  </Text>
                </>
              ) : (
                <Text fontSize="sm" fontWeight="medium" color="gray.500">
                  Not verified
                </Text>
              )}
            </Box>
          )}
          {isClickable && <ChevronRightIcon color={colors.accentColor} boxSize={6} />}
        </HStack>
      </HStack>
    </Box>
  );
};

export default EditProfilePage;
