import {
  Button,
  Flex,
  Input,
  Text,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useColorModeValue,
} from "@chakra-ui/react";
// import Comment from "../Comment/Comment";
import Going from "../Comment/Going";
// import usePostComment from "../../hooks/usePostComment";
import { useEffect, useRef } from "react";
import GroupFollowing from "../Comment/GroupFollowing";

const GroupsCreatorModal = ({ isOpen, onClose, userProfile }) => {
  // const { handlePostComment, isCommenting } = usePostComment();
  const commentRef = useRef(null);
  const commentsContainerRef = useRef(null);
  // const handleSubmitComment = async (e) => {
  // 	// do not refresh the page, prevent it
  // 	e.preventDefault();
  // 	await handlePostComment(post.id, commentRef.current.value);
  // 	commentRef.current.value = "";
  // };

  useEffect(() => {
    const scrollToBottom = () => {
      commentsContainerRef.current.scrollTop =
        commentsContainerRef.current.scrollHeight;
    };
    // if (isOpen) {
    // 	setTimeout(() => {
    // 		scrollToBottom();
    // 	}, 100);
    // }
  }, [isOpen, userProfile.groups.length]);

  const bgTheme = useColorModeValue(`white`, `black`);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={"lg"}>
      <ModalOverlay />
      <ModalContent bg={bgTheme} border={"1px solid gray"} maxW={"400px"}>
        <ModalHeader>Groups Admin({userProfile.groups.length})</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={0}>
          <Flex
            mb={4}
            gap={4}
            flexDir={"column"}
			maxH={"500px"}
            overflowY={"auto"}
            ref={commentsContainerRef}
          >
            {userProfile.groups.map((following, idx) => (
              // <Text key={idx} >
              // 	{comment}
              // 	</Text>
              <GroupFollowing key={idx} user={following} />
            ))}
          </Flex>
          {/* <form onSubmit={handleSubmitComment} style={{ marginTop: "2rem" }}>
						<Input placeholder='Comment' size={"sm"} ref={commentRef} />
						<Flex w={"full"} justifyContent={"flex-end"}>
							<Button type='submit' ml={"auto"} size={"sm"} my={4} isLoading={isCommenting}>
								Post
							</Button>
						</Flex>
					</form> */}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default GroupsCreatorModal;

// COPY AND PASTE AS THE STARTER CODE FOR THE COMMENTS MODAL COMPONENT
// import {
// 	Button,
// 	Flex,
// 	Input,
// 	Modal,
// 	ModalBody,
// 	ModalCloseButton,
// 	ModalContent,
// 	ModalHeader,
// 	ModalOverlay,
// } from "@chakra-ui/react";

// const CommentsModal = ({ isOpen, onClose }) => {
// 	return (
// 		<Modal isOpen={isOpen} onClose={onClose} motionPreset='slideInLeft'>
// 			<ModalOverlay />
// 			<ModalContent bg={"black"} border={"1px solid gray"} maxW={"400px"}>
// 				<ModalHeader>Comments</ModalHeader>
// 				<ModalCloseButton />
// 				<ModalBody pb={6}>
// 					<Flex mb={4} gap={4} flexDir={"column"} maxH={"250px"} overflowY={"auto"}></Flex>
// 					<form style={{ marginTop: "2rem" }}>
// 						<Input placeholder='Comment' size={"sm"} />
// 						<Flex w={"full"} justifyContent={"flex-end"}>
// 							<Button type='submit' ml={"auto"} size={"sm"} my={4}>
// 								Post
// 							</Button>
// 						</Flex>
// 					</form>
// 				</ModalBody>
// 			</ModalContent>
// 		</Modal>
// 	);
// };

// export default CommentsModal;
