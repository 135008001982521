import {
  Container,
  Flex,
  Button,
  VStack,
  FormControl,
  Input,
  Text,
  Box,
  useToast,
} from "@chakra-ui/react";
import { FaArrowRight } from "react-icons/fa";
import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../../firebase/firebase";

const ForgotPasswordPage = () => {
  const [email, setEmail] = useState("");
  const toast = useToast();
  const navigate = useNavigate();
  const emailInputRef = useRef(null);

  const handlePasswordReset = () => {
    if (!email) {
      toast({
        title: "Error",
        description: "Please enter your email address.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
      return;
    }

    sendPasswordResetEmail(auth, email)
      .then(() => {
        toast({
          title: "Password Reset Email Sent",
          description: `Check your inbox at ${email} for the reset link.`,
          status: "success",
          duration: 4000,
          isClosable: true,
          position: "top",
        });
      })
      .catch((error) => {
        toast({
          title: "Error",
          description: error.message,
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
      });
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      emailInputRef.current.blur();
      handlePasswordReset();
    }
  };

  return (
    <Box
      minH="100vh"
      bg="white"
      color="black"
    >
      {/* Top Bar */}
      <Box
        position="sticky"
        top={0}
        left={0}
        right={0}
        zIndex={1000}
        bg="white"
        h={{ base: "56px", md: "64px" }}
        backdropFilter="blur(12px)"
        backgroundColor="rgba(255, 255, 255, 0.9)"
        borderBottom="1px solid"
        borderColor="gray.100"
      >
        <Container maxW="container.xl" h="full">
          <Flex 
            justify="center"
            align="center"
            h="full"
            px={{ base: 3, md: 4 }}
          >
            {/* Logo */}
            <Box 
              as="h1" 
              fontSize={{ base: "26px", md: "32px" }}
              fontWeight="900"
              letterSpacing="-0.03em"
              position="relative"
              display="inline-block"
              transform="translateY(-1px)"
              cursor="pointer"
              onClick={() => navigate("/")}
              transition="all 0.3s ease"
              _hover={{
                transform: "translateY(-2px) scale(1.02)",
              }}
            >
              <Box
                as="span"
                position="relative"
                bgGradient="linear(135deg, #4F46E5 0%, #7C3AED 100%)"
                bgClip="text"
                _before={{
                  content: '""',
                  position: "absolute",
                  left: "-6px",
                  right: "-6px",
                  top: "-6px",
                  bottom: "-6px",
                  background: "linear-gradient(135deg, #4F46E5 0%, #7C3AED 100%)",
                  opacity: 0.15,
                  borderRadius: "xl",
                  transform: "rotate(-2deg)",
                  transition: "all 0.3s ease",
                  zIndex: -1,
                }}
                _after={{
                  content: '""',
                  position: "absolute",
                  left: "-3px",
                  right: "-3px",
                  top: "-3px",
                  bottom: "-3px",
                  background: "white",
                  opacity: 0.9,
                  borderRadius: "lg",
                  transform: "rotate(-1deg)",
                  transition: "all 0.3s ease",
                  zIndex: -1,
                }}
              >
                hubzo
              </Box>
            </Box>
          </Flex>
        </Container>
      </Box>

      {/* Main Content */}
      <Container 
        maxW="container.sm" 
        pt={{ base: 8, md: 16 }}
        pb={8}
        px={6}
      >
        <VStack spacing={8} align="center" w="full">
          <VStack spacing={3} textAlign="center">
            <Text 
              fontSize={{ base: "2xl", md: "3xl" }}
              fontWeight="bold"
              bgGradient="linear(to-r, #4F46E5, #7C3AED)"
              bgClip="text"
            >
              Reset Your Password
            </Text>
            <Text color="gray.600" fontSize={{ base: "md", md: "lg" }}>
              Enter your email to receive a password reset link
            </Text>
          </VStack>

          <VStack spacing={6} w="full">
            <FormControl>
              <Input
                ref={emailInputRef}
                placeholder="Email address"
                size="lg"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onKeyDown={handleKeyDown}
                bg="gray.50"
                color="gray.800"
                border="1px solid"
                borderColor="gray.200"
                _placeholder={{ color: "gray.400" }}
                _focus={{ 
                  borderColor: "#4F46E5",
                  boxShadow: "0 0 0 1px #4F46E5",
                  bg: "white" 
                }}
                rounded="xl"
              />
            </FormControl>

            <Button
              w="full"
              size="lg"
              bg="linear-gradient(135deg, #4F46E5 0%, #7C3AED 100%)"
              color="white"
              _hover={{
                transform: "translateY(-2px)",
                shadow: "lg",
                opacity: 0.95,
              }}
              _active={{ transform: "translateY(0)" }}
              onClick={handlePasswordReset}
              rounded="xl"
              fontSize="md"
              rightIcon={<FaArrowRight />}
              transition="all 0.2s"
              isDisabled={!email}
            >
              Send Reset Link
            </Button>

            <Button
              variant="ghost"
              color="gray.500"
              fontSize="sm"
              fontWeight="medium"
              onClick={() => navigate("/sign_in")}
              _hover={{ color: "#4F46E5" }}
            >
              Back to Sign In
            </Button>
          </VStack>
        </VStack>
      </Container>
    </Box>
  );
};

export default ForgotPasswordPage;
