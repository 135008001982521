import {
  Container,
  Flex,
  Box,
  HStack,
  VStack,
  Divider,
  IconButton,
  Text,
  Image,
  useColorModeValue,
  Button,
  useToast,
  Tooltip,
  useDisclosure,
  CloseButton,
  Grid,
  Wrap,
  Tag,
  SimpleGrid,
  Icon,
} from "@chakra-ui/react";
import {
  FaCalendarAlt,
  FaMapMarkerAlt,
  FaUserFriends,
  FaTicketAlt,
  FaChartLine,
  FaEnvelope,
} from "react-icons/fa";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import useGetEventData from "../hooks/useGetEventData";
import useGenerateTicket from "../hooks/useGenerateTicket";
import useAuthStore from "../store/authStore";
import { format } from "date-fns";

const InviteEventPage = () => {
  const { postId } = useParams();
  const { data: post, loading: isLoading, error } = useGetEventData(postId);
  const navigate = useNavigate();
  const authUser = useAuthStore((state) => state.user);
  const { generateTicket, loading: ticketLoading } = useGenerateTicket();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [guestCount, setGuestCount] = useState(0);
  const toast = useToast();
  const [isExpanded, setIsExpanded] = useState(false);
  const [showHeader, setShowHeader] = useState(false);

  // Update the colors object to match brand identity
  const colors = {
    textColor: "gray.800",
    bgColor: "white",
    cardBg: "gray.50",
    dividerColor: "gray.200",
    accentColor: "#4F46E5",
    subtitleColor: "gray.600",
    buttonBg: "linear-gradient(135deg, #4F46E5 0%, #7C3AED 100%)",
    buttonHoverBg: "linear-gradient(135deg, #4F46E5 0%, #7C3AED 100%)",
  };

  const formatDate = (date) => format(date.toDate(), "EEE, MMM d, h:mm a");

  const matchPercentage = postId === "Fq5kuG6269tzsS6KpZhg" ? 83 : 91;
  91;

  const attendingPeople = postId === "Fq5kuG6269tzsS6KpZhg" ? 28 : 41;
  91;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setShowHeader(scrollPosition > 50);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleGenerateTicket = async () => {
    const { success, error } = await generateTicket(
      postId,
      authUser?.uid,
      guestCount
    );
    if (success) {
      toast({
        title: "Ticket generated successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      onClose();
      navigate(-1);
    } else {
      toast({
        title: "Error generating ticket.",
        description: error,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Box
      h="100vh"
      maxH="100vh"
      overflow="hidden"
      position="relative"
      bg={colors.bgColor}
      pb="env(safe-area-inset-bottom)"
    >
      {/* Fixed Header */}
      <Box
        position="fixed"
        top={0}
        left={0}
        right={0}
        h="64px"
        bg="rgba(255, 255, 255, 0.9)"
        backdropFilter="blur(12px)"
        borderBottom="1px solid"
        borderColor="gray.100"
        transform={showHeader ? "translateY(0)" : "translateY(-100%)"}
        opacity={showHeader ? 1 : 0}
        transition="all 0.3s"
        zIndex={1000}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <IconButton
          icon={<ArrowBackIcon />}
          variant="ghost"
          aria-label="Go back"
          onClick={() => navigate(-1)}
          position="absolute"
          left={4}
          color={colors.accentColor}
        />
        <Text
          fontSize={{ base: "lg", md: "xl" }}
          fontWeight="bold"
          color={colors.textColor}
        >
          Invitation Details
        </Text>
      </Box>

      {/* Scrollable Content */}
      <Box
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        overflowY="auto"
        pb={{ base: 32, md: 8 }}
        style={{
          WebkitOverflowScrolling: "touch",
        }}
      >
        {/* Add Back Button */}
        <IconButton
          icon={<ArrowBackIcon />}
          variant="ghost"
          aria-label="Go back"
          onClick={() => navigate(-1)}
          position="absolute"
          top={4}
          left={4}
          zIndex={2}
          bg="white"
          color={colors.accentColor}
          _hover={{
            bg: "gray.100",
          }}
          boxShadow="base"
          borderRadius="full"
        />

        {/* Hero Image Section */}
        {isLoading ? (
          <Box
            w="full"
            h={{ base: "200px", md: "300px" }}
            bg="gray.100"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Text color="gray.500">Loading...</Text>
          </Box>
        ) : error ? (
          <Box
            w="full"
            h={{ base: "200px", md: "300px" }}
            bg="gray.100"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Text color="gray.500">Error loading event</Text>
          </Box>
        ) : post ? (
          <Box position="relative" w="full" h={{ base: "200px", md: "300px" }}>
            <Box position="absolute" top={0} left={0} right={0} bottom={0}>
              {post.postPicURL ? (
                <Image
                  src={post.postPicURL}
                  alt="Event"
                  w="full"
                  h="full"
                  objectFit="cover"
                />
              ) : (
                <Box
                  w="full"
                  h="full"
                  bg="gray.100"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Text color="gray.500">No Image Available</Text>
                </Box>
              )}
              {/* Gradient Overlay */}
              <Box
                position="absolute"
                bottom={0}
                left={0}
                right={0}
                h="50%"
                bgGradient="linear(to-t, rgba(0,0,0,0.7) 0%, transparent 100%)"
              />
            </Box>
          </Box>
        ) : null}

        <Container 
          maxW={{ base: "100%", md: "container.xl" }} 
          px={{ base: 4, md: 8 }} 
          py={6}
        >
          {post && (
            <Grid
              templateColumns={{ base: "1fr", lg: "2fr 1fr" }}
              gap={{ base: 6, lg: 10 }}
            >
              {/* Left Column - Main Content */}
              <VStack spacing={6} align="stretch">
                {/* Event Title */}
                <Text
                  fontSize={{ base: "2xl", md: "3xl" }}
                  fontWeight="bold"
                  color={colors.textColor}
                >
                  {post.title}
                </Text>

                {/* Date & Time */}
                <Box
                  bg="purple.50"
                  p={3}
                  borderRadius="lg"
                  borderWidth="1px"
                  borderColor="purple.100"
                >
                  <HStack spacing={3} align="center">
                    <Icon as={FaCalendarAlt} color="purple.500" boxSize={5} />
                    <Text
                      fontSize="md"
                      fontWeight="semibold"
                      color="purple.700"
                      letterSpacing="tight"
                    >
                      {post.formattedEventDate || formatDate(new Date())}
                    </Text>
                  </HStack>
                </Box>

                {/* Location */}
                <Box
                  onClick={() => window.open(post.placeLocation, "_blank")}
                  cursor="pointer"
                  _hover={{ color: colors.accentColor }}
                  transition="all 0.2s"
                >
                  <HStack spacing={4}>
                    <Box
                      p={3}
                      bg="gray.100"
                      borderRadius="full"
                      color={colors.subtitleColor}
                    >
                      <FaMapMarkerAlt size={20} />
                    </Box>
                    <VStack align="start" spacing={1}>
                      <Text
                        fontSize={{ base: "lg", md: "xl" }}
                        color={colors.textColor}
                      >
                        {post.placeName || "Location not specified"}
                      </Text>
                      <Text fontSize="md" color={colors.subtitleColor}>
                        {post.address || "Address not specified"}
                      </Text>
                    </VStack>
                  </HStack>
                </Box>

                {/* About Section */}
                <Box
                  w="full"
                  p={4}
                  bg="gray.50"
                  borderRadius="lg"
                  borderWidth="1px"
                  borderColor="gray.200"
                  mb={4}
                >
                  <VStack align="start" spacing={3}>
                    <Text fontSize="sm" fontWeight="medium" color={colors.textColor}>
                      About the Event
                    </Text>
                    <Box position="relative" w="full">
                      <Text
                        color={colors.subtitleColor}
                        noOfLines={isExpanded ? undefined : 4}
                        fontSize="md"
                        lineHeight="tall"
                      >
                        {post.caption || "No description available."}
                      </Text>
                      {post.caption && post.caption.length > 200 && (
                        <Button
                          variant="link"
                          size="sm"
                          onClick={() => setIsExpanded(!isExpanded)}
                          color="blue.500"
                          fontWeight="medium"
                        >
                          {isExpanded ? "Show less" : "Show more"}
                        </Button>
                      )}
                    </Box>
                  </VStack>
                </Box>

                {/* Stats Section */}
                <HStack
                  spacing={4}
                  w="full"
                  p={4}
                  bg="gray.50"
                  borderRadius="lg"
                  borderWidth="1px"
                  borderColor="gray.100"
                  justify="space-between"
                  display={{ base: "flex", lg: "none" }}
                >
                  <VStack spacing={1} align="start">
                    <HStack spacing={2} align="center">
                      <Icon as={FaEnvelope} color="gray.500" boxSize={4} />
                      <Text fontSize="sm" color="gray.500" fontWeight="medium">
                        Invites
                      </Text>
                    </HStack>
                    <Text fontSize="2xl" fontWeight="bold" color="gray.700">
                      {post?.invitesSent || "85"}
                    </Text>
                  </VStack>

                  <Box h="40px" w="1px" bg="gray.200" />

                  <VStack spacing={1} align="start">
                    <HStack spacing={2} align="center">
                      <Icon as={FaUserFriends} color="gray.500" boxSize={4} />
                      <Text fontSize="sm" color="gray.500" fontWeight="medium">
                        Expected
                      </Text>
                    </HStack>
                    <Text fontSize="2xl" fontWeight="bold" color="gray.700">
                      {attendingPeople}
                    </Text>
                  </VStack>

                  <Box h="40px" w="1px" bg="gray.200" />

                  <VStack spacing={1} align="start">
                    <Text fontSize="sm" color="gray.500" fontWeight="medium">
                      Match
                    </Text>
                    <HStack spacing={1} align="baseline">
                      <Text fontSize="2xl" fontWeight="bold" color="green.500">
                        {matchPercentage}
                      </Text>
                      <Text fontSize="sm" color="green.500">
                        %
                      </Text>
                    </HStack>
                  </VStack>
                </HStack>

                {/* Gender Distribution */}
                <Box
                  w="full"
                  p={4}
                  bg="gray.50"
                  borderRadius="lg"
                  borderWidth="1px"
                  borderColor="gray.200"
                  mb={6}
                >
                  <VStack spacing={3}>
                    <Text
                      fontSize="sm"
                      fontWeight="medium"
                      color={colors.textColor}
                    >
                      Gender Distribution
                    </Text>
                    <HStack spacing={4} w="full" maxW="200px" mx="auto">
                      <VStack flex={post.ratio || 50}>
                        <Box
                          w="full"
                          h="8px"
                          bg="blue.100"
                          borderRadius="full"
                          overflow="hidden"
                        >
                          <Box
                            w={`${post.ratio || 50}%`}
                            h="full"
                            bg="blue.500"
                            transition="width 0.3s"
                          />
                        </Box>
                        <Text fontSize="sm" color="gray.700" fontWeight="medium">
                          {post.ratio || "50"}% M
                        </Text>
                      </VStack>
                      <VStack flex={100 - (post.ratio || 50)}>
                        <Box
                          w="full"
                          h="8px"
                          bg="pink.100"
                          borderRadius="full"
                          overflow="hidden"
                        >
                          <Box
                            w={`${100 - (post.ratio || 50)}%`}
                            h="full"
                            bg="pink.500"
                            transition="width 0.3s"
                          />
                        </Box>
                        <Text fontSize="sm" color="gray.700" fontWeight="medium">
                          {100 - (post.ratio || 50)}% F
                        </Text>
                      </VStack>
                    </HStack>
                  </VStack>
                </Box>

                {/* Age Distribution */}
                <Box
                  w="full"
                  p={4}
                  bg="gray.50"
                  borderRadius="lg"
                  borderWidth="1px"
                  borderColor="gray.200"
                  mb={6}
                >
                  <VStack spacing={4} align="start">
                    <Text
                      fontSize="sm"
                      fontWeight="medium"
                      color={colors.textColor}
                    >
                      Age Distribution
                    </Text>
                    <Grid templateColumns="repeat(4, 1fr)" gap={3} w="full">
                      {[
                        { range: "20-25", percent: "30%" },
                        { range: "26-30", percent: "40%" },
                        { range: "31-35", percent: "20%" },
                        { range: "36-40", percent: "10%" },
                      ].map((age) => (
                        <Box key={age.range} textAlign="center">
                          <Box
                            bg="purple.100"
                            p={1}
                            borderRadius="md"
                            position="relative"
                            h="24px"
                            overflow="hidden"
                          >
                            <Box
                              bg="linear-gradient(135deg, #4F46E5 0%, #7C3AED 100%)"
                              position="absolute"
                              bottom={0}
                              left={0}
                              right={0}
                              h={age.percent}
                              transition="height 0.3s"
                            />
                          </Box>
                          <Text fontSize="xs" color={colors.textColor} mt={1}>
                            {age.range}
                          </Text>
                        </Box>
                      ))}
                    </Grid>
                  </VStack>
                </Box>

                {/* Professional Background */}
                <Box
                  w="full"
                  p={4}
                  bg="gray.50"
                  borderRadius="lg"
                  borderWidth="1px"
                  borderColor="gray.200"
                  mb={6}
                >
                  <VStack spacing={4} align="start">
                    <Text
                      fontSize="sm"
                      fontWeight="medium"
                      color={colors.textColor}
                    >
                      Professional Background
                    </Text>
                    <Grid templateColumns="repeat(4, 1fr)" gap={3} w="full">
                      {[
                        { role: "Tech", percent: "35%", icon: "💻" },
                        { role: "Finance", percent: "25%", icon: "📊" },
                        { role: "Creative", percent: "20%", icon: "🎨" },
                        { role: "Other", percent: "20%", icon: "💼" },
                      ].map((job) => (
                        <Box key={job.role} textAlign="center">
                          <Box
                            bg="purple.100"
                            p={1}
                            borderRadius="md"
                            position="relative"
                            h="24px"
                            overflow="hidden"
                          >
                            <Box
                              bg="linear-gradient(135deg, #4F46E5 0%, #7C3AED 100%)"
                              position="absolute"
                              bottom={0}
                              left={0}
                              right={0}
                              h={job.percent}
                              transition="height 0.3s"
                            />
                          </Box>
                          <VStack spacing={0} mt={2}>
                            <Text
                              fontSize="xs"
                              color={colors.textColor}
                              fontWeight="medium"
                            >
                              {job.icon} {job.role}
                            </Text>
                            <Text fontSize="xs" color="gray.500">
                              {job.percent}
                            </Text>
                          </VStack>
                        </Box>
                      ))}
                    </Grid>
                  </VStack>
                </Box>

                {/* Common Interests */}
                <Box
                  w="full"
                  p={4}
                  bg="gray.50"
                  borderRadius="lg"
                  borderWidth="1px"
                  borderColor="gray.200"
                  mb={{ base: 20, lg: 6 }}
                >
                  <VStack spacing={3} align="start">
                    <Text
                      fontSize="sm"
                      fontWeight="medium"
                      color={colors.textColor}
                    >
                      Common Interests
                    </Text>
                    <Wrap spacing={2}>
                      {[
                        "Tech 💻",
                        "Startups 🚀",
                        "Food & Drinks 🍷",
                        "Networking 🤝",
                        "Arts 🎨",
                      ].map((interest) => (
                        <Tag
                          key={interest}
                          size="sm"
                          variant="subtle"
                          colorScheme="purple"
                          borderRadius="full"
                          px={3}
                          py={1}
                        >
                          {interest}
                        </Tag>
                      ))}
                    </Wrap>
                  </VStack>
                </Box>
              </VStack>

              {/* Right Column - Stats & Ticket */}
              <Box display={{ base: "none", lg: "block" }}>
                <Box
                  position="sticky"
                  top={4}
                  p={6}
                  bg="white"
                  borderRadius="xl"
                  borderWidth="1px"
                  borderColor="gray.200"
                  shadow="sm"
                >
                  {/* Stats Section for Desktop */}
                  <VStack spacing={6} align="stretch">
                    <SimpleGrid columns={3} gap={4}>
                      <VStack spacing={1} align="start">
                        <HStack spacing={2} align="center">
                          <Icon as={FaEnvelope} color="gray.500" boxSize={4} />
                          <Text fontSize="sm" color="gray.500" fontWeight="medium">
                            Invites
                          </Text>
                        </HStack>
                        <Text fontSize="2xl" fontWeight="bold" color="gray.700">
                          {post?.invitesSent || "85"}
                        </Text>
                      </VStack>

                      <VStack spacing={1} align="start">
                        <HStack spacing={2} align="center">
                          <Icon as={FaUserFriends} color="gray.500" boxSize={4} />
                          <Text fontSize="sm" color="gray.500" fontWeight="medium">
                            Expected
                          </Text>
                        </HStack>
                        <Text fontSize="2xl" fontWeight="bold" color="gray.700">
                          {attendingPeople}
                        </Text>
                      </VStack>

                      <VStack spacing={1} align="start">
                        <Text fontSize="sm" color="gray.500" fontWeight="medium">
                          Match
                        </Text>
                        <HStack spacing={1} align="baseline">
                          <Text fontSize="2xl" fontWeight="bold" color="green.500">
                            {matchPercentage}
                          </Text>
                          <Text fontSize="sm" color="green.500">
                            %
                          </Text>
                        </HStack>
                      </VStack>
                    </SimpleGrid>

                    {/* Get Ticket Button */}
                    <Button
                      leftIcon={<FaTicketAlt />}
                      bg={colors.buttonBg}
                      color="white"
                      w="full"
                      size="lg"
                      isLoading={ticketLoading}
                      onClick={onOpen}
                      _hover={{
                        transform: "translateY(-2px)",
                        shadow: "lg",
                        opacity: 0.95,
                      }}
                      transition="all 0.2s"
                      borderRadius="full"
                      py={7}
                      fontSize={{ base: "md", md: "lg" }}
                    >
                      Get Ticket
                    </Button>
                  </VStack>
                </Box>
              </Box>
            </Grid>
          )}
        </Container>
      </Box>

      {/* Mobile Get Ticket Button */}
      <Box
        position="fixed"
        bottom={4}
        left="50%"
        transform="translateX(-50%)"
        w={{ base: "90%", md: "400px" }}
        zIndex={1000}
        display={{ base: "block", lg: "none" }}
      >
        <Button
          leftIcon={<FaTicketAlt />}
          bg={colors.buttonBg}
          color="white"
          w="full"
          size="lg"
          isLoading={ticketLoading}
          onClick={onOpen}
          _hover={{
            transform: "translateY(-2px)",
            shadow: "lg",
            opacity: 0.95,
          }}
          transition="all 0.2s"
          borderRadius="full"
          py={7}
          fontSize={{ base: "md", md: "lg" }}
        >
          Get Ticket
        </Button>
      </Box>

      {/* Guest Modal */}
      {isOpen && (
        <Box
          position="fixed"
          top={0}
          left={0}
          right={0}
          bottom={0}
          bg="rgba(0, 0, 0, 0.6)"
          display="flex"
          justifyContent="flex-end"
          alignItems="flex-end"
          zIndex="1000"
          onClick={onClose}
          transition="all 0.3s"
        >
          <Box
            bg={colors.cardBg}
            w="full"
            maxW={{ base: "100%", md: "400px" }}
            mx={{ base: 0, md: "auto" }}
            position="relative"
            onClick={(e) => e.stopPropagation()}
            borderTopRadius={{ base: "20px", md: "lg" }}
            borderBottomRadius={{ base: "0", md: "lg" }}
            p={6}
            transform="translateY(0)"
            transition="transform 0.3s ease-out"
            marginX="auto"
            marginTop={{ base: "auto", md: "20vh" }}
            boxShadow="0px -4px 10px rgba(0, 0, 0, 0.1)"
          >
            {/* Drag indicator for mobile */}
            <Box
              w="40px"
              h="4px"
              bg="gray.300"
              borderRadius="full"
              mx="auto"
              mb={4}
              display={{ base: "block", md: "none" }}
            />

            <CloseButton
              position="absolute"
              top={4}
              right={4}
              onClick={onClose}
              size="lg"
            />

            <VStack spacing={6} align="stretch">
              <Text
                fontSize="xl"
                fontWeight="bold"
                color={colors.textColor}
                textAlign="center"
                mt={2}
              >
                Ticket Details
              </Text>

              {/* Info Section */}
              <Box
                bg={useColorModeValue("gray.50", "gray.700")}
                p={4}
                borderRadius="lg"
              >
                <VStack align="start" spacing={3} color={colors.subtitleColor}>
                  <HStack>
                    <Box as={FaTicketAlt} color={colors.accentColor} />
                    <Text fontSize="sm">
                      Ticket is linked to your profile for entry
                    </Text>
                  </HStack>
                  <HStack>
                    <Box as={FaUserFriends} color={colors.accentColor} />
                    <Text fontSize="sm">
                      Confirm attendance early to secure your spot
                    </Text>
                  </HStack>
                </VStack>
              </Box>

              {/* Guest Counter Section */}
              <Box>
                <Text
                  fontSize="lg"
                  fontWeight="semibold"
                  color={colors.textColor}
                  mb={4}
                  textAlign="center"
                >
                  Number of Guests
                </Text>
                <HStack justify="center" spacing={8} my={2}>
                  <IconButton
                    icon={<Text fontSize="xl">-</Text>}
                    colorScheme="blue"
                    variant="outline"
                    isRound
                    onClick={() => setGuestCount(Math.max(0, guestCount - 1))}
                    isDisabled={guestCount <= 0}
                  />
                  <Text
                    fontSize="2xl"
                    fontWeight="bold"
                    color={colors.textColor}
                    w="40px"
                    textAlign="center"
                  >
                    {guestCount}
                  </Text>
                  <IconButton
                    icon={<Text fontSize="xl">+</Text>}
                    colorScheme="blue"
                    variant="outline"
                    isRound
                    onClick={() => setGuestCount(Math.min(5, guestCount + 1))}
                    isDisabled={guestCount >= 5}
                  />
                </HStack>
                <Text
                  fontSize="sm"
                  color={colors.subtitleColor}
                  textAlign="center"
                  mt={2}
                >
                  Maximum 5 guests allowed
                </Text>
              </Box>

              {/* Confirm Button */}
              <Button
                colorScheme="blue"
                size="lg"
                onClick={handleGenerateTicket}
                isLoading={ticketLoading}
                borderRadius="full"
                py={7}
                _hover={{ transform: "translateY(-1px)" }}
                transition="all 0.2s"
              >
                Confirm Ticket
              </Button>
            </VStack>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default InviteEventPage;
