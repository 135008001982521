import { 
  Box, 
  Text, 
  VStack, 
  HStack,
  Spinner, 
  Image,
  Tag,
  Wrap,
  WrapItem,
  Icon,
  Skeleton,
  Badge,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { FaCalendarAlt, FaMapMarkerAlt, FaUserFriends, FaChartLine } from "react-icons/fa";
import useFetchEventDetails from "../hooks/useFetchEventDetails";
import useLogUserCount from "../hooks/useLogUserCount";

const InvitationItem = ({ invitation = {} }) => {
  const navigate = useNavigate();
  const { eventDetails, loading, error } = useFetchEventDetails(invitation?.eventId);
  const { triggerLogUserCount } = useLogUserCount();

  const colors = {
    textColor: "gray.800",
    subtitleColor: "gray.600",
    cardBg: "white",
    borderColor: "gray.200",
    accentGradient: "linear(135deg, #4F46E5 0%, #7C3AED 100%)",
  };

  const handleClick = async () => {
    navigate(`/i/${invitation.eventId}`);
  };
  // Generate random match percentage and attendance
  const matchPercentage = invitation.eventId === "Fq5kuG6269tzsS6KpZhg" ? 83 : 91;
    91
  
  const attendingPeople = invitation.eventId === "Fq5kuG6269tzsS6KpZhg" ? 28 : 41;
  91

  if (!invitation || !invitation.eventId) {
    return (
      <Box
        bg="white"
        borderRadius="xl"
        borderWidth="1px"
        borderColor="gray.200"
        p={4}
        w="full"
      >
        <Skeleton height="100px" />
      </Box>
    );
  }

  if (loading) return <Spinner size="sm" />;
  if (error) return <Text color="red.500">Error loading event details</Text>;

  return (
    <Box
      onClick={handleClick}
      bg={colors.cardBg}
      borderRadius="xl"
      borderWidth="1px"
      borderColor={colors.borderColor}
      overflow="hidden"
      transition="all 0.2s"
      cursor="pointer"
      _hover={{ 
        transform: "translateY(-2px)",
        shadow: "lg",
      }}
      position="relative"
      w="full"
      height="full"
      display="flex"
      flexDirection="column"
    >
      {/* Image Section */}
      <Box position="relative" h="200px">
        {eventDetails?.postPicURL ? (
          <Image
            src={eventDetails.postPicURL}
            alt="Event"
            w="full"
            h="full"
            objectFit="cover"
          />
        ) : (
          <Box
            w="full"
            h="full"
            bg="gray.100"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Text color="gray.500">No Image Available</Text>
          </Box>
        )}
        
        {/* Gradient Overlay */}
        <Box
          position="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
          bg="linear-gradient(180deg, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0) 30%)"
        />

        {/* Stats Tags */}
        <Wrap
          position="absolute"
          top={4}
          right={4}
          spacing={2}
        >
          <WrapItem>
            <Badge
              bg="rgba(255, 255, 255, 0.95)"
              color="green.600"
              backdropFilter="blur(12px)"
              px={3}
              py={1.5}
              borderRadius="full"
              fontWeight="medium"
              fontSize="sm"
              display="flex"
              alignItems="center"
              gap={1.5}
              boxShadow="sm"
            >
              <Icon as={FaChartLine} boxSize={3} />
              {matchPercentage}% Match
            </Badge>
          </WrapItem>
          <WrapItem>
            <Badge
              bg="rgba(255, 255, 255, 0.95)"
              color="gray.700"
              backdropFilter="blur(12px)"
              px={3}
              py={1.5}
              borderRadius="full"
              fontWeight="medium"
              fontSize="sm"
              display="flex"
              alignItems="center"
              gap={1.5}
              boxShadow="sm"
            >
              <Icon as={FaUserFriends} boxSize={3} />
              {attendingPeople} People
            </Badge>
          </WrapItem>
        </Wrap>
      </Box>

      {/* Content Section */}
      <VStack 
        align="stretch" 
        p={5} 
        spacing={4}
        flex={1}
        justify="space-between"
      >
        <VStack align="stretch" spacing={4}>
          {/* Event Title */}
          <Text 
            fontSize={{ base: "lg", md: "xl" }} 
            fontWeight="bold" 
            color={colors.textColor}
            noOfLines={2}
            lineHeight="shorter"
          >
            {eventDetails?.title || "Event Title"}
          </Text>

          {/* Date and Time */}
          <Box
            bg="purple.50"
            p={3}
            borderRadius="lg"
            borderWidth="1px"
            borderColor="purple.100"
          >
            <HStack spacing={3} align="center">
              <Icon as={FaCalendarAlt} color="purple.500" boxSize={5} />
              <Text
                fontSize="md"
                fontWeight="semibold"
                color="purple.700"
                letterSpacing="tight"
              >
                {eventDetails?.formattedEventDate || "Date not specified"}
              </Text>
            </HStack>
          </Box>
        </VStack>

        {/* Location */}
        <HStack spacing={3} color={colors.subtitleColor}>
          <Icon as={FaMapMarkerAlt} boxSize={4} />
          <Text fontSize="sm" noOfLines={1}>
            {eventDetails?.placeName || "Location not specified"}
          </Text>
        </HStack>
      </VStack>
    </Box>
  );
};

export default InvitationItem;
