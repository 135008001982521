import { useCreateUserWithEmailAndPassword } from "react-firebase-hooks/auth";
import { auth, firestore } from "../firebase/firebase";
import {
  collection,
  doc,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import useShowToast from "./useShowToast";
import useAuthStore from "../store/authStore";
import { sendEmailVerification } from "firebase/auth"; // Import for email verification

const useSignUpWithEmailAndPassword = () => {
  const [createUserWithEmailAndPassword, , loading, error] =
    useCreateUserWithEmailAndPassword(auth);
  const showToast = useShowToast();
  const loginUser = useAuthStore((state) => state.login);

  const signup = async (inputs) => {
    // console.log(inputs);
    if (
      !inputs.email ||
      !inputs.password

      // || !inputs.username || !inputs.fullName
    ) {
      showToast("Error", "Please fill all the fields", "error");
      return;
    }

    function safeConvertToInt(str) {
      if (!str) {
        // Handle empty string, null, or undefined
        return 0; // or any default value you want
      }

      const num = parseInt(str, 10);

      // Check if conversion resulted in NaN
      if (isNaN(num)) {
        return 0; // or any default value you want
      }

      return num;
    }

    function generateRandomAlphanumeric() {
      const chars =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let result = "";
      for (let i = 0; i < 5; i++) {
        result += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return result.toUpperCase();
    }




  const createProfilePicURLs = (pics) => {
    const profilePicURLs = {};
    
    if (pics.facePic) {
      profilePicURLs.pic1 = {
        url: pics.facePic,
        uploadedAt: Date.now(),
        isVerified: false
      };
    }
    if (pics.facePic1) {
      profilePicURLs.pic1 = {
        url: pics.facePic1,
        uploadedAt: Date.now(),
        isVerified: false
      };
    }
    if (pics.facePic2) {
      profilePicURLs.pic2 = {
        url: pics.facePic2,
        uploadedAt: Date.now(),
        isVerified: false
      };
    }
    if (pics.facePic3) {
      profilePicURLs.pic3 = {
        url: pics.facePic3,
        uploadedAt: Date.now(),
        isVerified: false
      };
    }
    if (pics.facePic4) {
      profilePicURLs.pic4 = {
        url: pics.facePic4,
        uploadedAt: Date.now(),
        isVerified: false
      };
    }
  
    return Object.keys(profilePicURLs).length > 0 ? profilePicURLs : null;
  };



    const usersRef = collection(firestore, "users");

    const q = query(usersRef, where("username", "==", "inputs.username"));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      showToast("Error", "Username already exists", "error");
      return;
    }

    try {
      const newUser = await createUserWithEmailAndPassword(
        inputs.email,
        inputs.password
      );
      if (!newUser && error) {
        showToast("Error", error.message, "error");
        return;
      }
      if (newUser) {
        // Send email verification
        await sendEmailVerification(newUser.user);
        showToast(
          "Success",
          "Signup successful! Please verify your email before logging in.",
          "success"
        );

        let facePic = localStorage.getItem("face-pic");
        // console.log(facePic);

        let yearOfBirth = localStorage.getItem("dob");

        let job = localStorage.getItem("profession");
        let instagram = localStorage.getItem("instagram");
        let linkedin = localStorage.getItem("linkedin");
        let interests = localStorage.getItem("interests");
        let fullName = localStorage.getItem("name");
        let city = localStorage.getItem("city");
        let isCreator = localStorage.getItem("isCreator");

        let verified = newUser.user.emailVerified;

        let usercode = generateRandomAlphanumeric();

        const pics = {
          facePic: localStorage.getItem("face-pic"),
          facePic1: localStorage.getItem("face-pic1"),
          facePic2: localStorage.getItem("face-pic2"),
          facePic3: localStorage.getItem("face-pic3"),
          facePic4: localStorage.getItem("face-pic4")
        };

        const userDoc = {
          uid: newUser.user.uid,
          email: inputs.email,
          username: "",
          fullName: fullName ? fullName : "",
          bio: "",
          profilePicURL: pics.facePic || "", // Keep for backward compatibility
          profilePicURLs: createProfilePicURLs(pics),
          followers: [],
          following: [],
          posts: [],
          createdAt: Date.now(),
          whatsapp: "",
          instagram: instagram,
          linkedin: linkedin,
          phoneNumber: "",
          isCreator: isCreator,
          job: job,
          interests: interests,
          yob: safeConvertToInt(yearOfBirth),
          verified: verified,
          city: city,
          userCode: usercode,
          isInstagramVerified: false,
          isLinkedinVerified: false,
        };
        await setDoc(doc(firestore, "users", newUser.user.uid), userDoc);
        localStorage.setItem("user-info", JSON.stringify(userDoc));
        loginUser(userDoc);
        localStorage.setItem("dob", "");
        localStorage.setItem("city", "");
        localStorage.setItem("email", "");
        localStorage.setItem("face-pic", "");
        localStorage.setItem("job", "");
        localStorage.setItem("instagram", "");
        localStorage.setItem("linkedin", "");
        localStorage.setItem("isCreator", false);
        localStorage.setItem("usercode", "");
      }
    } catch (error) {
      showToast("Error", error.message, "error");
    }
  };

  return { loading, error, signup };
};

export default useSignUpWithEmailAndPassword;
