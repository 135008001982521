import {
  Container,
  Flex,
  VStack,
  Button,
  Text,
  Box,
  Icon,
  HStack,
  Skeleton,
  SkeletonText,
  Image,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from "@chakra-ui/react";
import { CheckIcon } from "@chakra-ui/icons";
import { FaGoogle, FaApple, FaArrowRight } from "react-icons/fa";
import { MdOutlineEmail } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import Lottie from "react-lottie-player";
import {
  getAuth,
  OAuthProvider,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import loadingAnimation from "../../assets/processing_pre_signin.json";
import { firestore } from "../../firebase/firebase";
import useAuthStore from "../../store/authStore";
import useShowToast from "../../hooks/useShowToast";
import { getDoc, setDoc, doc } from "firebase/firestore";

const safeConvertToInt = (value) => {
  if (!value) return null;
  const parsed = parseInt(value);
  return isNaN(parsed) ? null : parsed;
};

const isIosStandalone = () => {
  const isIosDevice =
    /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  const isStandaloneMode =
    typeof window.navigator.standalone === "boolean"
      ? window.navigator.standalone
      : window.matchMedia &&
        window.matchMedia("(display-mode: standalone)").matches;
  return isIosDevice && isStandaloneMode;
};

const LogoComponent = ({ onClick }) => (
  <Box 
    as="h1" 
    fontSize={{ base: "28px", md: "32px" }}
    fontWeight="400"
    letterSpacing="0.35em"
    position="relative"
    display="inline-block"
    cursor="pointer"
    color="#1A1A1A"
    textTransform="uppercase"
    fontFamily="Montserrat, sans-serif"
    onClick={onClick}
    _hover={{
      letterSpacing: "0.4em",
      transform: "translateY(-1px)",
      transition: "all 0.4s cubic-bezier(0.4, 0, 0.2, 1)",
    }}
    transition="all 0.4s cubic-bezier(0.4, 0, 0.2, 1)"
    sx={{
      WebkitFontSmoothing: "antialiased",
      textRendering: "optimizeLegibility",
    }}
  >
    <Box
      as="span"
      position="relative"
      _before={{
        content: '""',
        position: "absolute",
        left: "-8%",
        right: "-8%",
        top: "-8px",
        bottom: "-8px",
        background: "linear-gradient(135deg, rgba(26, 26, 26, 0.08), rgba(26, 26, 26, 0.03))",
        backdropFilter: "blur(4px)",
        opacity: { base: 0.95, md: 0.9 },
        borderRadius: "2px",
        transform: "skew(-4deg)",
        transition: "all 0.3s ease",
        zIndex: -1,
      }}
      _after={{
        content: '""',
        position: "absolute",
        left: 0,
        right: 0,
        bottom: "-4px",
        height: "1px",
        background: "linear-gradient(to right, transparent, currentColor, transparent)",
        opacity: 0.4,
        transform: "scaleX(0.8)",
        transition: "transform 0.3s ease",
      }}
      _hover={{
        _after: {
          transform: "scaleX(1)",
        },
      }}
    >
      hubzo
    </Box>
  </Box>
);

const PrimaryButton = ({ children, ...props }) => (
  <Button
    size="lg"
    bg="black"
    color="white"
    h="60px"
    fontSize="lg"
    fontWeight="semibold"
    rounded="full"
    _hover={{
      transform: "translateY(-2px)",
      shadow: "lg",
      opacity: 0.95,
    }}
    transition="all 0.2s"
    {...props}
  >
    {children}
  </Button>
);

const SocialButton = ({ children, ...props }) => (
  <Button
    size="lg"
    bg="white"
    color="gray.900"
    h="60px"
    fontSize="lg"
    fontWeight="semibold"
    rounded="full"
    border="1px solid"
    borderColor="gray.200"
    _hover={{
      transform: "translateY(-2px)",
      shadow: "lg",
      bg: "gray.50",
    }}
    transition="all 0.2s"
    {...props}
  >
    {children}
  </Button>
);

const OnboardingSignUpPage = () => {
  const [showSuccess, setShowSuccess] = useState(false);
  const [isStandaloneIos, setIsStandaloneIos] = useState(true);
  const [isBusiness, setIsBusiness] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const loginUser = useAuthStore((state) => state.login);
  const showToast = useShowToast();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const cancelRef = useRef();
  const isInstagramBrowser = /Instagram/.test(navigator.userAgent);

  useEffect(() => {
    const isCreator = localStorage.getItem("isCreator") === "true";
    setIsBusiness(isCreator);
    setIsStandaloneIos(isIosStandalone());

    setIsLoading(false);

    const timer = setTimeout(() => {
      setShowSuccess(true);
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  function generateRandomAlphanumeric() {
    const chars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < 5; i++) {
      result += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return result.toUpperCase();
  }

  const createProfilePicURLs = (pics) => {
    const profilePicURLs = {};
    
    if (pics.facePic) {
      profilePicURLs.pic1 = {
        url: pics.facePic,
        uploadedAt: Date.now(),
        isVerified: false
      };
    }
    if (pics.facePic1) {
      profilePicURLs.pic1 = {
        url: pics.facePic1,
        uploadedAt: Date.now(),
        isVerified: false
      };
    }
    if (pics.facePic2) {
      profilePicURLs.pic2 = {
        url: pics.facePic2,
        uploadedAt: Date.now(),
        isVerified: false
      };
    }
    if (pics.facePic3) {
      profilePicURLs.pic3 = {
        url: pics.facePic3,
        uploadedAt: Date.now(),
        isVerified: false
      };
    }
    if (pics.facePic4) {
      profilePicURLs.pic4 = {
        url: pics.facePic4,
        uploadedAt: Date.now(),
        isVerified: false
      };
    }
  
    return Object.keys(profilePicURLs).length > 0 ? profilePicURLs : null;
  };


  const handleOpenInBrowser = () => {
    // Store current localStorage values in sessionStorage before redirecting
    const keysToPreserve = [
      "face-pic",
      "dob",
      "profession",
      "instagram",
      "linkedin",
      "interests",
      "name",
      "city",
      "isCreator"
    ];

    keysToPreserve.forEach(key => {
      const value = localStorage.getItem(key);
      if (value) {
        sessionStorage.setItem(`temp_${key}`, value);
      }
    });

    // Get the full URL including protocol
    const currentUrl = window.location.href;
    const hostname = window.location.hostname;
    const pathname = window.location.pathname;
    const protocol = window.location.protocol;

    // For iOS
    if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
      window.location.href = `googlechrome://${hostname}${pathname}`;
      
      setTimeout(() => {
        window.location.href = currentUrl;
      }, 2500);
      return;
    }
    
    // For Android
    if (/Android/i.test(navigator.userAgent)) {
      window.location.href = `intent://${hostname}${pathname}#Intent;scheme=${protocol.slice(0, -1)};package=com.android.chrome;end`;
    } else {
      window.open(currentUrl, '_system');
    }
  };

  const handleGoogleSignUp = async () => {
    if (isInstagramBrowser) {
      setIsDialogOpen(true);
      return;
    }

    const provider = new GoogleAuthProvider();
    const authInstance = getAuth();
    try {
      const result = await signInWithPopup(authInstance, provider);
      const newUser = result.user;
      if (!newUser) {
        showToast("Error", "Google sign-up failed", "error");
        return;
      }
      const userRef = doc(firestore, "users", newUser.uid);
      const userSnap = await getDoc(userRef);

      if (userSnap.exists()) {
        const userDoc = userSnap.data();
        if (userDoc.status === "deleted") {
          showToast("Error", "This account has been deleted", "error");
        } else {
          localStorage.setItem("user-info", JSON.stringify(userDoc));
          loginUser(userDoc);
        }
      } else {
        // let facePic = localStorage.getItem("face-pic");
        let yearOfBirth = localStorage.getItem("dob");
        let job = localStorage.getItem("profession");
        let instagram = localStorage.getItem("instagram");
        let linkedin = localStorage.getItem("linkedin");
        let interests = localStorage.getItem("interests");
        let fullName = localStorage.getItem("name");
        let city = localStorage.getItem("city");
        let isCreator = localStorage.getItem("isCreator");

        let usercode = generateRandomAlphanumeric();

        const pics = {
          facePic: localStorage.getItem("face-pic"),
          facePic1: localStorage.getItem("face-pic1"),
          facePic2: localStorage.getItem("face-pic2"),
          facePic3: localStorage.getItem("face-pic3"),
          facePic4: localStorage.getItem("face-pic4")
        };

        const userDoc = {
          uid: newUser.uid,
          email: newUser.email,
          username: newUser.email.split("@")[0],
          fullName: fullName || newUser.displayName || "Google User",
          bio: "",
          profilePicURL: pics.facePic || "", // Keep for backward compatibility
          profilePicURLs: createProfilePicURLs(pics),
          followers: [],
          following: [],
          posts: [],
          createdAt: Date.now(),
          whatsapp: "",
          instagram: instagram || "",
          linkedin: linkedin || "",
          phoneNumber: "",
          isCreator: isCreator === "true",
          job: job || "",
          interests: interests || [],
          yob: safeConvertToInt(yearOfBirth),
          verified: true,
          city: city || "",
          userCode: usercode,
          isInstagramVerified: false,
          isLinkedinVerified: false,
        };

        await setDoc(doc(firestore, "users", newUser.uid), userDoc);
        localStorage.setItem("user-info", JSON.stringify(userDoc));
        loginUser(userDoc);
      }
      navigate("/");
    } catch (error) {
      console.error("Google Sign-Up Error:", error.message);
      showToast("Error", error.message, "error");
    }
  };

  const handleAppleSignUp = async () => {
    if (isInstagramBrowser) {
      setIsDialogOpen(true);
      return;
    }

    if (loading) return;
    setLoading(true);
    const provider = new OAuthProvider("apple.com");
    const authInstance = getAuth();

    try {
      const result = await signInWithPopup(authInstance, provider);
      const newUser = result.user;

      if (!newUser) {
        showToast("Error", "Apple sign-up failed", "error");
        setLoading(false);
        return;
      }

      const userRef = doc(firestore, "users", newUser.uid);
      const userSnap = await getDoc(userRef);

      if (userSnap.exists()) {
        const userDoc = userSnap.data();
        if (userDoc.status === "deleted") {
          showToast("Error", "This account has been deleted", "error");
        } else {
          localStorage.setItem("user-info", JSON.stringify(userDoc));
          loginUser(userDoc);
        }
      } else {
        let facePic = localStorage.getItem("face-pic");
        let yearOfBirth = localStorage.getItem("dob");
        let job = localStorage.getItem("profession");
        let instagram = localStorage.getItem("instagram");
        let linkedin = localStorage.getItem("linkedin");
        let interests = localStorage.getItem("interests");
        let fullName = localStorage.getItem("name");
        let city = localStorage.getItem("city");
        let isCreator = localStorage.getItem("isCreator");

        let usercode = generateRandomAlphanumeric();

        const pics = {
          facePic: localStorage.getItem("face-pic"),
          facePic1: localStorage.getItem("face-pic1"),
          facePic2: localStorage.getItem("face-pic2"),
          facePic3: localStorage.getItem("face-pic3"),
          facePic4: localStorage.getItem("face-pic4")
        };

        const userDoc = {
          uid: newUser.uid,
          email: newUser.email,
          username: newUser.email ? newUser.email.split("@")[0] : "AppleUser",
          fullName: fullName || newUser.displayName || "Apple User",
          bio: "",
          profilePicURL: pics.facePic || "", // Keep for backward compatibility
          profilePicURLs: createProfilePicURLs(pics),
          followers: [],
          following: [],
          posts: [],
          createdAt: Date.now(),
          whatsapp: "",
          instagram: instagram || "",
          linkedin: linkedin || "",
          phoneNumber: "",
          isCreator: isCreator === "true",
          job: job || "",
          interests: interests || [],
          yob: safeConvertToInt(yearOfBirth),
          verified: true,
          city: city || "",
          userCode: usercode,
          isInstagramVerified: false,
          isLinkedinVerified: false,
        };

        await setDoc(doc(firestore, "users", newUser.uid), userDoc);
        localStorage.setItem("user-info", JSON.stringify(userDoc));
        loginUser(userDoc);
      }
      navigate("/");
    } catch (error) {
      console.error("Apple Sign-Up Error:", error.message);
      showToast("Error", error.message, "error");
    } finally {
      setLoading(false);
    }
  };

  // Add useEffect to restore localStorage values
  useEffect(() => {
    const keysToRestore = [
      "face-pic",
      "dob",
      "profession",
      "instagram",
      "linkedin",
      "interests",
      "name",
      "city",
      "isCreator"
    ];

    keysToRestore.forEach(key => {
      const tempValue = sessionStorage.getItem(`temp_${key}`);
      if (tempValue) {
        localStorage.setItem(key, tempValue);
        sessionStorage.removeItem(`temp_${key}`);
      }
    });
  }, []);

  if (isLoading) {
    return <Box minH="100vh" bg="white" />;
  }

  return (
    <Box
      minH="100vh"
      maxH="100vh"
      overflow="hidden"
      position="relative"
      bg="white"
      pb="env(safe-area-inset-bottom)"
    >
      {/* Top Bar - Fixed */}
      <Box
        position="fixed"
        top={0}
        left={0}
        right={0}
        zIndex={1000}
        bg="white"
        h={{ base: "56px", md: "64px" }}
        backdropFilter="blur(12px)"
        backgroundColor="rgba(255, 255, 255, 0.9)"
        borderBottom="1px solid"
        borderColor="gray.100"
      >
        <Container maxW="container.xl" h="full">
          <Flex
            justify="center"
            align="center"
            h="full"
            px={{ base: 3, md: 4 }}
          >
            {/* Logo */}
            <LogoComponent onClick={() => navigate("/")} />
          </Flex>
        </Container>
      </Box>

      {/* Main Content */}
      <Box
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        overflowY="auto"
        style={{
          WebkitOverflowScrolling: "touch",
        }}
      >
        <Box
          pt={{ base: "56px", md: "64px" }}
          minH="100vh"
          pb={{ base: 32, md: 8 }}
          display="flex"
          alignItems="center"
        >
          <Container
            maxW="container.sm"
            py={{ base: 8, md: 16 }}
            px={6}
            mb={{ base: 8, md: 0 }}
          >
            {showSuccess ? (
              <VStack spacing={8} align="center" w="full">
                <VStack spacing={0} textAlign="center">
                  <Box
                    position="relative"
                    w={{ base: "180px", md: "220px" }}
                    h={{ base: "180px", md: "220px" }}
                    mb={4}
                  >
                    {/* Background decorative element */}
                    <Box
                      position="absolute"
                      inset={0}
                      bg="rgba(184, 160, 128, 0.06)"
                      rounded="3xl"
                      transform="rotate(-4deg)"
                    />
                    
                    {/* Main illustration container */}
                    <Box
                      position="relative"
                      h="full"
                      w="full"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      zIndex={1}
                    >
                      {/* Success checkmark */}
                      <Box
                        position="relative"
                        bg="white"
                        p={8}
                        rounded="full"
                        shadow="lg"
                        borderWidth="1px"
                        borderColor="rgba(184, 160, 128, 0.2)"
                      >
                        <Box
                          as="svg"
                          viewBox="0 0 24 24"
                          w="48px"
                          h="48px"
                          fill="none"
                          stroke="#B8A080"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path d="M20 6L9 17L4 12" />
                        </Box>
                      </Box>
                    </Box>
                  </Box>

                  {/* Welcome message */}
                  <Text
                    fontSize={{ base: "2xl", md: "3xl" }}
                    fontWeight="light"
                    color="gray.900"
                    letterSpacing="0.02em"
                    fontFamily="Montserrat, sans-serif"
                    maxW="md"
                    mb={2}
                  >
                    Welcome to hubzo
                  </Text>
                  {/* <Text
                    fontSize={{ base: "md", md: "lg" }}
                    color="gray.600"
                    letterSpacing="wide"
                    maxW="md"
                    mb={6}
                  >
                    Let's get you started
                  </Text> */}
                </VStack>

                <VStack spacing={6} w="full" maxW="400px">
                  <PrimaryButton
                    w="full"
                    leftIcon={<Icon as={MdOutlineEmail} boxSize={5} />}
                    onClick={() => navigate("/email/")}
                  >
                    Sign up with email
                  </PrimaryButton>

                  {false && !isStandaloneIos && (
                    <VStack spacing={4} w="full">
                      <HStack spacing={4} w="full">
                        <Box flex={1} h="1px" bg="gray.200" />
                        <Text
                          fontSize="sm"
                          color="gray.500"
                          fontWeight="medium"
                        >
                          OR
                        </Text>
                        <Box flex={1} h="1px" bg="gray.200" />
                      </HStack>

                      <SocialButton
                        w="full"
                        leftIcon={
                          <Box as="span" mr={2}>
                            <svg width="24" height="24" viewBox="0 0 24 24">
                              <path
                                d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
                                fill="#4285F4"
                              />
                              <path
                                d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
                                fill="#34A853"
                              />
                              <path
                                d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
                                fill="#FBBC05"
                              />
                              <path
                                d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
                                fill="#EA4335"
                              />
                            </svg>
                          </Box>
                        }
                        onClick={handleGoogleSignUp}
                      >
                        Sign up with Google
                      </SocialButton>

                      <SocialButton
                        w="full"
                        leftIcon={<Icon as={FaApple} />}
                        onClick={handleAppleSignUp}
                        isLoading={loading}
                      >
                        Sign up with Apple
                      </SocialButton>
                    </VStack>
                  )}
                </VStack>
              </VStack>
            ) : (
              <VStack spacing={8} align="center" maxW="400px" mx="auto">
                <VStack spacing={8} w="full">
                  {/* Animated background */}
                  <Box
                    position="absolute"
                    inset={0}
                    overflow="hidden"
                    zIndex={0}
                  >
                    <Box
                      position="absolute"
                      top="10%"
                      left="0"
                      right="0"
                      height="500px"
                      bgGradient="radial(circle at 30% 40%, rgba(184, 160, 128, 0.08), transparent 70%)"
                      opacity={0.8}
                      animation="pulse 4s ease-in-out infinite"
                    />
                    <Box
                      position="absolute"
                      bottom="10%"
                      right="0"
                      left="0"
                      height="500px"
                      bgGradient="radial(circle at 70% 60%, rgba(184, 160, 128, 0.08), transparent 70%)"
                      opacity={0.8}
                      animation="pulse 4s ease-in-out infinite alternate"
                    />
                  </Box>

                  {/* Loading content */}
                  <Box
                    position="relative"
                    zIndex={1}
                    bg="white"
                    p={6}
                    rounded="2xl"
                    shadow="lg"
                    borderWidth="1px"
                    borderColor="rgba(184, 160, 128, 0.2)"
                    animation="float 3s ease-in-out infinite"
                  >
                    <Box
                      p={4}
                      bg="rgba(184, 160, 128, 0.1)"
                      rounded="full"
                      width="100px"
                      height="100px"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      position="relative"
                      _after={{
                        content: '""',
                        position: "absolute",
                        top: "0",
                        left: "0",
                        right: "0",
                        bottom: "0",
                        border: "3px solid",
                        borderColor: "rgba(184, 160, 128, 0.2)",
                        borderLeftColor: "#B8A080",
                        borderRadius: "full",
                        animation: "spin 1s linear infinite",
                      }}
                    >
                      <Box
                        as="span"
                        position="relative"
                        color="#1A1A1A"
                        fontSize="xl"
                        fontWeight="400"
                        letterSpacing="0.35em"
                        textTransform="uppercase"
                        fontFamily="Montserrat, sans-serif"
                        sx={{
                          WebkitFontSmoothing: "antialiased",
                          textRendering: "optimizeLegibility",
                        }}
                      >
                        hubzo
                      </Box>
                    </Box>
                  </Box>

                  <Text
                    fontSize={{ base: "xl", md: "2xl" }}
                    fontWeight="light"
                    color="gray.900"
                    textAlign="center"
                    position="relative"
                    zIndex={1}
                    animation="fadeInOut 2s ease-in-out infinite alternate"
                    letterSpacing="0.02em"
                    fontFamily="Montserrat, sans-serif"
                  >
                    Analyzing your profile and discovering exclusive events...
                  </Text>
                </VStack>
              </VStack>
            )}
          </Container>
        </Box>
      </Box>

      {/* Add AlertDialog */}
      <AlertDialog
        isOpen={isDialogOpen}
        leastDestructiveRef={cancelRef}
        onClose={() => setIsDialogOpen(false)}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent mx={4}>
            <AlertDialogHeader
              fontSize="lg"
              fontWeight="bold"
              color="purple.700"
            >
              Open in Browser
            </AlertDialogHeader>

            <AlertDialogBody>
              <VStack spacing={4} align="start">
                <Text color="gray.700">
                  For the best experience, including:
                </Text>
                <VStack spacing={2} align="start" pl={4}>
                  <HStack spacing={2}>
                    <Icon as={FaGoogle} color="purple.500" />
                    <Text color="gray.600">Google Sign In</Text>
                  </HStack>
                  <HStack spacing={2}>
                    <Icon as={FaApple} color="purple.500" />
                    <Text color="gray.600">Apple Sign In</Text>
                  </HStack>
                </VStack>
                <Text color="gray.700">
                  Please open Hubzo in your default browser.
                </Text>
              </VStack>
            </AlertDialogBody>

            <AlertDialogFooter gap={3}>
              <Button
                ref={cancelRef}
                onClick={() => setIsDialogOpen(false)}
                variant="ghost"
                color="gray.500"
              >
                Cancel
              </Button>
              <Button
                bg="black"
                color="white"
                onClick={() => {
                  handleOpenInBrowser();
                  setIsDialogOpen(false);
                }}
                _hover={{
                  opacity: 0.9,
                }}
              >
                Open in Browser
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};

export default OnboardingSignUpPage;
