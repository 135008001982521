const useDeviceType = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  const platform = window.navigator.platform;
  
  const isIOS = () => {
    // Check if device is iOS
    const isAppleDevice = /ipad|iphone|ipod/.test(userAgent);
    // Check if device is iPad running iOS 13+ (which reports as MacIntel)
    const isIPadOS = platform === 'MacIntel' && navigator.maxTouchPoints > 1;
    // Check if running in mobile Chrome iOS simulator
    const isIOSChrome = /crios/.test(userAgent);
    // Check if running in Chrome emulator as iOS
    const isEmulatedIOS = /iphone|ipad/.test(navigator?.userAgentData?.platform?.toLowerCase() || '');

    return isAppleDevice || isIPadOS || isIOSChrome || isEmulatedIOS;
  };

  const isAndroid = () => {
    // Check if device is Android
    const isAndroidDevice = /android/.test(userAgent);
    // Check if running in Chrome emulator as Android
    const isEmulatedAndroid = /android/.test(navigator?.userAgentData?.platform?.toLowerCase() || '');
    
    return isAndroidDevice || isEmulatedAndroid;
  };

  const isMobile = () => {
    return isIOS() || isAndroid();
  };

  return {
    isIOS: isIOS(),
    isAndroid: isAndroid(),
    isMobile: isMobile(),
    isDesktop: !isMobile()
  };
};

export default useDeviceType; 