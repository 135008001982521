import {
  Avatar,
  AvatarBadge,
  Button,
  Center,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Switch,
  Modal,
  Checkbox,
  CheckboxGroup,
  Text,
  Textarea,
  ModalFooter,
  ModalBody,
  CardBody,
  Card,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  useColorModeValue,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";
import { useRef, useState } from "react";
import useAuthStore from "../../store/authStore";
import usePreviewImg from "../../hooks/usePreviewImg";
import useEditProfile from "../../hooks/useEditProfile";
import useShowToast from "../../hooks/useShowToast";
import { DeleteIcon, ChevronRightIcon, EditIcon } from "@chakra-ui/icons";

import {
  InstagramMobileLogo,
  InstagramMobileLightLogo,
  LinkedinLogo,
  LinkedinLightLogo,
  WorkLightLogo,
  WorkBlackLogo,
} from "../../assets/constants";

const EditProfile = ({ isOpen, onClose }) => {
  const authUser = useAuthStore((state) => state.user);
  const fileRef = useRef(null);
  const { handleImageChange, selectedFile, setSelectedFile } = usePreviewImg();
  const { isUpdating, editProfile } = useEditProfile();
  const showToast = useShowToast();
  const [yob, setYob] = useState(authUser.yob);

  const [instagramz, setInstagramz] = useState(authUser.instagram);
  const [linkedinz, setLinkedinz] = useState(authUser.linkedin);
  const [jobz, setJobz] = useState(authUser.job);
  const [fullNamez, setFullNamez] = useState(authUser.fullName);
  const [bioz, setBioz] = useState(authUser.bio);
  const [hiddenz, setHiddenz] = useState(authUser.hidden);

  const [checkInterests, setCheckInterests] = useState(authUser.interests);
  const checkedInterests = (values) => {
    // console.log(values);
    setCheckInterests(values);

    setInputs({ ...inputs, interests: values });
  };

  const [inputs, setInputs] = useState({
    fullName: authUser.fullName,
    username: authUser.username,
    bio: authUser.bio,
    whatsapp: authUser.whatsapp,
    instagram: authUser.instagram,
    linkedin: authUser.linkedin,
    job: authUser.job,
    hidden: authUser.hidden,
    interests: authUser.interests,
    yob: authUser.yob,
  });

  const visibleColor = useColorModeValue("black.500", "white");

  const handleEditProfile = async () => {
    if (inputs.fullName == "") {
      showToast("Error", "Your name cannot be empty", "error");
    } else {
      try {
        await editProfile(inputs, selectedFile);
        setSelectedFile(null);
        onClose();
      } catch (error) {
        showToast("Error", error.message, "error");
      }
    }
  };

  const bgTheme = useColorModeValue(`white`, `gray.800`);

  const InstagramMobileThemeLogo = useColorModeValue(
    InstagramMobileLightLogo,
    InstagramMobileLogo
  );

  const WorkThemeLogo = useColorModeValue(WorkLightLogo, WorkBlackLogo);
  const LinkedinThemeIcon = useColorModeValue(LinkedinLightLogo, LinkedinLogo);

  return (
    <>
      <Modal size={"full"} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          bg={bgTheme}
          // boxShadow={"xl"}
          border={"1px solid gray"}
          maxW={"xl"}

          // maxW={"400px"}
        >
          <ModalHeader mt={4}>
            <Text userSelect={"none"} mb={0} fontSize={22}>
              Edit Profile
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* Container Flex */}

            <Card mt={0} pb={0} w={"full"} mb={0}>
              <CardBody pl={4} pt={0} pb={0} bg={bgTheme}>
                <Flex pb={4} alignItems={"center"} bg={bgTheme}>
                  <Stack
                    spacing={8}
                    w={"full"}
                    maxW={"md"}
                    bg={bgTheme}
                    pb={1}
                    pt={4}
                    my={0}
                  >
                    {/* <Heading
                  userSelect={"none"}
                  lineHeight={1.1}
                  fontSize={{ base: "2xl", sm: "3xl" }}
                >
                  Edit Profile
                </Heading> */}

                    <FormControl>
                      <Stack
                        onClick={() => fileRef.current.click()}
                        direction={["column", "row"]}
                        spacing={6}
                      >
                        <Center>
                          <Avatar
                            // pointerEvents="none"
                            name={authUser.fullName}
                            size="xl"
                            src={selectedFile || authUser.profilePicURL}
                            border={"2px solid white "}
                          >
                            <AvatarBadge bg={"blue"} boxSize="1em">
                              <svg
                                width="0.4em"
                                fill="white"
                                viewBox="0 0 20 20"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M4 5a2 2 0 00-2 2v8a2 2 0 002 2h12a2 2 0 002-2V7a2 2 0 00-2-2h-1.586a1 1 0 01-.707-.293l-1.121-1.121A2 2 0 0011.172 3H8.828a2 2 0 00-1.414.586L6.293 4.707A1 1 0 015.586 5H4zm6 9a3 3 0 100-6 3 3 0 000 6z"
                                />
                              </svg>
                            </AvatarBadge>
                          </Avatar>

                          <Text fontWeight={"semibold"} mx="4">
                            Tap here to select a picture
                          </Text>

                          {/* 
                      <EditIcon
                        onClick={() => fileRef.current.click()}
                        boxSize={6}
                      /> */}
                        </Center>
                        {/* <Center w="full">
                      <Button
                        w="full"
                        colorScheme="gray"
                        variant={"solid"}
                        onClick={() => fileRef.current.click()}
                      >
                        Update Profile Picture
                      </Button>
                    </Center> */}
                        <Input
                          type="file"
                          hidden
                          size={"lg"}
                          ref={fileRef}
                          accept="image/*"
                          onChange={handleImageChange}
                        />
                      </Stack>

                      {/* <FormControl
                        w={"full"}
                        mt={2}
                        // backgroundColor={"red"}
                        display="flex"
                        alignSelf={"flex-end"}
                        alignContent={"flex-end"}
                        alignItems={"flex-end"}
                      >
                        <FormLabel
                          color={visibleColor}
                          fontSize={"xs"}
                          htmlFor="email-alerts"
                          mb="0"
                        >
                          Visible to group admins only
                        </FormLabel>
                        <Switch
                          id="email-alerts"
                          size="sm"
                          // isChecked={inputs.hidden}
                          isChecked={hiddenz}
                          onChange={(e) => {
                            setHiddenz(e.target.checked);
                            setInputs({ ...inputs, hidden: e.target.checked });
                          }}
                        />
                      </FormControl> */}
                    </FormControl>

                    <FormControl>
                      {/* <FormLabel fontSize={"sm"}>Full Name</FormLabel> */}
                      <Input
                        placeholder={"Your Name"}
                        size={"lg"}
                        type={"text"}
                        // value={inputs.fullName || authUser.fullName}
                        value={fullNamez}
                        onChange={(e) => {
                          setFullNamez(e.target.value);
                          setInputs({ ...inputs, fullName: e.target.value });
                        }}
                      />
                    </FormControl>

                    <FormControl>
                      {/* <FormLabel fontSize={"sm"}>Linkedin</FormLabel> */}

                      <InputGroup size={"lg"} mt={0} mb={0}>
                        <InputLeftElement
                          size={"lg"}
                          mt={0}
                          pointerEvents="none"
                        >
                          <LinkedinThemeIcon />
                        </InputLeftElement>

                        <Input
                          placeholder={"Your Year of birth"}
                          size={"lg"}
                          type={"text"}
                          value={yob}
                          onChange={(e) => {
                            setYob(e.target.value);
                            setInputs({ ...inputs, yob: e.target.value });
                          }}
                        />
                      </InputGroup>
                    </FormControl>

                    <FormControl>
                      <InputGroup size={"lg"} mt={0} mb={0}>
                        <InputLeftElement
                          size={"lg"}
                          mt={0}
                          pointerEvents="none"
                        >
                          <WorkThemeLogo />
                        </InputLeftElement>

                        <Input
                          placeholder={"What's your occupation?"}
                          size={"lg"}
                          type={"text"}
                          // value={inputs.job || authUser.job}
                          value={jobz}
                          onChange={(e) => {
                            setJobz(e.target.value);
                            setInputs({ ...inputs, job: e.target.value });
                          }}
                        />
                      </InputGroup>
                    </FormControl>

                    {/* <FormControl>
                      <FormLabel fontSize={"sm"}>Bio</FormLabel>
                      <Input
                    placeholder={"Tell us something about you"}
                    size={"md"}
                    type={"text"}
                    value={inputs.bio || authUser.bio}
                    onChange={(e) =>
                      setInputs({ ...inputs, bio: e.target.value })
                    }
                  />

                      <Textarea
                        placeholder={"Tell us something about you"}
                        // borderColor="whiteAlpha.900"
                        mt={0}
                        // value={inputs.bio || authUser.bio}
                        value={bioz}
                        onChange={(e) => {
                          setBioz(e.target.value);
                          setInputs({ ...inputs, bio: e.target.value });
                        }}
                      />
                    </FormControl> */}

                    {/* <FormControl>
									<FormLabel fontSize={"sm"}>Username</FormLabel>
									<Input
										placeholder={"Username"}
										size={"sm"}
										type={"text"}
										value={inputs.username || authUser.username}
										onChange={(e) => setInputs({ ...inputs, username: e.target.value })}
									/>
								</FormControl> */}

                    <FormControl>
                      {/* <FormLabel fontSize={"sm"}>Instagram</FormLabel> */}

                      <InputGroup size={"lg"} mt={0} mb={0}>
                        <InputLeftElement
                          size={"lg"}
                          mt={0}
                          pointerEvents="none"
                        >
                          <InstagramMobileThemeLogo />
                        </InputLeftElement>

                        <Input
                          placeholder={"Your Instagram @"}
                          size={"lg"}
                          type={"text"}
                          // value={
                          //   inputs.instagram ||
                          //   authUser.instagram
                          // }
                          value={instagramz}
                          onChange={(e) => {
                            setInstagramz(e.target.value);
                            setInputs({ ...inputs, instagram: e.target.value });
                          }}
                        />
                      </InputGroup>
                    </FormControl>

                    <FormControl>
                      {/* <FormLabel fontSize={"sm"}>Linkedin</FormLabel> */}

                      <InputGroup size={"lg"} mt={0} mb={0}>
                        <InputLeftElement
                          size={"lg"}
                          mt={0}
                          pointerEvents="none"
                        >
                          <LinkedinThemeIcon />
                        </InputLeftElement>

                        <Input
                          placeholder={"Your LinkedIn url"}
                          size={"lg"}
                          type={"text"}
                          value={linkedinz}
                          onChange={(e) => {
                            setLinkedinz(e.target.value);
                            setInputs({ ...inputs, linkedin: e.target.value });
                          }}
                        />
                      </InputGroup>
                    </FormControl>


                  <CheckboxGroup
                    colorScheme="pink"
                    defaultValue={["drinks", "locals", "sports"]}
                    value={checkInterests}
                    onChange={checkedInterests}
                    // onChange={(e) => {
                    //   setCheckInterests(e);
                    //   setInputs({ ...inputs, interests: e });
                    // }}
                  >
                    <Stack spacing={[1, 5]} direction={["column", "row"]}>
                      <Checkbox value="drinks">Networking social</Checkbox>
                      <Checkbox value="locals">Locals events</Checkbox>
                      <Checkbox value="sports">Sports</Checkbox>
                    </Stack>
                  </CheckboxGroup>

                    {/* <FormControl>
									<FormLabel fontSize={"sm"}>WhatsApp Group</FormLabel>
									<Input
										placeholder={"Link"}
										size={"sm"}
										type={"text"}
										value={inputs.whatsapp || authUser.whatsapp}
										onChange={(e) => setInputs({ ...inputs, whatsapp: e.target.value })}
									/>
								</FormControl> */}

                    {/* <Stack spacing={6} direction={["column", "row"]}>
                  <Button
                    bg={"red.400"}
                    color={"white"}
                    w="full"
                    size="sm"
                    _hover={{ bg: "red.500" }}
                    onClick={onClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    bg={"blue.400"}
                    color={"white"}
                    size="sm"
                    w="full"
                    _hover={{ bg: "blue.500" }}
                    onClick={handleEditProfile}
                    isLoading={isUpdating}
                  >
                    Submit
                  </Button>
                </Stack> */}
                  </Stack>
                </Flex>
              </CardBody>
            </Card>
          </ModalBody>
          <ModalFooter>
            <Button mb={2} mr={4} onClick={onClose}>
              Cancel
            </Button>

            <Button
              variant={"outline"}
              colorScheme="whatsapp"
              mb={2}
              mr={0}
              onClick={handleEditProfile}
              isLoading={isUpdating}
            >
              Update
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default EditProfile;

// COPY AND PASTE IT AS THE STARTED EDIT PROFILE MODAL
// import {
// 	Avatar,
// 	Button,
// 	Center,
// 	Flex,
// 	FormControl,
// 	FormLabel,
// 	Heading,
// 	Input,
// 	Modal,
// 	ModalBody,
// 	ModalCloseButton,
// 	ModalContent,
// 	ModalHeader,
// 	ModalOverlay,
// 	Stack,
// } from "@chakra-ui/react";

// const EditProfile = ({ isOpen, onClose }) => {
// 	return (
// 		<>
// 			<Modal isOpen={isOpen} onClose={onClose}>
// 				<ModalOverlay />
// 				<ModalContent bg={"black"} boxShadow={"xl"} border={"1px solid gray"} mx={3}>
// 					<ModalHeader />
// 					<ModalCloseButton />
// 					<ModalBody>
// 						{/* Container Flex */}
// 						<Flex bg={"black"}>
// 							<Stack spacing={4} w={"full"} maxW={"md"} bg={"black"} p={6} my={0}>
// 								<Heading lineHeight={1.1} fontSize={{ base: "2xl", sm: "3xl" }}>
// 									Edit Profile
// 								</Heading>
// 								<FormControl>
// 									<Stack direction={["column", "row"]} spacing={6}>
// 										<Center>
// 											<Avatar size='xl' src={""} border={"2px solid white "} />
// 										</Center>
// 										<Center w='full'>
// 											<Button w='full'>Edit Profile Picture</Button>
// 										</Center>
// 									</Stack>
// 								</FormControl>

// 								<FormControl>
// 									<FormLabel fontSize={"sm"}>Full Name</FormLabel>
// 									<Input placeholder={"Full Name"} size={"sm"} type={"text"} />
// 								</FormControl>

// 								<FormControl>
// 									<FormLabel fontSize={"sm"}>Username</FormLabel>
// 									<Input placeholder={"Username"} size={"sm"} type={"text"} />
// 								</FormControl>

// 								<FormControl>
// 									<FormLabel fontSize={"sm"}>Bio</FormLabel>
// 									<Input placeholder={"Bio"} size={"sm"} type={"text"} />
// 								</FormControl>

// 								<Stack spacing={6} direction={["column", "row"]}>
// 									<Button
// 										bg={"red.400"}
// 										color={"white"}
// 										w='full'
// 										size='sm'
// 										_hover={{ bg: "red.500" }}
// 									>
// 										Cancel
// 									</Button>
// 									<Button
// 										bg={"blue.400"}
// 										color={"white"}
// 										size='sm'
// 										w='full'
// 										_hover={{ bg: "blue.500" }}
// 									>
// 										Submit
// 									</Button>
// 								</Stack>
// 							</Stack>
// 						</Flex>
// 					</ModalBody>
// 				</ModalContent>
// 			</Modal>
// 		</>
// 	);
// };

// export default EditProfile;
