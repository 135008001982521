import {
  Container,
  Flex,
  Text,
  IconButton,
  Box,
  Image,
  Icon,
  HStack,
  Badge,
  Center,
  Spinner,
  VStack,
} from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useAuthStore from "../../store/authStore";
import DesktopNav from "../../components/Navigation/DesktopNav";
import { FaInfoCircle, FaBriefcase, FaClock, FaUser } from "react-icons/fa";

const ProfileViewPage = () => {
  const authUser = useAuthStore((state) => state.user);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(0);
  const TOTAL_PAGES = 3;

  const colors = {
    textColor: "gray.900",
    bgColor: "white",
    cardBg: "white",
    dividerColor: "gray.200",
    iconBgColor: "rgba(184, 160, 128, 0.1)",
    hoverBgColor: "rgba(184, 160, 128, 0.05)",
    subtitleColor: "gray.600",
    accentColor: "#B8A080",
    borderColor: "rgba(184, 160, 128, 0.2)",
  };

  const DetailedView = ({ user }) => (
    <Box w="full" h="full" bg="white" p={8}>
      <VStack align="start" spacing={8} h="full">
        <Box w="full">
          <HStack spacing={2} mb={4} color="gray.600">
            <Icon as={FaInfoCircle} />
            <Text fontSize="lg" fontWeight="semibold">
              About {user.fullName}
            </Text>
          </HStack>
          <Text
            color="gray.700"
            fontSize="lg"
            whiteSpace="pre-wrap"
            textAlign="center"
          >
            {user.bio || "No bio yet"}
          </Text>
        </Box>

        <Flex gap={3} flexWrap="wrap" justify="center" w="full">
          {user.occupation && (
            <Badge
              px={4}
              py={2}
              bg="yellow.50"
              color="gray.700"
              rounded="full"
              fontSize="md"
              display="flex"
              alignItems="center"
              gap={2}
            >
              <Icon as={FaBriefcase} />
              {user.occupation}
            </Badge>
          )}
          {user.yob && (
            <Badge
              px={4}
              py={2}
              bg="yellow.50"
              color="gray.700"
              rounded="full"
              fontSize="md"
              display="flex"
              alignItems="center"
              gap={2}
            >
              <Icon as={FaClock} />
              {new Date().getFullYear() - user.yob} years
            </Badge>
          )}
        </Flex>

        {Array.isArray(user.interests) && user.interests.length > 0 && (
          <Box w="full">
            <Text fontSize="lg" fontWeight="semibold" mb={4} color="gray.600">
              Interests
            </Text>
            <Flex gap={3} flexWrap="wrap">
              {user.interests.map((interest, i) => (
                <Badge
                  key={i}
                  px={4}
                  py={2}
                  bg="yellow.50"
                  color="gray.700"
                  rounded="full"
                  fontSize="md"
                >
                  {interest}
                </Badge>
              ))}
            </Flex>
          </Box>
        )}
      </VStack>
    </Box>
  );

  const PhotosView = ({ user }) => (
    <Box w="full" h="full" bg="white">
      <Flex h="full">
        {/* First Photo */}
        <Box w="50%" h="full" position="relative">
          <Image
            src={user?.profilePicURLs?.pic2?.url || user?.profilePicURL}
            alt={`${user.fullName} photo 1`}
            objectFit="cover"
            w="full"
            h="full"
          />
        </Box>

        {/* Second Photo */}
        <Box w="50%" h="full" position="relative">
          <Image
            src={user?.profilePicURLs?.pic3?.url || user?.profilePicURL}
            alt={`${user.fullName} photo 2`}
            objectFit="cover"
            w="full"
            h="full"
          />
        </Box>
      </Flex>
    </Box>
  );

  if (!authUser) {
    return (
      <Center h="100vh">
        <Spinner size="xl" color="purple.500" />
      </Center>
    );
  }

  return (
    <Box
      h="100vh"
      maxH="100vh"
      overflow="hidden"
      position="relative"
      bg={colors.bgColor}
      pb="env(safe-area-inset-bottom)"
    >
      <Box display={{ base: "none", md: "block" }}>
        <DesktopNav />
      </Box>

      <Box
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        overflowY="auto"
        pb={{ base: 32, md: 8 }}
      >
        <Container
          maxW={{ base: "container.md", md: "container.xl" }}
          px={4}
          py={6}
          mb={{ base: 8, md: 0 }}
          mt={{ base: 0, md: "60px" }}
        >
          <Flex w="full" alignItems="center" mb={8} position="relative">
            <IconButton
              icon={<ArrowBackIcon />}
              variant="ghost"
              aria-label="Go back"
              onClick={() => navigate(-1)}
              display={{ base: "flex", md: "none" }}
              color={colors.accentColor}
              _hover={{
                bg: colors.iconBgColor,
                transform: "translateY(-2px)",
              }}
              transition="all 0.2s"
            />
            <Text
              fontSize={{ base: "2xl", md: "3xl" }}
              fontWeight="light"
              color={colors.textColor}
              textAlign={{ base: "left", md: "center" }}
              flex="1"
              ml={{ base: 4, md: 0 }}
              letterSpacing="wide"
              fontFamily="Montserrat, sans-serif"
            >
              Your Profile
            </Text>
          </Flex>

          <Box position="relative" w={{ base: "full", md: "850px" }} mx="auto">
            <Box
              w="full"
              h={{ base: "calc(100vh - 180px)", md: "600px" }}
              borderRadius={{ base: "none", md: "xl" }}
              overflow="hidden"
              bg="white"
              shadow={{ base: "none", md: "xl" }}
              position="relative"
            >
              <Box
                transform={`translateY(-${currentPage * 100}%)`}
                transition="transform 0.3s ease-out"
                h="full"
              >
                {/* Main View */}
                <Flex h="full" direction={{ base: "column", md: "row" }}>
                  <Box
                    w={{ base: "100%", md: "65%" }}
                    h={{ base: "60%", md: "full" }}
                    position="relative"
                  >
                    <Image
                      src={
                        authUser?.profilePicURLs?.pic1?.url ||
                        authUser?.profilePicURL
                      }
                      alt={authUser.fullName}
                      objectFit="cover"
                      w="full"
                      h="full"
                    />
                  </Box>

                  <Box
                    w={{ base: "100%", md: "35%" }}
                    h={{ base: "40%", md: "full" }}
                    borderLeft={{ base: "none", md: "1px" }}
                    borderTop={{ base: "1px", md: "none" }}
                    borderColor="gray.100"
                    p={{ base: 4, md: 6 }}
                  >
                    <VStack align="start" spacing={6} h="full">
                      <Box>
                        <HStack spacing={3} mb={2}>
                          <Text fontSize="3xl" fontWeight="bold">
                            {authUser.fullName}
                          </Text>
                          <Text fontSize="2xl" color="gray.500">
                            {authUser.yob
                              ? new Date().getFullYear() - authUser.yob
                              : ""}
                          </Text>
                        </HStack>
                        <Text color="gray.500" fontSize="md">
                          {authUser.occupation || "No occupation listed"}
                        </Text>
                      </Box>
                    </VStack>
                  </Box>
                </Flex>

                {/* Detailed View */}
                <Box h="full" position="absolute" top="100%" left="0" right="0">
                  <DetailedView user={authUser} />
                </Box>

                {/* Photos View */}
                <Box h="full" position="absolute" top="200%" left="0" right="0">
                  <PhotosView user={authUser} />
                </Box>
              </Box>

              {/* Pagination Dots */}
              <Box
                position="absolute"
                top={4}
                right={4}
                zIndex={2}
                display="flex"
                flexDirection="column"
                gap={2}
              >
                {Array.from({ length: TOTAL_PAGES }).map((_, index) => (
                  <Box
                    key={index}
                    w="4px"
                    h="24px"
                    bg={currentPage === index ? "yellow.400" : "gray.300"}
                    rounded="full"
                    cursor="pointer"
                    onClick={() => setCurrentPage(index)}
                    transition="background 0.2s"
                  />
                ))}
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default ProfileViewPage;
