import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Avatar,
  Button,
  Menu,
  MenuButton,
  Grid,
  Image,
  MenuList,
  MenuItem,
  MenuDivider,
  useColorModeValue,
  Stack,
  useColorMode,
  Center,
  Box,
  Container,
  Flex,
  Divider,
  AlertDescription,
  Alert,
  Text,
  AlertIcon,
  useDisclosure,
  CloseButton,
} from "@chakra-ui/react";
import { MoonIcon, SunIcon, ArrowBackIcon } from "@chakra-ui/icons";

import useGetMemories from "../../hooks/useGetMemories";

import { Link as RouterLink, useNavigate } from "react-router-dom";

import useShowToast from "../../hooks/useShowToast";
import GroupsPosts from "../../components/GroupsPosts/GroupsPosts";
import SuggestedUsers from "../../components/SuggestedUsers/SuggestedUsers";
import SuggestedUsersLite from "../../components/SuggestedUsers/SuggestedUsersLite";
import useAuthStore from "../../store/authStore";

import MemoryCard from "../../components/Memories/MemoryCard";
import { useEffect, useRef, useState } from "react";

const MemoriesPage = () => {
  const authUser = useAuthStore((state) => state.user);
  const [prompt, setState] = useState(null);
  const showToast = useShowToast();

  const color = useColorModeValue("black", "white");

  const { isLoading, memories } = useGetMemories(true);

  const navigate = useNavigate();

  // const cancelRef = useRef();/

  // const {
  //   isOpen: isOpenModal,
  //   onOpen: onOpenModal,
  //   onClose: onCloseModal,
  // } = useDisclosure();

  // const {
  //   isOpen: isVisible,
  //   onClose,
  //   onOpen,
  // } = useDisclosure({ defaultIsOpen: true });

  // const { colorMode, toggleColorMode } = useColorMode();
  // const { isOpen, onOpen, onClose } = useDisclosure();

  // useEffect(() => {
  //   const ready = (e) => {
  //     e.preventDefault();
  //     console.log("Install Prompt fired");
  //     setState(e);
  //     // showToast("", "App install dismissed", "error");
  //   };

  //   window.addEventListener("beforeinstallprompt", ready);

  //   return () => {
  //     window.removeEventListener("beforeinstallprompt", ready);
  //   };
  // }, []);

  return (
    <Container
      px={0}
      // px={2}
      maxW={"container.lg"}
    >
      <Box bg={useColorModeValue(`white`, `gray.800`)} px={2}>
        <Flex h={16} alignItems={"center"} justifyContent={"flex-start"}>
          <ArrowBackIcon
            ml={2}
            boxSize={6}
            onClick={() => {
              navigate(-1);
            }}
          />

          <Box ml={4}>
            <Text
              // lineHeight={3.5}
              // letterSpacing={-1.5}
              // fontWeight={"bold"}
              ml={2}
              color={color}
              pl={0}
              fontSize={24}
              // fontWeight={"bold"} color={"green.300"}
            >
              Memories
            </Text>
          </Box>
        </Flex>
      </Box>

      <Flex maxW={"container.lg"} mb={40} flexDir={"column"}>
        {memories && memories.length > 0 && (
          <Flex
            // bg={"red"}
            alignSelf={"center"}
            // maxW={"lg"}
            alignItems={"flex-end"}
            mt={0}
            mb={0}
            // onClick={() => {
            //   onOpenModal();
            // }}
            flexDirection={"column"}
          >
            {/* <Divider mt={1} mb={1} bg={"gray.8000"} /> */}

            <Grid
              mt={1}
              mb={0}
              // templateColumns="repeat(2, 1fr)"

              templateColumns={{
                base: "repeat(2, 1fr)",
                md: "repeat(4, 1fr)",
                lg: "repeat(4, 1fr)",
              }}
              gap={1}
            >
              {memories.map((memory, idx) => (
                // <GridItem w="100%" h="10" bg="blue.500" />

                <MemoryCard
                  hasFooter={true}
                  key={idx}
                  memory={memory}
                  isClickable={true}
                  userId={authUser.uid}
                />
              ))}
            </Grid>

            {/* <Divider mt={0} mb={0} bg={"gray.8000"} /> */}
          </Flex>
        )}
      </Flex>
    </Container>
  );
};

export default MemoriesPage;
