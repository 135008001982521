import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Text,
  VStack,
  Icon,
  HStack,
  IconButton,
  SimpleGrid,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import {
  FaInstagram,
  FaLinkedin,
  FaEnvelope,
  FaHandshake,
  FaNewspaper,
  FaHeadset,
} from "react-icons/fa";
import { FaFacebook, FaTiktok } from "react-icons/fa6";

const ContactPage = () => {
  const navigate = useNavigate();

  const colors = {
    textColor: "gray.900",
    bgColor: "white",
    cardBg: "white",
    dividerColor: "gray.200",
    iconBgColor: "rgba(184, 160, 128, 0.1)",
    hoverBgColor: "rgba(184, 160, 128, 0.05)",
    subtitleColor: "gray.600",
    accentColor: "#B8A080",
    borderColor: "rgba(184, 160, 128, 0.2)",
  };

  const TopBanner = () => (
    <Box
      position="sticky"
      top={0}
      left={0}
      right={0}
      zIndex={1000}
      bg={colors.bgColor}
      h={{ base: "56px", md: "64px" }}
      backdropFilter="blur(12px)"
      backgroundColor="rgba(255, 255, 255, 0.9)"
      borderBottom="1px"
      borderColor={colors.borderColor}
    >
      <Container maxW="container.xl" h="full">
        <Flex
          justify="space-between"
          align="center"
          h="full"
          px={{ base: 3, md: 4 }}
        >
          {/* Logo */}
          <Box
            as="h1"
            fontSize={{ base: "28px", md: "32px" }}
            fontWeight="400"
            letterSpacing="0.35em"
            position="relative"
            display="inline-block"
            cursor="pointer"
            color={colors.textColor}
            textTransform="uppercase"
            fontFamily="Montserrat, sans-serif"
            onClick={() => navigate("/")}
            _hover={{
              letterSpacing: "0.4em",
              transform: "translateY(-1px)",
            }}
            transition="all 0.4s cubic-bezier(0.4, 0, 0.2, 1)"
            sx={{
              WebkitFontSmoothing: "antialiased",
              textRendering: "optimizeLegibility",
            }}
          >
            <Box
              as="span"
              position="relative"
              _before={{
                content: '""',
                position: "absolute",
                left: "-8%",
                right: "-8%",
                top: "-8px",
                bottom: "-8px",
                background: colors.iconBgColor,
                opacity: { base: 0.95, md: 0.9 },
                borderRadius: "2px",
                transform: "skew(-4deg)",
                transition: "all 0.3s ease",
                zIndex: -1,
              }}
              _after={{
                content: '""',
                position: "absolute",
                left: 0,
                right: 0,
                bottom: "-4px",
                height: "1px",
                background: `linear-gradient(to right, transparent, ${colors.accentColor}, transparent)`,
                opacity: 0.4,
                transform: "scaleX(0.8)",
                transition: "transform 0.3s ease",
              }}
              _hover={{
                _after: {
                  transform: "scaleX(1)",
                },
              }}
            >
              hubzo
            </Box>
          </Box>

          {/* Sign In Button */}
          <Button
            size={{ base: "sm", md: "md" }}
            bg="black"
            color="white"
            fontWeight="medium"
            onClick={() => navigate("/auth")}
            rounded="full"
            px={{ base: 4, md: 6 }}
            h={{ base: 10, md: 12 }}
            fontSize={{ base: "sm", md: "md" }}
            letterSpacing="wide"
            fontFamily="Montserrat, sans-serif"
            _hover={{
              transform: "translateY(-2px)",
              shadow: "lg",
              opacity: 0.9,
            }}
            _active={{
              transform: "translateY(0)",
              opacity: 0.8,
            }}
            transition="all 0.2s"
          >
            Sign In
          </Button>
        </Flex>
      </Container>
    </Box>
  );

  const contactSections = [
    {
      title: "Help / Support",
      description: "For all things technical and app-related.",
      icon: FaHeadset,
      action: "Contact Support",
      onClick: () => window.open("mailto:support@hubzo.social"),
    },
    {
      title: "Partnerships",
      description: "Interested in partnering with Hubzo?",
      icon: FaHandshake,
      action: "Email Us",
      onClick: () => window.open("mailto:partners@hubzo.social"),
    },
    {
      title: "Press",
      description:
        "Interested in including Hubzo in your next article or blog?",
      icon: FaNewspaper,
      action: "Get in Touch",
      onClick: () => window.open("mailto:press@hubzo.social"),
    },
    {
      title: "Success Stories",
      description:
        "Did you meet amazing people through Hubzo? Tell us about it!",
      icon: FaEnvelope,
      action: "Share Your Story",
      onClick: () => window.open("mailto:stories@hubzo.social"),
    },
  ];

  return (
    <Box
      h="100vh"
      maxH="100vh"
      overflow="hidden"
      position="relative"
      bg={colors.bgColor}
    >
      <TopBanner />
      <Box
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        overflowY="auto"
        pb={8}
        style={{
          WebkitOverflowScrolling: 'touch'
        }}
      >
        <Box pt="0">
          {/* Main Content */}
          <Container maxW="container.xl" py={20}>
            <VStack spacing={12} align="start">
              <VStack align="start" spacing={6} w="full">
                <Heading
                  fontSize={{ base: "3xl", md: "5xl" }}
                  fontWeight="light"
                  color={colors.textColor}
                  letterSpacing="wide"
                  fontFamily="Montserrat, sans-serif"
                >
                  Contact Us
                </Heading>
                <Text
                  fontSize={{ base: "lg", md: "xl" }}
                  color={colors.subtitleColor}
                  maxW="3xl"
                  lineHeight="tall"
                  fontFamily="Montserrat, sans-serif"
                  letterSpacing="wide"
                >
                  Got something you want to talk about? We'd love to hear from
                  you. Choose the most relevant option below and we'll make sure
                  to get back to you as soon as possible.
                </Text>
              </VStack>

              <SimpleGrid columns={{ base: 1, md: 2 }} spacing={8} w="full">
                {contactSections.map((section, index) => (
                  <Box
                    key={index}
                    p={8}
                    bg={colors.cardBg}
                    rounded="xl"
                    borderWidth="1px"
                    borderColor={colors.borderColor}
                    shadow="sm"
                    transition="all 0.2s"
                    _hover={{
                      transform: "translateY(-2px)",
                      shadow: "md",
                    }}
                  >
                    <VStack align="start" spacing={4}>
                      <Icon 
                        as={section.icon} 
                        boxSize={8} 
                        color={colors.accentColor} 
                      />
                      <Heading 
                        size="md"
                        fontFamily="Montserrat, sans-serif"
                        letterSpacing="wide"
                        color={colors.textColor}
                      >
                        {section.title}
                      </Heading>
                      <Text 
                        color={colors.subtitleColor}
                        fontFamily="Montserrat, sans-serif"
                        letterSpacing="wide"
                      >
                        {section.description}
                      </Text>
                      <Button
                        variant="ghost"
                        color={colors.accentColor}
                        rightIcon={<Icon as={FaEnvelope} />}
                        onClick={section.onClick}
                        fontFamily="Montserrat, sans-serif"
                        letterSpacing="wide"
                        _hover={{
                          bg: colors.iconBgColor,
                          transform: "translateY(-1px)",
                        }}
                        transition="all 0.2s"
                      >
                        {section.action}
                      </Button>
                    </VStack>
                  </Box>
                ))}
              </SimpleGrid>
            </VStack>
          </Container>

          {/* Footer */}
          <Box
            borderTop="1px"
            borderColor={colors.borderColor}
            bg={colors.iconBgColor}
          >
            <Container maxW="container.xl" py={20}>
              <VStack spacing={16}>
                {/* Top Footer */}
                <Flex
                  direction={{ base: "column", md: "row" }}
                  justify="space-between"
                  align={{ base: "start", md: "center" }}
                  w="full"
                  gap={10}
                >
                  <SimpleGrid columns={{ base: 2, md: 3 }} spacing={10}>
                    <VStack align="start" spacing={4}>
                      <Text
                        fontWeight="semibold"
                        fontSize="sm"
                        textTransform="uppercase"
                        color={colors.textColor}
                      >
                        Company
                      </Text>
                      <VStack align="start" spacing={3}>
                        <Button
                          variant="link"
                          fontSize="sm"
                          color={colors.subtitleColor}
                          onClick={() => navigate("/about-us")}
                        >
                          About Us
                        </Button>
                        <Button
                          variant="link"
                          fontSize="sm"
                          color={colors.subtitleColor}
                          onClick={() =>
                            window.open(
                              "https://www.linkedin.com/company/hubzo",
                              "_blank"
                            )
                          }
                        >
                          Careers
                        </Button>
                        {/* <Button
                          variant="link"
                          fontSize="sm"
                          color={colors.subtitleColor}
                        >
                          Press
                        </Button> */}
                        <Button
                          variant="link"
                          fontSize="sm"
                          color={colors.subtitleColor}
                          onClick={() => navigate("/contact")}
                        >
                          Contact
                        </Button>
                      </VStack>
                    </VStack>

                    <VStack align="start" spacing={4}>
                      <Text
                        fontWeight="semibold"
                        fontSize="sm"
                        textTransform="uppercase"
                        color={colors.textColor}
                      >
                        Resources
                      </Text>
                      <VStack align="start" spacing={3}>
                        <Button
                          variant="link"
                          fontSize="sm"
                          color={colors.subtitleColor}
                        >
                          Blog
                        </Button>
                        <Button
                          variant="link"
                          fontSize="sm"
                          color={colors.subtitleColor}
                        >
                          Community Guidelines
                        </Button>
                        <Button
                          variant="link"
                          fontSize="sm"
                          color={colors.subtitleColor}
                          onClick={() => navigate("/safety-tips")}
                        >
                          Safety Tips
                        </Button>
                        <Button
                          variant="link"
                          fontSize="sm"
                          color={colors.subtitleColor}
                          onClick={() => navigate("/faq")}
                        >
                          FAQ
                        </Button>
                      </VStack>
                    </VStack>

                    <VStack align="start" spacing={4}>
                      <Text
                        fontWeight="semibold"
                        fontSize="sm"
                        textTransform="uppercase"
                        color={colors.textColor}
                      >
                        Legal
                      </Text>
                      <VStack align="start" spacing={3}>
                        <Button
                          variant="link"
                          fontSize="sm"
                          color={colors.subtitleColor}
                          onClick={() => navigate("/privacy-policy")}
                        >
                          Privacy Policy
                        </Button>
                        <Button
                          variant="link"
                          fontSize="sm"
                          color={colors.subtitleColor}
                        >
                          Terms of Service
                        </Button>
                        <Button
                          variant="link"
                          fontSize="sm"
                          color={colors.subtitleColor}
                        >
                          Cookie Policy
                        </Button>
                        <Button
                          variant="link"
                          fontSize="sm"
                          color={colors.subtitleColor}
                        >
                          Safety
                        </Button>
                      </VStack>
                    </VStack>
                  </SimpleGrid>
                </Flex>

                {/* Bottom Footer */}
                <Flex
                  pt={8}
                  borderTop="1px"
                  borderColor={colors.borderColor}
                  w="full"
                  direction={{ base: "column", sm: "row" }}
                  justify="space-between"
                  align="center"
                  gap={4}
                >
                  <HStack
                    spacing={4}
                    color={colors.subtitleColor}
                    fontSize="sm"
                  >
                    <Text>© 2024 Hubzo</Text>
                    <Text>•</Text>
                    <Text>Made with ❤️ in London</Text>
                  </HStack>

                  <HStack spacing={6}>
                    <IconButton
                      aria-label="Instagram"
                      icon={<FaInstagram size={24} />}
                      variant="ghost"
                      color="gray.500"
                      size="lg"
                      rounded="full"
                      _hover={{
                        transform: "translateY(-2px)",
                        color: "#E1306C",
                        bg: "gray.50",
                      }}
                      transition="all 0.2s"
                      onClick={() =>
                        window.open(
                          "https://www.instagram.com/hubzo.social/",
                          "_blank"
                        )
                      }
                    />
                    <IconButton
                      aria-label="TikTok"
                      icon={<FaTiktok size={22} />}
                      variant="ghost"
                      color="gray.500"
                      size="lg"
                      rounded="full"
                      _hover={{
                        transform: "translateY(-2px)",
                        color: "#000000",
                        bg: "gray.50",
                      }}
                      transition="all 0.2s"
                      onClick={() =>
                        window.open(
                          "https://www.tiktok.com/@hubzo.social",
                          "_blank"
                        )
                      }
                    />
                    <IconButton
                      aria-label="Facebook"
                      icon={<FaFacebook size={24} />}
                      variant="ghost"
                      color="gray.500"
                      size="lg"
                      rounded="full"
                      _hover={{
                        transform: "translateY(-2px)",
                        color: "#1877F2",
                        bg: "gray.50",
                      }}
                      transition="all 0.2s"
                      onClick={() =>
                        window.open(
                          "https://www.facebook.com/hubzo.social",
                          "_blank"
                        )
                      }
                    />
                    <IconButton
                      aria-label="LinkedIn"
                      icon={<FaLinkedin size={24} />}
                      variant="ghost"
                      color="gray.500"
                      size="lg"
                      rounded="full"
                      _hover={{
                        transform: "translateY(-2px)",
                        color: "#0077B5",
                        bg: "gray.50",
                      }}
                      transition="all 0.2s"
                      onClick={() =>
                        window.open(
                          "https://www.linkedin.com/company/hubzo",
                          "_blank"
                        )
                      }
                    />
                  </HStack>
                </Flex>
              </VStack>
            </Container>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ContactPage;
