import {
  Container,
  Flex,
  VStack,
  Box,
  Text,
  Button,
  Input,
  InputGroup,
  InputRightElement,
  IconButton,
  Divider,
  useColorModeValue,
  HStack,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Icon,
} from "@chakra-ui/react";
import { ArrowBackIcon, ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { FaGoogle, FaApple, FaArrowRight } from "react-icons/fa";
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  getAuth,
  OAuthProvider,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import useLogin from "../../hooks/useLogin";
import useShowToast from "../../hooks/useShowToast";
import useAuthStore from "../../store/authStore";
import { firestore, storage, auth } from "../../firebase/firebase";
import { doc, getDoc } from "firebase/firestore";

const isIosStandalone = () => {
  const isIosDevice =
    /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  const isStandaloneMode =
    typeof window.navigator.standalone === "boolean"
      ? window.navigator.standalone
      : window.matchMedia &&
        window.matchMedia("(display-mode: standalone)").matches;
  return isIosDevice && isStandaloneMode;
};

const isAppleDevice = () => {
  return /iPad|iPhone|iPod|Macintosh|MacIntel/i.test(navigator.userAgent);
};

const getExternalBrowserUrl = () => {
  const currentUrl = window.location.href;
  return currentUrl;
};

const LogoComponent = ({ onClick }) => (
  <Box 
    as="h1" 
    fontSize={{ base: "28px", md: "32px" }}
    fontWeight="400"
    letterSpacing="0.35em"
    position="relative"
    display="inline-block"
    cursor="pointer"
    color="#1A1A1A"
    textTransform="uppercase"
    fontFamily="Montserrat, sans-serif"
    onClick={onClick}
    _hover={{
      letterSpacing: "0.4em",
      transform: "translateY(-1px)",
      transition: "all 0.4s cubic-bezier(0.4, 0, 0.2, 1)",
    }}
    transition="all 0.4s cubic-bezier(0.4, 0, 0.2, 1)"
    sx={{
      WebkitFontSmoothing: "antialiased",
      textRendering: "optimizeLegibility",
    }}
  >
    <Box
      as="span"
      position="relative"
      _before={{
        content: '""',
        position: "absolute",
        left: "-8%",
        right: "-8%",
        top: "-8px",
        bottom: "-8px",
        background: "linear-gradient(135deg, rgba(26, 26, 26, 0.08), rgba(26, 26, 26, 0.03))",
        backdropFilter: "blur(4px)",
        opacity: { base: 0.95, md: 0.9 },
        borderRadius: "2px",
        transform: "skew(-4deg)",
        transition: "all 0.3s ease",
        zIndex: -1,
      }}
      _after={{
        content: '""',
        position: "absolute",
        left: 0,
        right: 0,
        bottom: "-4px",
        height: "1px",
        background: "linear-gradient(to right, transparent, currentColor, transparent)",
        opacity: 0.4,
        transform: "scaleX(0.8)",
        transition: "transform 0.3s ease",
      }}
      _hover={{
        _after: {
          transform: "scaleX(1)",
        },
      }}
    >
      hubzo
    </Box>
  </Box>
);

const PrimaryButton = ({ children, ...props }) => (
  <Button
    size="lg"
    bg="black"
    color="white"
    px={8}
    h={14}
    fontSize="lg"
    fontWeight="semibold"
    rounded="full"
    _hover={{
      transform: "translateY(-2px)",
      shadow: "lg",
      opacity: 0.95,
    }}
    transition="all 0.2s"
    {...props}
  >
    {children}
  </Button>
);

const SocialButton = ({ children, ...props }) => (
  <Button
    size="lg"
    bg="white"
    color="gray.900"
    px={8}
    h={14}
    fontSize="lg"
    fontWeight="semibold"
    rounded="full"
    border="1px solid"
    borderColor="gray.200"
    _hover={{
      transform: "translateY(-2px)",
      shadow: "lg",
      bg: "gray.50",
    }}
    transition="all 0.2s"
    {...props}
  >
    {children}
  </Button>
);

const AuthPage = () => {
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const { login, loading } = useLogin();
  const showToast = useShowToast();
  const loginUser = useAuthStore((state) => state.login);
  const emailInputRef = useRef(null);
  const passwordInputRef = useRef(null);
  const [inputs, setInputs] = useState({ email: "", password: "" });
  const [isStandaloneIos, setIsStandaloneIos] = useState(true);
  const [isApple, setIsApple] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const cancelRef = useRef();
  const isInstagramBrowser = /Instagram/.test(navigator.userAgent);

  useEffect(() => {
    window.scrollTo(0, 0);
    setIsStandaloneIos(isIosStandalone());
    setIsApple(isAppleDevice());
  }, []);

  const togglePasswordVisibility = () => setShowPassword(!showPassword);
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      if (e.target.name === "email") {
        passwordInputRef.current.focus();
      } else if (e.target.name === "password") {
        handleLogin();
      }
    }
  };

  const handleLogin = () => {
    if (!inputs.email || !inputs.password) {
      showToast("Error", "Please fill in all fields", "error");
    } else {
      login(inputs);
    }
  };

  const handleGoogleSignIn = async () => {
    // Check if we're in Instagram's in-app browser
    if (isInstagramBrowser) {
      setIsDialogOpen(true);
      return;
    }

    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const newUser = result.user;

      if (newUser) {
        const userRef = doc(firestore, "users", newUser.uid);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists()) {
          const userDoc = userSnap.data();
          localStorage.setItem("user-info", JSON.stringify(userDoc));
          loginUser(userDoc);
          navigate("/");
        } else {
          showToast("Error", "User doesn't exist", "error");
        }
      }
    } catch (error) {
      // Handle specific error cases
      if (error.code === 'auth/popup-blocked') {
        showToast(
          "Popup Blocked",
          "Please allow popups or open in your default browser",
          "error"
        );
      } else if (error.code === 'auth/cancelled-popup-request') {
        // User closed the popup, no need to show error
        return;
      } else {
        showToast("Error", error.message, "error");
      }
    }
  };

  const handleAppleSignIn = async () => {
    // Check if we're in Instagram's in-app browser first
    if (isInstagramBrowser) {
      setIsDialogOpen(true);
      return;
    }

    const provider = new OAuthProvider("apple.com");
    try {
      const result = await signInWithPopup(auth, provider);
      const newUser = result.user;

      if (newUser) {
        const userRef = doc(firestore, "users", newUser.uid);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists()) {
          const userDoc = userSnap.data();
          localStorage.setItem("user-info", JSON.stringify(userDoc));
          loginUser(userDoc);
          navigate("/");
        } else {
          showToast("Error", "User doesn't exist", "error");
        }
      }
    } catch (error) {
      // Handle specific error cases
      if (error.code === 'auth/popup-blocked') {
        showToast(
          "Popup Blocked",
          "Please allow popups or open in your default browser",
          "error"
        );
      } else if (error.code === 'auth/cancelled-popup-request') {
        // User closed the popup, no need to show error
        return;
      } else {
        showToast("Error", error.message, "error");
      }
    }
  };

  const handleOpenInBrowser = () => {
    // Get the full URL including protocol
    const currentUrl = window.location.href;
    const hostname = window.location.hostname;
    const pathname = window.location.pathname;
    const protocol = window.location.protocol;

    // For iOS
    if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
      // Try opening in Chrome first
      window.location.href = `googlechrome://${hostname}${pathname}`;
      
      // Fallback to Safari after a short delay
      setTimeout(() => {
        window.location.href = currentUrl;
      }, 2500);
      return;
    }
    
    // For Android
    if (/Android/i.test(navigator.userAgent)) {
      // Try opening in Chrome with proper URL formatting
      window.location.href = `intent://${hostname}${pathname}#Intent;scheme=${protocol.slice(0, -1)};package=com.android.chrome;end`;
    } else {
      // Fallback for other browsers
      window.open(currentUrl, '_system');
    }
  };

  return (
    <Box
      minH="100vh"
      maxH="100vh"
      overflow="hidden"
      position="relative"
      bg="white"
      color="black"
      pb="env(safe-area-inset-bottom)"
    >
      {/* Add the AlertDialog */}
      <AlertDialog
        isOpen={isDialogOpen}
        leastDestructiveRef={cancelRef}
        onClose={() => setIsDialogOpen(false)}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent mx={4}>
            <AlertDialogHeader
              fontSize="lg"
              fontWeight="bold"
              color="purple.700"
            >
              Open in Browser
            </AlertDialogHeader>

            <AlertDialogBody>
              <VStack spacing={4} align="start">
                <Text color="gray.700">
                  For the best experience, including:
                </Text>
                <VStack spacing={2} align="start" pl={4}>
                  <HStack spacing={2}>
                    <Icon as={FaGoogle} color="purple.500" />
                    <Text color="gray.600">Google Sign In</Text>
                  </HStack>
                  <HStack spacing={2}>
                    <Icon as={FaApple} color="purple.500" />
                    <Text color="gray.600">Apple Sign In</Text>
                  </HStack>
                </VStack>
                <Text color="gray.700">
                  Please open Hubzo in your default browser.
                </Text>
              </VStack>
            </AlertDialogBody>

            <AlertDialogFooter gap={3}>
              <Button
                ref={cancelRef}
                onClick={() => setIsDialogOpen(false)}
                variant="ghost"
                color="gray.500"
              >
                Cancel
              </Button>
              <Button
                bg="linear-gradient(135deg, #4F46E5 0%, #7C3AED 100%)"
                color="white"
                onClick={() => {
                  handleOpenInBrowser();
                  setIsDialogOpen(false);
                }}
                _hover={{
                  opacity: 0.9,
                }}
              >
                Open in Browser
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      {/* Updated Top Bar */}
      <Box
        position="fixed"
        top={0}
        left={0}
        right={0}
        zIndex={1000}
        bg="white"
        h={{ base: "48px", md: "64px" }}
        backdropFilter="blur(12px)"
        backgroundColor="rgba(255, 255, 255, 0.9)"
        borderBottom="1px solid"
        borderColor="gray.100"
      >
        <Container maxW="container.xl" h="full">
          <Flex 
            justify="space-between"
            align="center"
            h="full"
            px={{ base: 3, md: 4 }}
          >
            <LogoComponent onClick={() => navigate("/")} />
          </Flex>
        </Container>
      </Box>

      {/* Main Content */}
      <Box 
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        overflowY="auto"
        style={{
          WebkitOverflowScrolling: 'touch'
        }}
      >
        <Box 
          pt={{ base: "48px", md: "64px" }}
          minH="100vh"
          pb={{ base: 32, md: 8 }}
        >
          <Container 
            maxW="container.sm" 
            py={{ base: 12, md: 20 }} 
            px={6}
          >
            <VStack spacing={12} align="center" w="full">
              {/* Updated Welcome Section */}
              <VStack spacing={4} textAlign="center">
                <Text
                  fontSize={{ base: "sm", md: "md" }}
                  letterSpacing="0.2em"
                  textTransform="uppercase"
                  fontWeight="light"
                  color="gray.500"
                >
                  Welcome Back
                </Text>
                <Text
                  fontSize={{ base: "3xl", md: "4xl" }}
                  fontWeight="light"
                  letterSpacing="tight"
                  color="gray.900"
                  lineHeight="shorter"
                >
                  Sign in to continue
                </Text>
              </VStack>

              {/* Form Section */}
              <VStack spacing={6} w="full" maxW="md">
                <Input
                  ref={emailInputRef}
                  name="email"
                  placeholder="Email address"
                  size="lg"
                  type="email"
                  value={inputs.email}
                  onChange={(e) => setInputs({ ...inputs, email: e.target.value })}
                  onKeyDown={handleKeyDown}
                  bg="gray.50"
                  color="gray.800"
                  border="1px solid"
                  borderColor="gray.200"
                  _placeholder={{ color: "gray.400" }}
                  _focus={{ 
                    borderColor: "black",
                    boxShadow: "0 0 0 1px black",
                    bg: "white" 
                  }}
                  rounded="full"
                  h={14}
                />

                <InputGroup size="lg">
                  <Input
                    ref={passwordInputRef}
                    name="password"
                    placeholder="Password"
                    type={showPassword ? "text" : "password"}
                    value={inputs.password}
                    onChange={(e) => setInputs({ ...inputs, password: e.target.value })}
                    onKeyDown={handleKeyDown}
                    bg="gray.50"
                    color="gray.800"
                    border="1px solid"
                    borderColor="gray.200"
                    _placeholder={{ color: "gray.400" }}
                    _focus={{ 
                      borderColor: "black",
                      boxShadow: "0 0 0 1px black",
                      bg: "white" 
                    }}
                    rounded="full"
                    h={14}
                  />
                  <InputRightElement h={14}>
                    <IconButton
                      variant="ghost"
                      onClick={togglePasswordVisibility}
                      icon={showPassword ? <ViewOffIcon /> : <ViewIcon />}
                      aria-label={showPassword ? "Hide password" : "Show password"}
                      color="gray.400"
                      _hover={{ color: "gray.600" }}
                    />
                  </InputRightElement>
                </InputGroup>

                <Flex justify="flex-end" w="full">
                  <Button
                    variant="link"
                    color="gray.600"
                    fontSize="sm"
                    fontWeight="medium"
                    onClick={() => navigate("/forgot-password")}
                    _hover={{ color: "black" }}
                  >
                    Forgot password?
                  </Button>
                </Flex>

                <PrimaryButton
                  w="full"
                  isLoading={loading}
                  onClick={handleLogin}
                  rightIcon={<FaArrowRight />}
                >
                  Sign In
                </PrimaryButton>
              </VStack>

              {/* Social Sign In Section */}
              {false && !isStandaloneIos && (
                <VStack spacing={6} w="full" maxW="md">
                  <HStack spacing={4} w="full">
                    <Divider flex={1} />
                    <Text fontSize="sm" color="gray.500" fontWeight="medium">
                      OR
                    </Text>
                    <Divider flex={1} />
                  </HStack>

                  {isApple ? (
                    <HStack spacing={4} w="full">
                      <SocialButton
                        flex={1}
                        leftIcon={
                          <Box as="span" mr={-1}>
                            <svg width="24" height="24" viewBox="0 0 24 24">
                              <path
                                d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
                                fill="#4285F4"
                              />
                              <path
                                d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
                                fill="#34A853"
                              />
                              <path
                                d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
                                fill="#FBBC05"
                              />
                              <path
                                d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
                                fill="#EA4335"
                              />
                            </svg>
                          </Box>
                        }
                        onClick={handleGoogleSignIn}
                      />

                      <SocialButton
                        flex={1}
                        leftIcon={<FaApple size={28} />}
                        onClick={handleAppleSignIn}
                      />
                    </HStack>
                  ) : (
                    <VStack spacing={2} w="full">
                      {!isInstagramBrowser ? (
                        <SocialButton
                          w="full"
                          leftIcon={
                            <Box as="span" mr={2}>
                              <svg width="24" height="24" viewBox="0 0 24 24">
                                <path
                                  d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
                                  fill="#4285F4"
                                />
                                <path
                                  d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
                                  fill="#34A853"
                                />
                                <path
                                  d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
                                  fill="#FBBC05"
                                />
                                <path
                                  d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
                                  fill="#EA4335"
                                />
                              </svg>
                            </Box>
                          }
                          onClick={handleGoogleSignIn}
                        >
                          Sign in with Google
                        </SocialButton>
                      ) : (
                        <Button
                          w="full"
                          size="lg"
                          bg="gray.100"
                          color="gray.500"
                          leftIcon={
                            <Box as="span" mr={2}>
                              <svg width="24" height="24" viewBox="0 0 24 24">
                                <path
                                  d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
                                  fill="#4285F4"
                                />
                                <path
                                  d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
                                  fill="#34A853"
                                />
                                <path
                                  d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
                                  fill="#FBBC05"
                                />
                                <path
                                  d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
                                  fill="#EA4335"
                                />
                              </svg>
                            </Box>
                          }
                          onClick={() => {
                            showToast(
                              "Browser Not Supported",
                              "Please open in Safari or Chrome to sign in with Google",
                              "error"
                            );
                          }}
                          _hover={{ bg: "gray.200" }}
                          cursor="not-allowed"
                          rounded="full"
                          h={14}
                        >
                          Sign in with Google
                        </Button>
                      )}
                    </VStack>
                  )}
                </VStack>
              )}
            </VStack>
          </Container>
        </Box>
      </Box>
    </Box>
  );
};

export default AuthPage;
