import {
  Avatar,
  Flex,
  Skeleton,
  SkeletonCircle,
  Spacer,
  Text,
} from "@chakra-ui/react";
import useGetGroupProfileById from "../../hooks/useGetGroupProfileById";
import { Link } from "react-router-dom";
import { timeAgo } from "../../utils/timeAgo";

const GroupFollowing = ({ user }) => {
  const { userProfile, isLoading } = useGetGroupProfileById(user);

  if (isLoading) return <FollowingSkeleton />;
  if (userProfile === null) {
    return <FollowingSkeleton />;
  } else {
    return (
      <Link to={`/${userProfile.username}`}>
        <Flex gap={4}>
          <Avatar src={userProfile.profilePicURL} size={"md"} />
          <Flex direction={"column"}>
            <Flex gap={2} alignItems={"center"}>
              <Link to={`/${userProfile.username}`}>
                <Text fontWeight={"bold"} fontSize={16}>
                  {userProfile.fullName}
                </Text>
                <Text color={"gray.500"} fontWeight={"semibold"} fontSize={12}>
                  {userProfile.memberList.length} followers
                </Text>
              </Link>
              {/* <Text fontSize={14}>{comment.comment}</Text> */}
            </Flex>

            {/* {userProfile.phoneNumber !== "" && (
            <Text fontSize={12} color={"gray"}>
              📞 *{userProfile.phoneNumber.substr(-4)}
            </Text>
          )} */}
          </Flex>

          <Spacer />
        </Flex>
      </Link>
    );
    // }
  }
};

export default GroupFollowing;

const FollowingSkeleton = () => {
  return (
    <Flex gap={4} w={"full"} alignItems={"center"}>
      <SkeletonCircle h={10} w="10" />
      <Flex gap={1} flexDir={"column"}>
        <Skeleton height={2} width={100} />
        <Skeleton height={2} width={50} />
      </Flex>
    </Flex>
  );
};
