import {
  Box,
  Container,
  Text,
  VStack,
  Heading,
  Button,
  Flex,
  HStack,
  IconButton,
  SimpleGrid,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import {
  FaInstagram,
  FaLinkedin,
  FaXTwitter,
  FaArrowRight,
} from "react-icons/fa6";

const PrivacyPolicyPage = () => {
  const navigate = useNavigate();

  const colors = {
    textColor: "gray.900",
    bgColor: "white",
    cardBg: "white",
    dividerColor: "gray.200",
    iconBgColor: "rgba(184, 160, 128, 0.1)",
    hoverBgColor: "rgba(184, 160, 128, 0.05)",
    subtitleColor: "gray.600",
    accentColor: "#B8A080",
    borderColor: "rgba(184, 160, 128, 0.2)",
  };

  const TopBanner = () => (
    <Box
      position="sticky"
      top={0}
      left={0}
      right={0}
      zIndex={1000}
      bg={colors.bgColor}
      h={{ base: "56px", md: "64px" }}
      backdropFilter="blur(12px)"
      backgroundColor="rgba(255, 255, 255, 0.9)"
      borderBottom="1px"
      borderColor={colors.borderColor}
    >
      <Container maxW="container.xl" h="full">
        <Flex
          justify="space-between"
          align="center"
          h="full"
          px={{ base: 3, md: 4 }}
        >
          {/* Logo */}
          <Box
            as="h1"
            fontSize={{ base: "28px", md: "32px" }}
            fontWeight="400"
            letterSpacing="0.35em"
            position="relative"
            display="inline-block"
            cursor="pointer"
            color={colors.textColor}
            textTransform="uppercase"
            fontFamily="Montserrat, sans-serif"
            onClick={() => navigate("/")}
            _hover={{
              letterSpacing: "0.4em",
              transform: "translateY(-1px)",
            }}
            transition="all 0.4s cubic-bezier(0.4, 0, 0.2, 1)"
            sx={{
              WebkitFontSmoothing: "antialiased",
              textRendering: "optimizeLegibility",
            }}
          >
            <Box
              as="span"
              position="relative"
              _before={{
                content: '""',
                position: "absolute",
                left: "-8%",
                right: "-8%",
                top: "-8px",
                bottom: "-8px",
                background: colors.iconBgColor,
                opacity: { base: 0.95, md: 0.9 },
                borderRadius: "2px",
                transform: "skew(-4deg)",
                transition: "all 0.3s ease",
                zIndex: -1,
              }}
              _after={{
                content: '""',
                position: "absolute",
                left: 0,
                right: 0,
                bottom: "-4px",
                height: "1px",
                background: `linear-gradient(to right, transparent, ${colors.accentColor}, transparent)`,
                opacity: 0.4,
                transform: "scaleX(0.8)",
                transition: "transform 0.3s ease",
              }}
              _hover={{
                _after: {
                  transform: "scaleX(1)",
                },
              }}
            >
              hubzo
            </Box>
          </Box>

          {/* Sign In Button */}
          <Button
            size={{ base: "sm", md: "md" }}
            bg="black"
            color="white"
            fontWeight="medium"
            onClick={() => navigate("/auth")}
            rounded="full"
            px={{ base: 4, md: 6 }}
            h={{ base: 10, md: 12 }}
            fontSize={{ base: "sm", md: "md" }}
            letterSpacing="wide"
            fontFamily="Montserrat, sans-serif"
            _hover={{
              transform: "translateY(-2px)",
              shadow: "lg",
              opacity: 0.9,
            }}
            _active={{
              transform: "translateY(0)",
              opacity: 0.8,
            }}
            transition="all 0.2s"
          >
            Sign In
          </Button>
        </Flex>
      </Container>
    </Box>
  );

  return (
    <Box
      minH="100vh"
      maxH="100vh"
      overflow="hidden"
      position="relative"
      bg={colors.bgColor}
    >
      <TopBanner />

      {/* Scrollable Content Area */}
      <Box
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        overflowY="auto"
        pt={{ base: "56px", md: "64px" }}
        pb={8}
      >
        {/* Main Content */}
        <Container maxW="container.xl" py={20}>
          <VStack spacing={12} align="center">
            <VStack align="center" spacing={6} w="full" maxW="3xl">
              <Heading
                fontSize={{ base: "3xl", md: "5xl" }}
                fontWeight="light"
                color={colors.textColor}
                letterSpacing="wide"
                fontFamily="Montserrat, sans-serif"
                textAlign="left"
                w="full"
              >
                Privacy Policy
              </Heading>
              <Text
                fontSize={{ base: "lg", md: "xl" }}
                color={colors.subtitleColor}
                lineHeight="tall"
                fontFamily="Montserrat, sans-serif"
                letterSpacing="wide"
                textAlign="left"
                w="full"
              >
                At Hubzo, accessible from Google Play and App Store, one of our main priorities 
                is the privacy of our visitors. This Privacy Policy document contains types of 
                information that is collected and recorded by Hubzo and how we use it.
              </Text>
            </VStack>

            <VStack align="center" spacing={8} w="full" maxW="3xl">
              <Box w="full">
                <Heading
                  size="lg"
                  mb={4}
                  fontWeight="medium"
                  color={colors.textColor}
                  fontFamily="Montserrat, sans-serif"
                  letterSpacing="wide"
                  textAlign="left"
                >
                  General Data Protection Regulation (GDPR)
                </Heading>
                <Text
                  fontSize="lg"
                  color={colors.subtitleColor}
                  lineHeight="tall"
                  fontFamily="Montserrat, sans-serif"
                  letterSpacing="wide"
                  textAlign="left"
                >
                  We are a Data Controller of your information.
                </Text>
              </Box>
            </VStack>

            <VStack align="center" spacing={8} w="full" maxW="3xl">
              <Box w="full">
                <Text
                  fontSize="lg"
                  color={colors.subtitleColor}
                  lineHeight="tall"
                  fontFamily="Montserrat, sans-serif"
                  letterSpacing="wide"
                  textAlign="left"
                  mb={4}
                >
                  Hubzo legal basis for collecting and using the personal information described in this Privacy Policy depends on the Personal Information we collect and the specific context in which we collect the information:
                </Text>

                <VStack align="start" spacing={2} pl={4} mb={4}>
                  <Text fontSize="lg" color={colors.subtitleColor}>• Hubzo needs to perform a contract with you</Text>
                  <Text fontSize="lg" color={colors.subtitleColor}>• You have given Hubzo permission to do so</Text>
                  <Text fontSize="lg" color={colors.subtitleColor}>• Processing your personal information is in Hubzo legitimate interests</Text>
                  <Text fontSize="lg" color={colors.subtitleColor}>• Hubzo needs to comply with the law</Text>
                </VStack>

                <Heading
                  size="lg"
                  mb={4}
                  fontWeight="medium"
                  color={colors.textColor}
                  fontFamily="Montserrat, sans-serif"
                  letterSpacing="wide"
                  textAlign="left"
                >
                  Log Files
                </Heading>
                <Text
                  fontSize="lg"
                  color={colors.subtitleColor}
                  lineHeight="tall"
                  fontFamily="Montserrat, sans-serif"
                  letterSpacing="wide"
                  textAlign="left"
                  mb={8}
                >
                  Hubzo follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services' analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users' movement on the website, and gathering demographic information.
                </Text>

                <Heading
                  size="lg"
                  mb={4}
                  fontWeight="medium"
                  color={colors.textColor}
                  fontFamily="Montserrat, sans-serif"
                  letterSpacing="wide"
                  textAlign="left"
                >
                  Privacy Policies
                </Heading>
                <Text
                  fontSize="lg"
                  color={colors.subtitleColor}
                  lineHeight="tall"
                  fontFamily="Montserrat, sans-serif"
                  letterSpacing="wide"
                  textAlign="left"
                  mb={8}
                >
                  You may consult this list to find the Privacy Policy for each of the advertising partners of Hubzo.
                </Text>

                <Heading
                  size="lg"
                  mb={4}
                  fontWeight="medium"
                  color={colors.textColor}
                  fontFamily="Montserrat, sans-serif"
                  letterSpacing="wide"
                  textAlign="left"
                >
                  Third Party Privacy Policies
                </Heading>
                <Text
                  fontSize="lg"
                  color={colors.subtitleColor}
                  lineHeight="tall"
                  fontFamily="Montserrat, sans-serif"
                  letterSpacing="wide"
                  textAlign="left"
                  mb={8}
                >
                  Hubzo's Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options.
                </Text>

                <Text 
                  color="gray.500" 
                  fontSize="sm" 
                  pt={8}
                  fontFamily="Montserrat, sans-serif"
                  letterSpacing="wide"
                >
                  Generated using GDPR Privacy Notice
                </Text>
              </Box>
            </VStack>
          </VStack>
        </Container>

        {/* Footer */}
        <Box
          borderTop="1px"
          borderColor={colors.borderColor}
          bg={colors.cardBg}
        >
          <Container maxW="container.xl" py={20}>
            <VStack spacing={16}>
              {/* Top Footer */}
              <Flex
                direction={{ base: "column", md: "row" }}
                justify="space-between"
                align={{ base: "start", md: "center" }}
                w="full"
                gap={10}
              >
                <SimpleGrid columns={{ base: 2, md: 3 }} spacing={10}>
                  <VStack align="start" spacing={4}>
                    <Text
                      fontWeight="semibold"
                      fontSize="sm"
                      textTransform="uppercase"
                      color={colors.textColor}
                    >
                      Company
                    </Text>
                    <VStack align="start" spacing={3}>
                      <Button
                        variant="link"
                        fontSize="sm"
                        color={colors.subtitleColor}
                      >
                        About Us
                      </Button>
                      <Button
                        variant="link"
                        fontSize="sm"
                        color={colors.subtitleColor}
                        onClick={() =>
                          window.open(
                            "https://www.linkedin.com/company/hubzo",
                            "_blank"
                          )
                        }
                      >
                        Careers
                      </Button>
                      {/* <Button variant="link" fontSize="sm" color={colors.subtitleColor}>
                        Press
                      </Button> */}
                      <Button
                        variant="link"
                        onClick={() => navigate("/contact")}
                        fontSize="sm"
                        color={colors.subtitleColor}
                      >
                        Contact
                      </Button>
                    </VStack>
                  </VStack>

                  <VStack align="start" spacing={4}>
                    <Text
                      fontWeight="semibold"
                      fontSize="sm"
                      textTransform="uppercase"
                      color={colors.textColor}
                    >
                      Resources
                    </Text>
                    <VStack align="start" spacing={3}>
                      <Button
                        variant="link"
                        fontSize="sm"
                        color={colors.subtitleColor}
                      >
                        Blog
                      </Button>
                      <Button
                        variant="link"
                        fontSize="sm"
                        color={colors.subtitleColor}
                      >
                        Community Guidelines
                      </Button>
                      <Button
                        variant="link"
                        fontSize="sm"
                        color={colors.subtitleColor}
                      >
                        Safety Tips
                      </Button>
                      <Button
                        variant="link"
                        fontSize="sm"
                        color={colors.subtitleColor}
                        onClick={() => navigate("/faq")}
                      >
                        FAQ
                      </Button>
                    </VStack>
                  </VStack>

                  <VStack align="start" spacing={4}>
                    <Text
                      fontWeight="semibold"
                      fontSize="sm"
                      textTransform="uppercase"
                      color={colors.textColor}
                    >
                      Legal
                    </Text>
                    <VStack align="start" spacing={3}>
                      <Button
                        variant="link"
                        fontSize="sm"
                        color={colors.subtitleColor}
                        onClick={() => navigate("/privacy-policy")}
                      >
                        Privacy Policy
                      </Button>
                      <Button
                        variant="link"
                        fontSize="sm"
                        color={colors.subtitleColor}
                      >
                        Terms of Service
                      </Button>
                      <Button
                        variant="link"
                        fontSize="sm"
                        color={colors.subtitleColor}
                      >
                        Cookie Policy
                      </Button>
                      <Button
                        variant="link"
                        fontSize="sm"
                        color={colors.subtitleColor}
                      >
                        Safety
                      </Button>
                    </VStack>
                  </VStack>
                </SimpleGrid>
              </Flex>

              {/* Bottom Footer */}
              <Flex
                pt={8}
                borderTop="1px"
                borderColor={colors.borderColor}
                w="full"
                direction={{ base: "column", sm: "row" }}
                justify="space-between"
                align="center"
                gap={4}
              >
                <HStack spacing={4} color={colors.subtitleColor} fontSize="sm">
                  <Text>© 2024 Hubzo</Text>
                  <Text>•</Text>
                  <Text>Made with ❤️ in London</Text>
                </HStack>

                <HStack spacing={4}>
                  <IconButton
                    aria-label="Instagram"
                    icon={<FaInstagram />}
                    variant="ghost"
                    color={colors.subtitleColor}
                    size="sm"
                    _hover={{ color: colors.accentColor }}
                    onClick={() =>
                      window.open(
                        "https://www.instagram.com/hubzo.social/",
                        "_blank"
                      )
                    }
                  />
                  <IconButton
                    aria-label="LinkedIn"
                    icon={<FaLinkedin />}
                    variant="ghost"
                    color={colors.subtitleColor}
                    size="sm"
                    _hover={{ color: colors.accentColor }}
                    onClick={() =>
                      window.open(
                        "https://www.linkedin.com/company/hubzo",
                        "_blank"
                      )
                    }
                  />
                  <IconButton
                    aria-label="Twitter"
                    icon={<FaXTwitter />}
                    variant="ghost"
                    color={colors.subtitleColor}
                    size="sm"
                    _hover={{ color: colors.accentColor }}
                  />
                </HStack>
              </Flex>
            </VStack>
          </Container>
        </Box>
      </Box>
    </Box>
  );
};

export default PrivacyPolicyPage;
