import { useState } from "react";
import { collection, addDoc, doc } from "firebase/firestore";
import { firestore } from "../firebase/firebase";
import { useNavigate } from "react-router-dom";

const useCreateEvent = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  const createEvent = async (eventData) => {
    setLoading(true);
    try {
      // Add event document to "events" collection
      const eventRef = collection(firestore, "events");
      const eventDocRef = await addDoc(eventRef, eventData);

      // Use the eventId from the newly created event document
      const eventId = eventDocRef.id;

      localStorage.setItem("eventId", eventId);

      // Add invitation document to "invitations" collection
      const invitationRef = collection(firestore, "invitations");
      await addDoc(invitationRef, {
        createdAt: new Date(),
        creatorId: eventData.createdBy,
        eventId: eventId,
        userId: eventData.createdBy,
      });

      setLoading(false);
      navigate(`/`);
    } catch (err) {
      console.log(err);
      setError(err.message);
      setLoading(false);
    }
  };

  return { createEvent, loading, error };
};

export default useCreateEvent;
