import { getFunctions, httpsCallable } from "firebase/functions";
import { useState } from "react";
import { app } from "../firebase/firebase"; // Ensure this points to your Firebase config file

const useLogUserCount = () => {
  const functions = getFunctions(app); // Use getFunctions to initialize functions
  const logUserCountWithDetails = httpsCallable(
    functions,
    "logUserCountWithDetails"
  );
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const triggerLogUserCount = async (eventId, userId) => {
    if (!eventId || !userId) {
      console.error("Missing eventId or userId", { eventId, userId });
      setError("Event ID and User ID are required.");
      return;
    }

    setLoading(true);
    setError(null);
    try {
        console.log(eventId);
        console.log(userId);
      const result = await logUserCountWithDetails({ eventId, userId });
      console.log("User count:", result.data.userCount);
    } catch (err) {
      console.error("Error fetching user count:", err);
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  return { triggerLogUserCount, loading, error };
};

export default useLogUserCount;
