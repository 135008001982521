import { useState, useEffect } from "react";
import { doc, getDoc } from "firebase/firestore";
import { firestore } from "../firebase/firebase";
import { format, isToday, isTomorrow, isThisWeek } from "date-fns";

const useFetchEventDetails = (eventId) => {
  const [eventDetails, setEventDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchEvent = async () => {
      setLoading(true);
      try {
        const eventRef = doc(firestore, "events", eventId);
        const eventSnap = await getDoc(eventRef);

        if (eventSnap.exists()) {
          const eventData = eventSnap.data();
          const startDate = eventData.startAt.toDate();

          // Format start time as "h:mm a" (e.g., "5:00 PM")
          const startTime = format(startDate, "h:mm a");
          let formattedEventDate = `${format(startDate, "EEE, MMM d")}, ${startTime}`;

          // Smarter formatting for "Today", "Tomorrow", and "This Week"
          if (isToday(startDate)) {
            formattedEventDate = `Today, ${startTime}`;
          } else if (isTomorrow(startDate)) {
            formattedEventDate = `Tomorrow, ${startTime}`;
          } else if (isThisWeek(startDate)) {
            formattedEventDate = `${format(startDate, "EEEE")}, ${startTime}`; // Day name (e.g., "Saturday")
          }

          setEventDetails({
            ...eventData,
            formattedEventDate,
          });
        } else {
          setError("Event not found");
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (eventId) fetchEvent();
  }, [eventId]);

  return { eventDetails, loading, error };
};

export default useFetchEventDetails;
