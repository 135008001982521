import {
  Container,
  Flex,
  Button,
  VStack,
  FormControl,
  Input,
  Text,
  IconButton,
  Box,
} from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import useEditProfile from "../../hooks/useEditProfile";
import useAuthStore from "../../store/authStore";
import useShowToast from "../../hooks/useShowToast";
import DesktopNav from '../../components/Navigation/DesktopNav';

const UpdateDobPage = () => {
  const authUser = useAuthStore((state) => state.user);
  const [dob, setDob] = useState(authUser?.yob || "");
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const { isUpdating, editProfile } = useEditProfile();
  const showToast = useShowToast();

  const colors = {
    textColor: "gray.900",
    bgColor: "white",
    cardBg: "white",
    dividerColor: "gray.200",
    iconBgColor: "rgba(184, 160, 128, 0.1)",
    hoverBgColor: "rgba(184, 160, 128, 0.05)",
    subtitleColor: "gray.600",
    accentColor: "#B8A080",
    borderColor: "rgba(184, 160, 128, 0.2)",
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      inputRef.current.blur();
    }
  };

  const handleUpdateDob = async () => {
    if (dob.length !== 4) {
      showToast("Error", "Please enter a valid 4-digit year", "error");
    } else {
      try {
        await editProfile({ yob: dob });
        showToast("Success", "Date of birth updated", "success");
        navigate(-1);
      } catch (error) {
        showToast("Error", error.message, "error");
      }
    }
  };

  return (
    <Box
      h="100vh"
      maxH="100vh"
      overflow="hidden"
      position="relative"
      bg={colors.bgColor}
      pb="env(safe-area-inset-bottom)"
    >
      <Box display={{ base: "none", md: "block" }}>
        <DesktopNav />
      </Box>

      <Box
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        overflowY="auto"
        pb={{ base: 32, md: 8 }}
        style={{
          WebkitOverflowScrolling: 'touch'
        }}
      >
        <Container 
          maxW={{ base: "container.md", md: "container.xl" }} 
          px={4} 
          py={6}
          mb={{ base: 8, md: 0 }}
          mt={{ base: 0, md: "60px" }}
        >
          {/* Header */}
          <Flex 
            w="full" 
            alignItems="center" 
            mb={8}
            position="relative"
          >
            <IconButton
              icon={<ArrowBackIcon />}
              variant="ghost"
              aria-label="Go back"
              onClick={() => navigate(-1)}
              position={{ base: "relative", md: "absolute" }}
              left={{ md: 0 }}
              color={colors.accentColor}
              _hover={{
                bg: colors.iconBgColor,
                transform: "translateY(-2px)",
              }}
              transition="all 0.2s"
            />
            <Text 
              fontSize={{ base: "2xl", md: "3xl" }}
              fontWeight="light"
              color={colors.textColor}
              textAlign={{ base: "left", md: "center" }}
              flex="1"
              ml={{ base: 4, md: 0 }}
              letterSpacing="wide"
              fontFamily="Montserrat, sans-serif"
            >
              What year were you born?
            </Text>
          </Flex>

          {/* Main Content */}
          <Box 
            maxW={{ base: "full", md: "lg" }} 
            mx="auto"
          >
            <Box
              bg={colors.cardBg}
              borderRadius="xl"
              borderWidth="1px"
              borderColor={colors.borderColor}
              p={6}
              textAlign="center"
              shadow="sm"
              _hover={{ shadow: "md" }}
              transition="all 0.2s"
            >
              <VStack spacing={6} align="center">
                <FormControl>
                  <Input
                    ref={inputRef}
                    placeholder="Year"
                    size="lg"
                    maxLength={4}
                    type="tel"
                    value={dob}
                    onChange={(e) => setDob(e.target.value)}
                    onKeyDown={handleKeyDown}
                    bg={colors.iconBgColor}
                    color={colors.textColor}
                    _placeholder={{ color: colors.subtitleColor }}
                    textAlign="center"
                    rounded="full"
                    h={14}
                    fontSize="md"
                    fontFamily="Montserrat, sans-serif"
                    letterSpacing="wide"
                    borderColor={colors.borderColor}
                    _hover={{
                      borderColor: colors.accentColor,
                    }}
                    _focus={{
                      borderColor: colors.accentColor,
                      boxShadow: `0 0 0 1px ${colors.accentColor}`,
                    }}
                  />
                </FormControl>

                <Button
                  w="full"
                  maxW="400px"
                  bg={dob.length === 4 ? "black" : "gray.300"}
                  color="white"
                  size="lg"
                  h={14}
                  fontSize="md"
                  fontWeight="medium"
                  isLoading={isUpdating}
                  onClick={handleUpdateDob}
                  rounded="full"
                  isDisabled={dob.length !== 4}
                  letterSpacing="wide"
                  fontFamily="Montserrat, sans-serif"
                  _hover={{ 
                    transform: dob.length === 4 ? "translateY(-2px)" : "none",
                    shadow: dob.length === 4 ? "lg" : "none",
                    opacity: dob.length === 4 ? 0.9 : 1,
                  }}
                  _active={{
                    transform: "translateY(0)",
                    opacity: 0.8,
                  }}
                  transition="all 0.2s"
                >
                  Update Year
                </Button>
              </VStack>
            </Box>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default UpdateDobPage;
