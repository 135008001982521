import {
  Box,
  Container,
  Flex,
  Divider,
  AlertDescription,
  Alert,
  Stack,
  Text,
  AlertIcon,
  useDisclosure,
  useColorModeValue,
  Spacer,
  VStack,
  Skeleton,
  CardBody,
  Card,
  SkeletonCircle,
  CloseButton,
  Heading,
} from "@chakra-ui/react";
import useAuthStore from "../../store/authStore";
import useGetGroupProfileByUsername from "../../hooks/useGetGroupProfileByUsername";
import { useParams, useNavigate } from "react-router-dom";
import Member from "../../components/Comment/Member";
import useGetGroupEventById from "../../hooks/useGetGroupEventById";
import { MoonIcon, SunIcon, ArrowBackIcon } from "@chakra-ui/icons";

const AttendingPage = () => {
  const { eventId } = useParams();
  const { isLoading, post } = useGetGroupEventById(eventId);

  // const { isLoading, userProfile, userId } =
  //   useGetGroupProfileByUsername(group);

  const {
    isOpen: isVisible,
    onClose,
    onOpen,
  } = useDisclosure({ defaultIsOpen: true });

  const attendingCounter = () => {
    var counter = 0;

    post.goingList.forEach((element) => {
      if (element.counter) {
        counter += parseInt(element.counter) + 1;
      } else {
        counter += 1;
      }

    });

    return counter
  };


  const navigate = useNavigate();

  return (
    <Container px={0} maxW={"container.sm"}>
      <Flex
        px={0}
        mt={0}
        mb={28}
        gap={4}
        flexDir={"column"}
        // maxH={"500px"}
        // overflowY={"auto"}
      >
        <Box bg={useColorModeValue(`white`, `gray.800`)} px={4}>
          <Flex h={16} alignItems={"center"} justifyContent={"flex-start"}>
            <Flex alignItems={"flex-start"}>
              <Stack direction={"row"} spacing={7}>
                {/* <Button
                    onClick={() => {
                      navigate(-1);
                    }}
                  > */}
                <ArrowBackIcon
                  boxSize={6}
                  onClick={() => {
                    navigate(-1);
                  }}
                />
                {/* {colorMode === "light" ? <MoonIcon /> : <SunIcon />} */}
                {/* </Button> */}
              </Stack>
            </Flex>

            <Box ml={8}>
              {post && post.goingList.length !== 0 && (
                <Text
                  pl={0}
                  fontSize={24}
                  // fontWeight={"bold"}
                  // color={"green.300"}
                >
                  {`Going (${attendingCounter()})`}
                </Text>
              )}
            </Box>

            <Spacer />
          </Flex>
        </Box>

        {isLoading &&
          [0, 1, 2, 3].map((_, idx) => (
            <VStack key={idx} gap={4} alignItems={"flex-start"} mb={5}>
              <Flex gap="2">
                <SkeletonCircle size="10" />
                <VStack gap={2} alignItems={"flex-start"}>
                  <Skeleton height="10px" w={"300px"} />
                  <Skeleton height="10px" w={"300px"} />
                </VStack>
              </Flex>
              {/* <Skeleton w={"full"}>
              <Box h={"400px"}>contents wrapped</Box>
            </Skeleton> */}
            </VStack>
          ))}

        <Flex mx={4} direction={"column"}>
          <Card mt={0} pb={0} w={"full"} mb={0}>
            <CardBody pl={2} pt={0} pb={0}>
              <Flex
                pl={2}
                pr={1}
                mt={6}
                // mb={8}
                gap={4}
                flexDir={"column"}
                // maxH={"500px"}
                // overflowY={"auto"}
              >
                {/* {post && post.goingList && (
          <Alert mb={2} status="warning">
            <AlertIcon />
            <Box>
              <AlertDescription />
              You are seeing members with linked accounts.
            </Box>
            <CloseButton
              alignSelf="flex-start"
              position="relative"
              right={-1}
              top={-1}
              onClick={onClose}
            />
          </Alert>
        )} */}

                {post &&
                  post.goingList &&
                  post.goingList.map((comment, idx) => (
                    <Member key={idx} comment={comment.uid} counter={comment.counter} />
                  ))}
              </Flex>
            </CardBody>
          </Card>
        </Flex>
      </Flex>
    </Container>
  );
};

export default AttendingPage;
