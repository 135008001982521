import {
  Box,
  VStack,
  HStack,
  Divider,
  Button,
  Text,
  Container,
  Flex,
  Grid,
  useColorModeValue,
  useToast,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  CloseButton,
  Heading,
  Icon,
  Skeleton,
} from "@chakra-ui/react";
import {
  FaRegCalendarTimes,
  FaLock,
  FaUserShield,
  FaCalendarAlt,
  FaPencilAlt,
} from "react-icons/fa";
import { useState, useEffect } from "react";
import SidebarItems from "../components/Sidebar/SidebarItems";
import useAuthStore from "../store/authStore";
import { useNavigate } from "react-router-dom";
import useFetchInvitations from "../hooks/useFetchInvitations";
import InvitationItem from "../components/InvitationItem";
import { getFunctions, httpsCallable } from "firebase/functions";
import { app } from "../firebase/firebase";
import PageLayout from "../Layouts/PageLayout/PageLayout";
import useGetUserProfileById from "../hooks/useGetUserProfileById";
import { getFirestore, addDoc, collection } from "firebase/firestore";

const LogoComponent = ({ onClick }) => (
  <Box 
    as="h1" 
    fontSize={{ base: "28px", md: "32px" }}
    fontWeight="400"
    letterSpacing="0.35em"
    position="relative"
    display="inline-block"
    cursor="pointer"
    color="#1A1A1A"
    textTransform="uppercase"
    fontFamily="Montserrat, sans-serif"
    onClick={onClick}
    _hover={{
      letterSpacing: "0.4em",
      transform: "translateY(-1px)",
      transition: "all 0.4s cubic-bezier(0.4, 0, 0.2, 1)",
    }}
    transition="all 0.4s cubic-bezier(0.4, 0, 0.2, 1)"
    sx={{
      WebkitFontSmoothing: "antialiased",
      textRendering: "optimizeLegibility",
    }}
  >
    <Box
      as="span"
      position="relative"
      _before={{
        content: '""',
        position: "absolute",
        left: "-8%",
        right: "-8%",
        top: "-8px",
        bottom: "-8px",
        background: "linear-gradient(135deg, rgba(26, 26, 26, 0.08), rgba(26, 26, 26, 0.03))",
        backdropFilter: "blur(4px)",
        opacity: { base: 0.95, md: 0.9 },
        borderRadius: "2px",
        transform: "skew(-4deg)",
        transition: "all 0.3s ease",
        zIndex: -1,
      }}
      _after={{
        content: '""',
        position: "absolute",
        left: 0,
        right: 0,
        bottom: "-4px",
        height: "1px",
        background: "linear-gradient(to right, transparent, currentColor, transparent)",
        opacity: 0.4,
        transform: "scaleX(0.8)",
        transition: "transform 0.3s ease",
      }}
      _hover={{
        _after: {
          transform: "scaleX(1)",
        },
      }}
    >
      hubzo
    </Box>
  </Box>
);

const InvitationsPage = () => {
  const navigate = useNavigate();
  const authUser = useAuthStore((state) => state.user);
  const userId = authUser?.id;

  const { invitations, loading, error } = useFetchInvitations(userId);
  const [filter, setFilter] = useState("upcoming");

  // Colors and styles
  const textColor = useColorModeValue("gray.800", "whiteAlpha.900");
  const bgColor = useColorModeValue("white", "gray.900");
  const sectionBgColor = useColorModeValue("white", "gray.800");
  const subtitleColor = useColorModeValue("gray.600", "gray.400");
  const dividerColor = useColorModeValue("gray.300", "gray.600");
  const accentColor = useColorModeValue("#007AFF", "#0A84FF");

  const [loadingz, setLoadingz] = useState(false);
  const [message, setMessage] = useState("");
  const toast = useToast();

  const { userProfile } = useGetUserProfileById(authUser?.uid);

  const filteredInvitations =
    filter === "upcoming"
      ? invitations.filter(
          (invite) => new Date(invite.createdAt.toDate()) > new Date()
        )
      : invitations.filter(
          (invite) => new Date(invite.createdAt.toDate()) <= new Date()
        );

  const handleShare = async () => {
    const shareMessage = `Hey! I found this exclusive meetup app that connects like-minded professionals based on attractiveness, career success, and shared interests. Use my code ${userProfile?.userCode} when signing up to boost both our profiles`;
    
    // Initialize Firestore
    const db = getFirestore();

    try {
      // First create the share record
      await addDoc(collection(db, "shares"), {
        userId: userProfile?.uid,
        userCode: userProfile?.userCode ? userProfile?.userCode : "",
        createdAt: new Date(),
      });

      // Then proceed with sharing
      if (navigator.share) {
        try {
          await navigator.share({
            title: 'Join Exclusive Meetups on Hubzo',
            text: shareMessage,
            url: 'https://hubzo.social'
          });
        } catch (error) {
          if (error.name !== 'AbortError') {
            await navigator.clipboard.writeText(shareMessage);
            toast({
              title: "Copied to clipboard",
              description: "Invite message has been copied to your clipboard!",
              status: "success",
              duration: 3000,
              isClosable: true,
            });
          }
        }
      } else {
        await navigator.clipboard.writeText(shareMessage);
        toast({
          title: "Copied to clipboard",
          description: "Invite message has been copied to your clipboard!",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error sharing:", error);
      toast({
        title: "Error",
        description: "There was an error sharing. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Box
      h="100vh"
      maxH="100vh"
      overflow="hidden"
      position="relative"
      bg="white"
      pb="env(safe-area-inset-bottom)"
    >
      <Box
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        overflowY="auto"
        pb={{ base: 32, md: 8 }}
        style={{
          WebkitOverflowScrolling: "touch",
        }}
      >
        <Container
          maxW={{ base: "container.md", md: "container.xl" }}
          px={4}
          py={6}
        >
          {/* Header */}
          <Flex
            w="full"
            alignItems="center"
            justify={{ base: "space-between", md: "center" }}
            mb={6}
            gap={4}
          >
            {/* Logo - Only shows on mobile */}
            <Box display={{ base: "block", md: "none" }}>
              <LogoComponent onClick={() => navigate("/")} />
            </Box>

            {/* Filter Pills - Desktop centered, Mobile to the right */}
            <HStack spacing={2} justify={{ base: "flex-end", md: "center" }}>
              <Button
                size="sm"
                bg={filter === "upcoming" ? "black" : "white"}
                color={filter === "upcoming" ? "white" : "gray.700"}
                borderWidth={1}
                borderColor={filter === "upcoming" ? "transparent" : "gray.200"}
                borderRadius="full"
                onClick={() => setFilter("upcoming")}
                _hover={{
                  transform: "translateY(-1px)",
                  shadow: "sm",
                }}
                transition="all 0.2s"
                px={4}
              >
                Upcoming
              </Button>
              <Button
                size="sm"
                bg={filter === "past" ? "black" : "white"}
                color={filter === "past" ? "white" : "gray.700"}
                borderWidth={1}
                borderColor={filter === "past" ? "transparent" : "gray.200"}
                borderRadius="full"
                onClick={() => setFilter("past")}
                _hover={{
                  transform: "translateY(-1px)",
                  shadow: "sm",
                }}
                transition="all 0.2s"
                px={4}
              >
                Past
              </Button>
            </HStack>
          </Flex>

          {/* Invitations Grid/List */}
          {loading ? (
            <Grid
              templateColumns={{
                base: "1fr",
                md: "repeat(2, 1fr)",
                lg: "repeat(3, 1fr)",
              }}
              gap={6}
              w="full"
              mb={8}
            >
              {[1, 2, 3].map((i) => (
                <Skeleton key={i} height="400px" borderRadius="xl" />
              ))}
            </Grid>
          ) : filteredInvitations.length > 0 ? (
            <Grid
              templateColumns={{
                base: "1fr",
                md: "repeat(2, 1fr)",
                lg: "repeat(3, 1fr)",
              }}
              gap={6}
              w="full"
              mb={8}
            >
              {filteredInvitations.map((invitation) => (
                <InvitationItem key={invitation.id} invitation={invitation} />
              ))}
            </Grid>
          ) : (
            !authUser?.isCreator && (
              <Box
                bg="white"
                borderRadius="2xl"
                w="full"
                maxW={{ base: "full", md: "lg" }}
                mx="auto"
                overflow="hidden"
                borderWidth="1px"
                borderColor="gray.200"
                shadow="sm"
                mb={8}
                transition="all 0.2s"
                _hover={{
                  transform: "translateY(-2px)",
                  shadow: "md",
                }}
              >
                <Box bg="gray.50" py={2} px={4}>
                  <Text
                    fontSize={{ base: "sm", md: "sm" }}
                    color="gray.700"
                    fontWeight="bold"
                  >
                    ⚡️ BOOST YOUR CHANCES
                  </Text>
                </Box>

                <Box p={{ base: 6, md: 8 }}>
                  <VStack spacing={6} align="stretch">
                    {/* Profile Completion Section */}
                    <VStack spacing={4} align="center" textAlign="center">
                      <Box p={3} bg="purple.50" rounded="full" color="purple.500">
                        <FaRegCalendarTimes size={24} />
                      </Box>
                      <VStack spacing={2}>
                        <Text fontWeight="bold" fontSize="xl" color="gray.800">
                          No Invites Yet
                        </Text>
                        <Text fontSize="md" color="gray.600" lineHeight="tall" maxW="md">
                          New to Hubzo? Complete your profile and start receiving exclusive invites
                        </Text>
                      </VStack>
                    </VStack>

                    {/* Action Cards */}
                    <VStack spacing={4} pt={2}>
                      {/* Profile Completion Card */}
                      <Box
                        p={4}
                        bg="gray.50"
                        borderRadius="xl"
                        w="full"
                        borderWidth="1px"
                        borderColor="gray.200"
                      >
                        <HStack justify="space-between" align="center">
                          <HStack spacing={3}>
                            <Box p={2} bg="purple.100" rounded="full">
                              <FaPencilAlt size={14} color="purple.500" />
                            </Box>
                            <VStack align="start" spacing={0}>
                              <Text fontSize="sm" fontWeight="500" color="gray.700">
                                Complete Your Profile
                              </Text>
                              <Text fontSize="xs" color="gray.500">
                                We need your profile info to send you meetup invites
                              </Text>
                            </VStack>
                          </HStack>
                          <Button
                            size="sm"
                            bg="black"
                            color="white"
                            px={4}
                            rounded="full"
                            _hover={{
                              transform: "translateY(-1px)",
                              shadow: "sm",
                            }}
                            onClick={() => navigate("/edit_profile")}
                          >
                            Complete
                          </Button>
                        </HStack>
                      </Box>

                      {/* Referral Card */}
                      <Box
                        p={4}
                        bg="gray.50"
                        borderRadius="xl"
                        w="full"
                        borderWidth="1px"
                        borderColor="gray.200"
                      >
                        <HStack justify="space-between" align="center">
                          <HStack spacing={3}>
                            <Box p={2} bg="blue.100" rounded="full">
                              <FaUserShield size={14} color="blue.500" />
                            </Box>
                            <VStack align="start" spacing={0}>
                              <Text fontSize="sm" fontWeight="500" color="gray.700">
                                Share Your Code
                              </Text>
                              <Text fontSize="xs" color="gray.500">
                                Friends signing up with your code boost both profiles
                              </Text>
                            </VStack>
                          </HStack>
                          <Button
                            size="sm"
                            bg="black"
                            color="white"
                            px={4}
                            rounded="full"
                            _hover={{
                              transform: "translateY(-1px)",
                              shadow: "sm",
                            }}
                            onClick={handleShare}
                          >
                            Share
                          </Button>
                        </HStack>
                      </Box>
                    </VStack>
                  </VStack>
                </Box>
              </Box>
            )
          )}

          {/* Advice Box */}
          {!loading && !authUser?.isCreator && false && (
            <Box
              bg="white"
              borderRadius="2xl"
              w="full"
              maxW={{ base: "full", md: "lg" }}
              mx="auto"
              overflow="hidden"
              borderWidth="1px"
              borderColor="gray.200"
              shadow="sm"
              transition="all 0.2s"
              _hover={{
                transform: "translateY(-2px)",
                shadow: "md",
              }}
            >
              <Box bg="purple.50" py={2} px={4}>
                <Text
                  fontSize={{ base: "sm", md: "sm" }}
                  color="purple.700"
                  fontWeight="bold"
                >
                  ✨ UNLOCK MORE OPPORTUNITIES
                </Text>
              </Box>

              <Box p={{ base: 8, md: 8 }}>
                <VStack
                  spacing={{ base: 6, md: 6 }}
                  align="center"
                  textAlign="center"
                >
                  <Box p={4} bg="purple.50" rounded="full" color="gray.500">
                    <Icon as={FaUserShield} boxSize={6} />
                  </Box>
                  <VStack spacing={3}>
                    <Text
                      fontWeight="bold"
                      fontSize={{ base: "xl", md: "xl" }}
                      color="gray.800"
                    >
                      Want More Invites? 🎉
                    </Text>
                    <Text
                      fontSize={{ base: "md", md: "md" }}
                      color="gray.600"
                      lineHeight="tall"
                      maxW="md"
                    >
                      Verify your profile to unlock exclusive events and
                      experiences
                    </Text>
                  </VStack>

                  <Button
                    size="lg"
                    bg="black"
                    color="white"
                    px={8}
                    h={14}
                    fontSize="lg"
                    fontWeight="semibold"
                    rounded="full"
                    _hover={{
                      transform: "translateY(-2px)",
                      shadow: "lg",
                      opacity: 0.95,
                    }}
                    transition="all 0.2s"
                    onClick={() => navigate("/edit_profile")}
                  >
                    Boost Profile ⭐️
                  </Button>
                </VStack>
              </Box>
            </Box>
          )}

          {authUser?.isCreator && (
            <Box
              bg="white"
              borderRadius="2xl"
              w="full"
              maxW={{ base: "full", md: "lg" }}
              mx="auto"
              overflow="hidden"
              borderWidth="1px"
              borderColor="gray.200"
              shadow="sm"
              transition="all 0.2s"
              _hover={{
                transform: "translateY(-2px)",
                shadow: "md",
              }}
              mt={8}
            >
              <Box bg="purple.50" py={2} px={4}>
                <Text
                  fontSize={{ base: "sm", md: "sm" }}
                  color="purple.700"
                  fontWeight="bold"
                >
                  🎯 FOR EVENT ORGANIZERS
                </Text>
              </Box>

              <Box p={{ base: 8, md: 8 }}>
                <VStack
                  spacing={{ base: 6, md: 6 }}
                  align="center"
                  textAlign="center"
                >
                  <Box p={4} bg="purple.50" rounded="full" color="gray.600">
                    <Icon as={FaCalendarAlt} boxSize={6} />
                  </Box>
                  <VStack spacing={3}>
                    <Text
                      fontWeight="bold"
                      fontSize={{ base: "xl", md: "xl" }}
                      color="gray.800"
                    >
                      Ready to Host an Event? 🎉
                    </Text>
                    <Text
                      fontSize={{ base: "md", md: "md" }}
                      color="gray.600"
                      lineHeight="tall"
                      maxW="md"
                    >
                      Create an exclusive event and connect with amazing people
                    </Text>
                  </VStack>

                  <Button
                    size="lg"
                    bg="black"
                    color="white"
                    px={8}
                    h={14}
                    fontSize="lg"
                    fontWeight="semibold"
                    rounded="full"
                    _hover={{
                      transform: "translateY(-2px)",
                      shadow: "lg",
                      opacity: 0.95,
                    }}
                    transition="all 0.2s"
                    onClick={() => navigate("/create_event_title")}
                  >
                    Create Event ✨
                  </Button>
                </VStack>
              </Box>
            </Box>
          )}
        </Container>
      </Box>
    </Box>
  );
};

export default InvitationsPage;
